import React, { useState, useEffect, Profiler, useMemo } from 'react';
import { IonSelectOption } from '@ionic/react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { extractSearchTerms, formatValue } from '../../../lib/util';
import { formatDateShort, getDayOffset, warnDays, dateDueToClassName, MAX_DATE } from '../../../lib/datesAndTime';
import { canEdit, canView, permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { renderFullName } from '../../../shared-state/Core/users';
import { CertificateHolderInfo, CrewCertificate } from '../../../shared-state/Crew/crewCertificates';
import { sharedState } from '../../../shared-state/shared-state';
import { renderCategoryName } from '../../../lib/categories';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import EditCrewCertificateSettings from '../../../modals/Crew/CrewCertificates/EditCrewCertificateSettings/EditCrewCertificateSettings';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import EditCrewCertificate from '../../../modals/Crew/CrewCertificates/EditCrewCertificate/EditCrewCertificate';
import ViewCrewCertificate from '../../../modals/Crew/CrewCertificates/ViewCrewCertificate/ViewCrewCertificate';
import ArchiveCrewCertificate from '../../../modals/Crew/CrewCertificates/ArchiveCrewCertificate/ArchiveCrewCertificate';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaSearchbar from '../../../components/SeaSearchbar/SeaSearchbar';
import CrewCertificatesListPdf from '../../../exports/Crew/CrewCertificates/CrewCertificatesListPdf';
import CrewCertificatesCsv from '../../../exports/Crew/CrewCertificates/CrewCertificatesCsv';
import './CrewCertificates.css';


interface CrewCertificatesProps {
    visible: boolean
}

const CrewCertificates: React.FC<CrewCertificatesProps> = ({ visible }) => {
    const userId = sharedState.userId.use(visible ? 1 : 101);
    const crewCertificates = sharedState.crewCertificates.use(visible ? 1 : 101);
    const crewCertificateTitles = sharedState.crewCertificateTitles.use(visible ? 1 : 101);
    sharedState.crewCertificates.use(visible ? 102 : 0); // Prepare for modal to use
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [searchText, setSearchText] = useState("");
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showArchivedModal, setShowArchivedModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<CrewCertificate>();
    const [showEditCrewCertificateSettings, setShowEditCrewCertificateSettings] = useState(false);
    const [listType, setListType] = useState(warnDays.crewCertificates[0].toString());
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (visible) {
            logPageView('Crew/CrewCertificates');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && crewCertificates?.byId[selectedItem.id]) {
            setSelectedItem(crewCertificates.byId[selectedItem.id])
        };
    }, [crewCertificates, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: CrewCertificate) => {
        if (item.state === 'missing') {
            setShowEditItemModal(true);
            setSelectedItem(item)
        } else {
            setShowViewItemModal(true);
            setSelectedItem(item)
        }
    };
    const onViewArchived = () => {
        setShowArchivedModal(true);
    };

    const prioritisedCrewCertificates = useMemo(() => {
        if (['all', 'gap'].includes(listType)) return [];
        const terms = extractSearchTerms(searchText, true);
        const numDays = Number(listType);
        const dateExpires = isNaN(numDays) ? MAX_DATE : getDayOffset(numDays);
        return crewCertificates?.prioritised?.filter((item) => {
            if ((canView('crewCertificates') || item.heldBy === userId) && item.dateExpires && item.dateExpires < dateExpires) {
                if (terms) {
                    let isMatch = true;
                    for (let term of terms) {
                        if (!item.searchText?.toLowerCase().includes(term.toLowerCase())) {
                            isMatch = false;
                            break;
                        }
                    }
                    return isMatch;
                }
                return true;
            } else {
                return false;
            }
        });
    }, [listType, searchText, crewCertificates?.prioritised, userId]);

    const filteredCertificateHolders: CertificateHolderInfo[] = useMemo(() => {
        if (!crewCertificates || !['all', 'gap'].includes(listType)) {
            return [];
        }

        const terms = extractSearchTerms(searchText, true);
        let _filteredHolders: CertificateHolderInfo[] = [];

        if (crewCertificates?.holdersSorted) {
            for (const holder of crewCertificates.holdersSorted) {

                const _certificates: CrewCertificate[] = [];
                if (canView('crewCertificates') || holder.id === userId) {
                    for (const certificate of holder.certificates || []) {

                        let isMatch = true;
                        for (let term of terms) {
                            if (!certificate.searchText?.toLowerCase().includes(term.toLowerCase())) {
                                isMatch = false;
                                break;
                            }
                        }

                        if (listType === 'gap' && certificate.state !== 'missing' && certificate.files.length !== 0) {
                            isMatch = false;
                        } else if (listType === 'gap') {
                            certificate.state = 'missing';
                        }

                        if (isMatch) {
                            _certificates.push(certificate);
                        }
                    }

                    if (_certificates.length > 0) {
                        _filteredHolders.push({
                            ...holder,
                            certificates: _certificates
                        });
                    }
                }
            }
        }
        return _filteredHolders;
    }, [crewCertificates, listType, searchText, userId]);

    return (
        // <RequirePermissions
        //     role="crewCertificates"
        //     level={permissionLevels.VIEW}
        //     showDenial={true}
        // >
        <div>
            <div className="crew-certificates page-head">
                <div><h2>{!canView('crewCertificates') ? 'My' : 'Crew'} Certificates</h2></div>
                <div className="actions">
                    <SeaSelect
                        name="listType"
                        value={listType}
                        width="250px"
                        zone="grey"
                        onchange={(e) => {
                            resetPageLimit();
                            setListType(e.detail.value);
                        }}
                    >
                        {warnDays.crewCertificates.map((days) => {
                            return <IonSelectOption key={days} value={days.toString()}>Overdue &amp; Due Within {days} Days</IonSelectOption>
                        })}
                        <IonSelectOption value="gap">Gap Analysis</IonSelectOption>
                        <IonSelectOption value="all">All</IonSelectOption>
                    </SeaSelect>
                    <RequirePermissions
                        role="crewCertificates"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            Add New
                        </SeaButton>
                        <RequirePermissions
                            role="crewCertificates"
                            level={permissionLevels.FULL}
                        >
                            <div className="spacer"></div>
                            <SeaButton onClick={(e) => onViewArchived()} zone="grey">
                                <SeaIcon slot="start" icon="archive" />
                                Archived
                            </SeaButton>
                        </RequirePermissions>

                        <SeaExporter
                            setExportType={setExportType}
                            
                            pdf={exportType === 'pdf' && (
                                <CrewCertificatesListPdf
                                    onCompleted={() => setExportType(undefined)}
                                    listType = {listType}
                                    crewCertificateHolder={filteredCertificateHolders}
                                    prioritisedCrewCertificates={prioritisedCrewCertificates || []}
                                />
                            )}
                            csv={exportType === "csv" && <CrewCertificatesCsv onCompleted={() => setExportType(undefined)} />}
                        />
                        <RequirePermissions
                            role="crewCertificates"
                            level={permissionLevels.EDIT}
                        >
                            <div className="spacer"></div>
                            <SeaButton
                                zone="grey"
                                shape="circle"
                                onClick={(e) => {
                                    setShowEditCrewCertificateSettings(true);
                                }}
                            >
                                <SeaIcon slot="icon-only" icon="settings"/>
                            </SeaButton>
                        </RequirePermissions>
                    </RequirePermissions>

                </div>
            </div>

            <div className={`columns wrap filters`}>
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
            </div>

            <div 
                key={listType} // Force re-render when listType changes
                className={`crew-certificates has-thumbs has-status ${['all', 'gap'].includes(listType) ? 'all' : ''} ${(filteredCertificateHolders || prioritisedCrewCertificates) ? 'reveal' : 'conceal'}`}
            >
                {(() => {
                    if (!canView('crewCertificates') && userId && !crewCertificates?.holders[userId]) {
                        return (
                            <div className="sea-no-data columns">
                                <div><SeaIcon icon="info" /></div>
                                No certificates were found that are held by you.
                            </div>
                        );
                    }

                    if (!canView('crewCertificates') && !['all', 'gap'].includes(listType) && prioritisedCrewCertificates?.filter((item) => item.heldBy === userId).length === 0) {
                        return (
                            <div className="sea-no-data columns">
                                <div><SeaIcon icon="info" /></div>
                                <div>
                                    No certificates were found matching the filter
                                </div>
                            </div>
                        );
                    }

                    if (['all', 'gap'].includes(listType) && !filteredCertificateHolders?.length) {
                        return (
                            <div className="sea-no-data columns">
                                <div><SeaIcon icon="info" /></div>
                                No certificates were found matching the filter
                            </div>
                        );
                    }

                    if (!['all', 'gap'].includes(listType) && prioritisedCrewCertificates && prioritisedCrewCertificates.length === 0) {
                        return (
                            <div className="sea-no-data columns">
                                <div><SeaIcon icon="info" /></div>
                                No certificates are overdue or due within the selected period.
                            </div>
                        );
                    }

                    return (
                        <div>
                            <Profiler id="crew.certificates" onRender={onProfilerRender}>
                                {/* Prioritised */}
                                {!['all', 'gap'].includes(listType) && prioritisedCrewCertificates && prioritisedCrewCertificates.length > 0 && (
                                    <>
                                        <div className="sea-row headings">
                                            <div></div>
                                            <div>Certificate</div>
                                            <div>Held By</div>
                                            <div>Issued By</div>
                                            <div>Expiry</div>
                                            <div>Status</div>
                                            <div>Reminder</div>
                                        </div>
                                        {mapArrayWithLimit(prioritisedCrewCertificates, (item) => {
                                            if (!canView('crewCertificates')) {
                                                if (item.heldBy === userId) {
                                                    return (
                                                        <div key={item.id} className={`sea-card sea-row ${dateDueToClassName(item.dateExpires, warnDays.crewCertificates[0])}`} onClick={(e) => onViewItem(item)}>
                                                            <div>
                                                                <SeaFileImage files={item.files} size="tiny" />
                                                            </div>
                                                            <div className="bold truncate-2">{renderCategoryName(item?.titleId, crewCertificateTitles)}</div>
                                                            <div className="truncate-2">{formatValue(crewCertificates?.holders[item.heldBy].name)}</div>
                                                            <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                                            <div>{item.dateExpires ? formatDateShort(item.dateExpires) : null}</div>
                                                            <div>{item.dateExpires ? <SeaStatusDueDate dateDue={item.dateExpires} warnDays={warnDays.crewCertificates[0]} /> : ''} </div>
                                                            <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                                        </div>
                                                    );
                                                } else {
                                                    return <></>;
                                                }
                                            }
                                            return (
                                                <div key={item.id} className={`sea-card sea-row ${dateDueToClassName(item.dateExpires, warnDays.crewCertificates[0])}`} onClick={(e) => onViewItem(item)}>
                                                    <div>
                                                        <SeaFileImage files={item.files} size="tiny" />
                                                    </div>
                                                    <div className="bold truncate-2">{item.title}</div>
                                                    <div className="truncate-2">{formatValue(crewCertificates?.holders[item.heldBy].name)}</div>
                                                    <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                                    <div>{item.dateExpires ? formatDateShort(item.dateExpires) : null}</div>
                                                    <div>{item.dateExpires ? <SeaStatusDueDate dateDue={item.dateExpires} warnDays={warnDays.crewCertificates[0]} /> : ''} </div>
                                                    <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                                </div>
                                            );
                                        })}
                                    </>
                                )}
                                {/* Category By User Name */}
                                {['all', 'gap'].includes(listType) && filteredCertificateHolders && filteredCertificateHolders.length > 0 && (
                                    <>
                                        <div className="sea-row headings">
                                            <div></div>
                                            <div>Certificate</div>
                                            <div>Issued By</div>
                                            <div>Issue Date</div>
                                            <div>Expiry</div>
                                            <div>Status</div>
                                            <div>Reminder</div>
                                        </div>
                                        {filteredCertificateHolders.map((holder) => {
                                            if (isLimitReached()) {
                                                return undefined;
                                            }
                                            if (!canView('crewCertificates')) {
                                                if (holder.name === renderFullName()) {
                                                    return <React.Fragment key={holder.name}>
                                                        <div className="category-heading">{holder.name}</div>
                                                        {mapArrayWithLimit(holder.certificates, (item) => {
                                                            return (
                                                                <div key={item.id} className={`sea-card sea-row ${item.dateExpires && dateDueToClassName(item.dateExpires, warnDays.crewCertificates[0])}`} onClick={(e) => onViewItem(item)}>
                                                                    <div>
                                                                        <SeaFileImage files={item.files} size="tiny" />
                                                                    </div>
                                                                    <div className="bold truncate-2">{item.title}</div>
                                                                    <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                                                    <div className={item.state === 'missing' ? 'not-card' : ''}>{formatDateShort(item.dateIssued)}</div>
                                                                    <div className={item.dateExpires ? '' : 'not-card'}>{item.dateExpires ? formatValue(formatDateShort(item.dateExpires)) : null}</div>
                                                                    <div className={(item.dateExpires || item.state === 'missing') ? '' : 'not-card'}>
                                                                        {item.state === 'missing' ? (
                                                                            <SeaStatusDueDate isMissing />
                                                                        ) : item.dateExpires ? (
                                                                            <SeaStatusDueDate dateDue={item.dateExpires} warnDays={warnDays.crewCertificates[0]} />
                                                                        ) : ''}
                                                                    </div>
                                                                    <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                                                </div>
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                }
                                            } else {
                                                return <React.Fragment key={holder.name}>
                                                    <div className="category-heading">{holder.name}</div>
                                                    {mapArrayWithLimit(holder.certificates, (item) => {
                                                        return (
                                                            <div
                                                                key={item.id}
                                                                className={`sea-card sea-row ${item.dateExpires && dateDueToClassName(item.dateExpires, warnDays.crewCertificates[0])}`}
                                                                onClick={(e) => onViewItem(item)}
                                                            >
                                                                <div>
                                                                    <SeaFileImage files={item.files} size="tiny" />
                                                                </div>
                                                                <div className="bold truncate-2">{item.title}</div>
                                                                <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                                                <div className={item.state === 'missing' ? 'not-card' : ''}>{formatDateShort(item.dateIssued)}</div>
                                                                <div className={item.dateExpires && item.state !== 'missing' ? '' : 'not-card'}>{item.dateExpires ? formatDateShort(item.dateExpires) : '-'}</div>
                                                                <div className={(item.dateExpires || item.state === 'missing') ? '' : 'not-card'}>
                                                                    {item.state === 'missing' ? (
                                                                        <SeaStatusDueDate isMissing />
                                                                    ) : item.dateExpires ? (
                                                                        <SeaStatusDueDate dateDue={item.dateExpires} warnDays={warnDays.crewCertificates[0]} />
                                                                    ) : ''}
                                                                </div>
                                                                <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                                            </div>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            }
                                            return undefined;
                                        })}
                                    </>
                                )}
                                
                                {visible && limitTriggerElement}
                            </Profiler>
                        </div>
                    );
                })()}
            </div>
            {visible &&
                <>
                    <EditCrewCertificate
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem?.state === 'missing' ? selectedItem : undefined}
                        setItemToUpdate={setSelectedItem}
                    />
                    {selectedItem && <ViewCrewCertificate
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                    {canView('crewCertificates') &&
                        <ArchiveCrewCertificate
                            showModal={showArchivedModal}
                            setShowModal={setShowArchivedModal}
                        />
                    }
                    {canEdit('crewCertificates') && <EditCrewCertificateSettings
                        showModal={showEditCrewCertificateSettings}
                        setShowModal={setShowEditCrewCertificateSettings}
                    />}
                    
                </>
            }
        </div>
    );
};

export default CrewCertificates;
