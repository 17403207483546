import React, { useCallback, useMemo } from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn, limitPdfText } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { formatDateShort } from '../../../lib/datesAndTime';
import { renderFullName, renderFullNameForUserId } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { getImgSrcFromString } from '../../../lib/files';
import { renderVesselName } from '../../../shared-state/Core/vessels';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';

interface CorrectiveActionsPdfProps {
    onCompleted: () => void;
    selectedVesselId?: string;
}

const CorrectiveActionsPdf: React.FC<CorrectiveActionsPdfProps> = ({ onCompleted, selectedVesselId }) => {
    const correctiveActions = sharedState.correctiveActions.use();

    const title = useMemo(() => {
        if (selectedVesselId) {
            return `Corrective Action List - ${renderVesselName(selectedVesselId)}`;
        }
        return `Corrective Action List`;
    }, [selectedVesselId]);

    const MakePDF = useCallback(() => {
        return (
            <Document title={title} author="Sea Flux" creator="Sea Flux" producer="Sea Flux">
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader heading={title} userFullName={renderFullName()} />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.thumbColumn}></View>
                        <View style={styleItemColumn(1.6)}>
                            <Text>Task</Text>
                        </View>
                        <View style={pdfStyles.numColumn}>
                            <Text>Action #</Text>
                        </View>
                        <View style={styleItemColumn(0.8)}>
                            <Text>Assigned</Text>
                        </View>
                        <View style={styleItemColumn(0.8)}>
                            <Text>Tags</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Date Added</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Due Date</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>

                    {correctiveActions?.array.all?.map((correctiveAction, index) => {
                        const file = correctiveAction.files && correctiveAction.files.length > 0 ? correctiveAction.files[0] : undefined;
                        const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                        return (
                            <React.Fragment key={correctiveAction.id}>
                                {index > 0 && <View style={pdfStyles.itemsRowSpacer}></View>}
                                <View wrap={false} style={pdfStyles.itemsRow}>
                                    <View style={pdfStyles.thumbColumn}>{imgSrc && <Image src={imgSrc} style={pdfStyles.thumbImg} />}</View>
                                    <View style={styleItemColumn(1.6)}>
                                        <Text wrap={true}>{formatValue(limitPdfText(correctiveAction.title, 80))}</Text>
                                    </View>
                                    <View style={pdfStyles.numColumn}>
                                        <Text>{formatValue(correctiveAction.correctiveActionNum)}</Text>
                                    </View>
                                    <View style={styleItemColumn(0.8)}>
                                        <Text>{formatValue(renderFullNameForUserId(correctiveAction.assignedTo))}</Text>
                                    </View>
                                    <View style={styleItemColumn(0.8)}>
                                        <Text>{formatValue(correctiveAction.tags && limitPdfText(correctiveAction.tags.join(', '), 40))}</Text>
                                    </View>
                                    <View style={pdfStyles.dateColumn}>
                                        <Text>{correctiveAction.whenAdded ? formatDateShort(correctiveAction.whenAdded) : '-'}</Text>
                                    </View>
                                    <View style={pdfStyles.dateColumn}>
                                        <Text>{correctiveAction.dateDue ? formatDateShort(correctiveAction.dateDue) : '-'}</Text>
                                    </View>
                                </View>
                            </React.Fragment>
                        );
                    })}
                </Page>
            </Document>
        );
    }, [title, correctiveActions?.array.all]);

    return <SeaPdfGenerator onCompleted={onCompleted} pdfTitle={title} MakePdf={MakePDF} requestedFrom="correctiveActions" />;
};

export default CorrectiveActionsPdf;
