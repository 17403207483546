import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { formatYear } from "../../lib/datesAndTime";
import { CrewCertificate } from "./crewCertificates";
import { registerFiles } from "../FileSyncSystem/filesToCache";

//
// Loads archived crew certificates
//

export type ArchivedCrewCertificatesData = {
    categories: string[], // e.g. "2023"
    byCategory: {
        [year: string]: CrewCertificate[] // dateIssued desc
    },
    count: number
};

export const archivedCrewCertificatesConfig: SharedStateConfig<ArchivedCrewCertificatesData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId'],
    countLiveDocs: () => sharedState.archivedCrewCertificates.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId && canView('crewCertificates')) {
            return onSnapshot(
                query(
                    collection(firestore, 'crewCertificates'),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'archived'),
                    orderBy('dateIssued', 'desc')
                ),
                (snap) => {
                    done();
                    const all = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as CrewCertificate;
                    });

                    const categories: string[] = [];
                    const byCategory: {
                        [category: string]: CrewCertificate[]
                    } = {};
                    all.forEach((item: CrewCertificate) => {
                        registerFiles(item.files, 'crewCertificates', item, 'thumbnailsOnly');
                        const dateIssued = formatYear(item.dateIssued);
                        if (byCategory[dateIssued] === undefined) {
                            categories.push(dateIssued);
                            byCategory[dateIssued] = [];
                        }
                        byCategory[dateIssued].push(item);
                    });

                    set({
                        categories,
                        byCategory,
                        count: all.length
                    });
                }, (error) => {
                    // This should be very rare
                    done();
                    console.log('Failed to access arcvived crew certificates', error.message, error);
                }
            );
        }
    }
};
