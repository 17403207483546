import React, { useEffect, useState, useMemo } from 'react';
import { formatVessels } from '../../../../../lib/util';
import { formatDateShort } from '../../../../../lib/datesAndTime';
import { canComplete, canEdit } from '../../../../../shared-state/Core/userPermissions';
import { UserType } from '../../../../../shared-state/Core/user';
import { sharedState } from '../../../../../shared-state/shared-state';
import { renderFullNameForUserId } from '../../../../../shared-state/Core/users';
import { useCrewCompletedCustomForms } from '../../../../../shared-state/Crew/useCrewCompletedCustomForms';
import { UserDocument, useCrewDocuments } from '../../../../../shared-state/Crew/useCrewDocuments';
import { CustomForm } from '../../../../../shared-state/CompanyDocuments/CustomForms/customForms';
import { CustomFormCompleted } from '../../../../../shared-state/CompanyDocuments/CustomForms/useCustomFormsCompleted';
import { ExportType } from '../../../../../components/SeaExporter/SeaExporter';
import SeaButton from '../../../../../components/SeaButton/SeaButton';
import SeaFileImage from '../../../../../components/SeaFileImage/SeaFileImage';
import SeaNoData from '../../../../../components/SeaNoData/SeaNoData';
import ViewCompletedCustomForm from '../../../../CompanyDocuments/CustomForms/ViewCompletedCustomForm/ViewCompletedCustomForm';
import CompleteCustomForm from '../../../../CompanyDocuments/CustomForms/CompleteCustomForm/CompleteCustomForm';
import BrowseCustomForms from '../../../../CompanyDocuments/CustomForms/BrowseCustomForms/BrowseCustomForms';
import CompletedCustomFormPdf from '../../../../../exports/CustomForms/CompletedCustomFormPdf';
import EditUserDocumentsModal from '../../EditUserDocumentsModal/EditUserDocumentsModal';
import './UserFormsAndDocumentsTab.css';

interface UserFormsAndDocumentsTabProps {
    showModal: boolean,
    selectedUser?: UserType
}

const UserFormsAndDocumentsTab: React.FC<UserFormsAndDocumentsTabProps> = ({
    showModal,
    selectedUser
}) => {
    const userId = sharedState.userId.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const customForms = sharedState.customForms.use(showModal);
    const userDocuments = useCrewDocuments(showModal ? selectedUser : undefined);
    const completedCustomForms = useCrewCompletedCustomForms(showModal ? selectedUser : undefined);

    const [showViewCompletedCustomForm, setShowViewCompletedCustomForm] = useState(false);
    const [showCompleteCustomForm, setShowCompleteCustomForm] = useState(false);
    const [showBrowseCustomForms, setShowBrowseCustomForms] = useState(false);
    const [selectedCustomForm, setSelectedCustomForm] = useState<CustomForm>();
    const [selectedCompletedCustomForm, setSelectedCompletedCustomForm] = useState<CustomFormCompleted>();
    const [selectedUserDocuments, setSelectedUserDocuments] = useState<UserDocument>();
    const [exportType, setExportType] = useState<ExportType>();
    const [showEditUserDocuments, setShowEditUserDocuments] = useState(false);

    // Keep selectedCompletedCustomForm fresh
    useEffect(() => {
        if (
            selectedCompletedCustomForm &&
            selectedCompletedCustomForm.id &&
            completedCustomForms?.byId[selectedCompletedCustomForm.id]
        ) {
            setSelectedCompletedCustomForm(completedCustomForms.byId[selectedCompletedCustomForm.id]);
        }
    }, [completedCustomForms]); // eslint-disable-line react-hooks/exhaustive-deps


    const formsAndFiles = useMemo(() => {
        if (completedCustomForms !== undefined && userDocuments !== undefined) {
            const _formsAndFiles = [] as (CustomFormCompleted | UserDocument)[];
            completedCustomForms.all.forEach((form) => {
                _formsAndFiles.push(form);
            });
            userDocuments.all.forEach((userDocument) => {
                _formsAndFiles.push(userDocument);
            });
            _formsAndFiles.sort((a, b) => {
                return b.whenAdded - a.whenAdded;
            });
            return _formsAndFiles;
        }
        return undefined;
    }, [completedCustomForms, userDocuments]);
    
    // Keep selectedCustomForm fresh
    useEffect(() => {
        if (
            selectedCustomForm &&
            selectedCompletedCustomForm?.customFormId &&
            customForms?.byId[selectedCompletedCustomForm.customFormId]
        ) {
            setSelectedCustomForm(customForms.byId[selectedCompletedCustomForm.customFormId]);
        }
    }, [customForms]); // eslint-disable-line react-hooks/exhaustive-deps

    const onCompleteForm = () => {
        setShowBrowseCustomForms(true);
    };

    const onAddDocuments = () => {
        setSelectedUserDocuments(undefined);
        setShowEditUserDocuments(true);
    };

    const onCustomFormSelected = (customForm: CustomForm) => {
        setSelectedCustomForm(customForm);
        setSelectedCompletedCustomForm(undefined);
        setShowCompleteCustomForm(true);
    };

    const onOpenPdf = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, customForm: CustomForm, completedCustomForm: CustomFormCompleted) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedCustomForm(customForm);
        setSelectedCompletedCustomForm(completedCustomForm);
        setExportType('pdf');
    };

    return (<>
        <SeaNoData
            dataName="forms or documents"
            isLoading={!formsAndFiles}
            hasNoData={formsAndFiles && formsAndFiles.length === 0}
            isUsingFilter={false}
        />

        {
            (
                formsAndFiles && (
                    canEdit('crewParticulars') ||
                    (selectedUser && selectedUser.id === userId)
                )
            ) && (
                <div className="columns" style={{ marginBottom: '20px' }}>
                    <div className="right">
                        {canComplete('customForms') &&
                            <SeaButton onClick={(e) => onCompleteForm()}>
                                Complete Form
                            </SeaButton>
                        }
                        <SeaButton onClick={(e) => onAddDocuments()}>
                            Add Documents
                        </SeaButton>
                    </div>
                </div>
            )
        }

        <div className={`forms-and-files has-thumbs ${formsAndFiles && formsAndFiles.length > 0 ? 'reveal' : 'conceal'}`}>
            <div className="sea-row-history headings-history">
                <div></div>
                <div>Form / Document</div>
                <div>Vessel(s)</div>
                <div>Added by</div>
                <div>Date Added</div>
            </div>
            {formsAndFiles && formsAndFiles.length > 0 && formsAndFiles.map((item) => {
                let customForm: CustomForm | undefined = undefined;
                let completedCustomForm: CustomFormCompleted | undefined = undefined;
                let userDocument: UserDocument | undefined = undefined;
                if ((item as CustomFormCompleted).customFormId) {
                    customForm = customForms?.byId[(item as CustomFormCompleted).customFormId];
                    completedCustomForm = item as CustomFormCompleted;
                } else if ((item as UserDocument).name) {
                    userDocument = item as UserDocument;
                }
                if ((item as CustomFormCompleted)?.data && customForm === undefined) {
                    return undefined; // Missing customForm
                }
                return (
                    <div
                        key={item.id}
                        className={`sea-card sea-row-history clickable ${(completedCustomForm?.isDraft) ? 'draft' : ''}`}
                        onClick={(e) => {
                            if (customForm) {
                                console.log(`Viewing customFormsCompleted ${item.id}`);
                                setSelectedCustomForm(customForm);
                                setSelectedCompletedCustomForm(completedCustomForm);
                                setShowViewCompletedCustomForm(true);
                            } else {
                                console.log(`Viewing userDocuments item ${item.id}`);
                                setSelectedUserDocuments(userDocument);
                                setShowEditUserDocuments(true);
                            }
                        }}
                    >
                        <div>
                            {(customForm) ? (
                                <img
                                    alt="pdf"
                                    className="sea-file-image tiny file"
                                    src="/assets/file-pdf_tiny@2x.png"
                                    onClick={(e) => onOpenPdf(e, customForm as CustomForm, completedCustomForm as CustomFormCompleted)}
                                />
                            ) : (
                                <SeaFileImage files={item.files} size="tiny"/>
                            )}
                        </div>
                        <div className="truncate">
                            {completedCustomForm ?
                                `${customForm?.title} ${(completedCustomForm.isDraft) ? ' (DRAFT)' : ''}`
                                :
                                userDocument?.name
                            }
                        </div>
                        <div className="truncate">{formatVessels(item.vesselIds, vessels)}</div>
                        <div className="truncate">{renderFullNameForUserId(item.addedBy)}</div>
                        <div className="truncate">{formatDateShort(item.whenAdded)}</div>
                    </div>
                );
            })}
        </div>

        {showModal &&
            <>
                <BrowseCustomForms
                    showModal={showBrowseCustomForms}
                    setShowModal={setShowBrowseCustomForms}
                    level={2}
                    filter={{
                        forCrew: true
                    }}
                    onCustomFormSelected={onCustomFormSelected}
                />
                <ViewCompletedCustomForm
                    showModal={showViewCompletedCustomForm}
                    setShowModal={setShowViewCompletedCustomForm}
                    setShowCompleteCustomForm={setShowCompleteCustomForm}
                    customForm={selectedCustomForm}
                    selectedCompletedCustomForm={selectedCompletedCustomForm}
                    level={2}
                />
                <CompleteCustomForm
                    showModal={showCompleteCustomForm}
                    setShowModal={setShowCompleteCustomForm}
                    setShowBrowseCustomForms={setShowBrowseCustomForms}
                    level={3}
                    customForm={selectedCustomForm}
                    itemToUpdate={selectedCompletedCustomForm}
                    attachTo={null} // (user forms don't need to be associated specifically with crew particulars)
                    attachToId={selectedUser?.id}
                    forcePersonnelId={selectedUser?.id}
                />
                {exportType === 'pdf' && <CompletedCustomFormPdf
                    onCompleted={() => setExportType(undefined)}
                    customForm={selectedCustomForm}
                    completedCustomForm={selectedCompletedCustomForm}
                />}
                {selectedUser && <EditUserDocumentsModal
                    showModal={showEditUserDocuments}
                    setShowModal={setShowEditUserDocuments}
                    selectedUser={selectedUser}
                    itemToUpdate={selectedUserDocuments}
                    level={2}
                />}
            </>
        }
    </>);
};

export default UserFormsAndDocumentsTab;
