import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, increment, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, toInt, preventMultiTap, toFloat, deepClone, formatValue, formatTextAreaText } from '../../../../lib/util';
import { formatSeaDatetime, formatShortTimeDurationHrsMinsOnly, hours24ToMillis, addInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { getEngineName } from '../../../../shared-state/Core/vessel';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { ScheduledMaintenanceTask } from '../../../../shared-state/VesselMaintenance/maintenanceSchedule';
import { MaintenanceTaskCompleted } from '../../../../shared-state/VesselMaintenance/maintenanceTasksCompleted';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaTime from '../../../../components/SeaTime/SeaTime';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaSparePartsAdjustments from '../../../../components/SeaSparePartsAdjustments/SeaSparePartsAdjustments';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';


interface CompleteMaintenanceScheduleProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setShowParentModal: (showModal: boolean) => void,
    selectedItem: ScheduledMaintenanceTask,
    historyItemToUpdate: MaintenanceTaskCompleted | undefined,
    completedScheduledMaintenanceTasks?: MaintenanceTaskCompleted[],
    level?: number
}

const CompleteMaintenanceSchedule: React.FC<CompleteMaintenanceScheduleProps> = ({ showModal, setShowModal, setShowParentModal, selectedItem, historyItemToUpdate, completedScheduledMaintenanceTasks, level }) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const equipment = sharedState.equipment.use(showModal);
    const engines = sharedState.engines.use(showModal);
    const spareParts = sharedState.spareParts.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const licenseeSettings = sharedState.licenseeSettings.use();
    const [sparePartsStock, setSparePartsStock] = useState<{ [key: string]: { used?: number, added?: number } }>({});
    const initialSparePartsStock: { [key: string]: { used?: number, added?: number } } = useMemo(() => {
        if (historyItemToUpdate?.spareParts) {
            return deepClone(historyItemToUpdate.spareParts);
        } else {
            return {};
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyItemToUpdate?.id, showModal])

    const initialValues = useMemo(() => {
        if (historyItemToUpdate) {
            return {
                whenCompleted: historyItemToUpdate.whenCompleted ? formatSeaDatetime(historyItemToUpdate.whenCompleted) : '',
                engineHours: historyItemToUpdate.engineHours ? '' + historyItemToUpdate.engineHours : '',
                notes: historyItemToUpdate.notes ? '' + historyItemToUpdate.notes : '',
                task: historyItemToUpdate.task ? '' + historyItemToUpdate.task : selectedItem?.task ? '' + selectedItem.task : '',
                actualTime: historyItemToUpdate.actualTime ? formatShortTimeDurationHrsMinsOnly(historyItemToUpdate.actualTime) : ''
            };
        } else {
            return {
                whenCompleted: formatSeaDatetime(),
                engineHours: (selectedItem?.engineId) ? '' + engines?.byId[selectedItem.engineId]?.hours : '',
                notes: '',
                task: selectedItem?.task ? '' + selectedItem.task : '',
                actualTime: selectedItem?.estimatedTime ? formatShortTimeDurationHrsMinsOnly(selectedItem.estimatedTime) : ''
            };
        }
    }, [engines?.byId, historyItemToUpdate, selectedItem?.engineId, selectedItem?.task, selectedItem?.estimatedTime]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setSparePartsStock(historyItemToUpdate?.spareParts ? historyItemToUpdate.spareParts : {});
        setFiles(makeSeaFiles(historyItemToUpdate?.files));
    };

    const onClosed = () => {
        setSparePartsStock({});
    };

    const toggleShowMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting, isValid } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            whenCompleted: Yup.date().required().min(...notTooOld).max(new Date()),
            engineHours: (selectedItem?.intervalType === 'engineHours' || selectedItem?.intervalType === 'weekMonthAndHours') ? Yup.number().integer().min(0).max(1000000).required() : Yup.number().integer().min(0).max(1000000),
            notes: Yup.string().max(5000),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('completeMaintenanceSchedule')) { return; }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {

                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'maintenanceTasksCompleted');

                const useWeekMonth = (selectedItem?.intervalType === 'weekMonth' || selectedItem?.intervalType === 'weekMonthAndHours');
                const useEngineHours = (selectedItem?.intervalType === 'engineHours' || selectedItem?.intervalType === 'weekMonthAndHours');

                // Determine last service (i.e. latest history item)
                let whenLastService = toMillis(data.whenCompleted) as number;
                let engineHoursLastService = toInt(data.engineHours, undefined);
                if (historyItemToUpdate && historyItemToUpdate.whenCompleted === selectedItem?.whenLastService) {
                    // History item being edited is the latest one,
                    // so it's possible the second most recent one (if it exists) is now the latest
                    if (completedScheduledMaintenanceTasks && completedScheduledMaintenanceTasks.length > 1) {
                        if (completedScheduledMaintenanceTasks[1].whenCompleted > whenLastService) {
                            // The second most recent history item IS NOW the latest
                            whenLastService = completedScheduledMaintenanceTasks[1].whenCompleted;
                            engineHoursLastService = completedScheduledMaintenanceTasks[1].engineHours;
                        }
                    }
                } else {
                    // The most recent history item could still be the latest
                    if (selectedItem?.whenLastService && selectedItem.whenLastService > whenLastService) {
                        // The most recent history item IS the latest
                        whenLastService = selectedItem.whenLastService;
                        engineHoursLastService = selectedItem.engineHoursLastService;
                    }
                }
                if (selectedItem) {
                    // Make sure dateDue, and engineHoursDue are up to date
                    batch.set(
                        doc(firestore, 'scheduledMaintenanceTasks', selectedItem.id),
                        {
                            whenLastService: whenLastService,
                            engineHoursLastService: engineHoursLastService,
                            dateDue: useWeekMonth ? addInterval(whenLastService, selectedItem?.intervalWeekMonth).toISODate() : deleteValue,
                            engineHoursDue: useEngineHours ? (Number(engineHoursLastService) + (selectedItem?.intervalEngineHours || 0)) : deleteValue,
                            whenUpdated: batchTrace.whenAction,
                            updatedBy: userId,
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );
                }

                let equipmentName = 'Maintenance Task';
                if (
                    selectedItem &&
                    selectedItem.equipmentId &&
                    equipment?.byId[selectedItem.equipmentId]?.equipment
                ) {
                    equipmentName = equipment.byId[selectedItem.equipmentId]?.equipment;
                }
                // Update spare parts stock
                for (const [sparePartId, adjustment] of Object.entries(sparePartsStock)) {
                    const initialAdjustment = initialSparePartsStock[sparePartId] || { used: 0, added: 0 };
                    let added = toFloat(adjustment.added) - toFloat(initialAdjustment.added);
                    let used = toFloat(adjustment.used) - toFloat(initialAdjustment.used);
                    let adjustmentFloat = added - used;
                    if (adjustment) {
                        batch.set(
                            doc(firestore, 'spareParts', sparePartId),
                            {
                                quantity: increment(adjustmentFloat),
                                updatedVia: 'completeMaintenanceSchedule',
                                whenUpdated: batchTrace.whenAction,
                                updatedBy: userId,
                                touched: serverTimestamp(),
                            },
                            { merge: true }
                        );
                        onCollectionUpdated(batch, 'spareParts');
                    }
                }
                if (historyItemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = historyItemToUpdate.id;
                    batch.set(
                        doc(firestore, 'maintenanceTasksCompleted', historyItemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            notes: data.notes ? data.notes : deleteValue,
                            whenCompleted: toMillis(data.whenCompleted),
                            engineHours: toInt(data.engineHours, deleteValue),
                            engineId: selectedItem?.engineId,
                            spareParts: sparePartsStock ? sparePartsStock : deleteValue,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                            actualTime: hours24ToMillis(data.actualTime),
                        },
                        { merge: true }
                    );
                    saveFileRefs(batch, files, 'maintenanceTasksCompleted', historyItemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'maintenanceTasksCompleted',
                        historyItemToUpdate.id,
                        equipmentName,
                        [selectedItem?.vesselId],
                        undefined,
                        'scheduled'
                    );
                } else {
                    const newRef = doc(collection(firestore, 'maintenanceTasksCompleted'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    batch.set(newRef, {
                        completedBy: userId,
                        whenAdded: batchTrace.whenAction,
                        addedBy: userId,
                        vesselId: vesselId,
                        maintenanceTaskId: selectedItem?.id,
                        //system: selectedItem.system ? selectedItem.system : undefined,
                        equipmentId: selectedItem?.equipmentId ? selectedItem.equipmentId : undefined,
                        location: selectedItem?.location ? selectedItem.location : undefined,
                        task: values.task ? values.task : undefined,
                        whenCompleted: data.whenCompleted ? toMillis(data.whenCompleted) : undefined,
                        files: seaFilesToValue(files),
                        type: 'scheduled',
                        notes: data.notes ? data.notes : undefined,
                        engineHours: toInt(data.engineHours, undefined),
                        engineId: selectedItem?.engineId,
                        spareParts: sparePartsStock ? sparePartsStock : undefined,
                        state: 'completed',
                        touched: serverTimestamp(),
                        actualTime: hours24ToMillis(data.actualTime),
                    });

                    saveFileRefs(batch, files, 'maintenanceTasksCompleted', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'maintenanceTasksCompleted',
                        newRef.id,
                        equipmentName,
                        [selectedItem?.vesselId],
                        undefined,
                        'scheduled'
                    );
                }

                if (selectedItem?.engineId && data.engineHours && engines?.byId[selectedItem.engineId]?.hours && toInt(data.engineHours) > engines?.byId[selectedItem.engineId]?.hours && vesselId) {
                    // engineHours entered is ahead of current engine's hours, therefore we should update the engine
                    batch.set(
                        doc(firestore, 'engines', selectedItem.engineId),
                        {
                            hours: toInt(data.engineHours),
                            whenUpdated: batchTrace.whenAction,
                            updatedVia: 'completeMaintenanceSchedule',
                            updatedBy: userId,
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );
                    onCollectionUpdated(batch, 'engines');

                    logAction(
                        batch,
                        'Update',
                        'engines',
                        selectedItem.engineId,
                        `${engines?.byId[selectedItem.engineId].name}, ${data.engineHours} hours`,
                        [vesselId]
                    );
                    showToast(`Updated ${getEngineName(selectedItem.engineId)} to be ${data.engineHours} hours`);
                }

                onCollectionUpdated(batch, 'scheduledMaintenanceTasks');
                onCollectionUpdated(batch, 'maintenanceTasksCompleted');

                batchTrace.data = {
                    data,
                    files: seaFilesToValue(files),
                    selectedItem
                };
                batchTrace.save(`${historyItemToUpdate ? 'Update' : 'Add'} completed scheduled maintenance task ${selectedItem?.task}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
                if (!historyItemToUpdate) {
                    setTimeout(() => {
                        setShowParentModal(false);
                    }, 250);
                }
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload Completed Maintenance Schedule files`, error.message, error, {
                        files: seaFilesToValue(files),
                        data,
                        selectedItem,
                        historyItemToUpdate
                    });
                }
            });

        }
    });

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, historyItemToUpdate?.files)
        );
    }, [values, files]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const equipmentSpareParts = useMemo(() =>
        spareParts?.all?.filter((part) => {
            return part.equipmentIds?.includes(selectedItem?.equipmentId);
        }
        ), [spareParts, selectedItem?.equipmentId]);

    return (
        <SeaModal
            title={historyItemToUpdate ? 'Edit Completed Task' : 'Complete Task'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='semi-wide'
            level={level}
            onClosed={onClosed}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaGridCell label="Task Title" w="50">
                                {formatValue(formatTextAreaText(values?.task || selectedItem?.task))}
                            </SeaGridCell>
                        </IonCol>
                        <IonCol size={licenseeSettings?.hasMaintenanceTaskTime? "6" : "12"}>
                            <SeaDatetime
                                label="When Completed"
                                name="whenCompleted"
                                value={values.whenCompleted}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenCompleted ? errors.whenCompleted : ''}
                            />
                        </IonCol>
                        {
                            licenseeSettings?.hasMaintenanceTaskTime &&
                            <IonCol size="6">
                                <SeaTime
                                    label="Actual Time"
                                    help={{ text: 'This is the actual time of how long this task took to perform.' }}
                                    name="actualTime"
                                    value={values.actualTime}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    error={touched.actualTime ? errors.actualTime : ''}
                                />
                            </IonCol>
                        }

                        {(selectedItem?.intervalType === 'engineHours' || selectedItem?.intervalType === 'weekMonthAndHours') && selectedItem.engineId &&
                            <IonCol size="12">
                                <SeaInput
                                    label={`Hours when completed (${getEngineName(selectedItem.engineId)})`}
                                    name="engineHours"
                                    value={values.engineHours}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    type="number"
                                    inputmode="numeric"
                                    error={touched.engineHours ? errors.engineHours : ''}
                                />
                            </IonCol>
                        }
                        <IonCol size="12">
                            <SeaTextarea
                                label="Notes"
                                placeholder="Add notes..."
                                name="notes"
                                value={values.notes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                height={150}
                                inputmode="text"
                                error={touched.notes ? errors.notes : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="maintenanceTasksCompleted"
                                field="files"
                            />
                        </IonCol>
                        {equipmentSpareParts?.length ? (
                            <IonCol size="12" className={`expander ${showMoreInfo ? 'reduce' : ''}`}>
                                <div className="more-info-dropdown no-select" onClick={(e) => toggleShowMoreInfo()}>Spare Parts Used <SeaIcon icon={showMoreInfo ? 'moveUp' : 'moveDown'} /></div>
                            </IonCol>
                        ) : null}
                        {showMoreInfo && equipmentSpareParts?.length ? <IonCol size="12">
                            <SeaSparePartsAdjustments
                                sparePartsStock={sparePartsStock}
                                setSparePartsStock={setSparePartsStock}
                                initialSparePartsStock={initialSparePartsStock}
                                spareParts={equipmentSpareParts}
                            />
                        </IonCol> : null}
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" size="wide" type="submit">
                        {historyItemToUpdate ? 'Save Changes' : 'Submit'}
                    </SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default CompleteMaintenanceSchedule;
