import React, { useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { isPasswordOk } from '../../../lib/util';
import { useLocation, useNavigate } from 'react-router-dom';
import { functions, logPageView } from '../../../lib/firebase';
import { httpsCallable } from "firebase/functions";
import { useFormik } from 'formik';
import QueryString, { ParsedQuery } from 'query-string';
import Yup from '../../../lib/yup';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaFormError from '../../../components/SeaFormError/SeaFormError';
import CenteredBoxPageLayout from '../../../layouts/CenteredBoxPageLayout/CenteredBoxPageLayout';
import style from './ActivateAccount.module.css';

export const passwordRequirements = (
    <div style={{
        textAlign: 'left'
    }}>
        Your password must be either:
        <ul style={{
            marginBlockStart: '4px',
            marginBlockEnd: '4px'
        }}>
            <li>At least 16 characters long</li>
            <li>Or, at least 10 characters long and contain at least one lowercase, uppercase, and digit or special character</li>
        </ul>
    </div>
);

const ActivateAccount: React.FC = () => {
    const [qsParams, setQsParams] = useState<ParsedQuery<string> | null>(null); // query string params
    const [formError, setFormError] = useState('');
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location?.search) { // Process query string
            const qs = QueryString.parse(location.search);
            setQsParams(qs);
            if (qs.email) {
                setFieldValue('email', qs.email);
            }
        }
        logPageView('ActivateAccount');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const {handleSubmit, handleChange, handleBlur, values, errors, setFieldError, touched, setFieldValue} = useFormik({
        initialValues: {
            email: '',
            password: '',
            passwordConfirm: ''
        }, validationSchema: Yup.object({
            password: Yup.string().max(32).required(),
            passwordConfirm: Yup.string().max(32).required()
        }), onSubmit: (data) => {
            //console.log('formik onsubmit data:', data);
            if (!isPasswordOk(data.password)) {
                return;
            }
            if (data.password !== data.passwordConfirm) {
                setFieldError('passwordConfirm', 'Passwords do not match');
                return;
            }
            setLoading(true);
            setFormError('');

            //console.log('calling activateAccount...');
            httpsCallable(functions, 'activateAccount')({
                userId: qsParams?.userId,
                code: qsParams?.code,
                email: qsParams?.email,
                password: values.password
            }).then((result: any) => {
                //console.log('httpsCallable result', result);
                if (result && result.data && result.data.error) {
                    return Promise.reject(result.data.error);
                }
                navigate(`/account-activated?email=${ encodeURIComponent(qsParams?.email as string) }`);
            }).catch((error) => {
                //console.log('httpsCallable error', error);
                setFormError(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    });

    return (
        <CenteredBoxPageLayout>
            <div className={style.instructions}>
                To activate your Sea Flux account you just need to set a password.
            </div>
            <form onSubmit={handleSubmit}>
                <IonGrid className="auth-form-grid" style={{paddingTop: '8px'}}>
                    <IonRow>
                        <IonCol size="12">
                            <SeaInput
                                label="Email"
                                name="email"
                                value={values.email}
                                zone="white"
                                type="email"
                                inputmode="email"
                                disabled={true}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                label="Password"
                                name="password"
                                value={values.password}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="password"
                                disabled={loading}
                                error={
                                    touched.password ? (
                                        (values.password && !isPasswordOk(values.password))
                                        ?
                                        passwordRequirements
                                        :
                                        errors.password
                                    ) : ''
                                }
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                label="Confirm Password"
                                name="passwordConfirm"
                                value={values.passwordConfirm}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="password"
                                disabled={loading}
                                error={touched.passwordConfirm ? errors.passwordConfirm : ''}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="form-spacer"></div>
                <SeaFormError message={formError}/>
                <SeaButton zone="white" disabled={loading} type="submit" size="wide">Activate Account</SeaButton>
                <input type="submit" className="submit-enter"/>
            </form>
        </CenteredBoxPageLayout>
    );
};

export default ActivateAccount;
