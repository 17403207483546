import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useFormik } from 'formik';
import { deleteValue, firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from 'firebase/firestore';
import { haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { addInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { Drill } from '../../../../shared-state/VesselSafety/drills';
import { handleLinkUpdates } from '../../../../lib/links';
import { LinkType } from '../../../../shared-state/Core/links';
import Yup from '../../../../lib/yup';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import { renderFullName } from '../../../../shared-state/Core/users';
import SeaLinkMultiList from '../../../../components/SeaLinkMultiList/SeaLinkMultiList';

interface EditDrillProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    itemToUpdate?: Drill;
    level?: number;
    existingLinks?: LinkType[];
}

const EditDrill: React.FC<EditDrillProps> = ({ showModal, setShowModal, itemToUpdate, level = 1, existingLinks }) => {
    const users = sharedState.users.use(showModal);

    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const drillReports = sharedState.drillReports.use(showModal);

    const allUserIds = useMemo(() => {
        if (!users?.byVesselId || !vesselId) return [];

        const result = users.byVesselId[vesselId].map((user) => user.id).filter((userId) => !!userId) as string[];

        return result ?? [];
    }, [users, vesselId]);

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [links, setLinks] = useState<LinkType[]>([]);

    const invertSelectedCrewIds = (selectedCrewIds: string[], allUserIds: string[]) => {
        if (!allUserIds) return [];

        return allUserIds.filter((userId) => !selectedCrewIds.includes(userId));
    };

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                name: itemToUpdate.name ? '' + itemToUpdate.name : '',
                interval: itemToUpdate.interval ? '' + itemToUpdate.interval : '',
                crewAssignedToDrill: itemToUpdate.notAssignedTo ? invertSelectedCrewIds(itemToUpdate.notAssignedTo, allUserIds) : [...allUserIds],
            };
        } else {
            return {
                name: '',
                interval: '',
                crewAssignedToDrill: [...allUserIds],
            };
        }
    }, [itemToUpdate, allUserIds]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setLinks(existingLinks ?? []);
        setValues(initialValues);
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, resetForm, isSubmitting, isValid, validateForm } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().max(500).required(),
            interval: Yup.string().max(4).required(),
            crewAssignedToDrill: Yup.array().of(Yup.string()).min(1),
        }),
        onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('drill')) {
                return;
            }
            if (!vesselId) {
                throw new Error('No vesselId');
            }
            // Process form
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'drills');

            let itemId: string;

            if (itemToUpdate) {
                // Updating an existing drill
                itemId = itemToUpdate.id;

                batchTrace.exampleOperation = 'update';
                batchTrace.exampleDocId = itemToUpdate.id;

                // Update the crew data (dateDue and dateCompleted) on the drill
                const newCrewEntries = Object.entries(itemToUpdate.crew ?? {}).map(([crewId, crewData]) => {
                    return [
                        crewId,
                        {
                            ...crewData,
                            dateDue: addInterval(crewData.dateLastCompleted, data.interval).toISODate(),
                        },
                    ];
                });
                const newCrewData = Object.fromEntries(newCrewEntries);

                batch.set(
                    doc(firestore, 'drills', itemToUpdate.id),
                    {
                        updatedBy: userId,
                        whenUpdated: batchTrace.whenAction,
                        name: data.name,
                        interval: data.interval,
                        notAssignedTo: invertSelectedCrewIds(values.crewAssignedToDrill, allUserIds),
                        dateDue: itemToUpdate.dateLastCompleted ? addInterval(itemToUpdate.dateLastCompleted, data.interval).toISODate() : deleteValue, // Obsolete?
                        crew: newCrewData,
                        touched: serverTimestamp(),
                    } as Partial<Drill>,
                    { merge: true }
                );
                logAction(batch, 'Update', 'drills', itemToUpdate.id, data.name, [itemToUpdate.vesselId]);

                // If interval changed we'll need to recalculate dateDue for all previously created reports
                if (itemToUpdate.interval !== data.interval) {
                    const reports = drillReports?.byDrillId[itemToUpdate.id];

                    reports?.forEach((report) => {
                        if (report?.drills?.length > 0) {
                            const newDrills = [...report.drills];
                            let needsUpdating = false;
                            for (let i = 0; i < newDrills.length; i++) {
                                if (newDrills[i]?.id === itemToUpdate.id) {
                                    newDrills[i].dateDue = addInterval(report.dateCompleted, data.interval).toISODate();
                                    needsUpdating = true;
                                }
                            }

                            if (needsUpdating) {
                                batch.set(
                                    doc(firestore, 'drillReports', report.id),
                                    {
                                        drills: [...newDrills],
                                        touched: serverTimestamp(),
                                    },
                                    { merge: true }
                                );
                            }
                        }
                    });

                    onCollectionUpdated(batch, 'drillReports');
                }
            } else {
                // Creating a new drill
                const newRef = doc(collection(firestore, 'drills'));
                itemId = newRef.id;
                batchTrace.exampleOperation = 'create';
                batchTrace.exampleDocId = newRef.id;
                batch.set(newRef, {
                    vesselId: vesselId,
                    addedBy: userId,
                    name: data.name,
                    interval: data.interval,
                    whenAdded: batchTrace.whenAction,
                    crew: {}, // Set to an empty map, as this will get populated when drill reports are completed
                    notAssignedTo: invertSelectedCrewIds(values.crewAssignedToDrill, allUserIds),
                    state: 'active',
                    touched: serverTimestamp(),
                } as Omit<Drill, 'id'>);
                logAction(batch, 'Add', 'drills', newRef.id, data.name, [vesselId]);
            }

            onCollectionUpdated(batch, 'drills');

            handleLinkUpdates(batch, links, existingLinks, itemId, 'drills');

            batchTrace.data = {
                data,
                itemToUpdate,
            };
            batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} drill ${data?.name}`);
            batch
                .commit()
                .then(() => {
                    batchTrace.reportSuccess();
                })
                .catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

            setShowModal(false);
        },
        validateOnChange: true,
        validateOnBlur: true,
    });

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    // Validate on every change - required as interval was showing required error despite being defined...
    useEffect(() => {
        validateForm(values);
    }, [values, validateForm]);

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [initialValues, values]);

    const crewInvolvedOptions = useMemo(() => {
        if (!vesselId) {
            return;
        }
        return (
            users?.byVesselId[vesselId] &&
            users.byVesselId[vesselId].map((user) => {
                return {
                    id: user.id as string,
                    name: renderFullName(user),
                };
            })
        );
    }, [users, vesselId]);

    return (
        <SeaModal title={itemToUpdate ? 'Edit Drill' : 'Add New Drill'} showModal={showModal} setShowModal={setShowModal} isDirty={isModalDirty} onOpened={onOpened} level={level} size={'thin'}>
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInput label="Drill name" name="name" value={values.name} onchange={handleChange} onblur={handleBlur} zone="white" type="text" inputmode="text" error={touched.name ? errors.name : ''} />
                        </IonCol>
                        <IonCol size="12">
                            <SeaSelectInterval label="Interval" name="interval" value={values.interval} onchange={handleChange} onblur={handleBlur} error={touched.interval && errors.interval ? errors.interval : ''} />
                        </IonCol>
                        <IonCol size="12">
                            <SeaMultiSelect
                                label="Personnel Assigned to Drill"
                                values={values.crewAssignedToDrill}
                                setValues={(value) => setFieldValue('crewAssignedToDrill', value)}
                                options={crewInvolvedOptions}
                                useAllOption={false}
                                required={true}
                                requiredError="At least one crew needs to be present"
                                isSubmitting={isSubmitting}
                                emptyText="Not Set"
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <SeaLinkMultiList
                                selectedCollection="drills"
                                selectedItemId={itemToUpdate?.id || ''}
                                selectedItemVesselId={vesselId}
                                values={links}
                                setValues={setLinks}
                                linkOptions={['SOPs', 'companyDocuments', 'external']}
                                deleteable
                                confirmDelete
                                level={level + 1}
                                vesselIds={vesselId ? [vesselId] : undefined}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="grid-row-spacer"></div>
                <SeaFormHasErrors hasSubmitted={hasSubmitted} isValid={isValid} />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">
                        {itemToUpdate ? 'Update Drill' : 'Save New Drill'}
                    </SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditDrill;
