import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { formatInterval } from '../../lib/util';

type FetchedItem = {
    id: string;
    state: string;
    [key: string]: any;
} | null;

// This was necessary fr linking as we cannot rely on archived/deleted items being available
export const useFetchSingleItem = (collection: string, id: string, abort?: boolean, custom?: { shortName?: boolean }): FetchedItem | undefined => {
    const [item, setItem] = useState<FetchedItem>();
    
    useEffect(() => {
        setItem(undefined);
        if (collection && id && !abort && collection !== 'external') {
            const docRef = doc(firestore, collection, id);
            getDoc(docRef)
                .then((docSnap) => {
                    if (docSnap.exists()) {
                        const baseItem = {
                            id: docSnap.id,
                            state: docSnap.data().state,
                            ...docSnap.data()
                        } as any;
                        
                        // If the item is a safety check item, we need to fetch the safety check item from the safetyCheckItems state and use that name
                        if (collection === 'safetyCheckItems') {
                            let name = ''; 
                            let location = '';
                            let category = '';
                            return fetchSingleItem('vesselSafetyItems', baseItem.itemId).then((item) => {
                                name = item ? `${item.name}${custom?.shortName ? '' : ` (${formatInterval(baseItem.interval)})`}` : '';
                                return fetchSingleItem('vesselLocations', baseItem.locationId).then((item) => {
                                    location = item ? item.name : '';
                                    return fetchSingleItem('safetyCheckCategories', baseItem.categoryId).then((item) => {
                                        category = item ? item.name : '';
                                    });
                                });
                            }).then(() => {
                                setItem({
                                    ...baseItem,
                                    name: name,
                                    location: location,
                                    category: category,
                                });
                            });
                        } else {
                            setItem(baseItem);
                        }
                    } else {
                        setItem(null);
                    }
                })
                .catch((error) => {
                    if (error.code === 'permission-denied') {
                        console.log(`User doesn't have permission to read document in ${collection} with id ${id}`);
                        return null;
                    } else {
                        console.error(`Error fetching document from ${collection} with id ${id}:`, error);
                        return null;
                    }
                });
        }
    }, [abort, collection, id]);

    return item;
};

export const fetchSingleItem = (collection: string, id: string): Promise<FetchedItem> => {
    const docRef = doc(firestore, collection, id);
    return getDoc(docRef)
        .then((docSnap) => {
            if (docSnap.exists()) {
                return {
                    id: docSnap.id,
                    state: docSnap.data().state,
                    ...docSnap.data()
                };
            } else {
                return null;
            }
        })
        .catch((error) => {
            console.error(`Error fetching document from ${collection} with id ${id}:`, error);
            return null;
        });
};