import React, { useState, useMemo } from 'react';
import { formatValue } from '../../../../../lib/util';
import { formatDateShort, warnDays } from '../../../../../lib/datesAndTime';
import { canCreate } from '../../../../../shared-state/Core/userPermissions';
import { sharedState } from '../../../../../shared-state/shared-state';
import { UserType } from '../../../../../shared-state/Core/user';
import { CrewCertificate } from '../../../../../shared-state/Crew/crewCertificates';
import { renderCategoryName } from '../../../../../lib/categories';
import SeaIcon from '../../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../../components/SeaButton/SeaButton';
import SeaFileImage from '../../../../../components/SeaFileImage/SeaFileImage';
import SeaNoData from '../../../../../components/SeaNoData/SeaNoData';
import SeaStatusDueDate from '../../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import ViewCrewCertificate from '../../../CrewCertificates/ViewCrewCertificate/ViewCrewCertificate';
import EditCrewCertificate from '../../../CrewCertificates/EditCrewCertificate/EditCrewCertificate';
import './UserCertificatesTab.css';

interface UserCertificatesTabProps {
    showModal: boolean,
    selectedUser?: UserType
}

const UserCertificatesTab: React.FC<UserCertificatesTabProps> = ({
    showModal,
    selectedUser
}) => {
    const crewCertificates = sharedState.crewCertificates.use(showModal);
    const crewCertificateTitles = sharedState.crewCertificateTitles.use(showModal);
    const [showEditCertificateModal, setShowEditCertificateModal] = useState(false);
    const [showViewCertificateModal, setShowViewCertificateModal] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState<CrewCertificate>();


    const userCertificates = useMemo(() => {
        if (selectedUser?.id && crewCertificates) {
            if (
                crewCertificates?.holders &&
                crewCertificates.holders[selectedUser.id] &&
                crewCertificates.holders[selectedUser.id].certificates.length > 0
            ) {
                return crewCertificates.holders[selectedUser.id].certificates;
            }
            return [];
        }
        return undefined;
    }, [crewCertificates, selectedUser]);

   
    return (<>
        <SeaNoData
            dataName="certificates"
            isLoading={!userCertificates}
            hasNoData={userCertificates && userCertificates.length === 0}
            isUsingFilter={false}
        />
        
        {canCreate('crewCertificates') &&
            <div className="columns" style={{ marginBottom: '20px' }}>
                <div className="right">
                    <SeaButton onClick={(e) => setShowEditCertificateModal(true)}>
                        <SeaIcon slot="start" icon="add"/>
                        Add Certificate
                    </SeaButton>
                </div>
            </div>
        }

        <div className={`user-certificates has-thumbs has-status ${userCertificates && userCertificates.length > 0 ? 'reveal' : 'conceal'}`}>
            <div className="sea-row-history headings-history">
                <div></div>
                <div>Certificate</div>
                <div>Issued By</div>
                <div>Expiry</div>
                <div>Status</div>
                <div>Reminder</div>
            </div>
            {userCertificates && userCertificates.map((item) => {
                return (
                    <div
                        key={item.id}
                        className="sea-card sea-row-history clickable"
                        onClick={(e) => {
                            setSelectedCertificate(item);
                            setShowViewCertificateModal(true);
                        }}
                    >
                        <div><SeaFileImage files={item.files} size="tiny"/></div>
                        <div className="truncate">{renderCategoryName(item?.titleId, crewCertificateTitles)}</div>
                        <div className="truncate">{formatValue(item.issuedBy)}</div>
                        <div className={item.dateExpires ? '' : 'not-card'}>
                            {item.dateExpires ? formatDateShort(item.dateExpires) : '-'}
                        </div>
                        <div className={(item.dateExpires || item.state === 'missing') ? '' : 'not-card'}>
                            {item.state === 'missing' ? (
                                <SeaStatusDueDate isMissing />
                            ) : item.dateExpires ?  (
                                <SeaStatusDueDate dateDue={item.dateExpires} warnDays={warnDays.crewCertificates[0]}/>
                            ) : ''}
                        </div>
                        <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                    </div>
                );
            })}
            
            {showModal &&
                <>
                    <EditCrewCertificate
                        showModal={showEditCertificateModal}
                        setShowModal={setShowEditCertificateModal}
                        level={2}
                        forcedUserId={selectedUser?.id}
                    />
                    <ViewCrewCertificate
                        showModal={showViewCertificateModal}
                        setShowModal={setShowViewCertificateModal}
                        selectedItem={selectedCertificate}
                        level={2}
                    />
                </>
            }

        </div>
    </>);
};

export default UserCertificatesTab;
