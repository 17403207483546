import React, { useEffect, useState, useRef } from 'react';
import { createGesture } from '@ionic/core';
import { regions } from '../../lib/util';
import { renderFullName } from '../../shared-state/Core/users';
import { sharedState } from '../../shared-state/shared-state';
import { canView, canViewAny } from '../../shared-state/Core/userPermissions';
import SeaSideBarItem from '../SeaSideBarItem/SeaSideBarItem';
import FleetIcon from '../../assets/svg/side-bar/Fleet';
import HealthSafetyIcon from '../../assets/svg/side-bar/HealthSafety';
import CrewIcon from '../../assets/svg/side-bar/Crew';
import DashboardIcon from '../../assets/svg/side-bar/Dashboard';
import LogBookIcon from '../../assets/svg/side-bar/LogBook';
import SafetyIcon from '../../assets/svg/side-bar/Safety';
import SafetyFacilityIcon from '../../assets/svg/side-bar/SafetyFacility';
import MaintenanceIcon from '../../assets/svg/side-bar/Maintenance';
//import DocsIcon from '../../assets/svg/side-bar/Docs';
import VesselDocsIcon from '../../assets/svg/side-bar/VesselDocs';
import CompanyDocsIcon from '../../assets/svg/side-bar/CompanyDocs';
import ReportingIcon from '../../assets/svg/side-bar/Reporting';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaSideBar.css';

interface SeaSideBarProps {
    showMenu: boolean,
    setShowMenu: (showMenu: boolean) => void,
    openViewUserModal: (user: any) => void,
    attemptLogout: (e: any) => void
}

const SeaSideBar: React.FC<SeaSideBarProps> = ({showMenu, setShowMenu, openViewUserModal, attemptLogout}) => {
    const user = sharedState.user.use();
    const licenseeSettings = sharedState.licenseeSettings.use();
    const vesselId = sharedState.vesselId.use();
    const vessel = sharedState.vessel.use();
    const [animateIn, setAnimateIn] = useState(false);
    const [displayBg, setDisplayBg] = useState(false);
    const gestureRef = useRef<any>();
    const sideBarRef = useRef<any>(null);

    useEffect(() => {
        let isActive = true;
        if (showMenu) {
            setTimeout(() => {
                if (!isActive) return;
                setAnimateIn(showMenu);
            }, 5);
            setDisplayBg(true);
        } else {
            setAnimateIn(showMenu);
            setTimeout(() => {
                if (!isActive) return;
                setDisplayBg(false);
            }, 200 + 5);
        }
        return () => { isActive = false; };
    }, [showMenu]);

    const closeMenu = () => {
        // We only want to close the menu if the screen is thin enough to be in mobile mode
        if (window.innerWidth <= 850) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        gestureRef.current = createGesture({
            gestureName: 'Swipe Right',
            el: sideBarRef.current as any,
            direction: 'x',
            passive: false,
            gesturePriority: 60, // We want to be higher than back and foward swiping with priority=40
            onEnd: (detail) => {
                if (
                    detail.deltaX < -60 // must have swiped at least this amount to the right
                    && (detail.currentTime - detail.startTime) < 500 // must be fast enought
                ) {
                    // SWIPE LEFT!
                    closeMenu();
                    detail.event.preventDefault();
                    detail.event.stopPropagation();
                }
            }
        });
        gestureRef.current.enable(true);
        return () => {
            if (gestureRef.current) {
                gestureRef.current.destroy();
            }
        };
    }, []);

    return (<>
        <div className={`resize-side-bar ${showMenu ? 'show-menu' : 'hide-menu'}`} onClick={(e) => setShowMenu(!showMenu)}>
            <img src="/assets/right.png" alt="Next"/>
        </div>
        <div
            ref={sideBarRef}
            className={`sea-side-bar ${showMenu ? 'show-menu' : 'hide-menu'} ${animateIn ? 'animate-in' : ''} ${displayBg ? 'display-bg' : ''}`}
        >
            <div className="side-bar-logo no-select">
                <img
                    src="/assets/seaflux.png"
                    alt="Sea Flux Logo"
                />
            </div>
            <div style={{ height: '5px' }}></div>
            <SeaSideBarItem
                url="/fleet"
                name="Fleet Dashboard"
                section="fleet"
                closeMenu={closeMenu}
            >
                <FleetIcon style={{ marginTop: '3px' }}/>
            </SeaSideBarItem>
            <div className={`sea-side-bar-sub ${vesselId ? 'menu-on' : ''}`} style={{ marginTop: '5px' }}>
                {vessel && <>
                    <div className="sea-side-bar-line"></div>
                    <div className="vessel-name no-select">
                        {vessel?.name}
                    </div>
                    <SeaSideBarItem
                        name="Vessel Dashboard"
                        url={`/vessel/${vesselId}`}
                        subMenu
                        section="vessel"
                        closeMenu={closeMenu}
                    >
                        <DashboardIcon style={{ marginTop: '4px' }}/>
                    </SeaSideBarItem>
                    {!vessel?.isShoreFacility && 
                        <SeaSideBarItem
                            name="Logbook"
                            url={`/vessel/${vesselId}/logbook`}
                            subMenu
                            section="vesselLogbook"
                            requireAnyRole={['logbook']}
                            closeMenu={closeMenu}
                        >
                            <LogBookIcon style={{ marginTop: '3px' }}/>
                        </SeaSideBarItem>
                    }

                    <SeaSideBarItem
                        name="Safety"
                        url={`/vessel/${vesselId}/safety`}
                        subMenu
                        hasTabs
                        section="vesselSafety"
                        requireAnyRole={['safetyEquipmentChecks', 'safetyEquipmentList', 'drills']}
                        closeMenu={closeMenu}
                    >
                        {vessel?.isShoreFacility ?
                            <SafetyFacilityIcon />
                            :
                            <SafetyIcon style={{ marginTop: '2px' }}/>
                        }
                    </SeaSideBarItem>
                    <SeaSideBarItem
                        name="Safety Checks"
                        url={`/vessel/${vesselId}/safety?tab=SafetyEquipmentChecks`}
                        tab="SafetyEquipmentChecks"
                        section="vesselSafety"
                        requireAnyRole={['safetyEquipmentChecks']}
                        closeMenu={closeMenu}
                    />
                    {!vessel?.isShoreFacility && 
                        <SeaSideBarItem
                            name="Safety Equipment Expiries"
                            url={`/vessel/${vesselId}/safety?tab=SafetyEquipmentList`}
                            tab="SafetyEquipmentList"
                            section="vesselSafety"
                            requireAnyRole={['safetyEquipmentList']}
                            closeMenu={closeMenu}
                        />
                    }
                    <SeaSideBarItem
                        name="Drills"
                        url={`/vessel/${vesselId}/safety?tab=Drills`}
                        tab="Drills"
                        section="vesselSafety"
                        requireAnyRole={['drills']}
                        closeMenu={closeMenu}
                    />
                    <div className="side-bar-spacer"></div>

                    <SeaSideBarItem
                        name="Maintenance"
                        url={`/vessel/${vesselId}/maintenance`}
                        subMenu
                        hasTabs
                        section="vesselMaintenance"
                        requireAnyRole={['maintenanceSchedule', 'jobList', 'maintenanceHistory', 'sparePartsList']}
                        closeMenu={closeMenu}
                    >
                        <MaintenanceIcon style={{ marginTop: '4px' }}/>
                    </SeaSideBarItem>
                    <SeaSideBarItem
                        name="Maintenance Schedule"
                        url={`/vessel/${vesselId}/maintenance?tab=MaintenanceSchedule`}
                        tab="MaintenanceSchedule"
                        section="vesselMaintenance"
                        requireAnyRole={['maintenanceSchedule']}
                        closeMenu={closeMenu}
                    />
                    <SeaSideBarItem
                        name="Job List "
                        url={`/vessel/${vesselId}/maintenance?tab=JobList`}
                        tab="JobList"
                        section="vesselMaintenance"
                        requireAnyRole={['jobList']}
                        closeMenu={closeMenu}
                    />
                    <SeaSideBarItem
                        name={vessel?.isShoreFacility ? 'Inventory List' : 'Spare Parts List'}
                        url={`/vessel/${vesselId}/maintenance?tab=PartsList`}
                        tab="PartsList"
                        section="vesselMaintenance"
                        requireAnyRole={['sparePartsList']}
                        closeMenu={closeMenu}
                    />
                    <SeaSideBarItem
                        name="Equipment List"
                        url={`/vessel/${vesselId}/maintenance?tab=EquipmentList`}
                        tab="EquipmentList"
                        section="vesselMaintenance"
                        requireAnyRole={['maintenanceSchedule']}
                        closeMenu={closeMenu}
                    />
                    {!vessel?.isShoreFacility &&
                        <SeaSideBarItem
                            name="Equipment Manuals"
                            url={`/vessel/${vesselId}/maintenance?tab=EquipmentManuals`}
                            tab="EquipmentManuals"
                            section="vesselMaintenance"
                            requireAnyRole={['equipmentManualDocuments']}
                            closeMenu={closeMenu}
                        />
                    }
                    <SeaSideBarItem
                        name="Maintenance History"
                        url={`/vessel/${vesselId}/maintenance?tab=MaintenanceHistory`}
                        tab="MaintenanceHistory"
                        section="vesselMaintenance"
                        requireAnyRole={['maintenanceHistory']}
                        closeMenu={closeMenu}
                    />
                    <div className="side-bar-spacer"></div>

                    <SeaSideBarItem
                        name="Vessel Document Register"
                        url={`/vessel/${vesselId}/documents`}
                        subMenu
                        hasTabs
                        section="vesselDocuments"
                        requireAnyRole={['vesselCertificates', 'vesselDocuments', 'survey', 'standardOperatingProcedures', 'equipmentManualDocuments']}
                        closeMenu={closeMenu}
                    >
                        <VesselDocsIcon style={{ marginTop: '3px' }}/>
                    </SeaSideBarItem>
                    <SeaSideBarItem
                        name={vessel?.isShoreFacility ? 'Vehicle Expiries' : 'Vessel Certificates'}
                        url={`/vessel/${vesselId}/documents?tab=Certifications`}
                        tab="Certifications"
                        section="vesselDocuments"
                        requireAnyRole={['vesselCertificates']}
                        closeMenu={closeMenu}
                    />
                    <SeaSideBarItem
                        name={vessel?.isShoreFacility ? 'Documents' : 'Vessel Documents'}
                        url={`/vessel/${vesselId}/documents?tab=Documents`}
                        tab="Documents"
                        section="vesselDocuments"
                        requireAnyRole={['vesselDocuments']}
                        closeMenu={closeMenu}
                    />
                    {!vessel?.isShoreFacility &&
                        <SeaSideBarItem
                            name="Survey Documents"
                            url={`/vessel/${vesselId}/documents?tab=SurveyDocuments`}
                            tab="SurveyDocuments"
                            section="vesselDocuments"
                            requireAnyRole={['survey']}
                            closeMenu={closeMenu}
                        />
                    }
                    <SeaSideBarItem
                        name="Standard Operating Procedures"
                        url={`/vessel/${vesselId}/documents?tab=StandardOperatingProcedures`}
                        tab="StandardOperatingProcedures"
                        section="vesselDocuments"
                        requireAnyRole={['standardOperatingProcedures']}
                        closeMenu={closeMenu}
                    />
                    <div className="side-bar-spacer"></div>
                </>}
            </div>
            <div className="sea-side-bar-line"></div>
            
            <SeaSideBarItem
                name="Health &amp; Safety"
                url="/healthsafety"
                section="healthsafety"
                hasTabs
                //requireAnyRole={['incidentAccidentMedicalRegister', 'hazardRegister', 'healthSafetyMeetings', 'dangerousGoodsRegister']}
                closeMenu={closeMenu}
            >
                <HealthSafetyIcon style={{ marginTop: '3px' }}/>
            </SeaSideBarItem>
            {licenseeSettings?.hasIncidents ? (
                <SeaSideBarItem
                    name="Incident / Event Reports"
                    url={`/healthsafety?tab=Incidents`}
                    tab="Incidents"
                    section="healthsafety"
                    closeMenu={closeMenu}
                />
            ) : null}
            {licenseeSettings?.hasCorrectiveActions && canView('correctiveActions') && (
                <SeaSideBarItem
                    name="Corrective Actions"
                    url={`/healthsafety?tab=CorrectiveActions`}
                    tab="CorrectiveActions"
                    section="healthsafety"
                    closeMenu={closeMenu}
                />
            )}
            {licenseeSettings &&
                (licenseeSettings.riskRegister?.version > 1)
                ? (
                    <SeaSideBarItem
                        name="Risk Assessments"
                        url={`/healthsafety?tab=RiskRegister`}
                        tab="RiskRegister"
                        section="healthsafety"
                        requireAnyRole={['hazardRegister']}
                        closeMenu={closeMenu}
                    />
                ) : (
                    <SeaSideBarItem
                        name="Hazard Register"
                        url={`/healthsafety?tab=HazardRegister`}
                        tab="HazardRegister"
                        section="healthsafety"
                        requireAnyRole={['hazardRegister']}
                        closeMenu={closeMenu}
                    />
                )
            }
            <SeaSideBarItem
                name="Health &amp; Safety Meetings"
                url={`/healthsafety?tab=HealthSafetyMeetings`}
                tab="HealthSafetyMeetings"
                section="healthsafety"
                requireAnyRole={['healthSafetyMeetings']}
                closeMenu={closeMenu}
            />
            <SeaSideBarItem
                name="Dangerous Goods Register"
                url={`/healthsafety?tab=DangerousGoodsRegister`}
                tab="DangerousGoodsRegister"
                section="healthsafety"
                requireAnyRole={['dangerousGoodsRegister']}
                closeMenu={closeMenu}
            />
            <div className="sea-side-bar-line" style={{ marginTop: '5px' }}></div>

            {canViewAny([
                'companyPlan',
                'companyDocuments',
                'customForms'
            ]) &&
                <>
                    <SeaSideBarItem
                        name="Company Document Register"
                        url="/documents"
                        section="documents"
                        hasTabs={true}
                        //requireAnyRole={['customForms']}
                        closeMenu={closeMenu}
                    >
                        <CompanyDocsIcon style={{ marginTop: '3px' }}/>
                    </SeaSideBarItem>
                    <SeaSideBarItem
                        name={regions[licenseeSettings?.region as string]?.companyPlan}
                        url={`/documents?tab=Plan`}
                        tab="Plan"
                        section="documents"
                        requireAnyRole={['companyPlan']}
                        closeMenu={closeMenu}
                        //isNew={true}
                    />
                    <SeaSideBarItem
                        name="Company Documents"
                        url="/documents?tab=CompanyDocuments"
                        tab="CompanyDocuments"
                        section="documents"
                        requireAnyRole={['companyDocuments']}
                        closeMenu={closeMenu}
                    />
                    <SeaSideBarItem
                        name="Forms/Checklists"
                        url="/documents?tab=Forms"
                        tab="Forms"
                        section="documents"
                        requireAnyRole={['customForms']}
                        closeMenu={closeMenu}
                    />
                    <div className="sea-side-bar-line" style={{ marginTop: '5px' }}></div>
                </>
            }

            {canViewAny([
                'crewParticulars',
                'crewCertificates',
                'crewTraining',
                'contacts'
            ]) &&
                <>
                    <SeaSideBarItem
                        name="Crew"
                        url="/crew"
                        section="crew"
                        hasTabs
                        //requireAnyRole={['crewParticulars', 'crewCertificates', 'crewTraining', 'contacts']}
                        closeMenu={closeMenu}
                    >
                        <CrewIcon style={{ marginTop: '3px' }}/>
                    </SeaSideBarItem>
                    <SeaSideBarItem
                        name="Crew Particulars"
                        url="/crew?tab=CrewParticulars"
                        tab="CrewParticulars"
                        section="crew"
                        requireAnyRole={['crewParticulars']}
                        closeMenu={closeMenu}
                    />
                    <SeaSideBarItem
                        name="Crew Certificates"
                        url="/crew?tab=CrewCertificates"
                        tab="CrewCertificates"
                        section="crew"
                        requireAnyRole={['crewCertificates']}
                        closeMenu={closeMenu}
                    />
                    <SeaSideBarItem
                        name="Crew Training"
                        url="/crew?tab=CrewTraining"
                        tab="CrewTraining"
                        section="crew"
                        requireAnyRole={['crewTraining']}
                        closeMenu={closeMenu}
                    />
                    <SeaSideBarItem
                        name="Contacts/Suppliers"
                        url="/crew?tab=Contacts"
                        tab="Contacts"
                        section="crew"
                        requireAnyRole={['contacts']}
                        closeMenu={closeMenu}
                    />
                </>
            }

            {licenseeSettings?.hasReporting !== false &&
                <>
                    <div className="sea-side-bar-line" style={{ marginTop: '5px' }}></div>
                    <SeaSideBarItem
                        name="Reporting"
                        url="/reporting"
                        section="reporting"
                        hasTabs
                        //requireAnyRole={['crewParticulars', 'crewCertificates', 'crewTraining', 'contacts']}
                        closeMenu={closeMenu}
                        //isNew={true}
                    >
                        <ReportingIcon style={{ marginTop: '4px' }}/>
                    </SeaSideBarItem>
                    <div className="side-bar-spacer"></div>
                </>
            }

            <div className="side-bar-spacer"></div>

            <div className="account">
                <div className="divider"></div>
                <div className="my-profile">
                    <SeaSideBarItem
                        name={renderFullName(user)}
                        section="account"
                        onAction={(e: any) => {
                            openViewUserModal(user);
                            closeMenu();
                        }}
                    >
                        <SeaIcon icon="profile"/>
                    </SeaSideBarItem>
                </div>
                <div className="log-out">
                    <SeaSideBarItem
                        name="Log Out"
                        section="account"
                        onAction={(e: any) => {
                            attemptLogout(e);
                            //closeMenu();
                        }}
                    >
                        <SeaIcon icon="logout"/>
                    </SeaSideBarItem>
                </div>
            </div>

            <div className="sea-side-menu-bg" onClick={(e) => setShowMenu(false)}></div>
        </div>
    </>);
};

export default SeaSideBar;
