import React, { useMemo, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { formatValue, formatTextAreaText } from '../../../../lib/util';
import { addInterval, formatDate } from '../../../../lib/datesAndTime';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { DrillReport, DrillReportsData } from '../../../../shared-state/VesselSafety/drillReports';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { doc, FieldValue, serverTimestamp, WriteBatch } from 'firebase/firestore';
import { deleteValue, firestore, SplittableBatch } from '../../../../lib/firebase';
import { useMultipleLinks } from '../../../../shared-state/Core/multiItemLinks';
import { SeaLinks } from '../../../../components/SeaLinks/SeaLinks';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import { getImgSrcFromString } from '../../../../lib/files';
import { DrillCrewData } from '../../../../shared-state/VesselSafety/drills';
import { VesselDrillsData } from '../../../../shared-state/VesselSafety/vesselDrills';
import EditDrillReport from '../EditDrillReport/EditDrillReport';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaSignatureImage from '../../../../components/SeaSignatureImage/SeaSignatureImage';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import DrillReportPdf from '../../../../exports/VesselSafety/DrillReportPdf';

interface ViewDrillReportProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    selectedItem: DrillReport;
    level?: number;
    viewOnly?: boolean;
}

const ViewDrillReport: React.FC<ViewDrillReportProps> = ({ showModal, setShowModal, selectedItem, level = 1, viewOnly }) => {
    const vesselDrills = sharedState.vesselDrills.use(showModal);
    const drillReports = sharedState.drillReports.use(showModal);
    const drillIds = useMemo(() => selectedItem?.drills?.map((d) => d.id) || [], [selectedItem?.drills]);
    const links = useMultipleLinks(drillIds);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();
    const signature = selectedItem?.signature ? getImgSrcFromString(selectedItem.signature, 'sig') : undefined;

    const onEditItem = () => {
        setShowEditItemModal(true);
    };

    // const links = useMemo(() => {
    //     const _links = [] as LinkType[];
    //     if (selectedItem?.drills) {
    //         for (const drill of selectedItem.drills) {
    //             if (drill.links) {
    //                 _links.push(...drill.links);
    //             }
    //         }
    //     }
    //     return _links;
    // }, [selectedItem?.drills]);

    /* For each crew member on the deleted report, backdate each drill document to the previously completed drill  */
    const backdateDrillsForCrew = (batch: WriteBatch | SplittableBatch, deletedDrillReport: DrillReport, vesselDrills?: VesselDrillsData, drillReports?: DrillReportsData) => {
        if (!vesselDrills || !drillReports) { // This should not happen
           throw new Error('DrillReportsData is missing');
        }

        selectedItem.drills.forEach((drillReportDrill) => {
            const newCrewDataForDrill = {} as DrillCrewData | { [crewId: string]: FieldValue };
            const drill = vesselDrills.byId[drillReportDrill.id];

            selectedItem.crewInvolvedIds.forEach((crewId) => {
                const key = String(drill.id + crewId);
                const mostRecentReport = drillReports?.byDrillAndUser[key];
                const currentReportId = deletedDrillReport.id;

                // If this is not the most recent report, we don't need to update the drill object
                const isMostRecentReportForDrill = mostRecentReport?.report.id === currentReportId; // If this is the most recent report for the drill, we need to update the drill
                if (!isMostRecentReportForDrill) {
                    return;
                }

                const drillReportsForUserAndDrill = (drillReports?.byUserId[crewId] ?? [])
                    .filter((drillReport) => drillReport.state === 'active')
                    .filter((drillReport) => drillReport.drillIds?.includes(drill.id) || drillReport.drills.some((drill) => drill.id === drill.id));

                const previousReportForDrill = drillReportsForUserAndDrill.find((report) => report.dateCompleted < deletedDrillReport.dateCompleted);

                if (!previousReportForDrill) {
                    // If we don't have a previous report, then we need to treat the drill as missing
                    newCrewDataForDrill[crewId] = deleteValue;
                    return;
                }

                const dateDue = previousReportForDrill.dateDue ?? addInterval(previousReportForDrill.dateCompleted, drill.interval).toISODate();

                newCrewDataForDrill[crewId] = { dateLastCompleted: previousReportForDrill.dateCompleted, dateDue };
            });

            batch.set(
                doc(firestore, 'drills', drill.id),
                {
                    whenUpdated: serverTimestamp(),
                    crew: newCrewDataForDrill,
                },
                { merge: true }
            );
        });
    };

    return (
        <SeaModal
            title={`Drill Report by ${renderFullNameForUserId(selectedItem?.addedBy)}`}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            level={level}
            onOpened={() => {
                console.log(`Viewing drillReport ${selectedItem?.id}`);
            }}
            onPdf={exportType === 'pdf' && <DrillReportPdf onCompleted={() => setExportType(undefined)} selectedItem={selectedItem} signature={signature} />}
            setExportType={setExportType}
        >
            <div className="columns">
                <div className="rows">
                    <div>
                        <IonGrid className="form-grid view-item-with-image">
                            <IonRow>
                                <IonCol size="6">
                                    <div className="sea-label">Date Completed</div>
                                    <div>{formatDate(selectedItem?.dateCompleted)}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Location</div>
                                    <div>{formatValue(selectedItem?.location)}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">People Involved</div>
                                    <div>
                                        {selectedItem?.crewInvolvedIds?.map((crewId, index) => {
                                            if (renderFullNameForUserId(crewId)) {
                                                if (index === 0) {
                                                    return renderFullNameForUserId(crewId);
                                                } else {
                                                    return `, ${renderFullNameForUserId(crewId)}`;
                                                }
                                            } else {
                                                return '-';
                                            }
                                        })}
                                    </div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Drills Completed</div>
                                    <div>
                                        {selectedItem?.drills?.map((drill, index) => {
                                            if (index === 0) {
                                                return vesselDrills?.byId[drill.id] ? vesselDrills?.byId[drill.id].name : drill.name;
                                            } else {
                                                return vesselDrills?.byId[drill.id] ? `, ${vesselDrills?.byId[drill.id].name}` : `, ${drill.name}`;
                                            }
                                        })}
                                    </div>
                                </IonCol>
                                <IonCol size="12">
                                    <SeaLinks ids={drillIds} links={links} viewOnly={viewOnly} level={level + 1} />
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Scenario</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.scenario))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Equipment</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.equipment))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Further Training</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.furtherTraining))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Modification to current operating procedures</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.modification))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Signed off by</div>
                                    <SeaSignatureImage file={selectedItem?.signature} />
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </div>
                <div className="right">
                    <SeaFileImage files={selectedItem?.files} size="medium" showOthers />
                </div>
            </div>

            <div style={{ height: '60px' }}></div>

            <div className="columns">
                <div className="columns">
                    <RequirePermissions role="drills" level={permissionLevels.EDIT}>
                        <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}>
                            <SeaIcon icon="edit" slot="start" />
                            Edit Report
                        </SeaButton>
                        <RequirePermissions role="companyDocuments" level={permissionLevels.FULL}>
                            <div className="spacer-wide"></div>
                            <SeaLinkButton
                                mode="standard-link"
                                onClick={(e) => {
                                    console.debug(`DBG: [${Date.now()}] L:159 selectedIdem`, { selectedItem });

                                    deleteIfConfirmed(
                                        'drillReports',
                                        selectedItem.id,
                                        (batch: WriteBatch | SplittableBatch) => {
                                            // Set crew drill dates to those of the previous report
                                            backdateDrillsForCrew(batch, selectedItem, vesselDrills, drillReports)

                                            onCollectionUpdated(batch, 'drillReports', selectedItem.vesselId);
                                            onCollectionUpdated(batch, 'drills', selectedItem.vesselId);
                                            setShowModal(false);
                                        },
                                        'drill report',
                                        selectedItem?.drills?.map((drill) => drill.name).join(', ')
                                    );
                                }}
                            >
                                Delete Report
                            </SeaLinkButton>
                        </RequirePermissions>
                        <div className="spacer-wide"></div>
                    </RequirePermissions>
                </div>
            </div>

            {showModal && (
                <>
                    <EditDrillReport showModal={showEditItemModal} setShowModal={setShowEditItemModal} itemToUpdate={selectedItem} level={level + 1} vesselId={selectedItem.vesselId} />
                </>
            )}
        </SeaModal>
    );
};

export default ViewDrillReport;
