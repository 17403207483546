import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { Equipment } from './equipment';

export type EquipmentByVesselData = {
    [id: string]: Equipment
};

export const useEquipmentFromVessels = (vesselIds: string[] | undefined) => {
    const [equipmentData, setEquipmentData] = useState<EquipmentByVesselData>();

    useEffect(() => {
        setEquipmentData(undefined);
        if (vesselIds?.length) {
            return onSnapshot(
                query(
                    collection(firestore, 'equipment'),
                    where('vesselId', 'in', vesselIds)
                    // where('state', '==', 'active')
                ),
                (snapshot) => {
                    const byId: { [id: string]: Equipment } = {};
                    snapshot.forEach((doc) => {
                        const equipment = {
                            id: doc.id,
                            ...doc.data(),
                        } as Equipment;
                        byId[equipment.id] = equipment;
                    });
                    setEquipmentData(byId);
                },
                (error) => {
                    console.error('Error fetching equipment:', error);
                }
            );
        }

        return () => {
            setEquipmentData(undefined);
        };
    }, [vesselIds]);

    return equipmentData;
};