import React, { useEffect } from 'react';
import { setupIonicReact } from '@ionic/react';
import { Route, Routes } from 'react-router';
import { IonApp } from '@ionic/react';
import { ErrorBoundary } from "react-error-boundary";
import { Device } from '@capacitor/device';
import { getDayOffsetMillis, makeDateTime } from './lib/datesAndTime';
import { sharedState } from './shared-state/shared-state';
import { initPhysicalDeviceInfo } from './shared-state/Core/deviceInfo';
import { initAuthentication } from './shared-state/Core/authentication';
import { initOnlineStatus } from './shared-state/Core/onlineStatus';
import { initAppState } from './shared-state/Core/appState';
import { initDebugger } from './shared-state/General/debugView';
import { checkForIosRefreshProblem, setAppIsOkToRestart } from './shared-state/General/diagnoseRefreshProblems';
import ErrorsManager from './managers/ErrorsManager/ErrorsManager';
import AppLayout from './layouts/AppLayout/AppLayout';
import DebugView from './modals/Utilities/DebugView/DebugView';
import RequireSuperAdmin from './components/RequireSuperAdmin/RequireSuperAdmin';
import RequireUser from './components/RequireUser/RequireUser';
import UnknownUrl from './components/UnknownUrl/UnknownUrl';
import Login from './pages/AccountManagement/Login/Login';
import ActivateAccount from './pages/AccountManagement/ActivateAccount/ActivateAccount';
import AccountActivated from './pages/AccountManagement/AccountActivated/AccountActivated';
import ForgotPassword from './pages/AccountManagement/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/AccountManagement/ResetPassword/ResetPassword';
import RestartRequired from './pages/AccountManagement/Login/RestartRequired';
import OrchidAdmin from './pages/_Admin/Orchid/Orchid';
import FleetDashboard from './pages/FleetDashboard/FleetDashboard';
import HealthSafety from './pages/HealthSafety/HealthSafety';
import Crew from './pages/Crew/Crew';
import Reporting from './pages/Reporting/Reporting';
import VesselDashboard from './pages/VesselDashboard/VesselDashboard';
import VesselLogbook from './pages/VesselLogbook/VesselLogbook';
import VesselSafety from './pages/VesselSafety/VesselSafety';
import VesselMaintenance from './pages/VesselMaintenance/VesselMaintenance';
import VesselDocuments from './pages/VesselDocuments/VesselDocuments';
import CompanyDocuments from './pages/CompanyDocuments/CompanyDocuments';
import ConfirmDialogManager from './managers/ConfirmDialogManager/ConfirmDialogManager';
import ToastManager from './managers/ToastManager/ToastManager';
import AlertManager from './managers/AlertManager/AlertManager';
import ServerInfoManager from './managers/ServerInfoManager/ServerInfoManager';
import FileUploadManager from './managers/FileUploadManager/FileUploadManager';
import FileViewerManager from './managers/FileViewerManager/FileViewerManager';
import ContextualHelpManager from './managers/ContextualHelpManager/ContextualHelpManager';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.css';
import SuperAdmin from './pages/_Admin/SuperAdmin/SuperAdmin';
import RequireLicenseeSettings from './components/RequireLicenseeSettings/RequireLicenseeSettings';
import ErrorFallback from './modals/Utilities/ErrorFallback/ErrorFallback';

export const isDesktopPretendingToBeHybrid = false; // If true, desktop will adopt some behaviours of the hybrid app, such as full data and file syncing

// console.log(`>>> __appIsOkToRestart=${window.localStorage.getItem('__appIsOkToRestart')} __appLastState=${window.localStorage.getItem('__appLastState')}`);


setupIonicReact({
    mode: 'md',
    platform: {
        desktop: (win) => {
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
            return !isMobile;
        },
    }
});

export const refreshApp = (reason: string) => {
    console.log(`Refreshing app: ${reason}`);
    setAppIsOkToRestart(true, `Refreshing app: ${reason}`); // Let diagnoseIosRefresh know that the next startup is due to an intentional refresh
    window.location = window.location as any; // eslint-disable-line no-self-assign
};

const App: React.FC = () => {
    const debugView = sharedState.debugView.use()!;

    // App initialisation
    useEffect(() => {
        checkForIosRefreshProblem(); // Now is the time to check diagnoseIosRefresh
        setAppIsOkToRestart(false, 'App init'); // Let diagnoseIosRefresh know that if a restart occurs it isn't expected

        // Keep todayMillis fresh. This is used to trigger state change when the current day has changed. Set to be 00:00 for the current day.
        const todayMillisInterval = setInterval(() => {
            sharedState.todayMillis.set(getDayOffsetMillis(0));
            sharedState.today.set(makeDateTime().toISODate());
        }, 1 * 60 * 1000);


        // Init deviceId
        Device.getId().then((id: { identifier: string }) => {
            if (id && id.identifier) {
                sharedState.deviceId.set(id.identifier);
            }
        });

        // Init physicalDeviceInfo. 
        initPhysicalDeviceInfo();

        // Init appState. Listens for when the app goes between the foreground (active) and background (inactive).
        const cleanupAppState = initAppState();

        // Init online status. Listens for when the app switches between being online and offline.
        const cleanupOnlineStatus = initOnlineStatus();

        // Init Authentication. Listens for Firebase authentication events.
        const cleanupAuthentication = initAuthentication();

        // Init debugger. User can see DebugView if they type debug333, or click 7 times in a row from left to right at the top of the screen
        const cleanupDebugger = initDebugger();

        // Cleanup
        return () => {
            setAppIsOkToRestart(true, 'App cleanup'); // Let diagnoseIosRefresh that the next startup is due to a normal restart
            clearInterval(todayMillisInterval);
            try { cleanupAppState(); } catch (e) {}
            try { cleanupOnlineStatus(); } catch (e) {}
            try { cleanupAuthentication(); } catch (e) {}
            try { cleanupDebugger(); } catch(e) {}
        };
    }, []);

    return (<>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <IonApp>
                <AppLayout>
                    <Routes>

                        {/* Public routes */}
                        <Route
                            path="/login"
                            element={<Login/>}
                        />
                        <Route
                            path="/activate"
                            element={<ActivateAccount/>}
                        />
                        <Route
                            path="/account-activated"
                            element={<AccountActivated/>}
                        />
                        <Route
                            path="/forgot-password"
                            element={<ForgotPassword/>}
                        />
                        <Route
                            path="/reset-password"
                            element={<ResetPassword/>}
                        />
                        <Route
                            path="/restart"
                            element={<RestartRequired/>}
                        />


                        {/* Super Admin routes */}
                        <Route
                            path="/admin"
                            element={<RequireSuperAdmin><SuperAdmin/></RequireSuperAdmin>}
                        />
                        <Route
                            path="/orchid"
                            element={<RequireSuperAdmin><OrchidAdmin/></RequireSuperAdmin>}
                        />


                        {/* User routes */}
                        <Route
                            path="/fleet"
                            element={<RequireUser><FleetDashboard/></RequireUser>}
                        />
                        <Route
                            path="/healthsafety"
                            element={<RequireUser><HealthSafety/></RequireUser>}
                        />
                        <Route
                            path="/documents"
                            element={<RequireUser><CompanyDocuments/></RequireUser>}
                        />
                        <Route
                            path="/crew"
                            element={<RequireUser><Crew/></RequireUser>}
                        />
                        <Route
                            path="/reporting"
                            element={<RequireUser><RequireLicenseeSettings role='hasReporting'><Reporting/></RequireLicenseeSettings></RequireUser>}
                        />
                        <Route
                            path="/vessel/:vesselId"
                            element={<RequireUser><VesselDashboard/></RequireUser>}
                        />
                        <Route
                            path="/vessel/:vesselId/logbook"
                            element={<RequireUser><VesselLogbook/></RequireUser>}
                        />
                        <Route
                            path="/vessel/:vesselId/safety"
                            element={<RequireUser><VesselSafety/></RequireUser>}
                        />
                        <Route
                            path="/vessel/:vesselId/maintenance"
                            element={<RequireUser><VesselMaintenance/></RequireUser>}
                        />
                        <Route
                            path="/vessel/:vesselId/documents"
                            element={<RequireUser><VesselDocuments/></RequireUser>}
                        />


                        {/* Catch all route */}
                        <Route
                            path="*"
                            element={<UnknownUrl/>}
                        />

                    </Routes>
                </AppLayout>
            </IonApp>
            <FileUploadManager />
            <FileViewerManager />
            <AlertManager />
            <ToastManager />
            <ConfirmDialogManager />
            <ContextualHelpManager />
            <ErrorsManager />
            <ServerInfoManager />
        </ErrorBoundary>
        {debugView.show && <DebugView />}    
    </>);
};

export default App;
