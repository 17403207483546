import React, { useRef, useCallback, useEffect } from 'react';
import { IonSpinner, isPlatform } from '@ionic/react';
import { createGesture } from '@ionic/core';
import { deepClone } from '../../lib/util';
import { FileId, SeaFile, convertBase64toBlob, convertBlobToBase64, getContentType, getExtFromString, getFileNameFromString, getFileSrc, getFileSrcFromString, getFileTrappedSrc, getImgSrc, getImgSrcFromSeaFile, getImgSrcFromString, isImage, isPdf, stripBase64Prefix, toSafeFilename, writeBlobFile } from '../../lib/files';
import { FileOpener, FileOpenerPlugin } from '@capacitor-community/file-opener';
import { Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { alertMessage } from '../AlertManager/AlertManager';
import { SharedStateConfig, sharedState } from '../../shared-state/shared-state';
import { cachedFiles, CachedFileType, getCachedFileSrc, getCachedFileUri } from '../../shared-state/FileSyncSystem/cachedFiles';
import { CustomFormCompletedFile, SeaFileViewerFile } from '../../components/SeaFileImage/SeaFileImage';
import { MakeCustomFormPDF } from '../../exports/CustomForms/CompletedCustomFormPdf';
import { pdf } from '@react-pdf/renderer';
import { reportError } from '../../managers/ErrorsManager/ErrorsManager';
import { CustomFormVersionsData } from '../../shared-state/CompanyDocuments/CustomForms/customFormVersions';
import SeaButton from '../../components/SeaButton/SeaButton';
import SeaIcon from '../../components/SeaIcon/SeaIcon';
import SeaPDFViewer from '../../components/SeaPDFViewer/SeaPDFViewer';
import './FileViewerManager.css';
//base64js = require('base64-js')

//
// Handles viewing images or documents fullscreen
//   edit mode
//     only shows a single image and includes the ability to trash the file
//   view mode
//     show one or more images which can be cycled through forward and back
//

const isHybrid = isPlatform('hybrid');
const pauseMillis = 200;

type DownloadInfo = {
    href?: string,
    contentType?: string,
    openUri?: string,
    srcBase64?: string,
    uri?: string,
    isMissing?: boolean,
    blob?: Blob
};

type ImgInfo = {
    className: string,
    src: string,
    fileSrc: string,
    isImage: boolean,
    isPdf?: boolean,
};

export type FileViewer = {
    mode: 'view' | 'edit',
    files?: SeaFileViewerFile[], // Array of "<state><id>.<ext>" (view mode)
    editFile?: SeaFile, // File to view in edit mode
    fadeIn: boolean, // When set to true, triggers UI to visually fade in
    downloadInfo: DownloadInfo | undefined,
    // Exclusive to view mode
    imgs: ImgInfo[], // Contains up to 3 image descriptions used for image cycling
    imgsIndex: number, // Current img being used to display active image
    indexToView: number // Which of files[] is currently being viewed
    loading?: boolean, // Whether the file/s are still loading
};

const initImgs: () => ImgInfo[] = () => {
    return [
        { className: 'off', src: '', fileSrc: '', isImage: true },
        { className: 'off', src: '', fileSrc: '', isImage: true },
        { className: 'off', src: '', fileSrc: '', isImage: true }
    ];
};

export const fileViewerConfig: SharedStateConfig<FileViewer> = {
    isAlwaysActive: true,
    default: {
        mode: 'view',
        fadeIn: false,
        downloadInfo: undefined,
        imgs: initImgs(),
        imgsIndex: 0,
        indexToView: 0
    },
    notes: 'Describes the current file viewing state that should be displayed to the user.'
};

let cmdN = 0;
let whenLastGesture = 0;
let fileOpener: FileOpenerPlugin = FileOpener;

//export const viewFileFullscreen = (file: SeaFile) => {
let trashFile = (fileToTrash: SeaFile | undefined) => {};

export const handleMakePdfFromCustomForm = (selectedFile: CustomFormCompletedFile, customFormVersions?: CustomFormVersionsData, imagesRef?: React.MutableRefObject<{
    reference?: number;
    element?: JSX.Element;
    src: string | Promise<string>;
    fileName: string;
}[]>) => {
    if (typeof selectedFile === 'string' || selectedFile.pdfUri) {
        return Promise.resolve(selectedFile);
    }
    if (!customFormVersions) {
        return Promise.reject('customFormVersions not available');
    }

    const pdfElements = MakeCustomFormPDF(selectedFile.customForm, selectedFile.customFormCompleted, customFormVersions, imagesRef);

    if (!pdfElements) {
        return Promise.resolve(selectedFile);
    }

    // Create a PDF blob and convert it to base64
    const createPdfBlob = () => {
        return pdf(pdfElements).toBlob().then(
            (blob) => convertBlobToBase64(blob).then((base64) => {
                if (typeof base64 === 'string') {
                    return base64;
                } else {
                    return Promise.reject('Failed to convert PDF blob to base64');
                }
            })
        );
    };

    return createPdfBlob().catch((error) => {
        if (error && error === 'cancelled') {
        } else if (error instanceof Error && error.message.includes('Expected null or instance of Config')) {
            // Retry creating the PDF blob as it may have been cancelled
            if (selectedFile.pdfUri) {
                return Promise.resolve(selectedFile.pdfUri);
            } else {
                return createPdfBlob();
            }
        } else {
            reportError(`Failed to generate PDF from custom form`, error.message, error);
        }
    });
};


export const editFileFullscreen = (file: SeaFile, _trashFile: (fileToTrash: SeaFile | undefined) => void, _fileOpener?: FileOpenerPlugin) => {
    trashFile = _trashFile;
    if (_fileOpener) {
        fileOpener = _fileOpener;
    }
    const n = ++cmdN;
    const isStillActive = () => (n === cmdN); // Check that no other view/edit operation has started after this one making this one void

    sharedState.fileViewer.clear();

    const _downloadInfo = {
        href: '',
    } as DownloadInfo;

    const onFinally = () => {
        if (!isStillActive()) return;
        if (file.src === '/assets/offline@2x.png') {
            _downloadInfo.isMissing = true;
        }
        sharedState.fileViewer.set({
            ...sharedState.fileViewer.current!,
            mode: 'edit',
            editFile: file,
            downloadInfo: _downloadInfo,
            imgs: [{
                className: '',
                src: file.src || '',
                fileSrc: file.src || '',
                isImage: isImage(file.ext as string),
                isPdf: isPdf(file.ext as string),
            }]
        });
    };

    if (file.id) { // Could be a cached file we can use
        if (isPdf(file.ext as string)) {
            getCachedFileSrc(`${file.state}${file.id}.${file.ext}`, 'F').then((_src) => {
                file.src = `${file.state}${file.id}.${file.ext}`;
                if (isHybrid) {
                    _downloadInfo.contentType = 'application/pdf';
                    _downloadInfo.srcBase64 = _src;
                    return getCachedFileUri(`${file.state}${file.id}.${file.ext}`, 'F').then((uri: string) => {
                        _downloadInfo.uri = uri;
                    });
                } else {
                    if (isPlatform('ios')) { // force ios browsers to download
                        return getFileSrc(file.id as string, file.ext as string).then((_href: string) => {
                            _downloadInfo.href = _href;
                        });
                    } else {
                        _downloadInfo.href = _src;
                        return Promise.resolve();
                    }
                }
            }).catch((error) => {
                return getFileSrc(file.id as string, file.ext as string).then((_href: string) => {
                    file.src = `${file.state}${file.id}.${file.ext}`;
                    if (isHybrid) {
                        _downloadInfo.contentType = 'application/pdf';
                        _downloadInfo.srcBase64 = _href;
                        return getCachedFileUri(`${file.state}${file.id}.${file.ext}`, 'F').then((uri: string) => {
                            _downloadInfo.uri = uri;
                        });
                    } else {
                        if (isPlatform('ios')) { // force ios browsers to download
                            return getFileSrc(file.id as string, file.ext as string).then((_href: string) => {
                                _downloadInfo.href = _href;
                            });
                        } else {
                            _downloadInfo.href = _href;
                        }
                    }
                }).catch((e) => {
                    return getFileSrc(file.id as string, file.ext as string).then((_href) => {
                        _downloadInfo.href = _href;
                        return Promise.resolve();
                    });
                });
            }).finally(() => {
                onFinally();
            });
        } else if (
            isHybrid &&
            file.ext &&
            !isImage(file.ext) &&
            getContentType(file.ext)
        ) {
            // No need to load from the cache as we'll be using the local uri to open the file
            _downloadInfo.contentType = getContentType(file.ext);
            getImgSrcFromSeaFile(file, 'full').then((_url) => {
                file.src = _url;
                return getCachedFileUri(`${file.state}${file.id}.${file.ext}`, 'F');
            }).then((uri: string) => {
                _downloadInfo.openUri = uri;
            }).catch((error) => {
                return getFileSrc(file.id as string, file.ext as string).then((_href) => {
                    _downloadInfo.href = _href;
                });
            }).finally(() => {
                onFinally();
            });

        } else {
            let src: string;
            getCachedFileSrc(`${file.state}${file.id}.${file.ext}`, 'F').then((_src: string) => {
                src = _src;
                if (isImage(file.ext as string) || isPdf(file.ext as string)) {
                    return Promise.resolve(src);
                } else {
                    return getImgSrcFromSeaFile(file, 'full');
                }
            }).then((_fileSrc) => {
                file.src = _fileSrc;
                if (isHybrid) {
                    _downloadInfo.contentType = file.ext ? getContentType(file.ext) : undefined;
                    if (_downloadInfo.contentType) {
                        _downloadInfo.srcBase64 = src;
                        return getCachedFileUri(`${file.state}${file.id}.${file.ext}`, 'F').then((uri: string) => {
                            _downloadInfo.uri = uri;
                        });
                    } else {
                        _downloadInfo.srcBase64 = src;
                    }
                } else {
                    if (isPlatform('ios')) { // force ios browsers to download
                        return getFileSrc(file.id as string, file.ext as string).then((_href: string) => {
                            _downloadInfo.href = _href;
                        });
                    } else {
                        _downloadInfo.href = src;
                    }
                }
            }).catch((e) => {
                if (file.state === 0) {
                    file.src = getFileTrappedSrc('full');
                    _downloadInfo.isMissing = true;
                } else {
                    return getImgSrc(file.state as number, file.id as string, file.ext as string, 'full').then((_src) => {
                        file.src = _src;
                        if (isImage(file.ext as string) || isPdf(file.ext as string)) {
                            _downloadInfo.href = _src;
                        } else {
                            return getFileSrc(file.id as string, file.ext as string).then((_href: string) => {
                                _downloadInfo.href = _href;
                            });
                        }
                    });
                }
            }).finally(() => {
                onFinally();
            });
        }
    } else {
        // Handle new files that haven't been uploaded yet
        if (file.base64 && file.contentType) {
            file.src = `data:${file.contentType};base64,${file.base64}`;
            sharedState.fileViewer.set({
                ...sharedState.fileViewer.current!,
                mode: 'edit',
                editFile: file,
                imgs: [{
                    className: '',
                    src: file.src,
                    fileSrc: file.src,
                    isImage: isImage(file.ext as string),
                    isPdf: isPdf(file.ext as string),
                }]
            });
        } else {
            // Fallback to the existing behavior for files without base64 data
            getImgSrcFromSeaFile(file, 'full').then((_src) => {
                if (!isStillActive()) return;
                file.src = _src;
                sharedState.fileViewer.set({
                    ...sharedState.fileViewer.current!,
                    mode: 'edit',
                    editFile: file,
                    imgs: [{
                        className: '',
                        src: file.src,
                        fileSrc: file.src,
                        isImage: isImage(file.ext as string),
                        isPdf: isPdf(file.ext as string),
                    }]
                });
            });
        }
    }
    setTimeout(() => {
        if (!isStillActive()) return;
        sharedState.fileViewer.set({
            ...sharedState.fileViewer.current!,
            fadeIn: true
        });
    }, 100);
};

const getCurrentFileName = (files: SeaFileViewerFile[], index: number) => {
    if (typeof files[index] === 'string') {
        return getFileNameFromString(files[index] as string);
    } else {
        return (files[index] as { name: string }).name;
    }
};

export const viewFilesFullScreen = (files: SeaFileViewerFile[], index: number, overrideFileType?: CachedFileType) => {
    const n = ++cmdN;
    const isStillActive = () => (n === cmdN); // Check that no other view/edit operation has started after this one making this one void
    const curFile = typeof files[index] === 'string' ? files[index] as string : undefined;
    const customFile = (files[index] as CustomFormCompletedFile).pdfUri ? (files[index] as CustomFormCompletedFile) : undefined;

    let i = (sharedState.fileViewer.current!.imgsIndex + 1) % 3;
    const imgs = initImgs();
    imgs[i].isImage = isImage(getExtFromString(curFile));

    imgs[i].isPdf = customFile?.pdfUri ? true : isPdf(getExtFromString(curFile));
    imgs[i].className = '';
    imgs[(i+1) % 3].src = '/assets/blank.png';
    imgs[(i+2) % 3].src = '/assets/blank.png';
    imgs[(i+1) % 3].className = 'off';
    imgs[(i+2) % 3].className = 'off';

    sharedState.fileViewer.set({
        ...sharedState.fileViewer.default!,
        // If the files are still loading, the viewer will already be faded in
        fadeIn: sharedState.fileViewer.current!.loading = true ? true : false,
        mode: 'view',
        files: files,
        imgs: imgs,
        indexToView: index
    });

    // debugApp('File Viewer', 'Viewing files in fullscreen mode', {
    //     ...sharedState.fileViewer.default!,
    //     mode: 'view',
    //     files: files,
    //     imgs: imgs,
    //     indexToView: index,
    // });

    const _downloadInfo = {
        href: '',
    } as DownloadInfo;

    const onFinally = () => {
        if (!isStillActive()) return;
        // setImgsIndex(i);
        // setImgs(imgs);
        // setDownloadInfo(_downloadInfo);
        if (imgs[i].src === '/assets/offline@2x.png') {
            _downloadInfo.isMissing = true;
        }
        sharedState.fileViewer.set({
            ...sharedState.fileViewer.current!,
            imgsIndex: i,
            imgs: imgs,
            downloadInfo: _downloadInfo
        });
    };

    if (imgs[i].isPdf) {
        if (customFile?.pdfUri) {
            imgs[i].src = customFile.pdfUri;
            if (isHybrid) {
                _downloadInfo.contentType = 'application/pdf';
                _downloadInfo.srcBase64 = customFile.pdfUri.startsWith('data:application/pdf;base64,') ? customFile.pdfUri : undefined;
                
            } else {
                _downloadInfo.href = customFile.pdfUri;
            }
            onFinally();
        } else if (curFile) {
            getCachedFileSrc(curFile).then((_src) => {
                imgs[i].src = curFile;
                if (isHybrid) {
                    _downloadInfo.contentType = 'application/pdf';
                    _downloadInfo.srcBase64 = _src;
                    return getCachedFileUri(curFile).then((uri: string) => {
                        _downloadInfo.uri = uri;
                    });
                } else {
                    if (isPlatform('ios')) { // force ios browsers to download
                        //_downloadInfo.href = getFileSrcFromString(curFile);
                        // _downloadInfo.href = `/download/${encodeURIComponent(curFile)}`;
                        return getFileSrcFromString(curFile).then((_href: string) => {
                            _downloadInfo.href = _href;
                        });
                    } else {
                        _downloadInfo.href = _src;
                        return Promise.resolve();
                    }
                }
            }).catch((error) => {
                return getFileSrcFromString(curFile).then((_href: string) => {
                    imgs[i].src = curFile;
                    if (isHybrid) {
                        _downloadInfo.contentType = 'application/pdf';
                        _downloadInfo.srcBase64 = _href;
                        return getCachedFileUri(curFile, 'F').then((uri: string) => {
                            _downloadInfo.uri = uri;
                        });
                    } else {
                        if (isPlatform('ios')) { // force ios browsers to download
                            // _downloadInfo.href = `/download/${encodeURIComponent(curFile)}`;
                            return getFileSrcFromString(curFile).then((_href: string) => {
                                _downloadInfo.href = _href;
                            });
                        } else {
                            _downloadInfo.href = _href;
                        }
                    }
    
                }).catch((e) => {
                    return getFileSrcFromString(curFile).then((_href) => {
                        _downloadInfo.href = _href;
                        return Promise.resolve();
                    })
    
                })
            }).finally(() => {
                onFinally();
            });;

        }
    } else if (
        curFile &&
        isHybrid &&
        !imgs[i].isImage &&
        getContentType(getExtFromString(curFile))
    ) {
        // No need to load from the cache as we'll be using the local uri to open the file
        _downloadInfo.contentType = getContentType(getExtFromString(curFile));
        //imgs[i].src = getImgSrcFromString(curFile, 'full');
        getImgSrcFromString(curFile, 'full').then((_src) => {
            imgs[i].src = _src;
            return getCachedFileUri(curFile, overrideFileType ?? 'F');
        }).then((uri: string) => {
            _downloadInfo.openUri = uri;
        }).catch((error) => {
            //_downloadInfo.href = getFileSrcFromString(curFile);
            return getFileSrcFromString(curFile).then((_href: string) => {
                _downloadInfo.href = _href;
            });
        }).finally(() => {
            onFinally();
        });
    } else if (curFile) {
        let src: string;
        getCachedFileSrc(curFile, overrideFileType ?? 'F').then((_src) => {
            src = _src;
            if (imgs[i].isImage) {
                return Promise.resolve(src);
            } else {
                return getImgSrcFromString(curFile, 'full');
            }
        }).then((_imgSrc) => {
            imgs[i].src = _imgSrc;

            if (isHybrid) {
                _downloadInfo.contentType = getContentType(getExtFromString(curFile));
                if (_downloadInfo.contentType) {
                    _downloadInfo.srcBase64 = src;
                    return getCachedFileUri(curFile, overrideFileType ?? 'F').then((uri: string) => {
                        _downloadInfo.uri = uri;
                    });
                } else {
                    _downloadInfo.srcBase64 = src;
                }
            } else {
                if (isPlatform('ios')) { // force ios browsers to download
                    //_downloadInfo.href = getFileSrcFromString(curFile);
                    // _downloadInfo.href = `/download/${encodeURIComponent(curFile)}`;
                    return getFileSrcFromString(curFile).then((_href: string) => {
                        _downloadInfo.href = _href;
                    });
                } else {
                    _downloadInfo.href = src;
                }
            }
        }).catch((e) => {
            if (curFile[0] === '0') {
                imgs[i].src = getFileTrappedSrc('full');
                _downloadInfo.isMissing = true;
            } else {
                //imgs[i].src = getImgSrcFromString(filesToView[newIndex], 'full');
                return getImgSrcFromString(curFile, 'full').then((_src) => {
                    imgs[i].src = _src;
                    if (imgs[i].isImage) {
                        _downloadInfo.href = imgs[i].src;
                    } else {
                        //_downloadInfo.href = getFileSrcFromString(curFile);
                        return getFileSrcFromString(curFile).then((_href) => {
                            _downloadInfo.href = _href;
                        });
                    }
                }).catch((e) => {
                });
            }
        }).finally(() => {
            onFinally();
        });
    }

    setTimeout(() => {
        if (!isStillActive()) return;
        sharedState.fileViewer.set({
            ...sharedState.fileViewer.current!,
            fadeIn: true
        });
    }, 100);
};

const onChangeImage = (e: React.MouseEvent, change: number) => {
    e.stopPropagation();
    e.preventDefault();
    const n = cmdN;
    const isStillActive = () => (n === cmdN); // Check that this action is still relevant
    //setDownloadInfo(undefined);
    sharedState.fileViewer.set({
        ...sharedState.fileViewer.current!,
        downloadInfo: undefined
    });
    const fileViewer = sharedState.fileViewer.current!;
    if (fileViewer.mode === 'view' && fileViewer.files?.length) {
        let newIndex = fileViewer.indexToView + change;
        if (newIndex >= fileViewer.files.length) {
            newIndex = 0;
        } else if (newIndex < 0) {
            newIndex = fileViewer.files.length - 1;
        }
        //setIndexToView(newIndex);
        sharedState.fileViewer.set({
            ...sharedState.fileViewer.current!,
            indexToView: newIndex
        });

        let i = (fileViewer.imgsIndex + 1) % 3;
        const imgs = deepClone(fileViewer.imgs);
        imgs[fileViewer.imgsIndex].className = `slide-${((change > 0) ? 'left' : 'right')} animate`;
        if (typeof fileViewer.files[newIndex] === 'string') {
            imgs[i].isImage = isImage(getExtFromString(fileViewer.files[newIndex] as string));
            imgs[i].isPdf = isPdf(getExtFromString(fileViewer.files[newIndex] as string));
        } else {
            imgs[i].isImage = false;
            imgs[i].isPdf = true;
            imgs[i].isCustomForm = true;
        }
        //imgs[i].src = getImgSrcFromString(filesToView[newIndex], 'full');
        imgs[i].className = `slide-${((change > 0) ? 'right' : 'left')}`;
        imgs[(i+1) % 3].className = 'off';
        imgs[(i+1) % 3].src = '/assets/blank.png';
        sharedState.fileViewer.set({
            ...sharedState.fileViewer.current!,
            imgs: imgs
        });

        const _downloadInfo = {
            href: '',
        } as DownloadInfo;


        const onFinally = () => {
            setTimeout(() => {
                if (!isStillActive()) {
                    return;
                }
                if (imgs[i].src === '/assets/offline@2x.png') {
                    _downloadInfo.isMissing = true;
                }
                imgs[i].className = 'slide-center animate';
                sharedState.fileViewer.set({
                    ...sharedState.fileViewer.current!,
                    imgsIndex: i,
                    imgs: imgs,
                    downloadInfo: _downloadInfo,
                });
            }, isHybrid ? pauseMillis : 0);
        };

        const fileToView = sharedState.fileViewer.current!.files![newIndex];
        const customFile = (fileToView as CustomFormCompletedFile).pdfUri ? (fileToView as CustomFormCompletedFile) : undefined;
        const curFile = typeof fileToView === 'string' ? fileToView : undefined;
        if (imgs[i].isPdf) {
            if (customFile?.pdfUri) {
                imgs[i].src = customFile.pdfUri;
                if (isHybrid) {
                    _downloadInfo.contentType = 'application/pdf';
                    _downloadInfo.srcBase64 = customFile.pdfUri.startsWith('data:application/pdf;base64,') ? customFile.pdfUri : undefined;
                    // _downloadInfo.uri = customFile.pdfUri.startsWith('data:application/pdf;base64,') ? undefined : customFile.pdfUri;
                    
                } else {
                    _downloadInfo.href = customFile.pdfUri;
                }
                onFinally();
    
            } else if (curFile) {
                getCachedFileSrc(curFile).then((_src) => {
                    imgs[i].src = curFile;
                    if (isHybrid) {
                    _downloadInfo.contentType = 'application/pdf';
                    _downloadInfo.srcBase64 = _src;
                    return getCachedFileUri(curFile).then((uri: string) => {
                            _downloadInfo.uri = uri;
                        });
                    } else {
                        if (isPlatform('ios')) { // force ios browsers to download
                            return getFileSrcFromString(curFile).then((_href: string) => {
                            _downloadInfo.href = _href;
                        });
                    } else {
                        _downloadInfo.href = _src;
                    }
                    return Promise.resolve();
                }
                }).catch((error) => {
                    return getFileSrcFromString(curFile)
                    .then((_href: string) => {
                        imgs[i].src = curFile;
                        if (isHybrid) {
                            _downloadInfo.contentType = 'application/pdf';
                            _downloadInfo.srcBase64 = _href;
                            return getCachedFileUri(curFile, 'F').then((uri: string) => {
                                _downloadInfo.uri = uri;
                            });
                        } else {
                            if (isPlatform('ios')) { // force ios browsers to download
                                // _downloadInfo.href = `download:${_href}`;
                                return getFileSrcFromString(curFile).then((_href: string) => {
                                    _downloadInfo.href = _href;
                                });
                            } else {
                                _downloadInfo.href = _href;
                            }
                        }
                        return Promise.resolve();
                    }).catch((e) => {
                        return getFileSrcFromString(curFile).then((_href: string) => {
                            _downloadInfo.href = _href;
                            return Promise.resolve();
                        });                
                    })
                }).finally(() => {
                    onFinally();
                });
            }
        } else if (
            curFile &&
            isHybrid &&
            !imgs[i].isImage &&
            !imgs[i].isPdf &&
            getContentType(getExtFromString(curFile))
        ) {
            // No need to load from the cache as we'll be using the local uri to open the file
            _downloadInfo.contentType = getContentType(getExtFromString(curFile));
            getImgSrcFromString(curFile, 'full').then((_src) => {
                imgs[i].src = _src;
                return getCachedFileUri(curFile, 'F');
            }).then((uri: string) => {
                _downloadInfo.openUri = uri;
            }).catch((error) => {
                //_downloadInfo.href = getFileSrcFromString(filesToView[newIndex]);
                return getFileSrcFromString(curFile).then((_href: string) => {
                    _downloadInfo.href = _href;
                });
            }).finally(() => {
                onFinally();
            });
        } else if (curFile) {
            let src: string;
            getCachedFileSrc(curFile, 'F').then((_src) => {
                src = _src;
                if (imgs[i].isImage) {
                    return Promise.resolve(src);
                } else {
                    return getImgSrcFromString(curFile, 'full');
                }
            }).then((_imgSrc) => {
                imgs[i].src = _imgSrc;
                if (isHybrid) {
                    _downloadInfo.contentType = getContentType(getExtFromString(curFile));
                    if (_downloadInfo.contentType) {
                        _downloadInfo.srcBase64 = src;
                        return getCachedFileUri(curFile, 'F').then((uri: string) => {
                            _downloadInfo.uri = uri;
                        });
                    } else {
                        _downloadInfo.srcBase64 = src;
                    }
                } else {
                    if (isPlatform('ios')) { // force ios browsers to download
                        // _downloadInfo.href = `download:${src}`;
                        return getFileSrcFromString(curFile).then((_href: string) => {
                            _downloadInfo.href = _href;
                        });
                    } else {
                        _downloadInfo.href = src;
                    }
                }
            }).catch((e) => {
                if (curFile[0] === '0') {
                    imgs[i].src = getFileTrappedSrc('full');
                    _downloadInfo.isMissing = true;
                } else {
                    //imgs[i].src = getImgSrcFromString(filesToView[newIndex], 'full');
                    return getImgSrcFromString(curFile, 'full').then((_src) => {
                        imgs[i].src = _src;
                        if (imgs[i].isImage) {
                            _downloadInfo.href = imgs[i].src;
                        } else {
                            return getFileSrcFromString(curFile).then((_href) => {
                                _downloadInfo.href = _href;
                            });
                        }
                    });

                }
            }).finally(() => {
                onFinally();
            });
        }
    }
};


const exitFileViewer = (e: React.MouseEvent) => {
    const n = cmdN;
    const isStillActive = () => (n === cmdN); // Check that this action is still relevant
    if (e) {
        e.preventDefault();
        e.stopPropagation();
    }
    setTimeout(() => {
        if (!isStillActive()) return;
        if ((Date.now() - whenLastGesture) < 200) {
            return; // Dont close because we recently swiped instead
        }
        //setFadeIn(false);
        sharedState.fileViewer.set({
            ...sharedState.fileViewer.current!,
            fadeIn: false
        });
        setTimeout(() => {
            if (!isStillActive()) return;
            // setFileToView(undefined);
            // setFilesToView(undefined);
            // setIndexToView(0);
            sharedState.fileViewer.clear();
        }, 100);
    }, 50);
};

export const openOrShareFile = (file: SeaFileViewerFile) => {
    const n = cmdN;
    const isStillActive = () => (n === cmdN); // Check that this action is still relevant
    const downloadInfo = sharedState.fileViewer.current?.downloadInfo;
    if (!downloadInfo || !downloadInfo.srcBase64) return;
    downloadInfo.srcBase64 = stripBase64Prefix(downloadInfo.srcBase64);

    return writeBlobFile(
        convertBase64toBlob(downloadInfo.srcBase64, downloadInfo.contentType),
        `${toSafeFilename(typeof file === 'string' ? getFileNameFromString(file) : file.name)}`,
        Directory.Cache
    ).catch((error) => {
        alertMessage(`Failed to save file. ${error.message}`);
    }).then((uri) => {
        if (!isStillActive()) return;
        if (!uri) return;
        if (downloadInfo.contentType) {
            fileOpener.open({
                filePath: uri,
                contentType: downloadInfo.contentType ? downloadInfo.contentType : 'application/json',
                openWithDefault: true
            })
        } else {
            Share.share({
                title: typeof file === 'string' ? getFileNameFromString(file) : file.name,
                url: uri,
            });
        }
    });
};
const renderMissingInfo = (fileSrc: string | undefined, fileId: FileId | undefined) => {
    if (fileSrc && fileSrc.indexOf('offline') !== -1) {
        let shouldBeCached = false;
        const fileCacheEntry = cachedFiles[fileId!];
        if (fileCacheEntry) {
            Object.keys(fileCacheEntry[5]).forEach((fileType) => {
                if (fileType !== 'T' && fileCacheEntry[5][fileType as CachedFileType] === null) {
                    shouldBeCached = true; // Is waiting to be downloaded, therefore should be cached but isn't yet
                }
            });
        }
        return (
            <div className="sea-missing-file">
                This file is unavailable while offline
                <br/>
                <span style={{fontSize: '80%'}}>
                    {shouldBeCached ? '(Never got a chance to be cached when online)' : '(Your File Sync settings exclude it)'}
                </span>
            </div>
        );
    }
    return (
        <div className="sea-missing-file">
            This file is currently missing!
            <br/>
            <span style={{fontSize: '80%'}}>
                (It has not been uploaded yet)
            </span>
        </div>
    );
};

const FileViewerManager: React.FC = () => {
    const fileViewer = sharedState.fileViewer.use()!;
    const isMounted = useRef(false);
    const gesture = useRef<any>();
    const fullscreenRef = useRef<HTMLDivElement>(null);
    const rightRef = useRef<HTMLDivElement>(null);
    const leftRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);

    // Handle swipe left and right gestures
    useEffect(() => {
        if (fileViewer.fadeIn) {
            // Setup gesture
            setTimeout(() => {
                if (!isMounted.current) return;
                if (gesture.current) {
                    gesture.current.destroy();
                }
                if (sharedState.fileViewer.current!.mode !== 'view') {
                    // Only view mode uses gestures to swipe left and right
                    return;
                }
                if (fullscreenRef.current) {
                    gesture.current = createGesture({
                        gestureName: 'Swipe Left/Right',
                        el: fullscreenRef.current as any,
                        direction: 'x',
                        onEnd: (detail) => {
                            if (
                                (detail.currentTime - detail.startTime) < 500 // must be fast enough
                                //&& !isPlatform('desktop') // must not be desktop i.e. no mouse, just touch
                            ) {
                                if (detail.deltaX > 100) {
                                    leftRef.current?.click();
                                } else if (detail.deltaX < -100) {
                                    rightRef.current?.click();
                                }
                            }
                            whenLastGesture = Date.now();
                        }
                    });
                    gesture.current.enable(true);
                }
            }, 400);
        } else {
            // Cleanup gesture
            if (gesture.current) {
                gesture.current.destroy();
            }
        }
    }, [fileViewer.fadeIn]);

    const onTrashFile = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        //setFileToTrash(fileToView);
        trashFile(sharedState.fileViewer.current?.editFile);
        exitFileViewer(e);
    }, []);

    const renderDownloadButton = useCallback(() => {
        return (
            <SeaButton zone="white" transparent size='small'>
                {(fileViewer.downloadInfo && fileViewer.downloadInfo.href === '')
                    ? (
                        fileViewer.downloadInfo.contentType ? (
                            <>
                                <SeaIcon slot="start" icon="open" />
                                Open file
                            </>
                        ) : (
                            <>
                                <SeaIcon slot="start" icon="share" />
                                Share file
                            </>
                        )
                    ) : (
                        <>
                            <SeaIcon slot="start" icon="download" />
                            Download file
                        </>
                    )
                }
            </SeaButton>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileViewer?.downloadInfo, isHybrid]);

    return (
        <>
            {fileViewer.mode === 'edit' && fileViewer.editFile && (
                <div className={`no-select sea-fullscreen-view ${ fileViewer.fadeIn ? 'reveal' : 'conceal' } faster`} onClick={(e) => exitFileViewer(e)}>
                    {fileViewer.imgs[0].isPdf ? (
                        <SeaPDFViewer isFullScreen={true} file={fileViewer.imgs[0].src} key={fileViewer.imgs[0].src} onClick={(e) => exitFileViewer(e)} className={fileViewer.imgs[0].className} maxPages={1} />
                    ) : (
                        <img
                            className={(fileViewer.editFile.ext && !isImage(fileViewer.editFile.ext)) ? 'non-image' : ''}
                            src={fileViewer.editFile.src}
                            onClick={(e) => exitFileViewer(e)}
                            alt="Exit"
                        />
                    )}
                    <div className="control bottom left" style={{ padding: '0px 0px 40px 40px', textShadow: '0px 1px 2px #000', fontSize: '16px' }}>
                        {fileViewer.editFile.name}
                    </div>
                    <div className="control top right pushy">
                        <SeaIcon icon="close"/>
                    </div>
                    <div className="control top left" style={{ padding: '20px 0px 0px 30px' }}>
                        <SeaButton zone="white" transparent onClick={(e) => {onTrashFile(e);}}>
                            <SeaIcon slot="start" icon="trash" />
                            Remove
                        </SeaButton>
                    </div>

                    {fileViewer.downloadInfo && fileViewer.editFile.id &&
                        <div className="control bottom right" style={{ padding: '0px 40px 30px 0px' }}>
                            {fileViewer.downloadInfo.isMissing
                                ? (
                                    renderMissingInfo(fileViewer.editFile.src, fileViewer.editFile.id)
                                ) : (
                                    <a
                                        href={fileViewer.downloadInfo.href}
                                        download={fileViewer.editFile.name ? fileViewer.editFile.name : `${fileViewer.editFile.id}.${fileViewer.editFile.ext}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (fileViewer.downloadInfo && fileViewer.downloadInfo!.href === '') {
                                                e.preventDefault();
                                                if (fileViewer.downloadInfo.openUri) {
                                                    fileOpener.open({
                                                        filePath: fileViewer.downloadInfo.openUri,
                                                        contentType: fileViewer.downloadInfo.contentType ? fileViewer.downloadInfo.contentType : 'application/json',
                                                        openWithDefault: true
                                                    });
                                                } else if (fileViewer.editFile) {
                                                    openOrShareFile(fileViewer.editFile.name ? fileViewer.editFile.name : `${fileViewer.editFile.id}.${fileViewer.editFile.ext}`);
                                                }
                                            }
                                        }}
                                    >
                                        {renderDownloadButton()}
                                    </a>
                                )
                            }
                        </div>
                    }
                </div>
            )}
            {fileViewer.mode === 'view' && fileViewer.files?.length && fileViewer.indexToView >= 0 && fileViewer.indexToView < fileViewer.files.length &&
                <div ref={fullscreenRef} className={`no-select sea-fullscreen-view ${ fileViewer.fadeIn ? 'reveal' : 'conceal' } faster`} onClick={(e) => exitFileViewer(e)}>
                    {fileViewer.loading ? (
                        <>
                            <div className="file-manager-spinner-container">
                                <IonSpinner name="crescent" className="sea-spinner-2" />
                                <p className="file-manager-spinner-text">
                                    Converting custom forms to PDFs for viewing...
                                </p>
                            </div>
                            <div className="control top right pushy">
                                <SeaIcon icon="close"/>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={`box ${fileViewer.imgs[0].isPdf && fileViewer.imgsIndex === 0 ? 'pdf-fullscreen' : ''}`}>
                                {fileViewer.imgs[0].isPdf ? (
                                    <SeaPDFViewer 
                                        isFullScreen={true}
                                        file={fileViewer.imgs[0].src} 
                                        onClick={(e) => exitFileViewer(e)} 
                                        className={fileViewer.imgs[0].className} 
                                        maxPages={1} 
                                    />
                                ) : (
                                    <img
                                        alt={fileViewer.imgs[0].fileSrc}
                                        className={`${fileViewer.imgs[0].className} ${fileViewer.imgs[0].isImage ? '' : 'non-image'}`}
                                        src={fileViewer.imgs[0].src}
                                        onClick={(e) => exitFileViewer(e)}
                                        //alt="Exit"
                                    />
                                )}
                            </div>
                            <div className={`box ${fileViewer.imgs[1].isPdf && fileViewer.imgsIndex === 1 ? 'pdf-fullscreen' : ''}`}>
                                {fileViewer.imgs[1].isPdf ? (
                                    <SeaPDFViewer isFullScreen={true} file={fileViewer.imgs[1].src} onClick={(e) => exitFileViewer(e)} className={fileViewer.imgs[1].className} maxPages={1} />
                                ) : (
                                    <img
                                        alt={fileViewer.imgs[1].fileSrc}
                                        className={`${fileViewer.imgs[1].className} ${fileViewer.imgs[1].isImage ? '' : 'non-image'}`}
                                        src={fileViewer.imgs[1].src}
                                        onClick={(e) => exitFileViewer(e)}
                                        //alt="Exit"
                                    />
                                )}
                            </div>
                            <div className={`box ${fileViewer.imgs[2].isPdf && fileViewer.imgsIndex === 2 ? 'pdf-fullscreen' : ''}`}>
                                {fileViewer.imgs[2].isPdf ? (
                                    <SeaPDFViewer isFullScreen={true} file={fileViewer.imgs[2].src} onClick={(e) => exitFileViewer(e)} className={fileViewer.imgs[2].className} maxPages={1} />
                                ) : (
                                    <img
                                        alt={fileViewer.imgs[2].fileSrc}
                                        className={`${fileViewer.imgs[2].className} ${fileViewer.imgs[2].isImage ? '' : 'non-image'}`}
                                        src={fileViewer.imgs[2].src}
                                        onClick={(e) => exitFileViewer(e)}
                                        //alt="Exit"
                                    />
                                )}
                            </div>

                            <div className="control bottom left" style={{ padding: '0px 0px 40px 40px', textShadow: '0px 1px 2px #000', fontSize: '16px' }}>
                                {fileViewer.files.length > 1 &&
                                    `File ${fileViewer.indexToView + 1} of ${fileViewer.files.length}: `
                                }
                                {typeof fileViewer.files[fileViewer.indexToView] === 'string' ? getFileNameFromString(fileViewer.files[fileViewer.indexToView] as string) : (fileViewer.files[fileViewer.indexToView] as CustomFormCompletedFile).name}
                            </div>
                            <div className="control top right pushy">
                                <SeaIcon icon="close"/>
                            </div>
                            {fileViewer.downloadInfo &&
                                <div className="control bottom right" style={{ padding: '0px 40px 30px 0px' }}>
                                    {fileViewer.downloadInfo.isMissing
                                        ? (
                                            renderMissingInfo(fileViewer.imgs[fileViewer.imgsIndex].src, typeof fileViewer?.files?.[fileViewer.indexToView] === 'string' ? (fileViewer.files[fileViewer.indexToView] as string).substring(1,21) : undefined)
                                        ):(
                                            <a
                                                //href={getFileSrcFromString(filesToView[indexToView])}
                                                //href={imgs[1].src}
                                                href={`${fileViewer.downloadInfo.href}`}
                                                download={getCurrentFileName(fileViewer.files, fileViewer.indexToView)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (fileViewer.downloadInfo) {
                                                        let s = fileViewer.downloadInfo.href;
                                                        if (s && s.length > 10) {
                                                            s = s.substring(0,10);
                                                        }
                                                        if (fileViewer.downloadInfo.href === '') {
                                                            e.preventDefault();

                                                            if (fileViewer.downloadInfo.openUri) {
                                                                fileOpener.open({
                                                                    filePath: fileViewer.downloadInfo.openUri,
                                                                    contentType: fileViewer.downloadInfo.contentType ? fileViewer.downloadInfo.contentType : 'application/json',
                                                                    openWithDefault: true
                                                                });
                                                            } else if (fileViewer.files) {
                                                                openOrShareFile(fileViewer.files[fileViewer.indexToView]);
                                                            }
                                                        }
                                                    } 
                                                }}
                                            >
                                                {renderDownloadButton()}
                                            </a>
                                        )
                                    }
                                </div>
                            }
                            {fileViewer.files.length > 1 &&
                                <>
                                    <div ref={leftRef} className="control left prev-next pushy" onClick={(e) => onChangeImage(e, -1)}>
                                        <SeaIcon icon="left" forceFontSize="100px"/>
                                    </div>
                                    <div ref={rightRef} className="control right prev-next pushy" onClick={(e) => onChangeImage(e, 1)}>
                                        <SeaIcon icon="right" forceFontSize="100px"/>
                                    </div>
                                </>
                            }
                        </>
                    )}
                </div>
            }
        </>
    );
};

export default FileViewerManager;
