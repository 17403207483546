import React from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc, serverTimestamp } from "firebase/firestore";
import { formatMonth, formatYear } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { VesselCertificate } from '../../../../shared-state/VesselDocuments/vesselCertificates';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import './ArchiveVesselCertificate.css';

interface ArchiveVesselCertificateProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const ArchiveVesselCertificate: React.FC<ArchiveVesselCertificateProps> = ({showModal, setShowModal}) => {
    const userId = sharedState.userId.use(showModal);
    const archivedVesselCertificates = sharedState.archivedVesselCertificates.use(showModal);

    const onUndoArchive = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: VesselCertificate) => {
        event.stopPropagation();
        confirmAction('Are you sure you want to unarchive this certification?', 'Yes, unarchive').then(() => {
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'vesselCertificates', 'unarchive', item.id);

            batch.set(
                doc(firestore, 'vesselCertificates', item.id),
                {
                    unarchivedBy: userId,
                    whenUnarchived: batchTrace.whenAction,
                    state: 'active',
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            onCollectionUpdated(batch, 'vesselCertificates');
            logAction(
                batch,
                'Unarchive',
                'vesselCertificates',
                item.id,
                item?.title,
                [item.vesselId]
            );

            batchTrace.data = {
                item
            };
            batchTrace.save(`Unarchive vessel certificate ${item?.title}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            showToast('Vessel certificate is now active');
        }).catch(() => {});
    };

    const onDeleteCertificate = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: VesselCertificate) => {
        event.stopPropagation();
        confirmAction('Are you sure you want to delete this certification?', 'Yes, delete').then(() => {
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'vesselCertificates', 'delete', item.id);

            batch.set(
                doc(firestore, 'vesselCertificates', item.id),
                {
                    deletedBy: userId,
                    whenDeleted: batchTrace.whenAction,
                    state: 'deleted',
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            onCollectionUpdated(batch, 'vesselCertificates');
            logAction(
                batch,
                'Delete',
                'vesselCertificates',
                item.id,
                item?.title,
                [item.vesselId]
            );

            batchTrace.data = {item};
            batchTrace.save(`Delete vessel certificate ${item?.title}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            showToast('Vessel certificate has now been deleted');
        }).catch(() => {});
    };
    
    return (
        <SeaModal
            title="Archived"
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
        >
            {archivedVesselCertificates && Object.keys(archivedVesselCertificates).length === 0
                ? <div style={{marginTop: 20}}>No archived Certifications</div> 
                : <div className="vessel-certificates-archived">
                    <div className="sea-row-history headings-history">
                        <div>Date</div>
                        <div>Name</div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {archivedVesselCertificates?.categories.map((category: string) => {
                        return (
                            <React.Fragment key={category}>
                                <div className="category-heading">{formatYear(category)}</div>
                                {archivedVesselCertificates?.byCategory[category] && archivedVesselCertificates?.byCategory[category].map((item) => {
                                    return (
                                        <div key={item.id} className="sea-card sea-row-history">
                                            <div>{formatMonth(item.dateIssued)}</div>
                                            <div className="truncate-2">{item.title}</div>
                                            <div><SeaFileImage files={item.files} size="tiny"/></div>
                                            <div onClick={(e) => onUndoArchive(e, item)}><SeaIcon icon="undo" slot="icon-only" /></div>
                                            <div onClick={(e) => onDeleteCertificate(e, item)}><SeaIcon icon='trash' slot="icon-only" /></div>
                                        </div>   
                                    )
                                })}
                            </React.Fragment>
                        )
                    })}
                </div>
            }
        </SeaModal>
    );
};

export default ArchiveVesselCertificate;
