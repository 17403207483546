import React, { useCallback, useMemo, useState } from 'react';
import { LinkType } from '../../shared-state/Core/links';
import { getExternalUrl, getLinksByCollectionFromIds, renderLinkLabelFromCollection } from '../../lib/links';
import { Risk } from '../../shared-state/HealthSafety/risks';
import { VesselDocument } from '../../shared-state/VesselDocuments/vesselDocuments';
import { SOP } from '../../shared-state/VesselDocuments/vesselSOPS';
import { ScheduledMaintenanceTask } from '../../shared-state/VesselMaintenance/maintenanceSchedule';
import { Drill } from '../../shared-state/VesselSafety/drills';
import { canView, getPermissionRoleFromCollection, PermissionRole } from '../../shared-state/Core/userPermissions';
import { useFetchSingleItem } from '../../shared-state/Core/fetchSingleItem';
import { TrainingTask } from '../../shared-state/Crew/trainingTasks';
import { CompanyDocument } from '../../shared-state/CompanyDocuments/companyDocuments';
import { CustomForm } from '../../shared-state/CompanyDocuments/CustomForms/customForms';
import { SafetyCheckItem } from '../../shared-state/VesselSafety/safetyCheckItems';
import SeaGridCell from '../SeaGridCell/SeaGridCell';
import ViewRiskAssessment from '../../modals/HealthSafety/RiskRegister/ViewRiskAssessment/ViewRiskAssessment';
import ViewSOP from '../../modals/VesselDocuments/SOPs/ViewSOP/ViewSOP';
import ViewVesselDocument from '../../modals/VesselDocuments/VesselDocuments/ViewVesselDocument/ViewVesselDocument';
import ViewMaintenanceSchedule from '../../modals/VesselMaintenance/MaintenanceSchedule/ViewMaintenanceSchedule/ViewMaintenanceSchedule';
import ViewDrill from '../../modals/VesselSafety/Drills/ViewDrill/ViewDrill';
import ViewTrainingTask from '../../modals/Crew/CrewTraining/ViewTrainingTask/ViewTrainingTask';
import ViewCustomForm from '../../modals/CompanyDocuments/CustomForms/ViewCustomForm/ViewCustomForm';
import ViewCompanyDocument from '../../modals/CompanyDocuments/CompanyDocuments/ViewCompanyDocument/ViewCompanyDocument';
import ViewSafetyCheck from '../../modals/VesselSafety/SafetyEquipmentChecks/ViewSafetyCheck/ViewSafetyCheck';
import './SeaLinks.css';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface LinkSide {
    id: string;
    linkId: string;
    collection: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface SeaLinksProps<T> {
    links?: LinkType[];
    ids: string[];
    level?: number;
    viewOnly?: boolean;
    width?: '100' | '75' | '66' | '50' | '33' | '25';
}

export const SeaLinks = <T extends Record<string, any>>({ links, ids, level = 1, viewOnly, width = '33' }: SeaLinksProps<T>) => {
    const byCollection = getLinksByCollectionFromIds(links, ids)
    const [selectedLink, setSelectedLink] = useState<LinkSide>();
    const [selectedLinkItem, setSelectedLinkItem] = useState<T>();


    const handleSelected = useCallback((link: LinkSide, item?: T) => {
        setSelectedLinkItem(item);
        setSelectedLink(link);
    }, []);

    if (!byCollection || !links) {
        return null;
    }

    return (
        <>
            {Object.entries(byCollection).map(([collection, linkSides]) => {
                const role = getPermissionRoleFromCollection(collection);
                const hasPermission = collection === 'external' ? true : canView(role as PermissionRole);
                return (
                    <SeaGridCell key={collection} label={renderLinkLabelFromCollection(collection, true)} w={width}>
                        {hasPermission ? (
                            linkSides.map((link, index) => <SeaLinkItem key={link.id} link={link} collection={collection} viewOnly={viewOnly} handleSelected={handleSelected} index={index} totalItems={linkSides.length} />)
                        ) : (
                            <span className="sea-link text disabled">
                                {linkSides.length} x {renderLinkLabelFromCollection(collection)}
                            </span>
                        )}
                    </SeaGridCell>
                );
            })}
            
             <ViewSOP
                viewOnly
                selectedItem={selectedLinkItem && selectedLink?.collection === 'SOPs' ? (selectedLinkItem as unknown as SOP) : undefined}
                showModal={selectedLinkItem && selectedLink?.collection === 'SOPs' ? true : false}
                setShowModal={() => setSelectedLink(undefined)}
                level={level+1}
            />

            <ViewVesselDocument
                viewOnly
                selectedItem={selectedLinkItem && selectedLink?.collection === 'vesselDocuments' ? (selectedLinkItem as unknown as VesselDocument) : undefined}
                showModal={selectedLinkItem && selectedLink?.collection === 'vesselDocuments' ? true : false}
                setShowModal={() => setSelectedLink(undefined)}
                level={level+1}
            />

            <ViewCompanyDocument
                viewOnly
                selectedItem={selectedLinkItem && selectedLink?.collection === 'companyDocuments' ? (selectedLinkItem as unknown as CompanyDocument) : undefined}
                showModal={selectedLinkItem && selectedLink?.collection === 'companyDocuments' ? true : false}
                setShowModal={() => setSelectedLink(undefined)}
                level={level+1}
            />

            <ViewRiskAssessment
                viewOnly
                selectedRisk={selectedLinkItem && selectedLink?.collection === 'risks' ? (selectedLinkItem as unknown as Risk) : undefined}
                showModal={selectedLinkItem && selectedLink?.collection === 'risks' ? true : false}
                setShowModal={() => setSelectedLink(undefined)}
                setRiskMatrixModal={() => null}
                setShowEditItemModal={() => null}
                level={level+1}
            />

            <ViewMaintenanceSchedule
                viewOnly
                selectedItem={selectedLinkItem && selectedLink?.collection === 'scheduledMaintenanceTasks' ? (selectedLinkItem as unknown as ScheduledMaintenanceTask) : undefined}
                showModal={selectedLinkItem && selectedLink?.collection === 'scheduledMaintenanceTasks' ? true : false}
                setShowModal={() => setSelectedLink(undefined)}
                level={level+1}
            />

             <ViewDrill
                viewOnly
                selectedItem={selectedLinkItem && selectedLink?.collection === 'drills' ? (selectedLinkItem as unknown as Drill) : undefined}
                showModal={selectedLinkItem && selectedLink?.collection === 'drills' ? true : false}
                setShowModal={() => setSelectedLink(undefined)}
                level={level+1}
            />

            <ViewTrainingTask
                viewOnly
                selectedTask={selectedLinkItem && selectedLink?.collection === 'trainingTasks' ? (selectedLinkItem as unknown as TrainingTask) : undefined}
                showModal={selectedLinkItem && selectedLink?.collection === 'trainingTasks' ? true : false}
                setShowModal={() => setSelectedLink(undefined)}
                level={level+1}
            />

            <ViewCustomForm
                viewOnly
                selectedCustomForm={selectedLinkItem && selectedLink?.collection === 'customForms' ? (selectedLinkItem as unknown as CustomForm) : undefined}
                showModal={selectedLinkItem && selectedLink?.collection === 'customForms' ? true : false}
                setShowModal={() => setSelectedLink(undefined)}
                level={level+1}
            />

            <ViewSafetyCheck
                viewOnly
                selectedItem={selectedLinkItem && selectedLink?.collection === 'safetyCheckItems' ? (selectedLinkItem as unknown as SafetyCheckItem) : undefined}
                showModal={selectedLinkItem && selectedLink?.collection === 'safetyCheckItems' ? true : false}
                setShowModal={() => setSelectedLink(undefined)}
                level={level+1}
                initialValues={{
                    name: selectedLinkItem?.name,
                    location: selectedLinkItem?.location,
                    category: selectedLinkItem?.category,
                }}
            />
        </>
    );
};

interface SeaLinkItemProps<T> {
    link: LinkSide;
    collection: string;
    viewOnly?: boolean;
    handleSelected?: (link: LinkSide, item: T | undefined) => void;
    className?: string;
    index: number;
    totalItems: number;
}

export const SeaLinkItem = <T extends Record<string, any>>({
    link,
    collection,
    viewOnly,
    handleSelected,
    className,
    index,
    totalItems
}: SeaLinkItemProps<T> & { index: number; totalItems: number }) => {
    const item = useFetchSingleItem(link.collection, link.linkId, false, { shortName: true }) as T | undefined;
    const isDeleted = item === null || item?.state === 'deleted' || item?.state === 'archived';

    const style = useMemo(() => {
        if (index < totalItems - 1) {
            return { paddingRight: '6px' };
        }
        return {};
    }, [index, totalItems]);
    
    const getLabelFromItem = useCallback((item: Record<string, any>): string => {
        const labelMapping: Record<string, keyof typeof item> = {
            customForms: 'title',
            SOPs: 'title',
            vesselDocuments: 'title',
            companyDocuments: 'title',
            scheduledMaintenanceTasks: 'task',
            trainingTasks: 'task',
        };

        const field = labelMapping[collection] || 'name';
        return (item[field] || '') as string;
    }, [collection]);

    const label = useMemo((): string => {
        if (collection === 'external') {
            if (index < totalItems - 1) {
                return link.linkId + ', ';
            }
            return link.linkId;
        }
        
        if (item === null) {
            return '(Deleted)';
        }
        
        if (!item) {
            return '';
        }

        let result = getLabelFromItem(item);
        
        if (item.state === 'archived' || item.state === 'deleted') {
            result += ' (Deleted)';
        }

        if (index < totalItems - 1) {
            result += ', ';
        }

        return result;
    }, [collection, getLabelFromItem, item, link.linkId, index, totalItems]);

    if (label === '') {
        return null;
    }

    if (collection === 'external') {
        return (
            <a
                className={`sea-link text ${className}`}
                style={style}
                href={getExternalUrl(link.linkId)}
                target="_blank"
                rel="noopener noreferrer"
            >
                {label}
            </a>
        );
    }

    if (viewOnly || isDeleted) {
        return (
            <span className={`sea-link text ${className} disabled`} style={style}>
                {label}
            </span>
        );
    }

    return (
        <span
            className={`sea-link text ${className}`}
            style={style}
            onClick={() => handleSelected?.(link, item)}
        >
            {label}
        </span>
    );
};