import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { SurveyReport } from '../../../../shared-state/VesselDocuments/vesselSurveyReports';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditSurveyReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: SurveyReport,
}

const EditSurveyReport: React.FC<EditSurveyReportProps> = ({showModal, setShowModal, itemToUpdate, level}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                title: itemToUpdate.title ? ''+itemToUpdate.title : '',
                dateSurveyed: itemToUpdate.dateSurveyed ? itemToUpdate.dateSurveyed : '',
                surveyor: itemToUpdate.surveyor ? ''+itemToUpdate.surveyor : '',
                personnelPresent: itemToUpdate.personnelPresent ? ''+itemToUpdate.personnelPresent : '',
                location: itemToUpdate.location ? ''+itemToUpdate.location : '',
                inOrOutWater: itemToUpdate.inOrOutWater ? ''+itemToUpdate.inOrOutWater : '',
            };
        } else {
            return {
                title: '',
                dateSurveyed: formatSeaDate(),
                surveyor: '',
                personnelPresent: '',
                location: '',
                inOrOutWater: 'N/A',
            };
        }
    }, [itemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().max(500).required(),
            dateSurveyed: Yup.date().required().min(...notTooOld),
            surveyor: Yup.string().max(500),
            personnelPresent: Yup.string().max(500),
            location: Yup.string().max(500),
            inOrOutWater: Yup.string().max(3),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('surveyReport')) { return; }
            if (!vesselId) {
                throw new Error('vesselId is not defined');
            }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'surveyReports');

                if (itemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'surveyReports', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            title: data.title,
                            dateSurveyed: data.dateSurveyed ? data.dateSurveyed : deleteValue,
                            surveyor: data.surveyor ? data.surveyor : deleteValue,
                            personnelPresent: data.personnelPresent ? data.personnelPresent : deleteValue,
                            location: data.location ? data.location : deleteValue,
                            inOrOutWater: data.inOrOutWater ? data.inOrOutWater : deleteValue,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'surveyReports', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'surveyReports',
                        itemToUpdate.id,
                        data.title,
                        [itemToUpdate.vesselId]
                    );
                } else {
                    const newRef = doc(collection(firestore, 'surveyReports'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    batch.set(newRef, {
                        vesselId: vesselId,
                        addedBy: userId,
                        whenAdded: batchTrace.whenAction,
                        title: data.title,
                        dateSurveyed: data.dateSurveyed ? data.dateSurveyed : undefined,
                        surveyor: data.surveyor ? data.surveyor : undefined,
                        personnelPresent: data.personnelPresent ? data.personnelPresent : undefined,
                        location: data.location ? data.location : undefined,
                        inOrOutWater: data.inOrOutWater ? data.inOrOutWater : undefined,
                        state: 'active',
                        files: seaFilesToValue(files),
                        touched: serverTimestamp(),
                    });

                    saveFileRefs(batch, files, 'surveyReports', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'surveyReports',
                        newRef.id,
                        data.title,
                        [vesselId]
                    );
                }

                onCollectionUpdated(batch, 'surveyReports');

                batchTrace.data = {
                    data,
                    files: seaFilesToValue(files),
                    itemToUpdate
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} survey document ${data?.title}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload Survey Document files`, error.message, error, {
                        files: seaFilesToValue(files),
                        data,
                        itemToUpdate
                    });
                }
            });
        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [initialValues, values, files, itemToUpdate?.files]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Survey Document' : 'Add New Survey Document'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                          	<SeaInput
								label="Document Title"
								name="title"
								value={values.title}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.title ? errors.title : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaDate
                                label="Survey Date"
                                name="dateSurveyed"
                                value={values.dateSurveyed}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.dateSurveyed ? errors.dateSurveyed : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Surveyor"
								name="surveyor"
								value={values.surveyor}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.surveyor ? errors.surveyor : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Personnel Present"
								name="personnelPresent"
								value={values.personnelPresent}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.personnelPresent ? errors.personnelPresent : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Location"
								name="location"
								value={values.location}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.location ? errors.location : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
							<SeaSelect
                                label="In or Out of water"
                                name="inOrOutWater"
                                value={values.inOrOutWater}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.inOrOutWater ? errors.inOrOutWater : ''}
                            >
                                <IonSelectOption value="N/A">N/A</IonSelectOption>
                                <IonSelectOption value="In">In</IonSelectOption>
                                <IonSelectOption value="Out">Out</IonSelectOption>
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="12">

                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="surveyReports"
                                field="files"
                            />

                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Report' : 'Save New Report'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditSurveyReport;
