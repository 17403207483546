import React, { useMemo, useCallback, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../lib/firebase';
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { haveValuesChanged } from '../../../lib/util';
import { sharedState } from '../../../shared-state/shared-state';
import { reportError } from '../../../managers/ErrorsManager/ErrorsManager';
import { formatSeaDatetime, makeDateTime, toMillis } from '../../../lib/datesAndTime';
import { showToast } from '../../../managers/ToastManager/ToastManager';
import { alertMessage } from '../../../managers/AlertManager/AlertManager';
import { ServerEvent } from '../../../shared-state/Admin/useServerEvents';
import { confirmAction } from '../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import SeaModal from '../../../components/SeaModal/SeaModal';
import Yup from '../../../lib/yup'
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaTextarea from '../../../components/SeaTextarea/SeaTextarea';
import SeaDatetime from '../../../components/SeaDatetime/SeaDatetime';
import SeaLinkButton from '../../../components/SeaLinkButton/SeaLinkButton';
import { renderMessageContent } from '../../../managers/ServerInfoManager/ServerInfoManager';

interface EditServerMessageModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedEvent?: ServerEvent
}

const EditServerMessageModal: React.FC<EditServerMessageModalProps> = ({showModal, setShowModal, selectedEvent}) => {
    const [loading, setLoading] = useState(false);
    const superAdmin = sharedState.superAdmin.use(showModal);

    const isFormDisabled = (loading || selectedEvent?.state === 'deleted');

    const initialValues = useMemo(() => {
        if (selectedEvent) {
            return {
                title: selectedEvent.title ? selectedEvent.title : '',
                content: selectedEvent.content ?? '',
                whenExpires: formatSeaDatetime(selectedEvent.whenExpires),
            };
        } else {
            return {
                title: '',
                content: '',
                whenExpires: formatSeaDatetime(makeDateTime(Date.now() + (24 * 60 * 60 * 1000)).set({ minute: 0, second: 0 })),
            };
        }
    }, [selectedEvent]);

    const onOpened = () => {
        resetForm();
        setValues(initialValues);
    }

    const updateServerInfoMessages = useCallback((now: number) => {
        return getDocs(
            query(
                collection(firestore, 'serverEvents'),
                where('state', '==', 'active'),
                where('type', '==', 'message'),
                where('whenExpires', '>', now)
            )
        ).then((snap) => {
            const messages = snap.docs.map((doc) => {
                return {
                    whenSent: doc.data().whenEvent,
                    whenExpires: doc.data().whenExpires,
                    title: doc.data().title,
                    content: doc.data().content
                };
            });
            messages.sort((a, b) => a.whenSent - b.whenSent);
            console.log(`New serverInfo.messages`, messages);
            return setDoc(
                doc(firestore, 'global', 'serverInfo'),
                { messages },
                { merge: true }
            );
        }).then(() => {
            console.log(`serverInfo updated!`);
        });
    }, []);

    const onDelete = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();
        const now = Date.now();
        return confirmAction('Are sure you want to delete this message?').then(() => {
            return setDoc(
                doc(firestore, 'serverEvents', selectedEvent!.id),
                {
                    state: 'deleted',
                    whenDeleted: now,
                    deletedBy: superAdmin!.id
                },
                { merge: true }
            );
        }).then(() => {
            return updateServerInfoMessages(now);
        }).then(() => {
            console.log('Delete it!');
            showToast('Message deleted!');
            setShowModal(false);
        });
    }, [selectedEvent, setShowModal, superAdmin, updateServerInfoMessages]);

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().max(500).required(),
            content: Yup.string().max(10000).required()
        }), onSubmit: (data) => {
            const now = Date.now();
            return confirmAction(`Are you sure you want to ${selectedEvent ? 'update this message?' : 'show this message to every single Sea Flux user?'}`).then(() => {
                setLoading(true);
                const batch = splittableBatch(firestore, 20 - 0);

                if (selectedEvent) {
                    // Update existing message
                    batch.set(
                        doc(firestore, 'serverEvents', selectedEvent.id),
                        {
                            whenExpires: toMillis(values.whenExpires),
                            whenUpdated: now,
                            updatedBy: superAdmin?.id,
                            state: 'active',
                            title: values.title,
                            content: values.content,
                        },
                        { merge: true }
                    );
                } else {
                    // New message
                    const newRef = doc(collection(firestore, 'serverEvents'));
                    batch.set(newRef, {
                        type: 'message',
                        isPending: false, // We are going to do it right now!
                        whenEvent: now,
                        whenExpires: toMillis(values.whenExpires),
                        whenAdded: now,
                        addedBy: superAdmin?.id,
                        state: 'active',
                        title: values.title,
                        content: values.content,
                    });
                }

                batch.commit().then(() => {
                    console.log('saved message');
                }).catch((error) => {
                    console.log('error! error.code', error.code);
                    reportError(`Failed to ${selectedEvent ? 'update' : 'send'} message`, error.message, error, {
                        values
                    });
                });
            }).then(() => {
                return updateServerInfoMessages(now);
            }).then(() => {
                showToast(`Message ${selectedEvent ? 'updated' : 'sent'}!`);
                setShowModal(false);
            }).catch((error) => {
                alertMessage(`Failed to ${selectedEvent ? 'update' : 'send'} message!`);
                console.error(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    });

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues)
    }, [initialValues, values]);

    return (
        <SeaModal
            title={selectedEvent ? (selectedEvent.state === 'deleted' ? 'Deleted Message' : 'Message') : 'Send New Message'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInput
                                label="Title"
                                name="title"
                                value={values.title}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.title ? errors.title : ''}
                                disabled={isFormDisabled}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Content"
                                name="content"
                                value={values.content}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                height={400}
                                error={touched.content ? errors.content : ''}
                                disabled={isFormDisabled}
                            />
                            <div style={{ textAlign: 'right' }}>
                                <SeaButton mini disabled={isFormDisabled} onClick={() => {
                                    setFieldValue('content', values.content += `{datetime:${makeDateTime().set({ minute: 0, second: 0, millisecond: 0 }).toISO()}}`);
                                }}>
                                    Add localised time
                                </SeaButton>
                                <SeaButton mini disabled={isFormDisabled} onClick={() => {
                                    alertMessage(renderMessageContent(values.content));
                                }}>
                                    Preview
                                </SeaButton>
                            </div>
                        </IonCol>
                        <IonCol size="12">
                            <SeaDatetime
                                label="Message Expires"
                                name="whenExpires"
                                value={values.whenExpires}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={errors.whenExpires ? errors.whenExpires : ''}
                                required={true}
                                disabled={isFormDisabled}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="view-modal-buttons">
                    <SeaButton
                        zone="white"
                        type="submit"
                        disabled={isFormDisabled}
                    >
                        {selectedEvent ? 'Update Message' : 'Send Global Message!'}
                    </SeaButton>
                    {selectedEvent && selectedEvent.state !== 'deleted' &&
                        <SeaLinkButton
                            mode="standard-link"
                            onClick={(e) => onDelete(e)}
                        >
                            Delete Message
                        </SeaLinkButton>
                    }
                </div>
                <div>
                        {selectedEvent ? (
                            <p>
                                NOTE: Any updated information will only be seen by users who haven't seen this message yet.<br/>
                                If you want everyone to see a new message, delete this one and create a new one.
                            </p>
                        ) : (
                            <p>
                                NOTE: This will show a message to ALL users of Sea Flux within the app.<br/>
                                Once a user dismisses the message they will not see it again unless they reinstall or use a different device or browser.
                            </p>
                        )}
                </div>
            </form>
        </SeaModal>
    );
};

export default EditServerMessageModal;
