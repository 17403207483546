import React, { useState } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc, serverTimestamp } from "firebase/firestore";
import { formatValue } from '../../../../lib/util';
import { formatDate, formatEmailReminder, warnDays } from '../../../../lib/datesAndTime';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { VesselCertificate } from '../../../../shared-state/VesselDocuments/vesselCertificates';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { isPdf } from '../../../../lib/files';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditVesselCertificate from '../EditVesselCertificate/EditVesselCertificate';
import RenewVesselCertificate from '../RenewVesselCertificate/RenewVesselCertificate';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaPDFViewer from '../../../../components/SeaPDFViewer/SeaPDFViewer';
import SeaLabel from '../../../../components/SeaLabel/SeaLabel';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import VesselCertificatePdf from '../../../../exports/VesselDocuments/VesselCertificatePdf';

interface ViewVesselCertificateProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem?: VesselCertificate,
}

const ViewVesselCertificate: React.FC<ViewVesselCertificateProps> = ({showModal, setShowModal, selectedItem}) => {
    const userId = sharedState.userId.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const vesselCertificateCategories = sharedState.vesselCertificateCategories.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showRenewItemModal, setShowRenewItemModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    const onRenewItem = () => {
        setShowRenewItemModal(true);
    };
    const onArchiveItem = () => {
        if (!selectedItem) {
            return;
        }
        confirmAction('Are you sure you want to archive this certification?', 'Yes, archive').then(() => {
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'vesselCertificates', 'archive', selectedItem.id);

            batch.set(
                doc(firestore, 'vesselCertificates', selectedItem.id),
                {
                    archivedBy: userId,
                    whenArchived: batchTrace.whenAction,
                    state: 'archived',
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            onCollectionUpdated(batch, 'vesselCertificates');
            logAction(
                batch,
                'Archive',
                'vesselCertificates',
                selectedItem.id,
                selectedItem.title,
                [selectedItem.vesselId]
            );

            batchTrace.data = {
                selectedItem
            };
            batchTrace.save(`Archive certificate ${selectedItem.title}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            showToast('Certification has now been archived');
            setShowModal(false);
        }).catch(() => {});
    };

    if (!selectedItem) {
        return <></>;
    }
    
    return (
        <SeaModal
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        {selectedItem?.title}
                    </span>
                    {selectedItem?.dateExpires &&
                        <SeaStatusDueDate
                            dateDue={selectedItem.dateExpires}
                            warnDays={warnDays.vesselCertificates[0]}
                            inline={true}
                        />
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            onOpened={() => console.log(`Viewing vessel certificate ${selectedItem?.id}`)}
            size="wide"
            onPdf={exportType === 'pdf' && <VesselCertificatePdf 
                onCompleted={() => setExportType(undefined)}
                selectedItem={selectedItem}
            />}
            setExportType={setExportType}
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label={`${vessel?.isShoreFacility ? 'Certification' : 'Certificate'} #`} w="50">
                    {formatValue(selectedItem?.certNum)}
                </SeaGridCell>
                <SeaGridCell label="Issued By" w="50">
                    {formatValue(selectedItem?.issuedBy)}
                </SeaGridCell>
                <SeaGridCell label="Issued Date" w="50">
                    {formatDate(selectedItem?.dateIssued)}
                </SeaGridCell>
                {selectedItem?.type === 'renewable' && 
                    <>
                        <SeaGridCell label="Expiry" w="50">
                            {formatDate(selectedItem?.dateExpires)}
                        </SeaGridCell>
                        <SeaGridCell label="Notification" w="50">
                            {formatValue(formatEmailReminder(selectedItem?.emailReminder))}
                        </SeaGridCell>
                    </>
                }
                {selectedItem?.categoryId && vesselCertificateCategories?.byId ? <SeaGridCell label="Category" w="50">
                    {formatValue(vesselCertificateCategories.byId[selectedItem.categoryId].name)}
                </SeaGridCell> : null}
            </SeaGrid>

            <div className="view-modal-buttons">
                <RequirePermissions
                    role="vesselCertificates"
                    level={permissionLevels.CREATE}
                >
                    <RequirePermissions
                        role="vesselCertificates"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton zone="white" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit {vessel?.isShoreFacility ? 'Certification' : 'Certificate'}</SeaButton>
                        <div className="spacer-wide"></div>
                    </RequirePermissions>
                    {selectedItem?.type === 'renewable' && <>
                        <SeaButton zone="white" onClick={(e) => onRenewItem()}>Renew {vessel?.isShoreFacility ? 'Certification' : 'Certificate'}</SeaButton>
                    </>}
                    <RequirePermissions
                        role="vesselCertificates"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <SeaLinkButton mode="standard-link" onClick={(e) => onArchiveItem()}>Archive {vessel?.isShoreFacility ? 'Certification' : 'Certificate'}</SeaLinkButton>
                    </RequirePermissions>
                </RequirePermissions>
            </div>

            {isPdf(selectedItem?.files?.[0]) ? (
                <div className="sea-fill-container">
                    <div className="end-info-line"></div>
                    <SeaLabel>PDF Preview</SeaLabel>
                    <SeaPDFViewer file={selectedItem.files?.[0]} allowScroll /> 
                </div>
            ) : null}

            {showModal && vesselCertificateCategories &&
                <>
                    <EditVesselCertificate
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={2}
                        vesselCertificateCategories={vesselCertificateCategories}
                    />
                    <RenewVesselCertificate
                        showModal={showRenewItemModal}
                        setShowModal={setShowRenewItemModal}
                        selectedItem={selectedItem}
                        setShowParentModal={setShowModal}
                        level={2}
                    />
                </>
            }
        </SeaModal>
    );
};

export default ViewVesselCertificate;
