import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import { canEdit } from '../../../shared-state/Core/userPermissions';
import { Document, View, Image, Text, Page } from '@react-pdf/renderer';
import { pdfStyles } from '../../../lib/pdf';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { getImgSrc, getImgSrcFromExt, getImgSrcFromString, makeSeaFiles } from '../../../lib/files';
import { Incident } from '../../../shared-state/HealthSafety/incidents';
import { CorrectiveAction } from '../../../shared-state/HealthSafety/correctiveActions';
import IncidentReportCommonPage from './IncidentReportCommonPage';
import IncidentReportReviewPage from './IncidentReportReviewPage';

interface IncidentEventReportPdfProps {
    onCompleted: () => void,
    selectedItem: Incident,
}

const IncidentEventReportPdf: React.FC<IncidentEventReportPdfProps> = ({
    onCompleted,
    selectedItem,
}) => {
    const incidentReviews = sharedState.incidentReviews.use();
    const incidentCategories = sharedState.incidentCategories.use();
    const incidentCauses = sharedState.incidentCauses.use();
    const vessels = sharedState.vessels.use();
    const injuryTypes = sharedState.injuryTypes.use();
    const injuryLocations = sharedState.injuryLocations.use();
    const correctiveActions = sharedState.correctiveActions.use();
    const [correctiveActionsAttached, setCorrectiveActionsAttached] = useState<CorrectiveAction[]>();
    const [incidentImages, setIncidentImages] = useState<{reference: number, element: JSX.Element, src: string}[]>([]);
    const [reviewImages, setReviewImages] = useState<{reference: number, element: JSX.Element, src: string}[]>([]);
    const [correctiveActionImages, setCorrectiveActionImages] = useState<{reference: number, element: JSX.Element, src: string}[]>([]);
    const [incidentImagesLoaded, setIncidentImagesLoaded] = useState(false);
    const [reviewImagesLoaded, setReviewImagesLoaded] = useState(false);
    const [correctiveActionImagesLoaded, setCorrectiveActionImagesLoaded] = useState(false);
    const imagesRef = useRef<{reference?: number, element?: JSX.Element, src: string | Promise<string>, fileName: string}[]>([]);

    console.log('correctiveActionsAttached', correctiveActionsAttached);

    const reportTitle = useMemo(() => {
        if (selectedItem) {
            return `Incident / Event Report - ${selectedItem.name}`;
        }
        return '';
    }, [selectedItem]);

    const incidentReview = useMemo(() => {
        if (selectedItem && incidentReviews?.byId[selectedItem.id]) {
            return incidentReviews.byId[selectedItem.id];
        }
        return undefined;
    }, [incidentReviews, selectedItem]);

    const getImageSrcs = useCallback((files: string[], setLoaded: (value: React.SetStateAction<boolean>) => void) => {
        const renderedImages: {reference: number, element: JSX.Element, src: string}[] = [];
        if (files?.length) {
            let index = -1;
            for (const _file of files) {
                index++
                const file = makeSeaFiles([_file])[0];
                const ext = file.ext?.toLowerCase();
                const isImage = (ext === 'jpg' || ext === 'jpeg' || ext === 'png');
                const imgSrc = (
                    isImage ?
                    getImgSrc(
                        file.state!,
                        file.id!,
                        file.ext!,
                        'full'
                    ) :
                    getImgSrcFromExt(file.ext, 'medium')
                );
                const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                    if (isImage) {
                        imagesRef.current.push({
                            src: imgSrc,
                            fileName: fileName
                        });
                    }
                    renderedImages.push ({
                        element: (
                            <View key={_file} style={{flexDirection: 'row'}}>
                                <View style={(index === selectedItem.files.length - 1) ? pdfStyles.thumbColumn : pdfStyles.thumbSeriesColumn }>
                                    <Image
                                        src={imgSrc}
                                        style={pdfStyles.thumbImg}
                                    />
                                </View>
                                <Text style={{alignSelf: 'center'}}>&nbsp;{fileName}</Text>
                                <Text 
                                style={{
                                    alignSelf: 'center',
                                    padding: '0 0 0.25cm 0.02cm',
                                    fontSize: '7pt'
                                }} >
                                    &nbsp;{imagesRef.current.length}
                                </Text>

                            </View>
                            ),
                        reference: imagesRef.current.length,
                        src: imgSrc as any
                    }
                );
            }
        }
        setLoaded(true);
        return renderedImages;
    }, [selectedItem.files]);

    useEffect(() => {
        // Reset the imagesRef array to prevent duplicates
        imagesRef.current = [];
        setIncidentImages(getImageSrcs(selectedItem.files, setIncidentImagesLoaded))
        if (incidentReview?.files) {
            setReviewImages(getImageSrcs(incidentReview?.files, setReviewImagesLoaded))
        } else {
            setReviewImagesLoaded(true);
        }
    }, [getImageSrcs, incidentReview, selectedItem.files]);

    useEffect(() => {
        if (!correctiveActions) return;
        if (incidentReview?.correctiveActionIds) {
            const correctiveActionsAttached = [];
            for (const id of incidentReview?.correctiveActionIds) {
                if (correctiveActions?.byId.all[id]) {
                    correctiveActionsAttached.push(correctiveActions?.byId.all[id]);
                }
            }
            setCorrectiveActionsAttached(correctiveActionsAttached);
        } else {
            setCorrectiveActionsAttached([]);
        }
    }, [correctiveActions, correctiveActions?.byId.all, incidentReview]);

    useEffect(() => {
        if (correctiveActionsAttached && correctiveActionsAttached?.length > 0) {
            setCorrectiveActionImages(getImageSrcs(correctiveActionsAttached.flatMap((action) => action.files), setCorrectiveActionImagesLoaded))
        } else if (correctiveActionsAttached) {
            setCorrectiveActionImagesLoaded(true);
        }
    }, [correctiveActionsAttached, getImageSrcs]);

    const MakePDF = useCallback(() => {
        const signature = selectedItem?.signature ? getImgSrcFromString(selectedItem.signature, 'sig') : undefined;
        const reviewSignature = incidentReview?.signature ? getImgSrcFromString(incidentReview.signature, 'sig') : undefined;
        const addedBy = selectedItem.addedBy ? renderFullNameForUserId(selectedItem.addedBy) : undefined;
        const completedBy = selectedItem.completedBy ? renderFullNameForUserId(selectedItem.completedBy) : undefined;
        const updatedBy = selectedItem.updatedBy ? renderFullNameForUserId(selectedItem.updatedBy) : undefined;
        const reviewAddedBy = incidentReview?.addedBy ? renderFullNameForUserId(incidentReview.addedBy) : undefined;
        const reviewCompletedBy = incidentReview?.completedBy ? renderFullNameForUserId(incidentReview.completedBy) : undefined;
        const reviewUpdatedBy = incidentReview?.updatedBy ? renderFullNameForUserId(incidentReview.updatedBy) : undefined;
        if (!selectedItem) return (<></>);
        return (
            <Document
                title={reportTitle}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <IncidentReportCommonPage
                    selectedItem={selectedItem}
                    reportTitle={reportTitle}
                    signature={signature}
                    addedBy={addedBy}
                    completedBy={completedBy}
                    updatedBy={updatedBy}
                    auth={{incidentCategories, incidentCauses, vessels, injuryTypes, injuryLocations}}
                    images={incidentImages}
                />
                {incidentReview && canEdit('incidentAccidentMedicalRegister') &&
                <IncidentReportReviewPage
                    selectedItem={selectedItem}
                    review={incidentReview}
                    signature={reviewSignature}
                    addedBy={reviewAddedBy}
                    completedBy={reviewCompletedBy}
                    updatedBy={reviewUpdatedBy}
                    auth={{incidentCauses}}
                    images={reviewImages}
                    correctiveActions={correctiveActionsAttached}
                    correctiveActionImages={correctiveActionImages}
                />}
                {imagesRef.current.length > 0 &&
                    <Page
                        style={{
                            ...pdfStyles.page
                        }}
                        orientation="portrait"
                        size="A4"
                        wrap={true}
                    >
                        {imagesRef.current.map((img: any, index: number) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        maxWidth: '19cm',
                                        margin: '0 1cm 0 1cm',
                                        paddingBottom: '0.5cm'
                                    }}
                                    wrap={false}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{
                                            alignSelf: 'center',
                                            padding: '0 0.02cm 0.25cm 0',
                                            fontSize: '7pt'
                                        }}>
                                            {index + 1}
                                        </Text>
                                        <Text>{img.fileName}</Text>
                                    </View>
                                    <Image
                                        src={img.src}
                                        style={{
                                            objectFit: 'scale-down',
                                            objectPositionX: 0,
                                            objectPositionY: 0,
                                        }}
                                    />
                                </View>
                            );
                        })}
                    </Page>
                }

            </Document>
        )
    }, [correctiveActionImages, correctiveActionsAttached, incidentCategories, incidentCauses, incidentImages, incidentReview, injuryLocations, injuryTypes, reportTitle, reviewImages, selectedItem, vessels]);

    if (!incidentImagesLoaded || !reviewImagesLoaded || !correctiveActionImagesLoaded || !correctiveActionsAttached) {
        return <></>;
    } 
   
    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={reportTitle}
            MakePdf={MakePDF}
            requestedFrom="incidentEventReport"
        />
    );
};

export default IncidentEventReportPdf;
