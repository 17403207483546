import { useMemo } from "react";
import { CsvConfig, formatCsvDate, formatCsvFiles, formatCsvString } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { formatEmailReminder } from "../../../lib/datesAndTime";
import { CorrectiveAction } from "../../../shared-state/HealthSafety/correctiveActions";
import { renderFullNameForUserId } from "../../../shared-state/Core/users";
import SeaCSVGenerator from "../../../components/SeaCSV/SeaCSVGenerator";
import { renderVesselsList } from "../../../shared-state/Core/vessels";

type CorrectiveActionsCsvProps = {
    onCompleted: () => void;
    selectedVesselId?: string;
};

const CorrectiveActionsCsv = ({ onCompleted, selectedVesselId }: CorrectiveActionsCsvProps) => {
    const correctiveActions = sharedState.correctiveActions.use();
    const vesselLocations = sharedState.vesselLocations.use();
    const vesselSystems = sharedState.vesselSystems.use();

    const data = useMemo(() => {
        if (selectedVesselId) {
            return correctiveActions?.byVesselId[selectedVesselId];
        }
        return correctiveActions?.array.all;
    }, [correctiveActions, selectedVesselId]);

    const csvConfig: CsvConfig<CorrectiveAction> | undefined = useMemo(() => {
        if (!vesselLocations || !vesselSystems) {
            return undefined;
        }
        return [
            {
                name: "Title",
                value: (correctiveAction) => formatCsvString(correctiveAction.title),
            },
            {
                name: "Action #",
                value: (correctiveAction) => correctiveAction.correctiveActionNum,
            },
            {
                name: "Description",
                value: (correctiveAction) => formatCsvString(correctiveAction.description),
            },
            {
                name: "Vessels",
                value: (correctiveAction) => renderVesselsList(correctiveAction.vesselIds),
            },
            {
                name: "Assigned To",
                value: (correctiveAction) => renderFullNameForUserId(correctiveAction.assignedTo),
            },
            {
                name: "Tags",
                value: (correctiveAction) => formatCsvString((correctiveAction.tags || []).join(", ")),
            },
            {
                name: "Due Date",
                value: (correctiveAction) => formatCsvDate(correctiveAction.dateDue),
            },
            {
                name: "Email Reminder",
                value: (correctiveAction) => formatEmailReminder(correctiveAction.emailReminder),
            },
            {
                name: "Date Added",
                value: (correctiveAction) => formatCsvDate(correctiveAction.whenAdded),
            },
            {
                name: "Images / Documents",
                value: (correctiveAction) => formatCsvFiles(correctiveAction.files),
            }
        ];
    }, [vesselLocations, vesselSystems]);

    return <SeaCSVGenerator name={"Corrective-Actions"} onCompleted={onCompleted} config={csvConfig} data={data} />;
};

export default CorrectiveActionsCsv;
