import React from 'react';
import { sharedState } from '../../../../shared-state/shared-state';
import { IonSelectOption } from '@ionic/react';
import { maxSessionOptions } from '../../../../lib/util';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import './AuthenticationSettingsTab.css';

interface AuthenticationSettingsTabProps {
    showModal: boolean,
    maxSessionSeconds: string,
    setMaxSesseionSeconds: (maxSessionSeconds: string) => void
}

const AuthenticationSettingsTab: React.FC<AuthenticationSettingsTabProps> = ({ showModal, maxSessionSeconds, setMaxSesseionSeconds }) => {
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const user = sharedState.user.use(showModal)!;

    return (<>
        {licenseeSettings &&
            <div style={{ paddingBottom: '32px' }}>
                <h2 className="for-view-modal">
                    Multi Factor Authentication: <b>{licenseeSettings.hasMfaEmail ? 'Enabled' : 'Disabled'}</b>
                </h2>
                <div style={{ paddingTop: '4px' }}>
                    {
                        licenseeSettings.hasMfaEmail ? (
                            <>
                                <p>
                                    When logging in, your crew will also need to provide a passcode sent to them via email.
                                </p>
                                <p>
                                    If you would like to disable Multi Factor Authentication, please contact the Sea Flux team
                                </p>
                                <p>
                                    +64223080209 or +64279404383
                                </p>
                            </>
                        ) : (
                            <>
                                <p>
                                    If Multi Factor Authentication is enabled, your crew will also need to provide an email passcode when logging in.
                                </p>
                                <p>
                                    If you would like to enable Multi Factor Authentication, please contact the Sea Flux team
                                </p>
                                <p>
                                    +64223080209 or +64279404383
                                </p>
                            </>
                        )
                    }
                </div>
            </div>
        }
        <div>
            <h2 className="for-view-modal">
                Session Management
            </h2>
            <div style={{ paddingTop: '20px', maxWidth: '500px' }}>
                <SeaSelect
                    label="Maximum Session Time"
                    help={{text: 'This is the amount of time a user is allowed to remain logged in for before being required to login again.'}}
                    name="maxSessionSeconds"
                    zone="white"
                    value={maxSessionSeconds}
                    onchange={(e) => {
                        setMaxSesseionSeconds(e.detail.value);
                    }}
                    disabled={!user.isLicensee}
                    //onblur={handleBlur}
                    //error={touched.maxSessionSeconds ? errors.maxSessionSeconds : ''}
                >
                    {maxSessionOptions.map((option) => {
                        return (
                            <IonSelectOption
                                key={option.value}
                                value={option.value}
                            >
                                {option.description}
                            </IonSelectOption>
                        );
                    })}
                </SeaSelect>
                {!user.isLicensee &&
                    <p>
                        Note: Only the licensee can change this setting.
                    </p>
                }
            </div>
        </div>
    </>);
};

export default AuthenticationSettingsTab;
