import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, arrayUnion, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, hasArrayChanged, cleanupStringArray, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate, subtractInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName, renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { sendVesselNotification } from '../../../../shared-state/Core/vessel';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { UserDetails } from '../../../../shared-state/Crew/userDetails';
import { CorrectiveAction } from '../../../../shared-state/HealthSafety/correctiveActions';
import SeaMultiSelect, { OptionType } from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaSelectEmailReminder from '../../../../components/SeaSelectEmailReminder/SeaSelectEmailReminder';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaTagsInput from '../../../../components/SeaTagsInput/SeaTagsInput';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaSelectVessels from '../../../../components/SeaSelectVessels/SeaSelectVessels';

interface EditCorrectiveActionProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    itemToUpdate?: CorrectiveAction,
    defaultValues?: Partial<CorrectiveAction>,
    onSubmitted?: (correctiveActionId: string) => void,
    level?: number,
    incidentReviewId?: string,
    isNewIncidentReview?: boolean,
}

const EditCorrectiveAction: React.FC<EditCorrectiveActionProps> = ({ showModal, setShowModal, itemToUpdate, defaultValues, onSubmitted, level, incidentReviewId, isNewIncidentReview }) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const users = sharedState.users.use(showModal);
    const userDetails = sharedState.userDetails.use(showModal);
    const [emailToIds, setEmailToIds] = useState<string[]>([]);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [tags, setTags] = useState<string[]>([]);
    const [vesselIds, setVesselIds] = useState<string[]>([]);
    const licenseeSettings = sharedState.licenseeSettings.use();

    const initialValues = useMemo(() => {
        if (defaultValues) {
            return {
                title: (defaultValues.title) ? '' + defaultValues.title : '',
                description: (defaultValues.description) ? '' + defaultValues.description : '',
                dateDue: defaultValues.dateDue ? formatSeaDate(defaultValues.dateDue) : '',
                emailReminder: (defaultValues.emailReminder) ? '' + defaultValues.emailReminder : '',
                assignedTo: (defaultValues.assignedTo) ? '' + defaultValues.assignedTo : '',
            };
        }
        if (!itemToUpdate) {
            return {
                title: '',
                description: '',
                dateDue: '',
                emailReminder: '',
                assignedTo: '',
            };
        } else {
            return {
                title: (itemToUpdate?.title) ? '' + itemToUpdate.title : '',
                description: (itemToUpdate?.description) ? '' + itemToUpdate.description : '',
                assignedTo: (itemToUpdate?.assignedTo) ? '' + itemToUpdate.assignedTo : '',
                dateDue: (itemToUpdate?.dateDue) ? formatSeaDate(itemToUpdate.dateDue) : '',
                emailReminder: (itemToUpdate?.emailReminder) ? '' + itemToUpdate.emailReminder : '',
            };
        }
    }, [defaultValues, itemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setTags(itemToUpdate?.tags ?? defaultValues?.tags ?? []);
        setFiles(makeSeaFiles(itemToUpdate?.files ?? defaultValues?.files ?? []));
        setVesselIds(itemToUpdate?.vesselIds ?? defaultValues?.vesselIds ?? []);
    }

    const onClosed = () => {
        setVesselIds([]);
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting, isValid } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().max(5000).required(),
            description: Yup.string().max(5000),
            dateDue: Yup.date().when('emailReminder', { is: (value: string) => value, then: (schema) => schema.required().min(...notTooOld) }),
            emailReminder: Yup.string().max(200),
            estimatedCost: Yup.number().max(1000000000000)
        }), onSubmit: (data) => {
            if (preventMultiTap('correctiveActions')) { return; }
            if (!licenseeId) {
                reportError('Licensee ID not found');
                return;
            }

            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'correctiveActions');

                let dateToRemind: string | undefined = undefined;
                if (data.dateDue && data.emailReminder) {
                    dateToRemind = subtractInterval(data.dateDue, data.emailReminder).toISODate();
                };

                let assignedToName = renderFullNameForUserId(data.assignedTo);
                let correctiveActionId: string = '';

                if (itemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    correctiveActionId = itemToUpdate.id;

                    batch.set(
                        doc(firestore, 'correctiveActions', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            title: data.title,
                            description: data.description,
                            assignedTo: data.assignedTo ?? deleteValue,
                            tags: tags ? cleanupStringArray(tags) : deleteValue,
                            files: seaFilesToValue(files),
                            emailReminder: data.emailReminder ? data.emailReminder : deleteValue,
                            dateDue: data.dateDue ?? deleteValue,
                            dateToRemind: dateToRemind ?? deleteValue,
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'correctiveActions', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'correctiveActions',
                        itemToUpdate.id,
                        data.title,
                        itemToUpdate.vesselIds
                    );

                    sendVesselNotification(batch, 'correctiveActionCreated', 'correctiveActionsUpdated', {
                        isUpdate: true,
                        id: itemToUpdate.id,
                        title: data.title,
                        description: data.description,
                        correctiveActionNum: itemToUpdate.correctiveActionNum ? itemToUpdate.correctiveActionNum : undefined,
                        assignedToName: assignedToName ? assignedToName : undefined,
                        tags: tags ? cleanupStringArray(tags)?.join(', ') : undefined,
                        dateDue: data.dateDue ?? undefined,
                    }, files, vesselIds, emailToIds);

                } else {
                    const newRef = doc(collection(firestore, 'correctiveActions'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    correctiveActionId = newRef.id;
                    batch.set(newRef, {
                        vesselIds: vesselIds,
                        licenseeId: licenseeId,
                        addedBy: userId,
                        title: data.title,
                        description: data.description,
                        assignedTo: data.assignedTo ? data.assignedTo : undefined,
                        tags: tags ? cleanupStringArray(tags) : undefined,
                        dateDue: data.dateDue ?? undefined,
                        emailReminder: data.emailReminder ? data.emailReminder : undefined,
                        dateToRemind: dateToRemind ?? undefined,
                        whenAdded: batchTrace.whenAction,
                        state: 'active',
                        files: seaFilesToValue(files),
                        touched: serverTimestamp()
                    });

                    saveFileRefs(batch, files, 'correctiveActions', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'correctiveActions',
                        newRef.id,
                        data.title,
                        [licenseeId]
                    );

                    sendVesselNotification(batch, 'correctiveActionCreated', 'correctiveActionsCreated', {
                        id: newRef.id,
                        title: data.title,
                        description: data.description,
                        assignedToName: assignedToName ? assignedToName : undefined,
                        tags: tags ? cleanupStringArray(tags)?.join(', ') : undefined,
                        dateDue: data.dateDue ?? undefined,
                    }, files, vesselIds, emailToIds);
                }
                if (incidentReviewId && !isNewIncidentReview) {
                    batch.set(doc(firestore, 'incidentReviews', incidentReviewId), {
                        prevention: deleteValue, // Clear old prevention field
                        correctiveActionIds: arrayUnion(correctiveActionId),
                        updatedVia: 'correctiveAction',
                        updatedBy: userId,
                        whenUpdated: batchTrace.whenAction,
                        touched: serverTimestamp()
                    }, { merge: true });
                    onCollectionUpdated(batch, 'incidentReviews');
                }
                onCollectionUpdated(batch, 'correctiveActions');
                let tagsChanged = false;
                if (tags) {
                    cleanupStringArray(tags)?.forEach((tag) => {
                        if (licenseeSettings?.correctiveActionTags === undefined || licenseeSettings?.correctiveActionTags.indexOf(tag) === -1) {
                            tagsChanged = true;
                            batch.set(
                                doc(firestore, 'licenseeSettings', licenseeId),
                                {
                                    updatedBy: userId,
                                    whenUpdated: batchTrace.whenAction,
                                    correctiveActionTags: arrayUnion(tag),
                                    touched: serverTimestamp()
                                },
                                { merge: true }
                            );
                        }
                    });
                }
                if (tagsChanged) {
                    onCollectionUpdated(batch, 'vessels');
                }
                batchTrace.data = {
                    data,
                    tags,
                    files: seaFilesToValue(files)
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} corrective action ${data?.title}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                if (onSubmitted) {
                    onSubmitted(correctiveActionId);
                }

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload corrective action files`, error.message, error, {
                        itemToUpdate,
                        data,
                        files: seaFilesToValue(files)
                    });
                }
            })
        }
    });

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            hasArrayChanged(tags, itemToUpdate?.tags) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [values, initialValues, itemToUpdate?.tags, itemToUpdate?.files, tags, files]);


    // Who can be emailed for this corrective action
    const emailToOptions = useMemo(() => {
        const options: OptionType[] = [];
        const userIds: string[] = [];


        if (showModal && users) {
            users?.all.forEach((user) => {
                if (!user.id || user.state !== 'active' || !userDetails?.byId[user.id]) {
                    return;
                }
                const usersDetails = userDetails?.byId[user.id] as UserDetails
                // If the user is the assigned to, or the user wants to be notified of this priority then add them to the list
                if (user.id === values.assignedTo || usersDetails.emailMe?.includes('correctiveActionsUpdated') || (!itemToUpdate && usersDetails.emailMe?.includes('correctiveActionsCreated'))) {
                    userIds.push(user.id);
                    options.push({
                        id: user.id,
                        name: renderFullName(user),
                        selected: true,
                        required: true,
                    });
                } else {
                    options.push({
                        id: user.id,
                        name: renderFullName(user),
                    });
                }
            });
        }
        setEmailToIds(userIds);
        return options;
    }, [showModal, users, userDetails?.byId, values.assignedTo, itemToUpdate]);

    const assignedToOptions = useMemo(() => {
        const options: OptionType[] = [];
        users?.all.forEach((user) => {
            if (user.id && user.state === 'active' && userDetails?.byId[user.id]) {
                options.push({
                    id: user.id,
                    name: renderFullName(user),
                });
            }
        });
        return options;
    }, [users, userDetails?.byId]);


    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Corrective Action' : 'Add New Corrective Action'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInput
                                label="Title"
                                name="title"
                                value={values.title}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.title ? errors.title : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Description"
                                name="description"
                                value={values.description}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.description ? errors.description : ''}
                                height={100}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="4">
                            <SeaSelectVessels
                                label="Select Vessel"
                                vesselIds={vesselIds}
                                setVesselIds={setVesselIds}
                            />
                        </IonCol>
                        <IonCol size="4">
                            <SeaSelect
                                label="Assigned to"
                                value={values.assignedTo}
                                onchange={(e) => {
                                    setValues({ ...values, assignedTo: e.detail.value });
                                }}
                            >
                                {assignedToOptions.map((option) => (
                                    <IonSelectOption key={option.id} value={option.id}>{option.name}</IonSelectOption>
                                ))}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="4">
                            <SeaTagsInput
                                label="Tags"
                                tags={tags}
                                setTags={setTags}
                                options={licenseeSettings?.correctiveActionTags}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='4'>
                            <SeaDate
                                label="Due Date"
                                name="dateDue"
                                value={values.dateDue}
                                onchange={handleChange}
                                onblur={handleBlur}
                            />
                        </IonCol>
                        <IonCol size="4">
                            <SeaSelectEmailReminder
                                label="Set Email Reminder"
                                name="emailReminder"
                                value={values.emailReminder}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.emailReminder ? errors.emailReminder : ''}
                            />
                        </IonCol>
                        <IonCol size="4">
                            <SeaMultiSelect
                                label="Email Notifications"
                                values={emailToIds}
                                setValues={setEmailToIds}
                                options={emailToOptions}
                                help={{ text: `Select the users that should be notified when this corrective action is ${itemToUpdate ? 'updated' : 'created'}.` }}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="correctiveActions"
                                field="files"
                            />
                        </IonCol>                      
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Corrective Action' : 'Save New Corrective Action'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditCorrectiveAction;
