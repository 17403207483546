import React from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { sharedState } from '../../../../shared-state/shared-state';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';
import SeaContextualHelp from '../../../../components/SeaContextualHelp/SeaContextualHelp';
import './TaskSettingsTab.css';

interface TaskSettingsTabProps {
    showModal: boolean,
    hasSafetyCheckTaskTime: boolean,
    setHasSafetyCheckTaskTime: (hasSafetyCheckTaskTime: boolean) => void,
    hasMaintenanceTaskTime: boolean,
    setHasMaintenanceTaskTime: (hasMaintenanceTaskTime: boolean) => void,
}

const TaskSettingsTab: React.FC<TaskSettingsTabProps> = ({ showModal, hasSafetyCheckTaskTime, setHasSafetyCheckTaskTime, hasMaintenanceTaskTime, setHasMaintenanceTaskTime }) => {
    const user = sharedState.user.use(showModal)!;

    return (<>
        <div style={{ paddingBottom: '20px' }}>
            <h2 className="for-view-modal">
                Task Times
            </h2>
        </div>
        <IonGrid>
            <IonRow>
                <IonCol size="6">
                    <SeaCheckbox
                        name="hasSafetyCheckTaskTime"
                        checked={hasSafetyCheckTaskTime}
                        setChecked={setHasSafetyCheckTaskTime}
                        disabled={!user.isLicensee}
                    >
                        Record Safety Check Task Times
                        <SeaContextualHelp
                            help={{ text: 'Enable users to record an estimated time for how long the safety check task will take and an actual time for how long it took to complete.' }}
                            mode="checkbox"
                        />
                    </SeaCheckbox>
                </IonCol>
                <IonCol size="6">
                    <SeaCheckbox
                        name="hasMaintenanceTaskTime"
                        checked={hasMaintenanceTaskTime}
                        setChecked={setHasMaintenanceTaskTime}
                        disabled={!user.isLicensee}
                    >
                        Record Maintenance Task Times
                        <SeaContextualHelp
                            help={{ text: 'Enable users to record an estimated time for how long the maintenance task will take and an actual time for how long it took to complete.' }}
                            mode="checkbox"
                        />
                    </SeaCheckbox>
                </IonCol>
            </IonRow>
        </IonGrid>
    </>);
};

export default TaskSettingsTab;
