import React, { ReactNode, useMemo, useEffect, useState } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { useNavigate } from 'react-router-dom';
import SeaIcon from '../SeaIcon/SeaIcon';

interface RequireLicenseeSettingsProps {
    children: ReactNode,
    role?: 'hasIncidents' | 'hasReporting' | 'hasCorrectiveActions',
    reroute?: boolean,
    showDenial?: boolean
}

const RequireLicenseeSettings: React.FC<RequireLicenseeSettingsProps> = ({
    children,
    role,
    reroute = true,
    showDenial
}) => {
    const navigate = useNavigate();
    const licenseeSettings = sharedState.licenseeSettings.use();
    const [isRerouting, setIsRerouting] = useState(false);

    const isDenied = useMemo(() => {
        if (role) {
            if (licenseeSettings && licenseeSettings[role] !== false) {
                return false;
            }
        } else {
            return false;
        }
        return true;
    }, [role, licenseeSettings]);

    useEffect(() => {
        if (showDenial) {
            return;
        }
        if (isDenied && reroute) {
            console.log(`Rerouting to /fleet`);
            navigate('/fleet');
            setIsRerouting(true);
        }
    }, [isDenied, reroute, navigate, showDenial]);

    if (isRerouting) {
        return <></>;
    }
    if (isDenied) {
        if (showDenial) {
            return (
                <div className="sea-no-data columns">
                    <div><SeaIcon icon="warn"/></div>
                    <div>Your licensee does not have permission to view this content</div>
                </div>
            );
        }
        return <></>;
    }
    return <>{children}</>;
};

export default RequireLicenseeSettings;