import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { useEffect, useState } from "react";
import { Drill } from "./drills";


type DrillsData = {
    byId: { [id: string]: Drill };
    byVesselId: { [id: string]: Drill[] };
};

export const useDrillsForVessels = (
    isActive: boolean,
    selectedVesselIds?: string[],
) => {
    const [drills, setDrills] = useState<DrillsData>();

    useEffect(() => {
        setDrills(undefined);
        if (
            isActive &&
            selectedVesselIds?.length
        ) {
            return setupArrayQueryListener(
                'drills',
                collection(firestore, 'drills'),
                [
                    where('state', '==', 'active'),
                    orderBy('name')
                ],
                'vesselId',
                'in',
                selectedVesselIds,
                [],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => {
                    const byId: { [id: string]: Drill } = {};
                    const byVesselId: { [id: string]: Drill[] } = {};
                    docs.forEach((doc) => {
                        const drill = {
                            id: doc.id,
                            ...doc.data(),
                        } as Drill;
                        byId[drill.id] = drill;
                        if (byVesselId[drill.vesselId] === undefined) {
                            byVesselId[drill.vesselId] = [];
                        }
                        byVesselId[drill.vesselId].push(drill);
                    });

                    setDrills({
                        byId,
                        byVesselId,
                    });
                }
            );
        }
    }, [isActive, selectedVesselIds]);

    return drills;
};
