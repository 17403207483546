import React from 'react';
import { formatDate, formatDatetime } from '../../../../lib/datesAndTime';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { SafetyEquipmentTaskCompleted } from '../../../../shared-state/VesselSafety/useCompletedSafetyEquipmentItems';
import { formatTextAreaText, formatValue } from '../../../../lib/util';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';

interface ViewCompletedSafetyEquipmentExpiryProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    selectedItem: SafetyEquipmentTaskCompleted | undefined;
    selectedSafetyItemName: string;
    level: number;
}

const ViewCompletedSafetyEquipmentExpiry: React.FC<ViewCompletedSafetyEquipmentExpiryProps> = ({ showModal, setShowModal, selectedItem, selectedSafetyItemName, level = 1 }) => {
    return (
        <SeaModal
            title="Completed Safety Task"
            showModal={showModal}
            setShowModal={setShowModal}
            size='thin'
            level={level}
        >
            <div className="info-image">
                <SeaFileImage files={selectedItem?.files} size="tiny" showOthers />
            </div>
             <SeaGrid>
                <SeaGridCell label="Safety Item" w="50">
                    {selectedSafetyItemName}
                </SeaGridCell>
                <SeaGridCell label="Completed By" w="50">
                    {renderFullNameForUserId(selectedItem?.completedBy)}
                </SeaGridCell>
                <SeaGridCell label="When Completed" w="50">
                    {formatDatetime(selectedItem?.whenCompleted)}
                </SeaGridCell>
                <SeaGridCell label="New Expiry Date" w="50">
                    {formatDate(selectedItem?.dateExpires)}
                </SeaGridCell>
                <SeaGridCell label="Notes" w="100">
                    {formatValue(formatTextAreaText(selectedItem?.notes))}
                </SeaGridCell>
            </SeaGrid>
        </SeaModal>
    );
};

export default ViewCompletedSafetyEquipmentExpiry;