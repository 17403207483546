import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from 'firebase/firestore';
import { haveValuesChanged, hasArrayChanged, preventMultiTap, toFloat, getCurrencyFromRegion } from '../../../../lib/util';
import { makeCategoryId } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { sharedState } from '../../../../shared-state/shared-state';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { SparePart } from '../../../../shared-state/VesselMaintenance/spareParts';
import SeaMultiSelect, { OptionType } from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import Yup from '../../../../lib/yup';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';

interface EditSparePartProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    level: number;
    itemToUpdate?: SparePart;
}

const EditSparePart: React.FC<EditSparePartProps> = ({ showModal, setShowModal, itemToUpdate, level }) => {
    const userId = sharedState.userId.use(showModal);
    const contacts = sharedState.contacts.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const equipment = sharedState.equipment.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const vesselSystems = sharedState.vesselSystems.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const [systemId, setSystemId] = useState<string>('');
    const [equipmentIds, setEquipmentIds] = useState<string[]>();
    const [equipmentOptions, setEquipmentOptions] = useState<OptionType[]>();
    const [locationId, setLocationId] = useState<string>();
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [contactIds, setContactIds] = useState<string[]>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [pulseEquipment, setPulseEquipment] = useState(false);
    const [pulseLocation, setPulseLocation] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                item: itemToUpdate?.item ? '' + itemToUpdate.item : '',
                locationDescription: itemToUpdate?.locationDescription ? '' + itemToUpdate.locationDescription : '',
                quantity: toFloat(itemToUpdate?.quantity) as number,
                minQuantity: itemToUpdate?.minQuantity && !isNaN(itemToUpdate?.minQuantity) ? (toFloat(itemToUpdate?.minQuantity) as number) : undefined,
                manufacturer: itemToUpdate?.manufacturer ? '' + itemToUpdate.manufacturer : '',
                model: itemToUpdate?.model ? '' + itemToUpdate.model : '',
                partNum: itemToUpdate?.partNum ? '' + itemToUpdate.partNum : '',
                unitPrice: itemToUpdate?.unitPrice ? '' + itemToUpdate.unitPrice : 0,
                notes: itemToUpdate?.notes ? '' + itemToUpdate.notes : '',
            };
        } else {
            return {
                item: '',
                locationDescription: '',
                quantity: 0,
                minQuantity: undefined,
                manufacturer: '',
                model: '',
                partNum: '',
                unitPrice: 0,
                notes: '',
            };
        }
    }, [itemToUpdate]);

    // Set equipmentOptions according to systemId
    // Reset equipmentIds if necessary
    useEffect(() => {
        setEquipmentOptions(undefined);
        if (systemId && equipment?.all) {
            const equipmentIdsFound = [] as boolean[];
            equipmentIds?.forEach((id) => {
                equipmentIdsFound.push(false);
            });

            let options: OptionType[] = [];

            if (equipment.bySystemId[systemId]) {
                options = equipment.bySystemId[systemId].map((item) => {
                    equipmentIds?.forEach((equipmentId, index) => {
                        if (equipmentId === item.id) {
                            equipmentIdsFound[index] = true;
                        }
                    });
                    return {
                        id: item.id,
                        name: item.equipment,
                    };
                });
            }

            equipmentIdsFound?.forEach((found, index) => {
                if (!found && equipmentIds) {
                    options.splice(0, 0, {
                        id: equipmentIds[index],
                        name: `${equipment.byId[equipmentIds[index]]?.equipment} (deleted)`,
                    });
                }
            });

            setEquipmentOptions(options);
            // Check if equipmentIds are still relevant (if not, reset them)
            if (equipmentIds && equipmentIds.length > 0 && equipment.byId[equipmentIds[0]] && equipment.byId[equipmentIds[0]].systemId !== systemId) {
                setEquipmentIds([]);
            }
        }
    }, [systemId, equipment]); // eslint-disable-line react-hooks/exhaustive-deps

    // Auto-fill location
    useEffect(() => {
        if (equipmentIds && equipmentIds.length > 0 && (locationId === undefined || locationId === '') && equipment?.byId[equipmentIds[0]]?.location) {
            setLocationId(equipment.byId[equipmentIds[0]].locationId);
            setPulseLocation(true);
            setTimeout(() => {
                setPulseLocation(false);
            }, 1);
        }
    }, [equipmentIds, equipment?.byId]); // eslint-disable-line react-hooks/exhaustive-deps

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting, isValid } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            item: Yup.string().max(500).required(),
            locationDescription: Yup.string().max(5000),
            quantity: Yup.number().min(0, 'This cannot be negative'),
            minQuantity: Yup.number().min(0, 'This cannot be negative'),
            manufacturer: Yup.string().max(500),
            model: Yup.string().max(500),
            partNum: Yup.string().max(500),
            currency: Yup.string().max(3),
            unitPrice: Yup.number().min(0, 'This cannot be negative'),
            notes: Yup.string().max(5000),
        }),
        onSubmit: (data) => {
            console.log('DBG', { data });
            setHasSubmitted(true);
            if (preventMultiTap('sparePart')) {
                return;
            }
            if (!vesselId) {
                throw new Error('No vesselId');
            }
            // Attempt upload first.... ?
            uploadFiles(files)
                .then(() => {
                    // Process form
                    const batch = splittableBatch(firestore, 20 - 0);
                    const batchTrace = makeBatchTrace(batch, 'spareParts');

                    if (itemToUpdate) {
                        batchTrace.exampleOperation = 'update';
                        batchTrace.exampleDocId = itemToUpdate.id;
                        batch.set(
                            doc(firestore, 'spareParts', itemToUpdate.id),
                            {
                                updatedBy: userId,
                                whenUpdated: batchTrace.whenAction,
                                updatedVia: 'editSpareParts',
                                item: data.item,
                                systemId: makeCategoryId(systemId, vesselSystems, deleteValue, batch, 'vesselSystems', 'vesselId', vesselId, {}),
                                equipmentIds: equipmentIds ? equipmentIds : deleteValue,
                                locationId: makeCategoryId(locationId, vesselLocations, deleteValue, batch, 'vesselLocations', 'vesselId', vesselId, {}),
                                locationDescription: data.locationDescription ? data.locationDescription : deleteValue,
                                quantity: toFloat(data.quantity, 0),
                                minQuantity: data.minQuantity ? toFloat(data.minQuantity, 0) : deleteValue,
                                manufacturer: data.manufacturer ? data.manufacturer : deleteValue,
                                model: data.model ? data.model : deleteValue,
                                partNum: data.partNum ? data.partNum : deleteValue,
                                unitPrice: data.unitPrice ? data.unitPrice : deleteValue,
                                notes: data.notes ? data.notes : deleteValue,
                                files: seaFilesToValue(files),
                                contactIds: contactIds,
                                touched: serverTimestamp(),
                            },
                            { merge: true }
                        );

                        saveFileRefs(batch, files, 'spareParts', itemToUpdate.id);
                        logAction(batch, 'Update', 'spareParts', itemToUpdate.id, `${data.item}${data.quantity ? ` (${data.quantity})` : ''}`, [itemToUpdate.vesselId]);
                    } else {
                        const newRef = doc(collection(firestore, 'spareParts'));
                        batchTrace.exampleOperation = 'create';
                        batchTrace.exampleDocId = newRef.id;
                        batch.set(newRef, {
                            vesselId: vesselId,
                            addedBy: userId,
                            whenAdded: batchTrace.whenAction,
                            item: data.item,
                            systemId: makeCategoryId(systemId, vesselSystems, undefined, batch, 'vesselSystems', 'vesselId', vesselId, {}),
                            equipmentIds: equipmentIds ? equipmentIds : undefined,
                            locationId: makeCategoryId(locationId, vesselLocations, undefined, batch, 'vesselLocations', 'vesselId', vesselId, {}),
                            locationDescription: data.locationDescription ? data.locationDescription : undefined,
                            quantity: toFloat(data.quantity, 0),
                            minQuantity: data.minQuantity ? toFloat(data.minQuantity, 0) : undefined,
                            manufacturer: data.manufacturer ? data.manufacturer : undefined,
                            model: data.model ? data.model : undefined,
                            partNum: data.partNum ? data.partNum : undefined,
                            unitPrice: data.unitPrice ? data.unitPrice : undefined,
                            notes: data.notes ? data.notes : undefined,
                            state: 'active',
                            files: seaFilesToValue(files),
                            contactIds: contactIds,
                            touched: serverTimestamp(),
                        });

                        saveFileRefs(batch, files, 'spareParts', newRef.id);
                        logAction(batch, 'Add', 'spareParts', newRef.id, `${data.item}${data.quantity ? ` (${data.quantity})` : ''}`, [vesselId]);
                    }

                    onCollectionUpdated(batch, 'spareParts');

                    batchTrace.data = {
                        data,
                        contactIds,
                        systemId,
                        equipmentIds,
                        locationId,
                        files: seaFilesToValue(files),
                    };
                    batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} spare part ${data?.item}`);
                    batch
                        .commit()
                        .then(() => {
                            batchTrace.reportSuccess();
                        })
                        .catch((error) => {
                            batchTrace.reportError(error.message, error);
                        });

                    setShowModal(false);
                })
                .catch((error: any) => {
                    if (!handleUploadError(error)) {
                        reportError(`Failed to upload Spare Part files`, error.message, error, {
                            files: seaFilesToValue(files),
                            data,
                            itemToUpdate,
                            contactIds,
                        });
                    }
                });
        },
    });

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setSystemId(itemToUpdate?.systemId ? itemToUpdate.systemId : '');
        setEquipmentIds(itemToUpdate?.equipmentIds ? itemToUpdate.equipmentIds : []);
        setLocationId(itemToUpdate?.locationId ? itemToUpdate.locationId : '');
        setTimeout(() => {
            // Prevent equipment.locationId overriding itemToUpdate.locationId
            setLocationId(itemToUpdate?.locationId ? itemToUpdate.locationId : '');
        }, 100);
        setFiles(makeSeaFiles(itemToUpdate?.files));
        setContactIds(itemToUpdate?.contactIds);
    };

    const onClosed = () => {
        setSystemId('');
        setLocationId('');
        setEquipmentIds([]);
    };

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            systemId !== (itemToUpdate?.systemId ? itemToUpdate.systemId : '') ||
            hasArrayChanged(equipmentIds, itemToUpdate?.equipmentIds) ||
            locationId !== (itemToUpdate?.locationId ? itemToUpdate.locationId : '') ||
            haveFilesChanged(files, itemToUpdate?.files) ||
            hasArrayChanged(contactIds, itemToUpdate?.contactIds)
        );
    }, [initialValues, values, itemToUpdate, systemId, equipmentIds, locationId, files, contactIds]);

    const contactOptions = useMemo(() => {
        return contacts?.all?.map((contact) => {
            return {
                id: contact.id,
                name: contact.company ? `${contact.company}, ${contact.name}` : contact.name,
            };
        });
    }, [contacts]);

    return (
        <SeaModal
            title={(itemToUpdate ? 'Edit ' : 'Add New ') + (vessel?.isShoreFacility ? 'Inventory Item' : 'Spare Part')}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInput label="Item" name="item" value={values.item} onchange={handleChange} onblur={handleBlur} zone="white" type="text" inputmode="text" error={touched.item ? errors.item : ''} />
                        </IonCol>

                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselSystems}
                                label="System"
                                name="systemId"
                                initialCategoryId={itemToUpdate?.systemId ? itemToUpdate.systemId : ''}
                                categoryId={systemId}
                                otherPlaceholder="Add New System"
                                onchange={(e) => {
                                    if (systemId !== e.detail.value) {
                                        setSystemId(e.detail.value);
                                        setPulseEquipment(true);
                                        setTimeout(() => {
                                            setPulseEquipment(false);
                                        }, 1);
                                    }
                                }}
                            />
                        </IonCol>
                        <IonCol size="6" className={`${pulseEquipment ? 'pulse-right' : 'pulse'}`}>
                            <SeaMultiSelect
                                mode="popover"
                                label="SELECT EQUIPMENT"
                                values={equipmentIds}
                                setValues={setEquipmentIds}
                                options={equipmentOptions}
                                useAllOption={equipmentOptions && equipmentOptions.length > 1}
                                isSubmitting={hasSubmitted}
                                emptyText="Not Set"
                                disabled={systemId === undefined || systemId === ''}
                            />
                        </IonCol>
                        <IonCol size="6" className={`${pulseLocation ? 'pulse-in' : 'pulse'}`}>
                            <SeaSelectCategory
                                categories={vesselLocations}
                                label="Location"
                                name="locationId"
                                initialCategoryId={itemToUpdate?.locationId ? '' + itemToUpdate.locationId : ''}
                                categoryId={locationId}
                                otherPlaceholder="Add New Location"
                                onchange={(e) => {
                                    setLocationId(e.detail.value);
                                }}
                            />
                        </IonCol>

                        <IonCol size="12">
                            <SeaInput
                                label="Location Description"
                                name="locationDescription"
                                value={values.locationDescription}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.locationDescription ? errors.locationDescription : ''}
                            />
                        </IonCol>
                        <IonCol size="4">
                            <SeaInput label="Quantity" name="quantity" value={values.quantity} onchange={handleChange} onblur={handleBlur} zone="white" type="number" inputmode="numeric" error={touched.quantity ? errors.quantity : ''} />
                        </IonCol>
                        <IonCol size="4">
                            <SeaInput
                                label="Minimum Quantity"
                                name="minQuantity"
                                value={values.minQuantity}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="number"
                                inputmode="numeric"
                                error={touched.minQuantity ? errors.minQuantity : ''}
                            />
                        </IonCol>
                        <IonCol size="4">
                            <SeaInput
                                label="Manufacturer"
                                name="manufacturer"
                                value={values.manufacturer}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.manufacturer ? errors.manufacturer : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput label="Model" name="model" value={values.model} onchange={handleChange} onblur={handleBlur} zone="white" type="text" inputmode="text" error={touched.model ? errors.model : ''} />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput label="Part Number" name="partNum" value={values.partNum} onchange={handleChange} onblur={handleBlur} zone="white" type="text" inputmode="text" error={touched.partNum ? errors.partNum : ''} />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                label="Unit Price"
                                name="unitPrice"
                                value={values.unitPrice}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="decimal"
                                prefix={getCurrencyFromRegion(licenseeSettings?.region)}
                                error={touched.unitPrice ? errors.unitPrice : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea label="Notes" name="notes" value={values.notes} onchange={handleChange} onblur={handleBlur} zone="white" inputmode="text" error={touched.notes ? errors.notes : ''} />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload label="Images / Documents" files={files} setFiles={setFiles} collection="spareParts" field="files" />
                        </IonCol>

                        {(contacts?.all?.length || 0) > 0 && (
                            <>
                                <IonCol size="12"></IonCol>
                                <div className="line-2"></div>
                                <IonCol size="12"></IonCol>
                                <IonCol size="12">
                                    <SeaMultiSelect label="Connect Contact" values={contactIds} setValues={setContactIds} options={contactOptions} useAllOption={true} emptyText="Not Set" />
                                </IonCol>
                            </>
                        )}
                    </IonRow>
                </IonGrid>
                <div className="grid-row-spacer"></div>
                <SeaFormHasErrors hasSubmitted={hasSubmitted} isValid={isValid} />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">
                        {itemToUpdate ? 'Update ' : 'Save New '}
                        {vessel?.isShoreFacility ? 'Item' : 'Part'}
                    </SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditSparePart;
