import React, { useState, useEffect, useMemo } from 'react';
import { logPageView } from '../../../lib/firebase';
import { taskStatuses } from '../../../lib/util';
import { useReportSettings } from '../Reporting';
import { renderFullName, renderFullNameForUserId } from '../../../shared-state/Core/users';
import { UserType } from '../../../shared-state/Core/user';
import { sharedState } from '../../../shared-state/shared-state';
import { CrewCertificate } from '../../../shared-state/Crew/crewCertificates';
import { useReportingActionLog } from '../../../shared-state/Reporting/CrewReporting/useReportingActionLog';
import { renderVesselName } from '../../../shared-state/Core/vessels';
import reporting, { colours } from '../../../lib/reporting';
import SeaHorizontalStackedBarGraph from '../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import ViewCrewCertificatesReport from '../../../modals/Reporting/Crew/ViewCrewCertificatesReport/ViewCrewCertificatesReport';
import ViewActionsLoggedReport from '../../../modals/Reporting/Crew/ViewActionsLoggedReport/ViewActionsLoggedReport';
import SeaHorizontalBarGraph, { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import './CrewReporting.css';

interface CrewReportingProps {
    visible: boolean,
    graphAvailability: {
        count: number,
        crewCertificateStatus: boolean,
        crewActionsLogged: boolean
    }
}

const CrewReporting: React.FC<CrewReportingProps> = ({
    visible,
    graphAvailability
}) => {
    const today = sharedState.today.use()!;
    const users = sharedState.users.use(visible ? 1 : 101);
    const crewCertificates = sharedState.crewCertificates.use(visible ? 1 : 101);
    const [modalToShow, setModalToShow] = useState<string>();
    const [selectedStatuses, setSelectedStatuses] = useState(['overdue', 'upcoming']);
    const { selectedVesselIds, selectedVesselId, dateRangeDescription } = useReportSettings();

    useEffect(() => {
        if (visible) {
            logPageView('Reporting/CrewReporting');
        }
    }, [visible]);

    const filteredUsers = useMemo(() => {
        if (
            visible &&
            graphAvailability.count > 0 &&
            users &&
            selectedVesselIds?.length
        ) {
            const hasVesselId: {
                [vesselId: string]: boolean
            } = {};
            selectedVesselIds.forEach((vesselId: string) => {
                hasVesselId[vesselId] = true;
            });

            const list: UserType[] = [];
            const byId: {
                [userId: string]: UserType
            } = {};

            const processUsers = (_users: UserType[]) => {
                for (let i = 0; i < _users.length; i++) {
                    const u = _users[i];
                    if (u.state !== 'active') {
                        continue;
                    }
                    if (u.vesselIds && u.id) {
                        for (let j = 0; j < u.vesselIds.length; j++) {
                            if (hasVesselId[u.vesselIds[j]]) {
                                list.push(u);
                                byId[u.id] = u;
                                break;
                            }
                        }
                    }
                }
            };

            processUsers(users?.staff);
            processUsers(users?.nonStaff);

            list.sort((a, b) => {
                if (!a.firstName && !b.firstName) {
                    if (!a.lastName && !b.lastName) return 0;
                    if (!a.lastName) return 1;
                    if (!b.lastName) return -1;
                    return a.lastName.localeCompare(b.lastName);
                }
                if (!a.firstName) return 1;
                if (!b.firstName) return -1;
            
                if (a.firstName === b.firstName) {
                    if (!a.lastName && !b.lastName) return 0;
                    if (!a.lastName) return 1;
                    if (!b.lastName) return -1;
                    return a.lastName.localeCompare(b.lastName);
                }
                return a.firstName.localeCompare(b.firstName);
            });
            return {
                list,
                byId
            };
        }
        return undefined;
    }, [visible, graphAvailability, users, selectedVesselIds]);

    const filteredActionLog = useReportingActionLog(
        visible && graphAvailability.crewActionsLogged,
        selectedVesselIds,
        filteredUsers
    );

    const filteredCrewCertificates = useMemo(() => {
        if (selectedStatuses?.length === 0 || selectedVesselIds?.length === 0) {
            // Nothing to load
            return [];
        }
        if (
            graphAvailability.crewCertificateStatus &&
            filteredUsers?.byId &&
            crewCertificates &&
            selectedStatuses
        ) {
            const includeOverdue = (selectedStatuses.indexOf('overdue') !== -1);
            const includeUpcoming = (selectedStatuses.indexOf('upcoming') !== -1);
            if (!includeOverdue && !includeUpcoming) {
                return undefined;
            }
            const array: CrewCertificate[] = [];
            for (let i = 0; i < crewCertificates.numHighestPriority; i++) {
                const certificate = crewCertificates.prioritised[i];
                if (!filteredUsers.byId[certificate.heldBy]) {
                    continue;
                }
                if (!includeOverdue && certificate.dateExpires && certificate.dateExpires < today) {
                    continue;
                }
                if (!includeUpcoming && certificate.dateExpires && certificate.dateExpires >= today) {
                    continue;
                }
                array.push(certificate);
            }
            return array;
        }
        return undefined;
    }, [selectedStatuses, selectedVesselIds?.length, graphAvailability.crewCertificateStatus, filteredUsers?.byId, crewCertificates, today]);

    const crewCertificatesGraphData = useMemo(() => {
        if (filteredCrewCertificates) {
            if (filteredUsers === undefined) {
                return [];
            }
            const array: GraphData[] = [];
            const byUserId: {
                [userId: string]: GraphData
            } = {};
            const indexByStatus = {
                'overdue': 0,
                'upcoming': 1
            }
            filteredCrewCertificates.forEach((certificate) => {
                if (byUserId[certificate.heldBy] === undefined) {
                    byUserId[certificate.heldBy] = {
                        name: `${filteredUsers.byId[certificate.heldBy].firstName} ${filteredUsers.byId[certificate.heldBy].lastName}`,
                        values: [0, 0]
                    } as GraphData;
                    array.push(byUserId[certificate.heldBy]);
                }
                if (certificate.dateExpires && certificate.dateExpires < today) { // Overdue item
                    byUserId[certificate.heldBy].values![indexByStatus.overdue]++;
                } else { // Upcoming item
                    byUserId[certificate.heldBy].values![indexByStatus.upcoming]++;
                }
            });

            const sorted: GraphData[] = [];
            filteredUsers.list.forEach((u) => {
                if (u.id && byUserId[u.id]) {
                    sorted.push(byUserId[u.id]);
                }
            });

            return sorted;
        }
        return undefined;
    }, [filteredCrewCertificates, filteredUsers, today]);

    const selectedStatusNames = useMemo(() => {
        return Object.keys(taskStatuses).map((statusId: string) => {
            if (selectedStatuses.indexOf(statusId) !== -1) {
                return taskStatuses[statusId];
            } else {
                return undefined; // Store undefined in array so it can be skipped
            }
        });
    }, [selectedStatuses]);

    const actionLogGraphData = useMemo(() => {
        if (filteredActionLog && filteredUsers?.list) {
            const array = [] as {name: string, value: number}[];
            
            const byUserId: {
                [userId: string]: {
                    name: string,
                    value: number
                }
            } = {};

            filteredUsers.list.forEach((u: UserType) => {
                const item = {
                    name: renderFullName(u),
                    value: 0
                };
                byUserId[u.id as string] = item;
                array.push(item);
            });

            filteredActionLog.forEach((action) => {
                if (action.userId && !byUserId[action.userId]) {
                    byUserId[action.userId] = {
                        name: renderFullNameForUserId(action.userId),
                        value: 0
                    };
                }
                byUserId[action.userId].value++;
            });

            return array;
        }
        return undefined;
    }, [filteredActionLog, filteredUsers]);

    const dateRangeSubTitle = useMemo(() => {
        return `(${dateRangeDescription})`;
    }, [dateRangeDescription]);

    const subtitle = useMemo(() => `(${selectedVesselId ? `${renderVesselName(selectedVesselId)}, ` : ''}${dateRangeDescription})`, [dateRangeDescription, selectedVesselId]);

    if (!visible) {
        return null;
    }

    let n = 0;

    return (
        <>
            <div className={`reporting-grid max-${graphAvailability.count}-graphs`}>
                {graphAvailability.crewCertificateStatus && crewCertificatesGraphData &&
                    <SeaHorizontalStackedBarGraph
                        n={n++}
                        title={"Crew Certificates"}
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('crewCertificateStatus');
                        }}
                        data={crewCertificatesGraphData}
                        units="Certificates"
                        categories={selectedStatusNames}
                        colourPalette={reporting.colours.taskStatuses}
                        yLabelWidth={140}
                    />
                }
                {graphAvailability.crewActionsLogged &&
                    <SeaHorizontalBarGraph
                        n={n++}
                        title="Actions Logged by Crew Member"
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('crewActionsLogged');
                        }}
                        data={actionLogGraphData}
                        sortData={true}
                        units="Actions Logged"
                        colourPalette={[colours.beige]}
                        hashNamesForColours={false}
                        yLabelWidth={140}
                    />
                }
            </div>
            {visible &&
                <>
                    {graphAvailability.crewCertificateStatus &&
                        <ViewCrewCertificatesReport
                            title="Crew Certificates"
                            subTitle={subtitle}
                            showModal={modalToShow === 'crewCertificateStatus'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'crewCertificateStatus' : undefined);
                            }}
                            graphData={crewCertificatesGraphData}
                            filteredItems={filteredCrewCertificates}
                            itemsById={filteredUsers?.byId}
                            categories={selectedStatusNames}
                            selectedStatuses={selectedStatuses}
                            setSelectedStatuses={setSelectedStatuses}
                            yLabelWidth={140}
                        />
                    }
                    {graphAvailability.crewActionsLogged &&
                        <ViewActionsLoggedReport
                            title="Actions Logged by Crew Member"
                            subTitle={subtitle}
                            showModal={modalToShow === 'crewActionsLogged'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'crewActionsLogged' : undefined);
                            }}
                            graphData={actionLogGraphData}
                            filteredItems={filteredActionLog}
                            yLabelWidth={140}
                            colourPalette={[colours.beige]}
                        />
                    }
                </>
            }
        </>
    );
};

export default CrewReporting;
