import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from 'react';
import { CreateableDocument, UpdateableDocument } from '../shared-state';
import { Risk } from './risks';
import { registerFiles } from "../FileSyncSystem/filesToCache";

export interface RiskReviewed extends CreateableDocument, UpdateableDocument {
    files: string[];
    notes?: string;
    reviewedBy: string;
    riskId: string;
    state: string;
    vesselIds: string[];
    dateReviewed: string;
}

export type RisksReviewedData = RiskReviewed[];

export const useRisksReviewed = (selectedRisk: Risk | undefined) => {
    const [risksReviewed, setRisksReviewed] = useState<RiskReviewed[]>();
    useEffect(() => {
        setRisksReviewed(undefined);
        if (selectedRisk) {
            return onSnapshot(
                query(
                    collection(firestore, 'risksReviewed'),
                    where('riskId', '==', selectedRisk.id),
                    where('state', '==', 'active'),
                    orderBy('dateReviewed', 'desc')
                ),
                (snap) => {
                    const _risksReviewed = snap.docs.map((doc) => {
                        registerFiles(doc.data().files, 'risksReviewed', doc.data());
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as RiskReviewed;
                    });
                    setRisksReviewed(_risksReviewed);
    
                }, (error) => {
                    console.log(`Failed to access risks reviewed for risk ${selectedRisk.id} `, error);
                }
            );
        }
    }, [selectedRisk])
    
    return risksReviewed;
};
