import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Document, View, Image, Text, Page } from '@react-pdf/renderer';
import { pdfStyles } from '../../../lib/pdf';
import { getImgSrc, getImgSrcFromExt, makeSeaFiles } from '../../../lib/files';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { formatValue, formatTextAreaText } from '../../../lib/util';
import { formatDate, formatEmailReminder, formatDatetime } from '../../../lib/datesAndTime';
import { CorrectiveAction } from '../../../shared-state/HealthSafety/correctiveActions';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import SeaAuthorshipPDF from '../../../components/SeaAuthorshipPdf';

interface CorrectiveActionPdfProps {
    onCompleted: () => void;
    selectedItem: CorrectiveAction;
}

const CorrectiveActionPdf: React.FC<CorrectiveActionPdfProps> = ({ onCompleted, selectedItem }) => {
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [completedImagesLoaded, setCompletedImagesLoaded] = useState(false);
    const [actionImages, setActionImages] = useState<{ reference: number; element: JSX.Element; src: string }[]>([]);
    const [completedImages, setCompletedImages] = useState<{ reference: number; element: JSX.Element; src: string }[]>([]);
    const imagesRef = useRef<{ reference?: number; element?: JSX.Element; src: string | Promise<string>; fileName: string }[]>([]);

    const addedBy = selectedItem.addedBy ? renderFullNameForUserId(selectedItem.addedBy) : undefined;
    const completedBy = selectedItem.completedBy ? renderFullNameForUserId(selectedItem.completedBy) : undefined;
    const updatedBy = selectedItem.updatedBy ? renderFullNameForUserId(selectedItem.updatedBy) : undefined;

    const reportTitle = useMemo(() => {
        if (selectedItem) {
            return `Corrective Action - ${selectedItem.title}`;
        }
        return '';
    }, [selectedItem]);

    const reportFilename = useMemo(() => {
        if (selectedItem) {
            return `Corrective Action - ${selectedItem.title}`;
        }
        return '';
    }, [selectedItem]);

    const getImageSrcs = useCallback(
        (files: string[], setLoaded: (value: React.SetStateAction<boolean>) => void) => {
            const renderedImages: { reference: number; element: JSX.Element; src: string }[] = [];
            if (files?.length) {
                let index = -1;
                for (const _file of files) {
                    index++;
                    const file = makeSeaFiles([_file])[0];
                    const ext = file.ext?.toLowerCase();
                    const isImage = ext === 'jpg' || ext === 'jpeg' || ext === 'png';
                    const imgSrc = isImage ? getImgSrc(file.state!, file.id!, file.ext!, 'full') : getImgSrcFromExt(file.ext, 'medium');
                    const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                    if (isImage) {
                        imagesRef.current.push({
                            src: imgSrc,
                            fileName: fileName,
                        });
                    }
                    renderedImages.push({
                        element: (
                            <View key={_file} style={{ flexDirection: 'row' }}>
                                <View style={index === selectedItem.files.length - 1 ? pdfStyles.thumbColumn : pdfStyles.thumbSeriesColumn}>
                                    <Image src={imgSrc} style={pdfStyles.thumbImg} />
                                </View>
                                <Text style={{ alignSelf: 'center' }}>&nbsp;{fileName}</Text>
                                <Text
                                    style={{
                                        alignSelf: 'center',
                                        padding: '0 0 0.25cm 0.02cm',
                                        fontSize: '7pt',
                                    }}
                                >
                                    &nbsp;{imagesRef.current.length}
                                </Text>
                            </View>
                        ),
                        reference: imagesRef.current.length,
                        src: imgSrc as any,
                    });
                }
            }
            setLoaded(true);
            return renderedImages;
        },
        [selectedItem.files]
    );

    useEffect(() => {
        // Reset the imagesRef array to prevent duplicates
        imagesRef.current = [];
        setActionImages(getImageSrcs(selectedItem.files, setImagesLoaded));
        if (selectedItem.completedFiles) {
            setCompletedImages(getImageSrcs(selectedItem.completedFiles, setCompletedImagesLoaded));
        } else {
            setCompletedImagesLoaded(true);
        }
    }, [getImageSrcs, selectedItem.completedFiles, selectedItem.files]);

    const MakePDF = useCallback(() => {
        if (!selectedItem) return <></>;
        return (
            <Document title={reportFilename} author="Sea Flux" creator="Sea Flux" producer="Sea Flux">
                <Page
                    style={{
                        ...pdfStyles.page,
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <View wrap={false} style={pdfStyles.header}>
                        <Text style={pdfStyles.heading}>{reportTitle}</Text>
                    </View>

                    {/* Authorship */}
                    <SeaAuthorshipPDF item={selectedItem} addedBy={addedBy} completedBy={completedBy} updatedBy={updatedBy} />

                    {/* Task and Corrective Action */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Title</Text>
                            <Text>{formatValue(formatTextAreaText(selectedItem?.title))}</Text>
                        </View>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Action #</Text>
                            <Text>{formatValue(selectedItem?.correctiveActionNum)}</Text>
                        </View>
                    </View>

                    {/* Description */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Description</Text>
                            <Text>{formatTextAreaText(selectedItem?.description)}</Text>
                        </View>
                    </View>

                    {/* Assigned To and Tags and Due Date */}
                    {
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Assigned To</Text>
                                <Text>{formatValue(renderFullNameForUserId(selectedItem?.assignedTo))}</Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Tags</Text>
                                <Text>{formatValue(selectedItem?.tags?.join(', '))}</Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>When Added</Text>
                                <Text>{selectedItem?.whenAdded ? formatDatetime(selectedItem?.whenAdded) : '-'}</Text>
                            </View>
                        </View>
                    }

                    {/* Email Reminder and Added By */}
                    {
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Email Reminder</Text>
                                <Text>{formatValue(formatEmailReminder(selectedItem?.emailReminder))}</Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Added By</Text>
                                <Text>{formatValue(renderFullNameForUserId(selectedItem?.addedBy))}</Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Due Date</Text>
                                <Text>{selectedItem?.dateDue ? formatDate(selectedItem?.dateDue) : '-'}</Text>
                            </View>
                        </View>
                    }

                    {/* Action Images */}
                    <View wrap={false} style={pdfStyles.rowNoSpace}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Images / Documents</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={[pdfStyles.column1, { flexDirection: 'column', textAlign: 'center' }]}>
                            {actionImages?.length ? (
                                actionImages.map((image) => image.element)
                            ) : (
                                <View wrap={false} style={pdfStyles.row}>
                                    <View style={[pdfStyles.column1, { textAlign: 'left' }]}>
                                        <Text>-</Text>
                                    </View>
                                </View>
                            )}
                        </View>
                    </View>

                    {/* Completed Details */}
                    {selectedItem?.state === 'completed' && (
                        <>
                            <View style={pdfStyles.greyLine}></View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.labelHeading}>Completed Details</Text>
                                </View>
                            </View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column3}>
                                    <Text style={pdfStyles.label}>Completed By</Text>
                                    <Text>{formatValue(renderFullNameForUserId(selectedItem?.completedBy))}</Text>
                                </View>
                                <View style={pdfStyles.column3}>
                                    <Text style={pdfStyles.label}>When Completed</Text>
                                    <Text>{formatValue(selectedItem?.whenCompleted && formatDate(selectedItem?.whenCompleted))}</Text>
                                </View>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.label}>Completed Notes</Text>
                                    <Text>{formatValue(selectedItem?.completedNotes)}</Text>
                                </View>
                            </View>

                            {/* Completed Images */}
                            <View wrap={false} style={pdfStyles.rowNoSpace}>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.label}>Completed Images / Documents</Text>
                                </View>
                            </View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={[pdfStyles.column1, { flexDirection: 'column', textAlign: 'center' }]}>
                                    {completedImages?.length ? (
                                        completedImages.map((image, index) => image.element)
                                    ) : (
                                        <View wrap={false} style={pdfStyles.row}>
                                            <View style={pdfStyles.column1}>
                                                <Text>-</Text>
                                            </View>
                                        </View>
                                    )}
                                </View>
                            </View>
                            <View style={pdfStyles.greyLine}></View>
                        </>
                    )}

                    {/* State */}
                    {selectedItem?.state === 'deleted' && (
                        <View wrap={false} style={pdfStyles.row}>
                            <Text>Corrective Action has been marked as {selectedItem?.state}</Text>
                        </View>
                    )}
                </Page>
                {imagesRef.current.length > 0 && (
                    <Page
                        style={{
                            ...pdfStyles.page,
                        }}
                        orientation="portrait"
                        size="A4"
                        wrap={true}
                    >
                        {imagesRef.current.map((img: any, index: number) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        maxWidth: '19cm',
                                        margin: '0 1cm 0 1cm',
                                        paddingBottom: '0.5cm',
                                    }}
                                    wrap={false}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text
                                            style={{
                                                alignSelf: 'center',
                                                padding: '0 0.02cm 0.25cm 0',
                                                fontSize: '7pt',
                                            }}
                                        >
                                            {index + 1}
                                        </Text>
                                        <Text>{img.fileName}</Text>
                                    </View>
                                    <Image
                                        src={img.src}
                                        style={{
                                            objectFit: 'scale-down',
                                            objectPositionX: 0,
                                            objectPositionY: 0,
                                        }}
                                    />
                                </View>
                            );
                        })}
                    </Page>
                )}
            </Document>
        );
    }, [selectedItem, reportFilename, reportTitle, actionImages, completedImages]);

    if (imagesLoaded && completedImagesLoaded) {
        return <SeaPdfGenerator onCompleted={onCompleted} pdfTitle={reportFilename} MakePdf={MakePDF} requestedFrom="CorrectiveAction" />;
    } else return <></>;
};
export default CorrectiveActionPdf;
