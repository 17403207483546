import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { useEffect, useState } from "react";
import { SafetyCheckItem } from "../../VesselSafety/safetyCheckItems";
import { getDayOffset, warnDays } from '../../../lib/datesAndTime';
import { sharedState } from "../../shared-state";
import { makeSafetyItems, sortSafetyItems } from './reportingSafetyFunctions';

export const useReportingDueSafetyChecks = (
    isActive: boolean,
    selectedVesselIds: string[],
    selectedSafetyCheckStatuses: string[]
) => {
    const today = sharedState.today.use()!;
    const [reportingDueSafetyChecks, setReportingDueSafetyChecks] = useState<SafetyCheckItem[]>();

    useEffect(() => {
        setReportingDueSafetyChecks(undefined);
        if (
            isActive &&
            selectedVesselIds?.length
        ) {
            const includeOverdue = (selectedSafetyCheckStatuses.indexOf('overdue') !== -1);
            const includeUpcoming = (selectedSafetyCheckStatuses.indexOf('upcoming') !== -1);
            if (!includeOverdue && !includeUpcoming) {
                return;
            }

            const baseConstraints = [
                where('state', '==', 'active')
            ];
            baseConstraints.push(
                where('hasFault', '==', false)
            );
            if (includeOverdue && includeUpcoming) {
                baseConstraints.push(
                    where('dateDue', '<', getDayOffset(warnDays.safetyEquipmentChecks[0]))
                );
            } else if (includeOverdue) { // Include overdue only
                baseConstraints.push(
                    where('dateDue', '<', today)
                );
            } else { // Include upcoming only
                baseConstraints.push(
                    where('dateDue', '<', getDayOffset(warnDays.safetyEquipmentChecks[0])),
                    where('dateDue', '>=', today)
                );
            }

            return setupArrayQueryListener(
                'safetyCheckItems', // what
                collection(firestore, 'safetyCheckItems'),
                baseConstraints,
                'vesselId',
                'in',
                selectedVesselIds,
                [orderBy('dateDue', 'asc')],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    const all = makeSafetyItems(docs) as SafetyCheckItem[];
                    if (isCombined) {
                        sortSafetyItems(all);
                    }
                    setReportingDueSafetyChecks(all);
                }
            );

        }
    }, [isActive, selectedVesselIds, selectedSafetyCheckStatuses, today]);

    return reportingDueSafetyChecks;

}
