import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from "react";

//
// Loads all serverEvents.
// This can only be used by superAdmin.
//

export type ServerEvent = {
    id: string,
    type: 'message',
    whenEvent: number,
    whenExpires?: number,
    isPending?: boolean,
    whenAdded: number,
    addedBy: string,
    state: string,
    title?: string,
    content?: string
};

export type ServerEventData = {
    all: ServerEvent[];
    byId: {
        [id: string]: ServerEvent
    };
};

export const useServerEvents = () => {
    const [serverEvents, setServerEvents] = useState<ServerEventData>();
    
    useEffect(() => {
        setServerEvents(undefined);
        return onSnapshot(
            query(
                collection(firestore, 'serverEvents'),
                orderBy('whenEvent', 'desc'),
            ),
            (snap) => {
                const all = snap.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    } as ServerEvent;
                });
                const byId = {} as {
                    [id: string]: ServerEvent
                };
                all.forEach((serverEvent: ServerEvent) => {
                    byId[serverEvent.id] = serverEvent;
                });
                setServerEvents({
                    all,
                    byId
                });
            },
            (error) => {
                console.log(`error getting serverEvents`, error);
            }
        );
    }, []);
    return serverEvents;
};
