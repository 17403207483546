import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from 'react';
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { TrainingTaskReportDocument } from "./useTrainingTaskReportDocuments";
import { sharedState } from "../shared-state";

export type TrainingTaskReportDocumentsData = {
    byId: { [id: string]: TrainingTaskReportDocument },
    byTaskId: { [id: string]: TrainingTaskReportDocument[] },
    byReportId: { [id: string]: TrainingTaskReportDocument[] },
    all: TrainingTaskReportDocument[]
};

export const useTrainingTaskReportDocumentsByUser = (personnelId?: string) => {
    const vesselIds = sharedState.vesselIds.use();
    const [trainingTaskReportDocuments, setTrainingTaskReportDocuments] = useState<TrainingTaskReportDocumentsData>();
    
    useEffect(() => {
        setTrainingTaskReportDocuments(undefined);
        if (personnelId && vesselIds) {
            const collectionRef = collection(firestore, 'trainingTaskReportDocuments');
            const queryConstraints = [
                where('vesselId', 'in', vesselIds),
                where('personnelIds', 'array-contains', personnelId),
                where('state', '==', 'active'),
                orderBy('whenAdded', 'desc')
            ];

            const q = query(collectionRef, ...queryConstraints);
            
            return onSnapshot(
                q,
                (snap) => {
                    const byId = {} as {
                        [id: string]: TrainingTaskReportDocument
                    }; 
                    const byTaskId = {} as {
                        [id: string]: TrainingTaskReportDocument[]
                    };
                    const byReportId = {} as {
                        [id: string]: TrainingTaskReportDocument[]
                    };
                    const all = snap.docs.map((doc) => {
                        const o = {
                            id: doc.id,
                            ...doc.data()
                        } as TrainingTaskReportDocument;
                        byId[o.id] = o;
                        registerFiles(o.files, 'trainingTaskReportDocuments', o);
                        if (!byTaskId[o.taskId]) {
                            byTaskId[o.taskId] = [];
                        }
                        byTaskId[o.taskId].push(o);
                        if (!byReportId[o.reportId]) {
                            byReportId[o.reportId] = [];
                        }
                        byReportId[o.reportId].push(o);
                        
                        return o;
                    });
                    setTrainingTaskReportDocuments({
                        byId,
                        byTaskId,
                        byReportId,
                        all
                    });
    
                }, (error) => {
                    // This should be very rare
                    console.log(`Failed to access trainingTaskReport documents for personnelId ${personnelId} `, error);
                }
            );
        }
    }, [personnelId]);

    return trainingTaskReportDocuments;
};
