import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { IonSpinner } from '@ionic/react';
import { getFileSrcFromString } from '../../lib/files';
import { getCachedFileSrc } from '../../shared-state/FileSyncSystem/cachedFiles';
import { sharedState } from '../../shared-state/shared-state';
import _ from 'lodash';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './SeaPDFViewer.css';

const pdfWorker = `${process.env.PUBLIC_URL}assets/pdf/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

type SeaPDFViewerProps = {
    file?: string;
    size?: 'tiny' | 'tiny2' | 'medium' | 'full';
    maxPages?: number;
    showFullPDFBtn?: boolean;
    onClick?: (e: React.MouseEvent) => void;
    className?: string;
    allowScroll?: boolean;
    isFullScreen?: boolean;
};

const SeaPDFViewer: React.FC<SeaPDFViewerProps> = ({ file, maxPages, showFullPDFBtn, size = 'full', onClick, className, allowScroll, isFullScreen }) => {
    const onlineStatus = sharedState.onlineStatus.use();
    const [loading, setLoading] = useState<boolean>(true);
    const [pdfURI, setPDFURI] = useState<string>();
    const [numPages, setNumPages] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isDocumentSmallerThanContainer, setIsDocumentSmallerThanContainer] = useState(false);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [containerWidth, setContainerWidth] = useState<number>();
    const [containerHeight, setContainerHeight] = useState<number>();
    const [layoutRendered, setLayoutRendered] = useState(false);
    const [failedToLoad, setFailedToLoad] = useState<string>();
    const [falseError, setFalseError] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const lastElementObserver = useRef({} as any);
    const isLastElementVisibleRef = useRef(false);
    const resizeCompleteTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    // This useMemo hook calculates the width of the PDF viewer based on the size and loading state.
    const width = useMemo(() => {
        if (size === 'tiny' && loading) {
            return 40;
        } else if (size === 'tiny') {
            return 28;
        } else if (size === 'tiny2' && loading) {
            return 40;
        } else if (size === 'tiny2') {
            return 40;
        } else if (size === 'medium' && loading) {
            return 100;
        } else if (size === 'medium') {
            return 200;
        } else if (size === 'full') {
            return undefined;
        }
    }, [loading, size]);

    const pageHeight = useMemo(() => {
        const pageAspectRatio = 0.71; // Aspect ratio of a standard page
        return containerWidth ? containerWidth / pageAspectRatio : 0;
    }, [containerWidth]);

    const placeholderImageSrc = useMemo(() => {
        let src = '/assets';
        switch (failedToLoad) {
            case 'broken':
                src += '/missing';
                break;
            case 'offline':
                src += '/offline';
                break;
            default:
                src += '/file-pdf';
                break;
        }
        if (size.includes('tiny')) {
            src += '_tiny';
        }
        src += '@2x.png';
        return src;
    }, [size, failedToLoad]);

    // Fetches URI of PDF file and sets the number of pages
    useEffect(() => {
        let isMounted = true;
        let cancelFetch = false;
        let _uri = '';
        let errorMessage = '';

        if (!file) return;
        setLoading(true);
        setFailedToLoad(undefined); // Reset failed state on each attempt
    
        if (file.startsWith('data:application/pdf')) {
            _uri = file;
            setLoading(false);
            setPDFURI(_uri);
            return;
        }
        getCachedFileSrc(file).then((uri) => {
            if (cancelFetch) return;
            if (!uri) throw new Error('File not cached offline');
            if (isMounted && uri) {
                setNumPages(maxPages || 1);
                _uri = uri;
            }
        }).catch((error) => {
            if (onlineStatus?.isOnline) {
                return getFileSrcFromString(file).then((uri) => {
                    if (cancelFetch) return;
                    if (isMounted) {
                        setNumPages(maxPages || 1);
                        _uri = uri;
                    }
                }).catch(() => {
                    errorMessage = 'broken';
                });
            } else {
                errorMessage = 'offline';
                return;
            }
        }).finally(() => {
            if (isMounted) {
                if (_uri) {
                    setPDFURI(_uri);
                } else {
                    setFailedToLoad(errorMessage);
                }
                setLoading(false);
            }
        });

        return () => {
            isMounted = false;
            cancelFetch = true;
        };
    }, [file, maxPages, onlineStatus?.isOnline]);

    const debouncedHandleResize = _.debounce(function handleResize() {
        const containerWidth = containerRef.current?.offsetWidth || 0;
        const containerHeight = containerRef.current?.offsetHeight || 0;
        const pageAspectRatio = 0.71; // Standard page aspect ratio (width / height)
    
        let newWidth = containerWidth;
        let newHeight = containerHeight;
    
        if (!allowScroll) {
            // Calculate dimensions to fit the container while maintaining aspect ratio
            if (containerWidth / containerHeight > pageAspectRatio) {
                // Container is wider than the page aspect ratio
                newHeight = containerHeight;
                newWidth = newHeight * pageAspectRatio;
            } else {
                // Container is taller than the page aspect ratio
                newWidth = containerWidth;
                newHeight = newWidth / pageAspectRatio;
            }
        }
    
        if (newWidth && numPages && newHeight) {
            const documentHeight = numPages * (newWidth / pageAspectRatio);
            setIsDocumentSmallerThanContainer(
                documentHeight < containerHeight && newWidth < containerWidth
            );
        }
    
        setContainerWidth(newWidth);
        setContainerHeight(newHeight);
    
        // Clear any existing timeout to ensure it doesn't fire prematurely
        if (resizeCompleteTimeoutRef.current) {
            clearTimeout(resizeCompleteTimeoutRef.current);
        }
    
        // Set a new timeout that will mark layout as rendered after a certain period of no further resize events
        resizeCompleteTimeoutRef.current = setTimeout(() => {
            setLayoutRendered(true);
        }, 300);
    }, 200);

    // Sets the container width and handles the resize event
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
        if (size !== 'full') {
            setContainerWidth(width);
            setLayoutRendered(true);
            return;
        }
        debouncedHandleResize();
        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
            debouncedHandleResize.cancel();
        };
    });

    useEffect(() => {
        return () => {
            // Cleanup timeout when component unmounts or dependencies change
            if (resizeCompleteTimeoutRef.current) {
                clearTimeout(resizeCompleteTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!allowScroll) {
            return;
        }

        const measureDivPosition = () => {
            if (containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                const topPosition = rect.top; // Distance from the top of the viewport to the top of the div
                if (!topPosition) { // Handles case where containerRef.current has been initialized but its position has not been updated
                    setTimeout(measureDivPosition, 10);
                    return;
                }
                const availableSpace = window.innerHeight - topPosition; // Space from the top of the div to the bottom of the window

                setContainerHeight(availableSpace);
            }
        };

        const handleResize = () => {
            if (window.innerHeight !== windowHeight) {
                setWindowHeight(window.innerHeight);
                measureDivPosition();
            }
        };

        // Initial measurement and event listener setup
        measureDivPosition();
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowScroll, windowHeight, containerRef.current]);

    useEffect(() => {
        if (!layoutRendered || totalPages === 0) {
            return;
        }

        if (size !== 'full') {
            setNumPages(1);
        } else {
            const visiblePages = allowScroll ? Math.ceil((containerHeight || 0) / pageHeight) : 1;

            if (allowScroll && containerHeight && isLastElementVisibleRef.current) {
                const pagesToLoad = Math.min(visiblePages, totalPages);
                setNumPages(maxPages && pagesToLoad > maxPages ? maxPages : pagesToLoad);
            } else if (!allowScroll) {
                setNumPages(maxPages && totalPages > maxPages ? maxPages : 1);
            }

            if (containerWidth && containerHeight) {
                setIsDocumentSmallerThanContainer(totalPages * containerWidth / 0.71 < containerHeight);
            }
        }
    }, [layoutRendered, totalPages, size, containerHeight, pageHeight, allowScroll, containerWidth, maxPages]);

    // Handles when pdf document is loaded
    const onDocumentLoadSuccess = useCallback(({ numPages: loadedNumPages }: { numPages: number }) => {
        setFalseError(false);
        setTotalPages(loadedNumPages);
    }, []);

    const triggerElementRef = useCallback((node) => {
        if (!allowScroll || (numPages && numPages >= totalPages) || loading) {
            return;
        }
        if (lastElementObserver.current?.disconnect) {
            lastElementObserver.current.disconnect();
        }
    
        lastElementObserver.current = new IntersectionObserver((entries) => {
            isLastElementVisibleRef.current = entries[0].isIntersecting;
            if (isLastElementVisibleRef.current) {
                setNumPages(Math.min(totalPages, numPages + 1));
            }
        }, {
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin around the root
            threshold: 1.0 // Trigger only when 100% of the target is visible
        });
    
        if (node) {
            lastElementObserver.current.observe(node);
        }
    }, [allowScroll, loading, numPages, totalPages]);

    useEffect(() => {
        return () => {
            if (lastElementObserver.current?.disconnect) {
                lastElementObserver.current.disconnect();
            }
        };
    }, []); // Ensure cleanup runs on component unmount

    const hidePageCanvas = useCallback(() => {
        const canvas = containerRef.current?.querySelector('canvas');
        if (canvas) canvas.style.visibility = 'hidden';
    }, [containerRef]);
      
    const showPageCanvas = useCallback(() => {
        const canvas = containerRef.current?.querySelector('canvas');
        if (canvas) {
            setLoading(false);
            canvas.style.visibility = 'visible';
        }
    }, [containerRef]);
     
    const onPageLoadSuccess = useCallback(() => {
        hidePageCanvas();
    }, [hidePageCanvas]);

    const onPageRenderSuccess = useCallback(() => {
        showPageCanvas();
    }, [showPageCanvas]);

    const onPageRenderError = useCallback(() => {
        showPageCanvas();
    }, [showPageCanvas]);

    useEffect(() => {
        if (falseError) {
            const errorMessageElement = containerRef.current?.querySelector('.react-pdf__message--error') as HTMLElement;
            if (errorMessageElement) {
                errorMessageElement.style.display = 'none';
            }
        } else {
            const errorMessageElement = containerRef.current?.querySelector('.react-pdf__message--error') as HTMLElement;
            if (errorMessageElement) {
                errorMessageElement.style.display = 'block';
            }
        }
    }, [falseError, containerRef]);

    return (
        <div className={`pdf-viewer-flex-container ${className ?? ''}`} style={{ position: isFullScreen ? 'absolute' : 'relative' }}>
            <div
                className={`sea-pdf-viewer document-container ${size} ${isDocumentSmallerThanContainer || !allowScroll ? 'center-document' : ''}`}
                style={{ minHeight: pageHeight }}
                ref={containerRef}
                placeholder='...'
            >
                {failedToLoad && (
                    <img
                        src={placeholderImageSrc}
                        alt={failedToLoad}
                        className={`sea-file-image ${size} file`}
                        onClick={onClick}
                    />
                )}
                {loading ? (
                    <IonSpinner name="crescent" className="sea-spinner" />
                ) : null}
                {layoutRendered && pdfURI && !failedToLoad && (
                    <Document
                        file={pdfURI}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={(e: any) => {
                            if (e.message === 'Worker was destroyed') {
                                setFalseError(true);
                                console.log('Worker was destroyed, handling silently');
                            } else {
                                console.log('onLoadError', e);
                                // setFailedToLoad('broken');
                                setLoading(false);
                            }
                        }}
                        onError={() => {
                            console.log('onError loading pdf');
                            setLoading(false);
                        }}
                        renderMode='canvas'
                        className={`${onClick ? 'clickable' : ''}`}
                        onClick={onClick}
                        loading={() => null}
                    >
                        {Array.from({ length: numPages || 0 }, (_, index) => (
                            <div className="page-container" key={index}>
                                <Page
                                    onLoadSuccess={onPageLoadSuccess}
                                    onRenderSuccess={onPageRenderSuccess}
                                    onRenderError={onPageRenderError}
                                    renderTextLayer={false}
                                    pageNumber={index + 1}
                                    width={containerWidth}
                                    loading={() => null}
                                />
                            </div>
                        ))}
                    </Document>
                )}
            </div>
            {allowScroll && !loading && (numPages || 0) < totalPages ?
                <div ref={triggerElementRef} style={{ height: '8px', width: '100%' }}></div>
                : null}
        </div>
    );
};

export default SeaPDFViewer;