import React, { useMemo, useCallback, ReactNode, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { cloneDivisions, createEmptyRootDivision, Division, Divisions } from '../../../shared-state/Core/divisions';
import { useFormik } from 'formik';
import { hasArrayChanged, haveValuesChanged, preventMultiTap } from '../../../lib/util';
import { firestore } from '../../../lib/firebase';
import { collection, doc } from 'firebase/firestore';
import { sharedState } from '../../../shared-state/shared-state';
import { Vessel } from '../../../shared-state/Core/vessel';
import { confirmAction } from '../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { alertMessage } from '../../../managers/AlertManager/AlertManager';
import Yup from '../../../lib/yup';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../components/SeaLinkButton/SeaLinkButton';
import SeaSelectVessels from '../../../components/SeaSelectVessels/SeaSelectVessels';
import SeaSelectDivision from '../../../components/SeaSelectDivision/SeaSelectDivision';


interface EditDivisionModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    divisions?: Divisions,
    setDivisions: React.Dispatch<React.SetStateAction<Divisions | undefined>>
    divisionToUpdate?: Division | undefined,
    level?: number
}

const EditDivisionModal: React.FC<EditDivisionModalProps> = ({
    showModal,
    setShowModal,
    divisions,
    setDivisions,
    divisionToUpdate,
    level
}) => {
    const vessels = sharedState.vessels.use(showModal);
    const [selectedVesselIds, setSelectedVesselIds] = useState<string[]>([]);
    const [initialVesselIds, setInitialVesselIds] = useState<string[]>([]);

    const initialValues = useMemo(() => {
        if (divisionToUpdate) {
            return {
                parentId: divisionToUpdate?.parent?.id ?? 'root',
                name: divisionToUpdate.name ? ''+divisionToUpdate.name : ''
            };
        } else {
            return {
                parentId: 'root',
                name: ''
            };
        }
    }, [divisionToUpdate]);

    const onOpened = () => {
        resetForm();
        setValues(initialValues);
        // Init selectedVesselIds for this division
        const ids = [] as string[];
        divisionToUpdate?.vessels?.forEach((vessel) => {
            ids.push(vessel.id);
        });
        setSelectedVesselIds(ids);
        setInitialVesselIds([...ids]);
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, setFieldValue } = useFormik({
        initialValues: initialValues, 
        validationSchema: Yup.object({
            name: Yup.string().max(500).required()
        }), onSubmit: (data) => {
            if (preventMultiTap('division') || !vessels) {
                return;
            }
            const selectedVessels = selectedVesselIds?.map((vesselId) => {
                return {
                    ...vessels?.byId[vesselId]
                };
            }) as Vessel[];
            let editDivisionId: string; // division id being edited, either new or existing
            if (divisionToUpdate) {
                editDivisionId = divisionToUpdate.id;
                // Check parentId is valid
                if (divisionToUpdate.id === values.parentId) {
                    alertMessage('You cannot set a division\'s parent to be itself.');
                    return;
                }
                let isParentIdOk = true;
                const checkChild = (d: Division) => {
                    if (d.id === values.parentId) {
                        isParentIdOk = false;
                    }
                    d.children?.forEach((child) => {
                        checkChild(child);
                    });
                };
                checkChild(divisionToUpdate);
                if (!isParentIdOk) {
                    alertMessage('You cannot set a division\'s parent to be one of it\'s sub-divisions!');
                    return;
                }

                // Update existing division
                setDivisions((_divisions) => {
                    const divisions = cloneDivisions(_divisions!);
                    const division = divisions.byId[editDivisionId];
                    division.name = values.name.trim();
                    division.vessels = selectedVessels;
                    if (values.parentId !== division.parent!.id) {
                        // Remove from parent
                        const children = division.parent!.children!;
                        for (let i = 0; i < children.length; i++) {
                            if (children[i].id === division.id) {
                                children.splice(i, 1);
                                break;
                            }
                        }
                        // Set new parent
                        division.parent = divisions!.byId[values.parentId];
                        if (division.parent.children === undefined) {
                            division.parent.children = [];
                        }
                        division.parent.children.push(division);
                    }
                    return divisions;
                });
            } else if (divisions) {
                // Add new division
                editDivisionId = doc(collection(firestore, 'vessels')).id;
                setDivisions((_divisions) => {
                    const divisions = cloneDivisions(_divisions!);
                    const newDivision = {
                        id: editDivisionId,
                        name: values.name.trim(),
                        vessels: selectedVessels,
                        unique: Math.random()
                    } as Division;
                    const parent = divisions.byId[values.parentId];
                    if (parent.children === undefined) {
                        parent.children = [];
                    }
                    parent.children.push(newDivision);
                    newDivision.parent = parent;
                    divisions.all.push(newDivision);
                    divisions.byId[newDivision.id] = newDivision;
                    return divisions;
                });
            } else {
                // Create first division
                editDivisionId = doc(collection(firestore, 'vessels')).id;
                const root = createEmptyRootDivision();
                root.vessels = [...vessels!.all];
                const firstDivision = {
                    id: editDivisionId,
                    name: values.name.trim(),
                    parent: root,
                    vessels: selectedVessels,
                    unique: Math.random()
                } as Division;
                root.children!.push(firstDivision);
                setDivisions({
                    all: [firstDivision],
                    byId: {
                        [firstDivision.id]: firstDivision
                    },
                    root
                });
            }

            if (hasArrayChanged(selectedVesselIds, initialVesselIds)) {
                // Apply movements of vessels INTO and OUT OF editDivisionId
                setDivisions((current) => {
                    const divisions = {...current} as Divisions;

                    // Find vessels that are moving INTO divisionToUpdate
                    Object.values(divisions.byId).forEach((division) => {
                        if (division.id === editDivisionId) {
                            return;
                        }
                        for (let i = division.vessels.length - 1; i >= 0; i--) {
                            if (selectedVesselIds.includes(division.vessels[i].id)) {
                                // Found a vessel that is being moved INTO editDivisionId
                                division.vessels.splice(i, 1); // Remove from original division
                            }
                        }
                    });

                    // Find vessels that are moving OUT OF divisionToUpdate
                    divisionToUpdate?.vessels?.forEach((vessel) => {
                        if (!selectedVesselIds.includes(vessel.id)) {
                            // Found a vessel that is being moved OUT OF divisionToUpdate
                            if (!divisions.root.vessels.includes(vessel)) {
                                divisions.root.vessels.push(vessel); // Move it into the root division
                            }
                        }
                    });

                    return divisions;
                });
            }

            setShowModal(false);
        }
    });

    const renderDivisionOptions = useCallback((division: Division, output: ReactNode[] = [], level = 0) => {
        if (divisionToUpdate && divisionToUpdate.id === division.id) {
            return;
        }
        if (division.id !== 'root') {
            let indent = [] as ReactNode[];
            output.push(
                <IonSelectOption key={division.id} value={division.id}>{indent}{division.name}</IonSelectOption>
            );
        }
        division.children?.forEach((child) => {
            renderDivisionOptions(child, output, level + 1);
        });
        return output;
    }, [divisionToUpdate]);

    const onDeleteDivision = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();
        return confirmAction('Are you sure you want to delete this division?').then(() => {
            setDivisions((_divisions) => {
                const divisions = cloneDivisions(_divisions!);
                const division = divisions.byId[divisionToUpdate!.id];
                if (division.vessels.length > 0) {
                    // Move vessels into parent division
                    division.parent!.vessels = [...division.parent!.vessels, ...division.vessels];
                }
                if (division.children && division.children.length > 0) {
                    // Move sub-divisions into parent division
                    division.children.forEach((child) => {
                        child.parent = division.parent;
                    });
                    division.parent!.children = [...division.parent!.children!, ...division.children];
                }
                // Remove this division
                division.parent!.children = division.parent!.children!.filter((d) => d.id !== divisionToUpdate!.id);
                divisions.all = divisions.all.filter((d) => d.id !== divisionToUpdate!.id);
                delete divisions.byId[divisionToUpdate!.id];
                if (divisions.all && divisions.all.length > 0) {
                    return divisions;
                }
                return undefined; // No divisions left
            });
            setShowModal(false);
        }).catch(() => {
            console.log('Deletion cancelled');
        });
    }, [divisionToUpdate, setDivisions, setShowModal]);

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues),
            hasArrayChanged(selectedVesselIds, initialVesselIds)
        );
    }, [initialValues, initialVesselIds, selectedVesselIds, values]);

    return (
        <SeaModal
            title={divisionToUpdate ? 'Edit Division' : 'Add New Division'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="standard"
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInput
                                label="Division Name"
                                name="name"
                                value={values.name}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.name ? errors.name : ''}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <SeaSelectDivision
                                label="Parent Division"
                                divisionId={values.parentId}
                                setDivisionId={(divisionId) => setFieldValue('parentId', divisionId)}
                                divisions={divisions}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <SeaSelectVessels
                                label="Vessels"
                                vesselIds={selectedVesselIds!}
                                setVesselIds={setSelectedVesselIds}
                                emptyText='None'
                                help={{text: 'This should contain all the vessels that belong directly within this division (not within any sub-divisions)'}}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <div className="view-modal-buttons">
                    <SeaButton zone="white" size="wide" type="submit">
                        {divisionToUpdate ? 'Update Division' : 'Create Division'}
                    </SeaButton>
                    {divisionToUpdate &&
                        <>
                            <div className="spacer-wide"></div>
                            <SeaLinkButton mode="standard-link" onClick={onDeleteDivision}>Delete</SeaLinkButton>
                        </>
                    }
                </div>
            </form>
        </SeaModal>
    );
};

export default EditDivisionModal;
