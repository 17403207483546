import React, { ReactNode, useMemo } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { permissionLevels, PermissionRole } from '../../shared-state/Core/userPermissions';
import SeaIcon from '../SeaIcon/SeaIcon';

interface RequirePermissionsProps {
    children: ReactNode,
    role?: PermissionRole,
    level?: number,
    showDenial?: boolean,
    licenseePermission?: 'hasCorrectiveActions' | 'hasIncidents' | 'hasReporting' | 'hasSafetyCheckTaskTime' | 'hasMaintenanceTaskTime',
}

const RequirePermissions: React.FC<RequirePermissionsProps> = ({
    children,
    role,
    level = permissionLevels.VIEW,
    showDenial,
    licenseePermission
}) => {
    const userPermissions = sharedState.userPermissions.use();
    const licenseeSettings = sharedState.licenseeSettings.use();

    const isLicenseeDenied = useMemo(() => {
        if (licenseePermission) {
            if (!licenseeSettings?.[licenseePermission]) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }, [licenseePermission, licenseeSettings]);

    const isDenied = useMemo(() => {
        if (isLicenseeDenied) {
            return true;
        } 
        if (role) {
            if (userPermissions && userPermissions[role] >= level) {
                return false;
            }
        } else {
            return false;
        }
        return true;
    }, [isLicenseeDenied, role, userPermissions, level]);

    if (isDenied) {
        if (showDenial) {
            return (
                <div className="sea-no-data columns">
                    <div><SeaIcon icon="warn"/></div>
                    <div>{`${isLicenseeDenied ? 'Your licensee does' : 'You do'} not have permission to view this content`}</div>
                </div>
            );
        }
        return <></>;
    }
    return <>{children}</>;
};

export default RequirePermissions;
