import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch, deleteValue } from '../../../../lib/firebase';
import { doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, formatValue, formatTextAreaText } from '../../../../lib/util';
import { formatSeaDatetime } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { CorrectiveAction } from '../../../../shared-state/HealthSafety/correctiveActions';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';

interface CompleteCorrectiveActionProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setShowParentModal: (showModal: boolean) => void,
    selectedItem: CorrectiveAction,
    level?: number
}

const CompleteCorrectiveAction: React.FC<CompleteCorrectiveActionProps> = ({ showModal, setShowModal, setShowParentModal, selectedItem, level }) => {
    const userId = sharedState.userId.use(showModal);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [files, setFiles] = useState<SeaFile[]>([]);

    const initialValues = useMemo(() => ({
        whenCompleted: selectedItem.whenCompleted ? formatSeaDatetime(selectedItem.whenCompleted) : formatSeaDatetime(),
        completedNotes: selectedItem.completedNotes ? '' + selectedItem.completedNotes : '',
        completedBy: selectedItem.completedBy ? '' + selectedItem.completedBy : '',
        completedFiles: selectedItem.completedFiles ? selectedItem.completedFiles : []
    }), [selectedItem]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(selectedItem.completedFiles));
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting, isValid } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            whenCompleted: Yup.date().required().min(...notTooOld),
            notes: Yup.string().min(1).max(5000),
        }), onSubmit: (data) => {
            uploadFiles(files).then(() => {
                if (!selectedItem.id) {
                    console.log('No corrective action id');
                    return;
                }
                const _files = [...makeSeaFiles(selectedItem.completedFiles), ...files];

                const batch = splittableBatch(firestore, 20 - 1); // -1 because safetyCheckCompleted requires an extra security access call
                const batchTrace = makeBatchTrace(batch, 'correctiveActions', 'update');

                batchTrace.exampleDocId = selectedItem.id;
                batch.set(
                    doc(firestore, 'correctiveActions', selectedItem.id),
                        {
                            state: 'completed',
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            completedNotes: data.completedNotes ? data.completedNotes : deleteValue,
                            completedBy: userId,
                            whenCompleted: toMillis(data.whenCompleted),
                            completedFiles: seaFilesToValue(files),
                            touched: serverTimestamp(),
                    },
                    { merge: true }
                );
                saveFileRefs(batch, files, 'correctiveActions', selectedItem.id);
                logAction(
                    batch,
                    'Update',
                    'correctiveActions',
                    selectedItem.id,
                    selectedItem.title,
                    selectedItem.vesselIds,
                    undefined,
                    'correctiveActions'
                );
                onCollectionUpdated(batch, 'correctiveActions');

                batchTrace.data = {
                    completedFiles: seaFilesToValue(_files),
                    data,
                    selectedItem,
                };
                batchTrace.save(`Complete corrective action ${selectedItem.title}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                showToast(selectedItem?.state === 'completed' ? 'Completed information updated' : 'Corrective Action has now been marked completed');
                setShowModal(false);

                if (selectedItem?.state !== 'completed') {
                    setTimeout(() => {
                        setShowParentModal(false);
                    }, 250);
                }
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload complete corrective action files`, error.message, error, {
                        files: seaFilesToValue(files),
                        data,
                        selectedItem,
                    });
                }
            });
        }
    });

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues)
            || files.length > 0;
    }, [initialValues, values, files]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={`${selectedItem?.state === 'completed' ? 'Update Completed Information' : 'Complete Corrective Action'}`}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="thin"
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        {selectedItem?.title ? <IonCol size="12">
                            <SeaGridCell label="Task" w="50">
                                {formatValue(formatTextAreaText(selectedItem?.title))}
                            </SeaGridCell>
                        </IonCol> : null}
                        <IonCol size="12">
                            <SeaDatetime
                                label="When Completed"
                                name="whenCompleted"
                                value={values.whenCompleted}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenCompleted ? errors.whenCompleted : ''}
                            />
                        </IonCol>

                        <IonCol size="12">
                            <SeaTextarea
                                label="Notes"
                                placeholder="Add notes..."
                                name="completedNotes"
                                value={values.completedNotes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                height={150}
                                inputmode="text"
                                error={touched.completedNotes ? errors.completedNotes : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="correctiveActions"
                                field="completedFiles"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" size="wide" type="submit">
                        {selectedItem?.state === 'completed' ? 'Update' : 'Submit'}
                    </SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default CompleteCorrectiveAction;