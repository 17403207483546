import React, { useState, useEffect, useMemo } from 'react';
import { logPageView } from '../../../lib/firebase';
import { extractSearchTerms, formatValue, formatVessels } from '../../../lib/util';
import { formatDateShort } from '../../../lib/datesAndTime';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { sharedState } from '../../../shared-state/shared-state';
import { CorrectiveAction } from '../../../shared-state/HealthSafety/correctiveActions';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { useLocation } from 'react-router-dom';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaSearchbar from '../../../components/SeaSearchbar/SeaSearchbar';
import EditCorrectiveAction from '../../../modals/HealthSafety/CorrectiveActions/EditCorrectiveAction/EditCorrectiveAction';
import ViewCorrectiveAction from '../../../modals/HealthSafety/CorrectiveActions/ViewCorrectiveAction/ViewCorrectiveAction';
import EditCorrectiveActionsSettings from '../../../modals/HealthSafety/CorrectiveActions/EditCorrectiveActionSettings/EditCorrectiveActionsSettings';
import CorrectiveActionsCsv from '../../../exports/HealthSafety/CorrectiveActions/CorrectiveActionsCsv';
import CorrectiveActionsPdf from '../../../exports/HealthSafety/CorrectiveActions/CorrectiveActionsPdf';
import SeaSelectVesselFilter from '../../../components/SeaSelectVesselFilter/SeaSelectVesselFilter';
import CorrectiveActionsHistoryModal from '../../../modals/HealthSafety/CorrectiveActions/CorrectiveActionsHistoryModal/CorrectiveActionsHistoryModal';
import QueryString from 'query-string';
import './CorrectiveActions.css';

interface CorrectiveActionsProps {
    visible: boolean;
}

const CorrectiveActions: React.FC<CorrectiveActionsProps> = ({ visible }) => {
    const correctiveActions = sharedState.correctiveActions.use(visible ? 1 : 101);
    const vessels = sharedState.vessels.use(visible ? 1 : 101);
    const location = useLocation();
    sharedState.contacts.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.vesselLocations.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.safetyCheckItems.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.spareParts.use(visible ? 51 : 0); // Prepare for modals to access

    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();

    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showCorrectiveActionsSettings, setShowCorrectiveActionsSettings] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();
    const [selectedItem, setSelectedItem] = useState<CorrectiveAction>();
    const [searchText, setSearchText] = useState('');
    const [selectedVesselId, setSelectedVesselId] = useState<string | undefined>();
    const [filteredCorrectiveActions, setFilteredCorrectiveActions] = useState<CorrectiveAction[]>();
    const [showHistoryModal, setShowHistoryModal] = useState(false);

    useEffect(() => {
        if (visible) {
            logPageView('HealthSafety/CorrectiveActions');
        }
        resetPageLimit();


    }, [visible, resetPageLimit]);

    useEffect(() => {
        resetPageLimit();
    }, [searchText, selectedVesselId, resetPageLimit]);

    useEffect(() => {
        const qs = QueryString.parse(location.search);
        if (qs.action && correctiveActions?.array.all) {
            const action = correctiveActions.array.all.find((ca) => ca.id === qs.action);
            if (action && action.state !== 'completed') {
                setSelectedItem(action);
                setShowViewItemModal(true); 
            }
        }
    }, [visible, correctiveActions?.array.all, location.search]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (correctiveActions?.byId.all && selectedItem?.id) {
            const action = correctiveActions.byId.all[selectedItem.id];
            if (action) {
                setSelectedItem(action);
            }
        }
    }, [correctiveActions, selectedItem?.id]);

    const isUsingFilters = useMemo(() => {
        return (searchText || selectedVesselId) ? true : false;
    }, [searchText, selectedVesselId]);

    useEffect(() => {
        setFilteredCorrectiveActions(undefined);
        if (correctiveActions?.array.active && correctiveActions?.array.active.length > 0) {
            const _filteredCorrectiveActions = [] as CorrectiveAction[];

            const terms = extractSearchTerms(searchText, true);
            correctiveActions.array.active.forEach((correctiveAction: CorrectiveAction) => {
                if (terms.length > 0) {
                    let isMatch = true;
                    for (let j = 0; j < terms.length; j++) {
                        if (correctiveAction.searchText?.indexOf(terms[j]) === -1) {
                            isMatch = false;
                            break;
                        }
                    }
                    if (!isMatch) {
                        return;
                    }
                }
                _filteredCorrectiveActions.push(correctiveAction);
            });
            setFilteredCorrectiveActions(_filteredCorrectiveActions);
        } else if (correctiveActions) {
            setFilteredCorrectiveActions([]);
        }
    }, [correctiveActions, searchText, selectedVesselId]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };

    const onViewHistoryModal = () => {
        setShowHistoryModal(true);
    };

    const onViewItem = (item: CorrectiveAction) => {
        setShowViewItemModal(true);
        setSelectedItem(item);
    };
    const onEditSettings = () => {
        setShowCorrectiveActionsSettings(true);
    };

    return (
        <RequirePermissions role="correctiveActions" level={permissionLevels.VIEW} showDenial={true} licenseePermission="hasCorrectiveActions">
            <div className="corrective-actions page-head">
                <div>
                    <h2>Corrective Action List</h2>
                </div>
                <div className="actions">
                    {vessels?.all && vessels.all.length > 0 && (
                        <SeaSelectVesselFilter
                            filterVesselId={selectedVesselId ?? ''}
                            setFilterVesselId={setSelectedVesselId}
                            includeAll={true}
                        />
                    )}
                    <RequirePermissions role="correctiveActions" level={permissionLevels.CREATE}>
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            Add New
                        </SeaButton>
                        <div className="spacer"></div>
                    </RequirePermissions>
                    <RequirePermissions role="correctiveActions" level={permissionLevels.COMPLETE}>
                        <SeaButton onClick={onViewHistoryModal} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            History
                        </SeaButton>
                        <div className="spacer"></div>
                    </RequirePermissions>
                    <SeaExporter
                        setExportType={setExportType}
                        csv={exportType === 'csv' && <CorrectiveActionsCsv onCompleted={() => setExportType(undefined)} />}
                        pdf={exportType === 'pdf' && <CorrectiveActionsPdf onCompleted={() => setExportType(undefined)} />}
                    />
                    <RequirePermissions role="correctiveActions" level={permissionLevels.EDIT}>
                        <SeaButton zone="grey" shape="circle" onClick={(e) => onEditSettings()}>
                            <SeaIcon slot="icon-only" icon="settings" />
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            {/* Filters */}
            <div className="columns wrap filters reveal">
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
                <div>
                    <SeaButton
                        onClick={(e) => {
                            setSearchText('');
                            setSelectedVesselId('');
                        }}
                        zone="grey"
                    >
                        Reset
                    </SeaButton>
                </div>
            </div>

            <SeaNoData dataName="correctiveActions" isLoading={!filteredCorrectiveActions} hasNoData={filteredCorrectiveActions?.length === 0} isUsingFilter={isUsingFilters} />

            <div className={`corrective-actions has-thumbs ${filteredCorrectiveActions && filteredCorrectiveActions.length > 0 ? 'reveal' : 'conceal'}`}>
                <div className="sea-row headings">
                    <div></div>
                    <div>Corrective Action</div>
                    <div>Action #</div>
                    <div>Assigned</div>
                    <div>Tags</div>
                    <div>Date Added</div>
                    <div>Due Date</div>
                    {selectedVesselId === 'all' && <div>Vessels</div>}
                </div>
                {mapArrayWithLimit(filteredCorrectiveActions, (item: CorrectiveAction) => {
                        return (
                            <div
                                key={item.id}
                                className="sea-card sea-row"
                                onClick={(e) => onViewItem(item)}
                            >
                                <div>
                                    <SeaFileImage files={item.files} size="tiny"/>
                                </div>
                                <div className="bold truncate-2">
                                    {formatValue(item.title)}
                                </div>
                                <div>
                                    {formatValue(item.correctiveActionNum)}
                                </div>
                                <div>
                                    {renderFullNameForUserId(item.assignedTo)}
                                </div>
                                <div>
                                    {item.tags?.join(', ')}
                                </div>
                                <div>{formatDateShort(item.whenAdded)}</div>
                                <div>
                                    {item.dateDue ? formatDateShort(item.dateDue) : '-'}
                                </div>
                                {selectedVesselId === 'all' && <div>{formatVessels(item.vesselIds, vessels)}</div>}
                            </div>
                        );
                    })}
                    {limitTriggerElement}
            </div>
            {visible && (
                <>
                    <EditCorrectiveAction showModal={showEditItemModal} setShowModal={setShowEditItemModal} />
                    <ViewCorrectiveAction showModal={showViewItemModal} setShowModal={setShowViewItemModal} selectedItem={selectedItem} />
                    <EditCorrectiveActionsSettings showModal={showCorrectiveActionsSettings} setShowModal={setShowCorrectiveActionsSettings} />
                    <CorrectiveActionsHistoryModal showModal={showHistoryModal} setShowModal={setShowHistoryModal} vesselId={selectedVesselId} />
                </>
            )}
        </RequirePermissions>
    );
};

export default CorrectiveActions;
