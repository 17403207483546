import React, { useState, useRef, useCallback } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc } from "firebase/firestore";
import { cleanupStringArray, hasArrayChanged } from '../../../../lib/util';
import { sharedState } from '../../../../shared-state/shared-state';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';

interface EditCorrectiveActionsSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const EditCorrectiveActionsSettings: React.FC<EditCorrectiveActionsSettingsProps> = ({showModal, setShowModal}) => {
    const userId = sharedState.userId.use(showModal);
    const licenseeId = sharedState.licenseeId.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    // const [tab, setTab] = useState("tags");
    const [possibleTags, setPossibleTags] = useState<string[]>();
    const formRef = useRef<HTMLFormElement>(null);

    const onOpened = () => {
        setPossibleTags(licenseeSettings?.correctiveActionTags ? licenseeSettings.correctiveActionTags : []);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!licenseeId) {
            throw new Error("No licenseeId");
        }

        const batch = splittableBatch(firestore, 20 - 0);
        const batchTrace = makeBatchTrace(batch, 'licenseeSettings', 'update', licenseeId);

        batchTrace.data = {
            possibleTags,
        };
        const licenseeRef = doc(firestore, 'licenseeSettings', licenseeId);

        batch.set(
            licenseeRef,
            {
                correctiveActionTags: cleanupStringArray(possibleTags)?.sort(),
                updatedBy: userId,
                whenUpdated: batchTrace.whenAction
            },
            { merge: true }
        );

        batchTrace.save(`Update licensee (Corrective Action list settings)`);
        batch.commit().then(() => {
            batchTrace.reportSuccess();
        }).catch((error: any) => {
            batchTrace.reportError(error.message, error);
        });
        setShowModal(false);
        showToast(`Corrective Action list settings updated`);
    };

    const isModalDirty = useCallback(() => {
        return (
            hasArrayChanged(possibleTags, licenseeSettings?.correctiveActionTags)
        );
    }, [possibleTags, licenseeSettings?.correctiveActionTags]);
 
    return (
        <SeaModal
            title="Corrective Action List Settings"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            // Left in for future use
            // onClosed={() => {setTab("systemItems")} }
            // tabsPanel={
            //     <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
            //         <SeaTab tab="tags" mode="forms">Tags</SeaTab>
            //     </SeaTabsGroup>
            // }
            actionPanel={
                <SeaButton zone="white" onClick={(e) => {
                    formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                }}>Save Corrective Action List Settings</SeaButton>
            }
        >
            <form ref={formRef} onSubmit={handleSubmit}>
                {/* <SeaTabContent tab="tags" selectedTab={tab}> */}
                <SeaInputList
                    name="correctiveActionTags"
                    label="Corrective Action Tags"
                    maxWidth="500px"
                    values={possibleTags}
                    setValues={setPossibleTags}
                    addNewText="Add Tag"
                    confirmDelete={true}
                />
                {/* </SeaTabContent> */}
            </form>
        </SeaModal>
    );
};

export default EditCorrectiveActionsSettings;
