import { useMemo, useState } from "react";
import { CsvConfig, formatCsvDate, formatCsvNamesFromIds } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { TrainingTask } from '../../../shared-state/Crew/trainingTasks';
import { TrainingTaskReport } from '../../../shared-state/Crew/useTrainingTaskReports';
import { useTrainingTaskReportsDateRange } from '../../../shared-state/Crew/useTrainingTaskReportsDateRange';
import { formatInterval } from "../../../lib/datesAndTime";
import { DateRange } from '../../../components/SeaDateRange/SeaDateRange';
import SeaExportFilterModal from '../../../components/SeaExporter/SeaExportFilterModal';
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';

type CrewTrainingCsvProps = {
    level?: number;
    onCompleted: () => void
}

const CrewTrainingCsv = ({ onCompleted, level = 1 }: CrewTrainingCsvProps) => {
    const [dateRange, setDateRange] = useState<DateRange>();
    const [showDateRangeModal, setShowDateRangeModal] = useState(true);
    const trainingTasks = sharedState.trainingTasks.use();
    const vessels = sharedState.vessels.use();
    const trainingTaskReports = useTrainingTaskReportsDateRange(dateRange);

    const csvConfig: CsvConfig<TrainingTask> | undefined = useMemo(() => {
        if (!vessels || !trainingTaskReports) {
            return undefined;
        }
        return [
            {
                name: 'Task',
                value: (item) => item.task || ''
            },
            {
                name: 'Vessel',
                value: (item) => formatCsvNamesFromIds([item.vesselId], vessels.byId) || ''
            },
            {
                name: 'Retraining interval',
                value: (item) => formatInterval(item.interval)
            },
            {
                name: 'Next due',
                value: (item) => formatCsvDate(item.dateDue)
            },
            {
                name: 'Training History',
                items: (item) => trainingTaskReports?.byTaskId[item.id],
                columns: () => [
                    {
                        name: 'Date completed by',
                        value: (item: TrainingTaskReport) => formatCsvDate(item.dateCompleted)
                    },
                    {
                        name: 'Trainer',
                        value: (item: TrainingTaskReport) => item.trainer || ''
                    },
                    {
                        name: 'Notes',
                        value: (item: TrainingTaskReport) => item.notes || ''
                    },
                ]
            }
           
        ];
    }, [trainingTaskReports, vessels]);


    return (
        <>
            <SeaExportFilterModal level={level} showModal={showDateRangeModal} setShowModal={setShowDateRangeModal} onClose={onCompleted} setDateRange={setDateRange} />
            {dateRange && <SeaCSVGenerator name={'Crew-Training'} onCompleted={onCompleted} config={csvConfig} data={trainingTasks?.all} dateRange={dateRange} />}  
        </>
    )


};

export default CrewTrainingCsv;