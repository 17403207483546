import React, { useState } from 'react';
import { useLicensees } from '../../../shared-state/Admin/useLicensees';
import SuperAdminPageLayout from '../../../layouts/SuperAdminPageLayout/SuperAdminPageLayout';
import SeaTabsGroup from '../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../components/SeaTab/SeaTab';
import SeaTabContent from '../../../components/SeaTabContent/SeaTabContent';
import LicenseeAccounts from './LicenseeAccounts/LicenseeAccounts';
import ServerEvents from './ServerEvents/ServerEvents';
import ErrorReports from './ErrorReports/ErrorReports';
import FilesAdmin from './FilesAdmin/FilesAdmin';
import Devices from './Devices/Devices';
import TimeZones from './TimeZones/TimeZones';
import VersionHistory from './VersionHistory/VersionHistory';
import './SuperAdmin.css';

const SuperAdmin: React.FC = () => {
    const licensees = useLicensees();
    const [tab, setTab] = useState('LicenseeAccounts');

    return (
        <SuperAdminPageLayout>
            <SeaTabsGroup id="SuperAdmin" selectedTab={tab} setTab={setTab} mode="forms">
                <SeaTab tab="LicenseeAccounts" setTab={setTab}>Licensee Accounts</SeaTab>
                <SeaTab tab="ErrorReports" setTab={setTab}>Error Reports</SeaTab>
                <SeaTab tab="ServerEvents" setTab={setTab}>Server Events</SeaTab>
                <SeaTab tab="Files" setTab={setTab}>Files</SeaTab>
                <SeaTab tab="Devices" setTab={setTab}>Devices</SeaTab>
                <SeaTab tab="Timezones" setTab={setTab}>Time Zones</SeaTab>
                <SeaTab tab="VersionHistory" setTab={setTab}>Version History</SeaTab>
            </SeaTabsGroup>

            <SeaTabContent tab="LicenseeAccounts" selectedTab={tab}>
                <LicenseeAccounts visible={tab === 'LicenseeAccounts'} licensees={licensees}/>
            </SeaTabContent>
            <SeaTabContent tab="ErrorReports" selectedTab={tab}>
                {tab === 'ErrorReports' &&
                    <ErrorReports visible={true}/>
                }
            </SeaTabContent>
            <SeaTabContent tab="ServerEvents" selectedTab={tab}>
                {tab === 'ServerEvents' &&
                    <ServerEvents visible={true}/>
                }
            </SeaTabContent>
            <SeaTabContent tab="Files" selectedTab={tab}>
                {tab === 'Files' &&
                    <FilesAdmin visible={true} licensees={licensees}/>
                }
            </SeaTabContent>
            <SeaTabContent tab="Devices" selectedTab={tab}>
                {tab === 'Devices' &&
                    <Devices visible={true}/>
                }
            </SeaTabContent>
            <SeaTabContent tab="Timezones" selectedTab={tab}>
                {tab === 'Timezones' &&
                    <TimeZones visible={true}/>
                }
            </SeaTabContent>
            <SeaTabContent tab="VersionHistory" selectedTab={tab}>
                {tab === 'VersionHistory' &&
                    <VersionHistory visible={true}/>
                }
            </SeaTabContent>
        </SuperAdminPageLayout>
    );
};

export default SuperAdmin;
