import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Document, View, Image, Text, Page } from '@react-pdf/renderer';
import { pdfStyles } from '../../lib/pdf';
import { formatValue, formatTextAreaText } from '../../lib/util';
import { formatDate, formatEmailReminder, formatInterval } from '../../lib/datesAndTime';
import { getImgSrc, getImgSrcFromExt, makeSeaFiles } from '../../lib/files';
import { SafetyEquipmentItem } from '../../shared-state/VesselSafety/safetyEquipmentItems';
import { renderCategoryName } from '../../lib/categories';
import { renderFullName } from '../../shared-state/Core/users';
import SeaPdfGenerator from '../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../components/PdfItemsHeader/PdfItemsHeader';

interface SafetyEquipmentExpiryPdfProps {
    onCompleted: () => void,
    selectedItem: SafetyEquipmentItem,
    auth: any,
}

const SafetyEquipmentExpiryPdf: React.FC<SafetyEquipmentExpiryPdfProps> = ({
    onCompleted,
    selectedItem,
    auth,
}) => {
    const { vesselLocations, vesselSafetyItems } = auth;
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const imagesRef = useRef<{ reference?: number, element?: JSX.Element, src: string | Promise<string>, fileName: string }[]>([]);

    const reportTitle = useMemo(() => {
        if (selectedItem) {
            return `Safety Check Expiry - ${renderCategoryName(selectedItem?.itemId, vesselSafetyItems)}`;
        }
        return '';
    }, [selectedItem, vesselSafetyItems]);

    const getImageSrcs = useCallback((files: string[], setLoaded: (value: React.SetStateAction<boolean>) => void) => {
        imagesRef.current = [];
        if (files?.length) {
            for (const _file of files) {
                const file = makeSeaFiles([_file])[0];
                const ext = file.ext?.toLowerCase();
                const isImage = (ext === 'jpg' || ext === 'jpeg' || ext === 'png');
                const imgSrc = (
                    isImage ?
                        getImgSrc(
                            file.state!,
                            file.id!,
                            file.ext!,
                            'full'
                        ) :
                        getImgSrcFromExt(file.ext, 'medium')
                );
                const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                if (isImage) {
                    imagesRef.current.push({
                        src: imgSrc,
                        fileName: fileName
                    });
                }
            }
        }
        setLoaded(true);
    }, []);

    useEffect(() => {
        getImageSrcs(selectedItem.files, setImagesLoaded);
    }, [getImageSrcs, selectedItem.files]);


    const MakePDF = useCallback(() => {
        if (!selectedItem) return (<></>);

        const itemView = [];
        itemView.push(
            <View style={pdfStyles.column2}>
                <Text style={pdfStyles.label}>Type</Text>
                <Text>{selectedItem?.type === 'nonExpiring' ? 'Non Expiring' : selectedItem?.type}</Text>
            </View>
        );
        itemView.push(
            <View style={pdfStyles.column2}>
                <Text style={pdfStyles.label}>Quantity</Text>
                <Text>
                    {formatValue(selectedItem?.quantity)}
                </Text>
            </View>
        );
        itemView.push(
            <View style={pdfStyles.column2}>
                <Text style={pdfStyles.label}>Location</Text>
                <Text>{formatValue(renderCategoryName(selectedItem?.locationId, vesselLocations))}</Text>
            </View>
        );
        itemView.push(
            <View style={pdfStyles.column2}>
                <Text style={pdfStyles.label}>Critical Equipment</Text>
                <Text>
                    {formatValue(vesselSafetyItems?.byId[selectedItem?.itemId]?.isCritical ? 'Yes' : 'No')}
                </Text>
            </View>
        );
        if (selectedItem?.interval) {
            itemView.push(
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Interval</Text>
                    <Text>
                        {formatInterval(selectedItem.interval)}
                    </Text>
                </View>
            );
        }
        if (selectedItem?.type !== 'nonExpiring') {
            itemView.push(
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Notification</Text>
                    <Text>{formatValue(formatEmailReminder(selectedItem?.emailReminder))}</Text>
                </View>
            );
            itemView.push(
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>{selectedItem?.type === 'servicable' ? 'Service Due' : 'Expiry'}</Text>
                    <Text>
                        {selectedItem?.dateDue ? formatDate(selectedItem?.dateDue) : '-'}
                    </Text>
                </View>
            );
        }

        const RowItems = (array: any, size: number) => {
            const rowItems: any[][] = [];
            for (let i = 0; i < array.length; i += size) {
                rowItems.push(array.slice(i, i + size));
            }
            return rowItems;
        };

        const rowItems: any[][] = RowItems(itemView, 2);

        return (
            <Document
                title={reportTitle}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={reportTitle}
                        subHeading=''
                        userFullName={renderFullName()}
                    />

                    {/* Expiry Task */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.labelHeading}>{`${selectedItem?.type === 'servicable' ? 'Service' : 'Expiry'} task`}</Text>
                            <Text>{formatValue(formatTextAreaText(selectedItem?.description))}</Text>
                        </View>
                    </View>

                    {rowItems.map((row, index) => (
                        <View key={'row-' + index} wrap={false} style={pdfStyles.row}>
                            {row.map((item, idx) => (
                                <React.Fragment key={'v' + idx}>
                                    {item}
                                </React.Fragment>
                            ))}
                        </View>
                    ))}

                </Page>
                {imagesRef.current.length > 0 &&
                    <Page
                        style={{
                            ...pdfStyles.page
                        }}
                        orientation="portrait"
                        size="A4"
                        wrap={true}
                    >
                        {imagesRef.current.map((img: any, index: number) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        maxWidth: '19cm',
                                        margin: '0 1cm 0 1cm',
                                        paddingBottom: '0.5cm'
                                    }}
                                    wrap={false}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{
                                            alignSelf: 'center',
                                            padding: '0 0.02cm 0.25cm 0',
                                            fontSize: '7pt'
                                        }}>
                                            {index + 1}
                                        </Text>
                                        <Text>{img.fileName}</Text>
                                    </View>
                                    <Image
                                        src={img.src}
                                        style={{
                                            objectFit: 'scale-down',
                                            objectPositionX: 0,
                                            objectPositionY: 0,
                                        }}
                                    />
                                </View>
                            );
                        })}
                    </Page>
                }
            </Document>
        )
    }, [reportTitle, selectedItem, vesselLocations, vesselSafetyItems?.byId]);

    if (imagesLoaded) {
        return (
            <SeaPdfGenerator
                onCompleted={onCompleted}
                pdfTitle={reportTitle}
                MakePdf={MakePDF}
                requestedFrom="SafetyEquipmentExpiry"
            />
        );
    } else return <></>
};
export default SafetyEquipmentExpiryPdf;