import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { renderCategoryName } from '../../lib/categories';
import { renderFullNameForUserId } from '../Core/users';
import { canView } from "../../shared-state/Core/userPermissions";
import { Equipment } from './equipment';
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { MAX_DATE } from "../../lib/datesAndTime";

export const jobPriorities = {
    '8urgent': 'Urgent',
    '6high': 'High',
    '4medium': 'Medium',
    '2low': 'Low',
    '0shipyard': 'Shipyard'
};

export interface Job extends CreateableDocument, UpdateableDocument {
    addedFromSafetyCheckCompletedId?: string;
    assignedTo?: {
        userId: string,
        contactId?: string,
        name: string
    };
    completedBy?: string;
    deletedBy?: string;
    description: string;
    emailReminder?: string;
    equipmentId?: string;
    estimatedCost?: number;
    files: string[];
    isCritical?: boolean;
    jobNum: string;
    licenseeId: string;
    location?: string;
    locationId?: string;
    priority: string;
    spareParts?: {
        [sparePartId: string]: {
            used?: number
            added?: number
        }
    };
    state: string;
    tags?: string[];
    maintenanceTags?: string[];
    task: string;
    vesselId: string;
    whenCompleted?: number;
    whenDeleted?: number;
    dateDue?: string;
    searchText?: string;
    equipment?: Equipment;
    estimatedTime?: number;
}

export type JobsData = {
    count: number,
    prioritised: Job[],
    byPriority: {
        [priorityId: string]: Job[]
    },
    filterOptions: {
        systemIds: string[],
        equipmentIds: string[],
        assignedTo: string[]
    },
    byId: {
        completed: { [jobId: string]: Job },
        active: { [jobId: string]: Job },
        all: { [jobId: string]: Job }
    },
    all: Job[]
};

export const jobsConfig: SharedStateConfig<JobsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'vesselSystems', 'vesselLocations', 'equipment', 'contacts'],
    countLiveDocs: () => sharedState.jobs.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        const vesselSystems = sharedState.vesselSystems.current;
        const vesselLocations = sharedState.vesselLocations.current;
        const equipment = sharedState.equipment.current;
        const contacts = sharedState.contacts.current;
        if (
            vesselId &&
            equipment?.byId &&
            vesselSystems &&
            vesselLocations &&
            contacts?.byId &&
            canView('jobList')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'jobs'),
                    where('vesselId', '==', vesselId),
                    where('state', 'in', ['active', 'completed']),
                    orderBy('priority', 'desc'),
                    orderBy('task', 'asc')
                ),
                (snap) => {
                    done();
                    const rawJobs = snap.docs.map((doc) => {
                        const job = {
                            id: doc.id,
                            ...doc.data(),
                        } as Job

                        if (job.state === 'active') {
                            registerFiles(job.files, 'jobs', job);
                        }

                        return job;
                    });
                    if (
                        rawJobs &&
                        equipment?.byId &&
                        vesselSystems &&
                        vesselLocations &&
                        contacts?.byId
                    ) {
                        const jobs = [] as Job[];
                        const byId = { 
                            completed: {} as { [jobId: string]: Job },
                            active: {} as { [jobId: string]: Job },
                            all: {} as { [jobId: string]: Job }
                        };
                        rawJobs.forEach((job) => {
                            job.searchText = job.task;
                            // Inject equipment if present
                            if (job.equipmentId) {
                                job.equipment = equipment.byId[job.equipmentId];
                            }
                            // Inject assignedTo.name
                            if (job.assignedTo) {
                                if (job.assignedTo.userId) {
                                    job.assignedTo.name = renderFullNameForUserId(
                                        job.assignedTo.userId
                                    );
                                } else if (job.assignedTo.contactId) {
                                    job.assignedTo.name =
                                        contacts.byId[job.assignedTo.contactId]?.name;
                                }
                                job.searchText += job.assignedTo.name;
                            }
                            if (job.description) {
                                job.searchText += job.description;
                            }
                            if (job.jobNum) {
                                job.searchText += job.jobNum;
                            }
                            if (job.tags) {
                                job.searchText += job.tags.join('');
                            }
                            if (job.maintenanceTags) {
                                job.searchText += job.maintenanceTags.join('');
                            }
                            if (job.state === 'active') {
                                jobs.push(job);
                                byId.active[job.id] = job;
                            } else {
                                byId.completed[job.id] = job;
                            }
                            byId.all[job.id] = job;
                        });

                        // Sort prioritised jobs
                        let prioritised = [...jobs];
                        prioritised.sort((a, b) => {
                            // jobs with dateDue will be sorted higher than default alphabetical
                            if (a.priority === b.priority) {
                                return (
                                    // (a.dateDue ? a.dateDue : Number.MAX_SAFE_INTEGER) -
                                    // (b.dateDue ? b.dateDue : Number.MAX_SAFE_INTEGER)
                                    (a.dateDue ? a.dateDue : MAX_DATE).localeCompare(
                                        b.dateDue ? b.dateDue : MAX_DATE
                                    )
                                );
                            }
                            return 0;
                        });

                        // Categorise prioritised jobs
                        const byPriority = {} as {
                            [priorityId: string]: Job[]
                        };
                        Object.keys(jobPriorities).forEach((priorityId: string) => {
                            byPriority[priorityId] = [];
                        });

                        // Filters
                        const filterOptions = {
                            systemIds: [] as string[],
                            equipmentIds: [] as string[],
                            assignedTo: [] as string[],
                        };
                        const hasFilter = {
                            systemIds: {} as { [systemId: string]: boolean },
                            equipmentIds: {} as { [equipmentId: string]: boolean },
                            assignedTo: {} as { [contactId: string]: boolean },
                        };

                        prioritised.forEach((job) => {
                            byPriority[job.priority].push(job);
                            if (job.equipment) {
                                job.searchText += job.equipment.equipment;
                                if (job.equipment.systemId) {
                                    job.searchText += renderCategoryName(
                                        job.equipment.systemId,
                                        vesselSystems
                                    );
                                    if (
                                        hasFilter.systemIds[job.equipment.systemId] ===
                                        undefined
                                    ) {
                                        hasFilter.systemIds[job.equipment.systemId] = true;
                                    }
                                }
                                if (job.equipment.id) {
                                    job.searchText += job.equipment.equipment;
                                    if (
                                        hasFilter.equipmentIds[job.equipment.id] ===
                                        undefined
                                    ) {
                                        hasFilter.equipmentIds[job.equipment.id] = true;
                                    }
                                }
                                if (job.equipment.locationId) {
                                    job.searchText += renderCategoryName(
                                        job.equipment.locationId,
                                        vesselLocations
                                    );
                                }
                            }
                            if (
                                job.assignedTo?.name &&
                                hasFilter.assignedTo[job.assignedTo.name] === undefined
                            ) {
                                hasFilter.assignedTo[job.assignedTo.name] = true;
                                filterOptions.assignedTo.push(job.assignedTo.name);
                            }
                            job.searchText = job.searchText?.toLowerCase();
                        });

                        vesselSystems.ids?.forEach((id: string) => {
                            if (hasFilter.systemIds[id]) {
                                filterOptions.systemIds.push(id);
                            }
                        });

                        equipment.all?.forEach((item) => {
                            if (hasFilter.equipmentIds[item.id]) {
                                filterOptions.equipmentIds.push(item.id);
                            }
                        });

                        filterOptions.assignedTo.sort();

                        set({
                            count: prioritised.length,
                            all: rawJobs,
                            prioritised,
                            byPriority,
                            filterOptions,
                            byId
                        });
                    }
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.error(
                        `Failed to access job for vessel ${vesselId}`,
                        error
                    );
                }
            )
        } else {
            done();
        }
    },

};
