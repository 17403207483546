import React, { useMemo, useState } from 'react';
import { CachedFileType, FileCacheEntry, FileCollection, cachedFiles } from '../../../../shared-state/FileSyncSystem/cachedFiles';
import { FileId } from '../../../../lib/files';
import { formatDp } from '../../../../lib/util';
import { formatDateShort, formatYear } from '../../../../lib/datesAndTime';
import { viewFilesFullScreen } from '../../../../managers/FileViewerManager/FileViewerManager';
import { filesToCache } from '../../../../shared-state/FileSyncSystem/filesToCache';
import SeaHorizontalBarGraph, { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaPieGraph from '../../../../components/reporting/SeaPieGraph/SeaPieGraph';
import './DebugFilesTab.css';

const getBiggestFileSize = (entry: FileCacheEntry) => {
    let size = 0;
    Object.keys(entry[5]).forEach((fileType: any) => {
        size = Math.max(size, entry[5][fileType as CachedFileType] ?? 0);
    });
    return size;
};
const getTotalFileSize = (entry: FileCacheEntry) => {
    let size = 0;
    Object.keys(entry[5]).forEach((fileType: any) => {
        size += entry[5][fileType as CachedFileType] ?? 0;
    });
    return size;
};
const getOriginalSize = (entry: FileCacheEntry) => {
    return entry[5].O ?? 0;
};
const getOptimisedSize = (entry: FileCacheEntry) => {
    return (entry[5].F || entry[5].S || entry[5].R) ?? 0;
};
const getThumbnailSize = (entry: FileCacheEntry) => {
    return entry[5].T ?? 0;
};
const processSize = (size: number) => {
    return Math.round(size / 1024); // KB
};

const sortFunctions = {
    'File Id': (a, b) => {
        return a[1] > b[1] ? 1 : -1; // I'm not using localeCompare so we match how Firestore orders strings
    },
    'Newest': (a, b) => {
        return (b[4] ?? 0) - (a[4] ?? 0);
    },
    'Oldest': (a, b) => {
        return (a[4] ?? 0) - (b[4] ?? 0);
    },
    'Smallest Total': (a, b) => {
        return getTotalFileSize(a) - getTotalFileSize(b);
    },
    'Biggest Total': (a, b) => {
        return getTotalFileSize(b) - getTotalFileSize(a);
    },
    'Biggest File': (a, b) => {
        return getBiggestFileSize(b) - getBiggestFileSize(a);
    },
    'Biggest Original File': (a, b) => {
        return getOriginalSize(b) - getOriginalSize(a);
    },
    'Biggest Optimised File': (a, b) => {
        return getOptimisedSize(b) - getOptimisedSize(a);
    },
    'Biggest Thumbnail': (a, b) => {
        return getThumbnailSize(b) - getThumbnailSize(a);
    }
} as {
    [id: string]: (a: FileCacheEntry, b: FileCacheEntry) => number
};

const DebugFilesTab: React.FC = () => {
    const [drawCount, setDrawCount] = useState<number>(0); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [tab, setTab] = useState<string>('all');
    const [expandedCollections, setExpandedCollections] = useState<{[collection: string]: boolean}>({});
    const [orderBy, setOrderBy] = useState(Object.keys(sortFunctions)[0]);
    const [entryToView, setEntryToView] = useState<FileCacheEntry | undefined>();
    // We want list of files
    // * By collection
    // * All at once
    // We want cachedFiles rendered
    // We want to analyse a particular file

    const toggleCollection = (collection: string) => {
        const o = {...expandedCollections} as any;
        if (o[collection]) {
            delete o[collection];
        } else {
            o[collection] = true;
        }
        setExpandedCollections(o);
    };

    const cachedFilesData = useMemo(() => {
        const collections = ['UNKNOWN'] as (FileCollection | 'UNKNOWN')[];
        const collectionStats = {
            'UNKNOWN': {
                count: 0,
                original: 0,
                optimised: 0,
                thumbnail: 0,
                total: 0
            }
        } as {} as any;
        const totals = {
            count: 0,
            total: 0,
            original: 0,
            optimised: 0,
            thumbnail: 0
        };
        const byCollection = {
            'UNKNOWN': []
        } as any;
        const all = [] as FileCacheEntry[];

        const sortFunction = sortFunctions[orderBy];

        const fileIds = Object.keys(cachedFiles) as FileId[];
        fileIds.forEach((id: FileId) => {
            const entry = cachedFiles[id];
            all.push(entry);
            const collection = entry[3] ?? 'UNKNOWN';
            if (collection) { // collection
                if (byCollection[collection] === undefined) {
                    collections.push(collection);
                    byCollection[collection] = [];
                    collectionStats[collection] = {
                        count: 0,
                        original: 0,
                        optimised: 0,
                        thumbnail: 0,
                        total: 0
                    };
                }
                byCollection[collection].push(entry);
                collectionStats[collection].count++;
                collectionStats[collection].original += getOriginalSize(entry);
                collectionStats[collection].optimised += getOptimisedSize(entry);
                collectionStats[collection].thumbnail += getThumbnailSize(entry);
            }
        });

        collections.sort();
        collections.forEach((collection) => {
            byCollection[collection].sort(sortFunction);
            const stats = collectionStats[collection];
            if (stats) {
                stats.total = stats.original + stats.optimised + stats.thumbnail;
                totals.count += stats.count;
                totals.original += stats.original;
                totals.optimised += stats.optimised;
                totals.thumbnail += stats.thumbnail;
                totals.total += stats.total;
            }
        });

        // Sort all by id
        all.sort(sortFunction);

        return {
            all,
            collections,
            collectionStats,
            byCollection,
            totals
        }
    }, [drawCount, orderBy]); // eslint-disable-line react-hooks/exhaustive-deps


    const graphData = useMemo(() => {
        if (tab === 'stats' && cachedFilesData) {
            const o = {
                collectionsByCount: [] as GraphData[],
                collectionsBySize: [] as GraphData[],
                fileTypesByCount: [] as GraphData[],
                fileTypesBySize: [] as GraphData[],
                extByCount: [] as GraphData[],
                extBySize: [] as GraphData[],
                yearByCount: [] as GraphData[],
                yearBySize: [] as GraphData[]
            };

            // Collection stats
            let otherTotal = 0;
            let otherCount = 0;
            cachedFilesData.collections.forEach((collection: string) => {
                const stats = cachedFilesData.collectionStats[collection];
                if (stats) {
                    if (stats.total / cachedFilesData.totals.total >= 0.01) {
                        o.collectionsBySize.push({
                            name: collection,
                            value: processSize(stats.total)
                        });
                    } else {
                        otherTotal += stats.total;
                    }
                    if (stats.count / cachedFilesData.totals.count >= 0.01) {
                        o.collectionsByCount.push({
                            name: collection,
                            value: stats.count
                        });
                    } else {
                        otherCount += stats.count;
                    }
                }
            });
            o.collectionsBySize.push({
                name: 'other',
                value: processSize(otherTotal)
            });
            o.collectionsByCount.push({
                name: 'other',
                value: otherCount
            });
            
            // File type stats
            let typeCounts = {
                O: 0,
                F: 0,
                S: 0,
                T: 0,
                R: 0
            } as any;
            let typeSizes = { ...typeCounts }
            const extCounts = {} as any;
            const extSizes = {} as any;

            let yearCounts = {} as any;
            let yearSizes = {} as any;

            let totalExtCount = 0;
            let totalExtSize = 0;
            cachedFilesData.all.forEach((entry: FileCacheEntry) => {
                Object.keys(entry[5]).forEach((fileType) => {
                    typeCounts[fileType]++;
                    typeSizes[fileType] += (entry[5] as any)[fileType] ?? 0;
                });
                const ext = entry[2].toLowerCase();
                if (extCounts[ext] === undefined) {
                    extCounts[ext] = 0;
                    extSizes[ext] = 0;
                }
                extCounts[ext]++;
                extSizes[ext] += getTotalFileSize(entry);
                totalExtCount++;
                totalExtSize += getTotalFileSize(entry);

                const year = formatYear(entry[4]);
                if (yearCounts[year] === undefined) {
                    yearCounts[year] = 0;
                    yearSizes[year] = 0;
                }
                yearCounts[year]++;
                yearSizes[year] += getTotalFileSize(entry);
            });
            o.fileTypesByCount.push({ name: 'Original Files', value: typeCounts.O });
            o.fileTypesByCount.push({ name: 'Full Size Images', value: typeCounts.F });
            o.fileTypesByCount.push({ name: 'Optimised Signatures', value: typeCounts.S });
            o.fileTypesByCount.push({ name: 'Thumbnails', value: typeCounts.T });
            o.fileTypesByCount.push({ name: 'Optimised Rich Text', value: typeCounts.R });
            o.fileTypesBySize.push({ name: 'Original Files', value: processSize(typeSizes.O) });
            o.fileTypesBySize.push({ name: 'Full Size Images', value: processSize(typeSizes.F) });
            o.fileTypesBySize.push({ name: 'Optimised Signatures', value: processSize(typeSizes.S) });
            o.fileTypesBySize.push({ name: 'Thumbnails', value: processSize(typeSizes.T) });
            o.fileTypesBySize.push({ name: 'Optimised Rich Text', value: processSize(typeSizes.R) });

            let otherExtCount = 0;
            let otherExtSize = 0;
            Object.keys(extCounts).forEach((ext) => {
                if (extCounts[ext] / totalExtCount >= 0.01) {
                    o.extByCount.push({
                        name: ext,
                        value: extCounts[ext]
                    });
                } else {
                    otherExtCount++;
                }
                if (extSizes[ext] / totalExtSize >= 0.01) {
                    o.extBySize.push({
                        name: ext,
                        value: processSize(extSizes[ext])
                    });
                } else {
                    otherExtSize += extSizes[ext];
                }
            });

            o.extByCount.push({
                name: 'other',
                value: otherExtCount
            });
            o.extBySize.push({
                name: 'other',
                value: processSize(otherExtSize)
            });

            Object.keys(yearCounts).forEach((year) => {
                o.yearByCount.push({
                    name: year,
                    value: yearCounts[year]
                });
                o.yearBySize.push({
                    name: year,
                    value: processSize(yearSizes[year])
                });
            });

            return o;
        }
        return undefined;
    }, [cachedFilesData, tab]);

    const viewFile = (entry: FileCacheEntry) => {
        console.log(`viewFile ${entry[0]}${entry[1]}.${entry[2]} ${entry[5].S ? 'S' : 'F'}`);
        if (entry[6] && entry[7] && !entry[5].F && !entry[5].O && !entry[5].R && !entry[5].S && !entry[5].T) {
            // File has a problem, let's display error message instead
            if (entryToView && entryToView[1] === entry[1]) {
                setEntryToView(undefined);
            } else {
                setEntryToView(entry);
            }
        } else {
            viewFilesFullScreen([`${entry[0]}${entry[1]}.${entry[2]}`], 0, entry[5].S ? 'S' : 'F');
        }
    };

    const renderFileCacheEntry = (entry: FileCacheEntry) => {
        const renderSize = (x: number | null | undefined) => {
            if (x === null) {
                return entry[6] ? 'error' : 'queued'
            }
            return x?.toLocaleString();
        }
        return (
            <div
                className="sea-row card"
                key={entry[1]}
                style={{ backgroundColor: '#ddd', cursor: 'pointer' }}
                onClick={(e) => viewFile(entry)}
            >
                <div>{entry[1]}</div>
                <div>{entry[3]}</div>
                <div>{entry[0]}</div>
                <div>{formatDateShort(entry[4])}</div>
                <div>{entry[2]}</div>
                <div>{renderSize(entry[5].O)}</div>
                {/* <div>{(entry[5].F || entry[5].S || entry[5].R)?.toLocaleString()}</div> */}
                <div>
                    {
                        renderSize(
                            entry[5].F || entry[5].S || entry[5].R || (
                                (entry[5].F === null || entry[5].S === null || entry[5].R === null) ? null : undefined
                            )
                        )
                    }
                </div>
                <div>{renderSize(entry[5].T)}</div>
                <div>{getTotalFileSize(entry)?.toLocaleString()}</div>
                <div>{entry[6]}</div>
                {entry[6] && entryToView && entryToView[1] === entry[1] &&
                    <div style={{ position: 'absolute', backgroundColor: 'yellow', color: 'red', width: '100%', bottom: '0px', left: '0px', padding: '16px', pointerEvents: 'none' }}>
                        {entry[7]}
                    </div>
                }
            </div>
        );
    };

    return (
        <div
            className="debug-files"
            style={{
                padding: '0px 8px',
                //minWidth: '1200px',
                color: 'var(--text-on-white)',
            }
        }>
            <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                <SeaTab tab="all" mode="forms">All</SeaTab>
                <SeaTab tab="collections" mode="forms">Collections</SeaTab>
                <SeaTab tab="stats" mode="forms">Stats</SeaTab>
                <SeaTab tab="filesToCache" mode="forms">Files To Cache</SeaTab>
            </SeaTabsGroup>
            {tab === 'filesToCache' ? (
                <div style={{ color: '#fff', fontFamily: 'monospace', fontSize: '14px' }}>
                    {filesToCache.map((fileToCache) => {
                        return (
                            <div key={fileToCache.file}>
                                {fileToCache.file[0]} {fileToCache.file.substring(1)} p={fileToCache.priority}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <>
                    <div>
                        <select
                            title="Sort By"
                            onChange={(e) => {
                                console.log(e);
                                setOrderBy(e.target.value);
                            }}
                        >
                            {Object.keys(sortFunctions).map((name: string) => {
                                return <option key={name} value={name}>{name}</option>;
                            })}
                        </select>
                    </div>
                    {graphData &&
                        <div className={`reporting-grid max-8-graphs`}>
                            <SeaPieGraph
                                n={1}
                                title="Collections by Count"
                                mode="dashboard"
                                data={graphData.collectionsByCount}
                                showAllLabels={true}
                                //hashNamesForColours={true}
                            />
                            <SeaPieGraph
                                n={2}
                                title="Collections by KB"
                                mode="dashboard"
                                data={graphData.collectionsBySize}
                                showAllLabels={true}
                                //hashNamesForColours={true}
                            />
                            <SeaPieGraph
                                n={3}
                                title="File Types by Count"
                                mode="dashboard"
                                data={graphData.fileTypesByCount}
                                showAllLabels={true}
                                //hashNamesForColours={true}
                            />
                            <SeaPieGraph
                                n={4}
                                title="File Types by KB"
                                mode="dashboard"
                                data={graphData.fileTypesBySize}
                                showAllLabels={true}
                                //hashNamesForColours={true}
                            />
                            <SeaPieGraph
                                n={5}
                                title="File Extensions by Count"
                                mode="dashboard"
                                data={graphData.extByCount}
                                showAllLabels={true}
                                //hashNamesForColours={true}
                            />
                            <SeaPieGraph
                                n={6}
                                title="File Extensions by KB"
                                mode="dashboard"
                                data={graphData.extBySize}
                                showAllLabels={true}
                                //hashNamesForColours={true}
                            />
                            <SeaHorizontalBarGraph
                                n={7}
                                title="Years by Count"
                                mode="dashboard"
                                data={graphData.yearByCount}
                                //showAllLabels={true}
                                sortData={false}
                                //hashNamesForColours={true}
                            />
                            <SeaHorizontalBarGraph
                                n={8}
                                title="Years by KB"
                                mode="dashboard"
                                data={graphData.yearBySize}
                                //showAllLabels={true}
                                sortData={false}
                                //hashNamesForColours={true}
                            />
                        </div>
                    }
                    <div>
                        <div className="sea-row headings" style={{ paddingTop: '0px', marginTop: '0px' }}>
                            <div>File Id</div>
                            <div>Collection</div>
                            <div>State</div>
                            <div>When</div>
                            <div>Ext.</div>
                            <div>Original<br/>bytes</div>
                            <div>Optimised<br/>bytes</div>
                            <div>Thumbnail<br/>bytes</div>
                            <div>Total<br/>bytes</div>
                            <div>Fails</div>
                        </div>
                        <div
                            className="sea-row card"
                            style={{ backgroundColor: 'unset', color: '#ffffaa', fontWeight: 'bold' }}
                        >
                            <div><b>TOTAL</b> ({cachedFilesData.totals.count})</div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div>{cachedFilesData.totals.original?.toLocaleString()}</div>
                            <div>{cachedFilesData.totals.optimised?.toLocaleString()}</div>
                            <div>{cachedFilesData.totals.thumbnail?.toLocaleString()}</div>
                            <div>{cachedFilesData.totals.total?.toLocaleString()}</div>
                            <div></div>
                        </div>
                        {tab === 'all' && cachedFilesData.all.map((entry: FileCacheEntry) => {
                            return renderFileCacheEntry(entry);
                        })}
                        {tab === 'collections' && cachedFilesData.collections.map((collection) => {
                            const stats = cachedFilesData.collectionStats[collection];
                            return (
                                <div key={collection}>
                                    <div
                                        className="sea-row card"
                                        style={{ backgroundColor: 'unset', cursor: 'pointer', color: '#fff' }}
                                        onClick={(e) => toggleCollection(collection)}
                                    >
                                        <div style={{ fontSize: '14px' }} onClick={(e) => toggleCollection(collection)}>
                                            {collection}
                                        </div>
                                        <div></div>
                                        <div>{stats?.count ?? 0}</div>
                                        <div></div>
                                        <div>{formatDp(100 * (stats?.total ?? 0) / cachedFilesData.totals.total, 2)}%</div>
                                        <div>{stats?.original?.toLocaleString()}</div>
                                        <div>{stats?.optimised?.toLocaleString()}</div>
                                        <div>{stats?.thumbnail?.toLocaleString()}</div>
                                        <div>{stats?.total?.toLocaleString()}</div>
                                        <div></div>
                                    </div>
                                    {expandedCollections[collection] && cachedFilesData.byCollection[collection]?.map((entry: FileCacheEntry) => {
                                        return renderFileCacheEntry(entry);
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};

export default DebugFilesTab;
