import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { useEffect, useState } from "react";
import { SafetyCheckItem } from "../../VesselSafety/safetyCheckItems";
import { makeSafetyItems, sortSafetyItems } from "./reportingSafetyFunctions";

export const useReportingFaultedSafetyChecks = (
    isActive: boolean,
    selectedVesselIds: string[],
    selectedSafetyCheckStatuses: string[]
) => {
    const [reportingFaultedSafetyChecks, setReportingFaultedSafetyChecks] = useState<SafetyCheckItem[]>();

    useEffect(() => {
        setReportingFaultedSafetyChecks(undefined);
        if (
            isActive &&
            selectedVesselIds?.length &&
            selectedSafetyCheckStatuses.indexOf('faulted') !== -1
        ) {
            return setupArrayQueryListener(
                'safetyCheckItems', // what
                collection(firestore, 'safetyCheckItems'),
                [
                    where('state', '==', 'active'),
                    where('hasFault', '==', true)
                ],
                'vesselId',
                'in',
                selectedVesselIds,
                [orderBy('dateDue', 'asc')],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    const all = makeSafetyItems(docs) as SafetyCheckItem[];
                    if (isCombined) {
                        sortSafetyItems(all);
                    }
                    setReportingFaultedSafetyChecks(all);
                }
            );
        }
    }, [isActive, selectedVesselIds, selectedSafetyCheckStatuses]);

    return reportingFaultedSafetyChecks;

}
