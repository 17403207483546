import React, { useState, useEffect, useMemo, useCallback, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { formatVessels, getBasedOnBackgroundColour } from '../../../lib/util';
import { formatDateShort, getDayOffset, getToday, warnDays } from '../../../lib/datesAndTime';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { renderCategoryName } from '../../../lib/categories';
import { sharedState } from '../../../shared-state/shared-state';
import { Risk } from '../../../shared-state/HealthSafety/risks';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaSelectVesselFilter from '../../../components/SeaSelectVesselFilter/SeaSelectVesselFilter';
import ViewRiskMatrix from '../../../modals/HealthSafety/RiskRegister/ViewRiskMatrix/ViewRiskMatrix';
import EditRiskAssessment from '../../../modals/HealthSafety/RiskRegister/EditRiskAssessment/EditRiskAssessment';
import ViewRiskAssessment from '../../../modals/HealthSafety/RiskRegister/ViewRiskAssessment/ViewRiskAssessment';
import EditRiskRegisterSettings from '../../../modals/HealthSafety/RiskRegister/EditRiskRegisterSettings/EditRiskRegisterSettings';
import RiskRegisterPdf from '../../../exports/HealthSafety/Risks/RiskRegisterPdf';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import RiskRegisterCsv from '../../../exports/HealthSafety/Risks/RiskRegisterCsv';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import './RiskRegister.css';

interface RiskRegisterProps {
    visible: boolean,
}

const RiskRegister: React.FC<RiskRegisterProps> = ({visible}) => {
    const risks = sharedState.risks.use(visible ? 1 : 101);
    const riskCategories = sharedState.riskCategories.use(visible ? 1 : 101);
    const vessels = sharedState.vessels.use(visible ? 1 : 101);
    const licenseeSettings = sharedState.licenseeSettings.use(visible ? 1 : 101);
    const todayMillis = sharedState.todayMillis.use(visible ? 1 : 101) as number;
    const [selectedRisk, setSelectedRisk] = useState<Risk>();
    // const links = useItemLinks(selectedRisk?.id);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [filterVesselId, setFilterVesselId] = useState<string>();
    const [riskMatrixModal, setRiskMatrixModal] = useState({
        show: false,
        allowEdit: true,
        level: 1,
        likelihoodId: 0,
        consequenceId: 0,
        type: ''
    });
    const [showRiskRegisterSettings, setShowRiskRegisterSettings] = useState(false);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (visible) {
            logPageView('HealthSafety/RiskRegistry');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    useEffect(() => {
        resetPageLimit();
    }, [filterVesselId, resetPageLimit]);

    // Keep selectedRisk fresh
    useEffect(() => {
        if (selectedRisk?.id && risks?.byId[selectedRisk.id]) { 
            setSelectedRisk(risks.byId[selectedRisk.id])
        };
    }, [risks, selectedRisk?.id]);

    const isUsingFilter = useMemo(() => {
        return filterVesselId && filterVesselId !== 'all' ? true : false;
    }, [filterVesselId]);

    const filteredRisks = useMemo(() => {
        if (
            filterVesselId &&
            filterVesselId !== 'all' &&
            risks?.byVesselId
        ) {
            return risks.byVesselId[filterVesselId]?.byCategoryId ? risks.byVesselId[filterVesselId].byCategoryId : {};
        }
        return risks?.byCategoryId;
    }, [risks, filterVesselId]);

    const renderRiskRating = useCallback((likelihoodId: number | string, consequenceId: number | string) => {
        if (!licenseeSettings?.riskRegister?.matrix) return undefined;
        const raw = licenseeSettings.riskRegister.matrix[`${likelihoodId}-${consequenceId}`];
        const color = raw.substring(0, 6);
        const name = raw.substring(7);
        return <div className="sea-status risk-rating" style={{ backgroundColor: `#${color}`, color: getBasedOnBackgroundColour(color) }}>
            {name}
        </div>;
    }, [licenseeSettings]);



    const today = useMemo(() => {
        return getToday();
    }, [todayMillis]); // eslint-disable-line react-hooks/exhaustive-deps

    const dayUpcoming = useMemo(() => {
        return getDayOffset(warnDays.riskRegister[0]);
    }, [todayMillis]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderWhenDueClass = useCallback((dateDue: string) => {
        if (dateDue < today) {
            return 'fail';
        } else if (dateDue < dayUpcoming) {
            return 'warn';
        }
        return '';
    }, [today, dayUpcoming]);


    const onViewRiskMatrix = () => {
        setRiskMatrixModal({
            show: true,
            allowEdit: true,
            level: 1,
            likelihoodId: 0,
            consequenceId: 0,
            type: ''
        });
    };
    const onAddNewItem = () => {
        setSelectedRisk(undefined);
        setShowEditItemModal(true);
    };

    const onEditSettings = () => {
        setShowRiskRegisterSettings(true);
    };
    const onViewItem = (item: Risk) => {
        setSelectedRisk(item)
        setShowViewItemModal(true);
    };

    return (
        <RequirePermissions
            role="hazardRegister"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="risk-register page-head">
                <div><h2>Risk Assessments</h2></div>
                <div className="actions">
                    <SeaSelectVesselFilter
                        filterVesselId={filterVesselId}
                        setFilterVesselId={setFilterVesselId}
                    />
                    <SeaButton onClick={(e) => onViewRiskMatrix()} zone="grey">
                        Risk Matrix
                    </SeaButton>
                    <div className="spacer"></div>
                    <RequirePermissions
                        role="hazardRegister"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Complete <span className="wider-420">&nbsp;Risk&nbsp;</span> Assessment
                        </SeaButton>
                        <div className="spacer"></div>
                    </RequirePermissions>
                    <SeaExporter 
                        setExportType={setExportType}
                        csv={exportType === "csv" && <RiskRegisterCsv onCompleted={() => setExportType(undefined)} />}
                        pdf={exportType === "pdf" && (
                            <RiskRegisterPdf
                                onCompleted={() => setExportType(undefined)}
                                filterVesselId={filterVesselId}
                                isUsingFilter={isUsingFilter}
                                filteredRisks={filteredRisks}
                            />
                        )}
                    />
                    <RequirePermissions
                        role="hazardRegister"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton zone="grey" shape="circle" onClick={(e) => onEditSettings()}>
                            <SeaIcon slot="icon-only" icon="settings"/>
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            <SeaNoData
                dataName="risks"
                isLoading={!risks}
                hasNoData={(filteredRisks && Object.keys(filteredRisks).length === 0)}
                isUsingFilter={isUsingFilter}
            />

            <div className={`risk-register has-thumbs has-status ${isUsingFilter ? 'hide-vessels' : 'show-vessels'} ${(filteredRisks && Object.keys(filteredRisks).length > 0) ? 'reveal' : 'conceal' }`}>
                <div className="sea-row headings">
                    <div></div>
                    <div>Hazard</div>
                    <div>Risks</div>
                    <div>Pre Risk Rating</div>
                    <div>Controls</div>
                    <div>Post Risk Rating</div>
                    <div>Vessels</div>
                    <div>Review Date</div>
                </div>
                <Profiler id="healthSafety.risks" onRender={onProfilerRender}>
                    {filteredRisks && riskCategories?.ids?.map((categoryId: string) => {
                        if (isLimitReached() || filteredRisks[categoryId] === undefined) {
                            return undefined;
                        }
                        return (
                            <React.Fragment key={categoryId}>
                                <div className="category-heading">{renderCategoryName(categoryId, riskCategories)}</div>
                                {mapArrayWithLimit(filteredRisks[categoryId], (item) => {
                                    return (
                                        <div key={item.id} className={`sea-card sea-row ${renderWhenDueClass(item.dateDue)}`} onClick={(e) => onViewItem(item)}>
                                            <div>
                                                <SeaFileImage files={item.files} size="tiny"/>
                                            </div>
                                            <div className="bold truncate-2">{item.name}</div>
                                            <div className="truncate-2">{item.risks}</div>
                                            {renderRiskRating(item.preControls.likelihood, item.preControls.consequence)}
                                            <div className="truncate-2">{item.controls}</div>
                                            <div>{renderRiskRating(item.postControls.likelihood, item.postControls.consequence)}</div>
                                            <div className="truncate-2">{!isUsingFilter && formatVessels(item.vesselIds, vessels)}</div>
                                            <div className={`truncate-2 review-date ${renderWhenDueClass(item.dateDue)}`}>
                                                {renderWhenDueClass(item.dateDue) ? (
                                                    <SeaStatusDueDate
                                                        dateDue={item.dateDue}
                                                        warnDays={warnDays.riskRegister[0]}
                                                    />
                                                ) : formatDateShort(item.dateDue)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                    {visible && limitTriggerElement}
                </Profiler>
            </div>

            {visible &&
                <>
                    <ViewRiskMatrix
                        showModal={riskMatrixModal.show}
                        setShowModal={(showModal: boolean) => setRiskMatrixModal({...riskMatrixModal, show: showModal})}
                        allowEdit={riskMatrixModal.allowEdit}
                        level={riskMatrixModal.level}
                        likelihoodId={riskMatrixModal.likelihoodId}
                        consequenceId={riskMatrixModal.consequenceId}
                        type={riskMatrixModal.type}
                    />
                    <EditRiskRegisterSettings
                        showModal={showRiskRegisterSettings}
                        setShowModal={setShowRiskRegisterSettings}
                    />
                    <EditRiskAssessment
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        defaultVesselId={isUsingFilter ? filterVesselId : undefined}
                        setRiskMatrixModal={setRiskMatrixModal}
                        itemToUpdate={selectedRisk}
                        level={2}
                    />
                    {selectedRisk && <ViewRiskAssessment
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        setRiskMatrixModal={setRiskMatrixModal}
                        setShowEditItemModal={setShowEditItemModal}
                        selectedRisk={selectedRisk}
                    />}
                </>
            }
        </RequirePermissions>
    );
};

export default RiskRegister;
