import React from 'react';
import { SafetyCheckCompleted } from '../../../../shared-state/VesselSafety/useCompletedSafetyCheckItems';
import { formatDatetime, formatShortTimeDurationHrsMinsView } from '../../../../lib/datesAndTime';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { formatValue } from '../../../../lib/util';
import { sharedState } from '../../../../shared-state/shared-state';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';

interface ViewCompletedSafetyCheckProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    selectedItem: SafetyCheckCompleted | undefined;
    selectedSafetyItemName: string;
    level: number;
}

const ViewCompletedSafetyCheck: React.FC<ViewCompletedSafetyCheckProps> = ({ showModal, setShowModal, selectedItem, selectedSafetyItemName, level = 1 }) => {
    const licenseeSettings = sharedState.licenseeSettings.use();
    return (
        <SeaModal
            title="Completed Safety Check"
            showModal={showModal}
            setShowModal={setShowModal}
            size='thin'
            level={level}
        >
            <div className="info-image">
                <SeaFileImage files={selectedItem?.files} size="tiny" showOthers />
            </div>
             <SeaGrid>
                <SeaGridCell label="Safety Item" w="50">
                    {selectedSafetyItemName}
                </SeaGridCell>
                <SeaGridCell label="Completed By" w="50">
                    {renderFullNameForUserId(selectedItem?.completedBy)}
                </SeaGridCell>
                <SeaGridCell label="When Completed" w="50">
                    {formatDatetime(selectedItem?.whenCompleted)}
                </SeaGridCell>
                <SeaGridCell label="Fault Reported" w="50">
                    {selectedItem?.shouldReportFault ? "Yes" : "No"}
                </SeaGridCell>
                {licenseeSettings?.hasSafetyCheckTaskTime && (
                    <SeaGridCell label="Actual Time" w="50">
                        {formatValue(formatShortTimeDurationHrsMinsView(selectedItem?.actualTime, true))}
                    </SeaGridCell>
                )}
                <SeaGridCell label="Notes" w="100">
                    {selectedItem?.notes}
                </SeaGridCell>
            </SeaGrid>
        </SeaModal>
    );
};

export default ViewCompletedSafetyCheck;