import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { isPlatform } from '@ionic/react';
import { refreshApp } from '../../App';
import { toInt } from '../../lib/util';
import { formatDatetimeNatural } from '../../lib/datesAndTime';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import packageJson from '../../../package.json';
import SeaButton from '../../components/SeaButton/SeaButton';
import './ServerInfoManager.css';

/**
 * Handles informing the user when
 * - There's a new mandatory release that they must install
 * - There's a new recommended release that they may install
 * - There's a new global message they haven't read yet (see Super Admin / Server Events)
 * - They've just installed a new version of Sea Flux
 */

const openAppStore = () => {
    if (isPlatform('ios')) {
        window.open(
            'itms-apps://itunes.apple.com/app/apple-store/id1560908960?mt=8',
            '_system',
            'location=yes'
        );
    } else {
        window.open(
            'https://play.google.com/store/apps/details?id=nz.co.orchid.seaflux',
            '_system',
            'location=yes'
        );
    }
};

export const renderMessageContent = (s: string) => {
    let limit = 100;
    while (s.indexOf('{datetime:') !== -1 && limit > 0) {
        const indexFrom = s.indexOf('{datetime:');
        const indexTo = s.indexOf('}', indexFrom + 1);
        const isoDate = s.substring(indexFrom + 10, indexTo);
        s = s.substring(0, indexFrom) + formatDatetimeNatural(isoDate) + s.substring(indexTo + 1);
        limit--;
    }
    return s;
};

export const renderAppVersionNotes = (content: string) => {
    const lines = content.split('\n');
    let index = 0;
    const output = [] as ReactNode[];
    while (index < lines.length) {
        while (index < lines.length && !lines[index].startsWith('* ')) {
            output.push(
                <p key={index}>{lines[index]}</p>
            );
            index++;
        }
        const renderPoints = () => { // eslint-disable-line no-loop-func
            const points = [] as string[];
            while (index < lines.length && lines[index].startsWith('* ')) {
                points.push(lines[index].substring(2));
                index++;
            }
            return points;
        };
        if (index < lines.length) {
            output.push(
                <ul className="legal" key={index}>
                    {renderPoints().map((line) => {
                        return <li key={line}>{line}</li>;
                    })}
                </ul>
            );
        }
        index++;
    }
    return output;
};

// What's the latest recommended build available SEEN on this device/browser
const getBuildAvailableSeen = () => {
    const value = window.localStorage.getItem('_availableBuildSeen');
    return value ? parseInt(value) : 0;
};
// What's the last server message SEEN by the user - returns the message's whenSent millis value
const getLastMessageSeenMillis = () => {
    const value = window.localStorage.getItem('_lastMessageSeenMillis');
    return value ? parseInt(value) : 0;
}
// What's the latest app version for which we've seen notes for?
const getLastVersionNotesSeen = () => {
    return window.localStorage.getItem('_lastVersionNotesSeen') ?? '';
}

const ServerInfoManager: React.FC = () => {
    const superAdmin = sharedState.superAdmin.use();
    const serverInfo = sharedState.serverInfo.use();
    const onlineStatus = sharedState.onlineStatus.use();
    const [newMessage, setNewMessage] = useState({
        show: false,
        showRequired: false,
        title: '',
        buttons: [] 
    } as {
        show?: boolean,
        showRequired?: boolean,
        title: string,
        textContent?: string,
        htmlContent?: ReactNode,
        buttons: any[]
    });
    const [triggerRefresh, setTriggerRefresh] = useState(1);
    const [fade, setFade] = useState('conceal');

    useEffect(() => {
        if (newMessage.show) {
            setTimeout(() => {
                setFade('reveal');
            }, 100);
        }
    }, [newMessage.show]);

    const closeMessage = useCallback(() => {
        setFade('conceal');
        setTimeout(() => {
            setNewMessage((current) => {
                return {
                    ...current,
                    show: false
                };
            });
            setTriggerRefresh((current) => current + 1);
        }, 200);
    }, []);

    // Record whenSent of the last message the user has SEEN
    const onMessageSeen = useCallback((whenSent: number) => {
        window.localStorage.setItem('_lastMessageSeenMillis', ''+whenSent);
        closeMessage();
    }, [closeMessage]);

    // Record that a latest recommended build has been SEEN on this device/browser
    const onBuildAvailableSeen = useCallback((build: number) => {
        window.localStorage.setItem('_availableBuildSeen', ''+build);
        closeMessage();
    }, [closeMessage]);

    // Record that the app's version notes has been SEEN on this device
    const onVersionNotesSeen = useCallback((version: string) => {
        window.localStorage.setItem('_lastVersionNotesSeen', version);
        closeMessage();
    }, [closeMessage]);


    useEffect(() => {
        if (serverInfo && triggerRefresh) {
            const appBuild = toInt(packageJson.build, 0);
            const appVersion = packageJson.version;

            // Act on new builds
            if (isPlatform('hybrid')) {
                if (isPlatform('ios')) {
                    // iOS App
                    if (
                        appBuild < serverInfo.iosBuildAvailable &&
                        getBuildAvailableSeen() < serverInfo.iosBuildAvailable
                    ) {
                        setNewMessage({
                            show: true,
                            showRequired: (appBuild < serverInfo.iosBuildRequired),
                            title: 'A new version of Sea Flux is now available',
                            textContent: 'Would you like to install the update? (Recommended)',
                            buttons: [
                                {
                                    text: 'Not right now',
                                    handler: (e: any) => {
                                        onBuildAvailableSeen(serverInfo.iosBuildAvailable);
                                    }
                                }, {
                                    text: 'Yes (Open App Store)',
                                    handler: (e: any) => {
                                        onBuildAvailableSeen(serverInfo.iosBuildAvailable);
                                        openAppStore();
                                    }
                                }
                            ]
                        });
                        return;
                    }
                } else {
                    // Android app
                    if (
                        appBuild < serverInfo.androidBuildAvailable &&
                        getBuildAvailableSeen() < serverInfo.androidBuildAvailable
                    ) {
                        setNewMessage({
                            show: true,
                            showRequired: (appBuild < serverInfo.androidBuildRequired),
                            title: 'A new version of Sea Flux is now available!',
                            textContent: 'Would you like to install the update? (Recommended)',
                            buttons: [
                                {
                                    text: 'Not right now',
                                    handler: (e: any) => {
                                        onBuildAvailableSeen(serverInfo.androidBuildAvailable);
                                    }
                                },
                                {
                                    text: 'Yes please (Open Google Play)',
                                    handler: (e: any) => {
                                        onBuildAvailableSeen(serverInfo.androidBuildAvailable);
                                        openAppStore();
                                    }
                                }
                            ]
                        });
                        return;
                    }
                }
            } else {
                // Web
                if (appBuild < serverInfo.webBuildRequired) {
                    // Web is obsolete... force a restart ASAP!
                    refreshApp(`Web is obsolete... force a restart ASAP! appBuild=${appBuild} serverInfo.webBuildRequired=${serverInfo.webBuildRequired}`);
                } else if (
                    appBuild < serverInfo.webBuildAvailable &&
                    getBuildAvailableSeen() < serverInfo.webBuildAvailable
                ) {
                    setNewMessage({
                        show: true,
                        showRequired: false,
                        title: 'A new version of Sea Flux has just become available!',
                        textContent: 'Would you like to start using it now? (Recommended)',
                        buttons: [
                            {
                                text: 'Not right now',
                                handler: (e: any) => {
                                    onBuildAvailableSeen(serverInfo.webBuildAvailable);
                                }
                            }, {
                                text: 'Yes (will refresh page)',
                                handler: (e: any) => {
                                    onBuildAvailableSeen(serverInfo.webBuildAvailable);
                                    (window.location as any) = window.location; // eslint-disable-line no-self-assign
                                }
                            }
                        ]
                    });
                    return;
                }
            }
            // Act on new version notes (need to be online to see them)
            const isFreshInstall = (getLastVersionNotesSeen() === '');
            if (
                onlineStatus?.isOnline &&
                appVersion !== getLastVersionNotesSeen() && (
                    isPlatform('hybrid')  ||
                    !isFreshInstall // Only show updates on the web (because we don't want "Welcome to Sea Flux" every new browser session)
                )
            ) {
                // We have a valid message to show the user that they haven't seen before
                let isActive = true;
                getDoc(
                    doc(firestore, 'appVersions', appVersion)
                ).then((doc) => {
                    if (isActive && doc.exists()) {
                        setNewMessage({
                            show: true,
                            showRequired: false,
                            title: `${isFreshInstall ? 'Welcome to Sea Flux' : 'Sea Flux has been updated'}`,
                            htmlContent: <>
                                <b style={{ fontSize: '120%' }}>Version {appVersion}</b>
                                <div style={{ paddingTop: '8px' }}>
                                    {renderAppVersionNotes(doc.data().content)}
                                </div>
                            </>,
                            buttons: [
                                {
                                    text: 'Continue',
                                    handler: (e: any) => {
                                        onVersionNotesSeen(appVersion);
                                    }
                                }
                            ]
                        });
                    }
                });
                return () => {
                    isActive = false;
                }
            }
            if (isFreshInstall && !isPlatform('hybrid')) {
                // Record install version to browser
                onVersionNotesSeen(appVersion);
            }
            // Act on new server messages
            if (serverInfo.messages && serverInfo.messages.length > 0) {
                // There's at least one message available to be shown to the user
                for (let i = 0; i < serverInfo.messages.length; i++) {
                    const message = serverInfo.messages[i];
                    if (
                        Date.now() < message.whenExpires &&
                        message.whenSent > getLastMessageSeenMillis()
                    ) {
                        // We have a valid message to show the user that they haven't seen before
                        setNewMessage({
                            show: true,
                            showRequired: false,
                            title: message.title,
                            textContent: renderMessageContent(message.content),
                            buttons: [
                                {
                                    text: 'Dismiss',
                                    handler: (e: any) => {
                                        onMessageSeen(message.whenSent);
                                    }
                                }
                            ]
                        });
                        return;
                    }
                }
            }
        }
    }, [serverInfo, onBuildAvailableSeen, onMessageSeen, onVersionNotesSeen, triggerRefresh, onlineStatus]);

    return <>
        {newMessage.showRequired &&
            <div className="sea-error-report-backdrop" style={{ backgroundColor: '#373946' }}>
                <div className="sea-error-report" style={{ textAlign: 'center' }}>
                    <h1>Your Sea Flux app is out of date!</h1>
                    <br/>
                    <p>
                        Please download and install the latest version of Sea Flux from {isPlatform('ios') ? 'The Apple App Store' : 'Google Play'}.
                    </p>
                    <div style={{ padding: '32px 16px 16px' }}>
                        <SeaButton onClick={(e) => openAppStore()}>
                            Open {isPlatform('ios') ? ' App Store' : 'Google Play'}...
                        </SeaButton>
                    </div>
                </div>
            </div>
        }
        {serverInfo?.underMaintenance && !superAdmin &&
            <div className="sea-error-report-backdrop" style={{ backgroundColor: '#373946', color: 'white', textAlign: 'center' }}>
                <div className="cbl-centered">
                    <div className="cbl-logo">
                        <img src="/assets/seaflux.png" alt="Sea Flux Logo"/>
                    </div>
                    <div className={`cbl-box`}>
                        <h2>Sea Flux is currently down for planned maintenance.</h2>
                        <p>
                            We expect to be back within 2 hours. Thanks for your patience.
                        </p>
                    </div>
                </div>
            </div>
        }
        {newMessage.show &&
            <div className={`sea-error-report-backdrop new-message ${fade}`} style={{ backgroundColor: 'rgba(0,0,0,0.75)' }}>
                <div className="sea-error-report">
                    <h2>{newMessage.title}</h2>
                    <br/>
                    {newMessage.textContent &&
                        <div style={{ whiteSpace: 'pre', marginTop: '10px', fontSize: '120%' }}>
                            {newMessage.textContent}
                        </div>
                    }
                    {newMessage.htmlContent &&
                        <div style={{ marginTop: '10px', fontSize: '120%' }}>
                            {newMessage.htmlContent}
                        </div>
                    }
                    <div style={{ padding: '60px 16px 16px', textAlign: 'right' }}>
                        {newMessage.buttons.map((button) => {
                            return (
                                <SeaButton key={button.text} onClick={button.handler}>
                                    {button.text}
                                </SeaButton>
                            );
                        })}
                    </div>
                </div>
            </div>
        }
    </>;
};

export default ServerInfoManager;
