import { DocumentData, QueryDocumentSnapshot, collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { getDayOffset, MAX_DATE, warnDays } from '../../lib/datesAndTime';
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { canView } from "../../shared-state/Core/userPermissions";

//
// Load training tasks
//

export interface TrainingTask extends CreateableDocument, UpdateableDocument {
    deletedBy?: string;
    files: string[];
    interval: string;
    licenseeId: string;
    state: string;
    task: string;
    vesselId: string;
    whenDeleted?: number;
    dateDue?: string;
    dateLastCompleted?: string;
}

export type TrainingTasksData = {
    all: TrainingTask[],
    allByVesselId: {
        [vesselId: string]: TrainingTask[] // by task alphabetically
    },
    byId: {
        [taskId: string]: TrainingTask
    },
    top5ByVesselId: {
        [vesselId: string]: TrainingTask[] // by dateDue desc
    },
    totalExpiredOrDueSoonByVesselId: {
        [vesselId: string]: number // by dateDue desc
    }
};

export const trainingTasksConfig: SharedStateConfig<TrainingTasksData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId', 'vesselIds', 'todayMillis'], // todayMillis is used due to calculating around days
    countLiveDocs: () => sharedState.trainingTasks.current?.all ? sharedState.trainingTasks.current.all.length : 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        const vesselIds = sharedState.vesselIds.current;
        if (licenseeId && vesselIds) {
            const processDocs = (
                docs: QueryDocumentSnapshot<DocumentData>[],
                isCombined: boolean
            ) => {
                const all = docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    } as TrainingTask;
                });

                if (isCombined) { // Need to sort by name
                    all.sort((a, b) => {
                        return a.task.localeCompare(b.task);
                    });
                }

                const byId = {} as {
                    [taskId: string]: TrainingTask
                };
                const allByVesselId = {} as {
                    [vesselId: string]: TrainingTask[]
                };
                all.forEach((task: TrainingTask) => {
                    registerFiles(task.files, 'trainingTasks', task);
                    byId[task.id] = task;
                    if (allByVesselId[task.vesselId] === undefined) {
                        allByVesselId[task.vesselId] = [] as TrainingTask[]
                    }
                    allByVesselId[task.vesselId].push(task);
                });

                const top5ByVesselId = {} as {
                    [vesselId: string]: TrainingTask[]
                };
                const totalExpiredOrDueSoonByVesselId = {} as {
                    [vesselId: string]: number
                };
                Object.keys(allByVesselId).forEach((vesselId: string) => {
                    let prioritised = [...allByVesselId[vesselId]];
                    prioritised.sort((a, b) => {
                        //return ((a.dateDue ? a.dateDue : Number.MAX_SAFE_INTEGER) - (b.dateDue ? b.dateDue : Number.MAX_SAFE_INTEGER));
                        return (a.dateDue ? a.dateDue : MAX_DATE).localeCompare(
                            b.dateDue ? b.dateDue : MAX_DATE
                        );
                    });
                    const maxDateDue = getDayOffset(warnDays.crewTraining[warnDays.crewTraining.length - 1] - 1);
                    const minDateDue = getDayOffset(warnDays.crewTraining[0] - 1);
                    totalExpiredOrDueSoonByVesselId[vesselId] = 0
                    for (let i = 0; i < prioritised.length; i++) {
                        if (prioritised[i].dateDue && prioritised[i].dateDue! >= minDateDue) {
                            totalExpiredOrDueSoonByVesselId[vesselId]++
                        }
                        if (
                            prioritised[i].dateDue === undefined || (
                                prioritised[i].dateDue &&
                                prioritised[i].dateDue! > maxDateDue
                            )
                        ) {
                            prioritised = prioritised.slice(0, i);
                            break;
                        };
                    };
                    top5ByVesselId[vesselId] = prioritised?.slice(0, 5).filter((p) => p.dateDue && p.dateDue >= minDateDue);
                });
                set({
                    all,
                    allByVesselId,
                    byId,
                    top5ByVesselId,
                    totalExpiredOrDueSoonByVesselId
                });
            };

            if (canView('crewParticulars')) { // Get for entire licensee (as we may need to see tasks for crew on vessels we dont have access to)
                return onSnapshot(
                    query(
                        collection(firestore, 'trainingTasks'),
                        where('licenseeId', '==', licenseeId),
                        where('state', '==', 'active'),
                        orderBy('task', 'asc')
                    ),
                    (snap) => {
                        done();
                        processDocs(snap.docs, false);
                    },
                    (error) => {
                        done();
                        console.log(`Failed to access crew training for licenseeId: ${licenseeId}`, error);
                    }
                );
            } else { // Get for all my vessels
                return setupArrayQueryListener(
                    'trainingTasks', // what
                    collection(firestore, 'trainingTasks'),
                    [where('state', '==', 'active')], // baseConstraints
                    'vesselId',
                    'in',
                    vesselIds,
                    [orderBy('task', 'asc')], // orderBy
                    (
                        docs: QueryDocumentSnapshot<DocumentData>[],
                        isCombined: boolean
                    ) => { // processDocs
                        done();
                        processDocs(docs, isCombined);
                    }, (error) => {
                        done();
                    }
                );
            }
        }
    }
};
