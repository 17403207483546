import React, { useEffect, useState, useMemo, useRef } from 'react';
import { IonCheckbox, IonSelectOption } from '@ionic/react';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import { extractHeadingFromCategoryName, stripCategoryHeadingFromCategoryName } from '../../lib/categories';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaButton from '../SeaButton/SeaButton';
import SeaModalBox from '../SeaModalBox/SeaModalBox';
import SeaSelect from '../SeaSelect/SeaSelect';
import './SeaMultiSelect.css';

/*

Uses of this:
    crew > vessel access
    voyage > Personnel involved
    drills > create report > drill type (JUST STRINGS)
    drills > create report > personell involved
    maintenance schedule > connect equipment document
    maintenance schedule > connect contact
    health & safety > create meeting report > personell present

*/

export interface OptionType {
    id: string;
    name: string;
    options?: OptionType[];
    isDropdown?: boolean;
    selected?: boolean;
    required?: boolean;
    default?: boolean;
}

interface SeaMultiSelectProps {
    name?: string,
    mode?: 'tags' | 'popover',
    zone?: 'blue' | 'white' | 'grey',
    label?: string,
    help?: SeaHelp,
    values?: string[],
    setValues?: (values: string[]) => void, // callback to set values
    useAllOption?: boolean,
    options?: OptionType[], // [{id: x, name: x} ...]
    //error?: string,
    required?: boolean, // if true will show an error when not set.
    requiredError?: string,
    onChanged?: (values: string[]) => void,
    isSubmitting?: boolean, // Pass in formik's isSubmitting so we can tell if form has been submitted (count as touched)
    modalTitle?: string,
    disabled?: boolean,
    emptyText?: string,
    modalMaxWidth?: string | number | undefined,
}

const SeaMultiSelect: React.FC<SeaMultiSelectProps> = ({
    name,
    mode = 'popover',
    zone,
    label,
    help,
    values,
    setValues,
    useAllOption,
    options,
    required,
    requiredError,
    isSubmitting,
    onChanged,
    modalTitle,
    disabled,
    emptyText,
    modalMaxWidth = '295px',
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState<boolean[]>();
    const [touched, setTouched] = useState(false);
    const suppressOnChangeRef = useRef(false);

    const formattedOptions = useMemo(() => {
        const markSelectedRecursively = (option: OptionType): OptionType => ({
            ...option,
            selected: values?.includes(option.id) || option.options?.some(child => markSelectedRecursively(child).selected),
            // If the option is not specified as non-default, it is default
            default: option.default === false ? false : true,
            options: option.options?.map(markSelectedRecursively) || [],
        });

        return options?.map(markSelectedRecursively);
    }, [options, values]);

    const _modalTitle = useMemo(() => {
        if (modalTitle) {
            return modalTitle;
        } else if (label) {
            return label;
        }
        return undefined;
    }, [label, modalTitle]);

    useEffect(() => {
        let isActive = true;
        setTouched(false);
        setTimeout(() => {
            if (!isActive) return;
            setTouched(false);
        }, 100);
        return () => { isActive = false; };
    }, []);

    useEffect(() => {
        if (isSubmitting) {
            setTouched(true);
        }
    }, [isSubmitting]);

    let error = '';
    if (required && touched && (values === undefined || values.length === 0)) {
        error = requiredError ? requiredError : 'Please select at least one option';
    }

    useEffect(() => {
        if (options) {
            let _selected: boolean[] = new Array(options.length).fill(false);
            
            if (Array.isArray(values)) {
                values.forEach((value) => {
                    const index = options.findIndex(option => option.id === value);
                    if (index !== -1) {
                        _selected[index] = true;
                    }
                });
            }
            
            setSelected(_selected);
        } else {
            setSelected(undefined);
        }
    }, [values, options]);

    const toggleOption = (optionId: string) => {
        setTouched(true);
        if (options && selected !== undefined) {
            for (let i = 0; i < options.length; i++) {
                if (options[i].id === optionId) {
                    const _selected = [...selected];
                    _selected[i] = !_selected[i];
                    setSelected(_selected);
                    updateValues(_selected);
                }
            }
        }
    }

    const toggleAll = (allCurrentlySelected: boolean) => {
        setTouched(true);
        if (options) {
            const _selected: boolean[] = [];
            for (let i = 0; i < options.length; i++) {
                _selected[i] = !allCurrentlySelected;
            }
            setSelected(_selected);
            updateValues(_selected);
        }
    };

    const updateValues = (_selected: boolean[]) => {
        const _values: string[] = [];
        if (_selected?.length > 0 && options) {
            for (let i = 0; i < _selected.length; i++) {
                if (_selected[i]) {
                    _values.push(options[i].id);
                }
            }
        }
        if (setValues) {
            setValues(_values);
        }
        if (onChanged) {
            onChanged(_values);
        }
    }

    const areAllSelected = (selected: boolean[] | undefined) => {
        if (selected) {
            for (let i = 0; i < selected?.length; i++) {
                if (!selected[i]) {
                    return false;
                }
            }
        }
        return true;
    };

    let allSelected = areAllSelected(selected);

    const multiSelectSummary = useMemo(() => {
        if (mode === 'popover') {
            const appendOptionNames = (option: OptionType) => {
                // If the option is not a dropdown and it is selected, add the name to the summary
                if (!option.options?.length && values?.includes(option.id)) {
                    if (s.length > 0) {
                        s += ', ';
                    }
                    s += stripCategoryHeadingFromCategoryName(option.name).trim();
                }
                // If there are further options, recurse
                option.options?.forEach(appendOptionNames);
            };

            let s = '';
            options?.forEach(appendOptionNames);
            if (s.length === 0 && emptyText) {
                return emptyText;
            }
            return s;
        }
        return undefined;
    }, [mode, options, emptyText, values]);

    if (mode === 'popover') {
        let currentHeading = '';
        const stopClick = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
        };

        const selectAll = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            const _values = new Set<string>();

            const addOptionsChildren = (option: OptionType) => {
                if (option.options) {
                    option.options.forEach(addOptionsChildren);
                } else if (option.default !== false) {
                    _values.add(option.id);
                }
            };

            options?.forEach(addOptionsChildren);

            const updatedValues = Array.from(_values);
            setValues?.(updatedValues);
            onChanged?.(updatedValues);
        };
        const selectNone = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (!values || values.length === 0) return;
            const _values = [...(values ?? [])];
            _values.length = 0;
            setValues?.([..._values]);
            onChanged?.([..._values]);
        };

        const togglePopoverOption = (e: any, _option: OptionType, otherDropdownOptions?: OptionType[]) => {
            // Used to prevent IonCheckboxes overwriting the state when initialised
            suppressOnChangeRef.current = true;
            e.preventDefault();
            e.stopPropagation();

            const localValues = new Set(values ?? []);

            // Recursively select options and their children
            const selectOptionAndChildren = (option: OptionType, isProgrammatic?: boolean) => {
                if (option.options?.length) {
                    option.options.forEach((_option) => selectOptionAndChildren(_option, true));
                // If we are not programmaticly setting the option, or the option is a default, select the option
                } else if ((isProgrammatic && option.default) || !isProgrammatic) {
                    // If there are other dropdown options, deselect them
                    if (otherDropdownOptions?.length) {
                        otherDropdownOptions.forEach((_option) => {
                            localValues.delete(_option.id);
                        });
                    }
                    localValues.add(option.id);
                }
            };

            const deselectOptionAndChildren = (option: OptionType) => {
                localValues.delete(option.id);
                if (option.options) {
                    option.options.forEach(deselectOptionAndChildren);
                }
            };

            if (_option.selected) {
                // If the option is already selected, deselect it and its children
                deselectOptionAndChildren(_option);
            } else {
                selectOptionAndChildren(_option, false);
            }
            const updatedValues = Array.from(localValues);
            setValues?.(updatedValues);
            onChanged?.(updatedValues);
            setTimeout(() => {
                suppressOnChangeRef.current = false;
            }, 10);
        };

        const renderOption = (option: OptionType, indentLevel = 0) => {
            const heading = extractHeadingFromCategoryName(option.name);

            if (option.isDropdown && option.options) {
                return (
                    <SeaSelect
                        key={option.id}
                        value={option.options.find(child => child.selected)?.id}
                        popoverSize="wide"
                        onchange={(e) => {
                            if (e.target && !suppressOnChangeRef.current) {
                                const selectedOption = option.options?.find(child => child.id === (e.target as HTMLSelectElement).value);
                                if (selectedOption) {
                                    togglePopoverOption(e, {...selectedOption, selected: false}, option.options)
                                }
                            }
                        }}
                    >
                        {option.options.map((_option, index) => {
                            return (
                                <IonSelectOption key={_option.id} value={_option.id}>
                                    {_option.name}
                                </IonSelectOption>
                            );
                        })}
                    </SeaSelect>
                )
            }
            
            const item = (
                <div key={option.id} className="option" style={{ paddingLeft: `${indentLevel * 36}px` }} onClick={(e) => !option.required && !disabled ? togglePopoverOption(e, option) : null}>
                    <IonCheckbox
                        className="sea-checkbox"
                        checked={option.selected}
                        color="secondary"
                        disabled={option.required || disabled}
                    />
                    <div>
                        {stripCategoryHeadingFromCategoryName(option.name)}
                    </div>
                </div>
            );

            const children = option.selected && !option.isDropdown ? option.options?.map(childOption => 
                renderOption(childOption, indentLevel + 1)
            ) : null;

            if (heading !== currentHeading) {
                currentHeading = heading;
                return (
                    <React.Fragment key={heading}>
                        <div className="category-heading">{heading}</div>
                        {item}
                        {children}
                    </React.Fragment>
                );
            }

            return (
                <React.Fragment key={option.id}>
                    {item}
                    {children}
                </React.Fragment>
            );
        };
        
        return (
            <>
                {label && <div><SeaLabel zone={zone} help={help}>{label}</SeaLabel></div>}
                <div
                    className={`sea-input sea-select multi ${zone}-zone ${error ? 'has-error' : ''} ${disabled ? 'disabled' : ''}`}
                    onClick={() => !disabled && setShowModal(true)}
                >
                    <div className="select-text no-select">
                        {multiSelectSummary}
                    </div>
                    <div className="select-icon">
                        <div className="select-icon-inner"></div>
                    </div>
                </div>
                <SeaInputError alignLeft>{error}</SeaInputError>
                <SeaModalBox showModal={showModal} setShowModal={setShowModal} maxWidth={modalMaxWidth}>
                    {_modalTitle &&
                        <div className="sea-select-multi-title">
                            {_modalTitle}
                        </div>
                    }
                    {useAllOption &&
                        <div className="sea-select-multi-links" onClick={stopClick}>
                            <div className={`${(values && options && values.length >= options.length) ? 'disabled' : 'pushy'}`} onClick={selectAll}>
                                SELECT ALL
                            </div>
                            <div className={`${((values && options && values.length === 0) || !values) ? 'disabled' : 'pushy'}`} onClick={selectNone}>
                                CLEAR
                            </div>
                        </div>
                    }
                    <div
                        className="sea-select-multi-box"
                        onClick={stopClick}
                    >
                        {formattedOptions?.map((option) => {
                            return renderOption(option);
                        })}
                    </div>
                    <div className="sea-select-multi-actions" onClick={stopClick}>
                        <SeaButton size="small" mini onClick={() => setShowModal(false)}>
                            OK
                        </SeaButton>
                    </div>
                </SeaModalBox>
            </>
        );
    }

    // tags mode
    return (
        <>
            {label && <div><SeaLabel help={help}>{label}</SeaLabel></div>}
            {useAllOption &&
                <div
                    className={`sea-input sea-multi-option no-select ${allSelected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
                    onClick={(e) => !disabled && toggleAll(allSelected)}
                >
                    All
                </div>
            }
            {options && options.map((option, index) => {
                return (
                    <div
                        key={option.id}
                        className={`sea-input sea-multi-option no-select ${(selected && selected[index]) ? 'selected' : '' } ${error ? 'warn' : ''} ${disabled || option.required ? 'disabled' : ''}`}
                        onClick={(e) => !disabled && !option.required ? toggleOption(option.id) : null}
                    >
                        {stripCategoryHeadingFromCategoryName(option.name)}
                    </div>
                );
            })}
            <SeaInputError alignLeft>{error}</SeaInputError>
        </>
    );
};

export default SeaMultiSelect;
