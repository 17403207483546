import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { IonSpinner } from '@ionic/react';
import { functions, logPageView } from '../../lib/firebase';
import { httpsCallable } from 'firebase/functions';
import { sharedState } from '../../shared-state/shared-state';
import { useNavigate } from 'react-router';
import { OverdueStat, useOverdueStats } from '../../shared-state/General/useOverdueStats';
import { alertMessage } from '../../managers/AlertManager/AlertManager';
import SeaBreadcrumb from '../../components/SeaBreadcrumb/SeaBreadcrumb';
import SeaIcon from '../../components/SeaIcon/SeaIcon';
import StandardPageLayout from '../../layouts/StandardPageLayout/StandardPageLayout';
import SeaLink from '../../components/SeaLink/SeaLink';
import SeaFileImage from '../../components/SeaFileImage/SeaFileImage';
import './FleetDashboard.css';
import SeaButton from '../../components/SeaButton/SeaButton';
import EditFleetSettingsModal from '../../modals/FleetDashboard/EditFleetSettingsModal/EditFleetSettingsModal';


interface VesselTotals {
    [vesselId: string]: {
        safety?: number;
        maintenance?: number;
        documents?: number;
    };
}

interface Totals {
    needsRefresh?: boolean;
    vessels: VesselTotals;
}

const FleetDashboard: React.FC = () => {
    const todayMillis = sharedState.todayMillis.use()!;
    const licenseeId = sharedState.licenseeId.use();
    const licenseeSettings = sharedState.licenseeSettings.use();
    const user = sharedState.user.use();
    const vesselIds = sharedState.vesselIds.use();
    const vessels = sharedState.vessels.use();
    const onlineStatus = sharedState.onlineStatus.use();
    const navigate = useNavigate();
    const [overdueTotals, setOverdueTotals] = useState<Totals>();
    const overdueStatsNeedRefreshRef = useRef(false);
    const [showEditSettingsModal, setShowEditSettingsModal] = useState(false);
    // const [yellowError, setYellowError] = useState('');
    // const [showDebugging, setShowDebugging] = useState(true);

    // If we are displaying the page and anything is stale, call this.
    const triggerRefreshOverdueStats = useCallback(() => {
        console.log('triggerRefreshOverdueStats!');
        httpsCallable(functions, 'refreshOverdueStats')({
            licenseeId: licenseeId,
            vesselIds: vesselIds,
            timeZone: licenseeSettings?.timeZone
        }).then((result: any) => {
            console.log('triggerRefreshOverdueStats result', result);
        }).catch((error) => {
            console.log('triggerRefreshOverdueStats error', error);
        });
    }, [licenseeId, vesselIds, licenseeSettings]);

    const overdueStats = useOverdueStats(triggerRefreshOverdueStats);

    useEffect(() => {
        if (overdueStatsNeedRefreshRef.current) {
            triggerRefreshOverdueStats();
        }
        logPageView('FleetDashboard');
    }, [triggerRefreshOverdueStats]);

    // Calculate overdueTotals based on overdueStats whenever they change
    useEffect(() => {
        setOverdueTotals(undefined);
        if (overdueStats && vesselIds) {
            let needsRefresh = false;
            if (overdueStats.whenUpdated && overdueStats.whenUpdated < todayMillis) {
                needsRefresh = true;
            }
            const totals: Totals = {
                vessels: {}
            };

            vesselIds?.forEach((vesselId: string) => {
                totals.vessels[vesselId] = {};
                const overdueVessel = overdueStats[vesselId] as OverdueStat | undefined;
                // Safety overdue total
                if (
                    overdueVessel?.safetyCheckItems?.stale === false
                    && overdueVessel?.safetyEquipmentItems?.stale === false
                    && overdueVessel?.drills?.stale === false
                ) {
                    totals.vessels[vesselId].safety =
                        overdueVessel.safetyCheckItems.overdue +
                        overdueVessel.safetyEquipmentItems.overdue +
                        overdueVessel.drills.overdue;
                } else {
                    needsRefresh = true;
                }
                // Maintenance overdue total
                if (
                    overdueVessel?.scheduledMaintenanceTasks?.stale === false
                    && overdueVessel?.jobs?.stale === false
                ) {
                    totals.vessels[vesselId].maintenance =
                        overdueVessel.scheduledMaintenanceTasks.overdue +
                        overdueVessel.jobs.overdue;
                } else {
                    needsRefresh = true;
                }
                // Documents overdue total
                if (
                    overdueVessel?.vesselCertificates?.stale === false
                    && overdueVessel?.vesselDocuments?.stale === false
                    && overdueVessel?.safetyMeetings?.stale === false
                ) {
                    totals.vessels[vesselId].documents =
                        overdueVessel.vesselCertificates.overdue +
                        overdueVessel.vesselDocuments.overdue +
                        overdueVessel.safetyMeetings.overdue;
                } else {
                    needsRefresh = true;
                }
            });

            totals.needsRefresh = needsRefresh;
            overdueStatsNeedRefreshRef.current = needsRefresh;
            setOverdueTotals(totals);
            if (needsRefresh) {
                triggerRefreshOverdueStats();
            }
        }
    }, [overdueStats, vesselIds, todayMillis, triggerRefreshOverdueStats]);

    const onViewVessel = useCallback((vesselId: string) => {
        sharedState.vesselId.set(vesselId);
        setTimeout(() => {
            navigate(`/vessel/${vesselId}`);
        }, 10);
    }, [navigate]);

    const statUnknown = useMemo(() => {
        return (
            <div className="pushy" onClick={(e) => alertMessage('This value should load when next online')}>
                {(onlineStatus?.isOnline) ?
                    <IonSpinner name="crescent" className="sea-spinner overdue-stat"/>
                    :
                    <SeaIcon icon="offline" marginBottom={-3}/>
                }
            </div>
        );
    }, [onlineStatus]);

    /* // Test error handling:
    const generateSecurityError = (actionDescription: string, isFake = false) => {
        const batch = splittableBatch(firestore, 20 - 0);
        const batchTrace = makeBatchTrace(batch, 'impossible');
        const newRef =  doc(collection(firestore, 'impossible'));
        batchTrace.exampleOperation = 'create';
        batchTrace.exampleDocId = newRef.id;
        batch.set(
            newRef,
            { nothing: 'much' },
            { merge: true }
        );
        batchTrace.data = {
            nothing: 'much'
        };
        batchTrace.save(actionDescription);
        batch.commit().then(() => {
            batchTrace.reportSuccess();
        }).catch((error) => {
            if (isFake) {
                const batch = splittableBatch(firestore, 20 - 0);
                batch.set(
                    doc(firestore, 'actionsConfirmed', action.errorId as string),
                    {
                        userId: sharedState.userId.current,
                        licenseeId: licenseeId,
                        errorReportId: action.errorId,
                        collection: action.collection,
                        docId: action.docId,
                        whenAction: batchTrace.whenAction,
                        whenSynced: serverTimestamp(),
                        batches: 1,
                        writeCount: 1
                    },
                    { merge: true }
                );
                batch.commit().then(() => {
                    console.log(`Saved fake actionsConfirmed id=${action.errorId}`);
                }).catch((e) => {
                    console.log(`Failed to saved fake actionsConfirmed id=${action.errorId}`, e);
                })
            }
            batchTrace.reportError(error.message, error);
        });
    };

    const generateUntracedError = () => {
        reportError(`Failed to do something`, 'An error message', {message: 'An error message'} as any);
    };

    const generateYellowError = () => {
        setYellowError(`This is a fake yellow screen error generated at ${formatDatetime()}`);
    };

    useEffect(() => {
        if (yellowError) {
            throw new Error(yellowError);
        }
    }, [yellowError]);
    */

    return (
        <StandardPageLayout section="fleet">
            <SeaBreadcrumb>
                <SeaLink to="/fleet">Fleet Dashboard</SeaLink>
            </SeaBreadcrumb>

            <div className="columns wrap" style={{ width: '900px', maxWidth: '100%' }}>
                <div><h1>{`Hello,${user ? ` ${user.firstName}` : ''}`}</h1></div>
                <div className="right actions">
                    <div className="vessel-settings-fat">
                        <SeaButton zone="grey" size="wide" onClick={() => setShowEditSettingsModal(true)}>
                            <SeaIcon slot="start" icon="settings" /> Fleet Settings
                        </SeaButton>
                    </div>
                    <div className="vessel-settings-thin" onClick={() => setShowEditSettingsModal(true)}>
                        <SeaButton zone="grey" type="button" shape="circle">
                            <SeaIcon slot="icon-only" icon="settings" />
                        </SeaButton>
                    </div>
                </div>
            </div>

            <div style={{ height: '25px'}}></div>

            {/* Test error handling */}
            {/* <div style={{
                backgroundColor: 'ivory',
                marginBottom: '20px',
                padding: '8px 16px',
                borderRadius: '10px'
            }}>
                <p style={{ color: 'black' }}>
                    Generate errors for testing<br/>
                    ("Fake" errors will only be shown if we have been online for at least 15 minutes)
                </p>
                <SeaButton
                    onClick={(e) => generateSecurityError('Create the impossible')}
                >
                    Security Error
                </SeaButton>
                <SeaButton
                    onClick={(e) => {
                        generateSecurityError('Create the impossible A');
                        generateSecurityError('Create the impossible B');
                        generateSecurityError('Create the impossible C');
                    }}
                >
                    3 Security Errors
                </SeaButton>
                <SeaButton
                    onClick={(e) => generateSecurityError('Create the impossible', true)}
                >
                    "Fake" Security Error
                </SeaButton>
                <SeaButton
                    onClick={(e) => generateUntracedError()}
                >
                    Untraced Error
                </SeaButton>
                <SeaButton
                    onClick={(e) => generateYellowError()}
                >
                    Yellow Error
                </SeaButton>
            </div> */}

            {vessels?.categorised?.vessels?.map((vessel) => {
                return (
                    <div key={vessel.id} className="white-zone vessel-card" onClick={(e) => onViewVessel(vessel.id)}>
                        <div className="basic-details">
                            <div className="vessel-image-div">
                                <SeaFileImage files={vessel.images} size="medium" mode="vessel"/>
                            </div>
                            <div className="vessel-info">
                                <div>
                                    <h2>{vessel.name}</h2>
                                </div>
                                <div>
                                    <div className="overdue">
                                        Overdue Tasks
                                    </div>
                                    <div className="overdue-tasks">
                                        <div className="mini-card">
                                            <div className="num">
                                                {(overdueTotals && overdueTotals.vessels[vessel.id] && overdueTotals.vessels[vessel.id].safety !== undefined)
                                                    ?
                                                    overdueTotals.vessels[vessel.id].safety
                                                    :
                                                    statUnknown
                                                }
                                            </div>
                                            Safety
                                        </div>
                                        <div className="mini-card">
                                            <div className="num">
                                                {(overdueTotals && overdueTotals.vessels[vessel.id] && overdueTotals.vessels[vessel.id].maintenance !== undefined)
                                                    ?
                                                    overdueTotals.vessels[vessel.id].maintenance
                                                    :
                                                    statUnknown
                                                }
                                            </div>
                                            Maintenance
                                        </div>
                                        <div className="mini-card">
                                            <div className="num">
                                                {(overdueTotals && overdueTotals.vessels[vessel.id] && overdueTotals.vessels[vessel.id].documents !== undefined)
                                                    ?
                                                    overdueTotals.vessels[vessel.id].documents
                                                    :
                                                    statUnknown
                                                }
                                            </div>
                                            Documents
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}

            {(vessels?.categorised?.shoreFacilities.length || 0) > 0 && 
                <>
                    <h2 style={{marginBottom: 20, marginTop: 30}}>Shore Based Facilities</h2>
                    {vessels?.categorised?.shoreFacilities?.map((vessel) => {
                        return (
                            <div key={vessel.id} className="white-zone vessel-card" onClick={(e) => onViewVessel(vessel.id)}>
                                <div className="basic-details">
                                    <div className="vessel-image-div">
                                        <SeaFileImage files={vessel.images} size="medium" mode="vessel"/>
                                    </div>
                                    <div className="vessel-info">
                                        <div>
                                            <h2>{vessel.name}</h2>
                                        </div>
                                        <div>
                                            <div className="overdue">
                                                Overdue Tasks
                                            </div>
                                            <div className="overdue-tasks">
                                                <div className="mini-card">
                                                    <div className="num">
                                                        {(overdueTotals && overdueTotals.vessels[vessel.id] && overdueTotals.vessels[vessel.id].safety !== undefined)
                                                            ?
                                                            overdueTotals.vessels[vessel.id].safety
                                                            :
                                                            statUnknown
                                                        }
                                                    </div>
                                                    Safety
                                                </div>
                                                <div className="mini-card">
                                                    <div className="num">
                                                        {(overdueTotals && overdueTotals.vessels[vessel.id] && overdueTotals.vessels[vessel.id].maintenance !== undefined)
                                                            ?
                                                            overdueTotals.vessels[vessel.id].maintenance
                                                            :
                                                            statUnknown
                                                        }
                                                    </div>
                                                    Maintenance
                                                </div>
                                                <div className="mini-card">
                                                    <div className="num">
                                                        {(overdueTotals && overdueTotals.vessels[vessel.id] && overdueTotals.vessels[vessel.id].documents !== undefined)
                                                            ?
                                                            overdueTotals.vessels[vessel.id].documents
                                                            :
                                                            statUnknown
                                                        }
                                                    </div>
                                                    Documents
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </>
            }

            {/* <div style={{ position: 'absolute', right: 'var(--page-padding-horizontal)' }}>
                <SeaButton onClick={(e) => {
                    setShowDebugging((current) => !current);
                }}>
                    {showDebugging ? 'Hide' : 'Show'} Debugging
                </SeaButton>
                <SeaButton onClick={(e) => {
                    sharedState.debugging.clear();
                }}>
                    Clear Debugging
                </SeaButton>
            </div>
            <div>
                {showDebugging && debugging?.['File Caching'] && (
                    <pre style={{ whiteSpace: 'pre-wrap' }}>
                        File Sync Debug:<br/>
                        {renderDebugging('File Caching')}
                    </pre>
                )}
            </div> */}
            <EditFleetSettingsModal
                showModal={showEditSettingsModal}
                setShowModal={setShowEditSettingsModal}
            />
        </StandardPageLayout>
    );
};

export default FleetDashboard;
