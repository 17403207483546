import { DateTime } from 'luxon';
import { renderFullNameForUserId } from '../shared-state/Core/users';
import { getFileNameFromString } from "./files";
import { DateTimeAcceptable, formatSeaDate, formatShortTimeDurationHrsMinsView, makeDateTime } from './datesAndTime';
import { SparePart } from '../shared-state/VesselMaintenance/spareParts';
import { sharedState } from '../shared-state/shared-state';
import { Control } from '../shared-state/HealthSafety/risks';
import { ControlType } from '../modals/HealthSafety/RiskRegister/EditRiskAssessment/EditRiskAssessment';
import { ForecastData } from '../components/SeaWindyMap/SeaWindyMap';
import { formatCoordsForCsv } from './mapping';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type CsvColumnBase<T> = {
    name: string;
    id?: string; // Used for custom forms
};
  
export type CsvSingleColumn<T> = CsvColumnBase<T> & {
    value: (item: T) => string | number | undefined;
};
  

export type CsvMultiColumn<T, U> = CsvColumnBase<T> & {
    items: (item: T) => U[];
    columns: (item?: T, index?: number) => CsvColumn<U, T>[];
};
  
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type CsvColumn<T, U = undefined> = CsvSingleColumn<T> | CsvMultiColumn<T, any>;
  
export type CsvConfig<T> = CsvColumn<T>[];

export const formatCsvString = (value?: any): string => {
    if (!value) return '';
    if (typeof value !== 'string') value = value.toString();

    // Replace commas with fullwidth commas, escape double quotes, and replace line breaks with CRLF.
    return value
        .replace(/"/g, '""')       // Escape double quotes
        .replace(/\r\n|\n|\r/g, '\r\n'); // Replace line breaks
};

export const formatCsvDate = (date?: number | string) => {
    if (!date) return '';
    return makeDateTime(date).toFormat('yyyy-MM-dd');
};

export const formatCsvDatetime = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('yyyy-MM-dd HH:mm');
};

export const formatCsvTimeDuration = (date?: number) => {
    if (!date) return '';
    return formatShortTimeDurationHrsMinsView(date);
};

export const formatCsvFiles = (files?: string[]) => {
    if (!files?.length) return '';
    return files.map((file) => {
        return getFileNameFromString(file);
    }).join(', ');
};

export const formatCsvBoolean = (value: any) => {
    return value ? 'Y' : 'N';
};

export const formatCsvSignature = (value: any) => {
    return value ? 'Signed' : '';
};

export const renderCsvIntervalType = (value?: string) => {
    if (!value) return '';
    
    switch (value) {
        case 'weekMonth':
            return 'Week / Month';
        case 'engineHours':
            return 'Hours';
        case 'weekMonthAndHours':
            return 'Week / Month & Hours';
        default:
            return value;
    }
}

export const formatCsvStatus = (dateDue: DateTimeAcceptable | undefined, warnDays: number, hasFault?: boolean) => {
    if (!dateDue) return ''; // Return early if no due date

    const due = DateTime.fromISO(formatSeaDate(dateDue)); // Crunch day to match local timezone
    const today = DateTime.fromISO(formatSeaDate());
    const days = due.diff(today, ['days']).days;

    if (hasFault) return 'FAULT';
    if (days === 0) return 'Due Today';
    
    return `${days} day${(days > 1) ? 's' : ''}`;
}

export const formatCsvSpareParts = (sparePartsUsed: {[sparePartId: string]: { used?: number | undefined; added?: number | undefined; }} | undefined, spareParts: Record<string, SparePart> | undefined, format: 'used' | 'added' | 'both') => {
    const _format = format || 'both';
    if (!sparePartsUsed || !spareParts) return '';
    return Object.entries(sparePartsUsed)
        .map(([key, value]) => {
            const sparePart = spareParts[key];
            if (!sparePart) return '';
            const used = value.used || 0;
            const added = value.added || 0;
            if (_format === 'used' && used) return `${sparePart.item}: x${used}`;
            if (_format === 'added' && added) return `${sparePart.item}: x${added}`;
            return `${sparePart.item}: x${used}/x${added}`;
        }).join(', ');
}

export const formatCsvRisk = (riskRating: number | string, type: 'likelihoods' | 'consequences' = 'consequences') => {
    const licenseeSettings = sharedState.licenseeSettings.current;
    if (!licenseeSettings?.riskRegister) return '';
    return `${(licenseeSettings.riskRegister[type][`C${riskRating}`] as ControlType)?.name}`;
}

export const formatCsvRiskRating = (likelihoodId: number | string, consequenceId?: number | string) => {
    const licenseeSettings = sharedState.licenseeSettings.current;
    if (!licenseeSettings?.riskRegister?.matrix) return '';
    const raw = licenseeSettings.riskRegister.matrix[`${likelihoodId}-${consequenceId || 0}`] || '';
    return raw.substring(7);
}

export const formatCsvRiskDescription = (controls: Control) => {
    const licenseeSettings = sharedState.licenseeSettings.current;
    if (!licenseeSettings?.riskRegister?.consequences) return '';
    return (licenseeSettings.riskRegister.consequences[`C${Number(controls.likelihood) - Number(controls.consequence)}`] as { name: string, description: string })?.description || '';
};


export const formatCsvNameFromIds = (id: string | undefined, collection?: Record<string, any>, key = 'name') => {
    if (!id || !collection) return '';
    if (!collection[id]?.[key]) return '';
    return collection[id][key]
};

export const formatCsvNamesFromIds = (ids: string[] | undefined, collection?: Record<string, any>, key = 'name') => {
    if (!ids?.length || !collection) return '';
    return ids.map(
        (id) => {
            if (!collection[id]?.[key]) return '';
            return collection[id][key]
            // .replace(/[.,;:]$/, '')
        }
    ).join(', '); // trims any trailing periods
};

export const formatCsvUserNamesFromIds = (ids: string[] | undefined) => {
    if (!ids?.length) return '';
    return ids.map((id) => {
        const fullName = renderFullNameForUserId(id);
        return formatCsvString(fullName);
    }).join(',');
}

export const formatCsvForecast = (forecast?: ForecastData) => {
    if (!forecast?.lat || !forecast?.lon) return '';
    
    return `${formatCoordsForCsv(forecast.lat, forecast.lon)}`;
}

// Generate CSV object for exporting from data and columns
export const generateCsvObject = <T>(data: T[], columns: CsvColumn<T>[], filterBlankHistoryItems?: boolean) => {    
    const headers: { label: string; key: string }[] = [];
    const rows: { [key: string]: any; }[] = [];
    
    // Generate headers including multi column headers
    function addHeaders(column: CsvColumn<any>, parentName = '') {
        if ('value' in column) {
            headers.push({ label: `${parentName}${column.name}`, key: `${parentName}${column.name}` });
        } else {
            for (const subheader of column.columns()) {
                addHeaders(subheader, `${parentName}${column.name}/`);
            }
        }
    }

    function processItems(item: any, columns: CsvColumn<any>[], parentName = '', filter?: boolean): { [key: string]: any }[] {
        const rows: { [key: string]: any }[] = [];
        const rowObject: { [key: string]: any } = {};
        // Judges if the row should be skipped
        let shouldSkip = false;
    
        columns.forEach((column) => {
            if ('value' in column) {
                // Single column
                let value = column.value(item);
                if (value === undefined) {
                    value = '';
                } else if (typeof value === 'number') {
                    value = value.toString();
                }
                rowObject[`${parentName}${column.name}`] = formatCsvString(value);
            } else {
                // Skip if no columns are present
                shouldSkip = true;
                // Multi column
                const subItems = column.items(item);
                if (Array.isArray(subItems) && subItems.length > 0) {
                    // Columns are present
                    shouldSkip = false;
                    subItems.forEach((subItem, subIndex) => {
                        const subRows = processItems(subItem, column.columns(item, subIndex), `${parentName}${column.name}/`);
                        if (subIndex === 0) {
                            // Merge the first sub-row into the main rowObject
                            Object.assign(rowObject, subRows[0]);
                            // Add the remaining sub-rows to the rows array
                            rows.push(...subRows.slice(1));
                        } else {
                            // Add the sub-rows to the rows array
                            rows.push(...subRows);
                        }
                    });
                }
            }
        });

        if (filter && shouldSkip && rows.length === 0) {
            return [];
        }
    
        rows.unshift(rowObject); // Add the main rowObject before any subRows
        return rows;
    }
    
    for (const column of columns) {
        addHeaders(column);
    }
    
    // Generate data rows
    data.forEach((item) => {
        const itemRows = processItems(item, columns, '', filterBlankHistoryItems);
        if (itemRows.length > 0) {
            rows.push(...itemRows);
        }
    });
    
    // Combine headers and data rows
    return {headers, rows};
}
