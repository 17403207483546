import React, { ReactNode, useEffect, useCallback, useRef, useState } from 'react';
import { IonModal, createAnimation, IonSpinner, createGesture, isPlatform } from '@ionic/react';
import { confirmAction } from '../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { onModalOpened, onUserAction } from '../../shared-state/General/appActivity';
import { jsxToText } from '../../lib/util';
import { sharedState } from '../../shared-state/shared-state';
import { openContextualHelp } from '../../managers/ContextualHelpManager/ContextualHelpManager';
import SeaScrollableArea, { SeaScrollable } from '../SeaScrollableArea/SeaScrollableArea';
import SeaExporter, { ExportType } from '../SeaExporter/SeaExporter';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaModal.css';
interface SeaModalProps {
    children?: ReactNode,
    alternativeContent?: ReactNode,
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    isDirty?: () => boolean, // function that returns if dirty. Returning true it means we should ask before dismissing modal
    confirmDismissDirty?: { title: string, yes: string, no: string },
    size?: 'thin' | 'semi-thin' | 'standard' | 'semi-wide' | 'wide' | 'extra-wide' | 'edit-rich-text' | 'rich-text',
    title?: string | JSX.Element,
    disabled?: boolean, // reduces opacity and makes unclickable
    onOpened?: () => void,
    onFullyOpened?: () => void,
    onClosed?: () => void,
    level?: number,
    renderWhenHidden?: boolean,
    tabsPanel?: ReactNode,
    actionPanel?: ReactNode,
    noContentPadding?: boolean,
    onPdf?: React.ReactNode, // function to run if PDF button is pressed
    onCsv?: React.ReactNode, // function to run if CSV button is pressed
    setExportType?: (exportType: ExportType) => void, // Must be set to run onCsv or onPdf
    fixTitleHeight?: boolean,
    scrollEvents?: boolean, // Must be set to true to enable onScroll
    onScroll?: (event: Event) => void,
    setModalContentRef?: (ref: React.RefObject<SeaScrollable>) => void,
    viewOnly?: boolean,
    contentClassName?: string
}

const SeaModal: React.FC<SeaModalProps> = ({
    children,
    alternativeContent,
    showModal,
    setShowModal,
    isDirty,
    confirmDismissDirty = {
        title: 'Are you sure you want to leave this form?',
        yes: 'Yes, leave form',
        no: 'Cancel'
    },
    size = 'standard',
    title,
    disabled,
    onOpened,
    onFullyOpened,
    onClosed,
    level = 1,
    renderWhenHidden,
    tabsPanel,
    actionPanel,
    noContentPadding,
    onPdf,
    onCsv,
    setExportType,
    fixTitleHeight,
    scrollEvents = false,
    onScroll,
    setModalContentRef,
    viewOnly,
    contentClassName
}) => {
    const gestureRef = useRef<any>();
    const modalRef = useRef<HTMLIonModalElement>(null);
    const isDirtyRef = useRef<() => boolean>();
    const modalContentRef = useRef<SeaScrollable>(null);
    const [showIonModal, setShowIonModal] = useState(false);

    useEffect(() => {
        if (setModalContentRef) {
            setModalContentRef(modalContentRef);
        }
    }, [setModalContentRef]);

    useEffect(() => {
        isDirtyRef.current = isDirty;
    }, [isDirty]);

    useEffect(() => {
        if (showModal) {
            // Modal opened, therefore make sure form is reset
            if (title) {
                onModalOpened(jsxToText(title, 'Unknown'));
            }
            if (onOpened) {
                onOpened();
            }
        } else {
            if (onClosed) {
                onClosed();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]); // Unfortuantely this cause a lint warning... but if onOpened is added, it will infinitely rerender

    // If the modal is closed, set the isDismissingRef to true - Necessary to prevent the confirmAction from being called when the modal is actually closed
    useEffect(() => {
        setShowIonModal(showModal);
    }, [showModal]);

    const attemptDismiss = useCallback((): Promise<boolean> => {
        return new Promise((resolve) => {
            // Introduce a delay to ensure showModal state is updated
            // setTimeout(() => {
                if (!showModal) {
                    resolve(true);
                    return;
                }
                if (isDirtyRef.current && isDirtyRef.current()) {
                    confirmAction(
                        confirmDismissDirty.title,
                        confirmDismissDirty.yes,
                        confirmDismissDirty.no,
                        undefined,
                        'Leaving this form will mean losing any information you have entered so far.'
                    ).then(() => {
                        onUserAction(`Closed Modal (Confirmed) ${jsxToText(title, 'Unknown')}`);
                        setShowModal(false);
                        resolve(true);
                    }).catch(() => {
                        resolve(false);
                    });
                } else {
                    onUserAction(`Closed Modal ${jsxToText(title, 'Unknown')}`);
                    setShowModal(false);
                    resolve(true);
                }
            // 0 or even 1 is not enough
            // }, 0);
        });
    }, [confirmDismissDirty.no, confirmDismissDirty.title, confirmDismissDirty.yes, setShowModal, showModal, title]);

    const onDidPresent = useCallback(() => {
        if (modalRef?.current) {
            gestureRef.current = createGesture({
                gestureName: 'Swipe Right',
                el: modalRef.current,
                direction: 'x',
                gesturePriority: 50,
                onEnd: (detail) => {
                    if (
                        detail.deltaX > 100 &&
                        (detail.currentTime - detail.startTime) < 500 &&
                        !isPlatform('desktop') &&
                        sharedState.isSwipeEnabled.current
                    ) {
                        attemptDismiss();
                    }
                }
            });
            
            gestureRef.current.enable();
        } else {
            console.error('Modal ref not found');
        }
        if (onFullyOpened) {
            onFullyOpened();
        }
    }, [attemptDismiss, onFullyOpened]);

    useEffect(() => {
        return () => {
            if (gestureRef.current) {
                gestureRef.current.destroy();
            }
        };
    }, []);

    const enterAnimation = useCallback((baseEl: any) => {
        const backdropElement = baseEl.shadowRoot.querySelector('ion-backdrop');
        const wrapperElement = baseEl.shadowRoot.querySelector('.modal-wrapper');

        const backdropAnimation = createAnimation()
            .addElement(backdropElement!)
            .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

        const wrapperAnimation = createAnimation()
            .addElement(wrapperElement!)
            .fromTo('transform', 'translateX(100%)', 'translateX(0)')
            .fromTo('opacity', '0.01', '1');

        return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(250)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    }, []);

    const leaveAnimation = useCallback((baseEl: any) => {
        return enterAnimation(baseEl).direction('reverse');
    }, [enterAnimation]);

    const onDidDismiss = (e: any) => {
        setShowModal(false);
    }

    return (
        <IonModal
            ref={modalRef}
            isOpen={showIonModal}
            mode="ios"
            showBackdrop={true}
            backdropDismiss={true}
            canDismiss={attemptDismiss}
            className={`sea-modal ${size} level-${level} right-side-modal`}
            enterAnimation={enterAnimation}
            leaveAnimation={leaveAnimation}
            onDidDismiss={onDidDismiss}
            onDidPresent={onDidPresent}
        >
            <div className={`disable-sea-modal ${disabled ? 'active' : ''}`}> {/* Should only show this if desktop */}
                <IonSpinner name="crescent" className="sea-spinner"/>
            </div>
            <div className="columns" style={{ position: 'relative' }}>
                <div className={`title${setExportType ? ' has-pdf-button' : ''}${fixTitleHeight ? ' fixed-title-height' : ''}`}>
                <h2>
                    {title}
                    {viewOnly && (
                        <>
                            <span className="view-only-label"> (View Only)</span>
                            <div className="text sea-help" style={{ position: 'relative', top: '2px' }} onClick={(e) => openContextualHelp(e, {text: 'In order to edit, please locate and open this item from its designated area within the app.'})}>
                                <SeaIcon icon="help"/>
                            </div>
                        </>
                    )}
                </h2>
                </div>
                <div className="right">
                    {setExportType && (
                        <div className="sea-modal-pdf">
                            <SeaExporter
                                zone="white"
                                pdf={onPdf}
                                csv={onCsv}
                                setExportType={setExportType}
                            />
                        </div>
                    )}
                    {/* {onPdf && !viewOnly &&
                        <div className="sea-modal-pdf">
                            <SeaButton zone="white" shape="circle" onClick={(e) => onPdf()}>
                                <SeaIcon slot="icon-only" icon="pdf" />
                            </SeaButton>
                        </div>
                    }
                    {onCsv && !viewOnly &&
                        <div className="sea-modal-pdf">
                            <SeaButton zone="white" shape="circle" onClick={(e) => onCsv()}>
                                <SeaIcon slot="icon-only" icon="document" />
                            </SeaButton>
                        </div>
                    } */}
                    <div className="sea-modal-x" onClick={attemptDismiss}>
                        <SeaIcon icon="close"/>
                    </div>
                </div>
            </div>
            {tabsPanel && 
                <div className="sea-modal-tabs-panel">
                    {tabsPanel}
                </div>
            }
            {children &&
                <SeaScrollableArea
                    scrollEvents={scrollEvents}
                    onScroll={onScroll}
                    ref={modalContentRef}
                    className={contentClassName}
                >
                    <div className={`sea-modal-content ${noContentPadding ? 'no-padding' : ''}`}>
                        {(showModal || renderWhenHidden) && children}
                    </div>
                </SeaScrollableArea>
            }
            {alternativeContent}
            {actionPanel &&
                <div className="sea-modal-action-panel">
                    <div className="top-line"></div>
                    <div className="actions">
                        {actionPanel}
                    </div>
                </div>
            }
        </IonModal>
    );
};

export default SeaModal;
