import React, { useCallback, useState } from 'react';
import { cloneDivisions, Divisions } from '../../../shared-state/Core/divisions';
import { sharedState } from '../../../shared-state/shared-state';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaSelectDivision from '../../../components/SeaSelectDivision/SeaSelectDivision';
import "./EditVesselDivisionsModal.css";


interface EditVesselDivisionsModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    divisions?: Divisions,
    setDivisions: React.Dispatch<React.SetStateAction<Divisions | undefined>>
    level?: number
}

type DivisionIdsByVesselId = {
    [vesselId: string]: string
};

const EditVesselDivisionsModal: React.FC<EditVesselDivisionsModalProps> = ({
    showModal,
    setShowModal,
    divisions,
    setDivisions,
    level
}) => {
    const vessels = sharedState.vessels.use(showModal);
    const [divisionIdsByVesselId, setDivisionIdsByVesselId] = useState<DivisionIdsByVesselId>({});
    const [initialDivisionIdsByVesselId, setInitialDivisionIdsByVesselId] = useState<DivisionIdsByVesselId>({});

    const onOpened = () => {
        const d = {} as DivisionIdsByVesselId;
        if (divisions?.byId) {
            Object.values(divisions.byId).forEach((division) => {
                division.vessels.forEach((vessel) => {
                    d[vessel.id] = division.id
                });
            });
        }
        setDivisionIdsByVesselId(d);
        setInitialDivisionIdsByVesselId({...d});
    };

    const isModalDirty = useCallback(() => {
        let isDirty = false;
        Object.keys(divisionIdsByVesselId).forEach((vesselId) => {
            if (divisionIdsByVesselId[vesselId] !== initialDivisionIdsByVesselId[vesselId]) {
                isDirty = true;
            }
        });
        return isDirty;
    }, [divisionIdsByVesselId, initialDivisionIdsByVesselId]);

    const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isModalDirty()) {
            // Repopulate divisions with vessels
            setDivisions((_divisions) => {
                const divisions = cloneDivisions(_divisions!)!;
                // Remove all vessels first
                Object.values(divisions.byId).forEach((division) => {
                    division.vessels = [];
                });
                // Populate with vessels
                Object.keys(divisionIdsByVesselId).forEach((vesselId) => {
                    divisions.byId[
                        divisionIdsByVesselId[vesselId]
                    ].vessels.push(vessels!.byId[vesselId])
                });
                return divisions;
            });
            setShowModal(false);
        }
    }, [divisionIdsByVesselId, isModalDirty, setDivisions, setShowModal, vessels]);

    return (
        <SeaModal
            title="Edit Vessel Divisions"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="standard"
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <div>
                    {vessels?.all?.map((vessel) => {
                        return (
                            <div className="columns vessel-divisions-row">
                                <div>
                                    {vessel.name}
                                </div>
                                <div>
                                    <SeaSelectDivision
                                        divisions={divisions}
                                        divisionId={divisionIdsByVesselId[vessel.id]}
                                        setDivisionId={(divisionId: string) => {
                                            setDivisionIdsByVesselId((current) => {
                                                return {
                                                    ...current,
                                                    [vessel.id]: divisionId
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className='grid-row-spacer'></div>
                <div className="view-modal-buttons">
                    <SeaButton zone="white" size="wide" type="submit">
                        Update Vessel Divisions
                    </SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditVesselDivisionsModal;
