import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { CreateableDocument, UpdateableDocument, sharedState } from "../../shared-state";
import { canView } from "../../Core/userPermissions";
import { useEffect, useState } from 'react';
import { registerFiles, registerSignature } from "../../FileSyncSystem/filesToCache";
import { DateRange } from "../../../components/SeaDateRange/SeaDateRange";
import { addInterval, makeDateTime } from "../../../lib/datesAndTime";
//
// Load completed custom forms
//

export type CustomFormCompletedAttachTo = null | 'voyage' | 'trainingTaskReport';

export interface CustomFormCompleted extends UpdateableDocument, CreateableDocument {
    attachTo?: CustomFormCompletedAttachTo;
    attachToId?: string;
    attachToTrainingTaskId: string;
    attachToVesselId?: string;
    customFormId: string;
    data: any;
    deletedBy?: string;
    isDraft: boolean;
    licenseeId: string;
    personnelIds?: string[];
    state: string;
    version: number;
    vesselIds: string[];
    whenDeleted?: number;
    completedBy: string;
    whenCompleted: number;
    notes?: string;
    files?: string[];
}

export type CustomFormsCompletedData = {
    all: CustomFormCompleted[], // Active & alphabetical
    byId: {
        [id: string]: CustomFormCompleted
    },
    byVersion: {
        [version: string]: CustomFormCompleted[]
    }
};

export const useCustomFormsCompleted = (customFormId?: string, dateRange?: DateRange) => {
    const vesselIds = sharedState.vesselIds.current;
    const licenseeId = sharedState.licenseeId.current;
    const customFormVersions = sharedState.customFormVersions.use();
    const [customFormsCompleted , setCustomFormsCompleted] = useState<CustomFormsCompletedData>();

    useEffect(() => {
        setCustomFormsCompleted(undefined);
        if (
            customFormId && 
            vesselIds &&
            licenseeId &&
            canView('customForms')
        ) {
            return setupArrayQueryListener(
                'customFormsCompleted', // what
                collection(firestore, 'customFormsCompleted'),
                [
                    where('customFormId', '==', customFormId),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    ...(dateRange ? [
                        where('whenAdded', '>=', makeDateTime(dateRange.from).toMillis()),
                        where('whenAdded', '<', addInterval(dateRange.to, '1d').toMillis()),
                    ] : [])
                ],
                'vesselIds',
                'array-contains-any',
                ['none', ...vesselIds],
                [orderBy('whenAdded', 'desc')], // orderBy
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean // (since there is no orderBy, this is irrelevant)
                ) => { // processDocs
                    const all = docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as CustomFormCompleted;
                    });
                    const byId = {} as {
                        [id: string]: CustomFormCompleted
                    };
                    const byVersion = {} as {
                        [version: string]: CustomFormCompleted[]
                    };
                    all.forEach((form) => {
                        byId[form.id] = form;
                        const formVersion = customFormVersions?.byFormIdAndVersion[customFormId][form.version];
                        if (formVersion) {
                            for (let key in formVersion.form) {
                                if (formVersion.form.hasOwnProperty(key)) {
                                    if (formVersion.form[key].id === 'files') {
                                        if (form?.data[formVersion.form[key].n]) {
                                            registerFiles(form.data[formVersion.form[key].n], 'customFormsCompleted', form);
                                        }
                                    } else if (formVersion.form[key].id === 'signature') {
                                        if (form?.data[formVersion.form[key].n]) {
                                            registerSignature(form.data[formVersion.form[key].n], 'customFormsCompleted', form);
                                        }
                                    }
                                }
                            }
                            // Add to byVersion
                            if (!byVersion[form.version]) {
                                byVersion[form.version] = [];
                            }
                            byVersion[form.version].push(form);
                        }
                    });

                    if (isCombined) { // Need to sort by whenAdded
                        all.sort((a, b) => {
                            return b.whenAdded - a.whenAdded;
                        });
                    }

                    setCustomFormsCompleted({
                        all,
                        byId,
                        byVersion
                    });
                }
            );
        }
    }, [customFormVersions?.byFormIdAndVersion, licenseeId, customFormId, vesselIds, dateRange]);

    return customFormsCompleted;
};
