import React, { useEffect, useState } from 'react';
import { ServerEvent, useServerEvents } from '../../../../shared-state/Admin/useServerEvents';
import { formatDatetime } from '../../../../lib/datesAndTime';
import { formatValue } from '../../../../lib/util';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import EditServerMessageModal from '../../../../modals/Admin/EditServerMessageModal/EditServerMessageModal';
import './ServerEvents.css';

const renderEventStyle = (event: ServerEvent, now: number) => {
    if (event.state === 'deleted') {
        return { opacity: 0.4 };
    } else if (event.whenExpires && now > event.whenExpires) {
        return { backgroundColor: '#bbb' };
    } else if (event.whenEvent && now < event.whenEvent) {
        return { backgroundColor: '#bfb' }; // future
    }
    return { backgroundColor: '#fff' }; // current
};

const ServerEvents: React.FC<{visible: boolean}> = ({visible: boolean}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<ServerEvent>();
    const serverEvents = useServerEvents();

    useEffect(() => {
        resetPageLimit();
    }, [resetPageLimit]);

    const now = Date.now();

    return (
        <>
            <div>
                <SeaButton onClick={() => {
                    setSelectedEvent(undefined);
                    setShowMessageModal(true);
                }}>
                    Send Global Message
                </SeaButton>
            </div>
            {serverEvents?.all && serverEvents.all.length > 0 &&
                <div className="error-reports server-events">
                    <div className="sea-row headings">
                        <div>When Event</div>
                        <div>Type</div>
                        <div>Title</div>
                        <div>Content</div>
                        <div>When Expires</div>
                    </div>
                    {mapArrayWithLimit(serverEvents.all, (event) => {
                        return (
                            <div
                                key={event.id}
                                className="sea-card sea-row"
                                style={renderEventStyle(event, now)}
                                onClick={(e) => {
                                    setSelectedEvent(event);
                                    setShowMessageModal(true);
                                }}
                            >
                                <div className="truncate-2">{formatDatetime(event.whenEvent)}</div>
                                <div className="truncate-2">{event.type}</div>
                                <div className="truncate-2">
                                    {formatValue(event.title)}
                                    {event.state === 'deleted' ? ' (DELETED)' : ''}
                                </div>
                                <div className="truncate-2">{formatValue(event.content)}</div>
                                <div className="truncate-2">{event.whenExpires ? formatDatetime(event.whenExpires) : '-'}</div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            }
            <EditServerMessageModal
                showModal={showMessageModal}
                setShowModal={setShowMessageModal}
                selectedEvent={selectedEvent}
            />
        </>
    );
};

export default ServerEvents;
