import React, { useState } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc, serverTimestamp } from "firebase/firestore";
import { formatDateShort } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { renderFullName } from '../../../../shared-state/Core/users';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { UserType } from '../../../../shared-state/Core/user';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import ViewUserModal from '../ViewUserModal/ViewUserModal';
import './ArchiveUserModal.css';

interface ArchiveUserModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const ArchiveUserModal: React.FC<ArchiveUserModalProps> = ({showModal, setShowModal}) => {
    const userId = sharedState.userId.use(showModal);
    const users = sharedState.users.use(showModal);
    const [selectedUser, setSelectedUser] = useState<UserType>();
    const [showViewUserModal, setShowViewUserModal] = useState(false);

    const onUndoArchive = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: UserType) => {
        event.stopPropagation();
        confirmAction('Are you sure you want to unarchive this user?', 'Yes, unarchive').then(() => { 
            if (!item.id) {
                console.error('No user id');
                return;
            }

            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'users', 'unarchive', item.id);

            batch.set(
                doc(firestore, 'users', item.id),
                {
                    unarchivedBy: userId,
                    whenUnarchived: batchTrace.whenAction,
                    state: 'active'
                },
                { merge: true }
            );

            batch.set(
                doc(firestore, 'userDetails', item.id),
                {
                    state: 'active',
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            onCollectionUpdated(batch, 'userDetails');
            onCollectionUpdated(batch, 'users');

            logAction(
                batch,
                'Unarchive',
                'users',
                item.id,
                renderFullName(item),
                undefined,
                [item.id]
            );

            batchTrace.data = {item};
            batchTrace.save(`Unarchive user ${renderFullName(item)}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            showToast('Crew user is now active');
        }).catch(() => {});
    };
    const onDeleteUser = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, userSelected: UserType) => {
        event.stopPropagation();
        confirmAction('Are you sure you want to delete this user?', 'Yes, delete').then(() => {

            if (!userSelected.id) {
                console.error('No user id');
                return;
            }
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'users', 'delete', userSelected.id);

            batch.set(
                doc(firestore, 'users', userSelected.id),
                {
                    deletedBy: userId,
                    whenDeleted: batchTrace.whenAction,
                    state: 'deleted'
                },
                { merge: true }
            );

            batch.set(
                doc(firestore, 'userDetails', userSelected.id),
                {
                    state: 'deleted',
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            onCollectionUpdated(batch, 'userDetails');
            onCollectionUpdated(batch, 'users');

            logAction(
                batch,
                'Delete',
                'users',
                userSelected.id,
                renderFullName(userSelected),
                undefined,
                [userSelected.id]
            );

            batchTrace.data = {userSelected};
            batchTrace.save(`Delete user ${renderFullName(userSelected)}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            showToast('Crew user has now been deleted');
        }).catch(() => {});
    };

    const onViewProfile = (user: UserType) => {
        setSelectedUser(user)
        setShowViewUserModal(true);
    };

    return (
        <SeaModal
            title='Archived Crew'
            showModal={showModal}
            setShowModal={setShowModal}
            size='standard'
        >
            <SeaNoData
                dataName="archived crew users"
                isLoading={!users}
                hasNoData={users?.archived && users.archived.length === 0}
                isUsingFilter={false}
            />
            <div className={`crew-archived ${(users?.archived && users.archived.length > 0) ? 'reveal' : 'conceal'}`}>
                <div className="sea-row-history headings-history">
                    <div>Archived</div>
                    <div>Name</div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {users?.archived?.map((user: UserType) => {
                    return (
                        <div key={user.id} className="sea-card sea-row-history">
                            <div>{formatDateShort(user.whenArchived)}</div>
                            <div className="truncate-2">{renderFullName(user)}</div>
                            <div><SeaLinkButton mode="standard-link" onClick={(e) => onViewProfile(user)}>Profile</SeaLinkButton></div>
                            <div onClick={(e) => onUndoArchive(e, user)}><SeaIcon icon="undo" slot="icon-only" /></div>
                            <div onClick={(e) => onDeleteUser(e, user)}><SeaIcon icon='trash' slot="icon-only" /></div>
                        </div>
                    )
                })}
            </div>
            {showModal &&
                <ViewUserModal
                    showModal={showViewUserModal}
                    setShowModal={setShowViewUserModal}
                    selectedUser={selectedUser}
                />
            }
        </SeaModal>
    );
};

export default ArchiveUserModal;
