import { SharedStateConfig, sharedState } from "../shared-state";
import { Vessel } from "./vessel";

//
// Creates divisions based on vessels and licenseeSettings.
//

export type Division = {
    id: string,
    name: string,
    parent?: Division,
    children?: Division[],
    vessels: Vessel[],
    unique?: number
}
export type Divisions = {
    all: Division[], // Does not include root
    byId: {
        [divisionId: string]: Division
    },
    root: Division
}

export const createEmptyRootDivision = () => {
    return {
        id: 'root',
        name: 'Top Level',
        children: [],
        vessels: [],
        unique: Math.random()
    } as Division;
};

export const divisionsConfig: SharedStateConfig<Divisions> = {
    isAlwaysActive: true,
    dependencies: ['licenseeSettings', 'vessels'],
    countLiveDocs: () => sharedState.divisions.current ? sharedState.divisions.current.all.length : 0,
    run: (done, set, clear) => {
        clear();
        const vessels = sharedState.vessels.current;
        const licenseeSettings = sharedState.licenseeSettings.current;
        if (vessels && licenseeSettings?.divisions?.length) {

            const all = [] as Division[];
            const root = createEmptyRootDivision();
            const byId = {
                'root': root
            } as {
                [id: string]: Division
            };

            // Create list of divisions without tree structure
            licenseeSettings.divisions.forEach((_division) => {
                const division = {
                    id: _division.id,
                    name: _division.name,
                    vessels: [],
                    unique: Math.random()
                } as Division;
                byId[division.id] = division;
                all.push(division);
            });

            // Create tree structure
            licenseeSettings.divisions.forEach((_division) => {
                const division = byId[_division.id];
                const parent = _division.parentId ? byId[_division.parentId] : root;
                if (parent.children === undefined) {
                    parent.children = [];
                }
                parent.children.push(division);
                division.parent = parent;
            });

            // Populate with vessels
            vessels.all.forEach((vessel) => {
                if (vessel?.divisionId && byId[vessel.divisionId]) {
                    byId[vessel.divisionId].vessels.push(vessel);
                } else {
                    root.vessels.push(vessel);
                }
            });

            // Todo: Probably need list for which I have access too only?

            set({
                all,
                byId,
                root
            });
        }
        done();
    }
};

/**
 * We need this because simple cloning will mess up the tree structure
 */
export const cloneDivisions = (divisions: Divisions) => {
    const all = [] as Division[];
    const root = createEmptyRootDivision();
    root.vessels = [...divisions.root.vessels];
    const byId = {
        'root': root
    } as {
        [id: string]: Division
    };

    if (divisions?.all) {
        // Clone divisions without tree structure
        divisions.all.forEach((division) => {
            if (division.id === 'root') {
                return;
            }
            byId[division.id] = {
                id: division.id,
                name: division.name,
                vessels: [...division.vessels],
                unique: Math.random()
            }
            all.push(byId[division.id]);
        });
        // Create tree structure in clone
        divisions.all.forEach((division) => {
            if (division.id === 'root') {
                return;
            }
            const parent = byId[division.parent!.id];
            if (parent.children === undefined) {
                parent.children = [];
            }
            parent.children.push(byId[division.id]);
            byId[division.id].parent = parent;
        });
    }

    return {
        all,
        byId,
        root
    }
};

/**
 * Turns what is important within a Divisions to a string.
 * Namely, ids and parentIds in order of divisions.all
 */
export const divisionsToString = (divisions?: Divisions) => {
    let s = '';
    divisions?.all?.forEach((division) => {
        s += `${division.id}${division.parent!.id}`;
    });
    return s;
};
