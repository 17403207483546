import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonPopover, IonList, IonItem } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, pulseElementById, toMillis, getBasedOnBackgroundColour, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate, addInterval } from '../../../../lib/datesAndTime';
import { getDefaultCategoryId, makeCategoryId, renderCategoryName } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { sendVesselNotification } from '../../../../shared-state/Core/vessel';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { Risk } from '../../../../shared-state/HealthSafety/risks';
import { VesselOptions } from '../../../../shared-state/Core/vessels';
import { LinkType } from '../../../../shared-state/Core/links';
import { handleLinkUpdates } from '../../../../lib/links';
import { useItemLinks } from '../../../../shared-state/Core/useItemLinks';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaLinkMultiList from '../../../../components/SeaLinkMultiList/SeaLinkMultiList';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaLabel from '../../../../components/SeaLabel/SeaLabel';
import SeaInputError from '../../../../components/SeaInputError/SeaInputError';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import './EditRiskAssessment.css';

export interface ControlType {
    name: string,
    description: string
}

interface EditRiskAssessmentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setRiskMatrixModal: (options: {
        show: boolean,
        allowEdit: boolean,
        level: number,
        likelihoodId: number,
        consequenceId: number,
        type: string
    }) => void,
    level?: number,
    defaultVesselId?: string,
    itemToUpdate?: Risk,
}

const EditRiskAssessment: React.FC<EditRiskAssessmentProps> = ({showModal, setShowModal, setRiskMatrixModal, defaultVesselId, itemToUpdate, level = 1}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const user = sharedState.user.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const riskCategories = sharedState.riskCategories.use(showModal);
    const [vesselIds, setVesselIds] = useState<string[]>();
    const [vesselOptions, setVesselOptions] = useState<VesselOptions[]>();
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [riskPopover, setRiskPopover] = useState<{
        show: boolean,
        event: any | undefined,
        field: string,
        type: 'likelihoods' | 'consequences' | 'matrix' | '',
        arrayName: string,
        letter: string
    }>({
        show: false,
        event: undefined,
        field: '',
        type: '',
        arrayName: '',
        letter: '',
    });
    const [showRiskPrompts, setShowRiskPrompts] = useState(false);
    const [showControlPrompts, setShowControlPrompts] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const existingLinks = useItemLinks(itemToUpdate?.id);
    const [links, setLinks] = useState<LinkType[]>([]);

        

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                vesselIds: itemToUpdate.vesselIds,
                name: itemToUpdate.name ? ''+itemToUpdate.name : '',
                categoryId: itemToUpdate.categoryId ? ''+itemToUpdate.categoryId : '',
                risks: itemToUpdate.risks ? ''+itemToUpdate.risks : '',
                preLikelihood: (itemToUpdate.preControls?.likelihood) ? ''+itemToUpdate.preControls.likelihood : '',
                preConsequence: (itemToUpdate.preControls?.consequence) ? ''+itemToUpdate.preControls.consequence : '',
                postLikelihood: (itemToUpdate.postControls?.likelihood) ? ''+itemToUpdate.postControls.likelihood : '',
                postConsequence: (itemToUpdate.postControls?.consequence) ? ''+itemToUpdate.postControls.consequence : '',
                controls: itemToUpdate.controls ? ''+itemToUpdate.controls : '',
                whoResponsible: itemToUpdate.whoResponsible ? ''+itemToUpdate.whoResponsible : '',
                interval: itemToUpdate.interval ? ''+itemToUpdate.interval : '',
                dateLastReviewed: itemToUpdate.dateLastReviewed ? formatSeaDate(itemToUpdate.dateLastReviewed) : '',
                shouldReportToManagement: itemToUpdate.shouldReportToManagement ? true : false
            };
        } else {
            return {
                vesselIds: defaultVesselId ? [defaultVesselId] : [],
                name: '',
                categoryId: getDefaultCategoryId('General', riskCategories),
                risks: '',
                preLikelihood: '',
                preConsequence: '',
                postLikelihood: '',
                postConsequence: '',
                controls: '',
                whoResponsible: '',
                interval: '',
                dateLastReviewed: formatSeaDate(),
                shouldReportToManagement: false
            };
        }
    }, [itemToUpdate, riskCategories, defaultVesselId]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setLinks(existingLinks ?? []);
        setValues(initialValues);

        const vesselIds = itemToUpdate ? itemToUpdate.vesselIds : (defaultVesselId ? [defaultVesselId] : []);
        const vesselOptions = user?.vesselIds?.map((vesselId: string) =>
            ({
                id: vesselId,
                name: vessels?.byId[vesselId]?.name
            } as VesselOptions)).sort((a, b) => a.name?.localeCompare(b.name));

        setVesselIds(vesselIds);
        setVesselOptions(vesselOptions);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().max(500).required(),
            categoryId: Yup.string().max(500).required(),
            risks: Yup.string().max(5000),
            preLikelihood: Yup.number().max(1000).required(),
            preConsequence: Yup.number().max(1000).required(),
            postLikelihood: Yup.number().max(1000).required(),
            postConsequence: Yup.number().max(1000).required(),
            controls: Yup.string().max(5000).required(),
            whoResponsible: Yup.string().max(500),
            interval: Yup.string().max(4).required(),
            dateLastReviewed: Yup.date().max(formatSeaDate()).required().min(...notTooOld),
            shouldReportToManagement: Yup.boolean()
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            // If vessels array is empty - update error state
            if (preventMultiTap('riskAssessment') || vesselIds === undefined || vesselIds.length === 0) {
                return;
            };
            if (!licenseeId) {
                throw new Error('Licensee ID not found');
            }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'risks');

                let itemId: string;

                if (itemToUpdate) {
                    itemId = itemToUpdate.id;
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'risks', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            name: data.name,
                            categoryId: makeCategoryId(
                                data.categoryId,
                                riskCategories,
                                deleteValue,
                                batch,
                                'riskCategories',
                                'licenseeId',
                                licenseeId
                            ),
                            vesselIds: vesselIds,
                            risks: data.risks ? data.risks : deleteValue,
                            preControls: {
                                likelihood: data.preLikelihood,
                                consequence: data.preConsequence
                            },
                            controls: data.controls ? data.controls : deleteValue,
                            postControls: {
                                likelihood: data.postLikelihood,
                                consequence: data.postConsequence
                            },
                            whoResponsible: data.whoResponsible ? data.whoResponsible : deleteValue,
                            interval: data.interval,
                            dateLastReviewed: toMillis(data.dateLastReviewed),
                            dateDue: addInterval(data.dateLastReviewed, data.interval).toISODate(),
                            shouldReportToManagement: data.shouldReportToManagement ? data.shouldReportToManagement : deleteValue,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );

                    // saveFileRefs(batch, files, 'risks', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'risks',
                        itemToUpdate.id,
                        data.name,
                        itemToUpdate.vesselIds
                    );

                    if (data.shouldReportToManagement) {
                        sendVesselNotification(batch, 'riskAssessed', 'hazards', {
                            isUpdate: true,
                            id: itemToUpdate.id,
                            name: data.name,
                            category: renderCategoryName(data.categoryId, riskCategories),
                            risks: data.risks ? data.risks : undefined,
                            preControls: {
                                likelihood: (licenseeSettings?.riskRegister.likelihoods[`L${data.preLikelihood}`] as ControlType).name,
                                consequence: (licenseeSettings?.riskRegister.consequences[`C${data.preConsequence}`] as ControlType).name,
                                evaluation: licenseeSettings?.riskRegister.matrix[`${data.preLikelihood}-${data.preConsequence}`]?.substring(7)
                            },
                            controls: data.controls ? data.controls : undefined,
                            postControls: {
                                likelihood: (licenseeSettings?.riskRegister.likelihoods[`L${data.postLikelihood}`] as ControlType).name,
                                consequence: (licenseeSettings?.riskRegister.consequences[`C${data.postConsequence}`] as ControlType).name,
                                evaluation: licenseeSettings?.riskRegister.matrix[`${data.postLikelihood}-${data.postConsequence}`].substring(7)
                            },
                            whoResponsible: data.whoResponsible ? data.whoResponsible : undefined,
                            interval: data.interval,
                            dateLastReviewed: toMillis(data.dateLastReviewed),
                        }, files, itemToUpdate.vesselIds);
                    }

                } else {
                    const newRef = doc(collection(firestore, 'risks'));
                    itemId = newRef.id;
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    const obj = {
                        vesselIds: vesselIds,
                        addedBy: userId,
                        whenAdded: batchTrace.whenAction,
                        name: data.name,
                        categoryId: makeCategoryId(
                            data.categoryId,
                            riskCategories,
                            undefined,
                            batch,
                            'riskCategories',
                            'licenseeId',
                            licenseeId
                        ),
                        risks: data.risks ? data.risks : undefined,
                        preControls: {
                            likelihood: data.preLikelihood,
                            consequence: data.preConsequence
                        },
                        controls: data.controls ? data.controls : undefined,
                        postControls: {
                            likelihood: data.postLikelihood,
                            consequence: data.postConsequence
                        },
                        whoResponsible: data.whoResponsible ? data.whoResponsible : undefined,
                        interval: data.interval,
                        dateLastReviewed: toMillis(data.dateLastReviewed),
                        dateDue: addInterval(data.dateLastReviewed, data.interval).toISODate(),
                        shouldReportToManagement: data.shouldReportToManagement ? data.shouldReportToManagement : undefined,
                        state: 'active',
                        files: seaFilesToValue(files),
                        touched: serverTimestamp(),
                    };
                    batch.set(newRef, obj);

                    saveFileRefs(batch, files, 'risks', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'risks',
                        newRef.id,
                        data.name,
                        vesselIds
                    );
                    if (data.shouldReportToManagement) {
                        sendVesselNotification(batch, 'riskAssessed', 'hazards', {
                            id: newRef.id,
                            name: data.name,
                            categoryId: renderCategoryName(data.categoryId, riskCategories),
                            risks: data.risks ? data.risks : undefined,
                            preControls: {
                                likelihood: (licenseeSettings?.riskRegister.likelihoods[`L${data.preLikelihood}`] as ControlType).name,
                                consequence: (licenseeSettings?.riskRegister.consequences[`C${data.preConsequence}`] as ControlType).name,
                                evaluation: licenseeSettings?.riskRegister.matrix[`${data.preLikelihood}-${data.preConsequence}`]?.substring(7)
                            },
                            controls: data.controls ? data.controls : undefined,
                            postControls: {
                                likelihood: (licenseeSettings?.riskRegister.likelihoods[`L${data.postLikelihood}`] as ControlType).name,
                                consequence: (licenseeSettings?.riskRegister.consequences[`C${data.postConsequence}`] as ControlType).name,
                                evaluation: licenseeSettings?.riskRegister.matrix[`${data.postLikelihood}-${data.postConsequence}`].substring(7)
                            },
                            whoResponsible: data.whoResponsible ? data.whoResponsible : undefined,
                            interval: data.interval,
                            dateLastReviewed: toMillis(data.dateLastReviewed),
                        }, files, vesselIds);
                    }
                }

                batchTrace.data = {
                    vesselIds,
                    data,
                    files: seaFilesToValue(files),
                    itemToUpdate
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} risk ${data.name}`);

                onCollectionUpdated(batch, 'risks');

                handleLinkUpdates(
                    batch,
                    links,
                    existingLinks,
                    itemId,
                    'risks'
                )

                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload risk files`, error.message, error, {
                        data,
                        files: seaFilesToValue(files),
                        itemToUpdate
                    });
                }
            });
        }
    });

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [values, files, initialValues, itemToUpdate?.files])

    const openRiskPopover = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, field: string, type: 'likelihoods' | 'consequences' | 'matrix', arrayName: string, letter: string) => {
        // e.persist(); // No longer needed in React 17+
        setRiskPopover({
            show: true,
            event: e,
            field,
            type,
            arrayName,
            letter
        });
    }
    const closeRiskPopover = () => {
        setRiskPopover({
            ...riskPopover,
            show: false
        });
    };

    const openRiskMatrixModal = (preOrPost: 'pre' | 'post') => {
        setRiskMatrixModal({
            show: true,
            allowEdit: false,
            level: 3,
            likelihoodId: values[`${preOrPost}Likelihood` as 'preLikelihood' | 'postLikelihood'] ? parseInt(values[`${preOrPost}Likelihood` as 'preLikelihood' | 'postLikelihood']) : 0,
            consequenceId: values[`${preOrPost}Consequence` as 'preConsequence' | 'postConsequence'] ? parseInt(values[`${preOrPost}Consequence` as 'preConsequence' | 'postConsequence']) : 0,
            type: `${preOrPost}Controls`
        });
    };

    const toggleRiskPrompts = () => {
        setShowRiskPrompts((previous: boolean) => {return !previous;});
    };

    const toggleControlPrompts = () => {
        setShowControlPrompts((previous: boolean) => {return !previous;});
    };

    const addPrompt = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, field: 'risks' | 'controls' ) => {
        if (e.currentTarget.innerText) {
            let s = values[field];
            if (s.length > 0) {
                s += ', ';
            }
            s += e.currentTarget.innerText;
            setFieldValue(field, s);
            //pulseElementById(`${field}Field`);
        }
    };

    useEffect(() => {
        if (values.preLikelihood && values.preConsequence) {
            pulseElementById('preRiskRating');
        }
    }, [values.preLikelihood, values.preConsequence]);

    useEffect(() => {
        if (values.postLikelihood && values.postConsequence) {
            pulseElementById('postRiskRating');
        }
    }, [values.postLikelihood, values.postConsequence]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Risk Assessment' : 'Add New Risk Assessment'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size='wide'
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaMultiSelect
                                label="Vessels / Facilities"
                                help={{text: 'Select which vessels and/or facilities this hazard/risk is associated with.'}}
                                modalTitle="Vessels / Facilities"
                                values={vesselIds}
                                setValues={setVesselIds}
                                options={vesselOptions}
                                useAllOption={true}
                                required={true}
                                isSubmitting={isSubmitting}
                                emptyText="Not Set"
                            />
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Hazard/Risk Name"
								name="name"
								value={values.name}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.name ? errors.name : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={riskCategories}
                                label="Category"
                                name="categoryId"
                                initialCategoryId={initialValues.categoryId}
                                categoryId={values.categoryId}
                                otherPlaceholder="New Category"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.categoryId ? errors.categoryId : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Risks Associated With This Hazard"
                                name="risks"
                                id="risksField"
                                value={values.risks}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                height={100}
                                error={touched.risks ? errors.risks : ''}
                            />
                            <div className="input-link no-select">
                                <div onClick={(e) => toggleRiskPrompts()}>
                                    {showRiskPrompts ? 'Hide' : 'Show' } examples&nbsp;
                                    <SeaIcon icon={showRiskPrompts ? 'moveUp' : 'moveDown'} />
                                </div>
                            </div>
                            <div className={`risk-prompts ${showRiskPrompts ? 'show' : 'hide'}`}>
                                <SeaLabel>Risk prompt words</SeaLabel>
                                <div>
                                    <ul>
                                        {licenseeSettings?.riskRegister?.riskPrompts?.map((text: string) => {
                                            return (
                                                <li key={text} onClick={(e) => addPrompt(e, 'risks')}>
                                                    {text}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </IonCol>
                        <IonCol size="12">
                            <div className="columns clickable" onClick={(e) => openRiskMatrixModal('pre')}>
                                <h4>Pre Controls Risk</h4>
                                <div className="text sea-help modal-h4">
                                    <SeaIcon icon="help"/>
                                </div>
                            </div>
                        </IonCol>
                        <IonCol size="4">
                            <SeaLabel>Likelihood</SeaLabel>
                            <div
                                className={`sea-input sea-select multi ${(touched.preLikelihood && errors.preLikelihood) ? 'has-error' : ''}`}
                                onClick={(e) => openRiskPopover(
                                    e,
                                    'preLikelihood',
                                    'likelihoods',
                                    'rows',
                                    'L'
                                )}
                            >
                                <div className="select-text no-select">
                                    {values.preLikelihood && (licenseeSettings?.riskRegister.likelihoods[`L${values.preLikelihood}`] as ControlType).name}
                                </div>
                                <div className="select-icon">
                                    <div className="select-icon-inner"></div>
                                </div>
                            </div>
                            <SeaInputError>{touched.preLikelihood && errors.preLikelihood}</SeaInputError>
                        </IonCol>
                        <IonCol size="4">
                            <SeaLabel>Consequence</SeaLabel>
                            <div
                                className={`sea-input sea-select multi ${(touched.preConsequence && errors.preConsequence) ? 'has-error' : ''}`}
                                onClick={(e) => openRiskPopover(
                                    e,
                                    'preConsequence',
                                    'consequences',
                                    'columns',
                                    'C'
                                )}
                            >
                                <div className="select-text no-select">
                                    {values.preConsequence && (licenseeSettings?.riskRegister.consequences[`C${values.preConsequence}`] as ControlType).name}
                                </div>
                                <div className="select-icon">
                                    <div className="select-icon-inner"></div>
                                </div>
                            </div>
                            <SeaInputError>{touched.preConsequence && errors.preConsequence}</SeaInputError>
                        </IonCol>
                        <IonCol size="4">
                            <SeaLabel>Risk Rating</SeaLabel>
                            <div
                                id="preRiskRating"
                                className="sea-input risk-rating clickable no-select"
                                style={{
                                    backgroundColor: values.preLikelihood && values.preConsequence && ('#'+licenseeSettings?.riskRegister.matrix[`${values.preLikelihood}-${values.preConsequence}`].substring(0,6))
                                }}
                                onClick={(e) => openRiskMatrixModal('pre')}
                            >
                                <div style={{
                                    color: values.preLikelihood && values.preConsequence && getBasedOnBackgroundColour(licenseeSettings?.riskRegister.matrix[`${values.preLikelihood}-${values.preConsequence}`].substring(0,6)!)
                                }}>
                                    {values.preLikelihood && values.preConsequence && licenseeSettings?.riskRegister.matrix[`${values.preLikelihood}-${values.preConsequence}`].substring(6)}
                                </div>
                            </div>
                        </IonCol>
                        <IonCol size="12">
                            <div className="form-line"></div>
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                name="controls"
                                label="Risk Controls"
                                help={{ text: 'What controls do we want to put in place to mitigate the likelihood and/or consequences of this risk/hazard?' }}
                                value={values.controls}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                height={100}
                                error={touched.controls ? errors.controls : ''}
                            />
                            <div className="input-link no-select">
                                <div onClick={(e) => toggleControlPrompts()}>
                                    {showControlPrompts ? 'Hide' : 'Show' } examples&nbsp;
                                    <SeaIcon icon={showControlPrompts ? 'moveUp' : 'moveDown'} />
                                </div>
                            </div>
                            <div className={`risk-prompts ${showControlPrompts ? 'show' : 'hide'}`}>
                                <SeaLabel>Control prompt words</SeaLabel>
                                <div>
                                    <ul>
                                        {licenseeSettings?.riskRegister?.controlPrompts?.map((text: string) => {
                                            return (
                                                <li key={text} onClick={(e) => addPrompt(e, 'controls')}>
                                                    {text}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </IonCol>
                        <IonCol size="12">
                            <div className="form-line"></div>
                            <div className="columns clickable" onClick={(e) => openRiskMatrixModal('post')}>
                                <h4>Post Controls Risk</h4>
                                <div className="text sea-help modal-h4">
                                    <SeaIcon icon="help"/>
                                </div>
                            </div>
                        </IonCol>
                        <IonCol size="4">
                            <SeaLabel>Likelihood</SeaLabel>
                            <div
                                className={`sea-input sea-select multi ${(touched.postLikelihood && errors.postLikelihood) ? 'has-error' : ''}`}
                                onClick={(e) => openRiskPopover(
                                    e,
                                    'postLikelihood',
                                    'likelihoods',
                                    'rows',
                                    'L'
                                )}
                            >
                                <div className="select-text no-select">
                                    {values.postLikelihood && (licenseeSettings?.riskRegister.likelihoods[`L${values.postLikelihood}`] as ControlType).name}
                                </div>
                                <div className="select-icon">
                                    <div className="select-icon-inner"></div>
                                </div>
                            </div>
                            <SeaInputError>{touched.postLikelihood && errors.postLikelihood}</SeaInputError>
                        </IonCol>
                        <IonCol size="4">
                            <SeaLabel>Consequence</SeaLabel>
                            <div
                                className={`sea-input sea-select multi ${(touched.postConsequence && errors.postConsequence) ? 'has-error' : ''}`}
                                onClick={(e) => openRiskPopover(
                                    e,
                                    'postConsequence',
                                    'consequences',
                                    'columns',
                                    'C'
                                )}
                            >
                                <div className="select-text no-select">
                                    {values.postConsequence && (licenseeSettings?.riskRegister.consequences[`C${values.postConsequence}`] as ControlType).name}
                                </div>
                                <div className="select-icon">
                                    <div className="select-icon-inner"></div>
                                </div>
                            </div>
                            <SeaInputError>{touched.postConsequence && errors.postConsequence}</SeaInputError>
                        </IonCol>
                        <IonCol size="4">
                            <SeaLabel>Risk Rating</SeaLabel>
                            <div
                                id="postRiskRating"
                                className="sea-input risk-rating clickable no-select"
                                style={{
                                    backgroundColor: values.postLikelihood && values.postConsequence && ('#'+licenseeSettings?.riskRegister.matrix[`${values.postLikelihood}-${values.postConsequence}`].substring(0,6))
                                }}
                                onClick={(e) => openRiskMatrixModal('post')}
                            >
                                <div style={{
                                    color: values.postLikelihood && values.postConsequence && getBasedOnBackgroundColour(licenseeSettings?.riskRegister.matrix[`${values.postLikelihood}-${values.postConsequence}`].substring(0,6)!)
                                }}>
                                    {values.postLikelihood && values.postConsequence && licenseeSettings?.riskRegister.matrix[`${values.postLikelihood}-${values.postConsequence}`].substring(6)}
                                </div>
                            </div>
                        </IonCol>
                        <IonCol size="12">
                            <div className="form-line"></div>
                        </IonCol>
                        <IonCol size="4">
                          	<SeaInput
								label="Person/s Responsible"
								name="whoResponsible"
								value={values.whoResponsible}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.whoResponsible ? errors.whoResponsible : ''}
                          	/>
                        </IonCol>
                        <IonCol size="4">
                            <SeaSelectInterval
                                label="Review Interval"
                                name="interval"
                                value={values.interval}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.interval ? errors.interval : ''}
                            />
                        </IonCol>
                        <IonCol size="4">
                            <SeaDate
                                disabled={itemToUpdate ? true : false}
                                label="Date Last Reviewed"
                                name="dateLastReviewed"
                                value={values.dateLastReviewed}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.dateLastReviewed ? errors.dateLastReviewed : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="risks"
                                field="files"
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaCheckbox
                                name="shouldReportToManagement"
                                help={{ text: 'A notification will be sent to all crew who have selected "Hazards" under "Email Notifications" within their crew particulars.' }}
                                checked={values.shouldReportToManagement}
                                setFieldValue={setFieldValue}
                                checkedColor="primary"
                                error={touched.shouldReportToManagement ? errors.shouldReportToManagement : ''}
                            >
                                Report new Hazard / Risk to relevant crew
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="12">
                            <SeaLinkMultiList
                                selectedCollection='risks'
                                selectedItemId={itemToUpdate?.id || ''}
                                values={links}
                                setValues={setLinks}
                                linkOptions={['scheduledMaintenanceTasks', 'SOPs', 'companyDocuments', 'customForms', 'external']}
                                deleteable
                                confirmDelete
                                level={level+1}
                                showVesselNameInCategory
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid && !(vesselIds === undefined || vesselIds.length === 0)}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Risk Assessment' : 'Save New Risk Assessment'}</SeaButton>
                </div>
            </form>
            <IonPopover
                event={riskPopover.event}
                isOpen={riskPopover.show}
                onDidDismiss={() => closeRiskPopover()}
                className="risk-popover"
            >
                {riskPopover.show &&
                    <IonList>
                        {riskPopover.type !== '' && (licenseeSettings?.riskRegister[riskPopover.type][riskPopover.arrayName] as number[]).map((id: number) => {
                            if (riskPopover.type === '')
                                {
                                    return null;
                                }                            
                                const item = licenseeSettings?.riskRegister[riskPopover.type][`${riskPopover.letter}${id}`] as ControlType;
                            return (
                                <IonItem
                                    key={id}
                                    button
                                    onClick={(e) => {
                                        setFieldValue(riskPopover.field, id);
                                        closeRiskPopover();
                                    }}
                                >
                                    <div className="risk-options">
                                        <div>
                                            {item?.name}
                                        </div>
                                        <div>
                                            {item?.description}
                                        </div>
                                    </div>
                                </IonItem>
                            );
                        })}
                    </IonList>
                }
            </IonPopover>
        </SeaModal>
    );
};

export default EditRiskAssessment;
