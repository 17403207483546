import React, { useEffect, useMemo, useState } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { stringify } from 'csv-stringify/sync';
import { renderVesselName } from '../../shared-state/Core/vessels';
import { DateRange } from '../SeaDateRange/SeaDateRange';
import { formatDate } from '../../lib/datesAndTime';
import { CsvColumn, generateCsvObject } from '../../lib/csv';
import { Data } from 'react-csv/lib/core';
import { IonSpinner, isPlatform } from '@ionic/react';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { alertMessage } from '../../managers/AlertManager/AlertManager';
import { toSafeFilename } from '../../lib/files';
import { Share } from '@capacitor/share';
import SeaAlert from '../SeaAlert/SeaAlert';
import './SeaCSVGenerator.css';

export type SeaCSVGeneratorProps<T> = {
    onCompleted: () => void,
    name: string,
    data?: T[],
    config?: CsvColumn<T>[],
    dateRange?: DateRange, // Must use if wanting to filter out items with no history
    filtersForFilename?: string[], // used if wanting to add filters to the filename
}

const SeaCSVGenerator = <T,>({ onCompleted, data, config, name, dateRange, filtersForFilename }: SeaCSVGeneratorProps<T>) => {
    const vesselId = sharedState.vesselId.use();
    const [csvData, setCsvData] = useState<Data | string>()
    const [csvHeaders, setCsvHeaders] = useState<{
        label: string;
        key: string;
    }[]>();
    const [generatingCsv, setGeneratingCsv] = useState(true);
    const [showAlert, setShowAlert] = useState(true);

    const generateCSVContent = () => {    
        if (!csvData || !csvHeaders || typeof csvData === 'string') {
            console.log('Invalid csvData or csvHeaders');
            return '';
        }
        const headerRow = csvHeaders.map(header => header.label);
        const dataRows = Array.isArray(csvData) ? csvData.map((row: Record<string, any>) => 
            csvHeaders.map(header => row[header.key])
        ) : [];

        console.log('headerRow', headerRow);
        console.log('dataRows', dataRows);
        
        return stringify([headerRow, ...dataRows]);
    };

    const filename = useMemo(() => {
        const vesselSuffix = vesselId ? `_${renderVesselName(vesselId)}` : '';
        let _filename = `SeaFlux_${name}${vesselSuffix}`;
        if (filtersForFilename) {
            _filename += `_${filtersForFilename.join('_')}`;
        }
        if (dateRange) {
            _filename += `_${formatDate(dateRange.from)}_${formatDate(dateRange.to)}`;
        } else {
            const currentDate = formatDate();
            _filename += `_${currentDate}`;
        }
        _filename = _filename.replace(/ /g, '-');
        return toSafeFilename(_filename.endsWith('.csv') ? _filename : `${_filename}.csv`);
    }, [dateRange, filtersForFilename, name, vesselId]);

    const handleCleanup = (succeeded: boolean, errorMessage?: string) => {
        if (!succeeded) {
            setCsvData('');
            if (errorMessage) {
                alertMessage(errorMessage);
            }
        }
        onCompleted();
    };

    const handleDownload = () => {
        const csvContent = generateCSVContent();  
       
        if (!csvContent) {
            console.log('No CSV content generated');
            handleCleanup(false, 'Failed to generate CSV content. Please try again.');
            return;
        }
        
        const directory = isPlatform('android') ? Directory.Documents : Directory.Cache;
        const path = filename;
        
        return Filesystem.writeFile({
            path: path,
            data: csvContent,
            directory: directory,
            encoding: Encoding.UTF8
        }).then((result) => {
            if (isPlatform('ios')) {
                return Share.share({
                    title: toSafeFilename(name),
                    url: result.uri,
                }).then(() => {
                    return;
                }).catch(() => {
                    return;
                });
            } else if (isPlatform('android')) {
                alertMessage('This file has been saved to your downloads folder.');
            } else {
                const url = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }
        }).catch((error) => {
            console.error('Error saving file:', error);
            handleCleanup(false, 'Failed to download file. Please try again.');
        }).finally(() => {
            handleCleanup(true);
        });
    };

    useEffect(() => {
        if (data && config) {
            const res = generateCsvObject<T>(data, config, Boolean(dateRange));
            setCsvData(res.rows);
            setCsvHeaders(res.headers);
            setGeneratingCsv(false);
        }
    }, [data, config, dateRange]);

    if (!csvData) {
        return <></>
    }
    return (
            <SeaAlert
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                type="csv"
                onClosed={onCompleted}
            >
                <div className="csv-generator-container">
                    {generatingCsv ? (
                        <div>
                            <IonSpinner name="crescent" className="spinner" />
                            <br />
                            <p>Generating CSV, please wait...</p>
                        </div>
                    ) : (
                        <>
                            CSV successfully generated.
                            <br/>
                            <p className="download-link-container">
                                <a 
                                    onClick={handleDownload}
                                    className="download-link"
                                >
                                    Download <b>{filename}</b>
                                </a>
                            </p>
                        </>
                    )}
                </div>
            </SeaAlert>
    )
}

export default SeaCSVGenerator;