import { doc, onSnapshot, Timestamp } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";

//
// Holds the current global build numbers that are required (minimum) or recommended
//

export type ServerInfo = {
    androidBuildAvailable: number,
    androidBuildRequired: number,
    iosBuildAvailable: number,
    iosBuildRequired: number,
    webBuildAvailable: number,
    webBuildRequired: number,
    underMaintenance: boolean,
    whenMaintenance?: Timestamp | undefined,
    messages?: {
        whenSent: number,
        whenExpires: number,
        title: string,
        content: string
    }[]
};

export const serverInfoConfig: SharedStateConfig<ServerInfo> = {
    isAlwaysActive: true,
    dependencies: ['authUser'],
    countLiveDocs: () => sharedState.serverInfo.current ? 1 : 0,
    run: (done, set, clear) => {
        clear();
        const authUser = sharedState.authUser.current;
        if (authUser) {
            return onSnapshot(
                doc(firestore, 'global', 'serverInfo'),
                (doc) => {
                    done();
                    set(doc.data() as ServerInfo);
                }, (error) => {
                    done();
                    // sharedState.serverInfo.clear();
                    console.log(`Error getting global.serverInfo`, error);
                }
            );
        }
    },
};
