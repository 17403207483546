import React, { useCallback, useMemo } from 'react';
import { Document, View, Text, Page } from '@react-pdf/renderer';
import { pdfStyles } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { formatDate, formatDatetime } from '../../../lib/datesAndTime';
import { sharedState } from '../../../shared-state/shared-state';
import { UserType } from '../../../shared-state/Core/user';
import { UserDetails } from '../../../shared-state/Crew/userDetails';
import { renderCategoryNames } from '../../../lib/categories';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';

interface CrewParticularsDetailPdfProps {
    onCompleted: () => void,
    selectedUser?: UserType,
    selectedUserDetails?: UserDetails
}

const CrewParticularsDetailPdf: React.FC<CrewParticularsDetailPdfProps> = ({
    onCompleted,
    selectedUser,
    selectedUserDetails
}) => {
    const userRoles = sharedState.userRoles.use(true);
    const vessels = sharedState.vessels.use(true);

    const reportTitle = useMemo(() => {
        if (selectedUser) {
            return selectedUser?.firstName + ' ' + selectedUser?.lastName;
        }
        return '';
    }, [selectedUser]);

    const MakePDF = useCallback(() => {
        if (!selectedUser) return (<></>);

        const itemView = [];
        itemView.push(<View style={pdfStyles.column3} key='jobtitle'>
            <Text style={pdfStyles.label}>Job Title / Position</Text>
            <Text>
                {formatValue(selectedUser?.position)}
            </Text>
        </View>);

        itemView.push(<View style={pdfStyles.column3} key='roles'>
            <Text style={pdfStyles.label}>Roles/Departments</Text>
            <Text>
                {formatValue(renderCategoryNames(selectedUser?.roleIds, userRoles))}
            </Text>
        </View>);

        if (selectedUser?.isStaff) {
            itemView.push(<View style={pdfStyles.column3} key='dateOfBirth'>
                <Text style={pdfStyles.label}>Date of birth</Text>
                <Text>
                    {selectedUserDetails?.dateOfBirth ? formatDate(selectedUserDetails?.dateOfBirth) : '-'}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='inductedDate'>
                <Text style={pdfStyles.label}>Inducted date</Text>
                <Text>
                    {selectedUser?.dateInducted ? formatDate(selectedUser?.dateInducted) : '-'}
                </Text>
            </View>);
        }

        itemView.push(<View style={pdfStyles.column3} key='email'>
            <Text style={pdfStyles.label}>Email</Text>
            <Text>
                {selectedUserDetails?.email}
            </Text>
        </View>);
        itemView.push(<View style={pdfStyles.column3} key='phone'>
            <Text style={pdfStyles.label}>Phone #</Text>
            <Text>
                {formatValue(selectedUserDetails?.contactNumber)}
            </Text>
        </View>);
        itemView.push(<View style={pdfStyles.column3}>
            <Text style={pdfStyles.label}>Address</Text>
            <Text>
                {formatValue(selectedUserDetails?.address)}
            </Text>
        </View>);

        if (!selectedUser?.isStaff || selectedUser?.isLicensee) {
            itemView.push(<View style={pdfStyles.column3} key='companyName'>
                <Text style={pdfStyles.label}>Company Name</Text>
                <Text>
                    {formatValue(selectedUserDetails?.companyName)}
                </Text>
            </View>);
        }

        if (selectedUser?.isLicensee) {
            itemView.push(<View style={pdfStyles.column3} key='companyAddress'>
                <Text style={pdfStyles.label}>Company Address</Text>
                <Text>
                    {formatValue(selectedUserDetails?.companyAddress)}
                </Text>
            </View>);
        }

        if (selectedUser?.isStaff) {
            itemView.push(<View style={pdfStyles.column3} key='passport'>
                <Text style={pdfStyles.label}>Passport #</Text>
                <Text>
                    {formatValue(selectedUserDetails?.passportNumber)}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='nextOfKin'>
                <Text style={pdfStyles.label}>Next of kin</Text>
                <Text>
                    {formatValue(selectedUserDetails?.nextOfKin?.name)}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='nextOfKinRelationship'>
                <Text style={pdfStyles.label}>Next of kin relationship</Text>
                <Text>
                    {formatValue(selectedUserDetails?.nextOfKin?.relationship)}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='nextOfKinContact'>
                <Text style={pdfStyles.label}>Next of kin contact</Text>
                <Text>
                    {formatValue(selectedUserDetails?.nextOfKin?.phone)}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='medicalDoctor'>
                <Text style={pdfStyles.label}>Medical doctor</Text>
                <Text>
                    {formatValue(selectedUserDetails?.medicalDoctor?.name)}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='doctorContact'>
                <Text style={pdfStyles.label}>Doctor contact</Text>
                <Text>
                    {formatValue(selectedUserDetails?.medicalDoctor?.phone)}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='medicalIssues'>
                <Text style={pdfStyles.label}>Medical issues</Text>
                <Text>
                    {formatValue(selectedUserDetails?.currentMedicalIssues)}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='currentMedication'>
                <Text style={pdfStyles.label}>Current medication</Text>
                <Text>
                    {formatValue(selectedUserDetails?.currentMedication)}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='previousInjuries'>
                <Text style={pdfStyles.label}>Previous injuries/surgeries</Text>
                <Text>
                    {formatValue(selectedUserDetails?.previousInjuryOrSurgery)}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='allergies'>
                <Text style={pdfStyles.label}>Allergies</Text>
                <Text>
                    {formatValue(selectedUserDetails?.allergies)}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='bloodType'>
                <Text style={pdfStyles.label}>Blood type</Text>
                <Text>
                    {formatValue(selectedUserDetails?.bloodType)}
                </Text>
            </View>);
            itemView.push(<View style={pdfStyles.column3} key='vessel'>
                <Text style={pdfStyles.label}>Vessel / Facility Access</Text>
                <Text>
                    {vessels?.all?.map((vessel) => {
                        if (selectedUser?.vesselIds?.find((id: string) => id === vessel.id)) {
                            return vessel.name;
                        }
                        return undefined;
                    }).filter(Boolean).join(', ')}
                </Text>
            </View>);
        }
        itemView.push(<View style={pdfStyles.column3} key='status'>
            <Text style={pdfStyles.label}>Status</Text>
            <Text>
                {
                    selectedUser?.whenActivated ? `Activated ${formatDatetime(selectedUser?.whenActivated)}` : (
                        selectedUser?.whenWelcomed ? `Emailed ${formatDatetime(selectedUser?.whenWelcomed)}` : (
                            `Added ${formatDatetime(selectedUser?.whenAdded)}`
                        )
                    )
                }
            </Text>
        </View>);

        const RowItems = (array: any, size: number) => {
            const rowItems: any[][] = [];
            for (let i = 0; i < array.length; i += size) {
                rowItems.push(array.slice(i, i + size));
            }
            return rowItems;
        };

        const rowItems: any[][] = RowItems(itemView, 3);

        return (
            <Document
                title={reportTitle}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    {selectedUser && <>
                        <PdfItemsHeader
                            heading={reportTitle}
                            subHeading=''
                            userFullName={selectedUser?.firstName + ' ' + selectedUser?.lastName}
                        />

                        {rowItems.map((row, index) => (
                            <View key={'row-' + index} wrap={false} style={pdfStyles.row}>
                                {row.map((item, idx) => (
                                    <React.Fragment key={'v' + idx}>
                                        {item}
                                    </React.Fragment>
                                ))}
                            </View>
                        ))}
                    </>
                    }
                </Page>
            </Document>
        )
    }, [selectedUserDetails, reportTitle, selectedUser, userRoles, vessels?.all]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={reportTitle}
            MakePdf={MakePDF}
            requestedFrom="CrewParticularsDetail"
        />
    );
};
export default CrewParticularsDetailPdf;