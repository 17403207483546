import React from 'react';
import { useTimeZonesData } from '../../../../shared-state/General/useTimeZonesData';
import { IonSelectOption } from '@ionic/react';
import { sharedState } from '../../../../shared-state/shared-state';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import './TimeZoneSettingsTab.css';

interface TimeZoneSettingsTabProps {
    showModal: boolean,
    timeZone: string | undefined,
    setTimeZone: (timeZone: string) => void
}

const TimeZoneSettingsTab: React.FC<TimeZoneSettingsTabProps> = ({ showModal, timeZone, setTimeZone }) => {
    const timeZoneData = useTimeZonesData(showModal);
    const user = sharedState.user.use(showModal)!;

    return (<>
        <div style={{ paddingTop: '20px', maxWidth: '500px' }}>
            {timeZoneData?.options &&
                <SeaSelect
                    label="Licensee Time Zone"
                    //help={{text: timeZoneHelp}}
                    name="timeZone"
                    zone="white"
                    value={timeZone}
                    onchange={(e) => {
                        setTimeZone(e.detail.value);
                    }}
                    disabled={!user.isLicensee}
                >
                    <IonSelectOption value="">Not Set</IonSelectOption>
                    {timeZoneData?.options.map((option) => {
                        return (
                            <IonSelectOption
                                key={option.identifier}
                                value={option.identifier}
                            >
                                {option.name}
                            </IonSelectOption>
                        );
                    })}
                </SeaSelect>
            }
        </div>
        <div style={{ height: '20px' }}></div>
        <p>
            Note: All daily notifications and weekly reports will be sent at 00:00 relative to this time zone.
            {/* 'All dates across the app (that don\'t include a time) will all be considered to be in this time zone.' */}
        </p>
        {!user.isLicensee &&
            <p>
                Only the licensee can change this setting.
            </p>
        }
    </>);
};

export default TimeZoneSettingsTab;
