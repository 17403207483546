import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useState, useEffect } from "react";
import { SafetyCheckItem } from './safetyCheckItems';

export type SafetyCheckItemsData = {
    all: SafetyCheckItem[],
    byId: {
        [id: string]: SafetyCheckItem
    }
}


export const useVesselSafetyItemsForVessels = (shouldLoad?: boolean, vesselIds?: string[]): SafetyCheckItemsData | undefined => {
    const [safetyChecks, setSafetyChecks] = useState<SafetyCheckItemsData>();

    useEffect(() => {
        setSafetyChecks(undefined);
    
        if (shouldLoad && vesselIds?.length) {
            return onSnapshot(
                query(
                    collection(firestore, 'vesselSafetyItems'),
                    where('vesselId', 'in', vesselIds),
                    orderBy('name', 'asc'),
                ),
                (snap) => {
                    const items: SafetyCheckItem[] = [];
                    const byId: {
                        [id: string]: SafetyCheckItem
                    } = {};
                    snap.docs.forEach((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as SafetyCheckItem;
                        items.push(item);
                        byId[item.id] = item;
                    });
                    setSafetyChecks({
                        all: items,
                        byId,
                    });
                },
                (error) => {
                    console.log(`Failed to access SafetyCheckItems for vesselIds=${vesselIds}`, error.message, error, {
                        vesselIds,
                    });
                }
            );
        }
    }, [shouldLoad, vesselIds]);

    return safetyChecks
};