import React from 'react';
import SeaStatus, { SeaStatusOption } from '../SeaStatus/SeaStatus';
import { pdfStyles } from '../../lib/pdf';
import { View, Text } from '@react-pdf/renderer';
import { DateTimeAcceptable, getToday, makeDateTime } from '../../lib/datesAndTime';
import './SeaStatusDueDate.css';

interface SeaStatusDueDateProps {
    dateDue?: DateTimeAcceptable,
    warnDays?: number, // For how many days left should we then start warning?
    hasFault?: boolean,
    forPdf?: boolean,
    inline?: boolean,
    isMissing?: boolean
}

const SeaStatusDueDate: React.FC<SeaStatusDueDateProps> = ({
    dateDue,
    warnDays = 6,
    hasFault = false,
    forPdf = false,
    inline = false,
    isMissing = false
}) => {

    let status = 'ok';
    let text = '';
    const due = makeDateTime(dateDue); // Crunch day to match local timezone
    const days = Math.round(due.diff(makeDateTime(getToday()), ['days']).days);

    if (hasFault) {
        status = 'fail';
        text = 'FAULT';
    } else if (isMissing) {
        status = 'missing';
        text = ' MISSING';
    } else if (days === 0) {
        status = 'now';
        text = 'Due Today';
    } else if (days < 0) {
        status = 'fail';
        text = `${-days} day${(days < -1)?'s':''} OD`;
    } else if (days < warnDays) {
        status = 'warn';
        text = `${days} day${(days > 1)?'s':''}`;
    } else {
        status = 'ok';
        text = `${days} day${(days > 1)?'s':''}`;
    }

    if (forPdf) {
        //return <Text style={{textAlign: 'center'}}>{text}</Text>;
        // default colours for good status
        let bgColor = '#2dd36f';
        let fontColor = '#ffffff';
        if (status === 'fail') {
            bgColor = '#e9455a';
        } else if (status === 'missing') {
            bgColor = '#545666';  
        } else if (status === 'warn') {
            bgColor = '#f99e1b';
        } else if (status === 'now') {
            bgColor = '#f5f6f8';
            fontColor = '#0369d9';
        }
        return (
            <View style={{
                ...pdfStyles.status,
                backgroundColor: bgColor,
                color: fontColor
            }}>
                <Text>{text}</Text>
            </View>
        );
    }

    return (
        <SeaStatus status={status as SeaStatusOption} inline={inline}>
            {text}
        </SeaStatus>
    );
};

export default SeaStatusDueDate;
