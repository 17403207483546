import React, { useState } from 'react';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { formatTextAreaText, formatValue } from '../../../../lib/util';
import { formatDate, formatDateLonger, formatDatetime, formatEmailReminder } from '../../../../lib/datesAndTime';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { WriteBatch } from 'firebase/firestore';
import { SplittableBatch } from '../../../../lib/firebase';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { CorrectiveAction } from '../../../../shared-state/HealthSafety/correctiveActions';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import UpdateCorrectiveAction from '../UpdateCorrectiveAction/UpdateCorrectiveAction';
import EditCorrectiveAction from '../EditCorrectiveAction/EditCorrectiveAction';
import CompleteCorrectiveAction from '../CompleteCorrectiveAction/CompleteCorrectiveAction';
import CorrectiveActionPdf from '../../../../exports/HealthSafety/CorrectiveActions/CorrectiveActionPdf';

interface ViewCorrectiveActionProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    selectedItem?: CorrectiveAction;
    level?: number;
}

const ViewCorrectiveAction: React.FC<ViewCorrectiveActionProps> = ({ showModal, setShowModal, selectedItem, level = 1 }) => {
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showUpdateItemModal, setShowUpdateItemModal] = useState(false);
    const [showCompleteItemModal, setShowCompleteItemModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();

    const onEditItem = () => {
        setShowEditItemModal(true);
    };

    const onUpdateItem = () => {
        setShowUpdateItemModal(true);
    };

    const onCompleteCorrectiveAction = () => {
        setShowCompleteItemModal(true);
    };

    if (!selectedItem) {
        return <></>;
    }

    return (
        <SeaModal
            title={`${selectedItem?.state === 'completed' ? 'Completed ' : ''}Corrective Action`}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            onOpened={() => {
                console.log(`Viewing Corrective Action ${selectedItem?.id}`);
            }}
            onPdf={exportType === 'pdf' && <CorrectiveActionPdf onCompleted={() => setExportType(undefined)} selectedItem={selectedItem} />}
            setExportType={setExportType}
            level={level}
        >
            <div className="info-image">
                <SeaFileImage files={selectedItem?.files} size="medium" showOthers />
            </div>
            <SeaGrid>
                <SeaGridCell label="Title" w="75">
                    {formatValue(formatTextAreaText(selectedItem?.title))}
                </SeaGridCell>
                <SeaGridCell label="Action #" w="25">
                    {formatValue(selectedItem?.correctiveActionNum)}
                </SeaGridCell>
                <SeaGridCell label="Description" w="100">
                    {formatTextAreaText(selectedItem?.description)}
                    {selectedItem?.state !== 'completed' && (
                        <RequirePermissions role="correctiveActions" level={permissionLevels.EDIT}>
                            {selectedItem?.description && <div style={{ height: '10px' }}></div>}
                            <SeaButton onClick={(e) => onUpdateItem()}>Update Corrective Action</SeaButton>
                        </RequirePermissions>
                    )}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Assigned To" w="33">
                    {formatValue(renderFullNameForUserId(selectedItem?.assignedTo))}
                </SeaGridCell>
                <SeaGridCell label="Tags" w="33">
                    {formatValue(selectedItem?.tags?.join(', '))}
                </SeaGridCell>
                <SeaGridCell label="When Added" w="33">
                    {selectedItem?.whenAdded ? formatDatetime(selectedItem?.whenAdded, ', ') : '-'}
                </SeaGridCell>
                <SeaGridCell label="Email Reminder" w="33">
                    {formatValue(formatEmailReminder(selectedItem?.emailReminder))}
                </SeaGridCell>
                <SeaGridCell label="Added by" w="33">
                    {selectedItem?.addedBy ? formatValue(renderFullNameForUserId(selectedItem.addedBy)) : '-'}
                </SeaGridCell>
                <SeaGridCell label="Due Date" w="33">
                    {selectedItem?.dateDue ? formatDate(selectedItem?.dateDue) : '-'}
                </SeaGridCell>
            </SeaGrid>

            {selectedItem?.state === 'completed' && (
                <>
                    <div className="end-info-line"></div>
                    <h2 className="for-view-modal">Completed Details</h2>
                    <SeaGrid>
                        <SeaGridCell label="Completed by" w="33">
                            {selectedItem?.completedBy ? formatValue(renderFullNameForUserId(selectedItem.completedBy)) : '-'}
                        </SeaGridCell>
                        <SeaGridCell label="When Completed" w="33">
                            {selectedItem?.whenCompleted ? formatValue(formatDate(selectedItem.whenCompleted)) : '-'}
                        </SeaGridCell>
                        <SeaGridCell label="Completed Notes" w="100">
                            {selectedItem?.completedNotes ? formatValue(selectedItem.completedNotes) : '-'}
                        </SeaGridCell>
                        <SeaGridCell label="Completed Images / Documents" w="100">
                            <SeaFileImage files={selectedItem?.completedFiles} size="medium" showOthers />
                        </SeaGridCell>
                    </SeaGrid>
                    <SeaButton zone="white" type="submit" size="wide" onClick={(e) => onCompleteCorrectiveAction()}>
                        <SeaIcon icon="tick" slot="start" />
                        Update Completed Information
                    </SeaButton>
                    <div style={{ marginTop: '20px' }} className="end-info-line"></div>
                </>
            )}

            {selectedItem?.state === 'deleted' ? (
                <div>Corrective Action has been marked as {selectedItem?.state}</div>
            ) : (
                <div className="view-modal-buttons">
                    <RequirePermissions role="correctiveActions" level={permissionLevels.COMPLETE}>
                        <RequirePermissions role="correctiveActions" level={permissionLevels.EDIT}>
                            <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}>
                                <SeaIcon icon="edit" slot="start" />
                                Edit Corrective Action
                            </SeaButton>
                        </RequirePermissions>
                        <RequirePermissions role="correctiveActions" level={permissionLevels.FULL}>
                            <div className="spacer-wide"></div>
                            <div>
                                <SeaLinkButton
                                    mode="standard-link"
                                    onClick={(e) => {
                                        deleteIfConfirmed(
                                            'correctiveActions',
                                            selectedItem.id,
                                            (batch: WriteBatch | SplittableBatch) => {
                                                onCollectionUpdated(batch, 'correctiveActions');
                                                setShowModal(false);
                                            },
                                            'correctiveActions',
                                            selectedItem?.title,
                                            selectedItem.vesselIds
                                        );
                                    }}
                                >
                                    Delete Corrective Action
                                </SeaLinkButton>
                            </div>
                        </RequirePermissions>
                        {selectedItem?.state !== 'completed' && (
                            <div className="right">
                                <SeaButton zone="white" type="submit" size="wide" onClick={(e) => onCompleteCorrectiveAction()}>
                                    <SeaIcon icon="tick" slot="start" />
                                    Mark Corrective Action as Complete
                                </SeaButton>
                            </div>
                        )}
                    </RequirePermissions>
                </div>
            )}

            {showModal && (
                <>
                    <UpdateCorrectiveAction showModal={showUpdateItemModal} setShowModal={setShowUpdateItemModal} itemToUpdate={selectedItem} level={level + 1} />
                    <EditCorrectiveAction showModal={showEditItemModal} setShowModal={setShowEditItemModal} itemToUpdate={selectedItem} level={level + 1} />
                    <CompleteCorrectiveAction showModal={showCompleteItemModal} setShowModal={setShowCompleteItemModal} setShowParentModal={setShowModal} selectedItem={selectedItem} level={level + 1} />
                </>
            )}
        </SeaModal>
    );
};

export default ViewCorrectiveAction;
