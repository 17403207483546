import React, { useState, useMemo, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, functions, deleteValue, splittableBatch } from '../../../lib/firebase';
import { collection, doc, serverTimestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { regions, haveValuesChanged, maxSessionOptions, toInt } from '../../../lib/util';
import { defaultRiskRegisterLicenseeSettings } from './defaultLicenseeSettings';
import { weeklyReportOptions } from '../../Crew/CrewParticulars/EditUserModal/EditUserModal';
import { sharedState } from '../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../shared-state/DataSyncSystem/dataSync';
import { alertMessage } from '../../../managers/AlertManager/AlertManager';
import { reportError } from '../../../managers/ErrorsManager/ErrorsManager';
import { LicenseeUserType } from '../../../shared-state/Core/user';
import { UserDetails } from '../../../shared-state/Crew/userDetails';
import { LicenseeSettings } from '../../../shared-state/Core/licenseeSettings';
import { defaultUserPermissionDefaults } from '../../../shared-state/Crew/userPermissionDefaults';
import SeaModal from '../../../components/SeaModal/SeaModal';
import Yup from '../../../lib/yup';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaCheckbox from '../../../components/SeaCheckbox/SeaCheckbox';
import SeaFormError from '../../../components/SeaFormError/SeaFormError';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import { useTimeZonesData } from '../../../shared-state/General/useTimeZonesData';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';

interface EditLicenseeAccountModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    licenseeSettingsById: {
        [key: string]: LicenseeSettings;
    };
    licenseeToEdit?: LicenseeUserType;
    licenseeDetailsById?: {
        [key: string]: UserDetails;
    };
}

const EditLicenseeAccountModal: React.FC<EditLicenseeAccountModalProps> = ({ showModal, setShowModal, licenseeSettingsById, licenseeToEdit, licenseeDetailsById }) => {
    const user = sharedState.user.use();
    const userId = sharedState.userId.use();
    const superAdmin = sharedState.superAdmin.use();
    const [formError, setFormError] = useState('');
    const [loading, setLoading] = useState(false);
    const timeZoneData = useTimeZonesData();

    const initialValues = useMemo(() => {
        const licenseeDetails = licenseeDetailsById && licenseeToEdit && licenseeToEdit.id ? licenseeDetailsById[licenseeToEdit.id] : undefined;
        const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (licenseeToEdit && licenseeToEdit.id && licenseeDetails) {
            //console.log(`Editing licensee ${licenseeToEdit.id}`, licenseeToEdit);
            return {
                firstName: licenseeToEdit.firstName ? '' + licenseeToEdit.firstName : '',
                lastName: licenseeToEdit.lastName ? '' + licenseeToEdit.lastName : '',
                email: licenseeDetails.email ? '' + licenseeDetails.email : '',
                companyName: licenseeDetails.companyName ? '' + licenseeDetails.companyName : '',
                companyAddress: licenseeDetails.companyAddress ? '' + licenseeDetails.companyAddress : '',
                region: licenseeSettingsById[licenseeToEdit.id]?.region ? '' + licenseeSettingsById[licenseeToEdit.id]?.region : 'nz',
                timeZone: licenseeSettingsById[licenseeToEdit.id]?.timeZone ? '' + licenseeSettingsById[licenseeToEdit.id]?.timeZone : defaultTimeZone,
                hasOffline: licenseeSettingsById[licenseeToEdit.id]?.hasOffline ? true : false,
                hasMfaEmail: licenseeSettingsById[licenseeToEdit.id]?.hasMfaEmail ? true : false,
                maxSessionSeconds: licenseeSettingsById[licenseeToEdit.id]?.maxSessionSeconds ? '' + licenseeSettingsById[licenseeToEdit.id].maxSessionSeconds : '0',
                hasRiskRegisterV2: licenseeSettingsById[licenseeToEdit.id]?.riskRegister?.version === 2 ? true : false,
                hasReporting: licenseeSettingsById[licenseeToEdit.id]?.hasReporting,
                hasIncidents: licenseeSettingsById[licenseeToEdit.id]?.hasIncidents,
                hasSafetyCheckTaskTime: licenseeSettingsById[licenseeToEdit.id]?.hasSafetyCheckTaskTime,
                hasMaintenanceTaskTime: licenseeSettingsById[licenseeToEdit.id]?.hasMaintenanceTaskTime,
                hasCorrectiveActions: licenseeSettingsById[licenseeToEdit.id]?.hasCorrectiveActions,
            };
        } else {
            return {
                firstName: '',
                lastName: '',
                email: '',
                companyName: '',
                companyAddress: '',
                region: 'nz',
                timeZone: defaultTimeZone,
                hasOffline: false,
                hasMfaEmail: false,
                maxSessionSeconds: '0',
                hasRiskRegisterV2: true,
                hasReporting: true,
                hasIncidents: true,
                hasSafetyCheckTaskTime: true,
                hasMaintenanceTaskTime: true,
                hasCorrectiveActions: false,
            };
        }
    }, [licenseeDetailsById, licenseeSettingsById, licenseeToEdit]);

    const onOpened = () => {
        resetForm();
        setValues(initialValues);
    };

    const makeWeeklyReportValue = () => {
        const array = [] as string[];
        weeklyReportOptions.forEach((option) => {
            array.push(option.id);
        });
        return array;
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            region: Yup.string().max(10).required(),
            timeZone: Yup.string().required(),
            firstName: Yup.string().max(500).required(),
            lastName: Yup.string().max(500).required(),
            email: Yup.string().min(3).max(500).email().required(),
            companyName: Yup.string().max(500),
            companyAddress: Yup.string().max(500),
        }),
        onSubmit: (data) => {
            // Checking for duplicate email address
            setLoading(true);
            return Promise.resolve()
                .then(() => {
                    if (licenseeToEdit) {
                        return Promise.resolve(); // Don't need to check for email being available
                    }
                    return httpsCallable(
                        functions,
                        'isEmailAvailable'
                    )({
                        email: data.email.trim().toLowerCase(),
                    }).then((result: any) => {
                        console.log('isEmailAvailable result', result);
                        if (result.data.result === 'duplicateEmail') {
                            return Promise.reject({ code: 'duplicateEmail' });
                        }
                        return Promise.resolve();
                    });
                })
                .then(() => {
                    // Process form
                    setFormError('');
                    const batch = splittableBatch(firestore, 20 - 0);

                    let riskRegisterLicenseeSettings = {
                        version: data.hasRiskRegisterV2 ? 2 : 1,
                    };
                    if (data.hasRiskRegisterV2 && !(licenseeSettingsById && licenseeToEdit && licenseeSettingsById[licenseeToEdit.id]?.riskRegister?.matrix)) {
                        riskRegisterLicenseeSettings = defaultRiskRegisterLicenseeSettings;
                    }

                    if (licenseeToEdit) {
                        // Edit existing licensee
                        batch.set(
                            doc(firestore, 'users', licenseeToEdit.id),
                            {
                                firstName: data.firstName,
                                lastName: data.lastName,
                                whenUpdated: Date.now(),
                                updatedBy: user ? userId : superAdmin?.id,
                            },
                            { merge: true }
                        );

                        batch.set(
                            doc(firestore, 'userDetails', licenseeToEdit.id),
                            {
                                companyName: data.companyName ? data.companyName : deleteValue,
                                companyAddress: data.companyAddress ? data.companyAddress : deleteValue,
                                touched: serverTimestamp(),
                            },
                            { merge: true }
                        );

                        batch.set(
                            doc(firestore, 'licenseeSettings', licenseeToEdit.id),
                            {
                                region: data.region ? data.region : 'nz',
                                timeZone: data.timeZone ? data.timeZone : undefined,
                                hasOffline: data.hasOffline ? true : false,
                                hasMfaEmail: data.hasMfaEmail ? true : false,
                                maxSessionSeconds: toInt(data.maxSessionSeconds, 0),
                                riskRegister: riskRegisterLicenseeSettings,
                                hasReporting: data.hasReporting ? true : false,
                                hasIncidents: data.hasIncidents ? true : false,
                                hasSafetyCheckTaskTime: data.hasSafetyCheckTaskTime ? true : false,
                                hasMaintenanceTaskTime: data.hasMaintenanceTaskTime ? true : false,
                                hasCorrectiveActions: data.hasCorrectiveActions ? true : false,
                            },
                            { merge: true }
                        );

                        batch.set(
                            doc(firestore, 'userPermissions', licenseeToEdit.id),
                            {
                                correctiveActions: data.hasCorrectiveActions ? permissionLevels.FULL : permissionLevels.NONE,
                            },
                            { merge: true }
                        );
                    } else {
                        // New licensee
                        const newUserRef = doc(collection(firestore, 'users'));
                        batch.set(newUserRef, {
                            licenseeId: newUserRef.id,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            whenAdded: Date.now(),
                            addedBy: user ? userId : superAdmin?.id,
                            isLicensee: true,
                            isStaff: true,
                            state: 'active',
                        });

                        batch.set(doc(firestore, 'userDetails', newUserRef.id), {
                            licenseeId: newUserRef.id,
                            email: data.email.trim().toLowerCase(),
                            companyName: data.companyName ? data.companyName : undefined,
                            companyAddress: data.companyAddress ? data.companyAddress : undefined,
                            weeklyReport: makeWeeklyReportValue(),
                            touched: serverTimestamp(),
                            state: 'active',
                        });

                        batch.set(doc(firestore, 'licenseeSettings', newUserRef.id), {
                            region: data.region ? data.region : 'nz',
                            timeZone: data.timeZone ? data.timeZone : undefined,
                            hasOffline: data.hasOffline ? true : false,
                            hasMfaEmail: data.hasMfaEmail ? true : false,
                            maxSessionSeconds: toInt(data.maxSessionSeconds, 0),
                            riskRegister: riskRegisterLicenseeSettings,
                            hasReporting: data.hasReporting ? true : false,
                            hasIncidents: data.hasIncidents ? true : false,
                            hasSafetyCheckTaskTime: data.hasSafetyCheckTaskTime ? true : false,
                            hasMaintenanceTaskTime: data.hasMaintenanceTaskTime ? true : false,
                            hasCorrectiveActions: data.hasCorrectiveActions ? true : false,
                        });

                        // Also setup userPermissions
                        const defaultLicenseePermissions = defaultUserPermissionDefaults.licensee as any;
                        delete defaultLicenseePermissions.name;
                        batch.set(doc(firestore, 'userPermissions', newUserRef.id), {
                            ...defaultLicenseePermissions,
                            correctiveActions: data.hasCorrectiveActions ? permissionLevels.FULL : permissionLevels.NONE,
                            whenUpdated: Date.now(),
                            updatedBy: user ? userId : superAdmin?.id,
                        });
                    }

                    onCollectionUpdated(batch, 'userDetails');
                    onCollectionUpdated(batch, 'users');
                    onCollectionUpdated(batch, 'userPermissions');

                    batch
                        .commit()
                        .then(() => {
                            // This wont make a difference, as we already processed using local cache
                            // We could show a toast though
                            console.log('saved licensee');
                        })
                        .catch((error) => {
                            console.log('error! error.code', error.code);
                            reportError(`Failed to create licensee ${data.firstName}`, error.message, error, {
                                data,
                                licenseeToEdit,
                            });
                        });
                    setShowModal(false);
                })
                .catch((error: any) => {
                    if (error?.code === 'duplicateEmail') {
                        alertMessage('Email address is already in use by an existing Sea Flux user.<br/><br/>Please try a different email address.');
                        console.log('email already in use');
                    } else {
                        reportError(`Failed to check duplicate email address from users collection `, error.message, error, {
                            data,
                            licenseeToEdit,
                        });
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        },
    });

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [initialValues, values]);

    return (
        <SeaModal title={licenseeToEdit ? 'Edit Licensee Account' : 'Create New Licensee Account'} showModal={showModal} setShowModal={setShowModal} isDirty={isModalDirty} onOpened={onOpened}>
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                            <SeaInput
                                label="First name"
                                name="firstName"
                                value={values.firstName}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.firstName ? errors.firstName : ''}
                                disabled={loading}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                label="Last name"
                                name="lastName"
                                value={values.lastName}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.lastName ? errors.lastName : ''}
                                disabled={loading}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                label="Email"
                                name="email"
                                value={values.email}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="email"
                                inputmode="email"
                                error={touched.email ? errors.email : ''}
                                disabled={loading || licenseeToEdit ? true : false}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                label="Company name"
                                name="companyName"
                                value={values.companyName}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.companyName ? errors.companyName : ''}
                                disabled={loading}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                label="Company Address"
                                name="companyAddress"
                                value={values.companyAddress}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.companyAddress ? errors.companyAddress : ''}
                                disabled={loading}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect label="Region" name="region" value={values.region} onchange={handleChange} onblur={handleBlur} error={touched.region ? errors.region : ''}>
                                {Object.keys(regions).map((region) => {
                                    return (
                                        <IonSelectOption key={region} value={region}>
                                            {regions[region].name}
                                        </IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect label="Time Zone" name="timeZone" value={values.timeZone} onchange={handleChange} onblur={handleBlur} error={touched.timeZone ? errors.timeZone : ''}>
                                {timeZoneData?.options?.map((option) => {
                                    return (
                                        <IonSelectOption key={option.identifier} value={option.identifier}>
                                            {option.name}
                                        </IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6">
                            <SeaCheckbox name="hasOffline" checked={values.hasOffline} setFieldValue={setFieldValue} error={touched.hasOffline ? errors.hasOffline : ''} disabled={loading}>
                                Enable offline
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="6">
                            <SeaCheckbox name="hasMfaEmail" checked={values.hasMfaEmail} setFieldValue={setFieldValue} error={touched.hasMfaEmail ? errors.hasMfaEmail : ''} disabled={loading}>
                                Enable Multi Factor Authentication (Email)
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                name="maxSessionSeconds"
                                label="Maximum Session Time"
                                help={{ text: 'This is the amount of time a user is allowed to remain logged in for before being required to login again.' }}
                                zone="white"
                                value={values.maxSessionSeconds}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.maxSessionSeconds ? errors.maxSessionSeconds : ''}
                            >
                                {maxSessionOptions.map((option) => {
                                    return (
                                        <IonSelectOption key={option.value} value={option.value}>
                                            {option.description}
                                        </IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6"></IonCol>
                        <IonCol size="6">
                            <SeaCheckbox name="hasRiskRegisterV2" checked={values.hasRiskRegisterV2} setFieldValue={setFieldValue} error={touched.hasRiskRegisterV2 ? errors.hasRiskRegisterV2 : ''} disabled={loading}>
                                Enable Risk Assessments v2
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="6">
                            <SeaCheckbox name="hasIncidents" checked={values.hasIncidents} setFieldValue={setFieldValue} error={touched.hasIncidents ? errors.hasIncidents : ''} disabled={loading}>
                                Enable Incidents
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="6">
                            <SeaCheckbox name="hasReporting" checked={values.hasReporting} setFieldValue={setFieldValue} error={touched.hasReporting ? errors.hasReporting : ''} disabled={loading}>
                                Enable Reporting
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="6">
                            <SeaCheckbox name="hasSafetyCheckTaskTime" checked={values.hasSafetyCheckTaskTime} setFieldValue={setFieldValue} error={touched.hasSafetyCheckTaskTime ? errors.hasSafetyCheckTaskTime : ''} disabled={loading}>
                                Record Safety Check Task Times
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="6">
                            <SeaCheckbox name="hasMaintenanceTaskTime" checked={values.hasMaintenanceTaskTime} setFieldValue={setFieldValue} error={touched.hasMaintenanceTaskTime ? errors.hasMaintenanceTaskTime : ''} disabled={loading}>
                                Record Maintenance Task Times
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="6">
                            <SeaCheckbox name="hasCorrectiveActions" checked={values.hasCorrectiveActions} setFieldValue={setFieldValue} error={touched.hasCorrectiveActions ? errors.hasCorrectiveActions : ''} disabled={loading}>
                                Enable Corrective Actions
                            </SeaCheckbox>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{ height: '60px' }}></div>
                <SeaFormError message={formError} />
                <SeaButton zone="white" type="submit" disabled={loading}>
                    {licenseeToEdit ? 'Update Account' : 'Create New Account'}
                </SeaButton>
            </form>
        </SeaModal>
    );
};

export default EditLicenseeAccountModal;
