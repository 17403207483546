import React, { useEffect, useState } from 'react';
import { formatValue, taskStatuses } from '../../../../lib/util';
import { formatDateShort, warnDays, dateDueToClassName } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { IonSelectOption } from '@ionic/react';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { SafetyEquipmentItem } from '../../../../shared-state/VesselSafety/safetyEquipmentItems';
import { ReportingSafetyItemById } from '../../../../shared-state/Reporting/SafetyReporting/useReportingSafetyItemsById';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import SeaHorizontalStackedBarGraph from '../../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import reporting from '../../../../lib/reporting';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaStatusIcon from '../../../../components/SeaStatusIcon/SeaStatusIcon';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SafetyEquipmentStatusReportPDF from '../../../../exports/Reporting/Safety/SafetyEquipmentStatusReportPdf';
import './ViewSafetyEquipmentStatusReport.css';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';

const safetyEquipmentStatusOptions = Object.keys(taskStatuses).map((statusId: string) => {
    return {
        id: statusId,
        name: taskStatuses[statusId]
    };
});

interface ViewSafetyEquipmentStatusReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredSafetyEquipment?: SafetyEquipmentItem[],
    categories?: (string | undefined)[],
    selectedSafetyEquipmentStatuses: string[],
    setSelectedSafetyEquipmentStatuses: React.Dispatch<React.SetStateAction<string[]>>,
    safetyCriticality: string,
    setSafetyCriticality: React.Dispatch<React.SetStateAction<string>>,
    vesselSafetyItemsById?: ReportingSafetyItemById,
    vesselName?: string,
}

const ViewSafetyEquipmentStatusReport: React.FC<ViewSafetyEquipmentStatusReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredSafetyEquipment,
    categories,
    selectedSafetyEquipmentStatuses,
    setSelectedSafetyEquipmentStatuses,
    safetyCriticality,
    setSafetyCriticality,
    vesselSafetyItemsById,
    vesselName,
}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const { selectedVesselId } = useReportSettings();
    const [exportType, setExportType] = useState<ExportType>();
    const [labelWidthsForPdf, setLabelWidthsForPdf] = useState<number[]>([]);

    useEffect(() => {
        if (showModal) {
            logModalView('SafetyEquipmentStatusReport', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={filteredSafetyEquipment && exportType === 'pdf' &&
                <SafetyEquipmentStatusReportPDF
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredItems={filteredSafetyEquipment}
                    vesselSafetyItemsById={vesselSafetyItemsById}
                    categories={categories}
                    labelWidthsForPdf={labelWidthsForPdf}
                />
            }
            setExportType={setExportType}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={false}
                extraComponents={[
                    <SeaMultiSelect
                        label="Safety Equipment Statuses"
                        values={selectedSafetyEquipmentStatuses}
                        setValues={setSelectedSafetyEquipmentStatuses}
                        options={safetyEquipmentStatusOptions}
                        useAllOption={true}
                    />,
                    <SeaSelect
                        label="Critical?"
                        value={safetyCriticality}
                        onchange={(e) => {
                            setSafetyCriticality(e.detail.value);
                        }}
                    >
                        <IonSelectOption value="both">Critical & Non-Critical</IonSelectOption>
                        <IonSelectOption value="critical">Critical Only</IonSelectOption>
                        <IonSelectOption value="nonCritical">Non-Critical Only</IonSelectOption>
                    </SeaSelect>
                ]}
            />

            <SeaHorizontalStackedBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                categories={categories}
                setLabelWidthsForPdf={setLabelWidthsForPdf}
                colourPalette={reporting.colours.taskStatuses}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="safety equipment expiries"
                    isLoading={!filteredSafetyEquipment}
                    hasNoData={filteredSafetyEquipment?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`safety-equipment-items has-thumbs has-status ${(filteredSafetyEquipment && filteredSafetyEquipment.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div></div>
                        <div>Safety Item</div>
                        <div>Service / Expiry</div>
                        <div>Vessel</div>
                        <div>Status</div>
                        <div>Critical</div>
                    </div>
                    {mapArrayWithLimit(filteredSafetyEquipment, (item) => {
                        return (
                            <div
                                key={item.id}
                                className={`sea-card sea-row-history ${dateDueToClassName(item.dateDue, warnDays.safetyEquipmentChecks[0])}`}
                            >
                                <div>
                                    <SeaFileImage files={item.files} size="tiny"/>
                                </div>
                                <div className="bold truncate-2">
                                    {formatValue(vesselSafetyItemsById?.[item.itemId]?.name)}
                                </div>
                                <div>{formatDateShort(item.dateDue)}</div>
                                <div className="truncate-2">
                                    {renderVesselName(item.vesselId)}
                                </div>
                                <div>
                                    <SeaStatusDueDate dateDue={item.dateDue} warnDays={warnDays.safetyEquipmentChecks[0]}/>
                                </div>
                                <div>
                                    {vesselSafetyItemsById?.[item.itemId]?.isCritical && (
                                        <SeaStatusIcon
                                            icon="flag"
                                            className="critical bottom"
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
               
            </div>
        </SeaModal>
    );
};

export default ViewSafetyEquipmentStatusReport;
