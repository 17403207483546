import { collection, DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import { firestore, setupArrayQueryListener } from '../../lib/firebase';
import { CreateableDocument, sharedState, SharedStateConfig, UpdateableDocument } from '../shared-state';

export interface Drill extends CreateableDocument, UpdateableDocument {
    deletedBy?: string;
    interval: string;
    name: string;
    state: string;
    vesselId: string;
    whenDeleted?: number;
    /* @deprecated - Use `crew[crewId].dateDue` instead*/
    dateDue?: string;
    /* @deprecated - Use `crew[crewId].whenLastCompleted` instead*/
    dateLastCompleted?: string;
    crew?: DrillCrewData;
    notAssignedTo?: string[];
}

export interface DrillCrewData {
    [crewId: string]: {
        dateDue: string;
        dateLastCompleted: string;
    };
}

export type DrillsData = {
    byId: {
        [id: string]: Drill;
    };
};

export const drillsConfig: SharedStateConfig<DrillsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselIds'],
    countLiveDocs: () => Object.keys(sharedState.drills.current?.byId ?? {}).length,
    run: (done, set, clear) => {
        clear();
        const vesselIds = sharedState.vesselIds.current;
        if (vesselIds) {
            return setupArrayQueryListener(
                'drills', // what
                collection(firestore, 'drills'),
                [], // baseConstraints
                'vesselId',
                'in',
                vesselIds,
                [],
                (docs: QueryDocumentSnapshot<DocumentData>[], isCombined: boolean) => {
                    // processDocs
                    done();
                    const byId = {} as {
                        [drillId: string]: Drill;
                    };
                    docs.forEach((doc) => {
                        const drill = {
                            id: doc.id,
                            ...doc.data(),
                        } as Drill;
                        byId[drill.id] = drill;
                    });

                    set({
                        byId,
                    });
                },
                () => {
                    // onError
                    done();
                }
            );
        }
    },
};
