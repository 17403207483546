import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from 'react';
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { CustomFormCompleted } from "../CompanyDocuments/CustomForms/useCustomFormsCompleted";
import { sharedState } from "../shared-state";


export type TrainingTaskReportCustomFormsCompletedData = {
    byId: { [id: string]: CustomFormCompleted },
    byTaskId: { [id: string]: CustomFormCompleted[] },
    byReportId: { [id: string]: CustomFormCompleted[] },
    all: CustomFormCompleted[]
};

export const useTrainingTaskReportCustomFormsCompleted = (taskId?: string, reportId?: string, vesselId?: string, personnelId?: string) => {
    const [trainingTaskReportCustomFormsCompleted, setTrainingTaskReportCustomFormsCompleted] = useState<TrainingTaskReportCustomFormsCompletedData>();
    const licenseeId = sharedState.licenseeId.current;
    
    useEffect(() => {
        setTrainingTaskReportCustomFormsCompleted(undefined);
        if (taskId && vesselId && licenseeId) {
            const collectionRef = collection(firestore, 'customFormsCompleted');
            const queryConstraints = [
                where('licenseeId', '==', licenseeId),
                where('attachToVesselId', '==', vesselId),
                where('state', '==', 'active'),
                where('attachTo', '==', 'trainingTaskReport'),
                where('attachToTrainingTaskId', '==', taskId),
                orderBy('whenAdded', 'desc')
            ];

            if (reportId) {
                queryConstraints.push(where('attachToId', '==', reportId));
            }
            if (personnelId) {
                queryConstraints.push(where('personnelIds', 'array-contains', personnelId));
            }

            const q = query(collectionRef, ...queryConstraints);
            
            return onSnapshot(
                q,
                (snap) => {
                    const byId = {} as {
                        [id: string]: CustomFormCompleted
                    };
                    const byReportId = {} as {
                        [id: string]: CustomFormCompleted[]
                    };
                    const byTaskId = {} as {
                        [id: string]: CustomFormCompleted[]
                    };
                    const all = snap.docs.map((doc) => {
                        const o = {
                            id: doc.id,
                            ...doc.data()
                        } as CustomFormCompleted;
                        byId[o.id] = o;
                        if (o.attachToId) {
                            if (byReportId[o.attachToId] === undefined) {
                                byReportId[o.attachToId] = [];
                            }
                            byReportId[o.attachToId].push(o);
                        }
                        if (o.attachToTrainingTaskId) {
                            if (byTaskId[o.attachToTrainingTaskId] === undefined) {
                                byTaskId[o.attachToTrainingTaskId] = [];
                            }
                            byTaskId[o.attachToTrainingTaskId].push(o);
                        }
                        registerFiles(o.files, 'customFormsCompleted', o);
                        return o;
                    });
                    setTrainingTaskReportCustomFormsCompleted({
                        byId,
                        byReportId,
                        byTaskId,
                        all
                    });
    
                }, (error) => {
                    // This should be very rare
                    console.log(`Failed to access trainingTaskReport custom forms for trainingTask ${taskId} with reportId ${reportId} with personnelId ${personnelId} `, error);
                }
            );
        }
    }, [personnelId, vesselId, licenseeId, taskId, reportId]);

    return trainingTaskReportCustomFormsCompleted;
};
