import React, { ReactNode, useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { disableSwipe, enableSwipe } from '../../shared-state/General/swipeGestures';
import { PermissionRole, canView } from '../../shared-state/Core/userPermissions';
import { easeQuadInOut } from '../../lib/util';
import { isPlatform } from '@ionic/core';
import { sharedState } from '../../shared-state/shared-state';
import QueryString from 'query-string';
import SeaTab from '../SeaTab/SeaTab';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaTabsGroup.css';

let currentAnimId = 0;
const animateScrollLeft = (element: any, scrollLeftFrom: number, scrollLeftTo: number) => {
    if (scrollLeftFrom === scrollLeftTo) {
        return;
    }
    let start = 0;
    const animId = ++currentAnimId;
    const animLength = 300;
    function step(now: number) {
        if (animId !== currentAnimId) {
            return;
        }
        if (start === 0) {
            start = now;
        }
        const elapsed = now - start;
        const r = Math.min(elapsed / animLength, 1);
        element.scrollLeft = easeQuadInOut(r) * (scrollLeftTo - scrollLeftFrom) + scrollLeftFrom;
        if (r < 1) {
            window.requestAnimationFrame(step);
        }
    }
    window.requestAnimationFrame(step);
};

interface SeaTabsGroupProps {
    id?: string,
    children: ReactNode,
    selectedTab?: string,
    setTab: (tab: string) => void,
    mode?: 'page' | 'page-never-hide' | 'forms',
    mini?: boolean,
    micro?: boolean,
    allowFades?: boolean,
    fullWidth?: boolean,
    showPlus?: boolean,
    plusText?: string,
    disablePlus?: boolean,
    hasErrors?: boolean,
    onAddTab?: (e: React.MouseEvent<Element, MouseEvent>) => void,
    onAddTabFailed?: (e: React.MouseEvent<Element, MouseEvent>) => void,
    alwaysShowAllTabs?: boolean,
    draggable?: boolean,
    onDragEnd?: (startIndex: number, endIndex: number) => void
}

const SeaTabsGroup: React.FC<SeaTabsGroupProps> = ({
    id,
    children,
    selectedTab,
    setTab,
    mode = 'page',
    mini = false,
    micro = false,
    allowFades = true,
    fullWidth = false,
    showPlus = false,
    plusText,
    disablePlus = false,
    hasErrors = false,
    onAddTab,
    onAddTabFailed,
    alwaysShowAllTabs,
    draggable = false,
    onDragEnd
}) => {
    const isMounted = useRef(false);
    const location = useLocation();
    const navigate = useNavigate();
    const superAdmin = sharedState.superAdmin.use();
    const userPermissions = sharedState.userPermissions.use();
    const [tabViewed, setTabViewed] = useState<string>();
    const [fadeLeft, setFadeLeft] = useState(true);
    const [fadeRight, setFadeRight] = useState(true);
    const hasInitialised = useRef(false);
    const tabGroupRef = useRef(null);
    const [tabs, setTabs] = useState<{ tab: string, requireRole?: PermissionRole }[]>([]);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleDragStart = (index: number) => (event: React.DragEvent<HTMLDivElement>) => {
        if (!draggable) {
            event.stopPropagation();
            return;
        }
        event.dataTransfer.setData('text/plain', index.toString());

        // Add dragging class to prevent button activation
        const button = event.currentTarget.querySelector('ion-button');
        if (button) {
            button.classList.add('dragging');
        }

        // Remove ion-selected attribute from all buttons to prevent multiple tabs appearing as selected
        const tabElements = document.querySelectorAll('.sea-draggable-tab');
        tabElements.forEach((tabElement) => {
            const button = tabElement.querySelector('ion-button');
            if (button && button.classList.contains('ion-activated')) {
                button.classList.remove('ion-activated');
            }
        });
    };


    const handleDragOver = (index: number) => (event: React.DragEvent<HTMLDivElement>) => {
        if (!draggable) {
            return;
        }
        event.preventDefault();
        const element = event.currentTarget;
        const data = event.dataTransfer.getData('text/plain');
        const newIndex = parseInt(data, 10);
        if (newIndex !== index && element !== event.target) {
            element.classList.add('drag-over');
            element.addEventListener('dragleave', () => {
                element.classList.remove('drag-over');
            }, { once: true });
        }
    };

    const handleDrop = (index: number) => (event: React.DragEvent<HTMLDivElement>) => {
        if (!draggable) {
            return;
        }
        event.preventDefault();
        const element = event.currentTarget;
        element.classList.remove('drag-over');
        const data = event.dataTransfer.getData('text/plain');
        const newIndex = parseInt(data, 10);
        if (newIndex !== index) {
            const _tabs = [...tabs];
            const tab = _tabs.splice(newIndex, 1)[0];
            _tabs.splice(index, 0, tab);
            setTabs(_tabs);
            if (onDragEnd) {
                onDragEnd(newIndex, index);
            }
        }

        // Remove dragging class after drop
        const button = event.currentTarget.querySelector('ion-button');
        if (button) {
            button.classList.remove('dragging');
        }

        setTimeout(() => {
            // Remove ion-selected attribute from all buttons to prevent multiple tabs appearing as selected
            const tabElements = document.querySelectorAll('.sea-draggable-tab');
            tabElements.forEach((tabElement) => {
                const button = tabElement.querySelector('ion-button');
                if (button && button.classList.contains('ion-activated')) {
                    button.classList.remove('ion-activated');
                }
            });
        }, 20)
    };


    const onSetTab = useCallback((tab: string) => {
        // Update Url to use tab in query string
        if (mode !== 'forms') {
            const qs = QueryString.parse(location.search);
            qs.tab = tab;
            //history.replace(`${history.location.pathname}?${QueryString.stringify(location)}`);
            navigate(`${location.pathname}?${QueryString.stringify(qs)}`, { replace: true });
        }
        setTab(tab);
    }, [setTab, location, mode, navigate]);

    useEffect(() => {
        const _tabs = [] as { tab: string, requireRole?: PermissionRole }[];
        React.Children.forEach(children, (child: any) => {
            if (child?.props?.tab) {
                _tabs.push({
                    tab: child.props.tab,
                    requireRole: child.props.requireRole
                });
            }
        });
        setTabs((current) => {
            if (current?.length !== _tabs?.length) {
                return _tabs;
            }
            for (let i = 0; i < _tabs.length; i++) {
                if (current[i].tab !== _tabs[i].tab || current[i].requireRole !== _tabs[i].requireRole) {
                    return _tabs;
                }
            }
            return current; // no changes
        });
    }, [children]);

    // Create an object that stores whether we have permission to view each tab
    const canViewTabs = useMemo(() => {
        if (userPermissions === undefined && !superAdmin) {
            return undefined;
        }
        const o = {} as any;
        tabs.forEach((item) => {
            if (item.requireRole) {
                o[item.tab] = canView(item.requireRole);
            } else {
                o[item.tab] = true;
            }
        });
        return o;
    }, [superAdmin, tabs, userPermissions]);

    useEffect(() => {
        if (userPermissions === undefined || !(tabs?.length > 0)) {
            return;
        }

        if (hasInitialised.current) {
            // We have already initialised tab, but what if the user has clicked back/forward in the browser?
            // If the Query String has changed, let's change tab
            if (location?.search && mode !== 'forms') { // Process query string
                const qs = QueryString.parse(location.search);
                if (qs.tab) {
                    if (qs.tab !== selectedTab) {
                        let isFound = false;
                        tabs?.forEach((tab) => {
                            if (tab.tab === qs.tab) {
                                isFound = true;
                            }
                        });
                        if (isFound) {
                            setTab('' + qs.tab);
                            setTabViewed('' + qs.tab);
                        }
                    }
                    return;
                }
            }
            return;
        }

        // Try and set tab based on URL first
        if (location?.search && mode !== 'forms') { // Process query string
            const qs = QueryString.parse(location.search);
            if (qs.tab) {
                setTab('' + qs.tab);
                setTabViewed('' + qs.tab);
                hasInitialised.current = true;
                return;
            }
        }
        // Otherwise, use selectedTab if it exists and is within this tabgroup
        if (selectedTab) {
            // See if we have that tab...
            setTabViewed(selectedTab);
            for (let i = 0; i < tabs.length; i++) {
                if (
                    tabs[i].tab === selectedTab && (
                        tabs[i].requireRole === undefined ||
                        canView(tabs[i].requireRole as PermissionRole)
                    )
                ) {
                    setTab(tabs[i].tab);
                    setTabViewed(tabs[i].tab);
                    hasInitialised.current = true;
                    return;
                }
            }
        }
        // Otherwise, default to the first tab
        for (let i = 0; i < tabs.length; i++) {
            if (
                tabs[i].requireRole === undefined ||
                canView(tabs[i].requireRole as PermissionRole)
            ) {
                setTab(tabs[i].tab);
                setTabViewed(tabs[i].tab);
                hasInitialised.current = true;
                return;
            }
        }
        // No tab found to default to!
        //hasInitialised.current = true;
    }, [tabs, location?.search, mode, selectedTab, setTab, userPermissions, id]);


    const scrollToSelectedTab = useCallback((selectedTab: string, delay = 0) => {
        if (delay > 0) {
            setTimeout(() => {
                if (!isMounted.current) return;
                scrollToSelectedTab(selectedTab, 0);
            }, delay);
            return;
        }

        if (!tabGroupRef.current) {
            return;
        }
        let tabIndex = 0;
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].tab === selectedTab) {
                tabIndex = i;
                break;
            }
        }

        let tabWidth = (window.innerWidth > 850) ? (162 + 4) : (150 + 4);
        const groupWidth = (tabGroupRef.current as any).offsetWidth;
        const scrollWidth = (tabGroupRef.current as any).scrollWidth;
        let x = (tabWidth * tabIndex) - ((groupWidth - tabWidth) / 2);
        if (showPlus) {
            x -= 40;
        }
        if (window.innerWidth > 850) {
            x += 32;
        } else if (window.innerWidth > 450) {
            x += 16;
        } else {
            x += 12;
        }
        if (allowFades) {
            setFadeLeft(x > 0);
            setFadeRight(x + groupWidth < scrollWidth);
        } else {
            setFadeLeft(false);
            setFadeRight(false);
        }
        if (mode === 'forms' && tabGroupRef.current && groupWidth === 0) {
            scrollToSelectedTab(selectedTab, 100);
        } else {
            // if (isPlatform('ios') && isPlatform('hybrid')) {
            //     animateScrollLeft(
            //         (tabGroupRef.current as any),
            //         (tabGroupRef.current as any).scrollLeft,
            //         x
            //     );
            // } else {
                (tabGroupRef.current as any).scrollLeft = x;
            // }
        }
    }, [allowFades, tabs, mode, showPlus]);

    useEffect(() => {
        if (selectedTab && tabGroupRef.current) {
            scrollToSelectedTab(selectedTab);
            setTabViewed(selectedTab);
        }
    }, [selectedTab, scrollToSelectedTab]);

    useEffect(() => {
        if (tabViewed && tabGroupRef.current) {
            scrollToSelectedTab(tabViewed);
        }
    }, [tabViewed, scrollToSelectedTab]);


    if (canViewTabs === undefined) {
        return <></>;
    }
    
    if (fullWidth) {
        return (
            <div className={`sea-tabs-container full-width`}>
                <div
                    ref={tabGroupRef}
                    className="sea-tabs-group-parent"
                >
                    {
                        children && React.Children.map(children, (child: any, index: number) => {
                            if (canViewTabs[child.props.tab] && !child.props.hide) {
                                return ( 
                                    <div
                                        key={child.props.tab}
                                        className={`${(selectedTab === child.props.tab) ? 'tab-selected' : ''}`}
                                        style={{
                                            flex: '1 1 0',
                                        }}
                                    >
                                        {draggable ? (
                                            <div
                                                onDragStart={handleDragStart(index)}
                                                onDrop={handleDrop(index)}
                                                onDragOver={handleDragOver(index)}
                                                draggable={draggable}
                                                className='sea-draggable-tab'
                                            >
                                                <SeaTab {...child.props} setTab={onSetTab} draggable={draggable} hasErrors={child.props.hasErrors || hasErrors} disabled={child.props.disabled}>
                                                        {child.props.children}
                                                </SeaTab>
                                            </div>
                                        ) : (
                                            <SeaTab {...child.props} setTab={onSetTab} draggable={false} hasErrors={child.props.hasErrors || hasErrors} disabled={child.props.disabled}>
                                                {child.props.children}
                                            </SeaTab>
                                        )}
                                    </div>
                                );
                            } else {
                                return <></>;
                            }
                        })
                    }
                </div>
            </div>
        );
    }

    return (
        <div className={`sea-tabs-container${ mini ? ' mini' : ''}${ micro ? ' micro' : '' }`}>
            <div className={`sea-tabs-fade left ${fadeLeft ? 'on' : 'off'}`}></div>
            <div
                ref={tabGroupRef}
                className={`sea-tabs-group ${mode ? mode : ''}`}
                // onTouchMove={(e) => {
                //     disableSwipe();
                // }}
                // onTouchEnd={(e) => {
                //     enableSwipe(500);
                // }}
            >
                {
                    children && React.Children.map(children, (child: any, index: number) => {
                        if (
                            child?.props?.tab &&
                            (alwaysShowAllTabs || (canViewTabs[child.props.tab] && !child.props.hide))
                        ) {
                            return (
                                <div
                                    key={child.props.tab}
                                    className={`tab-container ${ (selectedTab === child.props.tab) ? 'tab-selected' : '' }`}
                                >
                                    {draggable ? (
                                        <div
                                            onDragStart={handleDragStart(index)}
                                            onDrop={handleDrop(index)}
                                            onDragOver={handleDragOver(index)}
                                            draggable={draggable}
                                            className='sea-draggable-tab'
                                        >
                                            <SeaTab {...child.props} setTab={onSetTab} draggable={draggable} hasErrors={child.props.hasErrors || hasErrors} disabled={child.props.disabled}>
                                                {child.props.children}
                                            </SeaTab>
                                        </div>
                                    ) : (
                                        <SeaTab {...child.props} setTab={onSetTab} draggable={false} hasErrors={child.props.hasErrors || hasErrors} disabled={child.props.disabled}>
                                            {child.props.children}
                                        </SeaTab>
                                    )}
                                </div>
                            );
                        } else {
                            return <></>;
                        }
                    })
                }
                {showPlus &&
                    <div className="tab-container">
                        <div
                            className={`sea-plus-button pushy no-select${disablePlus ? ' disabled' : ''}`}
                            onClick={(e) => {
                                if (!disablePlus) {
                                    if (onAddTab) {
                                        onAddTab(e);
                                    }
                                } else if (onAddTabFailed) {
                                    onAddTabFailed(e);
                                }
                            }}
                        >
                            <div className="columns">
                                <div>
                                    <SeaIcon slot='icon-only' icon='add' forceFontSize='30px'/>
                                </div>
                                {plusText &&
                                    <div style={{
                                        whiteSpace: 'nowrap',
                                        padding: '6px 0px 0px 8px',
                                        fontWeight: '500'
                                    }}>
                                        {plusText}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="end-bit">
                    <div></div>
                </div>
            </div>
            <div className={`sea-tabs-fade right ${fadeRight ? 'on' : 'off'}`}></div>
            {/* {isHybridBrowser() &&
                <div style={{
                    position: 'absolute',
                    right: '0',
                    top: '50%',
                    transform: 'translateY(-50%)',
                }}>
                    {`<`}
                </div>
            } */}
        </div>
    );
};

export default SeaTabsGroup;
