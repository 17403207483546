import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { canCreate, canDelete } from '../../../../shared-state/Core/userPermissions';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { TrainingTask } from '../../../../shared-state/Crew/trainingTasks';
import { TrainingTaskReportDocument } from '../../../../shared-state/Crew/useTrainingTaskReportDocuments';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import Yup from '../../../../lib/yup'
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaInputError from '../../../../components/SeaInputError/SeaInputError';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';

interface EditTrainingTaskReportDocumentsModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    itemToUpdate?: TrainingTaskReportDocument,
    selectedTask: TrainingTask,
    selectedReportAndPersonnel: {
        reportId: string,
        personnelIds: string[]
    },
    level?: number
}

const EditTrainingTaskReportDocumentsModal: React.FC<EditTrainingTaskReportDocumentsModalProps> = ({
    showModal,
    setShowModal,
    itemToUpdate,
    selectedTask,
    selectedReportAndPersonnel,
    level
}) => {
    const userId = sharedState.userId.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                name: itemToUpdate.name ? ''+itemToUpdate.name : ''
            };
        } else {
            return {
                name: ''
            };
        }
    }, [itemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isSubmitting } = useFormik({
        initialValues: initialValues, 
        validationSchema: Yup.object({
            name: Yup.string().max(500).required()
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('trainingTaskReportDocuments') || files === undefined || files.length === 0) {
                return;
            }

            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                const batch = splittableBatch(firestore, 20 - 1); // -1 because trainingTaskReportDocuments requires an extra security access call
                const batchTrace = makeBatchTrace(batch, 'trainingTaskReportDocuments');

                if (itemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'trainingTaskReportDocuments', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            name: data.name,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    logAction(
                        batch,
                        'Update',
                        'trainingTaskReportDocuments',
                        itemToUpdate.id,
                        `${data.name} - ${itemToUpdate.name}`,
                        [selectedTask.vesselId]
                    );

                    onCollectionUpdated(batch, 'trainingTaskReportDocuments');
                    saveFileRefs(batch, files, 'trainingTaskReportDocuments', itemToUpdate.id);
                } else {
                    const newRef = doc(collection(firestore, 'trainingTaskReportDocuments'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    batch.set(newRef, {
                        vesselId: selectedTask.vesselId,
                        taskId: selectedTask.id,
                        reportId: selectedReportAndPersonnel.reportId,
                        personnelIds: selectedReportAndPersonnel.personnelIds,
                        whenAdded: batchTrace.whenAction,
                        addedBy: userId,
                        name: data.name,
                        files: seaFilesToValue(files),
                        state: 'active',
                        touched: serverTimestamp(),
                    });

                    saveFileRefs(batch, files, 'trainingTaskReportDocuments', newRef.id);

                    logAction(
                        batch,
                        'Add',
                        'trainingTaskReportDocuments',
                        newRef.id,
                        `${data.name} - ${selectedTask.task}`,
                        [selectedTask.vesselId]
                    );
                }
                
                batchTrace.data = {
                    data,
                    files: seaFilesToValue(files),
                    taskId: selectedTask?.id,
                    vesselId: selectedTask?.vesselId,
                    itemToUpdate
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} trainingTaskReportDocuments ${selectedTask?.id}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload trainingTaskReportDocuments`, error.message, error, {
                        files: seaFilesToValue(files),
                        itemToUpdate,
                        data
                    });
                }
            });

        }
    });

    useEffect(() => {
        if (files && files.length > 0) {
            if (
                (values.name === undefined || values.name.length === 0) &&
                files[0].name &&
                files[0].name.length > 0
            ) {
                let s = files[0].name;
                if (s.indexOf('.') > 0) {
                    s = s.substring(0, s.lastIndexOf('.'));
                }
                setFieldValue('name', s);
            }
        }
    }, [files, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [initialValues, values, files, itemToUpdate?.files]);

    const onDeleteTrainingTaskReportDocuments = (e: any) => {
        e.preventDefault();
        if (!itemToUpdate) {
            return;
        }
        confirmAction('Are you sure you want to delete this', 'Yes, delete').then(() => {
            const batch = splittableBatch(firestore, 20 - 1); // -1 because trainingTaskReportDocuments requires an extra security access call
            const batchTrace = makeBatchTrace(batch, 'trainingTaskReportDocuments', 'delete', itemToUpdate.id);

            batch.set(
                doc(firestore, 'trainingTaskReportDocuments', itemToUpdate.id),
                {
                    state: 'deleted',
                    deletedBy: userId,
                    whenDeleted: batchTrace.whenAction,
                    touched: serverTimestamp(),
                },
                { merge: true }
            );

            logAction(
                batch,
                'Delete',
                'trainingTaskReportDocuments',
                itemToUpdate.id,
                `${itemToUpdate.name} - ${selectedTask.task}`,
                [selectedTask.vesselId]
            );

            onCollectionUpdated(batch, 'trainingTaskReportDocuments');

            batchTrace.data = {itemToUpdate};
            batchTrace.save(`Delete trainingTaskReportDocument ${itemToUpdate?.id}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            setShowModal(false);
        }).catch(() => {});
    };

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Training Task Report Documents' : 'Add Training Task Report Documents'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='thin'
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="trainingTaskReportDocuments"
                                field="files"
                            />
                            {hasSubmitted && (files === undefined || files.length === 0) &&
                                <SeaInputError alignLeft={true}>
                                    Please choose at least one image/document to upload
                                </SeaInputError>
                            }
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                label="Name / Description"
                                //placeholder="Add notes..."
                                name="name"
                                value={values.name}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.name ? errors.name : ''}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                {canCreate('crewTraining') &&
                    <div className="view-modal-buttons">
                        <SeaButton zone="white" size="wide" type="submit">
                            {itemToUpdate ? 'Update Documents' : 'Save Documents'}
                        </SeaButton>
                        <div className="spacer-wide"></div>
                        {(canDelete('crewTraining') || itemToUpdate?.addedBy === userId) &&
                            <SeaLinkButton mode="standard-link" onClick={(e) => onDeleteTrainingTaskReportDocuments(e)}>Delete</SeaLinkButton>
                        }
                    </div>
                }
                <div style={{ height: '30px' }}></div>
            </form>
        </SeaModal>
    );
};

export default EditTrainingTaskReportDocumentsModal;
