import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react';
import { Document, View, Text, Page, Image } from '@react-pdf/renderer';
import { pdfStyles } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { formatDate, formatDueDateStatus, formatEmailReminder } from '../../../lib/datesAndTime';
import { sharedState } from '../../../shared-state/shared-state';
import { CrewCertificate } from '../../../shared-state/Crew/crewCertificates';
import { renderFullName, renderFullNameForUserId } from '../../../shared-state/Core/users';
import { renderCategoryName } from '../../../lib/categories';
import { getImgSrc, getImgSrcFromExt, makeSeaFiles } from '../../../lib/files';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';

interface CrewPCertificatesDetailPdfProps {
    onCompleted: () => void,
    selectedItem: CrewCertificate,
}

const CrewPCertificatesDetailPdf: React.FC<CrewPCertificatesDetailPdfProps> = ({
    onCompleted,
    selectedItem,
}) => {
    const crewCertificateTitles = sharedState.crewCertificateTitles.use(true);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const imagesRef = useRef<{ reference?: number, element?: JSX.Element, src: string | Promise<string>, fileName: string }[]>([]);

    const reportTitle = useMemo(() => {
        return renderFullNameForUserId(selectedItem?.heldBy) + ' - ' + renderCategoryName(selectedItem?.titleId, crewCertificateTitles);
    }, [selectedItem, crewCertificateTitles]);

    const getImageSrcs = useCallback((files: string[], setLoaded: (value: React.SetStateAction<boolean>) => void) => {
        imagesRef.current =[];
        if (files?.length) {
            for (const _file of files) {
                const file = makeSeaFiles([_file])[0];
                const ext = file.ext?.toLowerCase();
                const isImage = (ext === 'jpg' || ext === 'jpeg' || ext === 'png');
                const imgSrc = (
                    isImage ?
                        getImgSrc(
                            file.state!,
                            file.id!,
                            file.ext!,
                            'full'
                        ) :
                        getImgSrcFromExt(file.ext, 'medium')
                );
                const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                if (isImage) {
                    imagesRef.current.push({
                        src: imgSrc,
                        fileName: fileName
                    });
                }
            }
        }
        setLoaded(true);
    }, []);

    useEffect(() => {
        getImageSrcs(selectedItem.files, setImagesLoaded);
    }, [getImageSrcs, selectedItem.files]);

    const MakePDF = useCallback(() => {
        if (!selectedItem) return (<></>);

        return (
            <Document
                title={reportTitle}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    {selectedItem && <>
                        <PdfItemsHeader
                            heading={reportTitle}
                            subHeading={formatDueDateStatus(selectedItem.dateExpires)}
                            userFullName={renderFullName()}
                        />

                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column2}>
                                <Text style={pdfStyles.label}>Issued By</Text>
                                <Text>{formatValue(selectedItem?.issuedBy)}</Text>
                            </View>
                            <View style={pdfStyles.column2}>
                                <Text style={pdfStyles.label}>Issued Date</Text>
                                <Text>
                                    {formatDate(selectedItem?.dateIssued)}
                                </Text>
                            </View>
                        </View>

                        {selectedItem?.type === 'renewable' &&
                            <>
                                <View wrap={false} style={pdfStyles.row}>
                                    <View style={pdfStyles.column2}>
                                        <Text style={pdfStyles.label}>Expiry</Text>
                                        <Text>{formatDate(selectedItem?.dateExpires)}</Text>
                                    </View>
                                    <View style={pdfStyles.column2}>
                                        <Text style={pdfStyles.label}>Notification</Text>
                                        <Text>
                                            {formatValue(formatEmailReminder(selectedItem?.emailReminder), 'No reminder set')}
                                        </Text>
                                    </View>
                                </View>
                            </>
                        }

                    </>
                    }
                </Page>
                {imagesRef.current.length > 0 &&
                    <Page
                        style={{
                            ...pdfStyles.page
                        }}
                        orientation="portrait"
                        size="A4"
                        wrap={true}
                    >
                        {imagesRef.current.map((img: any, index: number) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        maxWidth: '19cm',
                                        margin: '0 1cm 0 1cm',
                                        paddingBottom: '0.5cm'
                                    }}
                                    wrap={false}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{
                                            alignSelf: 'center',
                                            padding: '0 0.02cm 0.25cm 0',
                                            fontSize: '7pt'
                                        }}>
                                            {index + 1}
                                        </Text>
                                        <Text>{img.fileName}</Text>
                                    </View>
                                    <Image
                                        src={img.src}
                                        style={{
                                            objectFit: 'scale-down',
                                            objectPositionX: 0,
                                            objectPositionY: 0,
                                        }}
                                    />
                                </View>
                            );
                        })}
                    </Page>
                }
            </Document>
        )
    }, [reportTitle, selectedItem]);

    if (imagesLoaded) {
        return (
            <SeaPdfGenerator
                onCompleted={onCompleted}
                pdfTitle={reportTitle}
                MakePdf={MakePDF}
                requestedFrom="CrewParticularsDetail"
            />
        );
    }
    return <></>;
};
export default CrewPCertificatesDetailPdf;