import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate } from '../../../../lib/datesAndTime';
import { CategoriesData, makeCategoryId } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { saveFileRefs, seaFilesToValue } from '../../../../lib/files';
import { SOP } from '../../../../shared-state/VesselDocuments/vesselSOPS';
import { handleLinkUpdates } from '../../../../lib/links';
import { LinkType } from '../../../../shared-state/Core/links';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaEditDocumentation, { SeaDocumentation, confirmAnyDocumentationLoss, getFilesToUploadForSeaDocumentation, hasSeaDocumentationChanged, makeSeaDocumentation, seaDocumentationToFilesValue, seaDocumentationToSfdocValue } from '../../../../components/SeaEditDocumentation/SeaEditDocumentation';
import SeaLinkMultiList from '../../../../components/SeaLinkMultiList/SeaLinkMultiList';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditSOPProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: SOP,
    vesselSopCategories: CategoriesData,
    existingLinks?: LinkType[]
}

const EditSOP: React.FC<EditSOPProps> = ({showModal, setShowModal, itemToUpdate, level=1, vesselSopCategories, existingLinks}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [documentation, setDocumentation] = useState<SeaDocumentation>({
        type: 'sfdoc',
        files: [],
        sfdoc: {}
    });
    const [links, setLinks] = useState<LinkType[]>([]);


    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                title: itemToUpdate.title ? ''+itemToUpdate.title : '',
                dateIssued: itemToUpdate.dateIssued ? itemToUpdate.dateIssued : '',
                categoryId: itemToUpdate.categoryId ? ''+itemToUpdate.categoryId : ''
            };
        } else {
            return {
                title: '',
                dateIssued: formatSeaDate(),
                categoryId: ''
            };
        }
    }, [itemToUpdate]);



    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setLinks(existingLinks ?? []);
        setDocumentation(makeSeaDocumentation(itemToUpdate));
    }

    const onClosed = () => {
        setFieldValue('categoryId', ''); // Make sure selects do not remember old values
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().max(500).required(),
            dateIssued: Yup.date().required().min(...notTooOld),
            categoryId: Yup.string().max(500).required()
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('SOP')) { return; }
            confirmAnyDocumentationLoss(documentation, makeSeaDocumentation(itemToUpdate), confirmAction).then(() => {
                const filesToUpload = getFilesToUploadForSeaDocumentation(documentation);
                uploadFiles(filesToUpload).then(() => {
                    if (!vesselId) {
                        throw new Error('vesselId is not defined');
                    }
                    let itemId: string;
                    // Process form
                    const batch = splittableBatch(firestore, 20 - 0);
                    const batchTrace = makeBatchTrace(batch, 'SOPs');
                    const filesValue = seaDocumentationToFilesValue(documentation);
                    const sfdocValue = seaDocumentationToSfdocValue(documentation);
                    if (itemToUpdate) {
                        itemId = itemToUpdate.id;
                        batchTrace.exampleOperation = 'update';
                        batchTrace.exampleDocId = itemToUpdate.id;
                        batch.set(
                            doc(firestore, 'SOPs', itemToUpdate.id),
                            {
                                updatedBy: userId,
                                whenUpdated: batchTrace.whenAction,
                                title: data.title,
                                dateIssued: data.dateIssued,
                                categoryId: makeCategoryId(
                                    data.categoryId,
                                    vesselSopCategories,
                                    deleteValue,
                                    batch,
                                    'vesselSopCategories',
                                    'vesselId',
                                    vesselId,
                                    {}
                                ),
                                files: filesValue ? filesValue : deleteValue,
                                sfdoc: sfdocValue ? sfdocValue : deleteValue,
                                touched: serverTimestamp(),
                            },
                            { merge: true }
                        );

                        saveFileRefs(batch, filesToUpload, 'SOPs', itemToUpdate.id);
                        logAction(
                            batch,
                            'Update',
                            'SOPs',
                            itemToUpdate.id,
                            data.title,
                            [itemToUpdate.vesselId]
                        );
                    } else {
                        const newRef = doc(collection(firestore, 'SOPs'));
                        itemId = newRef.id;
                        batchTrace.exampleOperation = 'create';
                        batchTrace.exampleDocId = newRef.id;
                        batch.set(newRef, {
                            vesselId: vesselId,
                            addedBy: userId,
                            title: data.title,
                            dateIssued: data.dateIssued,
                            categoryId: makeCategoryId(
                                data.categoryId,
                                vesselSopCategories,
                                undefined,
                                batch,
                                'vesselSopCategories',
                                'vesselId',
                                vesselId,
                                {}
                            ),
                            whenAdded: batchTrace.whenAction,
                            state: 'active',
                            files: filesValue ? filesValue : undefined,
                            sfdoc: sfdocValue ? sfdocValue : undefined,
                            touched: serverTimestamp(),
                        });

                        saveFileRefs(batch, filesToUpload, 'SOPs', newRef.id);
                        logAction(
                            batch,
                            'Add',
                            'SOPs',
                            newRef.id,
                            data.title,
                            [vesselId]
                        );
                    }

                    onCollectionUpdated(batch, 'SOPs');

                    handleLinkUpdates(batch, links, existingLinks, itemId, 'SOPs');

                    batchTrace.data = {
                        data,
                        documentation,
                        itemToUpdate
                    };
                    batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} SOP ${data?.title}`);
                    batch.commit().then(() => {
                        batchTrace.reportSuccess();
                    }).catch((error) => {
                        batchTrace.reportError(error.message, error);
                    });

                    setShowModal(false);
                }).catch((error: any) => {
                    if (!handleUploadError(error)) {
                        reportError(`Failed to upload SOP files`, error.message, error, {
                            filesToUpload: seaFilesToValue(filesToUpload),
                            data,
                            itemToUpdate
                        });
                    }
                });
            }).catch(() => {
                // Ignore cancellation of documentation loss
            });
        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            hasSeaDocumentationChanged(documentation, makeSeaDocumentation(itemToUpdate))
        );
    }, [values, initialValues, documentation, itemToUpdate]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);


    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Standard Operating Procedure' : 'Add New Standard Operating Procedure'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
            size="wide"
            confirmDismissDirty={{
                title: "Are you sure you want to leave this form without saving first?",
                no: "Cancel",
                yes: "Yes, leave form"
            }}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                          	<SeaInput
								label="SOP Title"
								name="title"
								value={values.title}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.title ? errors.title : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselSopCategories}
                                label="Category"
                                name="categoryId"
                                initialCategoryId={initialValues.categoryId}
                                categoryId={values.categoryId}
                                otherPlaceholder="Add New Category"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.categoryId ? errors.categoryId : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaDate
								label="Issue Date"
								name="dateIssued"
								value={values.dateIssued}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								error={touched.dateIssued ? errors.dateIssued : ''}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <SeaEditDocumentation
                                label="Documentation"
                                title={values.title}
                                documentation={documentation}
                                setDocumentation={setDocumentation}
                                collection="SOPs"
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <SeaLinkMultiList 
                                selectedCollection='SOPs'
                                selectedItemId={itemToUpdate?.id || ''}
                                selectedItemVesselId={itemToUpdate?.vesselId || 'na'}
                                values={links}
                                setValues={setLinks}
                                linkOptions={['drills', 'scheduledMaintenanceTasks', 'risks', 'customForms', 'trainingTasks', 'external']}
                                deleteable
                                confirmDelete
                                level={level+1}
                                vesselIds={vesselId ? [vesselId] : undefined}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">
                        {itemToUpdate ? 'Save Changes' : 'Save New SOP'}
                    </SeaButton>
                </div>
            </form>

        </SeaModal>
    );
};

export default EditSOP;
