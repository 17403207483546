import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useState, useEffect } from "react";
import { sharedState } from "../shared-state";
import { SafetyCheckItem } from './safetyCheckItems';



export const useSafetyCheckItems = (shouldLoad?: boolean): SafetyCheckItem[] | undefined => {
    const vesselId = sharedState.vesselId.use();;
    const [safetyChecks, setSafetyChecks] = useState<SafetyCheckItem[]>();

    useEffect(() => {
        setSafetyChecks(undefined);
    
        if (shouldLoad && vesselId) {
            return onSnapshot(
                query(
                    collection(firestore, 'safetyCheckItems'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    orderBy('whenAdded', 'desc'),
                ),
                (snap) => {
                    const items: SafetyCheckItem[] = [];
                    snap.docs.forEach((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as SafetyCheckItem;
                        items.push(item);
                    });
                    setSafetyChecks(items);
                },
                (error) => {
                    console.log(`Failed to access SafetyCheckItems for vesselId=${vesselId}`, error.message, error, {
                        vesselId,
                    });
                }
            );
        }
    }, [shouldLoad, vesselId]);

    return safetyChecks
};