import React from 'react';
import { IonInput } from '@ionic/react';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import './SeaDate.css';

interface SeaDateProps {
    zone?: 'blue' | 'white' | 'grey',
    name?: string,
    label?: string,
    value?: string | undefined,
    error?: string,
    disabled?: boolean,
    readonly?: boolean,
    required?: boolean,
    onchange?: (e: CustomEvent) => void,
    onblur?: (e: CustomEvent) => void,
    onfocus?: (e: CustomEvent) => void,
    onkeyup?: (e: CustomEvent) => void
}

const SeaDate = React.forwardRef<HTMLInputElement, SeaDateProps>(({ zone, name, label, value, error, disabled, readonly, required, onchange, onblur, onfocus, onkeyup }, forwardedRef:any) => {

    const onIonInput = (e: CustomEvent) => {
        if (onkeyup) {
            onkeyup(e);
        }
    }

    const onIonChange = (e: CustomEvent) => {
        if (e.detail.value === value) {
            return;
        }
        if (onchange) {
            onchange(e);
        }
    };

    let color = 'primary';
    switch (zone) {
        case 'blue':
            color = 'light'; // white
            break;
        case 'white':
        case 'grey':
            color = 'primary'; // blue
            break;
    }

    return (
        <>
            {label && <SeaLabel>{label}</SeaLabel>}
            <IonInput
                className={`sea-input ${zone}-zone ${error ? 'has-error' : ''}`}
                color={color}
                inputmode="text"
                type="date"
                name={name}
                value={value}
                disabled={disabled}
                readonly={readonly}
                required={required}
                onIonChange={onIonChange}
                onIonBlur={onblur}
                onIonFocus={onfocus}
                onIonInput={onIonInput}
                autofocus={false}
            />
            <SeaInputError>{error}</SeaInputError>
        </>
    );
});

export default SeaDate;
