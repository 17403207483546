import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useFormik } from 'formik';
import { deleteValue, firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, FieldValue, serverTimestamp } from 'firebase/firestore';
import { haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { addInterval, formatSeaDate } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName, renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { makeBatchTrace, reportError } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { hasSignatureChanged, haveFilesChanged, makeSeaFiles, makeSignature, saveFileRefs, SeaFile, seaFilesToValue, signatureToValue } from '../../../../lib/files';
import { DrillReport } from '../../../../shared-state/VesselSafety/drillReports';
import { SeaLinks } from '../../../../components/SeaLinks/SeaLinks';
import { DrillCrewData } from '../../../../shared-state/VesselSafety/drills';
import { useMultipleLinks } from '../../../../shared-state/Core/multiItemLinks';
import Yup, { notTooOld } from '../../../../lib/yup';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaSignature from '../../../../components/SeaSignature/SeaSignature';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditDrillReportProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    level?: number;
    itemToUpdate?: DrillReport;
    drillTypeId?: string;
    viewOnly?: boolean;
    vesselId: string;
}

const EditDrillReport: React.FC<EditDrillReportProps> = ({ showModal, setShowModal, itemToUpdate, level = 1, drillTypeId, viewOnly, vesselId }) => {
    const userId = sharedState.userId.use(showModal);
    const users = sharedState.users.use(showModal);
    const vesselDrills = sharedState.vesselDrills.use(showModal);
    const drillReports = sharedState.drillReports.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [drillsSelected, setDrillsSelected] = useState<string[]>();
    const links = useMultipleLinks(drillsSelected);
    const [signature, setSignature] = useState<SeaFile>();
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                dateCompleted: itemToUpdate.dateCompleted ? itemToUpdate.dateCompleted : '',
                location: itemToUpdate.location ? '' + itemToUpdate.location : '',
                scenario: itemToUpdate.scenario ? '' + itemToUpdate.scenario : '',
                equipment: itemToUpdate.equipment ? '' + itemToUpdate.equipment : '',
                furtherTraining: itemToUpdate.furtherTraining ? '' + itemToUpdate.furtherTraining : '',
                modification: itemToUpdate.modification ? '' + itemToUpdate.modification : '',
                crewInvolvedIds: itemToUpdate.crewInvolvedIds ? [...itemToUpdate.crewInvolvedIds] : [],
            };
        } else {
            return {
                dateCompleted: formatSeaDate(),
                location: '',
                scenario: '',
                equipment: '',
                furtherTraining: '',
                modification: '',
                crewInvolvedIds: [],
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemToUpdate, showModal]); // showModal to ensure data is reset on close

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
        setSignature(makeSignature(itemToUpdate?.signature));
        const drillIds: string[] = [];
        itemToUpdate?.drills?.forEach((drill) => drillIds.push(drill.id));
        setDrillsSelected(itemToUpdate ? drillIds : drillTypeId ? [drillTypeId] : []);
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting, isValid, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            dateCompleted: Yup.date()
                .required()
                .min(...notTooOld),
            location: Yup.string().max(500),
            scenario: Yup.string().max(5000),
            equipment: Yup.string().max(5000),
            furtherTraining: Yup.string().max(5000),
            modification: Yup.string().max(5000),
            crewInvolvedIds: Yup.array().of(Yup.string()).min(1),
        }),
        onSubmit: (data) => {
            setHasSubmitted(true);
            // if drills array is empty - update error state
            if (preventMultiTap('drillReport') || drillsSelected === undefined || (drillsSelected && drillsSelected.length === 0) || signature === undefined || !vesselId) {
                return;
            }

            // Attempt upload first.... ?
            uploadFiles([...files, signature])
                .then(() => {
                    // Process form
                    const batch = splittableBatch(firestore, 20 - 0);
                    const batchTrace = makeBatchTrace(batch, 'drillReports');

                    const dateCompleted = data.dateCompleted;

                    if (itemToUpdate) {
                        // Updating an exising drill report
                        batchTrace.exampleOperation = 'update';
                        batchTrace.exampleDocId = itemToUpdate.id;

                        const drillsMapped: { id: string; dateDue: string }[] = [];
                        const drillNames = [] as string[];

                        itemToUpdate?.drills.forEach((drill) => {
                            drillsMapped.push({
                                id: drill.id,
                                dateDue: addInterval(dateCompleted, vesselDrills?.byId[drill.id]?.interval as string).toISODate(),
                                //name: vesselDrills?.byId[drill.id]?.name
                            });
                            drillNames.push(vesselDrills?.byId[drill.id]?.name as string);
                        });

                        const drillIds = itemToUpdate?.drills?.map((drill) => drill.id);

                        const existingCrewOnDrillReport = itemToUpdate.crewInvolvedIds;
                        const newCrewOnDrillReport = data.crewInvolvedIds;
                        const haveCrewBeenRemovedFromDrillReport = newCrewOnDrillReport.length < existingCrewOnDrillReport.length;

                        // Update the drill documents for the crew on the updated report
                        drillIds.forEach((drillId) => {
                            const interval = vesselDrills?.byId[drillId]?.interval as string;

                            const crewEntries = newCrewOnDrillReport.map((crewId) => {
                                return [
                                    crewId,
                                    {
                                        dateLastCompleted: dateCompleted,
                                        dateDue: addInterval(dateCompleted, interval).toISODate(),
                                    },
                                ];
                            });

                            const newCrewDataForDrill = Object.fromEntries(crewEntries);

                            // Update the drill doc with the new crew data
                            batch.set(
                                doc(firestore, 'drills', drillId),
                                {
                                    crew: newCrewDataForDrill,
                                },
                                { merge: true }
                            );
                        });
                        onCollectionUpdated(batch, 'drills');

                        // Update drills for any crew removed from drill report
                        if (haveCrewBeenRemovedFromDrillReport) {
                            const removedCrew = existingCrewOnDrillReport.filter((crewId) => !newCrewOnDrillReport.includes(crewId));

                            drillIds.forEach((drillId) => {
                                const drill = vesselDrills?.byId[drillId];
                                if (!drill) {
                                    throw new Error('No drill'); // This should never happen
                                }

                                const newCrewDataForDrill = {} as DrillCrewData | { [crewId: string]: FieldValue };

                                removedCrew.forEach((crewId) => {
                                    const key = String(drillId + crewId);
                                    const mostRecentReport = drillReports?.byDrillAndUser[key];
                                    const currentReportId = itemToUpdate.id;

                                    // If this is not the most recent report, we don't need to update the drill object
                                    const isMostRecentReportForDrill = mostRecentReport?.report.id === currentReportId; // If this is the most recent report for the drill, we need to update the drill
                                    if (!isMostRecentReportForDrill) {
                                        return;
                                    }

                                    const drillReportsForUserAndDrill = (drillReports?.byUserId[crewId] ?? [])
                                        .filter((drillReport) => drillReport.state === 'active')
                                        .filter((drillReport) => drillReport.drillIds?.includes(drillId) || drillReport.drills.some((drill) => drill.id === drillId));

                                    const previousReportForDrill = drillReportsForUserAndDrill.find((report) => report.dateCompleted < itemToUpdate.dateCompleted);

                                    if (!previousReportForDrill) {
                                        // If we don't have a previous report, then we need to treat the drill as missing
                                        newCrewDataForDrill[crewId] = deleteValue;
                                        return;
                                    }

                                    const dateDue = previousReportForDrill.dateDue ?? addInterval(previousReportForDrill.dateCompleted, drill.interval).toISODate();

                                    newCrewDataForDrill[crewId] = { dateLastCompleted: previousReportForDrill.dateCompleted, dateDue };
                                });

                                batch.set(
                                    doc(firestore, 'drills', drillId),
                                    {
                                        crew: newCrewDataForDrill,
                                    },
                                    { merge: true }
                                );
                            });
                            onCollectionUpdated(batch, 'drills');
                        }

                        batch.set(
                            doc(firestore, 'drillReports', itemToUpdate.id),
                            {
                                updatedBy: userId,
                                whenUpdated: batchTrace.whenAction,
                                dateCompleted: dateCompleted,
                                location: data.location ? data.location : deleteValue,
                                crewInvolvedIds: data.crewInvolvedIds ? data.crewInvolvedIds : deleteValue,
                                scenario: data.scenario ? data.scenario : deleteValue,
                                equipment: data.equipment ? data.equipment : deleteValue,
                                furtherTraining: data.furtherTraining ? data.furtherTraining : deleteValue,
                                modification: data.modification ? data.modification : deleteValue,
                                files: seaFilesToValue(files),
                                signature: signatureToValue(signature),
                                drills: drillsMapped.length > 0 ? drillsMapped : undefined,
                                drillIds: drillIds?.length > 0 ? drillIds : undefined,
                                touched: serverTimestamp(),
                            },
                            { merge: true }
                        );

                        saveFileRefs(batch, [...files, signature], 'drillReports', itemToUpdate.id);

                        logAction(batch, 'Update', 'drillReports', itemToUpdate.id, drillNames.join(', '), [itemToUpdate.vesselId]);
                    } else {
                        // Creating a new drill report
                        const newDrillReportRef = doc(collection(firestore, 'drillReports'));
                        batchTrace.exampleOperation = 'create';
                        batchTrace.exampleDocId = newDrillReportRef.id;

                        const drillsMapped: { id: string; dateDue: string }[] = [];
                        const drillNames = [] as string[];

                        // Update the drill documents for the crew on the report
                        drillsSelected?.forEach((drillId) => {
                            const drillInterval = vesselDrills?.byId[drillId]?.interval as string;

                            drillsMapped.push({
                                id: drillId,
                                dateDue: addInterval(data.dateCompleted, drillInterval).toISODate(),
                                //name: vesselDrills?.byId[drillId]?.name
                            });

                            if (vesselDrills?.byId[drillId]?.name) {
                                drillNames.push(vesselDrills.byId[drillId].name);
                            }

                            // Build the new 'crew' object that gets saved against the drill document
                            const crewEntries = data.crewInvolvedIds.map((crewId) => {
                                return [
                                    crewId,
                                    {
                                        dateLastCompleted: dateCompleted,
                                        dateDue: addInterval(dateCompleted, drillInterval).toISODate(),
                                    },
                                ];
                            });

                            const newCrewDataForDrill = Object.fromEntries(crewEntries);

                            // Update the drill doc with the new crew data
                            batch.set(
                                doc(firestore, 'drills', drillId),
                                {
                                    crew: newCrewDataForDrill,
                                },
                                { merge: true }
                            );
                        });
                        onCollectionUpdated(batch, 'drills');
                        const drillIds = drillsSelected?.map((drillId) => drillId);

                        batch.set(newDrillReportRef, {
                            vesselId: vesselId,
                            addedBy: userId,
                            whenAdded: batchTrace.whenAction,
                            dateCompleted: data.dateCompleted,
                            location: data.location ? data.location : undefined,
                            scenario: data.scenario ? data.scenario : undefined,
                            equipment: data.equipment ? data.equipment : undefined,
                            furtherTraining: data.furtherTraining ? data.furtherTraining : undefined,
                            modification: data.modification ? data.modification : undefined,
                            state: 'active',
                            files: seaFilesToValue(files),
                            signature: signatureToValue(signature),
                            crewInvolvedIds: data.crewInvolvedIds ? data.crewInvolvedIds : undefined,
                            drills: drillsMapped.length > 0 ? drillsMapped : undefined,
                            drillIds: drillIds?.length > 0 ? drillIds : undefined,
                            touched: serverTimestamp(),
                        });

                        saveFileRefs(batch, [...files, signature], 'drillReports', newDrillReportRef.id);
                        logAction(batch, 'Add', 'drillReports', newDrillReportRef.id, drillNames.join(', '), [vesselId]);
                    }

                    // Update drill.whenLatestCompleted values if necessary
                    if (drillsSelected && drillsSelected.length > 0) {
                        let anyDrillsUpdated = false;
                        drillsSelected.forEach((drillId: string) => {
                            let dateLastCompleted = data.dateCompleted;
                            drillReports?.byDrillId[drillId]?.forEach((report, index) => {
                                if (index < 10 && (itemToUpdate === undefined || report.id !== itemToUpdate.id) && report.dateCompleted > dateLastCompleted) {
                                    dateLastCompleted = report.dateCompleted;
                                }
                            });
                            const drill = vesselDrills?.byId[drillId];
                            if (drill?.dateLastCompleted !== dateLastCompleted) {
                                anyDrillsUpdated = true;
                                batch.set(
                                    doc(firestore, 'drills', drillId),
                                    {
                                        dateLastCompleted: dateLastCompleted, // Obsolete?
                                        dateDue: addInterval(dateLastCompleted, drill?.interval as string).toISODate(), // Obsolete?
                                        touched: serverTimestamp(),
                                    },
                                    { merge: true }
                                );
                            }
                        });
                        if (anyDrillsUpdated) {
                            onCollectionUpdated(batch, 'drills');
                        }
                    }
                    onCollectionUpdated(batch, 'drillReports');
                    batchTrace.data = {
                        data,
                        drillsSelected,
                        files: seaFilesToValue(files),
                        signature: signatureToValue(signature),
                    };
                    batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} drill reports`);
                    batch
                        .commit()
                        .then(() => {
                            batchTrace.reportSuccess();
                        })
                        .catch((error) => {
                            batchTrace.reportError(error.message, error);
                        });

                    showToast('Your drill report has been saved');
                    setShowModal(false);
                })
                .catch((error: any) => {
                    if (!handleUploadError(error)) {
                        reportError(`Failed to upload Drill Report files`, error.message, error, {
                            files: seaFilesToValue(files),
                            data,
                            drillsSelected,
                        });
                    }
                });
        },
    });

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues) || haveFilesChanged(files, itemToUpdate?.files) || hasSignatureChanged(signature, itemToUpdate?.signature);
    }, [values, initialValues, files, signature, itemToUpdate]);

    const drillsOptions = useMemo(() => {
        return (
            vesselDrills?.all &&
            vesselDrills?.all?.map((drill) => {
                return {
                    id: drill.id,
                    name: drill.name,
                };
            })
        );
    }, [vesselDrills]);

    const crewInvolvedOptions = useMemo(() => {
        if (!vesselId) {
            return;
        }
        return (
            users?.byVesselId[vesselId] &&
            users.byVesselId[vesselId].map((user) => {
                return {
                    id: user.id as string,
                    name: renderFullName(user),
                };
            })
        );
    }, [users, vesselId]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? `Edit Report by ${renderFullNameForUserId(itemToUpdate?.addedBy)}` : 'Create Report'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit} autoComplete="off" autoFocus={false} noValidate>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                            <SeaDate label="Drill Date" name="dateCompleted" value={values.dateCompleted} onchange={handleChange} onblur={handleBlur} zone="white" error={touched.dateCompleted ? errors.dateCompleted : ''} />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput label="Location" name="location" value={values.location} onchange={handleChange} onblur={handleBlur} zone="white" type="text" inputmode="text" error={touched.location ? errors.location : ''} />
                        </IonCol>
                        {itemToUpdate ? (
                            <IonCol size="12">
                                <div className="sea-label">Drill Type</div>
                                {itemToUpdate.drills?.map((drill, index) => {
                                    if (index === 0) {
                                        return vesselDrills?.byId[drill.id] ? vesselDrills?.byId[drill.id].name : drill.name;
                                    } else {
                                        return vesselDrills?.byId[drill.id] ? `, ${vesselDrills?.byId[drill.id].name}` : `, ${drill.name}`;
                                    }
                                })}
                            </IonCol>
                        ) : (
                            <IonCol size="12">
                                <SeaMultiSelect
                                    label="Drill type"
                                    values={drillsSelected}
                                    setValues={setDrillsSelected}
                                    options={drillsOptions}
                                    useAllOption={true}
                                    required={true}
                                    requiredError="At least one Drill Type is required"
                                    isSubmitting={isSubmitting}
                                    emptyText="Not Set"
                                />
                            </IonCol>
                        )}
                        <IonCol size="12">
                            <SeaMultiSelect
                                label="Personnel Present"
                                values={values.crewInvolvedIds}
                                setValues={(value) => setFieldValue('crewInvolvedIds', value)}
                                options={crewInvolvedOptions}
                                useAllOption={false}
                                required={true}
                                requiredError="At least one crew needs to be present"
                                isSubmitting={isSubmitting}
                                emptyText="Not Set"
                            />
                        </IonCol>
                        {drillsSelected ? (
                            <IonCol size="12">
                                <SeaLinks ids={drillsSelected} links={links} viewOnly={viewOnly} level={level + 1} />
                            </IonCol>
                        ) : null}
                        <IonCol size="6">
                            <SeaTextarea label="Scenario" name="scenario" value={values.scenario} onchange={handleChange} onblur={handleBlur} zone="white" inputmode="text" error={touched.scenario ? errors.scenario : ''} />
                        </IonCol>
                        <IonCol size="6">
                            <SeaTextarea label="Equipment Used" name="equipment" value={values.equipment} onchange={handleChange} onblur={handleBlur} zone="white" inputmode="text" error={touched.equipment ? errors.equipment : ''} />
                        </IonCol>
                        <IonCol size="6">
                            <SeaTextarea
                                label="Further Training Required"
                                name="furtherTraining"
                                value={values.furtherTraining}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.furtherTraining ? errors.furtherTraining : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaTextarea
                                label="Modification to current operating procedures"
                                name="modification"
                                value={values.modification}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.modification ? errors.modification : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload label="Upload Document" files={files} setFiles={setFiles} collection="drillReports" field="files" />
                        </IonCol>
                        <IonCol size="12">
                            <SeaSignature collection="drillReports" file={signature} setFile={setSignature} label="Sign or initial below" isRequired={isSubmitting} />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="grid-row-spacer"></div>
                <SeaFormHasErrors hasSubmitted={hasSubmitted} isValid={isValid && !(drillsSelected === undefined || (drillsSelected && drillsSelected.length === 0) || signature === undefined)} />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">
                        {itemToUpdate ? 'Update Report' : 'Save New Report'}
                    </SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditDrillReport;
