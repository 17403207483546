import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, regions, preventMultiTap } from '../../../../lib/util';
import { addInterval, formatSeaDate, subtractInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaSelectEmailReminder from '../../../../components/SeaSelectEmailReminder/SeaSelectEmailReminder';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface CompanyPlanSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
}

const CompanyPlanSettings: React.FC<CompanyPlanSettingsProps> = ({
    showModal,
    setShowModal,
}) => {
    const userId = sharedState.userId.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const companyPlan = sharedState.companyPlan.use(showModal);
    const licenseeId = sharedState.licenseeId.use(showModal);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const companyPlanTitle = licenseeSettings ? regions[licenseeSettings?.region]?.companyPlan : '';

    const initialValues = useMemo(() => {
        if (companyPlan) {
            return {
                interval: (companyPlan.interval) ? ''+companyPlan.interval : '',
                dateLastReviewed: (companyPlan.dateLastReviewed) ? companyPlan.dateLastReviewed : '',
                emailReminder: (companyPlan.emailReminder) ? ''+companyPlan.emailReminder : '',
            };
        } else {
            return {
                interval: '',
                dateLastReviewed: formatSeaDate(),
                emailReminder: '',
            };
        }
    }, [companyPlan, showModal]); // eslint-disable-line react-hooks/exhaustive-deps

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            interval: Yup.string().max(200).required(),
            dateLastReviewed: Yup.date().required().min(...notTooOld),
            emailReminder: Yup.string().max(200),
        }), onSubmit: (data) => {
            if (preventMultiTap('companyPlanSettings')) { return Promise.resolve(false); }
            if (!licenseeId) { 
                console.error('No licenseeId');
                return Promise.resolve(false);
            }
            // Process form
            setHasSubmitted(true);
            let dateDue = undefined;
            let dateToRemind = undefined;
            if (data.dateLastReviewed && data.interval) {
                dateDue = addInterval(data.dateLastReviewed, data.interval).toISODate();
            }
            if (dateDue && data.emailReminder) {
                dateToRemind = subtractInterval(dateDue, data.emailReminder).toISODate();
            };

            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'companyPlans', 'update', licenseeId);
            const docRef = doc(firestore, 'companyPlans', licenseeId);

            batch.set(
                docRef,
                {
                    updatedBy: userId,
                    whenUpdated: batchTrace.whenAction,
                    interval: data.interval,
                    emailReminder: data.emailReminder,
                    dateLastReviewed: data.dateLastReviewed,
                    dateToRemind: dateToRemind,
                    dateDue: dateDue,
                    touched: serverTimestamp(),
                },
                { merge: true }
            );
            logAction(
                batch,
                'Update',
                'companyPlans',
                licenseeId,
                companyPlanTitle
            );

            onCollectionUpdated(batch, 'companyPlans');

            batchTrace.data = {
                ...companyPlan, 
                updatedBy: userId,
                whenUpdated: batchTrace.whenAction,
                interval: data.interval,
                emailReminder: data.emailReminder,
                dateLastReviewed: data.dateLastReviewed,
                dateToRemind: dateToRemind,
                dateDue: dateDue,
            };
            batchTrace.save('Update company plan settings');
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            setShowModal(false);
        }
    });

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={licenseeSettings ? regions[licenseeSettings?.region]?.companyPlanShort + ' Settings' : 'Company Plan Settings'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='thin'
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaSelectInterval
                                label="Interval"
                                name="interval"
                                value={values.interval}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.interval ? errors.interval : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaDate
                                label="Last Review Date"
                                name="dateLastReviewed"
                                value={values.dateLastReviewed}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.dateLastReviewed ? errors.dateLastReviewed : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaSelectEmailReminder
                                label="Set Email Reminder"
                                name="emailReminder"
                                value={values.emailReminder}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.emailReminder ? errors.emailReminder : ''}
                                safetyMeetingOptions={true}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">Save Settings</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default CompanyPlanSettings;
