import React, { useCallback } from 'react';
import { Document, View, Page, Text, Image } from '@react-pdf/renderer';
import { PdfPageLimiter, UnloadedPdfRows, pdfStyles } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { formatDateShort } from '../../../lib/datesAndTime';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { getImgSrcFromString } from '../../../lib/files';
import { Job } from '../../../shared-state/VesselMaintenance/jobs';
import { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { Equipment } from '../../../shared-state/VesselMaintenance/equipment';
import SeaNoDataPDF from '../../../components/SeaNoDataPDF';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';
import CriticalFlagPDF from '../../../assets/svg/CriticalFlagPDF';
import SeaHorizontalStackedBarGraph from '../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import reporting from '../../../lib/reporting';

interface JobsReportPDFProps {
    onCompleted: () => void;
    title: string;
    subTitle?: string;
    graphData?: GraphData[];
    filteredItems?: Job[];
    itemsById?: {
        [key: string]: Equipment;
    };
    categories?: (string | undefined)[];
    labelWidthsForPdf?: number[];
    firstOfPriority?: { id: string; index: number; priority: string }[];
}

const JobsReportPDF: React.FC<JobsReportPDFProps> = ({ onCompleted, title, subTitle, graphData, filteredItems, itemsById, categories, labelWidthsForPdf, firstOfPriority }) => {
    const vessels = sharedState.vessels.use();
    const { selectedVesselId, selectedVesselIds } = useReportSettings();
    const counterRef = React.useRef(0);
    const { mapPdfArrayWithLimit } = PdfPageLimiter(counterRef);

    const getColorFromPriority = (priority?: string) => {
        if (!priority) {
            return reporting.colours.jobPrioritiesBg[3];
        }
        switch (priority) {
            case '8urgent':
                return '#' + reporting.colours.jobPrioritiesBg[0];
            case '6high':
                return '#' + reporting.colours.jobPrioritiesBg[1];
            case '4medium':
                return '#' + reporting.colours.jobPrioritiesBg[2];
            case '2low':
                return '#' + reporting.colours.jobPrioritiesBg[3];
            case '0shipyard':
                return '#' + reporting.colours.jobPrioritiesBg[4];
        }
    };

    const MakePDF = useCallback(() => {
        counterRef.current = 0;
        return (
            <Document title={title} author="Sea Flux" creator="Sea Flux" producer="Sea Flux">
                <Page
                    style={{
                        ...pdfStyles.page,
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader heading={title} subHeading={subTitle} userFullName={renderFullName()} />
                    {vessels && selectedVesselIds?.length > 1 ? (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Vessels</Text>
                                <Text>{selectedVesselIds.map((id: string) => vessels.byId[id].name).join(', ')}</Text>
                            </View>
                        </View>
                    ) : null}

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <SeaHorizontalStackedBarGraph mode="pdf" data={graphData} n={0} visible={true} categories={categories} colourPalette={reporting.colours.jobPriorities} labelWidthsForPdf={labelWidthsForPdf} />
                        </View>
                    </View>

                    <SeaNoDataPDF dataName="maintenance tasks" hasNoData={!filteredItems?.length} isUsingFilter={true} />
                    {filteredItems?.length ? (
                        <View style={pdfStyles.dataContainer}>
                            <View style={pdfStyles.dataHeaderRow}>
                                <View style={pdfStyles.thumbColumn}></View>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2x3]}>Task</Text>
                                {!selectedVesselId ? <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Vessel</Text> : null}
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.dateColumn]}>Due Date</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.criticalColumn]}>Critical</Text>
                            </View>
                            {mapPdfArrayWithLimit(filteredItems, (item, index) => {
                                const file = item.files && item.files.length ? item.files[0] : itemsById?.[item.equipmentId]?.files && itemsById[item.equipmentId].files.length > 0 ? itemsById[item.equipmentId].files[0] : undefined;
                                const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                const bgColor = getColorFromPriority(item.priority);
                                const isUrgent = item.priority === '8urgent';
                                const color = isUrgent ? '#fff' : '#000';
                                let showPriority = false;
                                if (firstOfPriority?.some((f) => f.index === index)) {
                                    showPriority = true;
                                }
                                return (
                                    <>
                                        {showPriority ? (
                                            <View style={[pdfStyles.categoryHeading, isUrgent ? { marginTop: 0 } : {}]}>
                                                <Text style={pdfStyles.historyGroupHeaderText}>{formatValue(item?.priority && item?.priority.charAt(1).toUpperCase() + item?.priority.slice(2))}</Text>
                                            </View>
                                        ) : null}
                                        <View wrap={false} style={[pdfStyles.greyDataRow, { backgroundColor: bgColor, color: color }]}>
                                            <View style={pdfStyles.thumbColumn}>{imgSrc ? <Image src={imgSrc} style={pdfStyles.thumbImgRound} /> : null}</View>
                                            <Text style={[pdfStyles.truncate, pdfStyles.column2x3, pdfStyles.bold]}>{formatValue(item.task)}</Text>
                                            {!selectedVesselId ? <Text style={[pdfStyles.truncate, pdfStyles.column1]}>{formatValue(vessels?.byId[item.vesselId]?.name)}</Text> : null}
                                            <Text style={pdfStyles.dateColumn}>{formatDateShort(item.dateDue)}</Text>
                                            <View style={pdfStyles.criticalColumn}>{itemsById?.[item.equipmentId]?.isCritical ? <CriticalFlagPDF hasFault={isUrgent} /> : null}</View>
                                        </View>
                                    </>
                                );
                            })}
                            <UnloadedPdfRows length={filteredItems?.length} />
                        </View>
                    ) : null}
                </Page>
            </Document>
        );
    }, [title, subTitle, vessels, selectedVesselIds, graphData, categories, labelWidthsForPdf, filteredItems, selectedVesselId, mapPdfArrayWithLimit, itemsById, firstOfPriority]);

    return <SeaPdfGenerator onCompleted={onCompleted} pdfTitle={title} MakePdf={MakePDF} requestedFrom="jobsReport" />;
};

export default JobsReportPDF;
