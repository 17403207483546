import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate, formatSeaDatetime, subtractInterval, addInterval } from '../../../../lib/datesAndTime';
import { renderCategoryName } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { SafetyEquipmentItem } from '../../../../shared-state/VesselSafety/safetyEquipmentItems';
import { SafetyEquipmentTaskCompleted } from '../../../../shared-state/VesselSafety/useCompletedSafetyEquipmentItems';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface CompleteSafetyEquipmentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setShowParentModal: (showModal: boolean) => void,
    historyItemToUpdate: SafetyEquipmentTaskCompleted | undefined,
    selectedItem: SafetyEquipmentItem,
    completedSafetyItems?: SafetyEquipmentTaskCompleted[],
    level?: number
}

const CompleteSafetyEquipment: React.FC<CompleteSafetyEquipmentProps> = ({showModal, setShowModal, setShowParentModal, historyItemToUpdate, selectedItem, completedSafetyItems, level}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (historyItemToUpdate) {
            return {
                whenCompleted: historyItemToUpdate.whenCompleted ? formatSeaDatetime(historyItemToUpdate.whenCompleted) : '',
                dateExpires: (selectedItem?.type === 'expiring') ? historyItemToUpdate.dateExpires : '',
                notes: historyItemToUpdate.notes ? ''+historyItemToUpdate.notes : '',
                image: '',
            };
        } else {
            return {
                whenCompleted: formatSeaDatetime(),
                dateExpires: (selectedItem?.type === 'expiring') ? formatSeaDate() : '',
                notes: '',
                image: '',
            };
        }
    }, [historyItemToUpdate, selectedItem?.type]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(historyItemToUpdate?.files));
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues, 
        validationSchema: Yup.object({
            whenCompleted: Yup.date().required().min(...notTooOld),
            dateExpires: selectedItem?.type === 'expiring' ? Yup.date().min(formatSeaDate()).required().min(...notTooOld) : Yup.date().min(...notTooOld),
            notes: Yup.string().max(5000),
            // image
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('completeSafetyEquipment')) { return; }
            if (!vesselId) {
                throw new Error("No vesselId");
            }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {

                let newWhenExpires = undefined;
                let dateDue = undefined;
                let whenLastChecked = undefined;
                if (selectedItem.type === 'servicable') {
                    // Determine whenLatestCompleted
                    let whenLatestCompleted = toMillis(data.whenCompleted) as number;
                    if (historyItemToUpdate && historyItemToUpdate.whenCompleted === selectedItem.whenLastChecked) {
                        // History item being edited is the latest one,
                        // so it's possible the second most recent one (if it exists) is now the latest
                        if (completedSafetyItems && completedSafetyItems.length > 1) {
                            whenLatestCompleted = Math.max(whenLatestCompleted, completedSafetyItems[1].whenCompleted);
                        }
                    } else {
                        // The most recent completed could still be the latest
                        whenLatestCompleted = Math.max(whenLatestCompleted, selectedItem.whenLastChecked || 0);
                    }
                    whenLastChecked = whenLatestCompleted;
                    dateDue = addInterval(whenLatestCompleted, selectedItem.interval).toISODate();
                } else { // Must be type=expiring
                    newWhenExpires = data.dateExpires;
                    // Because newWhenExpires cannot be in the past, let's just assume it is the new dateExpires for the equipment itself
                    // Todo: Perhaps this is wrong???
                    dateDue = formatSeaDate(newWhenExpires);
                }

                let dateToRemind = undefined;
                if (dateDue && selectedItem.emailReminder) {
                    dateToRemind = subtractInterval(dateDue, selectedItem.emailReminder).toISODate();
                }

                const batch = splittableBatch(firestore, 20 - 1); // -1 because safetyEquipmentTaskCompleted requires an extra security access call
                const batchTrace = makeBatchTrace(batch, 'safetyEquipmentTaskCompleted');
                
                batch.set(
                    doc(firestore, 'safetyEquipmentItems', selectedItem.id),
                    {
                        whenLastChecked: whenLastChecked,
                        dateDue: dateDue,
                        dateToRemind: dateToRemind,
                        touched: serverTimestamp(),
                    },
                    { merge: true }
                );

                if (historyItemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = historyItemToUpdate.id;
                    batch.set(
                        doc(firestore, 'safetyEquipmentTaskCompleted', historyItemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            notes: data.notes ? data.notes : deleteValue,
                            whenCompleted: toMillis(data.whenCompleted), 
                            dateExpires: newWhenExpires,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );
                    saveFileRefs(batch, files, 'safetyEquipmentTaskCompleted', historyItemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'safetyEquipmentTaskCompleted',
                        historyItemToUpdate.id,
                        renderCategoryName(selectedItem?.itemId, vesselSafetyItems),
                        [selectedItem.vesselId]
                    );
                } else {
                    const newRef = doc(collection(firestore, 'safetyEquipmentTaskCompleted'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    batch.set(newRef, {
                        safetyEquipmentId: selectedItem.id,
                        completedBy: userId,
                        vesselId: vesselId,
                        whenAdded: batchTrace.whenAction,
                        addedBy: userId,
                        notes: data.notes ? data.notes : undefined,
                        whenCompleted: toMillis(data.whenCompleted),
                        dateExpires: newWhenExpires,
                        files: seaFilesToValue(files),
                        state: 'active',
                        touched: serverTimestamp(),
                    });
                    saveFileRefs(batch, files, 'safetyEquipmentTaskCompleted', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'safetyEquipmentTaskCompleted',
                        newRef.id,
                        renderCategoryName(selectedItem?.itemId, vesselSafetyItems),
                        [vesselId]
                    );
                }

                onCollectionUpdated(batch, 'safetyEquipmentItems');
                onCollectionUpdated(batch, 'safetyEquipmentTaskCompleted');

                batchTrace.data = {
                    data,
                    files: seaFilesToValue(files),
                    selectedItem,
                    historyItemToUpdate
                };
                batchTrace.save(`${historyItemToUpdate ? 'Update' : 'Create'} completed safety equipment expiry check ${renderCategoryName(selectedItem?.itemId, vesselSafetyItems)}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
                if (!historyItemToUpdate) {
                    setTimeout(() => {
                        setShowParentModal(false);
                    }, 250);
                }
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload Completed Safety Equipment Expiry files`, error.message, error, {
                        historyItemToUpdate,
                        data,
                        files: seaFilesToValue(files),
                        selectedItem
                    });
                }
            });

        }
    });
    
    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [initialValues, values]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={historyItemToUpdate ? 'Edit Completed Safety Task' : 'Complete Safety Task'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='thin'
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaDatetime
                                label="When Completed"
                                name="whenCompleted"
                                value={values.whenCompleted}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenCompleted ? errors.whenCompleted : ''}
                            />
                        </IonCol>
                        {selectedItem?.type === 'expiring' && 
                            <IonCol size="12">
                                <SeaDate
                                    label="New Expiry Date"
                                    name="dateExpires"
                                    value={values.dateExpires}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    error={touched.dateExpires ? errors.dateExpires : ''}
                                />
                            </IonCol>
                        }
                        <IonCol size="12">
                            <SeaTextarea
                                label="Notes"
                                placeholder="Add notes..."
                                name="notes"
                                value={values.notes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                height={150}
                                inputmode="text"
                                error={touched.notes ? errors.notes : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="safetyEquipmentTaskCompleted"
                                field="files"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" size="wide" type="submit">{historyItemToUpdate ? 'Update Completed Task' : 'Save Completed Task'}</SeaButton>
               </div>
            </form>
        </SeaModal>
    );
};

export default CompleteSafetyEquipment;
