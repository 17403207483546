import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { canEdit, canDelete } from '../../../../shared-state/Core/userPermissions';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { UserType } from '../../../../shared-state/Core/user';
import { UserDocument } from '../../../../shared-state/Crew/useCrewDocuments';
import SeaMultiSelect, { OptionType } from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import Yup from '../../../../lib/yup'
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaInputError from '../../../../components/SeaInputError/SeaInputError';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';

interface EditUserDocumentsModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedUser: UserType,
    itemToUpdate: UserDocument | undefined,
    level?: number
}

const EditUserDocumentsModal: React.FC<EditUserDocumentsModalProps> = ({
    showModal,
    setShowModal,
    selectedUser,
    itemToUpdate,
    level
}) => {
    const userId = sharedState.userId.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [selectedVesselIds, setSelectedVesselIds] = useState<string[]>();
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                name: itemToUpdate.name ? ''+itemToUpdate.name : ''
            };
        } else {
            return {
                name: ''
            };
        }
    }, [itemToUpdate]);

    const vesselOptions = useMemo(() => {
        if (!vessels?.all) return [];

        const result: OptionType[] = vessels.all
            .map(vessel => ({id: vessel.id, name: vessel.name}))
            .sort((a, b) => a.name.localeCompare(b.name));

        return result;
    }, [vessels]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
        if (itemToUpdate?.vesselIds) {
            setSelectedVesselIds(itemToUpdate.vesselIds);
        } else {
            setSelectedVesselIds([]);
        }
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isSubmitting } = useFormik({
        initialValues: initialValues, 
        validationSchema: Yup.object({
            name: Yup.string().max(500).required()
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('userDocuments') || files === undefined || files.length === 0) {
                return;
            }

            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                if (!selectedUser || !selectedUser.id) {
                    reportError('No selected user', 'No selected user');
                    return;
                }
                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'userDocuments');

                if (itemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'userDocuments', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            name: data.name,
                            files: seaFilesToValue(files),
                            vesselIds: (selectedVesselIds && selectedVesselIds.length > 0) ? selectedVesselIds : deleteValue,
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );

                    logAction(
                        batch,
                        'Update',
                        'userDocuments',
                        itemToUpdate.id,
                        `${data.name} - ${renderFullNameForUserId(itemToUpdate.userId)}`,
                        (selectedVesselIds && selectedVesselIds.length > 0) ? selectedVesselIds : undefined,
                        [selectedUser.id]
                    );

                    saveFileRefs(batch, files, 'userDocuments', itemToUpdate.id);
                } else {
                    const newRef = doc(collection(firestore, 'userDocuments'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    batch.set(newRef, {
                        licenseeId: selectedUser.licenseeId,
                        userId: selectedUser.id,
                        whenAdded: batchTrace.whenAction,
                        addedBy: userId,
                        name: data.name ? data.name : undefined,
                        files: seaFilesToValue(files),
                        vesselIds: (selectedVesselIds && selectedVesselIds.length > 0) ? selectedVesselIds : undefined,
                        state: 'active',
                        touched: serverTimestamp()
                    });
                    saveFileRefs(batch, files, 'userDocuments', newRef.id);

                    logAction(
                        batch,
                        'Add',
                        'userDocuments',
                        newRef.id,
                        `${data.name} - ${renderFullNameForUserId(selectedUser.id)}`,
                        (selectedVesselIds && selectedVesselIds.length > 0) ? selectedVesselIds : undefined,
                        [selectedUser.id]
                    );
                }

                onCollectionUpdated(batch, 'userDocuments');

                batchTrace.data = {
                    data,
                    selectedVesselIds,
                    files: seaFilesToValue(files),
                    itemToUpdate
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} userDocuments ${selectedUser?.id}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload userDocuments`, error.message, error, {
                        files: seaFilesToValue(files),
                        itemToUpdate,
                        data
                    });
                }
            });

        }
    });

    useEffect(() => {
        if (files && files.length > 0) {
            if (
                (values.name === undefined || values.name.length === 0) &&
                files[0].name &&
                files[0].name.length > 0
            ) {
                let s = files[0].name;
                if (s.indexOf('.') > 0) {
                    s = s.substring(0, s.lastIndexOf('.'));
                }
                setFieldValue('name', s);
            }
        }
    }, [files, setFieldValue]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [initialValues, values, files, itemToUpdate?.files]);

    const onDeleteUserDocuments = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();
        if (!itemToUpdate) {
            return;
        }
        confirmAction('Are you sure you want to delete this', 'Yes, delete').then(() => {
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'userDocuments', 'delete', itemToUpdate.id);

            batch.set(
                doc(firestore, 'userDocuments', itemToUpdate.id),
                {
                    state: 'deleted',
                    deletedBy: userId,
                    whenDeleted: batchTrace.whenAction,
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            logAction(
                batch,
                'Delete',
                'userDocuments',
                itemToUpdate.id,
                `${itemToUpdate.name} - ${renderFullNameForUserId(itemToUpdate.userId)}`,
                itemToUpdate.vesselIds,
                [itemToUpdate.userId]
            );

            onCollectionUpdated(batch, 'userDocuments');

            batchTrace.data = {itemToUpdate};
            batchTrace.save(`Delete user document ${itemToUpdate?.id}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            setShowModal(false);
        }).catch(() => {});
    };

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit User Documents' : 'Add User Documents'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='thin'
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="userDocuments"
                                field="files"
                            />
                            {hasSubmitted && (files === undefined || files.length === 0) &&
                                <SeaInputError alignLeft={true}>
                                    Please choose at least one image/document to upload
                                </SeaInputError>
                            }
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                label="Name / Description"
                                //placeholder="Add notes..."
                                name="name"
                                value={values.name}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.name ? errors.name : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaMultiSelect
                                mode="popover"
                                label="SELECT VESSELS / FACILITIES"
                                values={selectedVesselIds}
                                setValues={setSelectedVesselIds}
                                options={vesselOptions}
                                useAllOption={vesselOptions && vesselOptions.length > 1}
                                isSubmitting={hasSubmitted}
                                emptyText="Not Set"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                {(canEdit('crewParticulars') || selectedUser?.id === userId) &&
                    <div className="view-modal-buttons">
                        <SeaButton zone="white" size="wide" type="submit">
                            {itemToUpdate ? 'Update Documents' : 'Save Documents'}
                        </SeaButton>
                        {itemToUpdate && ((selectedUser?.id === userId) || canDelete('crewParticulars')) &&
                            <>
                                <div className="spacer-wide"></div>
                                <SeaLinkButton mode="standard-link" onClick={(e) => onDeleteUserDocuments(e)}>Delete</SeaLinkButton>
                            </>
                        }
                    </div>
                }
            </form>
        </SeaModal>
    );
};

export default EditUserDocumentsModal;
