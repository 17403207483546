import React, { useRef, useState } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { addInterval, subtractInterval } from '../../lib/datesAndTime';
import SeaDateRange, { DateRange } from '../SeaDateRange/SeaDateRange';
import SeaModal from '../SeaModal/SeaModal';
import SeaButton from '../SeaButton/SeaButton';

type SeaExporterFilterProps = {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    setDateRange: (dateRange?: DateRange) => void;
    onClose: () => void; // Required as this is used to set exportType to undefined
    type?: 'csv' | 'pdf';
    hideDateRange?: boolean;
    level?: number;
    disabled?: boolean;
    children?: React.ReactNode;
}

const SeaExportFilterModal = ({showModal, setShowModal, setDateRange, onClose, type = 'csv', hideDateRange, level = 1, disabled, children}: SeaExporterFilterProps) => {
    const today = sharedState.today.use()!;
    const isSubmitted = useRef(false);
    const [localDateRange, setLocalDateRange] = useState<DateRange>({
        from: subtractInterval(today, '1y').toISODate(),
        to: addInterval(today, '1d').toISODate()
    });

    const onSubmit = () => {
        isSubmitted.current = true;
        setDateRange(hideDateRange ? undefined : localDateRange);
        setShowModal(false);
    }

    return (
        <SeaModal
            title={`Export ${type === 'csv' ? 'CSV' : 'PDF'}`}
            showModal={showModal}
            setShowModal={setShowModal}
            size="thin"
            noContentPadding={false}
            onClosed={() => {
                // ref is needed to determine whether the modal has been closed by the user or by the onSubmit function
                if (!isSubmitted) {
                    onClose();
                }
            }}
            onOpened={() => {
                isSubmitted.current = false;
            }}
            level={level}
        >
             <IonGrid>
                {!hideDateRange && <IonRow>
                    <IonCol>
                        <SeaDateRange
                            label="Date Range"
                            zone="white"
                            dateRange={localDateRange}
                            setDateRange={setLocalDateRange}
                        />
                    </IonCol>
                </IonRow>}
                {children && (
                    <IonRow>
                        <IonCol>
                            {children}
                        </IonCol>
                    </IonRow>
                )}
                <IonRow>
                    <IonCol>
                        <div className="view-modal-buttons">
                            <SeaButton disabled={disabled} onClick={onSubmit} size='wide'>
                                Export
                            </SeaButton>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </SeaModal>
    )
}

export default SeaExportFilterModal