// Make a Luxon DateTime object from either epoch millis or an ISO formatted string
import { DateTime } from 'luxon';
import { toInt } from './util';

// Need to keep in sync with /firebase/functions/common/util.js
export const warnDays = {
    crewTraining: [7],
    crewCertificates: [60, 90, 180],
    drills: [7],
    riskRegister: [30],
    healthSafetyMeetings: [5],
    dangerousGoods: [7],
    maintenanceSchedule: [7, 30, 60, 90],
    safetyEquipmentChecks: [7, 30],
    safetyEquipmentExpiries: [60, 90, 180],
    vesselCertificates: [60, 90, 180],
    vesselDocuments: [60],
    companyDocuments: [60],
    companyPlan: [60],
};

export type DateTimeAcceptable = string | number | DateTime | Date | null;

export const MAX_DATE = '9999-99-99'; // Useful for sorting
export const MIN_DATE = '0000-00-00'; // Useful for sorting

// If undefined or null, will return the current time
export const makeDateTime = (date?: DateTimeAcceptable): DateTime => {
    if (date) {
        if (typeof date === 'number') {
            return DateTime.fromMillis(date);
        }
        if (typeof date === 'string') {
            return DateTime.fromISO(date);
        }
        if (date instanceof DateTime) {
            return date;
        }
    }
    return DateTime.now();
};
// Format epoch millis (or ISO date) into the format required by SeaDate component
export const formatSeaDate = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toISODate();
};
// Format epoch millis (or ISO date) into the format required by SeaDatetime component
export const formatSeaDatetime = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toISO().substring(0, 16); // (don't want anything after minutes)
};
export const formatDatetimeISO = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toISO();
};
// Format epoch millis (or ISO date) into something like 3 Apr 21
export const formatDate = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('dd LLL yyyy');
};

export const formatDateShort = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('dd LLL yy');
};
export const formatDateLonger = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('dd LLLL yyyy');
};
export const formatDatetime = (date?: DateTimeAcceptable, inbetween = '\n') => {
    return makeDateTime(date).toFormat('dd LLL yyyy' + inbetween + 'HH:mm');
};
// Helper function to get the date suffix
const getDateSuffix = (day: number): string => {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

export const formatDayAndtime = (date?: DateTimeAcceptable, includeSuffix = false) => {
    const dateTime = makeDateTime(date);
    const day = dateTime.toFormat('d');
    const suffix = includeSuffix ? getDateSuffix(parseInt(day)) : '';
    return dateTime.toFormat(`d'${suffix}' HH:mm`);
};

export const formatMonthDayTime = (date?: DateTimeAcceptable) => {
    const dateTime = makeDateTime(date);
    return dateTime.toFormat('dd LLL, HH:mm');
};

export const formatDatetimeLonger = (date?: DateTimeAcceptable, inbetween = '\n') => {
    return makeDateTime(date).toFormat('dd LLLL yyyy' + inbetween + 'HH:mm');
};
export const formatDatetimeForPdf = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('HH:mm, d LLL yyyy');
};
export const formatTime = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('HH:mm');
};
export const formatTimeWithMillis = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('HH:mm:ss.SSS');
};
export const formatTime24Hour = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('HH:mm');
};
export const formatShortTimeDuration = (millis: number, includeHours = false) => {
    let s = '';
    if (includeHours) {
        const hours = Math.floor(millis / (60 * 60 * 1000));
        if (hours > 0) {
            s += hours + ':';
            millis -= hours * 60 * 60 * 1000;
        }
    }
    const min = Math.floor(millis / (60 * 1000));
    millis -= min * 60 * 1000;
    const seconds = Math.floor(millis / 1000);
    if (min < 10) {
        s += '0';
    }
    s += min;
    s += ':';
    if (seconds < 10) {
        s += '0';
    }
    s += seconds;
    return s;
};
export const formatTimeDuration = (millis: number) => {
    //let s = `(${millis})`;
    const isNegative = millis < 0 ? true : false;
    if (isNegative) {
        millis = -millis;
    }
    let s = '';
    const hours = Math.floor(millis / (60 * 60 * 1000));
    millis -= hours * 60 * 60 * 1000;
    if (hours < 10) {
        s += '0';
    }
    s += hours;
    s += ':';
    const minutes = Math.floor(millis / (60 * 1000));
    millis -= minutes * 60 * 1000;
    //s += `(${minutes})`;
    if (minutes < 10) {
        s += '0';
    }
    s += minutes;
    s += ':';
    const seconds = Math.floor(millis / 1000);
    //s += `(${seconds})`;
    millis -= seconds * 1000;
    if (seconds < 10) {
        s += '0';
    }
    s += seconds;
    s += '.';
    if (millis < 100) {
        s += '0';
    }
    if (millis < 10) {
        s += '0';
    }
    s += millis;
    s = (isNegative ? '-' : '+') + s;
    return s;
};
export const formatDatetimeNatural = (date?: DateTimeAcceptable) => {
    const now = makeDateTime();
    const t = makeDateTime(date);
    if (t.get('year') !== now.get('year')) {
        return `${t.toFormat('HH:mm')} on ${t.toFormat('dd LLL yyyy')}`;
    } else {
        const days = makeDateTime(t.toISODate()).diff(makeDateTime(now.toISODate()), ['days']).days;
        if (days === 0) {
            return `today at ${t.toFormat('HH:mm')}`;
        } else if (days === 1) {
            return `tomorrow at ${t.toFormat('HH:mm')}`;
        } else if (days === -1) {
            return `yesterday at ${t.toFormat('HH:mm')}`;
        } else {
            return `${t.toFormat('HH:mm')} on ${t.toFormat('dd LLL')}`;
        }
    }
};
// Format epoch millis (or ISO date) into something like 3 Apr 21
export const formatDateSimplify = (date?: DateTimeAcceptable) => {
    const s = formatDate(date);
    if (s === getToday()) {
        return 'Today';
    } else if (s === formatDate(addInterval(makeDateTime(), '-1d'))) {
        return 'Yesterday';
    }
    if (makeDateTime(date).toFormat('yy') === makeDateTime().toFormat('yy')) {
        return makeDateTime(date).toFormat('dd LLL');
    }
    return makeDateTime(date).toFormat('dd LLL yy');
};
export const formatDueDateStatus = (dateDue?: DateTimeAcceptable) => {
    const due = makeDateTime(dateDue);
    const days = due.diff(makeDateTime(), ['days']).days;
    let text = '';
    if (days === 0) {
        text = 'Due Today';
    } else if (days < 0) {
        text = `${-days} day${days < -1 ? 's' : ''} OD`;
    } else {
        text = `${days} day${days > 1 ? 's' : ''}`;
    }
    return text;
};

// Format epoch millis (or ISO date) into something like Apr 2021
export const formatMonth = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('LLL yyyy');
};
export const formatMonthLonger = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('LLLL yyyy');
};

// Format epoch millis (or ISO date) into something like 2021 01
export const formatMonthISO = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('yyyy-MM') + '-01';
};

// Format epoch millis (or ISO date) into something like 2021
export const formatYear = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('yyyy');
};

// Format epoch millis (or ISO date) into something like Mon 21
export const formatDay = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('ccc d');
};

//
export const formatDateRange = (from?: DateTimeAcceptable, to?: DateTimeAcceptable) => {
    const fromDate = makeDateTime(from);
    const toDate = makeDateTime(to);
    if (fromDate.year === toDate.year) {
        if (fromDate.month === toDate.month) {
            if (fromDate.day === toDate.day) {
                return `${toDate.toFormat('dd LLL yyyy')}`;
            }
            return `${fromDate.toFormat('dd')} - ${toDate.toFormat('dd LLL yyyy')}`;
        }
        return `${fromDate.toFormat('dd LLL')} - ${toDate.toFormat('dd LLL yyyy')}`;
    }
    return `${fromDate.toFormat('dd LLL yyyy')} - ${toDate.toFormat('dd LLL yyyy')}`;
};

export const hours24ToMillis = (hours24: string) => {
    if (hours24) {
        const a = hours24.split(':');
        if (a.length > 1) {
            return toInt(a[0], 0) * 60 * 60 * 1000 + toInt(a[1], 0) * 60 * 1000;
        } else if (a.length === 1) {
            return toInt(a[0], 0) * 60 * 60 * 1000;
        }
    }
    return 0;
};
export const combineDateAndHours24 = (dateMillis: number, hours24: string) => {
    return dateMillis + hours24ToMillis(hours24);
};

// Add an interval to a date. Return epoch millis.
// TODO! remove these after conversion
export const addIntervalMillis = (date: DateTimeAcceptable, interval?: string): number => {
    if (!interval) {
        return makeDateTime(date).toMillis();
    }
    return makeDateTime(date).plus(convertInterval(interval)).toMillis();
};
export const subtractIntervalMillis = (date: DateTimeAcceptable, interval: string): number => {
    return makeDateTime(date).minus(convertInterval(interval)).toMillis();
};
// Add an interval to a date. Return epoch millis.
export const addInterval = (date: DateTimeAcceptable, interval?: string): DateTime => {
    if (!interval) {
        return makeDateTime(date);
    }
    return makeDateTime(date).plus(convertInterval(interval));
};
export const subtractInterval = (date: DateTimeAcceptable, interval: string): DateTime => {
    return makeDateTime(date).minus(convertInterval(interval));
};

/**
 * Returns millis for a particular day. Defaults to today.
 * For tomorrow you would use offsetDays = 1
 */
export const getDayOffsetMillis = (offsetDays = 0): number => {
    return DateTime.fromISO(makeDateTime().toISODate()).toMillis() + offsetDays * 24 * 60 * 60 * 1000;
};

/**
 * Returns ISO date yyyy-MM-dd for a particular day. Defaults to today.
 * For tomorrow you would use offsetDays = 1.
 * For yesterday you would use offsetDays = -1.
 */
export const getDayOffset = (offsetDays = 0): string => {
    return DateTime.now().plus({ days: offsetDays }).toISODate();
};
export const getToday = () => {
    return DateTime.now().toISODate();
};

/**
 * Given dateDue, return 'fail' or 'warn' or '' depending on relation to today
 */
export const dateDueToClassName = (dateDue: DateTimeAcceptable | undefined, warnDays: number) => {
    const due = makeDateTime(dateDue);
    const days = due.diff(makeDateTime(), ['days']).days;
    if (days < 0) {
        return 'fail';
    } else if (days < warnDays) {
        return 'warn';
    }
    return '';
};

// Convert dates returned from SeaDate or SeaDatetime components into epoch millis that firestore wants
export const toMillis = (date?: string) => {
    if (date) {
        return DateTime.fromISO(date).toMillis();
    }
    return 0;
};

// interval can be anything listed as an option under <SeaSelectInterval> component
export const convertInterval = (interval: string) => {
    const x = toInt(interval.substring(0, interval.length - 1), 0);
    const t = interval.substring(interval.length - 1);
    switch (t) {
        case 'w':
            return { weeks: x };
        case 'm':
            return { months: x };
        case 'd':
            return { days: x };
        case 'y':
            return { years: x };
    }
    return {};
};
// This should be kept in sync with firebase/functions/src/lib/datesAndTime.js formatInterval
export const formatInterval = (interval?: string) => {
    if (!interval) {
        return '';
    }
    switch (interval) {
        case '1d':
            return 'Daily';
        case '7d':
            return 'Weekly';
        case '14d':
            return 'Fortnightly';
        case '5w':
            return '5 Weekly';
        case '1m':
            return 'Monthly';
        case '2m':
            return '2 Monthly';
        case '3m':
            return '3 Monthly';
        case '4m':
            return '4 Monthly';
        case '5m':
            return '5 Monthly';
        case '6m':
            return '6 Monthly';
        case '9m':
            return '9 Monthly';
        //case '2m': return 'Bimonthly';
        //case '3m': return 'Quarterly';
        //case '6m': return 'Half-Yearly';
        case '12m':
            return 'Annually';
        case '18m':
            return '18 Monthly';
        case '24m':
            return '2 Yearly';
        case '30m':
            return '2.5 Yearly';
        case '36m':
            return '3 Yearly';
        case '48m':
            return '4 Yearly';
        case '60m':
            return '5 Yearly';
        case '72m':
            return '6 Yearly';
        case '96m':
            return '8 Yearly';
        case '120m':
            return '10 Yearly';
        case '180m':
            return '15 Yearly';
    }
    return '';
};
export const formatEmailReminder = (interval?: string) => {
    if (!interval) {
        return '';
    }
    switch (interval) {
        case '0d':
            return 'On the day';
        case '1d':
            return '1 Day before';
        case '2d':
            return '2 Days before';
        case '3d':
            return '3 Days before';
        case '7d':
            return '1 Week before';
        case '14d':
            return '2 Weeks before';
        case '1m':
            return '1 Month before';
        case '2m':
            return '2 Months before';
        case '3m':
            return '3 Months before';
        case '6m':
            return '6 Months before';
    }
    return '';
};

/**
 * Calculates number of days between two dates
 */
export const dayDifferenceBetweenDates = (startDate: any, endDate: any): number => {
    const start = DateTime.fromISO(formatSeaDate(startDate));
    const end = DateTime.fromISO(formatSeaDate(endDate));
    const difference: any = end.diff(start, ['days']);
    return difference.values.days + 1;
};

export const engineHoursLeftToClassName = (engineHoursLeft: number) => {
    if (engineHoursLeft < 0) {
        return 'fail';
    } else if (engineHoursLeft < 50) {
        return 'warn';
    }
    return '';
};

// Format millis into 00:00
export const formatShortTimeDurationHrsMinsOnly = (millis: number) => {
    let s = '';
    const hours = Math.floor(millis / (60 * 60 * 1000));
    s += hours + ':';
    millis -= hours * 60 * 60 * 1000;
    const min = Math.floor(millis / (60 * 1000));
    millis -= min * 60 * 1000;
    if (min < 10) {
        s += '0';
    }
    s += min;
    return s;
};
// Format millis into 0hrs 0mins
export const formatShortTimeDurationHrsMinsView = (millis: number | undefined, includeHours = false) => {
    if (millis === undefined) {
        return '';
    }
    let s = '';
    const hours = Math.floor(millis / (60 * 60 * 1000));
    if (hours > 0) {
        s += hours + (hours === 1 ? 'hr ' : 'hrs ');
        millis -= hours * 60 * 60 * 1000;
    } else if (includeHours) {
        s += '0hr ';
    }
    const min = Math.floor(millis / (60 * 1000));
    millis -= min * 60 * 1000;
    if (min > 0) {
        s += min + (min === 1 ? 'min' : 'mins');
    }
    return s;
};
