import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc } from "firebase/firestore";
import { haveValuesChanged } from '../../../../lib/util';
import { formatSeaDate, subtractInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { markVesselOverdueStatStale } from '../../../../shared-state/DataSyncSystem/dataSync';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaSelectEmailReminder from '../../../../components/SeaSelectEmailReminder/SeaSelectEmailReminder';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditHealthSafetyMeetingSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    defaultVesselId?: string,
    setFilterVesselId: (vesselId: string) => void
}

const EditHealthSafetyMeetingSettings: React.FC<EditHealthSafetyMeetingSettingsProps> = ({
    showModal,
    setShowModal,
    defaultVesselId,
    setFilterVesselId
}) => {
    const userId = sharedState.userId.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const vessel = useMemo(() => {
        if (
            defaultVesselId &&
            vessels?.byId &&
            vessels.byId[defaultVesselId]
        ) {
            return vessels.byId[defaultVesselId];
        }
        return undefined;
    }, [defaultVesselId, vessels]);

    const initialValues = useMemo(() => {
        if (vessel) {
            return {
                vesselId: vessel.id ? ''+vessel.id : '',
                interval: (vessel.safetyMeetingSettings?.interval) ? ''+vessel.safetyMeetingSettings.interval : '',
                dateDue: (vessel.safetyMeetingSettings?.dateDue) ? vessel.safetyMeetingSettings.dateDue : '',
                emailReminder: (vessel.safetyMeetingSettings?.emailReminder) ? ''+vessel.safetyMeetingSettings.emailReminder : '',
            };
        } else {
            return {
                vesselId: defaultVesselId ? defaultVesselId : '',
                interval: '',
                dateDue: formatSeaDate(),
                emailReminder: '',
            };
        }
    }, [vessel, defaultVesselId]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
    }

    // Update form fields when vessel is changed to match current values
    const onVesselIdChanged = (e: CustomEvent) => {
        if (e?.detail?.value) {
            setFieldValue('vesselId', e.detail.value);
            const newVessel = vessels?.byId[e.detail.value];
            if (newVessel && newVessel.safetyMeetingSettings) {
                console.log('newVessel', newVessel);
                if (newVessel.safetyMeetingSettings.interval) {
                    setFieldValue('interval', newVessel.safetyMeetingSettings.interval);
                }
                if (newVessel.safetyMeetingSettings.dateDue) {
                    setFieldValue('dateDue', newVessel.safetyMeetingSettings.dateDue);
                }
                if (newVessel.safetyMeetingSettings.emailReminder) {
                    setFieldValue('emailReminder', newVessel.safetyMeetingSettings.emailReminder);
                }
            }
        } else {
            setFieldValue('vesselId', '');
        }
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            vesselId: Yup.string().required(),
            interval: Yup.string().max(200).required(),
            dateDue: Yup.date().required().min(...notTooOld),
            emailReminder: Yup.string().max(200),
        }), onSubmit: (data) => {
            // Process form
            setHasSubmitted(true);
            let dateToRemind = undefined;
            if (data.emailReminder) {
                dateToRemind = subtractInterval(data.dateDue, data.emailReminder).toISODate();
            };

            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'vessels', 'update', data.vesselId);

            batch.set(
                doc(firestore, 'vessels', data.vesselId),
                {
                    safetyMeetingSettings: {
                        interval: data.interval,
                        emailReminder: data.emailReminder,
                        dateDue: data.dateDue,
                        dateToRemind: dateToRemind
                    },
                    updatedBy: userId,
                    whenUpdated: batchTrace.whenAction
                },
                { merge: true }
            );

            markVesselOverdueStatStale(batch, 'safetyMeetings', data.vesselId);
            logAction(
                batch,
                'Update',
                'vessels',
                data.vesselId,
                vessels?.byId[data.vesselId]?.name ?? 'unkown vessel',
                [data.vesselId],
                undefined,
                'safetyMeetingSettings'
            );

            batchTrace.data = {vessel};
            batchTrace.save(`Update safety meeting settings for ${vessel?.name}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            setFilterVesselId(data.vesselId);
            setShowModal(false);
        }
    });

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [values, initialValues]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title='Meeting Settings'
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='thin'
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaSelect
                                label="Vessel / Facility"
                                name="vesselId"
                                zone="white"
                                value={values.vesselId}
                                onchange={onVesselIdChanged}
                                onblur={handleBlur}
                                error={touched.vesselId ? errors.vesselId : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {vessels?.all?.map((vessel) => {
                                    return (
                                        <IonSelectOption key={vessel.id} value={vessel.id}>{vessel.name}</IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="12">
                            <SeaSelectInterval
                                label="Interval"
                                name="interval"
                                value={values.interval}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.interval ? errors.interval : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaDate
                                label="Date of Next Meeting"
                                name="dateDue"
                                value={values.dateDue}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.dateDue ? errors.dateDue : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaSelectEmailReminder
                                label="Set Email Reminder"
                                name="emailReminder"
                                value={values.emailReminder}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.emailReminder ? errors.emailReminder : ''}
                                safetyMeetingOptions={true}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">Save Settings</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditHealthSafetyMeetingSettings;
