import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, arrayUnion, arrayRemove, serverTimestamp } from "firebase/firestore";
import { hasArrayChanged, haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { sharedState } from '../../../../shared-state/shared-state';
import { alertMessage } from '../../../../managers/AlertManager/AlertManager';
import { EquipmentManualDocument } from '../../../../shared-state/VesselDocuments/equipmentManualDocuments';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import SeaMultiSelect, { OptionType } from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import Yup from '../../../../lib/yup'
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditEquipmentManualDocumentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: EquipmentManualDocument,
}

const EditEquipmentManualDocument: React.FC<EditEquipmentManualDocumentProps> = ({showModal, setShowModal, itemToUpdate, level}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const equipment = sharedState.equipment.use(showModal);
    const [equipmentIds, setEquipmentIds] = useState<string[]>();
    const [initialEquipmentIds, setInitialEquipmentIds] = useState<string[]>();
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const equipmentOptions = useMemo(() => {
        if (showModal && equipment) {
            const options: OptionType[] = [];
            equipment.all.forEach((item) => {
                options.push({
                    id: item.id,
                    name: item.equipment
                });
            });
            return options;
        }
        return undefined;
    }, [showModal, equipment]);

    const initialValues = useMemo(() => {
        if (!itemToUpdate) {
            return {
                title: ''
            };
        } else {
            return {
                title: (itemToUpdate?.title) ? ''+itemToUpdate.title : ''
            };
        }
    }, [itemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
        // Collect equipment that refers to this manual
        setEquipmentIds(undefined);
        setInitialEquipmentIds(undefined);
        if (itemToUpdate && equipment?.all) {
            const _equipmentIds: string[] = [];
            equipment.all.forEach((item) => {
                if (item.equipmentDocumentIds && item.equipmentDocumentIds.indexOf(itemToUpdate.id) !== -1) {
                    _equipmentIds.push(item.id);
                }
            });
            setEquipmentIds(_equipmentIds);
            setInitialEquipmentIds([..._equipmentIds]);
        }
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting} = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().max(500).required()
        }), onSubmit: (data) => {
            if (files.length === 0) {
                alertMessage('You need to upload at least one file first!');
                return;
            }

            // Attempt upload first.... ?
            // if (files.length === 0) {
            //     return
            // }
            uploadFiles(files).then(() => {
                if (preventMultiTap('equipmentManualDocuments')) { return; }
                // Process form
                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'equipmentManualDocuments');

                let haveModifiedEquipment = false;
                if (itemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'equipmentManualDocuments', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            title: data.title,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'equipmentManualDocuments', itemToUpdate.id);

                    // Add/remove from equipment as necessary
                    equipment?.all?.forEach((item) => {
                        const itemHasManual = (item.equipmentDocumentIds && item.equipmentDocumentIds.indexOf(itemToUpdate.id) !== -1);
                        if (equipmentIds && equipmentIds.indexOf(item.id) !== -1) {
                            // Equipment should have this manual
                            if (!itemHasManual) {
                                batch.set(
                                    doc(firestore, 'equipment', item.id),
                                    {
                                        equipmentDocumentIds: arrayUnion(itemToUpdate.id),
                                        whenUpdated: batchTrace.whenAction,
                                        updatedBy: userId,
                                        touched: serverTimestamp(),
                                    },
                                    { merge: true }
                                );
                                haveModifiedEquipment = true;
                            }
                        } else {
                            // Equipment should NOT have this manual
                            if (itemHasManual) {
                                batch.set(
                                    doc(firestore, 'equipment', item.id),
                                    {
                                        equipmentDocumentIds: arrayRemove(itemToUpdate.id),
                                        whenUpdated: batchTrace.whenAction,
                                        updatedBy: userId,
                                        touched: serverTimestamp(),
                                    },
                                    { merge: true }
                                );
                                haveModifiedEquipment = true;
                            }
                        }
                    });

                    logAction(
                        batch,
                        'Update',
                        'equipmentManualDocuments',
                        itemToUpdate.id,
                        data.title,
                        [itemToUpdate.vesselId]
                    );
                } else {
                    const newRef = doc(collection(firestore, 'equipmentManualDocuments'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    batch.set(newRef, {
                        vesselId: vesselId,
                        addedBy: userId,
                        whenAdded: batchTrace.whenAction,
                        title: data.title,
                        files: seaFilesToValue(files),
                        state: 'active',
                        touched: serverTimestamp(),
                    });

                    // Add manual to all selected equipment
                    equipmentIds?.forEach((equipmentId: string) => {
                        batch.set(
                            doc(firestore, 'equipment', equipmentId),
                            {
                                equipmentDocumentIds: arrayUnion(newRef.id),
                                whenUpdated: batchTrace.whenAction,
                                updatedBy: userId,
                                touched: serverTimestamp(),
                            }, { merge: true }
                        );
                        haveModifiedEquipment = true;
                    });

                    saveFileRefs(batch, files, 'equipmentManualDocuments', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'equipmentManualDocuments',
                        newRef.id,
                        data.title,
                        [vesselId as string]
                    );
                }

                onCollectionUpdated(batch, 'equipmentManualDocuments');
                if (haveModifiedEquipment) {
                    onCollectionUpdated(batch, 'equipment');
                }

                batchTrace.data = {
                    data,
                    equipmentIds,
                    files: seaFilesToValue(files),
                    itemToUpdate
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} equipment manual document ${data?.title}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload equipment manual document files`, error.message, error, {
                        files: seaFilesToValue(files),
                        data,
                        itemToUpdate
                    });
                }
            });
        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            hasArrayChanged(equipmentIds, initialEquipmentIds) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [initialValues, values, itemToUpdate, initialEquipmentIds, equipmentIds, files]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? `Edit - ${itemToUpdate.title}` : 'Add New Equipment Manual Document'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                          	<SeaInput
								label="Document Title"
								name="title"
								value={values.title}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.title ? errors.title : ''}
                          	/>
                        </IonCol>
                        <IonCol size="12">
                            <SeaMultiSelect
                                label="Equipment"
                                values={equipmentIds}
                                setValues={setEquipmentIds}
                                options={equipmentOptions}
                                emptyText="Not Set"
                                mode="popover"
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Upload Document"
                                files={files}
                                setFiles={setFiles}
                                collection="equipmentManualDocuments"
                                field="files"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid && files.length > 0}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Manual' : 'Save Manual'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditEquipmentManualDocument;
