import { collection, onSnapshot, orderBy, query, where, or, and } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from "react";
import { DrillReport } from "./drillReports";

type DrillReportsData = {
    byId: { [id: string]: DrillReport };
    byDrillId: { [id: string]: DrillReport[] };
};

export const useDrillReportsForDrill = (
    isActive: boolean,
    drillId?: string,
    vesselId?: string,
) => {
    const [drillReports, setDrillReports] = useState<DrillReportsData>();

    useEffect(() => {
        setDrillReports(undefined);
        if (
            isActive &&
            drillId &&
            vesselId
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'drillReports'),
                    and(
                        where('vesselId', '==', vesselId),
                        where('state', '==', 'active'),
                        or(
                            where('drillIds', 'array-contains', drillId),
                            where('drills', 'array-contains', { id: drillId })
                        )
                    ),
                    orderBy('dateCompleted', 'desc')
                ),
                (snap) => {
                    const byId: { [id: string]: DrillReport } = {};
                    const byDrillId: { [id: string]: DrillReport[] } = {};
                    snap.docs.forEach((doc) => {
                        const drillReport = {
                            id: doc.id,
                            ...doc.data(),
                        } as DrillReport;
                        byId[drillReport.id] = drillReport;
                        drillReport.drillIds?.forEach((drillId) => {
                            if (byDrillId[drillId] === undefined) {
                                byDrillId[drillId] = [];
                            }
                            byDrillId[drillId].push(drillReport);
                        });
                    });
                    setDrillReports({
                        byId,
                        byDrillId,
                    });
                }
            );
        }
    }, [isActive, drillId, vesselId]);

    return drillReports;
};

export const useDrillReportsForVessels = (
    isActive: boolean,
    vesselIds?: string[],
) => {
    const [drillReports, setDrillReports] = useState<DrillReportsData>();

    useEffect(() => {
        setDrillReports(undefined);
        if (isActive && vesselIds) {
            return onSnapshot(
                query(
                    collection(firestore, 'drillReports'),
                    where('vesselId', 'in', vesselIds),
                    where('state', '==', 'active'),
                    orderBy('dateCompleted', 'desc'),
                ),
                (snap) => {
                    const byId: { [id: string]: DrillReport } = {};
                    const byDrillId: { [id: string]: DrillReport[] } = {};
                    snap.docs.forEach((doc) => {
                        const drillReport = {
                            id: doc.id,
                            ...doc.data(),
                        } as DrillReport;
                        byId[drillReport.id] = drillReport;
                        drillReport.drillIds?.forEach((drillId) => {
                            if (byDrillId[drillId] === undefined) {
                                byDrillId[drillId] = [];
                            }
                            byDrillId[drillId].push(drillReport);
                        });
                    });
                    setDrillReports({
                        byId,
                        byDrillId,
                    }); 
                }
            );
        }
    }, [isActive, vesselIds]);

    return drillReports;
};
