import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { SplittableBatch } from '../../../../lib/firebase';
import { WriteBatch } from "firebase/firestore";
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { formatValue, formatTextAreaText, formatVessels, getBasedOnBackgroundColour } from '../../../../lib/util';
import { addInterval, formatDate, formatDateShort, formatInterval } from '../../../../lib/datesAndTime';
import { canEdit, canDelete, canAccessAllVessels, permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { renderCategoryName } from '../../../../lib/categories';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { RiskReviewed, useRisksReviewed } from '../../../../shared-state/HealthSafety/useRisksReviewed';
import { Risk } from '../../../../shared-state/HealthSafety/risks';
import { ControlType } from '../EditRiskAssessment/EditRiskAssessment';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import { useItemLinks } from '../../../../shared-state/Core/useItemLinks';
import { SeaLinks } from '../../../../components/SeaLinks/SeaLinks';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaGridDividingLine from '../../../../components/SeaGridDividingLine/SeaGridDividingLine';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import ReviewRiskAssessment from '../ReviewRiskAssessment/ReviewRiskAssessment';
import RiskAssessmentModalPdf from '../../../../exports/HealthSafety/RiskAssessmentModalPdf';
import './ViewRiskAssessment.css';

interface ViewRiskAssessmentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setRiskMatrixModal: (options: {
        show: boolean,
        allowEdit: boolean,
        level: number,
        likelihoodId: number,
        consequenceId: number,
        type: string
    }) => void,
    setShowEditItemModal: (showModal: boolean) => void,
    selectedRisk?: Risk,
    level?: number,
    viewOnly?: boolean
}

const ViewRiskAssessment: React.FC<ViewRiskAssessmentProps> = ({
    showModal,
    setShowModal,
    setRiskMatrixModal,
    setShowEditItemModal,
    selectedRisk,
    level = 1,
    viewOnly
}) => {
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const today = sharedState.today.use(showModal)!;
    const riskCategories = sharedState.riskCategories.use(showModal);
    const risksReviewed = useRisksReviewed(showModal ? selectedRisk : undefined);
    const links = useItemLinks(selectedRisk?.id);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [showReviewItemModal, setShowReviewItemModal] = useState(false);
    const [historyItemToUpdate, setHistoryItemToUpdate] = useState<RiskReviewed>();
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    const openRiskMatrixModal = useCallback((preOrPost: 'pre' | 'post') => {
        if (!selectedRisk) {
            return;
        }
        setRiskMatrixModal?.({
            show: true,
            allowEdit: false,
            level: 2,
            likelihoodId: parseInt(selectedRisk[`${preOrPost}Controls`].likelihood),
            consequenceId: parseInt(selectedRisk[`${preOrPost}Controls`].consequence),
            type: `${preOrPost}Controls`
        });
    }, [selectedRisk, setRiskMatrixModal]);

    const renderRiskRating = useCallback((preOrPost: 'pre' | 'post', likelihoodId: string, consequenceId: string) => {
        if (!licenseeSettings?.riskRegister) return <></>
        const raw = licenseeSettings.riskRegister.matrix[`${likelihoodId}-${consequenceId}`];
        const color = raw.substring(0, 6);
        const name = raw.substring(7);


        return (
            <div
                className={`sea-status risk-rating ${viewOnly ? '' : 'clickable'}`}
                style={{
                    backgroundColor: `#${color}`,
                    color: getBasedOnBackgroundColour(color),
                    position: 'relative',
                    top: '-4px'
                }}
                onClick={(e) => {
                    !viewOnly && openRiskMatrixModal(preOrPost);
                }}
            >
                {name}
            </div>
        );
    }, [licenseeSettings?.riskRegister, openRiskMatrixModal, viewOnly]);

    const day30 = useMemo(() => {
        return addInterval(today, '30d').toISODate();
    }, [today]);

    const renderWhenDueClass = useCallback((dateDue: string) => {
        if (dateDue < today) {
            return 'fail';
        } else if (dateDue < day30) {
            return 'warn';
        }
        return '';
    }, [today, day30]);


    const onEditItem = () => {
        setShowEditItemModal?.(true);
    };

    const onReviewItem = (item?: RiskReviewed) => {
        setHistoryItemToUpdate(item)
        setShowReviewItemModal(true);
    };

    const canEditItem = useMemo(() => {
        return canEdit('hazardRegister')
    }, []);


    return (
        <SeaModal
            title={selectedRisk?.name}
            showModal={showModal}
            setShowModal={setShowModal}
            size='wide'
            level={level}
            viewOnly={viewOnly}
            onPdf={exportType === 'pdf' && selectedRisk && <RiskAssessmentModalPdf
                onCompleted={() => setExportType(undefined)}
                selectedRisk={selectedRisk}
            />}
            setExportType={setExportType}
        >
            {selectedRisk && licenseeSettings?.riskRegister && <>
                <div className="info-image">
                    <SeaFileImage
                        files={selectedRisk?.files}
                        size="medium"
                        showOthers
                    />
                </div>
                <SeaGrid>
                    <SeaGridCell label="Vessels / Facilities" w="50">
                        {formatVessels(selectedRisk?.vesselIds, vessels)}
                    </SeaGridCell>
                    <SeaGridCell label="Category" w="50">
                        {formatValue(renderCategoryName(selectedRisk?.categoryId, riskCategories))}
                    </SeaGridCell>
                    <SeaGridCell label="Risks Associated With This Hazard" w="100">
                        {formatValue(formatTextAreaText(selectedRisk?.risks))}
                    </SeaGridCell>

                </SeaGrid>

                <SeaGrid>
                    <SeaGridDividingLine />
                    <SeaGridCell w="100" style={{ paddingBottom: '24px', minHeight: 'unset' }}>
                        <div
                            className={`columns ${viewOnly ? '' : 'clickable'}`}
                            onClick={(e) => viewOnly ? null : openRiskMatrixModal('pre')}
                        >
                            <h4 style={{ margin: '0px' }}>Pre Controls Risk</h4>
                            {!viewOnly && <div className="text sea-help" style={{ position: 'relative', top: '-1px' }}>
                                <SeaIcon icon="help" />
                            </div>}
                        </div>
                    </SeaGridCell>
                    <SeaGridCell label="Likelihood" w="33">
                        {(licenseeSettings.riskRegister.likelihoods[`L${selectedRisk.preControls.likelihood}`] as ControlType).name}
                    </SeaGridCell>
                    <SeaGridCell label="Consequence" w="33">
                        {(licenseeSettings.riskRegister.consequences[`C${selectedRisk.preControls.consequence}`] as ControlType).name}
                    </SeaGridCell>
                    <SeaGridCell label="Risk Rating" w="33">
                        {renderRiskRating('pre', selectedRisk.preControls.likelihood, selectedRisk.preControls.consequence)}
                    </SeaGridCell>

                    <SeaGridDividingLine />
                    <SeaGridCell label="Risk Controls" w="100">
                        {formatValue(formatTextAreaText(selectedRisk?.controls))}
                    </SeaGridCell>

                    <SeaGridDividingLine />
                    <SeaGridCell w="100" style={{ paddingBottom: '24px', minHeight: 'unset' }}>
                        <div
                            className={`columns ${viewOnly ? '' : 'clickable'}`}
                            onClick={(e) => viewOnly ? null : openRiskMatrixModal('post')}
                        >
                            <h4 style={{ margin: '0px' }}>Post Controls Risk</h4>
                            {!viewOnly && <div className="text sea-help" style={{ position: 'relative', top: '-1px' }}>
                                <SeaIcon icon="help" />
                            </div>}
                        </div>
                    </SeaGridCell>
                    <SeaGridCell label="Likelihood" w="33">
                        {(licenseeSettings.riskRegister.likelihoods[`L${selectedRisk.postControls.likelihood}`] as ControlType).name}
                    </SeaGridCell>
                    <SeaGridCell label="Consequence" w="33">
                        {(licenseeSettings.riskRegister.consequences[`C${selectedRisk.postControls.consequence}`] as ControlType).name}
                    </SeaGridCell>
                    <SeaGridCell label="Risk Rating" w="33">
                        {renderRiskRating('post', selectedRisk.postControls.likelihood, selectedRisk.postControls.consequence)}
                    </SeaGridCell>

                    <SeaGridDividingLine />
                    <SeaGridCell label="Person/s Responsible" w="33">
                        {formatValue(formatTextAreaText(selectedRisk.whoResponsible))}
                    </SeaGridCell>
                    <SeaGridCell label="Review Interval" w="33">
                        {formatValue(formatInterval(selectedRisk.interval))}
                    </SeaGridCell>
                    <SeaGridCell label="Review Date" w="33">
                        <div className={`review-date ${renderWhenDueClass(selectedRisk.dateDue)}`}>
                            {formatDate(selectedRisk.dateDue)}
                        </div>
                    </SeaGridCell>
                    <SeaGridDividingLine />
                    <SeaLinks links={links} ids={selectedRisk?.id ? [selectedRisk.id] : []} level={level + 1} width='50' viewOnly={viewOnly} />
                </SeaGrid>

                {!viewOnly ? <div className="view-modal-buttons">
                    <RequirePermissions
                        role="hazardRegister"
                        level={permissionLevels.EDIT}
                    >
                        <>
                            <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}>
                                <SeaIcon icon="edit" slot="start" />Edit Assessment
                            </SeaButton>
                            <div className="spacer-wide"></div>
                            {canDelete('hazardRegister') &&
                                selectedRisk?.vesselIds &&
                                canAccessAllVessels(selectedRisk.vesselIds) &&
                                <SeaLinkButton
                                    mode="standard-link"
                                    onClick={(e) => {
                                        deleteIfConfirmed(
                                            'risks',
                                            selectedRisk.id,
                                            (batch: WriteBatch | SplittableBatch) => {
                                                setShowModal(false);
                                            },
                                            'risk assessment',
                                            selectedRisk?.name,
                                            selectedRisk.vesselIds
                                        );
                                    }}
                                >
                                    Delete Assessment
                                </SeaLinkButton>
                            }
                        </>
                    </RequirePermissions>
                </div> : null}
                <div className="end-info-line"></div>

                <div className="columns wrap">
                    <h2>Review History</h2>
                    <RequirePermissions
                        role="hazardRegister"
                        level={permissionLevels.EDIT}
                        >
                        <div className="right">
                            <SeaButton
                                zone="white"
                                onClick={(e) => onReviewItem(undefined)}
                            >
                                <SeaIcon icon="tick" slot="start" />
                                Review Hazard / Risk
                            </SeaButton>
                        </div>
                    </RequirePermissions>
                </div>

                <SeaNoData
                    dataName="risk assessment"
                    isHistory={true}
                    isLoading={!risksReviewed}
                    hasNoData={risksReviewed && risksReviewed.length === 0}
                />

                <div className={`risks-history ${(risksReviewed?.length || 0) > 0 ? 'reveal' : 'conceal'}`}>
                    <div className="sea-row-history headings-history">
                        <div>Date</div>
                        <div>By</div>
                        <div>Notes</div>
                        <div></div>
                    </div>
                    {mapArrayWithLimit(risksReviewed, (item) => {
                        return (
                            <div
                                key={item.id}
                                className={`sea-card sea-row-history ${canEditItem && !viewOnly ? 'clickable' : ''}`}
                                onClick={(e) => canEditItem && !viewOnly && onReviewItem(item)}
                            >
                                <div>{formatDateShort(item.dateReviewed)}</div>
                                <div className="truncate">{renderFullNameForUserId(item.reviewedBy)}</div>
                                <div className="truncate">{formatValue(formatTextAreaText(item.notes))}</div>
                                <div><SeaFileImage files={item.files} size="tiny" /></div>
                            </div>
                        )
                    })}
                    {limitTriggerElement}
                </div>

                {showModal &&
                    <ReviewRiskAssessment
                        showModal={showReviewItemModal}
                        setShowModal={setShowReviewItemModal}
                        setShowParentModal={setShowModal}
                        historyItemToUpdate={historyItemToUpdate}
                        level={2}
                        selectedRisk={selectedRisk}
                    />
                }
            </>}
        </SeaModal>
    );
};

export default ViewRiskAssessment;
