import React, { useState, useEffect, useMemo } from 'react';
import { logPageView } from '../../../lib/firebase';
import { useReportSettings } from '../Reporting';
import { taskStatuses } from '../../../lib/util';
import { sharedState } from '../../../shared-state/shared-state';
import { renderVesselName } from '../../../shared-state/Core/vessels';
import { useReportingVesselCertificates } from '../../../shared-state/Reporting/DocumentReporting/useReportingVesselCertificates';
import { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import reporting from '../../../lib/reporting';
import SeaHorizontalStackedBarGraph from '../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import ViewVesselCertificatesReport from '../../../modals/Reporting/Documents/ViewVesselCertificatesReport/ViewVesselCertificatesReport';
import './DocumentReporting.css';

interface DocumentReportingProps {
    visible: boolean,
    graphAvailability: {
        count: number,
        vesselCertificateStatus: boolean
    }
}

const DocumentReporting: React.FC<DocumentReportingProps> = ({
    visible,
    graphAvailability
}) => {
    const today = sharedState.today.use()!;
    const { selectedVesselIds, selectedVesselId } = useReportSettings();
    const [modalToShow, setModalToShow] = useState<string>();
    const [selectedStatuses, setSelectedStatuses] = useState(Object.keys(taskStatuses));
    const filteredVesselCertificates = useReportingVesselCertificates(
        visible && graphAvailability.vesselCertificateStatus,
        selectedVesselIds,
        selectedStatuses
    );

    useEffect(() => {
        if (visible) {
            logPageView('Reporting/DocumentReporting');
        }
    }, [visible]);


    const vesselCertificatesGraphData = useMemo(() => {
        if (filteredVesselCertificates && selectedVesselIds) {
            const array = [] as GraphData[];
            const byVesselId: {
                [vesselId: string]: GraphData
            } = {};
            const indexByStatus = {
                'overdue': 0,
                'upcoming': 1
            };

            selectedVesselIds.forEach((vesselId: string) => {
                const item = {
                    name: renderVesselName(vesselId),
                    values: [0, 0]
                }
                byVesselId[vesselId] = item;
                array.push(item);
            });

            filteredVesselCertificates.forEach((item) => {
                if (byVesselId[item.vesselId]) {
                    if (item.dateExpires && item.dateExpires < today) { // Overdue item
                        byVesselId[item.vesselId].values![indexByStatus.overdue]++;
                    } else { // Upcoming item
                        byVesselId[item.vesselId].values![indexByStatus.upcoming]++;
                    }
                }
            });
            return array;
        }
        return undefined;
    }, [filteredVesselCertificates, selectedVesselIds, today]);

    const selectedStatusNames = useMemo(() => {
        return Object.keys(taskStatuses).map((statusId: string) => {
            if (selectedStatuses.indexOf(statusId) !== -1) {
                return taskStatuses[statusId];
            } else {
                return undefined; // Store undefined in array so it can be skipped
            }
        });
    }, [selectedStatuses]);

    if (!visible) {
        return null;
    }

    let n = 0;

    return (
        <>
            {/* {filteredVesselCertificates === undefined &&
                <IonSpinner name="crescent" className="sea-spinner"/>
            } */}
            <div className="reporting-grid max-1-graphs">
                {graphAvailability.vesselCertificateStatus &&
                    <SeaHorizontalStackedBarGraph
                        n={n++}
                        title={"Vessel Certificates by Vessel"}
                        subTitle="(Live Status)"
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('vesselCertificateStatus');
                        }}
                        data={vesselCertificatesGraphData}
                        units="Tasks"
                        categories={selectedStatusNames}
                        colourPalette={reporting.colours.taskStatuses}
                    />
                }
            </div>
            {visible && graphAvailability.vesselCertificateStatus &&
                <ViewVesselCertificatesReport
                    title="Vessel Certificates by Vessel"
                    subTitle={selectedVesselId ? `(${renderVesselName(selectedVesselId)})` : ''}
                    showModal={modalToShow === 'vesselCertificateStatus'}
                    setShowModal={(showModal) => {
                        setModalToShow(showModal ? 'vesselCertificateStatus' : undefined);
                    }}
                    graphData={vesselCertificatesGraphData}
                    filteredItems={filteredVesselCertificates}
                    categories={selectedStatusNames}
                    selectedStatuses={selectedStatuses}
                    setSelectedStatuses={setSelectedStatuses}
                />
            }
        </>
    );
};

export default DocumentReporting;
