import { SplittableBatch, firestore, storage } from './firebase';
import { doc, WriteBatch, arrayUnion, serverTimestamp, FieldValue } from "firebase/firestore";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import { haveValuesChanged, toInt } from './util';
import { sharedState } from '../shared-state/shared-state';
import { FileCollection } from '../shared-state/FileSyncSystem/cachedFiles';
import { isPlatform } from '@ionic/react';
import { Directory, Filesystem } from '@capacitor/filesystem';
import write_blob from 'capacitor-blob-writer';
//base64js = require('base64-js')

export type FileState = 0 | 1 | 2; // 0=not uploaded yet, 1=uploaded (original file), 2=converted into optimised versions
export type FileId = string; // (20 characters)
export type FileExt = string;
export type FileReference = string; // <FileState><FileId>_<OriginalFileName>.<FileExt>

// when stored in firestore within an images or documents field
// the value parsed as follows:
// <state><id>.<extension>
export interface SeaFile {
    // values that can de derived from firestore documents
    id?: FileId, // firestore id
    state?: FileState, // 0=pending upload, 1=uploaded, 2=uploaded and processed (has thumb version)
    ext?: FileExt, // extension like jpeg, png, pdf etc.
    // values also stored in firestore files collection
    collection?: FileCollection, // firestore collection this is/will be attached to
    field?: string,      // firestore collection.field this is/will be attached to
    docId?: string,      // firestore doc id for which this is attached to. if undefined, it means is not attached
    whenAdded?: Number,  // when file is added (before it is uploaded)
    whenUploaded?: Number, // when file was succesfully uploaded to Storage
    whenProcessed?: Number, // when file was processed into thumbnail version
    // values created by SeaFileUpload component
    src?: string, // what can be passed to an img tag
    base64?: string, // image data that can be used to construct an img.src (see getFileImageSrc). Can also be uploaded as data
    name?: string, // original file name (not present when using getPhoto)
    contentType?: string, // mime type that was in original file (not present when using getPhoto)
    lastModified?: number, // last modified for the original file (not present when using getPhoto)
    authorId?: string, // userId for the author who created or edited the document (only used for *.sfdocs)
    unique?: string, // temporary unique identifier for use by key
    isSignature?: boolean, // set to true if it's a signature
    emailToken?: string // email passcode to allow emails to access file later on (uuid v4)
}

// 
export const convertBlobToBase64 = (blob: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

export const convertBase64toBlob = (base64: string, contentType='', sliceSize=512) => {
    // Source: https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    const byteCharacters = atob(base64);
    const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

const mimeTypes = {
    '3g2': 'video/3gpp2',
    '3gp': 'video/3gpp',
    '7z': 'application/x-7z-compressed',
    aac: 'audio/aac',
    avi: 'video/x-msvideo',
    azw: 'application/vnd.amazon.ebook',
    bin: 'application/octet-stream',
    bmp: 'image/bmp',
    bz: 'application/x-bzip',
    bz2: 'application/x-bzip2',
    csv: 'text/csv',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    epub: 'application/epub+zip',
    gif: 'image/gif',
    gz: 'application/gzip',
    // heic: 'image/heic', // These currently dont convert due to firebase imagemagick being v6 (my guess anyway)
    // heif: 'image/heif',
    htm: 'text/html',
    html: 'text/html',
    jar: 'application/java-archive',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    js: 'text/javascript',
    json: 'application/json',
    jsonld: 'application/ld+json',
    mid: 'audio/midi',
    midi: 'audio/midi',
    mp3: 'audio/mpeg',
    mp4: 'video/mp4',
    mpeg: 'video/mpeg',
    odp: 'application/vnd.oasis.opendocument.presentation',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    odt: 'application/vnd.oasis.opendocument.text',
    oga: 'audio/ogg',
    ogv: 'video/ogg',
    ogx: 'application/ogg',
    opus: 'audio/opus',
    pdf: 'application/pdf',
    png: 'image/png',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rar: 'application/vnd.rar',
    rtf: 'application/rtf',
    svg: 'image/svg+xml',
    tar: 'application/x-tar',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    ts: 'video/mp2t',
    txt: 'text/plain',
    vsd: 'application/vnd.visio',
    wav: 'audio/wav',
    weba: 'audio/webm',
    webm: 'video/webm',
    webp: 'image/webp',
    xhtml: 'application/xhtml+xml',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xml: 'text/xml',
    zip: 'application/zip',
} as any;



export const toSafeFilename = (filename: string | undefined) => {
    if (filename) {
        return filename.replace(/[^a-z0-9_\-\.]/gi, '_');
    }
    return '';
};

export const getContentType = (ext: string) => {
    if (mimeTypes[ext.toLowerCase()]) {
        return mimeTypes[ext.toLowerCase()];
    }
    //return undefined;
    return 'application/octet-stream';
};
export const isImage = (ext: string) => {
    if (getContentType(ext)?.startsWith('image/')) {
        return true;
    }
    return false;
};
export const isPdf = (ext: string | undefined) => {
    if (!ext) {
        return false;
    }
    if (ext.toLowerCase().endsWith('.pdf') || ext.toLowerCase() === 'pdf') {
        return true;
    }
    if (ext.endsWith('.pdf')) {
        return true;
    }
    return false;
}
export const stripBase64Prefix = (content: string) => {
    if (content.startsWith('data:')) {
        return content.substring(content.indexOf(',') + 1).trim();
    }
    return content;
};

export const hasSignatureChanged = (file: SeaFile | undefined, originalValue: string | SeaFile | undefined): boolean => {
    let originalId: string | undefined;
    if (originalValue && typeof originalValue === 'object') {
        originalId = originalValue.id;
    } else if (typeof originalValue === 'string') {
        originalId = originalValue.substring(1, 21);
    }
    if (file) {
        if (!originalId) {
            return true; // originalValue doesn't exist, but file does
        }
        if (file.id) {
            return file.id !== originalId;
        }
        return true; // Must be a freshly drawn signature
    }
    return false;
}

export const getFileNameFromString = (file: string) => {
    if (file.indexOf('_') !== -1) {
        return file.substring(file.indexOf('_') + 1);
    }
    return file;
};

export const getExtFromString = (file: string | undefined) => {
    if (file && file.indexOf('.') !== -1) {
        return file.substring(file.lastIndexOf('.') + 1);
    }
    return '';
};

export const getImgSrcFromExt = (ext: string | undefined, imageSize: string): string => {
    if (ext) {
        switch (ext.toLowerCase()) {
            case 'pdf':
                return `/assets/file-pdf${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
            case 'sfdoc':
                return `/assets/file-sfdoc${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
            case 'doc':
            case 'docx':
            case 'odt':
            case 'txt':
            case 'xls':
            case 'xlsx':
                return `/assets/file-doc${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
        }
    }
    return `/assets/file-attached${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
};

export const getImgSrcPlaceholder = (imageSize: string) => {
    return `/assets/file-null${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
};

export const getFileMissingSrc = (imageSize: string) => {
    return `/assets/missing${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
};

export const getFileTrappedSrc = (imageSize: string) => {
    return `/assets/trapped${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
};

export const getFileOfflineSrc = (imageSize: string) => {
    return `/assets/offline${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
};

export const getFileSrc = (id: string, ext: string): Promise<string> => {
    return getDownloadURL(
        storageRef(storage, `files/${id}.${ext}`)
    );
};

export const getFileSrcFromString = (file: string, imageSize = 'full'): Promise<string> => {
    const ext = file.substring(file.lastIndexOf('.') + 1);
    const state = toInt(file.substring(0,1), 0);
    const id = file.substring(1, 21);
    if (isImage(ext)) {
        return getDownloadURL(
            storageRef(storage, `files/${id}${(state === 2) ? ('_'+imageSize) : ''}.${ext}`)
        );
        //return storage.ref().child(`files/${id}${(state === 2) ? ('_'+imageSize) : ''}.${ext}`).getDownloadURL();
    } else {
        return getFileSrc(id, ext);
    }
};

// make files from firestore values
export const makeSeaFiles = (values: string[] | undefined): SeaFile[] => {
    if (values) {
        const files = [] as SeaFile[];
        values.forEach((value: string) => {
            const file = {
                state: toInt(value[0], 0),
                ext: value.substring(value.lastIndexOf('.') + 1)
            } as SeaFile;
            if (value.indexOf('_') !== -1) {
                file.id = value.substring(1, value.indexOf('_'));
                file.name = value.substring(value.indexOf('_') + 1);
            } else {
                file.id = value.substring(1, value.indexOf('.'));
            }
            files.push(file);
        });
        return files;
    }
    return [];
};

export const makeSignature = (value: string | undefined): SeaFile | undefined => {
    if (value && value.length > 0) {
        return {
            state: toInt(value[0], 0),
            ext: value.substring(value.lastIndexOf('.') + 1),
            id: value.substring(1, value.indexOf('.')),
            isSignature: true
        };
    }
    return undefined;
};

// Check whether files has changed from original firestore values
export const haveFilesChanged = (files: SeaFile[] | undefined, originalValues: string[] | undefined): boolean => {
    if (originalValues === undefined || originalValues.length === 0) {
        return (files && files.length > 0) ? true : false;
    }
    if (files === undefined || files.length === 0) {
        return true;
    }
    const originalFiles = makeSeaFiles(originalValues);
    if (originalFiles.length !== (files ? files.length : 0)) {
        return true;
    }
    if (files) {
        for (let i = 0; i < files.length; i++) {
            if (haveValuesChanged(files[i], originalFiles[i])) {
                return true;
            }
        }
    }
    return false;
}

// Indicate files are being referred to by a firestore document with a particular docId
export const saveFileRefs = (batch: WriteBatch | SplittableBatch, files: SeaFile[], collection: string, docId: string) => {
    if (files?.length > 0) {
        files.forEach((file: SeaFile) => {
            // To make sure the onFileUpdate function is triggered, we must have a value change...
            // Therefore, let's throw in a time value
            let triggerCheck: FieldValue | undefined = undefined;
            if (file.state !== undefined && (
                file.state < 1 ||
                (file.ext && isImage(file.ext) && file.state < 2)
            )) {
                // This file might not be in its final form
                // Therefore let's check that the document update that would be going along side
                // this function call is not overwriting a newer state saved by the server
                triggerCheck = serverTimestamp(); // Server timestamp is used so this will be a unique value that will trigger functions/onFileUpdate
            }
            batch.set(
                doc(firestore, 'files', ''+file.id),
                {
                    //docId: docId,
                    refs: arrayUnion({
                        collection: collection,
                        docId: docId
                    }),
                    triggerCheck
                },
                { merge: true }
            );
        });
    }
};

// Convert an array of SeaFile to an array of "<state><id>.<ext>" values
export const seaFilesToValue = (files: SeaFile[]): string[] => {
    let array = [] as string[];
    files.forEach((file: SeaFile) => {
        array.push(`${file.state}${file.id}${file.name ? ('_' + file.name.substring(0, file.name.lastIndexOf('.'))) : ''}.${file.ext}`);
    });
    return array;
}

export const signatureToValue = (file: SeaFile | undefined): string | undefined => {
    if (file) {
        return `${file.state}${file.id}.${file.ext}`;
    }
    return undefined;
}

export const sfdocToValue = (file: SeaFile): string | undefined => {
    if (file) {
        // return `${file.state}${file.id}_${file.name}.sfdoc`; // For now, we don't have a good reason to embed the title... its repeated redundantly and expected to be already known. Will not be visible with the fullscreen viewer anyway for the foreseeable future
        return `${file.state}${file.id}^${file.authorId}.sfdoc`; // authorId is embedded because sfdocs are uniquely created/edited by users directly
    }
    return undefined;
};

export const getImgSrc = (state: number, id: string, ext: string, imageSize: string): Promise<string> => {
    // if (state === 0) {
    //     return getFileMissingSrc(imageSize);
    // }
    if (isImage(ext)) {
        //return getStorageUrl(licenseeId, id, ext, (state === 2) ? ('_'+imageSize) : '');
        
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                //reject(`Timeout getting downloadUrl for files/${id}${(state === 2) ? ('_'+imageSize) : ''}.${ext}`);
                resolve(getFileOfflineSrc(imageSize));
            }, sharedState.onlineStatus.current?.isOnline ? (20 * 1000) : (3 * 1000)); // If offline, only wait 3 seconds. If online, wait up to 20 seconds
            return getDownloadURL(
                storageRef(storage, `files/${id}${(state === 2) ? ('_'+imageSize) : ''}.${ext}`)
            ).then((url) => {
                clearTimeout(timeout);
                resolve(url);
            }).catch((e) => {
                clearTimeout(timeout);
                console.log(`Failed to getDownloadURL error`, e);
                resolve(getImgSrcFromExt(ext, imageSize));
            });
        });
    }
    return Promise.resolve(getImgSrcFromExt(ext, imageSize));
};

export const getImgSrcFromSeaFile = (file: SeaFile, imageSize: string, returnFullPDF?: boolean): Promise<string> => {
    if (file.state === 0) {
        return Promise.resolve(getFileTrappedSrc('full'));
        //return '/assets/placeholder.png'; // image not yet uploaded on another's device
    } else if (file.base64) {
        if (returnFullPDF && file.contentType === 'application/pdf') {
            return Promise.resolve(`data:application/pdf;base64, ${file.base64}`);
        } else if (file.ext && !isImage(file.ext)) {
            return Promise.resolve(getImgSrcFromExt(file.ext, imageSize));
        }
        return Promise.resolve(`data:image/jpeg;base64, ${file.base64}`);
    } else if (file.src) {
        return Promise.resolve(file.src);
    } else if (file.id && sharedState.licenseeId.current) {
        return getImgSrc(file.state as number, file.id, file.ext as string, imageSize);
    }
    return Promise.resolve('/assets/placeholder.png');
};

export const getImgSrcFromString = (file: string, imageSize: string): Promise<string> => {
    return getImgSrc(
        toInt(file.substring(0,1), 0),
        file.substring(1, 21),
        file.substring(file.lastIndexOf('.') + 1),
        imageSize
    );
};

// Will render an img src depending on file
// nonImage: will render an icon
// noCache: will render a URL
// else: assumes it is base64 encoded
export const renderSrc = (file: string, src: string, size: string): Promise<string> => {
    if (src === 'nonImage') {
        return Promise.resolve(
            getImgSrcFromExt(getExtFromString(file), size)
        );
    } else if (src === 'noCache') {
        if (file[0] === '0') { // Not yet uploaded by another device
            return Promise.resolve(
                getFileTrappedSrc(size)
            );
        }
        return getImgSrcFromString(file, size);
    }
    return Promise.resolve(src);
};

//
// Wrapper function to write blob files.
// Implementation differs depending on platform.
// write_blob can be really slow on desktop - at least using Chrome on my windows 11 laptop, so we stick with the standard Capacitor method.
// write_blob is however a lot better for hybrid, especially iOS.
//
export const writeBlobFile = (blob: Blob, path: string, directory: Directory) => {
    if (isPlatform('hybrid')) {
        return write_blob({ // (Replace Filesytem.writeFile with write_blob)
            blob: blob,
            path: path,
            directory: directory,
            // on_fallback: (error) =>{
            //     debugApp('File Caching', `saveFileToLocalStorage on_fallback! ${fileType}${id}.${ext}`, error);
            //     console.error(error);
            // },
            recursive: true
        }) // returns uri
    } else {
        return Filesystem.writeFile({
            path: path,
            data: blob, // blob is only supported on web!
            directory: directory,
            recursive: true // create any missing parent directories
        }).then((result) => {
            return result.uri;
        });
    }
};
