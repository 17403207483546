import React, { useEffect, useState, useMemo } from 'react';
import { canCreate } from '../../../../shared-state/Core/userPermissions';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { useVoyageCompletedCustomForms } from '../../../../shared-state/VesselLogbook/useVoyageCompletedCustomForms';
import { VoyageDocument, useVoyageDocuments } from '../../../../shared-state/VesselLogbook/useVoyageDocuments';
import { Voyage } from '../../../../shared-state/VesselLogbook/voyages';
import { CustomFormCompleted } from '../../../../shared-state/CompanyDocuments/CustomForms/useCustomFormsCompleted';
import { CustomForm } from '../../../../shared-state/CompanyDocuments/CustomForms/customForms';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import ViewCompletedCustomForm from '../../../CompanyDocuments/CustomForms/ViewCompletedCustomForm/ViewCompletedCustomForm';
import CompleteCustomForm from '../../../CompanyDocuments/CustomForms/CompleteCustomForm/CompleteCustomForm';
import BrowseCustomForms from '../../../CompanyDocuments/CustomForms/BrowseCustomForms/BrowseCustomForms';
import CompletedCustomFormPdf from '../../../../exports/CustomForms/CompletedCustomFormPdf';
import EditVoyageDocumentsModal from '../../EditVoyageDocumentsModal/EditVoyageDocumentsModal';
import './VoyageFormsAndDocumentsTab.css';

interface VoyageFormsAndDocumentsTabProps {
    showModal: boolean,
    selectedVoyage: Voyage,
}

const VoyageFormsAndDocumentsTab: React.FC<VoyageFormsAndDocumentsTabProps> = ({
    showModal,
    selectedVoyage
}) => {
    const vesselId = sharedState.vesselId.use(showModal);
    const customForms = sharedState.customForms.use(showModal);
    const [showViewCompletedCustomForm, setShowViewCompletedCustomForm] = useState(false);
    const [showCompleteCustomForm, setShowCompleteCustomForm] = useState(false);
    const [showBrowseCustomForms, setShowBrowseCustomForms] = useState(false);
    const [selectedCustomForm, setSelectedCustomForm] = useState<CustomForm>();
    const [selectedCompletedCustomForm, setSelectedCompletedCustomForm] = useState<CustomFormCompleted>();
    const [selectedVoyageDocuments, setSelectedVoyageDocuments] = useState<VoyageDocument>();
    const [exportType, setExportType] = useState<ExportType>();
    const [showEditVoyageDocuments, setShowEditVoyageDocuments] = useState(false);
    const completedCustomForms = useVoyageCompletedCustomForms(showModal ? selectedVoyage : undefined);
    const voyageDocuments = useVoyageDocuments(showModal ? selectedVoyage : undefined);

    const formsAndFiles = useMemo(() => {
        if (completedCustomForms !== undefined && voyageDocuments !== undefined) {
            const _formsAndFiles: (VoyageDocument | CustomFormCompleted)[] = [];
            completedCustomForms.all.forEach((form: CustomFormCompleted) => {
                _formsAndFiles.push(form);
            });
            voyageDocuments.all.forEach((userDocument: VoyageDocument) => {
                _formsAndFiles.push(userDocument);
            });
            _formsAndFiles.sort((a, b) => {
                return b.whenAdded - a.whenAdded;
            });
            return _formsAndFiles;
        }
        return undefined;
    }, [completedCustomForms, voyageDocuments]);

    // Keep selectedCompletedCustomForm fresh
    useEffect(() => {
        if (
            selectedCompletedCustomForm?.id &&
            completedCustomForms?.byId[selectedCompletedCustomForm.id]
        ) {
            setSelectedCompletedCustomForm(completedCustomForms.byId[selectedCompletedCustomForm.id]);
        }
    }, [completedCustomForms, selectedCompletedCustomForm?.id]);

    // Keep selectedCustomForm fresh
    useEffect(() => {
        if (
            //selectedCustomForm &&
            selectedCompletedCustomForm?.customFormId &&
            customForms?.byId[selectedCompletedCustomForm.customFormId]
        ) {
            setSelectedCustomForm(customForms.byId[selectedCompletedCustomForm.customFormId]);
        }
    }, [customForms, selectedCompletedCustomForm?.customFormId]);

    const onCompleteForm = () => {
        setShowBrowseCustomForms(true);
    };

    const onAddDocuments = () => {
        setSelectedVoyageDocuments(undefined);
        setShowEditVoyageDocuments(true);
    };

    const onCustomFormSelected = (customForm: CustomForm) => {
        setSelectedCustomForm(customForm);
        setSelectedCompletedCustomForm(undefined);
        setShowCompleteCustomForm(true);
    };

    const onOpenPdf = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, customForm: CustomForm, completedCustomForm: CustomFormCompleted) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedCustomForm(customForm);
        setSelectedCompletedCustomForm(completedCustomForm);
        setExportType('pdf');
    };

    return (<>
        <SeaNoData
            dataName="forms or documents"
            isLoading={!formsAndFiles}
            hasNoData={formsAndFiles && formsAndFiles.length === 0}
            isUsingFilter={false}
        />
        {
            formsAndFiles &&
            canCreate('logbook') && (
                <div className="columns" style={{ marginBottom: '20px' }}>
                    <div className="right">
                        <SeaButton onClick={(e) => onCompleteForm()}>
                            Complete Form
                        </SeaButton>
                        <SeaButton onClick={(e) => onAddDocuments()}>
                            Add Documents
                        </SeaButton>
                    </div>
                </div>
            )
        }

        <div className={`forms-and-files has-thumbs ${formsAndFiles && formsAndFiles.length > 0 ? 'reveal' : 'conceal'}`}>
            <div className="sea-row-history headings-history">
                <div></div>
                <div>Form / Document</div>
                <div>Added by</div>
            </div>
            {formsAndFiles && formsAndFiles.length > 0 && formsAndFiles.map((item) => {
                const customFormCompleted = (item as CustomFormCompleted).customFormId ? (item as CustomFormCompleted) : undefined;
                const voyageDocument = customFormCompleted ? undefined : (item as VoyageDocument);
                const customForm = customFormCompleted?.customFormId ? (customForms?.byId[customFormCompleted.customFormId]) ? customForms.byId[customFormCompleted.customFormId] : undefined : undefined;
                return (
                    <div
                        key={item.id}
                        className={`sea-card sea-row-history clickable ${(customFormCompleted?.isDraft) ? 'draft' : ''}`}
                        onClick={(e) => {
                            if (customFormCompleted?.data) {
                                console.log(`Viewing customFormsCompleted ${customFormCompleted.id}`);
                                setSelectedCustomForm(customForm);
                                setSelectedCompletedCustomForm(customFormCompleted);
                                setShowViewCompletedCustomForm(true);
                            } else if (voyageDocument) {
                                console.log(`Viewing voyageDocuments item ${voyageDocument.id}`);
                                setSelectedVoyageDocuments(voyageDocument);
                                setShowEditVoyageDocuments(true);
                            }
                        }}
                    >
                        <div>
                            {customFormCompleted?.data && customForm ? (
                                <img
                                    alt="pdf"
                                    className="sea-file-image tiny file"
                                    src="/assets/file-pdf_tiny@2x.png"
                                    onClick={(e) => onOpenPdf(e, customForm, customFormCompleted)}
                                />
                            ) : (
                                <SeaFileImage files={item.files} size="tiny"/>
                            )}
                        </div>
                        <div className="truncate">
                            {customFormCompleted?.data ?
                                `${customForm?.title} ${(customFormCompleted?.isDraft) ? ' (DRAFT)' : ''}`
                                :
                                voyageDocument?.name
                            }
                        </div>
                        <div className="truncate">{renderFullNameForUserId(item.addedBy)}</div>
                    </div>
                );
            })}
        </div>

        {showModal &&
            <>
                <BrowseCustomForms
                    showModal={showBrowseCustomForms}
                    setShowModal={setShowBrowseCustomForms}
                    level={2}
                    filter={{
                        //forVesselId: vesselId
                    }}
                    onCustomFormSelected={onCustomFormSelected}
                />
                <ViewCompletedCustomForm
                    showModal={showViewCompletedCustomForm}
                    setShowModal={setShowViewCompletedCustomForm}
                    setShowCompleteCustomForm={setShowCompleteCustomForm}
                    customForm={selectedCustomForm}
                    selectedCompletedCustomForm={selectedCompletedCustomForm}
                    level={2}
                />
                <CompleteCustomForm
                    showModal={showCompleteCustomForm}
                    setShowModal={setShowCompleteCustomForm}
                    setShowBrowseCustomForms={setShowBrowseCustomForms}
                    level={3}
                    customForm={selectedCustomForm}
                    itemToUpdate={selectedCompletedCustomForm}
                    attachTo='voyage'
                    attachToId={selectedVoyage?.id}
                    attachToVesselId={selectedVoyage?.vesselId}
                    forceVesselId={vesselId}
                />
                {exportType === 'pdf' && <CompletedCustomFormPdf
                    onCompleted={() => setExportType(undefined)}
                    customForm={selectedCustomForm}
                    completedCustomForm={selectedCompletedCustomForm}
                />}
                <EditVoyageDocumentsModal
                    showModal={showEditVoyageDocuments}
                    setShowModal={setShowEditVoyageDocuments}
                    selectedVoyage={selectedVoyage}
                    itemToUpdate={selectedVoyageDocuments}
                    level={2}
                />
            </>
        }
    </>);
};

export default VoyageFormsAndDocumentsTab;
