import React, { useEffect, useState } from 'react';
import { formatDatetime, makeDateTime } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { AppVersion, useAppVersions } from '../../../../shared-state/Admin/useAppVersions';
import { renderAppVersionNotes } from '../../../../managers/ServerInfoManager/ServerInfoManager';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import EditAppVersionModal from '../../../../modals/Admin/EditAppVersionModal/EditAppVersionModal';
import './VersionHistory.css';


const VersionHistory: React.FC<{visible: boolean}> = ({visible: boolean}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [showVersionModal, setShowVersionModal] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState<AppVersion>();
    const appVersions = useAppVersions();

    useEffect(() => {
        resetPageLimit();
    }, [resetPageLimit]);

    return (
        <>
            <div>
                <SeaButton onClick={() => {
                    setSelectedVersion(undefined);
                    setShowVersionModal(true);
                }}>
                    Create New App Version
                </SeaButton>
            </div>
            {appVersions?.all && appVersions.all.length > 0 &&
                <div>
                    {mapArrayWithLimit(appVersions.all, (version) => {
                        return (
                            <div
                                key={version.id}
                                className="sea-card sea-row"
                                onClick={(e) => {
                                    setSelectedVersion(version);
                                    setShowVersionModal(true);
                                }}
                                style={{ backgroundColor: version.isMandatory ? '#fffcb2' : '#fff', opacity: version.hasReleaseDate ? 1 : 0.75 }}
                            >
                                <div style={{ padding: '4px 12px 0px' }}>
                                    <div className="columns">
                                        <div>
                                            <h2>{version.version}</h2>
                                        </div>
                                        <div style={{ padding: '9px 0px 0px 8px' }}>
                                            {version.isMandatory ? <b>(MANDATORY) </b> : ''}
                                            {version.hasReleaseDate ? (
                                                `${version.whenReleased > Date.now() ? 'To Be' : '' } Released ${makeDateTime(version.whenReleased).toFormat('cccc')}, ${formatDatetime(version.whenReleased)}`
                                            ) : (
                                                `Not Yet Released`
                                            )}
                                        </div>
                                    </div>
                                    <div style={{ paddingTop: '8px' }}>
                                        {renderAppVersionNotes(version.content)}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            }
            <EditAppVersionModal
                showModal={showVersionModal}
                setShowModal={setShowVersionModal}
                selectedVersion={selectedVersion}
            />
        </>
    );
};

export default VersionHistory;
