import React, { useCallback, useMemo } from 'react';
import { Document, View, Page, Text, Image } from '@react-pdf/renderer';
import { PdfPageLimiter, UnloadedPdfRows, pdfStyles } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { formatDateShort, warnDays } from '../../../lib/datesAndTime';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { renderFullName, renderFullNameForUserId } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { getImgSrcFromString } from '../../../lib/files';
import { CrewCertificate } from '../../../shared-state/Crew/crewCertificates';
import { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { renderCategoryName } from '../../../lib/categories';
import SeaNoDataPDF from '../../../components/SeaNoDataPDF';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaHorizontalStackedBarGraph from '../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import reporting from '../../../lib/reporting';

interface CrewCertificateReportPDFProps {
    onCompleted: () => void;
    title: string;
    subTitle?: string;
    graphData?: GraphData[];
    filteredItems?: CrewCertificate[];
    itemsById?: {
        [key: string]: CrewCertificate;
    };
    categories?: (string | undefined)[];
    labelWidthsForPdf?: number[];
    yLabelWidth?: number;
    vesselName?: string;
}

const CrewCertificateReportPDF: React.FC<CrewCertificateReportPDFProps> = ({ onCompleted, title, subTitle, graphData, filteredItems, itemsById, categories, labelWidthsForPdf, yLabelWidth, vesselName }) => {
    const vessels = sharedState.vessels.use();
    const crewCertificateTitles = sharedState.crewCertificateTitles.use();
    const { selectedVesselIds } = useReportSettings();
    const counterRef = React.useRef(0);
    const { mapPdfArrayWithLimit } = PdfPageLimiter(counterRef);

    const subHeading = useMemo(() => {
        if (vesselName && subTitle) {
            return subTitle.replace(/\) *$/, ` ${vesselName})`);
        } else if (vesselName) {
            return `(${vesselName})`;
        }
        return subTitle;
    }, [vesselName, subTitle]);

    const MakePDF = useCallback(() => {
        counterRef.current = 0;
        return (
            <Document title={title} author="Sea Flux" creator="Sea Flux" producer="Sea Flux">
                <Page
                    style={{
                        ...pdfStyles.page,
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader heading={title} subHeading={subHeading} userFullName={renderFullName()} />
                    {vessels && selectedVesselIds?.length > 1 ? (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Vessels</Text>
                                <Text>{selectedVesselIds.map((id: string) => vessels.byId[id].name).join(', ')}</Text>
                            </View>
                        </View>
                    ) : null}

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <SeaHorizontalStackedBarGraph
                                mode="pdf"
                                data={graphData}
                                n={0}
                                visible={true}
                                categories={categories}
                                colourPalette={reporting.colours.taskStatuses}
                                labelWidthsForPdf={labelWidthsForPdf}
                                yLabelWidth={yLabelWidth}
                            />
                        </View>
                    </View>

                    <SeaNoDataPDF dataName="crew certificates" hasNoData={!filteredItems?.length} isUsingFilter={true} />
                    {filteredItems?.length ? (
                        <View style={pdfStyles.dataContainer}>
                            <View style={pdfStyles.dataHeaderRow}>
                                <View style={pdfStyles.thumbColumn}></View>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2x3]}>Title</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.dateColumn]}>Expiry</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Held By</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.statusColumn]}>Status</Text>
                            </View>
                            {mapPdfArrayWithLimit(filteredItems, (item) => {
                                const file = item.files && item.files.length > 0 ? item.files[0] : undefined;
                                const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                return (
                                    <View wrap={false} key={item.id} style={pdfStyles.greyDataRow}>
                                        <View style={pdfStyles.thumbColumn}>{imgSrc && <Image src={imgSrc} style={pdfStyles.thumbImgRound} />}</View>
                                        <Text style={[pdfStyles.truncate, pdfStyles.column2x3, pdfStyles.bold]}>{formatValue(renderCategoryName(item?.titleId, crewCertificateTitles))}</Text>
                                        <Text style={pdfStyles.dateColumn}>{formatDateShort(item.dateExpires)}</Text>
                                        <Text style={[pdfStyles.truncate, pdfStyles.column1]}>{renderFullNameForUserId(item.heldBy)}</Text>
                                        <View style={pdfStyles.statusColumn}>
                                            <SeaStatusDueDate dateDue={item.dateExpires} warnDays={warnDays.crewCertificates[0]} forPdf={true} />
                                        </View>
                                    </View>
                                );
                            })}
                            <UnloadedPdfRows length={filteredItems?.length} />
                        </View>
                    ) : null}
                </Page>
            </Document>
        );
    }, [title, subHeading, vessels, selectedVesselIds, graphData, categories, labelWidthsForPdf, yLabelWidth, filteredItems, mapPdfArrayWithLimit, crewCertificateTitles]);

    return <SeaPdfGenerator onCompleted={onCompleted} pdfTitle={title} MakePdf={MakePDF} requestedFrom="crewCertificateReport" />;
};

export default CrewCertificateReportPDF;
