import React, { useEffect, useState } from 'react';
import { formatValue, formatVessels } from '../../../../lib/util';
import { formatDateShort, formatMonth } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { canAccessAnyVessels } from '../../../../shared-state/Core/userPermissions';
import { sharedState } from '../../../../shared-state/shared-state';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { openContextualHelp } from '../../../../managers/ContextualHelpManager/ContextualHelpManager';
import { CorrectiveAction } from '../../../../shared-state/HealthSafety/correctiveActions';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import ViewCorrectiveAction from '../ViewCorrectiveAction/ViewCorrectiveAction';
import './CorrectiveActionsHistoryModal.css';

interface CorrectiveActionsHistoryModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    vesselId?: string,
    level?: number
}

type CorrectiveActionsHistory = {
    months: string[],
    byMonth: {
        [key: string]: CorrectiveAction[]
    }
}

const CorrectiveActionsHistoryModal: React.FC<CorrectiveActionsHistoryModalProps> = ({showModal, setShowModal, vesselId, level = 1}) => {
    const user = sharedState.user.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const correctiveActions = sharedState.correctiveActions.use(showModal);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter(100); // itemsPerPage needs to be bigger than default because they don't take up much vertical space
    const [correctiveActionsHistory, setCorrectiveActionsHistory] = useState<CorrectiveActionsHistory>();
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<CorrectiveAction>();

    useEffect(() => {
        if (showModal) {
            logModalView('CorrectiveActionsHistory', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (correctiveActions?.byId.all && selectedItem?.id) {
            const action = correctiveActions.byId.all[selectedItem.id];
            if (action) {
                setSelectedItem(action);
            }
        }
    }, [correctiveActions, selectedItem?.id]);

    useEffect(() => {
        setCorrectiveActionsHistory(undefined);
        if (showModal && correctiveActions) {
            const months = [] as string[];
            const byMonth: {
                [key: string]: CorrectiveAction[]
            } = {};

            correctiveActions.array.completed.forEach((action) => {
                if (canAccessAnyVessels(action.vesselIds)) {
                    const month = formatMonth(action.whenAdded);
                    if (months.length === 0 || months[months.length - 1] !== month) {
                        months.push(month);
                        byMonth[month] = [];
                    }
                    byMonth[month].push(action);
                }
            });

            // Sort actions within each month by whenAdded in descending order
            Object.keys(byMonth).forEach(month => {
                byMonth[month].sort((a, b) => new Date(b.whenAdded).getTime() - new Date(a.whenAdded).getTime());
            });

            setCorrectiveActionsHistory({
                months,
                byMonth
            });
        }
    }, [showModal, user, correctiveActions]);

    const onViewItem = (action: CorrectiveAction) => {
        setSelectedItem(action);
        setShowViewItemModal(true);
    }
    
    return (
        <SeaModal
            title={`Corrective Action History${(vesselId && vessels?.byId && vessels.byId[vesselId]) ? ` - ${vessels.byId[vesselId].name}` : ''}`}
            showModal={showModal}
            setShowModal={setShowModal}
            size="extra-wide"
            level={level}
        >
            <SeaNoData
                dataName="corrective actions"
                isLoading={correctiveActionsHistory ? false : true}
                hasNoData={correctiveActionsHistory && correctiveActionsHistory.months.length === 0}
            />
            <div className={`corrective-actions-history ${vesselId ? 'hide-vessels' : 'show-vessels'} ${(correctiveActionsHistory && correctiveActionsHistory.months.length > 0) ? 'reveal' : 'conceal'}`}>
                <div className="sea-row-history headings-history" style={{marginBottom: '-8px'}}>
                    <div></div>
                    <div>Title</div>
                    <div>Action #</div>
                    <div>Assigned</div> 
                    <div>Date Completed</div>
                    <div>Vessels / Facilities</div>
                </div>
                {correctiveActionsHistory?.months?.map((month: string) => {
                    if (isLimitReached()) {
                        return undefined;
                    }
                    return (
                        <React.Fragment key={month}>
                            <div className="category-heading log-month">
                                {formatMonth(correctiveActionsHistory.byMonth[month][0].whenAdded)}
                            </div>
                            <div className="custom-history-table">
                                {mapArrayWithLimit(correctiveActionsHistory.byMonth[month], (action: CorrectiveAction) => {
                                    return (
                                        <div className="sea-card sea-row-history clickable" key={action.id} onClick={(e) => onViewItem(action)}>
                                            <div data-fullname={action.assignedTo ? `, Assigned to: ${renderFullNameForUserId(action.assignedTo)}` : ''}>{action.whenAdded ? formatDateShort(action.whenAdded) : ''}</div>
                                            <div>{formatValue(action.title)}</div>
                                            <div>{formatValue(action.correctiveActionNum)}</div>
                                            <div>{formatValue(renderFullNameForUserId(action.assignedTo))}</div>
                                            <div data-prefix="Completed: " data-fullname={action.completedBy ? `, ${renderFullNameForUserId(action.completedBy)}` : ''}>{action.whenCompleted ? formatDateShort(action.whenCompleted) : ''}</div>
                                            <div onClick={(e) => openContextualHelp(e, {text: formatVessels(action.vesselIds, vessels)})}>
                                                {formatVessels(action.vesselIds, vessels)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </React.Fragment>
                    );
                })}
                {limitTriggerElement}
            </div>
            <ViewCorrectiveAction
                showModal={showViewItemModal}
                setShowModal={setShowViewItemModal}
                selectedItem={selectedItem}
                level={level + 1}
            />
        </SeaModal>
    );
};

export default CorrectiveActionsHistoryModal;
