import React, { useEffect, useState } from 'react';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { formatValue } from '../../../../lib/util';
import { formatDatetime } from '../../../../lib/datesAndTime';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { renderCategoryNames } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { Incident } from '../../../../shared-state/HealthSafety/incidents';
import reporting, { colours } from '../../../../lib/reporting';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import SeaHorizontalBarGraph, { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import ContributingFactorsReportPdf from '../../../../exports/Reporting/HealthSafety/ContributingFactorsReportPdf';
import './ViewContributingFactorsReport.css';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';

interface ViewContributingFactorsReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredIncidents?: Incident[],
    yLabelWidth?: number,
    multiColoured?: boolean,
    vesselName?: string,
}

const ViewContributingFactorsReport: React.FC<ViewContributingFactorsReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredIncidents,
    yLabelWidth,
    multiColoured,
    vesselName
}) => {
    const incidentCauses = sharedState.incidentCauses.use(showModal);
    const {selectedVesselId} = useReportSettings();
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (showModal) {
            logModalView('IncidentStatusesReport', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);


    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={exportType === 'pdf' &&
                <ContributingFactorsReportPdf
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredIncidents={filteredIncidents}
                    yLabelWidth={yLabelWidth ? yLabelWidth + 50 : undefined}
                    vesselName={vesselName}
                />
            }
            setExportType={setExportType}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={true}
            />

            <SeaHorizontalBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                sortData={true}
                units="Events / Incidents"
                yLabelWidth={yLabelWidth}
                colourPalette={multiColoured ? reporting.colours.default : [colours.skyBlue]}
                hashNamesForColours={multiColoured ? true : false}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="incidents/events"
                    isLoading={!filteredIncidents}
                    hasNoData={filteredIncidents?.length === 0}
                    isUsingFilter={true}
                />
                
                <div className={`incidents by-contributing ${(filteredIncidents && filteredIncidents.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div>Date</div>
                        <div>Name</div>
                        <div>Contributing Factors</div>
                        <div>Vessel</div>
                    </div>
                    {mapArrayWithLimit(filteredIncidents, (incident) => {
                        return (
                            <div
                                key={incident.id}
                                className="sea-card sea-row-history"
                            >
                                <div>
                                    {formatDatetime(incident.whenEvent)}
                                </div>
                                <div>
                                    {formatValue(incident.name)}
                                </div>
                                <div>
                                    {formatValue(renderCategoryNames(incident.causeIds, incidentCauses))}
                                </div>
                                <div>
                                    {renderVesselName(incident.vesselId)}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
               
            </div>
        </SeaModal>
    );
};

export default ViewContributingFactorsReport;
