import React, { useCallback } from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn } from '../../lib/pdf';
import { formatValue } from '../../lib/util';
import { formatDateShort, formatDatetime, warnDays } from '../../lib/datesAndTime';
import { renderFullName } from '../../shared-state/Core/users';
import { sharedState } from '../../shared-state/shared-state';
import { renderCategoryName } from '../../lib/categories';
import { SafetyEquipmentItem } from '../../shared-state/VesselSafety/safetyEquipmentItems';
import { getImgSrcFromString } from '../../lib/files';
import SeaPdfGenerator from '../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../components/PdfItemsHeader/PdfItemsHeader';
import SeaStatusDueDate from '../../components/SeaStatusDueDate/SeaStatusDueDate';
import CriticalFlagPDF from '../../assets/svg/CriticalFlagPDF';

interface SafetyEquipmentExpiriesPdfProps {
    onCompleted: () => void,
    listType: string;
    servicable: SafetyEquipmentItem[];
    expiring: SafetyEquipmentItem[];
    nonExpiring: SafetyEquipmentItem[];
}

const SafetyEquipmentExpiriesPdf: React.FC<SafetyEquipmentExpiriesPdfProps> = ({
    onCompleted,
    listType,
    servicable,
    expiring,
    nonExpiring
}) => {
    const vessel = sharedState.vessel.use();
    const title = 'Safety Equipment Expiries' + (vessel !== undefined ? ' - ' + vessel.name : '');
    const vesselSafetyItems = sharedState.vesselSafetyItems.use();
    const vesselLocations = sharedState.vesselLocations.use();
    const subHeading = (listType === 'all') ? 'All' : (listType === 'critical') ? 'Critical' : `Overdue & Due Within ${listType} Days`;

    const isCritical = useCallback((id: string) => {
        if (vesselSafetyItems?.byId[id]?.isCritical) {
            return true;
        }
        return false;
    }, [vesselSafetyItems]);

    const MakePDF = useCallback(() => {
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={subHeading}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.thumbColumn}></View>
                        <View style={styleItemColumn(2)}>
                            <Text>Safety Item</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Location</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Last</Text>
                            <Text>Service</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Service</Text>
                            <Text>Expiry</Text>
                        </View>
                        <View style={pdfStyles.criticalColumn}>
                            <Text>Critical</Text>
                        </View>
                        <View style={pdfStyles.statusColumn}>
                            <Text>Status</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                    {/* Severviable Equipment Category */}
                    {
                        servicable && servicable?.length > 0 &&
                        <React.Fragment key='servicable'>
                            <View wrap={false} style={pdfStyles.itemsCategory}>
                                <Text>SERVICABLE EQUIPMENT</Text>
                            </View>
                            {
                                servicable.map((item: SafetyEquipmentItem, index) => {
                                    const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                    const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                    const isLast = index === servicable.length - 1;
                                    return (
                                        <React.Fragment key={'servicable-' + index}>
                                            {index > 0 &&
                                                <View style={pdfStyles.itemsRowSpacer}></View>
                                            }
                                            <View wrap={false} style={pdfStyles.itemsRow}>
                                                <View style={pdfStyles.thumbColumn}>
                                                    {imgSrc &&
                                                        <Image
                                                            src={imgSrc}
                                                            style={pdfStyles.thumbImg}
                                                        />
                                                    }
                                                </View>
                                                <View style={styleItemColumn(2)}>
                                                    <Text wrap={true}>
                                                        {renderCategoryName(item.itemId, vesselSafetyItems)}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(renderCategoryName(item.locationId, vesselLocations))}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatDatetime(item.whenLastChecked)}
                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.dateColumn}>
                                                    <Text>
                                                        {formatDateShort(item.dateDue)}
                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.criticalColumn}>
                                                    {isCritical(item.itemId) && (
                                                        <CriticalFlagPDF />
                                                    )}
                                                </View>
                                                <View style={pdfStyles.statusColumn}>
                                                    <SeaStatusDueDate forPdf={true} dateDue={item.dateDue} warnDays={warnDays.safetyEquipmentExpiries[0]} />
                                                </View>
                                            </View>
                                            {isLast && (
                                                <View style={pdfStyles.itemsCategorySpacer} />
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </React.Fragment>
                    }
                    {/* Expiring Equipment Category */}
                    {
                        expiring && expiring?.length > 0 &&
                        <React.Fragment key='expiring'>
                            <View wrap={false} style={pdfStyles.itemsCategory}>
                                <Text>EXPIRING EQUIPMENT</Text>
                            </View>
                            {
                                expiring.map((item: SafetyEquipmentItem, index) => {
                                    const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                    const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                    const isLast = index === expiring.length - 1;
                                    return (
                                        <React.Fragment key={'expiring-' + index}>
                                            {index > 0 &&
                                                <View style={pdfStyles.itemsRowSpacer}></View>
                                            }
                                            <View wrap={false} style={pdfStyles.itemsRow}>
                                                <View style={pdfStyles.thumbColumn}>
                                                    {imgSrc &&
                                                        <Image
                                                            src={imgSrc}
                                                            style={pdfStyles.thumbImg}
                                                        />
                                                    }
                                                </View>
                                                <View style={styleItemColumn(2)}>
                                                    <Text wrap={true}>
                                                        {renderCategoryName(item.itemId, vesselSafetyItems)}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(renderCategoryName(item.locationId, vesselLocations))}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>

                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.dateColumn}>
                                                    <Text>
                                                        {formatDateShort(item.dateDue)}
                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.criticalColumn}>
                                                    {isCritical(item.itemId) && (
                                                        <CriticalFlagPDF />
                                                    )}
                                                </View>
                                                <View style={pdfStyles.statusColumn}>
                                                    <SeaStatusDueDate forPdf={true} dateDue={item.dateDue} warnDays={warnDays.safetyEquipmentExpiries[0]} />
                                                </View>
                                            </View>
                                            {isLast && (
                                                <View style={pdfStyles.itemsCategorySpacer} />
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </React.Fragment>
                    }
                    {/* Non Expiring Equipment Category */}
                    {
                        nonExpiring && nonExpiring?.length > 0 &&
                        <React.Fragment key='nonExpiring'>
                            <View wrap={false} style={pdfStyles.itemsCategory}>
                                <Text>NON EXPIRING EQUIPMENT</Text>
                            </View>
                            {
                                nonExpiring.map((item: SafetyEquipmentItem, index) => {
                                    const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                    const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                    const isLast = index === nonExpiring.length - 1;
                                    return (
                                        <React.Fragment key={'nonExpiring-' + index}>
                                            {index > 0 &&
                                                <View style={pdfStyles.itemsRowSpacer}></View>
                                            }
                                            <View wrap={false} style={pdfStyles.itemsRow}>
                                                <View style={pdfStyles.thumbColumn}>
                                                    {imgSrc &&
                                                        <Image
                                                            src={imgSrc}
                                                            style={pdfStyles.thumbImg}
                                                        />
                                                    }
                                                </View>
                                                <View style={styleItemColumn(2)}>
                                                    <Text wrap={true}>
                                                        {renderCategoryName(item.itemId, vesselSafetyItems)}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(renderCategoryName(item.locationId, vesselLocations))}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>

                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.dateColumn}>
                                                    <Text>

                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.criticalColumn}>
                                                    {isCritical(item.itemId) && (
                                                        <CriticalFlagPDF />
                                                    )}
                                                </View>
                                                <View style={pdfStyles.statusColumn}>
                                                    <Text>

                                                    </Text>
                                                </View>
                                            </View>
                                            {isLast && (
                                                <View style={pdfStyles.itemsCategorySpacer} />
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </React.Fragment>
                    }

                </Page>
            </Document>
        );
    }, [servicable, expiring, nonExpiring, isCritical, subHeading, title, vesselLocations, vesselSafetyItems]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="SafetyEquipmentExpiries"
        />
    );
};

export default SafetyEquipmentExpiriesPdf;
