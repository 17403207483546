import React from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc, serverTimestamp } from "firebase/firestore";
import { formatMonth, formatYear } from '../../../../lib/datesAndTime';
import { sharedState } from '../../../../shared-state/shared-state';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { CrewCertificate } from '../../../../shared-state/Crew/crewCertificates';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import './ArchiveCrewCertificate.css';

interface ArchiveCrewCertificateProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const ArchiveCrewCertificate: React.FC<ArchiveCrewCertificateProps> = ({showModal, setShowModal}) => {
    const userId = sharedState.userId.use(showModal);
    const archivedCrewCertificates = sharedState.archivedCrewCertificates.use(showModal);

    const onUndoArchive = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: CrewCertificate) => {
        event.stopPropagation();
        confirmAction('Are you sure you want to unarchive this certificate?', 'Yes, unarchive').then(() => {        
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'crewCertificates', 'unarchive', item.id);
            batch.set(
                doc(firestore, 'crewCertificates', item.id),
                {
                    unarchivedBy: userId,
                    whenUnarchived: batchTrace.whenAction,
                    state: 'active',
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            onCollectionUpdated(batch, 'crewCertificates');

            logAction(
                batch,
                'Unarchive',
                'crewCertificates',
                item.id,
                item.title,
                undefined,
                [item.heldBy]
            );

            batchTrace.data = {item};
            batchTrace.save(`Unarchive certificate ${item?.title}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            showToast('Certificate is now active');
        }).catch(() => {});
    };

    const onDeleteCertificate = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: CrewCertificate) => {
        event.stopPropagation();
        confirmAction('Are you sure you want to delete this certificate?', 'Yes, delete').then(() => {
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'crewCertificates', 'delete', item.id);
            batch.set(
                doc(firestore, 'crewCertificates', item.id),
                {
                    deletedBy: userId,
                    whenDeleted: batchTrace.whenAction,
                    state: 'deleted',
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            onCollectionUpdated(batch, 'crewCertificates');

            logAction(
                batch,
                'Delete',
                'crewCertificates',
                item.id,
                item.title,
                undefined,
                [item.heldBy]
            );

            batchTrace.data = {item};
            batchTrace.save(`Delete crew certificate ${item.title}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            showToast('Crew Certificate has now been deleted');
        }).catch(() => {});
    };
    
    return (
        <SeaModal
            title='Archived'
            showModal={showModal}
            setShowModal={setShowModal}
            size='standard'
        >
            <SeaNoData
                dataName="archived crew certificates"
                isLoading={!archivedCrewCertificates}
                hasNoData={archivedCrewCertificates && archivedCrewCertificates.categories.length === 0}
                isUsingFilter={false}
            />
            {/* fix this */}
            <div className={`crew-certificates-archived ${archivedCrewCertificates?.categories && archivedCrewCertificates.categories.length > 0 ? 'reveal' : 'conceal' }`}>
                <div className="sea-row-history headings-history">
                    <div>Date</div>
                    <div>Crew Member</div>
                    <div>Certificate</div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {archivedCrewCertificates?.categories?.map((category: string) => {
                    return (
                        <React.Fragment key={category}>
                            <div className="category-heading">{formatYear(category)}</div>
                            {archivedCrewCertificates.byCategory[category] && archivedCrewCertificates.byCategory[category].map((item: CrewCertificate) => {
                                return (
                                    <div key={item.id} className="sea-card sea-row-history">
                                        <div>{formatMonth(item.dateIssued)}</div>
                                        <div className="truncate-2">{renderFullNameForUserId(item.heldBy)}</div>
                                        <div className="truncate-2">{item.title}</div>
                                        <div><SeaFileImage files={item.files} size="tiny"/></div>
                                        <div><div onClick={(e) => onUndoArchive(e, item)}><SeaIcon icon="undo" slot="icon-only" /></div></div>
                                        <div><div onClick={(e) => onDeleteCertificate(e, item)}><SeaIcon icon='trash' slot="icon-only" /></div></div>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    )
                })}
            </div>
        </SeaModal>
    );
};

export default ArchiveCrewCertificate;
