import React, { useCallback } from 'react';
import { Document, View, Page, Text } from '@react-pdf/renderer';
import { PdfPageLimiter, pdfStyles, UnloadedPdfRows } from '../../../lib/pdf';
import { calcFuelUsedFromVoyage, formatValue, renderCamelCase } from '../../../lib/util';
import { formatDatetime } from '../../../lib/datesAndTime';
import { Voyage } from '../../../shared-state/VesselLogbook/voyages';
import { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { renderFullName } from '../../../shared-state/Core/users';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { sharedState } from '../../../shared-state/shared-state';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';
import SeaHorizontalStackedBarGraph from '../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import reporting from '../../../lib/reporting';
import SeaNoDataPDF from '../../../components/SeaNoDataPDF';

interface FuelUsedReportPdfProps {
    onCompleted: () => void,
    title: string,
    subTitle: string,
    filteredItems?: Voyage[],
    graphData?: GraphData[],
    categories?: (string | undefined)[],
    labelWidthsForPdf?: number[],
}

const FuelUsedReportPdf: React.FC<FuelUsedReportPdfProps> = ({
    onCompleted,
    title,
    subTitle,
    filteredItems,
    graphData,
    categories,
    labelWidthsForPdf
}) => {
    const vessels = sharedState.vessels.use();
    const { selectedVesselId, selectedVesselIds } = useReportSettings();
    const counterRef = React.useRef(0);
    const {  mapPdfArrayWithLimit } = PdfPageLimiter(counterRef);
    
    const MakePDF = useCallback(() => {
        counterRef.current = 0;
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={subTitle}
                        userFullName={renderFullName()}
                    />
                    {vessels && selectedVesselIds?.length > 1 ? (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Vessels</Text>
                                <Text>{selectedVesselIds.map((id: string) => vessels.byId[id].name).join(', ')}</Text>
                            </View>
                        </View>
                    ) : null}

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <SeaHorizontalStackedBarGraph
                                mode="pdf"
                                data={graphData}
                                n={0}
                                visible={true}
                                categories={categories}
                                colourPalette={reporting.colours.default}
                                labelWidthsForPdf={labelWidthsForPdf}
                            />
                        </View>
                    </View>
                    <SeaNoDataPDF 
                        dataName="voyages"
                        hasNoData={!filteredItems?.length}
                        isUsingFilter={true}
                    />
                     {filteredItems?.length ? (
                        <View style={pdfStyles.dataContainer}>
                            <View style={pdfStyles.dataHeaderRow}>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2]}>Name</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.timeColumn]}>Departed</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Fuel Started</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>{`Fuel \nEnded`}</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Fuel Bunkered</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Fuel Used</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Trip Type</Text>
                                {!selectedVesselId ? <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Vessel</Text> : null}
                            </View>
                            {mapPdfArrayWithLimit(filteredItems, (item: Voyage) => {
                                const fuelUsed = calcFuelUsedFromVoyage(item);
                                return (
                                    <View wrap={false} key={item.id} style={pdfStyles.greyDataRow}>
                                        <Text style={[pdfStyles.truncate, pdfStyles.column2, pdfStyles.bold]}>{formatValue(item.name)}</Text>
                                        <Text style={[pdfStyles.timeColumn]}>{formatDatetime(item.whenDeparted)}</Text>
                                        <Text style={pdfStyles.column1}>{item.fuelStart ? item.fuelStart + 'L' : ''}</Text>
                                        <Text style={pdfStyles.column1}>{item.fuelEnd ? item.fuelEnd + 'L' : ''}</Text>
                                        <Text style={pdfStyles.column1}>{item.fuelBunkered ? item.fuelBunkered + 'L' : ''}</Text>
                                        <Text style={pdfStyles.column1}>{fuelUsed ? fuelUsed+ 'L' : ''}</Text>
                                        <Text style={pdfStyles.column1}>{renderCamelCase(item.tripType)}</Text>
                                        {!selectedVesselId ? <Text style={[pdfStyles.truncate, pdfStyles.column1]}>{vessels?.byId[item.vesselId]?.name}</Text> : null}
                                    </View>
                                );
                            })}
                                <UnloadedPdfRows length={filteredItems?.length} />
                        </View>
                    ) : null}
                </Page>
            </Document>
        );
    }, [title, subTitle, vessels, selectedVesselIds, graphData, categories, labelWidthsForPdf, filteredItems, selectedVesselId, mapPdfArrayWithLimit]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="voyagesReport"
        />
    );
};

export default FuelUsedReportPdf;