import React, { useMemo, useCallback, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { deleteValue, firestore, splittableBatch } from '../../../lib/firebase';
import { doc, setDoc } from "firebase/firestore";
import { haveValuesChanged } from '../../../lib/util';
import { sharedState } from '../../../shared-state/shared-state';
import { reportError } from '../../../managers/ErrorsManager/ErrorsManager';
import { formatSeaDatetime, makeDateTime, toMillis } from '../../../lib/datesAndTime';
import { showToast } from '../../../managers/ToastManager/ToastManager';
import { alertMessage } from '../../../managers/AlertManager/AlertManager';
import { confirmAction } from '../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { AppVersion } from '../../../shared-state/Admin/useAppVersions';
import { renderAppVersionNotes } from '../../../managers/ServerInfoManager/ServerInfoManager';
import Yup, { notTooOld } from '../../../lib/yup'
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaTextarea from '../../../components/SeaTextarea/SeaTextarea';
import SeaDatetime from '../../../components/SeaDatetime/SeaDatetime';
import SeaLinkButton from '../../../components/SeaLinkButton/SeaLinkButton';
import SeaCheckbox from '../../../components/SeaCheckbox/SeaCheckbox';
import SeaLabel from '../../../components/SeaLabel/SeaLabel';

interface EditAppVersionModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedVersion?: AppVersion
}


const EditAppVersionModal: React.FC<EditAppVersionModalProps> = ({showModal, setShowModal, selectedVersion}) => {
    const [loading, setLoading] = useState(false);
    const superAdmin = sharedState.superAdmin.use(showModal);
    
    const isFormDisabled = (loading || selectedVersion?.state === 'deleted');
    
    const indeterminateReleaseDate = useMemo(() => {
        return formatSeaDatetime('2200-01-01');
    }, []);

    const initialValues = useMemo(() => {
        const defaultWhenReleased = formatSeaDatetime(makeDateTime(Date.now() + (24 * 60 * 60 * 1000)).set({ minute: 0, second: 0 }));
        if (selectedVersion) {
            return {
                version: selectedVersion.version ? selectedVersion.version : '',
                whenReleased: selectedVersion.hasReleaseDate ? formatSeaDatetime(selectedVersion.whenReleased) : defaultWhenReleased,
                hasReleaseDate: selectedVersion.hasReleaseDate ? true : false,
                isMandatory: selectedVersion.isMandatory ? true : false,
                content: selectedVersion.content ?? '',
            };
        } else {
            return {
                version: '',
                isMandatory: false,
                whenReleased: defaultWhenReleased,
                hasReleaseDate: false,
                content: '',
            };
        }
    }, [selectedVersion]);

    const onOpened = () => {
        resetForm();
        setValues(initialValues);
    }

    const onDelete = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();
        const now = Date.now();
        return confirmAction('Are sure you want to delete app version?').then(() => {
            return setDoc(
                doc(firestore, 'appVersions', selectedVersion!.version),
                {
                    state: 'deleted',
                    whenDeleted: now,
                    deletedBy: superAdmin!.id
                },
                { merge: true }
            );
        }).then(() => {
            showToast('App version deleted!');
            setShowModal(false);
        });
    }, [selectedVersion, setShowModal, superAdmin]);

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            version: Yup.string().max(500).required(),
            whenReleased: Yup.date().required().min(...notTooOld),
            content: Yup.string().max(10000).required(),
        }), onSubmit: (data) => {
            const now = Date.now();
            setLoading(true);
            const batch = splittableBatch(firestore, 20 - 0);

            if (selectedVersion) {
                // Update existing version
                if (selectedVersion.version !== values.version) {
                    batch.set(
                        doc(firestore, 'appVersions', selectedVersion.version),
                        {
                            state: 'replaced',
                            whenReplaced: now,
                            replacedBy: superAdmin?.id
                        },
                        { merge: true }
                    );
                }
                batch.set(
                    doc(firestore, 'appVersions', values.version),
                    {
                        version: values.version,
                        isMandatory: values.isMandatory,
                        hasReleaseDate: values.hasReleaseDate,
                        whenReleased: values.hasReleaseDate ? toMillis(values.whenReleased) : toMillis(indeterminateReleaseDate),
                        whenUpdated: now,
                        updatedBy: superAdmin?.id,
                        state: 'active',
                        content: values.content ? values.content : deleteValue,
                    },
                    { merge: true }
                );
            } else {
                // New version
                batch.set(
                    doc(firestore, 'appVersions', values.version),
                    {
                        version: values.version,
                        isMandatory: values.isMandatory,
                        hasReleaseDate: values.hasReleaseDate,
                        whenReleased: values.hasReleaseDate ? toMillis(values.whenReleased) : toMillis(indeterminateReleaseDate),
                        whenAdded: now,
                        addedBy: superAdmin?.id,
                        state: 'active',
                        content: values.content ? values.content : undefined,
                    }
                );
            }

            return batch.commit().then(() => {
                console.log('saved app version');
            }).catch((error) => {
                console.log('error! error.code', error.code);
                reportError(`Failed to ${selectedVersion ? 'update' : 'create'} app version`, error.message, error, {
                    values
                });
            }).then(() => {
                showToast(`App version ${selectedVersion ? 'updated' : 'created'}!`);
                setShowModal(false);
            }).catch((error) => {
                alertMessage(`Failed to ${selectedVersion ? 'update' : 'create'} app version!`);
                console.error(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    });

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues)
    }, [initialValues, values]);

    return (
        <SeaModal
            title={selectedVersion ? 'App Version' : 'New App Version'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                            <SeaInput
                                label="Version"
                                name="version"
                                value={values.version}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.version ? errors.version : ''}
                                disabled={isFormDisabled}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaCheckbox
                                //mode="beside-input"
                                label="Is Mandatory?"
                                name="isMandatory"
                                checked={values.isMandatory ? true : false}
                                setFieldValue={setFieldValue}
                            >
                                Mandatory Release
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="6">
                            <SeaLabel>Release Date</SeaLabel>
                            <div className="columns">
                                <div>
                                    <SeaCheckbox
                                        //mode="beside-input"
                                        //label="Release Date"
                                        name="hasReleaseDate"
                                        checked={values.hasReleaseDate ? true : false}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                                <div style={{ flex: '1 1 0', paddingLeft: '8px' }}>
                                    <SeaDatetime
                                        //label="Release Date"
                                        name="whenReleased"
                                        value={values.whenReleased}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        error={errors.whenReleased ? errors.whenReleased : ''}
                                        required={true}
                                        disabled={isFormDisabled || !values.hasReleaseDate}
                                    />
                                </div>
                            </div>

                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Content"
                                name="content"
                                value={values.content}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                height={300}
                                error={touched.content ? errors.content : ''}
                                disabled={isFormDisabled}
                            />
                        </IonCol>
                        <IonCol size="12">
                            {renderAppVersionNotes(values.content)}
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="view-modal-buttons">
                    <SeaButton
                        zone="white"
                        type="submit"
                        disabled={isFormDisabled}
                    >
                        {selectedVersion ? 'Update App Version' : 'Create App Version'}
                    </SeaButton>
                    {selectedVersion && selectedVersion.state !== 'deleted' &&
                        <SeaLinkButton
                            mode="standard-link"
                            onClick={(e) => onDelete(e)}
                        >
                            Delete Version
                        </SeaLinkButton>
                    }
                </div>
            </form>
        </SeaModal>
    );
};

export default EditAppVersionModal;
