import React, { useEffect, useMemo, useState } from 'react';
import { IonCol, IonGrid, IonRow, IonSelectOption } from '@ionic/react';
import { addInterval, formatDateRange, formatSeaDate, makeDateTime, subtractInterval } from '../../lib/datesAndTime';
import { sharedState } from '../../shared-state/shared-state';
import SeaSelect from '../SeaSelect/SeaSelect';
import SeaModal from '../SeaModal/SeaModal';
import SeaDate from '../SeaDate/SeaDate';
import SeaButton from '../SeaButton/SeaButton';
import SeaFormHasErrors from '../SeaFormHasErrors/SeaFormHasErrors';
import './SeaDateRange.css';

export type DateRange = { from: string, to: string };

const checkDate = (date: string) => {
    if (date === '') {
        return 'Invalid date';
    }
    try {
        const _date = makeDateTime(date);
        if (_date.toMillis() < 0) {
            return 'This is over 50 years ago!';
        }
    } catch (e) {
        return 'Invalid date';
    }
    return '';
};

interface SeaDateRangeProps {
    level?: number,
    name?: string,
    label?: string,
    zone: 'blue' | 'grey' | 'white'
    dateRange: DateRange,
    setDateRange: React.Dispatch<React.SetStateAction<DateRange>>,
    setDateRangeDescription?: React.Dispatch<React.SetStateAction<string>>,
    width?: string
}

const SeaDateRange: React.FC<SeaDateRangeProps> = ({
    level = 1,
    name,
    label,
    zone,
    dateRange,
    setDateRange,
    setDateRangeDescription,
    width = '100%'
}) => {
    const today = sharedState.today.use()!;

    const [selected, setSelected] = useState('custom');
    const [showModal, setShowModal] = useState(false);
    const [customRange, setCustomRange] = useState<DateRange>({
        from: subtractInterval(today, '30d').toISODate(),
        to: addInterval(today, '1d').toISODate()
    });
    const [editRange, setEditRange] = useState({from: '', to: ''});
    const [touched, setTouched] = useState<{
        from?: boolean,
        to?: boolean,
        submitted?: boolean
    }>({});

    const options = useMemo(() => {
        return [
            {
                id: '7d',
                name: 'Last 7 Days',
                range: {
                    from: subtractInterval(today, '7d').toISODate(),
                    to: addInterval(today, '1d').toISODate()
                }
            }, {
                id: '30d',
                name: 'Last 30 Days',
                range: {
                    from: subtractInterval(today, '30d').toISODate(),
                    to: addInterval(today, '1d').toISODate()
                }
            }, {
                id: 'lastMonth',
                name: 'Last Month',
                range: {
                    from: subtractInterval(today, '1m').toISODate(),
                    to: subtractInterval(today, '1d').toISODate()
                }
            }, {
                id: 'ytd',
                name: 'Year to Date',
                range: {
                    from: subtractInterval(today, '1y').toISODate(),
                    to: addInterval(today, '1d').toISODate()
                }
            }, {
                id: 'last12Months',
                name: 'Last 12 Months',
                range: {
                    from: subtractInterval(today, '1y').toISODate(),
                    to: addInterval(today, '1d').toISODate()
                }
            }, {
                id: 'full',
                name: 'Full History',
                range: {
                    from: "0001-01-01",
                    to: addInterval(today, '1d').toISODate()
                }
            }
        ];
    }, [today]);

    const showCustomRange = useMemo(() => {
        for (let i = 0; i < options.length; i++) {
            if (
                customRange.from === options[i].range.from &&
                customRange.to === options[i].range.to
            ) {
                return false;
            }
        }
        return true;
    }, [customRange, options]);

    const setDateRangeIfChanged = (range: DateRange) => {
        setDateRange((current) => {
            if (current.from !== range.from || current.to !== range.to) {
                return {...range};
            }
            return current;
        });
    };

    useEffect(() => {
        // Set selected based on dateRange
        // First, let's see if we can match an option in options
        for (let i = 0; i < options.length; i++) {
            if (options[i].range.from === dateRange.from && options[i].range.to === dateRange.to) {
                setSelected(options[i].id);
                if (setDateRangeDescription) {
                    setDateRangeDescription(options[i].name);
                }
                return;
            }
        }
        // No options were found therefore we need a custom range
        setCustomRange({
            from: dateRange.from,
            to: dateRange.to
        });
        setSelected('custom');
        if (setDateRangeDescription) {
            setDateRangeDescription(formatDateRange(customRange.from, customRange.to));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, options, today]);

    const fromError = useMemo(() => {
        return checkDate(editRange.from);
    }, [editRange.from]);

    const toError = useMemo(() => {
        const error = checkDate(editRange.to);
        if (error) {
            return error;
        }
        if (fromError) {
            return '';
        }
        if (editRange.to < editRange.from) {
            return 'Cannot be before "From Date"';
        }
        return '';
    }, [editRange, fromError]);

    const onSubmitModal = () => {
        setTouched({
            from: true,
            to: true,
            submitted: true
        });
        if (fromError || toError) {
            return;
        }
        const newRange = {
            from: editRange.from,
            to: editRange.to
        };
        setCustomRange(newRange);
        setDateRangeIfChanged(newRange);
        setShowModal(false);
    };

    return (
        <>
            <SeaSelect
                name={name}
                label={label}
                zone={zone}
                value={selected}
                width={width}
                onchange={(e) => {
                    if (e.detail.value === 'newCustom') {
                        setEditRange({
                            from: formatSeaDate(customRange.from),
                            to: formatSeaDate(customRange.to)
                        });
                        setShowModal(true);
                        return;
                    }
                    setSelected(e.detail.value);
                    if (e.detail.value === 'custom') {
                        setDateRangeIfChanged(customRange);
                        return;
                    } else {
                        for (let i = 0; i < options.length; i++) {
                            if (options[i].id === e.detail.value) {
                                setDateRangeIfChanged(options[i].range);
                                return;
                            }
                        }
                    }
               }}
            >
                {options.map((option) => {
                    return (
                        <IonSelectOption key={option.id} value={option.id}>
                            {option.name}
                        </IonSelectOption>
                    );
                })}
                {showCustomRange &&
                    <IonSelectOption value="custom">
                        {formatDateRange(customRange.from, customRange.to)}
                    </IonSelectOption>
                }
                <IonSelectOption value="newCustom">
                    Custom date range...
                </IonSelectOption>
            </SeaSelect>
            <SeaModal
                showModal={showModal}
                setShowModal={setShowModal}
                level={level + 1}
                size="thin"
                title="Custom Date Range"
                onOpened={() => {
                    setTouched({});
                }}
            >
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaDate
                                label="From Date"
                                name="from"
                                zone="white"
                                value={editRange.from}
                                onchange={(e) => {
                                    setEditRange((current) => {
                                        return {
                                            ...current,
                                            from: e.detail.value
                                        }
                                    });
                                }}
                                onblur={(e) => {
                                    setTouched((current) => {
                                        return {
                                            ...current,
                                            from: true
                                        };
                                    });
                                }}
                                error={touched.from ? fromError : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaDate
                                label="To Date"
                                name="to"
                                zone="white"
                                value={editRange.to}
                                onchange={(e) => {
                                    setEditRange((current) => {
                                        return {
                                            ...current,
                                            to: e.detail.value
                                        }
                                    });
                                }}
                                onblur={(e) => {
                                    setTouched((current) => {
                                        return {
                                            ...current,
                                            to: true
                                        };
                                    });
                                }}
                                error={touched.to ? toError : ''}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>

                {/* <p>touched={JSON.stringify(touched)}</p>
                <p>editRange={JSON.stringify(editRange)}</p>
                <p>fromError={fromError}</p>
                <p>toError={toError}</p> */}

                <div className='grid-row-spacer' style={{width: '100%'}}></div>
                <SeaFormHasErrors
                    hasSubmitted={touched.submitted}
                    isValid={(fromError || toError) ? false : true}
                />
                <div className="view-modal-buttons">
                    <SeaButton
                        zone="white"
                        type="submit"
                        size="wide"
                        onClick={onSubmitModal}
                    >
                        Select Date Range
                    </SeaButton>
                </div>
            </SeaModal>
        </>
    );
};

export default SeaDateRange;
