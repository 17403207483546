import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useState, useEffect } from "react";
import { Category } from "../../lib/categories";
import { sharedState } from "../shared-state";

type SafetyCheckCategory = Category & {
    vesselName: string;
}

export type SafetyCheckCategoriesData = {
    all: SafetyCheckCategory[],
    byId: {
        [id: string]: SafetyCheckCategory
    },
}

export const useSafetyCheckCategoriesForVessels = (shouldLoad?: boolean, vesselIds?: string[]): SafetyCheckCategoriesData | undefined => {
    const vessels = sharedState.vessels.use();
    const [safetyCheckCategories, setSafetyCheckCategories] = useState<SafetyCheckCategoriesData>();
    useEffect(() => {
        setSafetyCheckCategories(undefined);
    
        if (shouldLoad && vesselIds?.length && vessels) {
            return onSnapshot(
                query(
                    collection(firestore, 'safetyCheckCategories'),
                    where('vesselId', 'in', vesselIds),
                    where('state', '==', 'active'),
                    orderBy('name', 'asc'),
                ),
                (snap) => {
                    const items: SafetyCheckCategory[] = [];
                    const byId: {
                        [id: string]: SafetyCheckCategory
                    } = {};
                    snap.docs.forEach((doc) => {
                        const vessel = vessels.byId[doc.data().vesselId];
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                            vesselName: vessel?.name,
                        } as SafetyCheckCategory;
                        items.push(item);
                        byId[item.id] = item;
                    });
                    setSafetyCheckCategories({
                        all: items,
                        byId,
                    });
                },
                (error) => {
                    console.log(`Failed to access SafetyChecks for vesselIds=${vesselIds}`, error.message, error, {
                        vesselIds,
                    });
                }
            );
        }
    }, [shouldLoad, vesselIds, vessels]);

    return safetyCheckCategories;
};