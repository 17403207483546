import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from "react";

//
// Loads all appVersions.
// This can only be used by superAdmin.
//

export type AppVersion = {
    id: string,
    version: string,
    isMandatory: boolean,
    whenReleased: number,
    hasReleaseDate: boolean, // If true, means that the release date is yet to be determined
    content: string,
    state: string,
    whenAdded: number,
    addedBy: string,
};

export type AppVersionData = {
    all: AppVersion[];
    byId: {
        [id: string]: AppVersion
    };
};

export const useAppVersions = () => {
    const [appVersions, setAppVersions] = useState<AppVersionData>();
    
    useEffect(() => {
        setAppVersions(undefined);
        return onSnapshot(
            query(
                collection(firestore, 'appVersions'),
                where('state', '==', 'active'),
                orderBy('whenReleased', 'desc'),
            ),
            (snap) => {
                const all = snap.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    } as AppVersion;
                });
                const byId = {} as {
                    [id: string]: AppVersion
                };
                all.forEach((appVersion: AppVersion) => {
                    byId[appVersion.id] = appVersion;
                });
                setAppVersions({
                    all,
                    byId
                });
            },
            (error) => {
                console.log(`error getting appVersions`, error);
            }
        );
    }, []);
    return appVersions;
};
