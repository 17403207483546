import React, { useMemo } from 'react';
import { formatValue } from '../../../../../lib/util';
import { formatDateShort } from '../../../../../lib/datesAndTime';
import { sharedState } from '../../../../../shared-state/shared-state';
import { UserType } from '../../../../../shared-state/Core/user';
import { useUserTrainingTaskReports } from '../../../../../shared-state/Crew/userTrainingTaskReports';
import { openContextualHelp } from '../../../../../managers/ContextualHelpManager/ContextualHelpManager';
import { useTrainingTaskReportCustomFormsCompletedByUser } from '../../../../../shared-state/Crew/useTrainingTaskReportCustomFormsCompletedByUser';
import { useTrainingTaskReportDocumentsByUser } from '../../../../../shared-state/Crew/useTrainingTaskReportDocumentsByUser';
import SeaFileImage, { SeaFileViewerFile } from '../../../../../components/SeaFileImage/SeaFileImage';
import SeaNoData from '../../../../../components/SeaNoData/SeaNoData';
import './UserTrainingTab.css';

interface UserTrainingTabProps {
    showModal?: boolean,
    selectedUser?: UserType;
}

const UserTrainingTab: React.FC<UserTrainingTabProps> = ({showModal, selectedUser}) => {
    const trainingTasks = sharedState.trainingTasks.use(showModal);
    const customForms = sharedState.customForms.use();
    const userTrainingTaskReports = useUserTrainingTaskReports(showModal ? selectedUser : undefined);
    const trainingTaskReportCustomFormsCompleted = useTrainingTaskReportCustomFormsCompletedByUser(selectedUser?.id);
    const trainingTaskReportDocuments = useTrainingTaskReportDocumentsByUser(selectedUser?.id);

    const filesByTrainingReportId: { [key: string]: SeaFileViewerFile[] } = useMemo(() => {
        const _files: { [key: string]: SeaFileViewerFile[] } = {};
        for (const report of userTrainingTaskReports || []) {
            _files[report.id] = [];
            if (trainingTaskReportDocuments?.byReportId[report.id]) {
                for (const document of trainingTaskReportDocuments.byReportId[report.id]) {
                    _files[report.id].push(...document.files);
                }
            }
            if (trainingTaskReportCustomFormsCompleted?.byReportId[report.id]) {
                for (const customFormCompleted of trainingTaskReportCustomFormsCompleted.byReportId[report.id]) {
                    const customForm = customForms?.byId[customFormCompleted.customFormId];
                    if (customForm) {
                        _files[report.id].push({
                            name: customForm.title,
                            customForm: customForm,
                            customFormCompleted: customFormCompleted,
                        });
                    }
                }   
            }
        }
        return _files;
    }, [userTrainingTaskReports, trainingTaskReportDocuments?.byReportId, trainingTaskReportCustomFormsCompleted?.byReportId, customForms?.byId]);

    return (<>
        <SeaNoData
            dataName="training reports"
            isLoading={!userTrainingTaskReports}
            hasNoData={userTrainingTaskReports && (userTrainingTaskReports.length || 0) === 0}
            isUsingFilter={false}
        />
        <div className={`user-training has-thumbs ${userTrainingTaskReports && userTrainingTaskReports.length > 0 ? 'reveal' : 'conceal'}`}>
            <div className="sea-row-history headings-history">
                <div></div>
                <div>Task</div>
                <div>Date</div>
                <div>Trainer</div>
                <div>Notes</div>
            </div>
            {trainingTasks?.byId && userTrainingTaskReports && userTrainingTaskReports.map((item) => {
                const task = trainingTasks.byId[item.taskId];
                if (task) {
                    return (
                        <div
                            key={item.id}
                            className="sea-card sea-row-history"
                        >
                            <div><SeaFileImage files={filesByTrainingReportId[item.id]} size="tiny"/></div>
                            <div
                                className="truncate"
                                onClick={(e) => openContextualHelp(e, {text: formatValue(task.task)})}
                            >
                                {formatValue(task.task)}
                            </div>
                            <div className="truncate">{formatDateShort(item.dateCompleted)}</div>
                            <div className="truncate">{formatValue(item.trainer)}</div>
                            <div
                                className="truncate"
                                onClick={(e) => openContextualHelp(e, {text: formatValue(item.notes)})}
                            >
                                {formatValue(item.notes)}
                            </div>
                        </div>
                    );
                }
                return undefined;
            })}
        </div>
    </>);
};

export default UserTrainingTab;
