import React, { useRef } from 'react';
import { Document, Image, Page, Path, Svg, Text, View } from '@react-pdf/renderer';
import { makeFormElements } from '../../lib/customForms';
import { pdfStyles, renderIf } from '../../lib/pdf';
import { toFloat } from '../../lib/util';
import { formatDateLonger, formatDatetimeLonger } from '../../lib/datesAndTime';
import { renderFullNameForUserId } from '../../shared-state/Core/users';
import { sharedState } from '../../shared-state/shared-state';
import { CustomForm } from '../../shared-state/CompanyDocuments/CustomForms/customForms';
import { CustomFormCompleted } from '../../shared-state/CompanyDocuments/CustomForms/useCustomFormsCompleted';
import { CustomFormElementType } from '../../components/CustomFormElement/CustomFormElement';
import { renderVesselName } from '../../shared-state/Core/vessels';
import { getImgSrc, getImgSrcFromExt } from '../../lib/files';
import { CustomFormVersionsData } from '../../shared-state/CompanyDocuments/CustomForms/customFormVersions';
import SeaPdfGenerator from '../../components/SeaPdfGenerator/SeaPdfGenerator';

const formWidth = 739;
const usuableWidthCm = 19;
const pixelsToCm = (pixels: number) => {
    // A4 = 21cm
    // usuable width = 19cm
    // form width = 739 pixels
    // cm per pixel = 19 / 739
    const cm = pixels * usuableWidthCm / formWidth;
    return Math.round(cm * 1000) / 1000; // Round to 4 dp
};

const tickSvg = (
    <Svg viewBox="0 0 19 15">
        <Path
            fill="#878787"
            d="M6.992,13.887c0.352,0.352,0.949,0.352,1.301,0L18.629,3.551c0.352-0.352,0.352-0.949,0-1.301l-1.266-1.266
            c-0.352-0.352-0.914-0.352-1.266,0L7.66,9.422L3.688,5.484c-0.352-0.352-0.914-0.352-1.266,0L1.156,6.75
            c-0.352,0.352-0.352,0.949,0,1.301L6.992,13.887z"
        >
        </Path>
    </Svg>
);
const xSvg = (
    <Svg viewBox="0 0 14 13">
        <Path
            fill="#878787"
            d="M9.23,6.35l3.516-3.516c0.457-0.422,0.457-1.125,0-1.547l-0.773-0.773c-0.422-0.457-1.125-0.457-1.547,0L6.91,4.029
            L3.359,0.514c-0.422-0.457-1.125-0.457-1.547,0L1.039,1.287c-0.457,0.422-0.457,1.125,0,1.547L4.555,6.35L1.039,9.9
            c-0.457,0.422-0.457,1.125,0,1.547l0.773,0.773c0.422,0.457,1.125,0.457,1.547,0L6.91,8.705l3.516,3.516
            c0.422,0.457,1.125,0.457,1.547,0l0.773-0.773c0.457-0.422,0.457-1.125,0-1.547L9.23,6.35z"
        >
        </Path>
    </Svg>
);
const naSvg = (
    <Svg viewBox="0 0 24 19">
        <Path
            fill="#878787"
            d="M21.543,18.778c0.246,0.211,0.598,0.141,0.773-0.105l0.703-0.879c0.211-0.246,0.141-0.598-0.105-0.773L2.242,1.024
            C1.996,0.813,1.645,0.884,1.469,1.13L0.766,2.009C0.555,2.255,0.625,2.606,0.871,2.782L21.543,18.778z"
        >
        </Path>
    </Svg>
);


interface CompletedCustomFormPdfProps {
    onCompleted: () => void,
    customForm?: CustomForm,
    completedCustomForm?: CustomFormCompleted
}

export const MakeCustomFormPDF = (customForm?: CustomForm, completedCustomForm?: CustomFormCompleted, customFormVersions?: CustomFormVersionsData, imagesRef?: React.MutableRefObject<{
    reference?: number;
    element?: JSX.Element;
    src: string | Promise<string>;
    fileName: string;
}[]>) => {
    if (customForm === undefined || completedCustomForm === undefined) {
        return null;
    }

    const customFormVersion = customFormVersions?.byFormIdAndVersion[customForm.id][completedCustomForm.version];

    const formElements = makeFormElements(
        customFormVersion,
        completedCustomForm
    );

    const prependedFormElements = [] as CustomFormElementType[];
    if (customForm?.forVesselIds && customForm.forVesselIds[0] !== 'none') {
        prependedFormElements.push({
            n: -2,
            id: 'vessels',
            width: (customForm?.vesselsElement?.width) ? Math.floor(customForm.vesselsElement.width) : 100,
            label: (customForm?.vesselsElement?.label) ? customForm.vesselsElement.label : 'SELECT VESSELS / FACILITIES',
            value: completedCustomForm?.vesselIds
        });
    }
    if (customForm?.forCrew) {
        prependedFormElements.push({
            n: -1,
            id: 'crew',
            width: (customForm?.crewElement?.width) ? Math.floor(customForm.crewElement.width) : 100,
            label: (customForm?.crewElement?.label) ? customForm.crewElement.label : 'SELECT PERSONNEL',
            value: completedCustomForm?.personnelIds
        });
    }
    formElements.forEach((element) => {
        prependedFormElements.push(element);
    });

    // Divide up into rows
    const rows = [] as any[];
    let row = [] as any[];
    rows.push(row);
    let width = 0;

    prependedFormElements.forEach((element) => {
        const w = toFloat(element.width, 100);
        width += w;
        if (width > 100) {
            row = [] as any[];
            rows.push(row);
            width = w;
        }
        row.push(element);
    });

    const getElementStyle = (element: any) => {
        const get = (columns: number, totalColumns: number) => {
            return {
                ...pdfStyles.element,
                maxWidth: pixelsToCm(formWidth * (columns / totalColumns))+'cm'
            };
        };
        switch (toFloat(element.width, 0)) {
            case 25:
                return get(1, 4);
            case 33.33:
                return get(1, 3);
            case 50:
                return get(1, 2);
            case 66.66:
                return get(2, 3);
            case 75:
                return get(3, 4);
            default:
                return get(1, 1);
        }
    };

    const getTextFontSize = (element: any) => {
        switch (element.size) {
            case 'tiny':
                return '8pt';
            case 'small':
                return '9pt';
            case 'medium':
                return '10pt';
            case 'large':
                return '12pt';
        }
        return '10pt';
    }

    const getHeadingFontSize = (element: any) => {
        switch (element.size) {
            case 'tiny':
                return '11pt';
            case 'small':
                return '14pt';
            case 'medium':
                return '18pt';
            case 'large':
                return '24pt';
        }
        return '10pt';
    }

    const renderInput = (element: any, value: any) => {
        const minLines = element.lines ? element.lines : 1;
        return (
            <View
                style={{
                    ...pdfStyles.input,
                    minHeight: (pixelsToCm(37 + minLines * 15)+'cm') // height = 37px + (lines * 15px)
                }}
                wrap={false}
            >
                <Text>{value}</Text>
            </View>
        );
    };

    const renderElement = (element: any) => {
        switch (element.id) {
            case 'heading':
                return (
                    <Text
                        style={{
                            fontWeight: 700,
                            color: '#373946',
                            fontSize: getHeadingFontSize(element)
                        }}
                        wrap={false}
                    >
                        {element.heading}
                    </Text>
                );
            case 'text':
                return (
                    <Text
                        style={{ fontSize: getTextFontSize(element) }}
                        wrap={false}
                    >
                        {element.text}
                    </Text>
                );
            case 'line':
                return (
                    <View style={{
                        backgroundColor: '#dbdce0',
                        height: '1pt'
                    }}>
                    </View>
                );
            case 'spacer':
                return (
                    <View style={{
                        height: pixelsToCm(element.height)+'cm'
                    }}>
                    </View>
                );
            case 'checkbox':
                return (
                    <View
                        style={{
                            flexDirection: 'row'
                        }}
                        wrap={false}
                    >
                        <View style={{
                            ...pdfStyles.input,
                            ...pdfStyles.checkbox,
                            padding: '0.3cm 0.24cm 0cm 0.23cm'
                        }}>
                            {element.value && tickSvg}
                        </View>
                        <View style={{
                            alignSelf: 'center',
                            padding: '0 0 0 0.2571cm',
                            width: '100%'
                        }}>
                            <Text>{element.label}</Text>
                        </View>
                    </View>
                );
            case 'input':
                return renderInput(element, element.value);
            case 'textarea':
                return renderInput(element, element.value);
            case 'dropdown':
                return renderInput(element, element.value);
            case 'yesno':
                return (<>
                    <View style={{
                        flexDirection: 'row'
                    }}>
                        <View style={{
                            color: '#575860',
                            fontSize: '8pt',
                            margin: '0 0.1286cm 0 0',
                            textAlign: 'center',
                            width: pdfStyles.checkbox.width,
                            height: '0.35cm'
                        }}>
                            <Text>YES</Text>
                        </View>
                        <View style={{
                            color: '#575860',
                            fontSize: '8pt',
                            margin: '0 0.1286cm 0 0',
                            textAlign: 'center',
                            width: pdfStyles.checkbox.width,
                            height: '0.35cm'
                        }}>
                            <Text>NO</Text>
                        </View>
                    </View>
                    <View
                        style={{
                            flexDirection: 'row'
                        }}
                        wrap={false}
                    >
                        <View style={{
                            ...pdfStyles.input,
                            ...pdfStyles.checkbox,
                            padding: '0.3cm 0.24cm 0cm 0.23cm',
                            margin: '0.1542cm 0.1286cm 0.1542cm 0'
                        }}>
                            {element.value === 1 && tickSvg}
                        </View>
                        <View style={{
                            ...pdfStyles.input,
                            ...pdfStyles.checkbox,
                            padding: '0.3cm 0.3cm 0cm 0.3cm',
                            margin: '0.1542cm 0.1286cm 0.1542cm 0'
                        }}>
                            {element.value === -1 && xSvg}
                        </View>
                        <View style={{
                            alignSelf: 'center',
                            padding: '0 0 0 0.2571cm',
                            width: '100%' // This prevents text overflow to the right
                        }}>
                            <Text>{element.label}</Text>
                        </View>
                    </View>
                </>);
            case 'checks':
                return (<>
                    <View style={{
                        flexDirection: 'row'
                    }}>
                        <View style={{
                            color: '#575860',
                            fontSize: '8pt',
                            margin: '0 0.1286cm 0 0',
                            textAlign: 'center',
                            width: pdfStyles.checkbox.width,
                            height: '0.35cm',
                            padding: '0.055cm 0 0.055cm 0'
                        }}>
                            {tickSvg}
                        </View>
                        <View style={{
                            color: '#575860',
                            fontSize: '8pt',
                            margin: '0 0.1286cm 0 0',
                            textAlign: 'center',
                            width: pdfStyles.checkbox.width,
                            height: '0.35cm'
                        }}>
                            <Text>FAULT</Text>
                        </View>
                        <View style={{
                            color: '#575860',
                            fontSize: '8pt',
                            margin: '0 0.1286cm 0 0',
                            textAlign: 'center',
                            width: pdfStyles.checkbox.width,
                            height: '0.35cm'
                        }}>
                            <Text>N/A</Text>
                        </View>
                    </View>
                    {
                        element.options.map((option: string, index: number) => {
                            return (
                                <View
                                    key={index}
                                    wrap={false}
                                >
                                    <View style={{
                                        flexDirection: 'row'
                                    }}>
                                        <View style={{
                                            ...pdfStyles.input,
                                            ...pdfStyles.checkbox,
                                            padding: '0.3cm 0.24cm 0cm 0.23cm',
                                            margin: '0.1542cm 0.1286cm 0.1542cm 0'
                                        }}>
                                            {element.value[index] === 'pass' && tickSvg}
                                        </View>
                                        <View style={{
                                            ...pdfStyles.input,
                                            ...pdfStyles.checkbox,
                                            padding: '0.3cm 0.3cm 0cm 0.3cm',
                                            margin: '0.1542cm 0.1286cm 0.1542cm 0'
                                        }}>
                                            {element.value[index] === 'fail' && xSvg}
                                        </View>
                                        <View style={{
                                            ...pdfStyles.input,
                                            ...pdfStyles.checkbox,
                                            padding: '0.22cm 0.18cm 0cm 0.2cm',
                                            margin: '0.1542cm 0.1286cm 0.1542cm 0'
                                        }}>
                                            {element.value[index] === 'na' && naSvg}
                                        </View>
                                        <View style={{
                                            alignSelf: 'center',
                                            padding: '0 0 0 0.2571cm',
                                            margin: '0.1542cm 0.1286cm 0.1542cm 0',
                                            flex: '1 0 1'
                                        }}>
                                            <Text>{option}</Text>
                                        </View>
                                    </View>
                                </View>
                            );
                        })
                    }
                </>);
            case 'date':
                return renderInput(element, formatDateLonger(element.value));
            case 'datetime':
                return renderInput(element, formatDatetimeLonger(element.value, ', '));
            case 'files':
                return (
                    <View>
                        {element.value.map((file: any) => {
                            if (!file) {
                                return <></>
                            }
                            const ext = file.ext.toLowerCase();
                            const isImage = (ext === 'jpg' || ext === 'jpeg' || ext === 'png');
                            const imgSrc = (
                                isImage ?
                                getImgSrc(file.state, file.id, file.ext, 'full') :
                                getImgSrcFromExt(file.ext, 'medium')
                            );
                            const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                            if (isImage) {
                                imagesRef?.current.push({
                                    src: imgSrc,
                                    fileName: fileName
                                });
                            }
                            return (
                                <View
                                    key={file.id}
                                    style={{
                                        flexDirection: 'row',
                                        paddingBottom: '0.1543cm'
                                    }}
                                >
                                    <Image
                                        src={imgSrc}
                                        style={{
                                            ...pdfStyles.checkbox,
                                            objectFit: 'cover',
                                            marginRight: '0.2571cm'
                                        }}
                                    />
                                    <Text style={{ alignSelf: 'center' }}>
                                        {fileName}
                                    </Text>
                                    {isImage &&
                                        <Text style={{
                                            alignSelf: 'center',
                                            padding: '0 0 0.25cm 0.02cm',
                                            fontSize: '7pt'
                                        }}>
                                            {imagesRef?.current.length}
                                        </Text>
                                    }
                                </View>
                            );
                        })}
                    </View>
                );

            case 'signature':
                let imgSrc;
                if (element.value) {
                    imgSrc = getImgSrc(element.value.state, element.value.id, element.value.ext, 'sig');
                } else {
                    imgSrc = '/assets/blank.png';
                }
                return (
                    <View
                        style={{
                            ...pdfStyles.input,
                            padding: '0 0 0 0',
                            width: pixelsToCm(352)+'cm',
                            height: pixelsToCm(202)+'cm'
                        }}
                        wrap={false}
                    >
                        <Image
                            src={imgSrc}
                            style={{
                                width: pixelsToCm(350)+'cm',
                                height: pixelsToCm(200)+'cm'
                            }}
                        />
                    </View>
                );
            case 'vessels':
                return renderInput(element,
                    element?.value?.reduce((result: string, vesselId: string) => {
                        return `${result}${result.length > 0 ? ', ' : ''}${renderVesselName(vesselId)}`;
                    }, '')
                );
            case 'crew':
                return renderInput(element,
                    element?.value?.reduce((result: string, personnelId: string) => {
                        return `${result}${result.length > 0 ? ', ' : ''}${
                            renderFullNameForUserId(personnelId)
                        }`;
                    }, '')
                );
            default:
                return null;
        }
    };

    if (imagesRef) {
        imagesRef.current = [];
    }
    return (
        <Document
            title={`${customForm.title}`}
            author="Sea Flux"
            creator="Sea Flux"
            producer="Sea Flux"
        >
            <Page
                style={{
                    ...pdfStyles.page
                }}
                orientation="portrait"
                size="A4" // 21cm x 29.7cm
                wrap={true}
            >
                <View style={{
                    marginBottom: '0.45cm'
                }}>
                    <Text style={pdfStyles.heading}>{customForm.title}</Text>
                    {completedCustomForm &&
                        <Text style={pdfStyles.headingText}>
                            Completed by {renderFullNameForUserId(completedCustomForm?.addedBy)}, {formatDateLonger(completedCustomForm?.whenAdded)}
                        </Text>
                    }
                </View>
                {rows.map((row, index: number) => {
                    return (
                        <View key={`row${index}`} style={pdfStyles.customFormRow}>
                            {/* {customForm?.forVesselIds && customForm.forVesselIds[0] !== 'none' &&
                                <View
                                    style={getElementStyle({
                                        width: (customForm?.vesselsElement?.width) ? Math.floor(customForm.vesselsElement.width) : 100
                                    })}
                                    wrap={false}
                                >
                                    <Text style={pdfStyles.labelNoUC}>
                                        {(customForm?.vesselsElement?.label) ? customForm.vesselsElement.label : 'SELECT VESSELS / FACILITIES'}
                                    </Text>
                                    {renderElement({
                                        id: 'vessels',
                                        value: completedCustomForm?.vesselIds
                                    })}
                                </View>
                            } */}
                            {row.map((element: any) => {
                                return (
                                    <View
                                        key={element.n}
                                        style={getElementStyle(element)}
                                        wrap={(element.id === 'checks')}
                                    >
                                        {renderIf(element.label && element.id !== 'checkbox' && element.id !== 'yesno',
                                            <Text style={pdfStyles.labelNoUC}>{element.label}</Text>
                                        )}
                                        {renderElement(element)}
                                    </View>
                                );
                            })}
                        </View>
                    );
                })}
            </Page>
            {(imagesRef?.current || []).length > 0 &&
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    {imagesRef?.current.map((img: any, index: number) => {
                        return (
                            <View
                                key={index}
                                style={{
                                    maxWidth: '19cm',
                                    margin: '0 1cm 0 1cm',
                                    //flexDirection: 'column',
                                    paddingBottom: '0.5cm'
                                }}
                                wrap={false}
                            >
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={{
                                        alignSelf: 'center',
                                        padding: '0 0.02cm 0.25cm 0',
                                        fontSize: '7pt'
                                    }}>
                                        {index + 1}
                                    </Text>
                                    <Text>{img.fileName}</Text>
                                </View>
                                <Image
                                    src={img.src}
                                    style={{
                                        objectFit: 'scale-down',
                                        //maxWidth: '19cm',
                                        //maxHeight: '10cm',
                                        objectPositionX: 0,
                                        objectPositionY: 0,
                                        //backgroundColor: '#eeff99'
                                    }}
                                />
                            </View>
                        );
                    })}

                </Page>
            }
        </Document>
    );
};

const CompletedCustomFormPdf: React.FC<CompletedCustomFormPdfProps> = ({
    onCompleted,
    customForm,
    completedCustomForm
}) => {
    const customFormVersions = sharedState.customFormVersions.use();
    const imagesRef = useRef<{ reference?: number, element?: JSX.Element, src: string | Promise<string>, fileName: string }[]>([]);

    // const MakeCustomFormPDF = useCallback(() => {
    //     if (customForm === undefined || completedCustomForm === undefined) {
    //         return null;
    //     }

    //     const customFormVersion = customFormVersions?.byFormIdAndVersion[customForm.id][completedCustomForm.version];

    //     const formElements = makeFormElements(
    //         customFormVersion,
    //         completedCustomForm
    //     );

    //     const prependedFormElements = [] as CustomFormElementType[];
    //     if (customForm?.forVesselIds && customForm.forVesselIds[0] !== 'none') {
    //         prependedFormElements.push({
    //             n: -2,
    //             id: 'vessels',
    //             width: (customForm?.vesselsElement?.width) ? Math.floor(customForm.vesselsElement.width) : 100,
    //             label: (customForm?.vesselsElement?.label) ? customForm.vesselsElement.label : 'SELECT VESSELS / FACILITIES',
    //             value: completedCustomForm?.vesselIds
    //         });
    //     }
    //     if (customForm?.forCrew) {
    //         prependedFormElements.push({
    //             n: -1,
    //             id: 'crew',
    //             width: (customForm?.crewElement?.width) ? Math.floor(customForm.crewElement.width) : 100,
    //             label: (customForm?.crewElement?.label) ? customForm.crewElement.label : 'SELECT PERSONNEL',
    //             value: completedCustomForm?.personnelIds
    //         });
    //     }
    //     formElements.forEach((element) => {
    //         prependedFormElements.push(element);
    //     });

    //     // Divide up into rows
    //     const rows = [] as any[];
    //     let row = [] as any[];
    //     rows.push(row);
    //     let width = 0;

    //     prependedFormElements.forEach((element) => {
    //         const w = toFloat(element.width, 100);
    //         width += w;
    //         if (width > 100) {
    //             row = [] as any[];
    //             rows.push(row);
    //             width = w;
    //         }
    //         row.push(element);
    //     });

    //     const getElementStyle = (element: any) => {
    //         const get = (columns: number, totalColumns: number) => {
    //             return {
    //                 ...pdfStyles.element,
    //                 maxWidth: pixelsToCm(formWidth * (columns / totalColumns))+'cm'
    //             };
    //         };
    //         switch (toFloat(element.width, 0)) {
    //             case 25:
    //                 return get(1, 4);
    //             case 33.33:
    //                 return get(1, 3);
    //             case 50:
    //                 return get(1, 2);
    //             case 66.66:
    //                 return get(2, 3);
    //             case 75:
    //                 return get(3, 4);
    //             default:
    //                 return get(1, 1);
    //         }
    //     };

    //     const getTextFontSize = (element: any) => {
    //         switch (element.size) {
    //             case 'tiny':
    //                 return '8pt';
    //             case 'small':
    //                 return '9pt';
    //             case 'medium':
    //                 return '10pt';
    //             case 'large':
    //                 return '12pt';
    //         }
    //         return '10pt';
    //     }

    //     const getHeadingFontSize = (element: any) => {
    //         switch (element.size) {
    //             case 'tiny':
    //                 return '11pt';
    //             case 'small':
    //                 return '14pt';
    //             case 'medium':
    //                 return '18pt';
    //             case 'large':
    //                 return '24pt';
    //         }
    //         return '10pt';
    //     }

    //     const renderInput = (element: any, value: any) => {
    //         const minLines = element.lines ? element.lines : 1;
    //         return (
    //             <View
    //                 style={{
    //                     ...pdfStyles.input,
    //                     minHeight: (pixelsToCm(37 + minLines * 15)+'cm') // height = 37px + (lines * 15px)
    //                 }}
    //                 wrap={false}
    //             >
    //                 <Text>{value}</Text>
    //             </View>
    //         );
    //     };

    //     const renderElement = (element: any) => {
    //         switch (element.id) {
    //             case 'heading':
    //                 return (
    //                     <Text
    //                         style={{
    //                             fontWeight: 700,
    //                             color: '#373946',
    //                             fontSize: getHeadingFontSize(element)
    //                         }}
    //                         wrap={false}
    //                     >
    //                         {element.heading}
    //                     </Text>
    //                 );
    //             case 'text':
    //                 return (
    //                     <Text
    //                         style={{ fontSize: getTextFontSize(element) }}
    //                         wrap={false}
    //                     >
    //                         {element.text}
    //                     </Text>
    //                 );
    //             case 'line':
    //                 return (
    //                     <View style={{
    //                         backgroundColor: '#dbdce0',
    //                         height: '1pt'
    //                     }}>
    //                     </View>
    //                 );
    //             case 'spacer':
    //                 return (
    //                     <View style={{
    //                         height: pixelsToCm(element.height)+'cm'
    //                     }}>
    //                     </View>
    //                 );
    //             case 'checkbox':
    //                 return (
    //                     <View
    //                         style={{
    //                             flexDirection: 'row'
    //                         }}
    //                         wrap={false}
    //                     >
    //                         <View style={{
    //                             ...pdfStyles.input,
    //                             ...pdfStyles.checkbox,
    //                             padding: '0.3cm 0.24cm 0cm 0.23cm'
    //                         }}>
    //                             {element.value && tickSvg}
    //                         </View>
    //                         <View style={{
    //                             alignSelf: 'center',
    //                             padding: '0 0 0 0.2571cm',
    //                             width: '100%'
    //                         }}>
    //                             <Text>{element.label}</Text>
    //                         </View>
    //                     </View>
    //                 );
    //             case 'input':
    //                 return renderInput(element, element.value);
    //             case 'textarea':
    //                 return renderInput(element, element.value);
    //             case 'dropdown':
    //                 return renderInput(element, element.value);
    //             case 'yesno':
    //                 return (<>
    //                     <View style={{
    //                         flexDirection: 'row'
    //                     }}>
    //                         <View style={{
    //                             color: '#575860',
    //                             fontSize: '8pt',
    //                             margin: '0 0.1286cm 0 0',
    //                             textAlign: 'center',
    //                             width: pdfStyles.checkbox.width,
    //                             height: '0.35cm'
    //                         }}>
    //                             <Text>YES</Text>
    //                         </View>
    //                         <View style={{
    //                             color: '#575860',
    //                             fontSize: '8pt',
    //                             margin: '0 0.1286cm 0 0',
    //                             textAlign: 'center',
    //                             width: pdfStyles.checkbox.width,
    //                             height: '0.35cm'
    //                         }}>
    //                             <Text>NO</Text>
    //                         </View>
    //                     </View>
    //                     <View
    //                         style={{
    //                             flexDirection: 'row'
    //                         }}
    //                         wrap={false}
    //                     >
    //                         <View style={{
    //                             ...pdfStyles.input,
    //                             ...pdfStyles.checkbox,
    //                             padding: '0.3cm 0.24cm 0cm 0.23cm',
    //                             margin: '0.1542cm 0.1286cm 0.1542cm 0'
    //                         }}>
    //                             {element.value === 1 && tickSvg}
    //                         </View>
    //                         <View style={{
    //                             ...pdfStyles.input,
    //                             ...pdfStyles.checkbox,
    //                             padding: '0.3cm 0.3cm 0cm 0.3cm',
    //                             margin: '0.1542cm 0.1286cm 0.1542cm 0'
    //                         }}>
    //                             {element.value === -1 && xSvg}
    //                         </View>
    //                         <View style={{
    //                             alignSelf: 'center',
    //                             padding: '0 0 0 0.2571cm',
    //                             width: '100%' // This prevents text overflow to the right
    //                         }}>
    //                             <Text>{element.label}</Text>
    //                         </View>
    //                     </View>
    //                 </>);
    //             case 'checks':
    //                 return (<>
    //                     <View style={{
    //                         flexDirection: 'row'
    //                     }}>
    //                         <View style={{
    //                             color: '#575860',
    //                             fontSize: '8pt',
    //                             margin: '0 0.1286cm 0 0',
    //                             textAlign: 'center',
    //                             width: pdfStyles.checkbox.width,
    //                             height: '0.35cm',
    //                             padding: '0.055cm 0 0.055cm 0'
    //                         }}>
    //                             {tickSvg}
    //                         </View>
    //                         <View style={{
    //                             color: '#575860',
    //                             fontSize: '8pt',
    //                             margin: '0 0.1286cm 0 0',
    //                             textAlign: 'center',
    //                             width: pdfStyles.checkbox.width,
    //                             height: '0.35cm'
    //                         }}>
    //                             <Text>FAULT</Text>
    //                         </View>
    //                         <View style={{
    //                             color: '#575860',
    //                             fontSize: '8pt',
    //                             margin: '0 0.1286cm 0 0',
    //                             textAlign: 'center',
    //                             width: pdfStyles.checkbox.width,
    //                             height: '0.35cm'
    //                         }}>
    //                             <Text>N/A</Text>
    //                         </View>
    //                     </View>
    //                     {
    //                         element.options.map((option: string, index: number) => {
    //                             return (
    //                                 <View
    //                                     key={index}
    //                                     wrap={false}
    //                                 >
    //                                     <View style={{
    //                                         flexDirection: 'row'
    //                                     }}>
    //                                         <View style={{
    //                                             ...pdfStyles.input,
    //                                             ...pdfStyles.checkbox,
    //                                             padding: '0.3cm 0.24cm 0cm 0.23cm',
    //                                             margin: '0.1542cm 0.1286cm 0.1542cm 0'
    //                                         }}>
    //                                             {element.value[index] === 'pass' && tickSvg}
    //                                         </View>
    //                                         <View style={{
    //                                             ...pdfStyles.input,
    //                                             ...pdfStyles.checkbox,
    //                                             padding: '0.3cm 0.3cm 0cm 0.3cm',
    //                                             margin: '0.1542cm 0.1286cm 0.1542cm 0'
    //                                         }}>
    //                                             {element.value[index] === 'fail' && xSvg}
    //                                         </View>
    //                                         <View style={{
    //                                             ...pdfStyles.input,
    //                                             ...pdfStyles.checkbox,
    //                                             padding: '0.22cm 0.18cm 0cm 0.2cm',
    //                                             margin: '0.1542cm 0.1286cm 0.1542cm 0'
    //                                         }}>
    //                                             {element.value[index] === 'na' && naSvg}
    //                                         </View>
    //                                         <View style={{
    //                                             alignSelf: 'center',
    //                                             padding: '0 0 0 0.2571cm',
    //                                             margin: '0.1542cm 0.1286cm 0.1542cm 0',
    //                                             flex: '1 0 1'
    //                                         }}>
    //                                             <Text>{option}</Text>
    //                                         </View>
    //                                     </View>
    //                                 </View>
    //                             );
    //                         })
    //                     }
    //                 </>);
    //             case 'date':
    //                 return renderInput(element, formatDateLonger(element.value));
    //             case 'datetime':
    //                 return renderInput(element, formatDatetimeLonger(element.value, ', '));
    //             case 'files':
    //                 return (
    //                     <View>
    //                         {element.value.map((file: any) => {
    //                             if (!file) {
    //                                 return <></>
    //                             }
    //                             const ext = file.ext.toLowerCase();
    //                             const isImage = (ext === 'jpg' || ext === 'jpeg' || ext === 'png');
    //                             const imgSrc = (
    //                                 isImage ?
    //                                 getImgSrc(file.state, file.id, file.ext, 'full') :
    //                                 getImgSrcFromExt(file.ext, 'medium')
    //                             );
    //                             const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
    //                             if (isImage) {
    //                                 imagesRef.current.push({
    //                                     src: imgSrc,
    //                                     fileName: fileName
    //                                 });
    //                             }
    //                             return (
    //                                 <View
    //                                     key={file.id}
    //                                     style={{
    //                                         flexDirection: 'row',
    //                                         paddingBottom: '0.1543cm'
    //                                     }}
    //                                 >
    //                                     <Image
    //                                         src={imgSrc}
    //                                         style={{
    //                                             ...pdfStyles.checkbox,
    //                                             objectFit: 'cover',
    //                                             marginRight: '0.2571cm'
    //                                         }}
    //                                     />
    //                                     <Text style={{ alignSelf: 'center' }}>
    //                                         {fileName}
    //                                     </Text>
    //                                     {isImage &&
    //                                         <Text style={{
    //                                             alignSelf: 'center',
    //                                             padding: '0 0 0.25cm 0.02cm',
    //                                             fontSize: '7pt'
    //                                         }}>
    //                                             {imagesRef.current.length}
    //                                         </Text>
    //                                     }
    //                                 </View>
    //                             );
    //                         })}
    //                     </View>
    //                 );

    //             case 'signature':
    //                 let imgSrc;
    //                 if (element.value) {
    //                     imgSrc = getImgSrc(element.value.state, element.value.id, element.value.ext, 'sig');
    //                 } else {
    //                     imgSrc = '/assets/blank.png';
    //                 }
    //                 return (
    //                     <View
    //                         style={{
    //                             ...pdfStyles.input,
    //                             padding: '0 0 0 0',
    //                             width: pixelsToCm(352)+'cm',
    //                             height: pixelsToCm(202)+'cm'
    //                         }}
    //                         wrap={false}
    //                     >
    //                         <Image
    //                             src={imgSrc}
    //                             style={{
    //                                 width: pixelsToCm(350)+'cm',
    //                                 height: pixelsToCm(200)+'cm'
    //                             }}
    //                         />
    //                     </View>
    //                 );
    //             case 'vessels':
    //                 return renderInput(element,
    //                     element?.value?.reduce((result: string, vesselId: string) => {
    //                         return `${result}${result.length > 0 ? ', ' : ''}${renderVesselName(vesselId)}`;
    //                     }, '')
    //                 );
    //             case 'crew':
    //                 return renderInput(element,
    //                     element?.value?.reduce((result: string, personnelId: string) => {
    //                         return `${result}${result.length > 0 ? ', ' : ''}${
    //                             renderFullNameForUserId(personnelId)
    //                         }`;
    //                     }, '')
    //                 );
    //             default:
    //                 return null;
    //         }
    //     };

    //     imagesRef.current = [];
    //     return (
    //         <Document
    //             title={`${customForm.title}`}
    //             author="Sea Flux"
    //             creator="Sea Flux"
    //             producer="Sea Flux"
    //         >
    //             <Page
    //                 style={{
    //                     ...pdfStyles.page
    //                 }}
    //                 orientation="portrait"
    //                 size="A4" // 21cm x 29.7cm
    //                 wrap={true}
    //             >
    //                 <View style={{
    //                     marginBottom: '0.45cm'
    //                 }}>
    //                     <Text style={pdfStyles.heading}>{customForm.title}</Text>
    //                     {completedCustomForm &&
    //                         <Text style={pdfStyles.headingText}>
    //                             Completed by {renderFullNameForUserId(completedCustomForm?.addedBy)}, {formatDateLonger(completedCustomForm?.whenAdded)}
    //                         </Text>
    //                     }
    //                 </View>
    //                 {rows.map((row, index: number) => {
    //                     return (
    //                         <View key={`row${index}`} style={pdfStyles.customFormRow}>
    //                             {/* {customForm?.forVesselIds && customForm.forVesselIds[0] !== 'none' &&
    //                                 <View
    //                                     style={getElementStyle({
    //                                         width: (customForm?.vesselsElement?.width) ? Math.floor(customForm.vesselsElement.width) : 100
    //                                     })}
    //                                     wrap={false}
    //                                 >
    //                                     <Text style={pdfStyles.labelNoUC}>
    //                                         {(customForm?.vesselsElement?.label) ? customForm.vesselsElement.label : 'SELECT VESSELS / FACILITIES'}
    //                                     </Text>
    //                                     {renderElement({
    //                                         id: 'vessels',
    //                                         value: completedCustomForm?.vesselIds
    //                                     })}
    //                                 </View>
    //                             } */}
    //                             {row.map((element: any) => {
    //                                 return (
    //                                     <View
    //                                         key={element.n}
    //                                         style={getElementStyle(element)}
    //                                         wrap={(element.id === 'checks')}
    //                                     >
    //                                         {renderIf(element.label && element.id !== 'checkbox' && element.id !== 'yesno',
    //                                             <Text style={pdfStyles.labelNoUC}>{element.label}</Text>
    //                                         )}
    //                                         {renderElement(element)}
    //                                     </View>
    //                                 );
    //                             })}
    //                         </View>
    //                     );
    //                 })}
    //             </Page>
    //             {imagesRef.current.length > 0 &&
    //                 <Page
    //                     style={{
    //                         ...pdfStyles.page
    //                     }}
    //                     orientation="portrait"
    //                     size="A4"
    //                     wrap={true}
    //                 >
    //                     {imagesRef.current.map((img: any, index: number) => {
    //                         return (
    //                             <View
    //                                 key={index}
    //                                 style={{
    //                                     maxWidth: '19cm',
    //                                     margin: '0 1cm 0 1cm',
    //                                     //flexDirection: 'column',
    //                                     paddingBottom: '0.5cm'
    //                                 }}
    //                                 wrap={false}
    //                             >
    //                                 <View style={{ flexDirection: 'row' }}>
    //                                     <Text style={{
    //                                         alignSelf: 'center',
    //                                         padding: '0 0.02cm 0.25cm 0',
    //                                         fontSize: '7pt'
    //                                     }}>
    //                                         {index + 1}
    //                                     </Text>
    //                                     <Text>{img.fileName}</Text>
    //                                 </View>
    //                                 <Image
    //                                     src={img.src}
    //                                     style={{
    //                                         objectFit: 'scale-down',
    //                                         //maxWidth: '19cm',
    //                                         //maxHeight: '10cm',
    //                                         objectPositionX: 0,
    //                                         objectPositionY: 0,
    //                                         //backgroundColor: '#eeff99'
    //                                     }}
    //                                 />
    //                             </View>
    //                         );
    //                     })}

    //                 </Page>
    //             }
    //         </Document>
    //     );
    // }, [customForm, completedCustomForm, customFormVersions]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={customForm?.title}
            MakePdf={() => MakeCustomFormPDF(customForm, completedCustomForm, customFormVersions, imagesRef)}
            requestedFrom="ViewCompletedCustomForm"
        />
    );
};

export default CompletedCustomFormPdf;
