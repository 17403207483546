import React, { useEffect, useMemo, useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { renderCategoryNames } from '../../../../lib/categories';
import { addedToRiskRegister, authoritiesNotifiedForReview, controlStrategies, injuryConclusions, lostTimes, yesNoNa } from '../../../../pages/HealthSafety/Incidents/incidents-util';
import { sharedState } from '../../../../shared-state/shared-state';
import { Incident } from '../../../../shared-state/HealthSafety/incidents';
import { IncidentReview } from '../../../../shared-state/HealthSafety/incidentReviews';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { formatDateShort } from '../../../../lib/datesAndTime';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaSignatureImage from '../../../../components/SeaSignatureImage/SeaSignatureImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import SeaAuthorship from '../../../../components/SeaAuthorship/SeaAuthorship';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import { canView, permissionLevels } from '../../../../shared-state/Core/userPermissions';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import { CorrectiveAction } from '../../../../shared-state/HealthSafety/correctiveActions';
import ViewCorrectiveAction from '../../CorrectiveActions/ViewCorrectiveAction/ViewCorrectiveAction';

interface IncidentReviewContentProps {
    showModal: boolean;
    incident: Incident;
    review: IncidentReview;
    level?: number;
}

const IncidentReviewContent: React.FC<IncidentReviewContentProps> = ({ showModal, incident, review, level = 1 }) => {
    const incidentCauses = sharedState.incidentCauses.use(showModal);
    const correctiveActions = sharedState.correctiveActions.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const [showCorrectiveActionModal, setShowCorrectiveActionModal] = useState(false);
    const [correctiveActionToUpdate, setCorrectiveActionToUpdate] = useState<CorrectiveAction | undefined>(undefined);

    const correctiveActionsAttached = useMemo(() => {
        if (review?.correctiveActionIds && correctiveActions?.byId.all) {
            const correctiveActionsAttached = [];
            for (const id of review?.correctiveActionIds) {
                if (correctiveActions?.byId.all[id]) {
                    correctiveActionsAttached.push(correctiveActions?.byId.all[id]);
                }
            }
            return correctiveActionsAttached;
        }
        return undefined;
    }, [review?.correctiveActionIds, correctiveActions]);

    const onViewCorrectiveAction = (e: React.MouseEvent<Element, MouseEvent>, correctiveAction: CorrectiveAction) => {
        setCorrectiveActionToUpdate(correctiveAction);
        setShowCorrectiveActionModal(true);
    };

    // Keep selected corrective action fresh
    useEffect(() => {
        if (correctiveActionToUpdate) {
            const correctiveAction = correctiveActions?.byId.all[correctiveActionToUpdate.id];
            if (correctiveAction) {
                setCorrectiveActionToUpdate(correctiveAction);
            }
        }
    }, [correctiveActions, correctiveActionToUpdate]);

    return (
        <>
            <div className="info-image">
                <SeaFileImage files={review?.files} size="medium" showOthers />
            </div>
            <SeaAuthorship item={review} creationText="Review created" />
            <SeaGrid>
                <SeaGridCell label="Severity of Event" w="50">
                    {formatValue(review?.severity)}
                </SeaGridCell>
                <SeaGridCell label="Notified to Authorities?" w="50">
                    {formatValue(review?.notifiedAuthorities && authoritiesNotifiedForReview[review?.notifiedAuthorities])}
                </SeaGridCell>
                <SeaGridCell label="Causes of this incident / event" w="50">
                    {formatValue(renderCategoryNames(review?.causeIds, incidentCauses))}
                </SeaGridCell>
                <SeaGridCell label="Control Strategies" w="50">
                    {formatValue(
                        review?.strategies
                            ?.map((strategy: string) => {
                                return controlStrategies[strategy];
                            })
                            .join(', ')
                    )}
                </SeaGridCell>
                <SeaGridCell label="Added to risk assessments?" w="50">
                    {formatValue(review?.addedToRiskRegister && addedToRiskRegister[review?.addedToRiskRegister])}
                </SeaGridCell>
                <SeaGridCell label="External investigation required?" w="50">
                    {formatValue(review?.externalRequired && yesNoNa[review?.externalRequired])}
                </SeaGridCell>
                {incident?.type === 'injury' && (
                    <>
                        <SeaGridCell label="Injury Conclusion" w="50">
                            {formatValue(
                                review?.injuryConclusions
                                    ?.map((conclusion: string) => {
                                        return injuryConclusions[conclusion];
                                    })
                                    .join(', ')
                            )}
                        </SeaGridCell>
                        <SeaGridCell label="Time Lost to Injury" w="50">
                            {formatValue(review?.lostTime && lostTimes[review?.lostTime])}
                        </SeaGridCell>
                    </>
                )}
                <SeaGridCell label="Analysis of Review" w="100">
                    {formatValue(review?.analysis)}
                </SeaGridCell>

                {!licenseeSettings?.hasCorrectiveActions || !canView('correctiveActions') ? (
                    <SeaGridCell label="Corrective actions or tasks required" w="100">
                        {formatValue(review?.prevention)}
                    </SeaGridCell>
                ) : null}
            </SeaGrid>

            <RequirePermissions role="correctiveActions" level={permissionLevels.VIEW} licenseePermission="hasCorrectiveActions">
                <div className="end-info-line"></div>
                <h2 className="for-view-modal">Corrective actions</h2>
                <SeaGrid>
                    {review?.prevention ? (
                        <SeaGridCell label="Corrective actions or tasks required" w="100">
                            {formatValue(review?.prevention)}
                        </SeaGridCell>
                    ) : (
                        <>
                            <SeaNoData dataName="corrective action" isHistory={true} isLoading={!correctiveActionsAttached} hasNoData={correctiveActionsAttached && !correctiveActionsAttached.length ? true : false} />
                            <div className={`corrective-action-history ${correctiveActionsAttached && correctiveActionsAttached.length ? 'reveal' : 'conceal'}`}>
                                <div className="sea-row-history headings-history">
                                    <div></div>
                                    <div>Title</div>
                                    <div>Assigned to</div>
                                    <div>Due date</div>
                                    <div>Tags</div>
                                    <div></div>
                                </div>
                                {correctiveActionsAttached?.map((item) => {
                                    return (
                                        <div key={item.id} className="sea-card sea-row-history clickable" onClick={(e) => onViewCorrectiveAction(e, item)}>
                                            <div className="date">{formatDateShort(item.whenCompleted)}</div>
                                            <div className="truncate-3 item-title">{item.title}</div>
                                            <div className="truncate by">{formatValue(renderFullNameForUserId(item.completedBy))}</div>
                                            <div className="date">{formatDateShort(item.dateDue)}</div>
                                            <div className="truncate-3 tags">
                                                {formatValue(
                                                    item.tags
                                                        ?.map((tag: string) => {
                                                            return tag;
                                                        })
                                                        .join(', ')
                                                )}
                                            </div>
                                            <div className="image">
                                                <SeaFileImage files={item.files} size="tiny" />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </SeaGrid>

                <div className="end-info-line"></div>
            </RequirePermissions>

            <SeaGrid>
                <SeaGridCell label="Signature" w="100">
                    {review?.signature ? <SeaSignatureImage file={review?.signature} /> : '-'}
                </SeaGridCell>
            </SeaGrid>

            <ViewCorrectiveAction showModal={showCorrectiveActionModal} setShowModal={setShowCorrectiveActionModal} selectedItem={correctiveActionToUpdate} level={level + 1} />
        </>
    );
};

export default IncidentReviewContent;
