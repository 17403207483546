import React from 'react';
import { sharedState } from '../../shared-state/shared-state';
import SeaBreadcrumb from '../../components/SeaBreadcrumb/SeaBreadcrumb';
import SeaLink from '../../components/SeaLink/SeaLink';
import StandardPageLayout from '../../layouts/StandardPageLayout/StandardPageLayout';
import SeaTabsGroup from '../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../components/SeaTab/SeaTab';
import SeaTabContent from '../../components/SeaTabContent/SeaTabContent';
import Incidents from './Incidents/Incidents';
import CorrectiveActions from './CorrectiveActions/CorrectiveActions';
import HazardRegisterDeprecated from './HazardRegisterDeprecated/HazardRegisterDeprecated';
import RiskRegister from './RiskRegister/RiskRegister';
import HealthSafetyMeetings from './HealthSafetyMeetings/HealthSafetyMeetings';
import DangerousGoodsRegister from './DangerousGoodsRegister/DangerousGoodsRegister';
import './HealthSafety.css';

const HealthSafety: React.FC = () => {
    const licenseeSettings = sharedState.licenseeSettings.use();
    const selectedSectionTab = sharedState.selectedSectionTab.use();

    if (licenseeSettings === undefined) return null;

    return (
        <StandardPageLayout section="healthsafety">
            <SeaBreadcrumb>
                <SeaLink to="/healthSafety">Health &amp; Safety</SeaLink>
            </SeaBreadcrumb>

            <SeaTabsGroup id="HealthSafety" selectedTab={selectedSectionTab} setTab={sharedState.selectedSectionTab.set}>
                {licenseeSettings.hasIncidents ? <SeaTab tab="Incidents">Incident / Event Reports</SeaTab> : null}
                {licenseeSettings.hasCorrectiveActions ? <SeaTab tab="CorrectiveActions" requireRole="correctiveActions">Corrective Actions</SeaTab> : null}
                {(licenseeSettings.riskRegister?.version > 1)
                    ? (
                        <SeaTab tab="RiskRegister" requireRole="hazardRegister">Risk Assessments</SeaTab>
                    ) : (
                        <SeaTab tab="HazardRegister" requireRole="hazardRegister">Hazard Register</SeaTab>
                    )
                }
                <SeaTab tab="HealthSafetyMeetings" requireRole="healthSafetyMeetings" setTab={sharedState.selectedSectionTab.set}>Health &amp; Safety<br/> Meetings</SeaTab>
                <SeaTab tab="DangerousGoodsRegister" requireRole="dangerousGoodsRegister" setTab={sharedState.selectedSectionTab.set}>Dangerous Goods Register</SeaTab>
            </SeaTabsGroup>

            <SeaTabContent tab="Incidents" selectedTab={selectedSectionTab}>
                <Incidents visible={selectedSectionTab === 'Incidents'}/>
            </SeaTabContent>
            <SeaTabContent tab="CorrectiveActions" selectedTab={selectedSectionTab}>
                <CorrectiveActions visible={selectedSectionTab === 'CorrectiveActions'}/>
            </SeaTabContent>
            {(licenseeSettings.riskRegister?.version > 1)
            ? (
                <SeaTabContent tab="RiskRegister" selectedTab={selectedSectionTab}>
                    <RiskRegister visible={selectedSectionTab === 'RiskRegister'}/>
                </SeaTabContent>
            ) : (
                <SeaTabContent tab="HazardRegister" selectedTab={selectedSectionTab}>
                    <HazardRegisterDeprecated visible={selectedSectionTab === 'HazardRegister'}/>
                </SeaTabContent>
            )}
            <SeaTabContent tab="HealthSafetyMeetings" selectedTab={selectedSectionTab}>
                <HealthSafetyMeetings visible={selectedSectionTab === 'HealthSafetyMeetings'}/>
            </SeaTabContent>
            <SeaTabContent tab="DangerousGoodsRegister" selectedTab={selectedSectionTab}>
                <DangerousGoodsRegister visible={selectedSectionTab === 'DangerousGoodsRegister'}/>
            </SeaTabContent>

        </StandardPageLayout>
    );
};

export default HealthSafety;
