import React, { ReactNode, useMemo } from 'react';
import { useLexDropDown } from '../LexDropDown/LexDropDown';
import './LexButton.css';

interface LexButtonProps {
    children: ReactNode,
    title?: string,
    onClick?: (e: React.MouseEvent) => void,
    disabled?: boolean,
    style?: React.CSSProperties,
    active?: boolean, // Used for toggles
    hideDropDownPopover?: boolean // Used for parent LexDropDown
}

const LexButton: React.FC<LexButtonProps> = ({
    children,
    title,
    onClick,
    disabled,
    style,
    active,
    hideDropDownPopover
}) => {
    const { hidePopover } = useLexDropDown();

    const deviceClass = useMemo(() => {
        const rootElement = document.documentElement;
        return rootElement.classList.contains('mouse-device') ? 'mouse-device' : 'touch-device';
    }, []);

    return (
        <button
            className={`lex-button pushy no-select${disabled ? ' disabled' : ''}${active ? ' active' : ''} ${deviceClass}`}
            disabled={disabled}
            type="button"
            title={title}
            onClick={(e: React.MouseEvent) => {
                if (onClick) {
                    onClick(e);
                }
                if (hideDropDownPopover && hidePopover) {
                    hidePopover();
                }
            }}
            aria-label={title}
            style={style}
        >
            {children}
        </button>
    );
};

export default LexButton;
