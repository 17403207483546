import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { isPlatform } from '@ionic/core';
import * as serviceWorker from './serviceWorker';
// import { StatusBar, Style } from '@capacitor/status-bar';

// Add classes for different platforms
const container = document.getElementById('root');
if (container) {
    if (isPlatform('ios')) container.classList.add('platform-ios');
    if (isPlatform('android')) container.classList.add('platform-android');
    if (isPlatform('hybrid')) container.classList.add('platform-hybrid');
    //if (true) container.classList.add('platform-hybrid'); // To force android emulator to think it is a device -- Also need to comment out desktop below
    if (isPlatform('desktop')) container.classList.add('platform-desktop');
    if (isPlatform('ipad')) container.classList.add('platform-ipad');
    if (isPlatform('iphone')) container.classList.add('platform-iphone');
    if (isPlatform('tablet')) container.classList.add('platform-tablet');
    if (isPlatform('mobile')) container.classList.add('platform-mobile');
    if (window?.navigator?.userAgent?.indexOf('Windows') !== -1) container.classList.add('platform-windows');

    if (window.matchMedia('(pointer: fine)').matches) container.classList.add('mouse-device');
    if (window.matchMedia('(pointer: coarse)').matches) container.classList.add('touch-device');

    // Configure status bar for ios
    if (isPlatform('ios') && isPlatform('hybrid')) {
        //StatusBar.show();
        // StatusBar obsolete
        // StatusBar?.setStyle({
        //     style: Style.Dark // light text on dark background
        // });
    }

    const root = createRoot(container!); 
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();