import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { sharedState } from "../../shared-state";
import { useEffect, useState } from "react";
import { getDayOffset, getDayOffsetMillis, MIN_DATE, warnDays } from '../../../lib/datesAndTime';
import { VesselCertificate } from "../../VesselDocuments/vesselCertificates";
import { registerFiles } from "../../FileSyncSystem/filesToCache";

export const useReportingVesselCertificates = (
    isActive: boolean,
    selectedVesselIds: string[],
    selectedStatuses: string[] | undefined
) => {
    const today = sharedState.today.use()!;
    const [reportingVesselCertificates, setReportingVesselCertificates] = useState<VesselCertificate[]>();

    useEffect(() => {
        if ((selectedStatuses?.length || 0) === 0 || (selectedVesselIds.length || 0) === 0) {
            // Nothing to load
            setReportingVesselCertificates([]);
            return;
        }
        setReportingVesselCertificates(undefined);
        if (isActive && selectedVesselIds?.length && selectedStatuses) {
            const includeOverdue = selectedStatuses.indexOf('overdue') !== -1;
            const includeUpcoming = selectedStatuses.indexOf('upcoming') !== -1;
            if (!includeOverdue && !includeUpcoming) {
                return;
            }

            const baseConstraints = [where('state', '==', 'active')];
            if (includeOverdue && includeUpcoming) {
                baseConstraints.push(where('dateExpires', '<', getDayOffset(warnDays.vesselCertificates[0])));
            } else if (includeOverdue) {
                // Include overdue only
                baseConstraints.push(where('dateExpires', '<', today));
            } else {
                // Include upcoming only
                baseConstraints.push(where('dateExpires', '<', getDayOffsetMillis(warnDays.vesselCertificates[0])), where('dateExpires', '>=', today));
            }

            return setupArrayQueryListener(
                'vesselCertificates', // what
                collection(firestore, 'vesselCertificates'),
                baseConstraints,
                'vesselId',
                'in',
                selectedVesselIds,
                [orderBy('dateExpires', 'asc')],
                (docs: QueryDocumentSnapshot<DocumentData>[], isCombined: boolean) => {
                    // processDocs
                    let all = [] as VesselCertificate[];
                    docs.forEach((doc) => {
                        const certificate = {
                            id: doc.id,
                            ...doc.data()
                        } as VesselCertificate;
                        registerFiles(certificate.files, 'vesselCertificates', certificate);
                        all.push(certificate);
                    });

                    if (isCombined) {
                        all.sort((a, b) => {
                            //return (a.dateExpires ?? 0) - (b.dateExpires ?? 0);
                            return (a.dateExpires ?? MIN_DATE).localeCompare(b.dateExpires ?? MIN_DATE);
                        });
                    }

                    setReportingVesselCertificates(all);
                }
            );
        }
    }, [isActive, selectedVesselIds, selectedStatuses, today]);

    return reportingVesselCertificates;
};
