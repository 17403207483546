import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Document, View, Image, Text, Page } from '@react-pdf/renderer';
import { pdfStyles, pixelsToCm } from '../../lib/pdf';
import { formatValue, formatTextAreaText } from '../../lib/util';
import { getImgSrc, getImgSrcFromExt, makeSeaFiles } from '../../lib/files';
import { DrillReport } from '../../shared-state/VesselSafety/drillReports';
import { renderFullNameForUserId } from '../../shared-state/Core/users';
import { sharedState } from '../../shared-state/shared-state';
import { renderFullName } from '../../shared-state/Core/users';
import { formatDate } from '../../lib/datesAndTime';
import SeaPdfGenerator from '../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../components/PdfItemsHeader/PdfItemsHeader';

interface DrillReportPdfProps {
    onCompleted: () => void,
    selectedItem: DrillReport,
    signature?: any,
}

const DrillReportPdf: React.FC<DrillReportPdfProps> = ({
    onCompleted,
    selectedItem,
    signature
}) => {
    const vesselDrills = sharedState.vesselDrills.use(true);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const imagesRef = useRef<{ reference?: number, element?: JSX.Element, src: string | Promise<string>, fileName: string }[]>([]);

    const reportTitle = useMemo(() => {
        if (selectedItem) {
            return `Drill Report by ${renderFullNameForUserId(selectedItem?.addedBy)}`;
        }
        return '';
    }, [selectedItem]);

    const getImageSrcs = useCallback((files: string[], setLoaded: (value: React.SetStateAction<boolean>) => void) => {
        imagesRef.current = [];
        if (files?.length) {
            for (const _file of files) {
                const file = makeSeaFiles([_file])[0];
                const ext = file.ext?.toLowerCase();
                const isImage = (ext === 'jpg' || ext === 'jpeg' || ext === 'png');
                const imgSrc = (
                    isImage ?
                        getImgSrc(
                            file.state!,
                            file.id!,
                            file.ext!,
                            'full'
                        ) :
                        getImgSrcFromExt(file.ext, 'medium')
                );
                const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                if (isImage) {
                    imagesRef.current.push({
                        src: imgSrc,
                        fileName: fileName
                    });
                }
            }
        }
        setLoaded(true);
    }, []);

    useEffect(() => {
        getImageSrcs(selectedItem.files, setImagesLoaded);
    }, [getImageSrcs, selectedItem.files]);


    const MakePDF = useCallback(() => {
        if (!selectedItem) return (<></>);
        return (
            <Document
                title={reportTitle}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={reportTitle}
                        subHeading=''
                        userFullName={renderFullName()}
                    />

                    {/* Date Completed and Location */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Date Completed</Text>
                            <Text>{formatDate(selectedItem?.dateCompleted)}</Text>
                        </View>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Location</Text>
                            <Text>
                                {formatValue(selectedItem?.location)}
                            </Text>
                        </View>
                    </View>

                    {/* People Involved and Drills Completed */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>People Involved</Text>
                            <Text>
                                {selectedItem?.crewInvolvedIds?.map((crewId, index) => {
                                    if (renderFullNameForUserId(crewId)) {
                                        if (index === 0) {
                                            return renderFullNameForUserId(crewId);
                                        } else {
                                            return `, ${renderFullNameForUserId(crewId)}`;
                                        }
                                    } else {
                                        return '-';
                                    }
                                })}
                            </Text>
                        </View>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Drills Completed</Text>
                            <Text>
                                {selectedItem?.drills?.map((drill, index) => {
                                    if (index === 0) {
                                        return (
                                            vesselDrills?.byId[drill.id]
                                                ? vesselDrills?.byId[drill.id].name
                                                : drill.name
                                        )
                                    } else {
                                        return (
                                            vesselDrills?.byId[drill.id]
                                                ? `, ${vesselDrills?.byId[drill.id].name}`
                                                : `, ${drill.name}`
                                        )
                                    }
                                })}
                            </Text>
                        </View>
                    </View>

                    {/* Scenario and Equipment */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Scenario</Text>
                            <Text>{formatValue(formatTextAreaText(selectedItem?.scenario))}</Text>
                        </View>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Equipment</Text>
                            <Text>
                                {formatValue(formatTextAreaText(selectedItem?.equipment))}
                            </Text>
                        </View>
                    </View>

                    {/* Further Training and Modification to current operating procedures */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Further Training</Text>
                            <Text>{formatValue(formatTextAreaText(selectedItem?.furtherTraining))}</Text>
                        </View>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Modification to current operating procedures</Text>
                            <Text>
                                {formatValue(formatTextAreaText(selectedItem?.modification))}
                            </Text>
                        </View>
                    </View>

                    {/* Signature */}
                    <View style={pdfStyles.greyLine} />
                    <View wrap={false} style={pdfStyles.rowNoSpace}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Signed off by</Text>
                        </View>
                    </View>
                    {signature ? (
                        <View wrap={false} style={pdfStyles.rowNoSpace}>
                            <View
                                style={{
                                    padding: '0 0 0 0',
                                    width: pixelsToCm(352) + 'cm',
                                    height: pixelsToCm(202) + 'cm'
                                }}
                                wrap={false}
                            >
                                <Image
                                    src={signature}
                                    style={{
                                        width: pixelsToCm(350) + 'cm',
                                        height: pixelsToCm(200) + 'cm'
                                    }}
                                />
                            </View>
                        </View>
                    ) : (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text>-</Text>
                            </View>
                        </View>
                    )}

                </Page>
                {imagesRef.current.length > 0 &&
                    <Page
                        style={{
                            ...pdfStyles.page
                        }}
                        orientation="portrait"
                        size="A4"
                        wrap={true}
                    >
                        {imagesRef.current.map((img: any, index: number) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        maxWidth: '19cm',
                                        margin: '0 1cm 0 1cm',
                                        paddingBottom: '0.5cm'
                                    }}
                                    wrap={false}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{
                                            alignSelf: 'center',
                                            padding: '0 0.02cm 0.25cm 0',
                                            fontSize: '7pt'
                                        }}>
                                            {index + 1}
                                        </Text>
                                        <Text>{img.fileName}</Text>
                                    </View>
                                    <Image
                                        src={img.src}
                                        style={{
                                            objectFit: 'scale-down',
                                            objectPositionX: 0,
                                            objectPositionY: 0,
                                        }}
                                    />
                                </View>
                            );
                        })}
                    </Page>
                }
            </Document>
        )
    }, [reportTitle, selectedItem, signature, vesselDrills?.byId]);

    if (imagesLoaded) {
        return (
            <SeaPdfGenerator
                onCompleted={onCompleted}
                pdfTitle={reportTitle}
                MakePdf={MakePDF}
                requestedFrom="DrillReport"
            />
        );
    } else return <></>
};
export default DrillReportPdf;