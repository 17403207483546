import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTimeZonesData } from '../../../../shared-state/General/useTimeZonesData';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import './TimeZones.css';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore } from '../../../../lib/firebase';
import { sharedState } from '../../../../shared-state/shared-state';


interface TimeZonesProps {
    visible: boolean
}

const TimeZones: React.FC<TimeZonesProps> = ({ visible}) => {
    const licenseeSettings = sharedState.licenseeSettings.use(visible);
    const [now, setNow] = useState(new Date());
    const [haveSomethingToSave, setHaveSomethingToSave] = useState(false);
    const timeZoneData = useTimeZonesData();

    useEffect(() => {
        if (visible) {
            const interval = setInterval(() => {
                setNow(new Date());
            }, 1000);
            return () => {
                clearInterval(interval);
            }
        }
    }, [visible]);

    const browserTimeZones = useMemo(() => {
        const identifiers = (Intl as any).supportedValuesOf('timeZone');
        const hasIdentifier = {} as { [identifier: string]: true }
        identifiers.forEach((identifier: string) => {
            hasIdentifier[identifier] = true;
        });
        return {
            identifiers,
            hasIdentifier 
        };
    }, []);

    const serverTimeZones = useMemo(() => {
        if (timeZoneData?.identifiers) {
            const identifiers = timeZoneData.identifiers;
            const hasIdentifier = {} as { [identifier: string]: true }
            identifiers.forEach((identifier: string) => {
                hasIdentifier[identifier] = true;
            });
            return {
                identifiers,
                hasIdentifier 
            };
        }
        return undefined;
    }, [timeZoneData]);

    const combinedIdentifiers = useMemo(() => {
        if (browserTimeZones && serverTimeZones) {
            const problemIdentifiers = [] as string[];
            const happyIdentifiers = [] as string[];

            browserTimeZones.identifiers.forEach((identifier: string) => {
                if (serverTimeZones.hasIdentifier[identifier]) {
                    happyIdentifiers.push(identifier);
                } else {
                    problemIdentifiers.push(identifier);
                }
            });

            setHaveSomethingToSave((problemIdentifiers?.length > 0) ? true : false);

            serverTimeZones.identifiers.forEach((identifier: string) => {
                if (browserTimeZones.hasIdentifier[identifier]) {
                    // already added
                } else {
                    problemIdentifiers.push(identifier);
                }
            });

            return [...problemIdentifiers, ...happyIdentifiers];
        }
        return undefined;
    }, [browserTimeZones, serverTimeZones]);

    const saveTimeZonesToFirestore = useCallback(() => {
        combinedIdentifiers?.sort();
        setDoc(
            doc(firestore, 'global', 'timeZones'),
            {
                identifiers: combinedIdentifiers,
                touched: serverTimestamp()
            }
        ).catch((error) => {
            console.error('Failed to save timeZones', error);
        });
    }, [combinedIdentifiers]);

    return (
        <>
            <div>
                Browser timeZone: <b>{Intl.DateTimeFormat().resolvedOptions().timeZone}</b>, Licensee timeZone: <b>{licenseeSettings?.timeZone ?? 'Not Set'}</b>
            </div>
            {combinedIdentifiers &&
                <div style={{ marginTop: '8px' }}>
                    <SeaButton onClick={saveTimeZonesToFirestore} disabled={!haveSomethingToSave}>
                        Save Time Zones to Firestore
                    </SeaButton>
                </div>
            }
            <div className="timeZones">
                <div className="sea-row headings">
                    <div>
                        TimeZone Identifier
                    </div>
                    <div>
                        Status
                    </div>
                    <div>
                        Current time
                    </div>
                </div>
                {combinedIdentifiers?.map((identifier: string) => {
                    return (
                        <div key={identifier} className={`sea-card sea-row ${(browserTimeZones?.hasIdentifier[identifier] && serverTimeZones?.hasIdentifier[identifier])?'':'fail'}`}>
                            <div>
                                {identifier}
                            </div>
                            <div>
                                {!browserTimeZones?.hasIdentifier[identifier] && 'Unknown in browser!'}
                                {!serverTimeZones?.hasIdentifier[identifier] && 'Not stored in Firestore!'}
                                {browserTimeZones?.hasIdentifier[identifier] && serverTimeZones?.hasIdentifier[identifier] && 'OK'}
                            </div>
                            <div>
                                {now.toLocaleDateString(undefined, {
                                    timeZone: identifier
                                })}
                                &nbsp;
                                {now.toLocaleTimeString(undefined, {
                                    timeZone: identifier,
                                    timeZoneName: 'long'
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default TimeZones;
