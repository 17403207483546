import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, DocumentData, DocumentReference, serverTimestamp } from "firebase/firestore";
import { hasArrayChanged, haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate, addInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName, renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { UserType } from '../../../../shared-state/Core/user';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { haveFilesChanged, makeSeaFiles, SeaFile } from '../../../../lib/files';
import { TrainingTask, TrainingTasksData } from '../../../../shared-state/Crew/trainingTasks';
import { TrainingTaskReport, TrainingTaskReportsData } from '../../../../shared-state/Crew/useTrainingTaskReports';
import { TrainingTaskReportDocument, useTrainingTaskReportDocuments } from '../../../../shared-state/Crew/useTrainingTaskReportDocuments';
import { useTrainingTaskReportCustomFormsCompleted } from '../../../../shared-state/Crew/useTrainingTaskReportCustomFormsCompleted';
import { CustomFormCompleted } from '../../../../shared-state/CompanyDocuments/CustomForms/useCustomFormsCompleted';
import { CustomForm } from '../../../../shared-state/CompanyDocuments/CustomForms/customForms';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import { canCreate } from '../../../../shared-state/Core/userPermissions';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaInputError from '../../../../components/SeaInputError/SeaInputError';
import CompleteCustomForm from '../../../CompanyDocuments/CustomForms/CompleteCustomForm/CompleteCustomForm';
import EditTrainingTaskReportDocumentsModal from './TrainingTaskReportDocumentsModal';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import CompletedCustomFormPdf from '../../../../exports/CustomForms/CompletedCustomFormPdf';
import BrowseCustomForms from '../../../CompanyDocuments/CustomForms/BrowseCustomForms/BrowseCustomForms';
import ViewCompletedCustomForm from '../../../CompanyDocuments/CustomForms/ViewCompletedCustomForm/ViewCompletedCustomForm';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaLabel from '../../../../components/SeaLabel/SeaLabel';
import './EditTrainingReport.css';


interface EditTrainingReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setTaskUserHistory?: React.Dispatch<React.SetStateAction<TrainingTaskReport[] | undefined>>,
    level?: number,
    vesselId?: string, // default vessel in form
    trainingTask?: TrainingTask,
    trainingTaskReport?: TrainingTaskReport,
    trainingTasks?: TrainingTasksData,
    trainingTaskReports?: TrainingTaskReportsData,
    selectedReportAndCrew?: {userId: string, reportId: string}
}

const limitByOptions = (values: string[], options?: {id: string, name: string}[]) => {
    const results = [] as string[];
    if (options) {
        values.forEach((value: string) => {
            for (let i = 0; i < options.length; i++) {
                if (options[i].id === value) {
                    results.push(value);
                    break;
                }
            }
        });
    }
    return results;
};

const EditTrainingReport: React.FC<EditTrainingReportProps> = ({showModal, setShowModal, setTaskUserHistory, level = 1, vesselId, trainingTask, trainingTasks, trainingTaskReport, trainingTaskReports, selectedReportAndCrew}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const users = sharedState.users.use(showModal);
    const customForms = sharedState.customForms.use(showModal);
    const [completedBy, setCompletedBy] = useState<string[]>([]);
    const [tasksCompleted, setTasksCompleted] = useState<string[]>([]);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [filesForUser, setFilesForUser] = useState<SeaFile[]>([]);
    const [customFormsForUser, setCustomFormsForUser] = useState<string[]>([]);
    const [taskNotes, setTaskNotes] = useState<{
        [key: string]: string
    }>({});
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [exportType, setExportType] = useState<ExportType>(undefined);
    const [selectedTrainingTaskReportDocuments, setSelectedTrainingTaskReportDocuments] = useState<TrainingTaskReportDocument>();
    const [showEditTrainingTaskReportDocuments, setShowEditTrainingTaskReportDocuments] = useState(false);
    const [showViewCompletedCustomForm, setShowViewCompletedCustomForm] = useState(false);
    const [showCompleteCustomForm, setShowCompleteCustomForm] = useState(false);
    const [showBrowseCustomForms, setShowBrowseCustomForms] = useState(false);
    const [selectedCustomForm, setSelectedCustomForm] = useState<CustomForm>();
    const [selectedCompletedCustomForm, setSelectedCompletedCustomForm] = useState<CustomFormCompleted>();
    // Needed to link trainingtaskReportDocuments if creating a new report
    const [newReportRef, setNewReportRef] = useState<DocumentReference<DocumentData>>();

    const reportId = useMemo(() => {
        if (trainingTaskReport) {
            return trainingTaskReport.id;
        }
        if (newReportRef) {
            return newReportRef.id;
        }
        return undefined;
    }, [trainingTaskReport, newReportRef]);

    const trainingTaskReportCustomForms = useTrainingTaskReportCustomFormsCompleted(trainingTask?.id, reportId, vesselId, selectedReportAndCrew?.userId);
    const trainingTaskReportDocuments = useTrainingTaskReportDocuments(trainingTask?.id, reportId, vesselId, selectedReportAndCrew?.userId);

    const isCreating = useMemo(() => {
        return trainingTask ? false : true;
    }, [trainingTask]);

    const isEditing = useMemo(() => {
        return trainingTask && trainingTaskReport ? true : false;
    }, [trainingTask, trainingTaskReport]);

    const initialValues = useMemo(() => {
        if (trainingTaskReport) {
            return {
                ...trainingTaskReport,
                vesselId: trainingTaskReport.vesselId,
                dateCompleted: trainingTaskReport.dateCompleted,
                trainer: trainingTaskReport.trainer,
            };
        }
        return {
            vesselId: trainingTask ? trainingTask.vesselId : vesselId ? vesselId : '',
            dateCompleted: formatSeaDate(),
            trainer: ''+renderFullName(),
        };
    }, [trainingTaskReport, trainingTask, vesselId]);

    const formsAndFiles = useMemo(() => {
        if (trainingTaskReportCustomForms && trainingTaskReportDocuments) {
            const _formsAndFiles: (TrainingTaskReportDocument | CustomFormCompleted)[] = [];
            trainingTaskReportCustomForms.all.forEach((form: CustomFormCompleted) => {
                _formsAndFiles.push(form);
            });
            trainingTaskReportDocuments.all.forEach((document: TrainingTaskReportDocument) => {
                _formsAndFiles.push(document);
            });
            _formsAndFiles.sort((a, b) => {
                return b.whenAdded - a.whenAdded;
            });
            return _formsAndFiles;
        }
        return undefined;
    }, [trainingTaskReportCustomForms, trainingTaskReportDocuments]);

    // Keep selectedCompletedCustomForm fresh
    useEffect(() => {
        if (
            selectedCompletedCustomForm?.id &&
            trainingTaskReportCustomForms?.byId[selectedCompletedCustomForm.id]
        ) {
            setSelectedCompletedCustomForm(trainingTaskReportCustomForms.byId[selectedCompletedCustomForm.id]);
        }
    }, [trainingTaskReportCustomForms, selectedCompletedCustomForm?.id]);

    // Keep selectedCustomForm fresh
    useEffect(() => {
        if (
            selectedCompletedCustomForm?.customFormId &&
            customForms?.byId[selectedCompletedCustomForm.customFormId]
        ) {
            setSelectedCustomForm(customForms.byId[selectedCompletedCustomForm.customFormId]);
        }
    }, [customForms, selectedCompletedCustomForm?.customFormId]);

    const onCompleteForm = () => {
        setShowBrowseCustomForms(true);
    };

    const onAddDocuments = () => {
        setSelectedTrainingTaskReportDocuments(undefined);
        setShowEditTrainingTaskReportDocuments(true);
    };

    const onCustomFormSelected = (customForm: CustomForm) => {
        setSelectedCustomForm(customForm);
        setSelectedCompletedCustomForm(undefined);
        setShowCompleteCustomForm(true);
    };

    const taskNotesDirty = useMemo(() => {
        if (trainingTask && (trainingTaskReport?.notes || taskNotes[trainingTask.id])) {
            return trainingTaskReport?.notes !== taskNotes[trainingTask.id];
        }
        return false;
    }, [trainingTaskReport, taskNotes, trainingTask]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setCompletedBy(selectedReportAndCrew ? [selectedReportAndCrew.userId] : []);
        setTasksCompleted(trainingTask ? [trainingTask.id] : selectedReportAndCrew ? [selectedReportAndCrew.reportId] : []);
        setTaskNotes(trainingTask ? { [trainingTask.id]: trainingTaskReport?.notes || ''} : {});
        setCustomFormsForUser(trainingTaskReportCustomForms?.all?.map((form) => form.id) ?? []);
        setFilesForUser(makeSeaFiles(trainingTaskReportDocuments?.all?.map((doc) => doc.id) ?? []));
        setFiles([]);
        if (!isCreating) {
            const newRef = doc(collection(firestore, 'trainingTaskReports'));
            setNewReportRef(newRef);
        }
    };

    useEffect(() => {
        setCustomFormsForUser(trainingTaskReportCustomForms?.all?.map((form) => form.id) ?? []);
    }, [trainingTaskReportCustomForms?.all]);

    useEffect(() => {
        setFilesForUser(makeSeaFiles(trainingTaskReportDocuments?.all?.map((doc) => doc.id) ?? []));
    }, [trainingTaskReportDocuments?.all]);

    // const onOpenPdf = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, customForm: CustomForm, completedCustomForm: CustomFormCompleted) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setSelectedCustomForm(customForm);
    //     setSelectedCompletedCustomForm(completedCustomForm);
    //     setExportType('pdf');
    // };
    

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            vesselId: Yup.string().max(200).required(),
            dateCompleted: Yup.date().required().min(...notTooOld),
            trainer: Yup.string().max(500).required(),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('trainingTaskReport')) { return; }

            if (!isStatesValid) {
                return;
            }
            const _completedBy = limitByOptions(completedBy, completedByOptions);
            
            // Process form
            const batch = splittableBatch(firestore);
            const batchTrace = makeBatchTrace(batch, 'trainingTaskReports');

            const processTaskReport = (taskId: string, reportRef: DocumentReference<DocumentData>, isFirst: boolean) => {
                const task = trainingTasks?.byId[taskId];
                if (task === undefined) {
                    return;
                }
                if (isFirst) {
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = reportRef.id;
                }
                batch.set(
                    reportRef,
                    {
                        taskId: taskId,
                        vesselId: data.vesselId,
                        licenseeId: licenseeId,
                        addedBy: userId,
                        whenAdded: batchTrace.whenAction,
                        completedBy: _completedBy,
                        dateCompleted: data.dateCompleted,
                        dateDue: addInterval(data.dateCompleted, task.interval).toISODate(),
                        notes: taskNotes[taskId] ? taskNotes[taskId] : undefined,
                        trainer: data.trainer ? data.trainer : undefined,
                        state: 'active',
                        touched: serverTimestamp(),
                    }
                );

                // Update trainingTask.whenLatestCompleted values if necessary
                let dateLastCompleted = data.dateCompleted;
            
                trainingTaskReports?.byTaskId[taskId]?.forEach((report, index) => {
                    if (
                        index < 10 &&
                        report.dateCompleted > dateLastCompleted
                    ) {
                        dateLastCompleted = report.dateCompleted;
                    }
                });
                if (task.dateLastCompleted !== dateLastCompleted) {
                    batch.set(
                        doc(firestore, 'trainingTasks', taskId),
                        {
                            dateLastCompleted: dateLastCompleted,
                            dateDue: addInterval(dateLastCompleted, task.interval).toISODate(),
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );
                    onCollectionUpdated(batch, 'trainingTasks');
                }
            
                logAction(
                    batch,
                    'Add',
                    'trainingTaskReports',
                    reportRef.id,
                    trainingTasks?.byId[taskId]?.task as string,
                    [data.vesselId],
                    _completedBy
                );
            };

            if (isEditing ) {
                if (!trainingTaskReport || !trainingTask) {
                    console.error('Training task report or training task not found while trying to update training task report');
                    return;
                }
                batchTrace.exampleOperation = 'update';
                batchTrace.exampleDocId = trainingTaskReport.id;
                batch.set(
                    doc(firestore, 'trainingTaskReports', trainingTaskReport.id),
                    {
                        updatedBy: userId,
                        whenUpdated: batchTrace.whenAction,
                        notes: taskNotes[trainingTask.id] ? taskNotes[trainingTask.id] : undefined,
                        touched: serverTimestamp(),
                    } as Partial<TrainingTaskReport>,
                    {merge: true}
                );
            } else if (newReportRef && trainingTask) {
                processTaskReport(trainingTask.id, newReportRef, true);
            } else {
                tasksCompleted.forEach((taskId: string, index: number) => {
                    const newRef = doc(collection(firestore, 'trainingTaskReports'));
                    processTaskReport(taskId, newRef, index === 0);
                });
            }


            onCollectionUpdated(batch, 'trainingTaskReports');
            batchTrace.data = {
                data,
                tasksCompleted,
                taskNotes,
                _completedBy
            };
            batchTrace.save(`${trainingTaskReport ? 'Update' : 'Create'} crew training reports`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error: any) => {
                batchTrace.reportError(error.message, error);
            });
            if (trainingTask && trainingTaskReport && setTaskUserHistory) {
                setTaskUserHistory((prev) => {
                    if (!prev) {
                        return undefined;
                    }
                    const index = prev.findIndex((item) => item.id === reportId);
                    const prevTask = prev[index];
                    if (index !== -1) {
                        prevTask.completedBy = _completedBy;
                        prevTask.dateCompleted = data.dateCompleted;
                        prevTask.dateDue = addInterval(data.dateCompleted, trainingTask.interval).toISODate();
                        prevTask.notes = taskNotes[trainingTask.id] ? taskNotes[trainingTask.id] : undefined;
                    }
                    return [...prev];
                });
            }
            setShowModal(false);
        }  
    });

    const onVesselChanged = (e: CustomEvent) => {
        handleChange(e);
    };

    const completedByOptions = useMemo(() => {
        if (values.vesselId && users?.byVesselId && users.byVesselId[values.vesselId]) {
            return users.byVesselId[values.vesselId].map((user: UserType) => {
                return {
                    id: user.id as string,
                    name: renderFullName(user)
                };
            });
        }
        return undefined;
    }, [values.vesselId, users]);

    const possibleTasks = useMemo(() => {
        if (trainingTask) {
            return [{id: trainingTask.id, name: trainingTask.task}];
        }
        if (values.vesselId && trainingTasks?.allByVesselId && trainingTasks.allByVesselId[values.vesselId]) {
            return trainingTasks.allByVesselId[values.vesselId].map((task) => {
                return {
                    id: task.id,
                    name: task.task
                }
            });
        }
        return undefined;
    }, [trainingTask, values.vesselId, trainingTasks?.allByVesselId]);

    const isStatesValid = useMemo(() => {
        const _tasksCompleted = limitByOptions(tasksCompleted, possibleTasks);
            const _completedBy = limitByOptions(completedBy, completedByOptions);
            if (_tasksCompleted === undefined || _tasksCompleted.length === 0) {
                return false;
            }
            if (_completedBy === undefined || _completedBy.length === 0) {
                return false;
            }
            return true
    }, [completedBy, completedByOptions, possibleTasks, tasksCompleted])

    const onTaskNotesChanged = (e: CustomEvent, taskId: string) => {
        const _taskNotes = {...taskNotes};
        _taskNotes[taskId] = e.detail.value;
        setTaskNotes(_taskNotes);
    };

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            taskNotesDirty ||
            haveFilesChanged(files, []) ||
            (trainingTaskReport ? haveFilesChanged(filesForUser, trainingTaskReportDocuments?.all?.map((doc) => doc.id) ?? []) : haveFilesChanged(filesForUser, [])) ||
            (trainingTaskReport ? hasArrayChanged(customFormsForUser, trainingTaskReportCustomForms?.all?.map((form) => form.id) ?? []) : hasArrayChanged(customFormsForUser, []))
        );
    }, [filesForUser, values, initialValues, taskNotesDirty, files, trainingTaskReportDocuments?.all, customFormsForUser, trainingTaskReportCustomForms?.all, trainingTaskReport]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={trainingTask ? `${renderFullNameForUserId(selectedReportAndCrew?.userId)} - ${trainingTask.task}` : 'Complete Training'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        {isCreating && <IonCol size="6">
                            <SeaSelect
                                label="Vessel or Facility"
                                name="vesselId"
                                value={values.vesselId}
                                onchange={(e) => onVesselChanged(e)}
                                onblur={handleBlur}
                                error={touched.vesselId ? errors.vesselId : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {vessels?.all?.map((vessel) => {
                                    return (
                                        <IonSelectOption key={vessel.id} value={vessel.id}>{vessel.name}</IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>}
                        <IonCol size="6">
                            <SeaDate
                                label="Date"
                                name="dateCompleted"
                                value={values.dateCompleted}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.dateCompleted ? errors.dateCompleted : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                label="Trainer"
                                name="trainer"
                                value={values.trainer}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.trainer ? errors.trainer : ''}
                            />
                        </IonCol>
                        {isCreating && <>
                            {completedByOptions &&
                                <IonCol size="12">
                                    <SeaMultiSelect
                                        label="Crew that completed this training"
                                        values={completedBy}
                                        setValues={setCompletedBy}
                                        options={completedByOptions}
                                        useAllOption={false}
                                        required={true}
                                        requiredError="At least one crew member is required"
                                        isSubmitting={isSubmitting}
                                        emptyText="Not Set"
                                        mode="tags"
                                    />
                                </IonCol>
                            }
                            {possibleTasks &&
                                <IonCol size="12">
                                    <SeaMultiSelect
                                        label="Training tasks completed"
                                        values={tasksCompleted}
                                        setValues={setTasksCompleted}
                                        options={possibleTasks}
                                        useAllOption={false}
                                        required={true}
                                        requiredError="At least one training task is required"
                                        isSubmitting={isSubmitting}
                                        emptyText="Not Set"
                                    />
                                </IonCol>
                            }
                        </>}
                        {tasksCompleted && tasksCompleted.map((taskId: string) => {
                            return (
                                <IonCol key={taskId} size="12">
                                    <SeaTextarea
                                        label={`Notes for ${trainingTask ? trainingTask.task : trainingTasks?.byId[taskId]?.task}`}
                                        name={`task${taskId}`}
                                        value={taskNotes[taskId] ? taskNotes[taskId] : ''}
                                        onchange={(e) => onTaskNotesChanged(e, taskId)}
                                        //onblur={handleBlur}
                                        zone="white"
                                        height={100}
                                        inputmode="text"
                                        //error={touched.currentMedicalIssues ? errors.currentMedicalIssues : ''}
                                    />
                                    {taskNotesDirty && trainingTask && (trainingTaskReport?.completedBy.length || 0) > 1 ? <SeaInputError>This will alter the notes for all crew members in this report.</SeaInputError> : null}
                                </IonCol>
                            );
                        })}
                    </IonRow>
                </IonGrid>
                {!isCreating && canCreate('crewTraining') && (
                    <>
                        <div className="grid-row-spacer"></div>
                        <div className="end-info-line"></div>
                        <div className="forms-and-files-container">
                            <SeaLabel>Forms/Documents</SeaLabel>
                            <SeaNoData
                                dataName="documents or files"
                                isLoading={!formsAndFiles}
                                hasNoData={formsAndFiles && formsAndFiles.length === 0}
                            />
                            <div className={`forms-and-files has-thumbs ${formsAndFiles && formsAndFiles.length > 0 ? 'reveal' : 'conceal'}`}>
                                <div className="sea-row-history headings-history">
                                    <div></div>
                                    <div>Form / Document</div>
                                    <div>Added by</div>
                                </div>
                                {formsAndFiles && formsAndFiles.length > 0 && formsAndFiles.map((item) => {
                                    const customFormCompleted = (item as CustomFormCompleted).customFormId ? (item as CustomFormCompleted) : undefined;
                                    const document = customFormCompleted ? undefined : (item as TrainingTaskReportDocument);
                                    const customForm = customFormCompleted?.customFormId ? (customForms?.byId[customFormCompleted.customFormId]) ? customForms.byId[customFormCompleted.customFormId] : undefined : undefined;
                                    return (
                                        <div
                                            key={item.id}
                                            className={`sea-card sea-row-history clickable ${(customFormCompleted?.isDraft) ? 'draft' : ''}`}
                                            onClick={(e) => {
                                                if (customFormCompleted?.data) {
                                                    console.log(`Viewing customFormsCompleted ${customFormCompleted.id}`);
                                                    setSelectedCustomForm(customForm);
                                                    setSelectedCompletedCustomForm(customFormCompleted);
                                                    setShowViewCompletedCustomForm(true);
                                                } else if (document) {
                                                    console.log(`Viewing trainingTaskReportDocuments item ${document.id}`);
                                                    setSelectedTrainingTaskReportDocuments(document);
                                                    setShowEditTrainingTaskReportDocuments(true);
                                                }
                                            }}
                                        >
                                            <div>
                                                <SeaFileImage files={customFormCompleted?.data && customForm ? [{customForm: customForm, customFormCompleted: customFormCompleted, name: customForm.title}] : item.files} size="tiny"/>
                                            </div>
                                            <div className="truncate">
                                                {customFormCompleted?.data ?
                                                    `${customForm?.title} ${(customFormCompleted?.isDraft) ? ' (DRAFT)' : ''}`
                                                    :
                                                    document?.name
                                                }
                                            </div>
                                            <div className="truncate">{renderFullNameForUserId(item.addedBy)}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            {formsAndFiles && formsAndFiles.length > 0 ? <div className="grid-row-spacer"></div> : null}
                            <div className="columns" style={{ marginBottom: '20px' }}>
                                <div className="right">
                                    <SeaButton onClick={(e) => onCompleteForm()}>
                                        Complete Form
                                    </SeaButton>
                                    <SeaButton onClick={(e) => onAddDocuments()}>
                                        Add Documents
                                    </SeaButton>
                                </div>
                            </div>
                        </div>
                        <div className="end-info-line"></div>
                    </>
                )}
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid && isStatesValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{isEditing ? 'Update' : 'Submit'} Training Form</SeaButton>
                </div>
            </form>

            <BrowseCustomForms
                showModal={showBrowseCustomForms}
                setShowModal={setShowBrowseCustomForms}
                level={level + 1}
                filter={{
                    forVesselId: trainingTask?.vesselId || vesselId
                }}
                onCustomFormSelected={onCustomFormSelected}
            />
            <ViewCompletedCustomForm
                showModal={showViewCompletedCustomForm}
                setShowModal={setShowViewCompletedCustomForm}
                setShowCompleteCustomForm={setShowCompleteCustomForm}
                customForm={selectedCustomForm}
                selectedCompletedCustomForm={selectedCompletedCustomForm}
                level={level + 1}
            />
            <CompleteCustomForm
                showModal={showCompleteCustomForm}
                setShowModal={setShowCompleteCustomForm}
                setShowBrowseCustomForms={setShowBrowseCustomForms}
                level={level + 2}
                customForm={selectedCustomForm}
                itemToUpdate={selectedCompletedCustomForm}
                attachTo='trainingTaskReport'
                attachToId={reportId}
                attachToTrainingTaskId={trainingTask?.id}
                attachToVesselId={trainingTask?.vesselId}
                forceVesselId={vesselId}
                forcePersonnelId={selectedReportAndCrew?.userId}
            />
            {exportType === 'pdf' && <CompletedCustomFormPdf
                onCompleted={() => setExportType(undefined)}
                customForm={selectedCustomForm}
                completedCustomForm={selectedCompletedCustomForm}
            />}
            {trainingTask && reportId && selectedReportAndCrew &&
                <EditTrainingTaskReportDocumentsModal
                    showModal={showEditTrainingTaskReportDocuments}
                    setShowModal={setShowEditTrainingTaskReportDocuments}
                    itemToUpdate={selectedTrainingTaskReportDocuments}
                    selectedTask={trainingTask}
                    selectedReportAndPersonnel={{
                        reportId: reportId || '',
                        personnelIds: [selectedReportAndCrew.userId]
                    }}
                    level={level + 1}
                />
            }
        </SeaModal>
    );
};

export default EditTrainingReport;
