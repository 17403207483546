import React, { useMemo } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import SeaMultiSelect from '../SeaMultiSelect/SeaMultiSelect';
import './SeaSelectVessels.css';

interface SeaSelectVesselsProps {
    zone?: 'blue' | 'grey' | 'white'
    label?: string,
    vesselIds: string[],
    setVesselIds: React.Dispatch<React.SetStateAction<string[]>>,
    emptyText?: string,
    help?: SeaHelp
}

const SeaSelectVessels: React.FC<SeaSelectVesselsProps> = ({
    zone,
    label,
    vesselIds,
    setVesselIds,
    emptyText,
    help
}) => {
    const user = sharedState.user.use();
    const vessels = sharedState.vessels.use();
    // const vesselId = sharedState.vesselId.use();
    const vesselOptions = useMemo(() => {
        if (user?.vesselIds && vessels?.byId) {
            const options: {id: string; name: string}[] = [];
            user.vesselIds.forEach((vesselId: string) => {
                if (vessels.byId[vesselId]) {
                    options.push({
                        id: vesselId,
                        name: vessels.byId[vesselId].name
                    });
                }
            });
            options.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            return options;
        }
        return undefined;
    }, [user?.vesselIds, vessels]);

    return (
        <SeaMultiSelect
            mode="popover"
            zone={zone}
            label={label}
            values={vesselIds}
            setValues={setVesselIds}
            options={vesselOptions}
            useAllOption={vesselOptions && vesselOptions.length > 1}
            //required={true}
            //isSubmitting={hasSubmitted}
            //disabled={forceVesselId ? true : false}
            emptyText={emptyText}
            help={help}
        />
    );
};

export default SeaSelectVessels;
