import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from "react";
import { makeDateTime } from "../../lib/datesAndTime";

//
// Loads Time Zones currently accepted by Sea Flux.
//

type TimeZoneOption = {
    identifier: string,
    name: string // Human friendly and sortable description
};
type TimeZoneData = {
    identifiers: string[]; // IANA time zone identifiers.
    options: TimeZoneOption[] // Massaged list of options for a user to see
};

export const useTimeZonesData = (isEnabled = true) => {
    const [timeZonesData, setTimeZonesData] = useState<TimeZoneData>();

    useEffect(() => {
        setTimeZonesData(undefined);
        if (isEnabled) {
            return onSnapshot(
                doc(firestore, 'global', 'timeZones'),
                (doc) => {
                    if (doc.exists()) {
                        const identifiers = doc.data().identifiers as string[];
                        const options = [] as TimeZoneOption[];

                        identifiers.forEach((identifier) => {
                            if (!identifier.startsWith('Etc/')) {
                                options.push({
                                    identifier,
                                    name: `(UTC${makeDateTime().setZone(identifier).toFormat('ZZ')}) ${identifier}`
                                });
                            }
                        });

                        options.sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });

                        setTimeZonesData({
                            identifiers,
                            options
                        });
                    } else {
                        setTimeZonesData({
                            identifiers: [],
                            options: []
                        });
                    }
                },
                (error) => {
                    console.log(`error getting timeZones`, error);
                }
            );
        }
    }, [isEnabled]);

    return timeZonesData;
};
