import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, UpdateableDocument, sharedState } from "../shared-state";
import { addInterval, makeDateTime } from "../../lib/datesAndTime";
import { canView } from "../Core/userPermissions";
import { useEffect, useState } from 'react';
import { DateRange } from '../../components/SeaDateRange/SeaDateRange';

//
// Load training task reports
//

export interface TrainingTaskReport extends CreateableDocument, UpdateableDocument {
    completedBy: string[];
    deletedBy?: string;
    licenseeId: string;
    notes?: string;
    state: string;
    taskId: string;
    trainer: string;
    vesselId: string;
    dateCompleted: string;
    whenDeleted?: number;
    dateDue: string;
    dateDueDiff?: number;
}

export type TrainingTaskReportsData = {
    all: TrainingTaskReport[], // ordered by dateDue (desc)
    byTaskId: {
        [taskId: string]: TrainingTaskReport[] // ordered by dateDue (desc)
    },
}


export const useTrainingTaskReportsDateRange = (dateRange?: DateRange) => {
    const trainingTasks = sharedState.trainingTasks.use();
    const today = sharedState.today.use()!;
    const licenseeId = sharedState.licenseeId.use();
    const users = sharedState.users.use();
    const [trainingTaskReports, setTrainingTaskReports] = useState<TrainingTaskReportsData>();

    useEffect(() => {
        setTrainingTaskReports(undefined);
        if (
            licenseeId &&
            dateRange &&
            trainingTasks &&
            users &&
            canView('crewTraining')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'trainingTaskReports'),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    where('whenAdded', '>=', makeDateTime(dateRange.from).toMillis()),
                    where('whenAdded', '<', addInterval(dateRange.to, '1d').toMillis()),
                    orderBy('dateDue', 'desc')
                ),
                (snap) => {
                    const reports = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as TrainingTaskReport;
                    });
                    const byTaskId = {} as {
                        [taskId: string]: TrainingTaskReport[]
                    };

                    reports.forEach((report: TrainingTaskReport) => {
                        if (trainingTasks.byId[report.taskId]?.state === 'active') {
                            report.dateDueDiff = Math.round(
                                makeDateTime(report.dateDue).diff(makeDateTime(today), ['days']).days
                            );
                            if (byTaskId[report.taskId] === undefined) {
                                byTaskId[report.taskId] = [];
                            }
                            byTaskId[report.taskId].push(report);
                        }
                    });
    

                    setTrainingTaskReports({ byTaskId, all: reports});

                }, (error) => {
                    // This should be very rare
                    console.log('Failed to access training task reports ', error)
                }
            );
        }
    }, [trainingTasks, users, licenseeId, dateRange, today]);

    return trainingTaskReports;
};
