import { DocumentData, QueryDocumentSnapshot, collection, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { useEffect, useState } from "react";
import { useReportSettings } from "../../../pages/Reporting/Reporting";
import { addInterval, makeDateTime } from "../../../lib/datesAndTime";
export type ReportingCompletedSafetyChecksMap = {
    [id: string]: number;
};

export const useReportingCompletedSafetyChecksMap = (
    isActive: boolean,
    selectedVesselIds: string[]
) => {
    const {dateRange} = useReportSettings();
    const [reportingCompletedSafetyChecksMap, setReportingCompletedSafetyChecksMap] = useState<ReportingCompletedSafetyChecksMap>();

    useEffect(() => {
        if (selectedVesselIds.length === 0) {
            // Nothing to load
            setReportingCompletedSafetyChecksMap({});
            return;
        }
        setReportingCompletedSafetyChecksMap(undefined);
        if (
            isActive &&
            selectedVesselIds?.length > 0
        ) {
            return setupArrayQueryListener(
                'safetyCheckCompleted', // what
                collection(firestore, 'safetyCheckCompleted'),
                [
                    where('state', '==', 'active'),
                    where('whenCompleted', '>=', makeDateTime(dateRange.from).toMillis()),
                    where('whenCompleted', '<', addInterval(dateRange.to, '1d').toMillis())
                ],
                'vesselId',
                'in',
                selectedVesselIds,
                [], // orderBy
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    const map = {} as {
                        [id: string]: number
                    };
                    docs.forEach((doc) => {
                        const safetyCheckId = doc.data().safetyCheckId;
                        if (map[safetyCheckId] === undefined) {
                            map[safetyCheckId] = 0;
                        }
                        map[safetyCheckId]++;
                    });
                    setReportingCompletedSafetyChecksMap(map);
                }
            );
        }
    }, [isActive, selectedVesselIds, dateRange]);

    return reportingCompletedSafetyChecksMap;

}
