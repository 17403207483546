import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { renderCategoryName } from '../../lib/categories';
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { canView } from "../../shared-state/Core/userPermissions";

//
// Loads all active and deleted Equipment
//

export interface Equipment extends CreateableDocument, UpdateableDocument {
    contactIds?: string[];
    deletedBy?: string;
    equipment: string;
    equipmentDocumentIds?: string[];
    files: string[];
    isCritical?: boolean;
    location?: string;
    locationId?: string;
    make?: string;
    model?: string;
    notes?: string;
    serial?: string;
    state: string;
    system?: string;
    systemId: string;
    vesselId: string;
    whenDeleted?: number;
    dateDue?: string;
    searchText?: string;
}

export type EquipmentData = {
    all: Equipment[],
    byId: {
        [id: string]: Equipment
    },
    bySystemId: {
        [id: string]: Equipment[]
    },
    filterOptions: {
        systemIds: string[];
        locationIds: string[];
    }
    isCritical: {
        [id: string]: boolean
    },
    count: number
};

export const equipmentConfig: SharedStateConfig<EquipmentData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'vesselSystems', 'vesselLocations'],
    countLiveDocs: () => sharedState.equipment.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        const vesselSystems = sharedState.vesselSystems.current;
        const vesselLocations = sharedState.vesselLocations.current;
        if (
            vesselId &&
            vesselSystems &&
            vesselLocations &&
            (
                canView('maintenanceSchedule') ||
                canView('jobList') ||
                canView('maintenanceHistory') ||
                canView('sparePartsList') ||
                canView('equipmentManualDocuments')
            )
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'equipment'),
                    where('vesselId', '==', vesselId),
                    where('state', 'in', ['active', 'deleted']),
                    orderBy('equipment', 'asc')
                ),
                (snap) => {
                    done();
                    const all: Equipment[] = [];
                    const byId = {} as {
                        [id: string]: Equipment
                    };
                    const bySystemId = {} as {
                        [id: string]: Equipment[]
                    };
                    snap.docs.forEach((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as Equipment;

                        if (item.state === 'active') {
                            if (bySystemId[item.systemId] === undefined) {
                                bySystemId[item.systemId] = [];
                                //systems.push(item.system);
                            }
                            bySystemId[item.systemId].push(item);
                        }
                        all.push(item);
                        byId[item.id] = item;
                        registerFiles(item.files, 'equipment', item, item.state === 'active' ? 'all' : 'none');
                    });

                    const rawEquipment = ({
                        all,
                        byId,
                        bySystemId,
                    });

                    if (rawEquipment?.all && vesselSystems) {
                        const all: Equipment[] = [];
                        const bySystemId = {} as {
                            [id: string]: Equipment[]
                        };
                        const byId = {} as {
                            [id: string]: Equipment
                        };
                        const isCritical = {} as {
                            [id: string]: boolean
                        };
                        // Filters
                        const filterOptions = {
                            systemIds: [] as string[],
                            locationIds: [] as string[],
                        };
                        const hasFilter = {
                            systemIds: {} as { [id: string]: boolean },
                            locationIds: {} as { [id: string]: boolean },
                        };
                        rawEquipment.all.forEach((equipment) => {
                            equipment.searchText = equipment.equipment;
                            equipment.searchText += renderCategoryName(
                                equipment.systemId,
                                vesselSystems
                            );
                            if (equipment.make)
                                equipment.searchText += ` ${equipment.make}`;
                            if (equipment.model)
                                equipment.searchText += ` ${equipment.model}`;
                            if (equipment.serial)
                                equipment.searchText += ` ${equipment.serial}`;
                            if (equipment.notes)
                                equipment.searchText += ` ${equipment.notes}`;
                            if (equipment.locationId) {
                                equipment.searchText += renderCategoryName(
                                    equipment.locationId,
                                    vesselLocations
                                );
                                hasFilter.locationIds[equipment.locationId] = true;
                            }
            
                            equipment.searchText = equipment.searchText.toLowerCase();
            
                            if (hasFilter.systemIds[equipment.systemId] === undefined) {
                                hasFilter.systemIds[equipment.systemId] = true;
                            }
                            if (bySystemId[equipment.systemId] === undefined) {
                                bySystemId[equipment.systemId] = [];
                                //systems.push(item.system);
                            }
                            if (equipment.state === 'active') {
                                bySystemId[equipment.systemId].push(equipment);
                                all.push(equipment);
                            }
                            byId[equipment.id] = equipment;
                        });
            
                        vesselSystems?.ids?.forEach((id: string) => {
                            if (hasFilter.systemIds[id]) {
                                filterOptions.systemIds.push(id);
                            }
                        });
            
                        vesselLocations?.ids?.forEach((id: string) => {
                            if (hasFilter.locationIds[id]) {
                                filterOptions.locationIds.push(id);
                            }
                        });
            
                        set({
                            filterOptions,
                            bySystemId,
                            byId,
                            isCritical,
                            all,
                            count: snap.docs.length,
                        });
                    }

                },
                (error) => {
                    done();
                    // This should be very rare
                    console.error(
                        `Failed to access equipment for vessel ${vesselId}`,
                        error
                    );
                }
            );
        } else {
            done();
        }
    },

};
