import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, hasArrayChanged, formatTextAreaText, formatValue, preventMultiTap } from '../../../../lib/util';
import { formatSeaDatetime, subtractInterval, addInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName, renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { sendVesselNotification } from '../../../../shared-state/Core/vessel';
import { markVesselOverdueStatStale, onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { alertMessage } from '../../../../managers/AlertManager/AlertManager';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { hasSignatureChanged, haveFilesChanged, makeSeaFiles, makeSignature, saveFileRefs, SeaFile, seaFilesToValue, signatureToValue } from '../../../../lib/files';
import { SafetyMeetingReport } from '../../../../shared-state/HealthSafety/safetyMeetingReports';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaMultiSelect, { OptionType } from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';
import SeaSignature from '../../../../components/SeaSignature/SeaSignature';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditHealthSafetyMeetingProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    defaultVesselId?: string,
    itemToUpdate?: SafetyMeetingReport
}

const EditHealthSafetyMeeting: React.FC<EditHealthSafetyMeetingProps> = ({
    showModal,
    setShowModal,
    itemToUpdate,
    level,
    defaultVesselId
}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const user = sharedState.user.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const users = sharedState.users.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const safetyMeetingReports = sharedState.safetyMeetingReports.use(showModal);
    const [personnelPresentIds, setPersonnelPresentIds] = useState<string[]>();
    const [jobs, setJobs] = useState<string[]>();
    const [vesselIds, setVesselIds] = useState<string[]>();
    const [vesselOptions, setVesselOptions] = useState<OptionType[]>();
    const [signature, setSignature] = useState<SeaFile>();
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const hasSubmittedRef = useRef(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                type: itemToUpdate.type ? ''+itemToUpdate.type : '',
                whenMeeting: formatSeaDatetime(itemToUpdate.whenMeeting),
                notes: itemToUpdate.notes ? ''+itemToUpdate.notes : '',
                sendToCrew: itemToUpdate.sendToCrew ? true : false
            };
        } else {
            return {
                type: '',
                whenMeeting: formatSeaDatetime(),
                notes: '',
                sendToCrew: false
            };
        }
    }, [itemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        hasSubmittedRef.current = false;
        resetForm();
        setValues(initialValues);

        const vesselIds = itemToUpdate ? itemToUpdate.vesselIds : (defaultVesselId ? [defaultVesselId] : []);
        const _vesselOptions: OptionType[] = [];
        user?.vesselIds?.forEach((vesselId: string) => {
            const vessel = vessels?.byId[vesselId];
            if (vessel?.safetyMeetingSettings?.interval) {
                _vesselOptions.push({
                    id: vesselId,
                    name: vessels?.byId[vesselId]?.name ?? ''
                });
            }
        });
        _vesselOptions.sort((a, b) => a.name?.localeCompare(b.name ?? "") ?? 0);

        setVesselIds(vesselIds);
        setVesselOptions(_vesselOptions);
        setFiles(makeSeaFiles(itemToUpdate?.files));
        setSignature(makeSignature(itemToUpdate?.signature));
        setPersonnelPresentIds(itemToUpdate?.personnelPresentIds ? itemToUpdate.personnelPresentIds : []);
        setJobs([]);
    }

    const personnelPresentOptions = useMemo(() => {
        if (vesselIds && vesselIds.length > 0) { 
            if (vesselIds.length === 1) {
                return users?.byVesselId[vesselIds[0]]?.map((u) => {
                    return {
                        id: u.id as string,
                        name: renderFullName(u)
                    };
                });
            } else {
                const options: OptionType[] = [];
                users?.staff.forEach((u) => {
                    if (u.vesselIds && u.vesselIds.some((r) => vesselIds.indexOf(r) >= 0)) {
                        options.push({
                            id: u.id as string,
                            name: renderFullName(u)
                        });
                    }
                });
                return options;
            }
        }
        return undefined;
    }, [users, vesselIds]);

    const lastMeetingNotes = useMemo(() => {
        if (
            vesselIds &&
            vesselIds.length === 1 &&
            vessels?.byId &&
            vessels.byId[vesselIds[0]] &&
            safetyMeetingReports?.byVesselId &&
            safetyMeetingReports.byVesselId[vesselIds[0]]
        ) {
            if ((safetyMeetingReports.byVesselId[vesselIds[0]] as any).lastMeetingNotes) {
                return (safetyMeetingReports.byVesselId[vesselIds[0]] as any).lastMeetingNotes;
            } else {
                return '-';
            }
        }
        return undefined;
    }, [vesselIds, safetyMeetingReports, vessels?.byId]);

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            type: Yup.string(),
            whenMeeting: Yup.date().required().min(...notTooOld),
            notes: Yup.string().max(10000),
            sendToCrew: Yup.boolean()
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            // If vessels array is empty - update error state
            if (
                preventMultiTap('healthSafetyMeeting') ||
                vesselIds === undefined ||
                vesselIds.length === 0 ||
                signature === undefined
            ) {
                return;
            };
            // Record redundant user names incase user is later marked as archived/deleted
            const personnelPresentNames: string[] = [];
            const filteredPersonnelPresentIds: string[] = [];

            if (personnelPresentIds?.length) {
                personnelPresentIds.forEach((id: string) => {
                    if (
                        users?.byId[id] && users.byId[id].state === 'active'
                    ) {
                        let found = false;
                        for (let i = 0; i < (personnelPresentOptions?.length || 0); i++) {
                            if (personnelPresentOptions?.[i].id === id) {
                                found = true;
                                break;
                            }
                        }
                        if (found) {
                            personnelPresentNames.push(renderFullNameForUserId(id));
                            filteredPersonnelPresentIds.push(id);
                        }
                    };
                });
            };

            if (filteredPersonnelPresentIds === undefined || filteredPersonnelPresentIds.length === 0) {
                alertMessage("Please select at least one personnel that was present.");
                return;
            }

            // Process form
            uploadFiles([...files, signature]).then(() => {
                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'safetyMeetingReports');

                if (itemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'safetyMeetingReports', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            whenMeeting: toMillis(data.whenMeeting),
                            notes: data.notes ? data.notes : deleteValue,
                            personnelPresentIds: filteredPersonnelPresentIds ? filteredPersonnelPresentIds : deleteValue,
                            personnelPresentNames: personnelPresentNames.length ? personnelPresentNames : deleteValue,
                            signature: signatureToValue(signature),
                            files: seaFilesToValue(files),
                            sendToCrew: data.sendToCrew ? data.sendToCrew : deleteValue,
                            type: data.type ? data.type : deleteValue,
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, [...files, signature], 'safetyMeetingReports', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'safetyMeetingReports',
                        itemToUpdate.id,
                        data.notes,
                        itemToUpdate.vesselIds,
                        itemToUpdate.personnelPresentIds
                    );

                    if (data.sendToCrew) {
                        sendVesselNotification(batch, 'safetyMeetingReported', 'safetyMeetings', {
                            isUpdate: true,
                            id: itemToUpdate.id,
                            whenMeeting: toMillis(data.whenMeeting),
                            notes: data.notes ? data.notes : deleteValue,
                            personnelPresentNames: personnelPresentNames.length ? personnelPresentNames : deleteValue
                        }, files, itemToUpdate.vesselIds);
                    }
                } else {
                    const newMeetingRef = doc(collection(firestore, 'safetyMeetingReports'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newMeetingRef.id;
                    let jobRefIds: string[] = [];
                    
                    if (jobs && vesselIds && vesselIds.length === 1) {
                        jobs.forEach((job) => {
                            if (job && job.trim().length > 0) {
                                const jobRef = doc(collection(firestore, 'jobs'));
                                jobRefIds.push(jobRef.id)
                                batch.set(jobRef, {
                                    addedBy: userId,
                                    whenAdded: batchTrace.whenAction,
                                    vesselId: vesselIds[0],
                                    licenseeId: licenseeId,
                                    task: job,
                                    priority: '4medium',
                                    state: 'active',
                                    addedFromMeetingId: newMeetingRef.id,
                                    touched: serverTimestamp()
                                });
                                logAction(
                                    batch,
                                    'Add',
                                    'jobs',
                                    jobRef.id,
                                    job,
                                    vesselIds,
                                    personnelPresentIds
                                );
                            }
                        });

                        onCollectionUpdated(batch, 'jobs', vesselIds[0]);
                    };

                    batch.set(newMeetingRef, {
                        vesselIds: vesselIds,
                        addedBy: userId,
                        whenAdded: batchTrace.whenAction,
                        whenMeeting: toMillis(data.whenMeeting),
                        notes: data.notes ? data.notes : undefined,
                        personnelPresentIds: personnelPresentIds ? personnelPresentIds : undefined,
                        personnelPresentNames: personnelPresentNames.length ? personnelPresentNames : undefined, 
                        jobIds: jobRefIds.length ? jobRefIds : undefined,
                        signature: signatureToValue(signature),
                        files: seaFilesToValue(files),
                        state: 'active',
                        sendToCrew: data.sendToCrew ? data.sendToCrew : undefined,
                        type: data.type ? data.type : undefined,
                        touched: serverTimestamp(),
                    });

                    saveFileRefs(batch, [...files, signature], 'safetyMeetingReports', newMeetingRef.id);
                    logAction(
                        batch,
                        'Add',
                        'safetyMeetingReports',
                        newMeetingRef.id,
                        data.notes,
                        vesselIds,
                        personnelPresentIds
                    );

                    if (data.sendToCrew) {
                        sendVesselNotification(batch, 'safetyMeetingReported', 'safetyMeetings', {
                            id: newMeetingRef.id,
                            whenMeeting: toMillis(data.whenMeeting),
                            notes: data.notes ? data.notes : undefined,
                            personnelPresentNames: personnelPresentNames.length ? personnelPresentNames : undefined
                        }, files, vesselIds);
                    }
                }

                vesselIds.forEach((vesselId: string) => {
                    let whenMeeting = toMillis(data.whenMeeting);
                    let whenLatestMeeting = whenMeeting; // Work out when the latest meeting is (might not be this one)
                    if (itemToUpdate && whenMeeting && safetyMeetingReports) {
                        const vesselReports = safetyMeetingReports.byVesselId[vesselId];
                        if (itemToUpdate.id === vesselReports[0].id) {
                            if (vesselReports.length > 1 && vesselReports[1].whenMeeting > whenMeeting) {
                                whenLatestMeeting = vesselReports[1].whenMeeting;
                            };
                        } else {
                            if (whenMeeting < vesselReports[0].whenMeeting) {
                                whenLatestMeeting = vesselReports[0].whenMeeting;
                            };
                        };
                    };

                    const vessel = vessels?.byId[vesselId];
                    let dateDue = addInterval(whenLatestMeeting, vessel?.safetyMeetingSettings?.interval).toISODate();
                    let dateToRemind: string | undefined = undefined;
                    if (vessel?.safetyMeetingSettings?.emailReminder) {
                        dateToRemind = subtractInterval(dateDue, vessel.safetyMeetingSettings.emailReminder).toISODate();
                    };
                    batch.set(
                        doc(firestore, 'vessels', vesselId),
                        {
                            safetyMeetingSettings: {
                                dateDue: dateDue,
                                dateToRemind: dateToRemind,
                                touched: serverTimestamp(),
                            }
                        },
                        { merge: true }
                    );
                    markVesselOverdueStatStale(batch, 'safetyMeetings', vesselId);
                    onCollectionUpdated(batch, 'safetyMeetingReports');
                });

                batchTrace.data = {
                    data,
                    files: seaFilesToValue(files),
                    signature: signatureToValue(signature),
                    vesselIds,
                    filteredPersonnelPresentIds,
                    personnelPresentNames,
                    itemToUpdate
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} safety meeting report ${data?.notes}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload files for safety meeting report`, error.message, error, {
                        itemToUpdate,
                        data,
                        files: seaFilesToValue(files),
                        filteredPersonnelPresentIds,
                        personnelPresentNames
                    });
                }
            });
        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            hasArrayChanged(personnelPresentIds, itemToUpdate?.personnelPresentIds) ||
            haveFilesChanged(files, itemToUpdate?.files) ||
            hasSignatureChanged(signature, itemToUpdate?.signature)
        );
    }, [initialValues, values, personnelPresentIds, itemToUpdate, signature, files]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
            hasSubmittedRef.current = true;
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Meeting Report' : 'Create Meeting Report'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaMultiSelect
                                label="Vessels / Facilities"
                                help={{text: 'Select whichever vessels and/or facilities this meeting was held for.'}}
                                modalTitle="Vessels / Facilities"
                                values={vesselIds}
                                setValues={setVesselIds}
                                options={vesselOptions}
                                useAllOption={true}
                                required={true}
                                isSubmitting={isSubmitting}
                                emptyText="Not Set"
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaDatetime
                                label="Date &amp; Time of Meeting"
                                name="whenMeeting"
                                value={values.whenMeeting}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenMeeting ? errors.whenMeeting : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                label="Type"
                                name="type"
                                value={values.type}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.type ? errors.type : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                <IonSelectOption value="Vessel">Vessel</IonSelectOption>
                                <IonSelectOption value="Shore Facility">Shore Facility</IonSelectOption>
                                <IonSelectOption value="Company">Company</IonSelectOption>
                                <IonSelectOption value="Shared Duties">Shared Duties</IonSelectOption>
                            </SeaSelect>
                        </IonCol>
                        {personnelPresentOptions && personnelPresentOptions.length > 0 &&
                            <IonCol size="12">
                                <SeaMultiSelect
                                    label="Personnel Present"
                                    values={personnelPresentIds}
                                    setValues={setPersonnelPresentIds}
                                    options={personnelPresentOptions}
                                    useAllOption={false}
                                    required={true}
                                    emptyText="Not Set"
                                />
                            </IonCol>
                        }
                        {!itemToUpdate && lastMeetingNotes &&
                            <IonCol size="12">
                                <div className="sea-label">Notes from previous meeting</div>
                                <div>{formatValue(formatTextAreaText(lastMeetingNotes ? lastMeetingNotes : ''))}</div>
                            </IonCol>
                        }
                        <IonCol size="12">
                            <SeaTextarea
                                label="Meeting Notes"
                                name="notes"
                                height={300}
                                value={values.notes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                placeholder="Add notes..."
                                error={touched.notes ? errors.notes : ''}
                            />
                        </IonCol>
                        {!itemToUpdate && vesselIds && vesselIds.length === 1 &&
                            <IonCol size="12">
                                <div className="sea-label">Create Jobs</div>
                                <div style={{paddingBottom: 10}}>Create jobs with medium priority. These can be edited from the job list.</div>
                                <SeaInputList
                                    name="jobs"
                                    maxWidth="635px"
                                    values={jobs}
                                    setValues={setJobs}
                                    addNewText="Add new job task"
                                    confirmDelete={true}
                                />
                            </IonCol>
                        }
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="safetyMeetingReports"
                                field="files"
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaCheckbox
                                name="sendToCrew"
                                checked={values.sendToCrew}
                                setFieldValue={setFieldValue}
                                checkedColor="primary"
                                help={{
                                    text: 'Ticking this will mean sending emails to all crew on any of the vessels/facilities selected that have chosen to receive safey meeting emails.'
                                }}
                                error={touched.sendToCrew ? errors.sendToCrew : ''}
                            >
                                Send meeting notes to crew
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="12">
                            <SeaSignature
                                collection="safetyMeetingReports"
                                file={signature}
                                setFile={setSignature}
                                label="Sign or initial below"
                                isRequired={isSubmitting}
                                // isRequired={hasSubmittedRef.current}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={
                        isValid && !(
                            vesselIds === undefined ||
                            vesselIds.length === 0 ||
                            signature === undefined
                        )
                    }
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Meeting Report' : 'Save New Meeting Report'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditHealthSafetyMeeting;
