import React, { useEffect, useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { formatDate, formatEmailReminder, formatInterval, warnDays } from '../../../../lib/datesAndTime';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { WriteBatch } from "firebase/firestore";
import { RichTextState, initialRichTextState, loadSfdoc } from '../../../../lib/richText';
import { SplittableBatch } from '../../../../lib/firebase';
import { SeaScrollable } from '../../../../components/SeaScrollableArea/SeaScrollableArea';
import { sharedState } from '../../../../shared-state/shared-state';
import { CompanyDocument } from '../../../../shared-state/CompanyDocuments/companyDocuments';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { useItemLinks } from '../../../../shared-state/Core/useItemLinks';
import { SeaLinks } from '../../../../components/SeaLinks/SeaLinks';
import { isPdf } from '../../../../lib/files';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditCompanyDocument from '../EditCompanyDocument/EditCompanyDocument'
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaRichText from '../../../../components/lexical/SeaRichText/SeaRichText';
import SeaLabel from '../../../../components/SeaLabel/SeaLabel';
import SeaPDFViewer from '../../../../components/SeaPDFViewer/SeaPDFViewer';

interface ViewCompanyDocumentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem?: CompanyDocument,
    level?: number
    viewOnly?: boolean,
}

const ViewCompanyDocument: React.FC<ViewCompanyDocumentProps> = ({showModal, setShowModal, selectedItem, level = 1, viewOnly}) => {
    const companyDocumentCategories = sharedState.companyDocumentCategories.use(showModal);
    const links = useItemLinks(selectedItem?.id);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [richTextState, setRichTextState] = useState<RichTextState>(initialRichTextState);
    const [onScroll, setOnScroll] = useState<(event: Event) => void>();
    const [visible, setVisible] = useState(false);
    const [modalContentRef, setModalContentRef] = useState<React.RefObject<SeaScrollable>>();

    // Handle loading
    useEffect(() => {
        if (selectedItem?.sfdoc) {
            return loadSfdoc(
                selectedItem.sfdoc,
                setRichTextState
                // getDefaultContent,
                // licenseeSettings !== undefined,
            );
        }
    }, [selectedItem?.sfdoc]);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    
    return (
        <SeaModal
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        {selectedItem?.title}
                    </span>
                    {selectedItem?.dateExpires &&
                        <SeaStatusDueDate
                            dateDue={selectedItem.dateExpires}
                            warnDays={warnDays.companyDocuments[0]}
                            inline={true}
                        />
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size={selectedItem?.sfdoc ? 'rich-text' : 'wide'}
            fixTitleHeight={true}
            scrollEvents={true}
            noContentPadding={true}
            onScroll={onScroll}
            onOpened={() => {
                setVisible(true);
            }}
            onClosed={() => {
                setVisible(false);
            }}
            setModalContentRef={setModalContentRef}
            level={level}
            viewOnly={viewOnly}
            contentClassName={selectedItem?.sfdoc && 'sfdoc-modal-content'}
        >
            <div style={{ padding: '0px var(--modal-padding-horizontal) 20px', backgroundColor: 'white' }}>
                <div className="info-image">
                    <SeaFileImage
                        files={selectedItem?.files}
                        size="medium"
                        showOthers
                    />
                </div>
                <SeaGrid>
                    {selectedItem?.categoryId && companyDocumentCategories?.byId ? <SeaGridCell label="Category" w="50">
                        {formatValue(companyDocumentCategories.byId[selectedItem.categoryId].name)}
                    </SeaGridCell> : null}
                    {selectedItem?.type === 'renewable' && 
                        <>
                            <SeaGridCell label="Renewal Interval" w="50">
                                {formatValue(formatInterval(selectedItem?.interval))}
                            </SeaGridCell>
                            <SeaGridCell label="Expiry" w="50">
                                {formatDate(selectedItem?.dateExpires)}
                            </SeaGridCell>
                            <SeaGridCell label="Notification" w="50">
                                {formatValue(formatEmailReminder(selectedItem?.emailReminder))}
                            </SeaGridCell>
                        </>
                    }
                    <SeaLinks links={links} ids={selectedItem?.id ? [selectedItem?.id] : []} level={level+1} viewOnly={viewOnly} width='50' />
                </SeaGrid>
                {!viewOnly && <div className="view-modal-buttons">
                    <RequirePermissions 
                        role="companyDocuments"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton zone="white" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Document</SeaButton>
                        <RequirePermissions
                            role="companyDocuments"
                            level={permissionLevels.FULL}
                        >
                            <div className="spacer-wide"></div>
                            <SeaLinkButton
                                mode="standard-link"
                                onClick={(e) => {
                                    if (!selectedItem) {
                                        return;
                                    }
                                    deleteIfConfirmed(
                                        'companyDocuments',
                                        selectedItem.id,
                                        (batch: WriteBatch | SplittableBatch) => {
                                            onCollectionUpdated(batch, 'companyDocuments');
                                            setShowModal(false);
                                        },
                                        'company document',
                                        selectedItem?.title
                                    );
                                }}
                            >
                                Delete Document
                            </SeaLinkButton>
                        </RequirePermissions>
                    </RequirePermissions>
                </div>}
            </div>

            {selectedItem?.sfdoc &&
                <div style={{ padding: '24px 0px 0px var(--modal-padding-horizontal)', backgroundColor: 'var(--ion-color-light)', borderWidth: '1px 0px 0px 0px', borderStyle: 'solid', borderColor: 'var(--input-border-color)', overflow: 'auto hidden' }}>
                    <SeaRichText
                        forModal={true}
                        visible={visible}
                        setOnScroll={setOnScroll}
                        richTextState={richTextState}
                        modalContentRef={modalContentRef}
                    />
                </div>
            }
            {showModal && !viewOnly &&
                <EditCompanyDocument
                    showModal={showEditItemModal}
                    setShowModal={setShowEditItemModal}
                    itemToUpdate={selectedItem}
                    level={2}
                />
            }
            {isPdf(selectedItem?.files?.[0]) ? (
                <div className="sea-fill-container">
                    <div className="end-info-line"></div>
                    <SeaLabel>PDF Preview</SeaLabel>
                    <SeaPDFViewer file={selectedItem?.files?.[0]} allowScroll /> 
                </div>
            ) : null}
        </SeaModal>
    );
};

export default ViewCompanyDocument;
