import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { canView } from "../../shared-state/Core/userPermissions";

//
// Risk Registry (v2 of Hazard Register)
// Will only load if licenseeSettings.riskRegister.version === 2
//

export interface Control {
    id: string;
    description: string;
    likelihood: string;
    consequence: string;
    // Other properties as needed
}

export interface Risk extends CreateableDocument, UpdateableDocument {
    categoryId: string;
    controls: string;
    deletedBy?: string;
    files?: string[];
    interval: string;
    name: string;
    postControls: Control;
    preControls: Control;
    risks: string;
    shouldReportToManagement?: boolean;
    state: string;
    vesselIds: string[];
    whenDeleted?: number;
    dateDue: string;
    dateLastReviewed: string;
    whoResponsible?: string;
}

export type RisksData = {
    all: Risk[];
    byId: {
        [hazardId: string]: Risk;
    };
    byVesselId: {
        [vesselId: string]: {
            byCategoryId: { // Correctly represent the nested structure here
                [categoryId: string]: Risk[]
            }
        }
    },
    byCategoryId: {
        [categoryId: string]: Risk[]; // Alphabetical list
    };
};

export const risksConfig: SharedStateConfig<RisksData> = {
    isAlwaysActive: false,
    dependencies: ['vesselIds', 'licenseeSettings'],
    countLiveDocs: () => Object.keys(sharedState.risks.current?.byId ?? {}).length,
    run: (done, set, clear) => {
        clear();
        const vesselIds = sharedState.vesselIds.current;
        const licenseeSettings = sharedState.licenseeSettings.current;
        if (
            licenseeSettings?.riskRegister?.version === 2 &&
            vesselIds &&
            vesselIds.length > 0 &&
            canView('hazardRegister')
        ) {
            return setupArrayQueryListener(
                'risks', // what
                collection(firestore, 'risks'),
                [where('state', '==', 'active')], // baseConstraints
                'vesselIds',
                'array-contains-any',
                vesselIds,
                [orderBy('name', 'asc')],
                (docs: QueryDocumentSnapshot<DocumentData>[], isCombined: boolean) => {
                    // processDocs
                    done();

                    const all = docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as Risk;
                    });

                    if (isCombined) { // Need to sort by name
                        all.sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                    }

                    const byId = {} as {
                        [id: string]: Risk
                    };
                    const byVesselId = {} as {
                        [vesselId: string]: {
                            byCategoryId: {
                                [categoryId: string]: Risk[]
                            }
                        }
                    };
                    const byCategoryId = {} as {
                        [categoryId: string]: Risk[]
                    };

                    all.forEach((risk: Risk) => {
                        byId[risk.id] = risk;
                        registerFiles(risk.files, 'risks', risk);
                        if (byCategoryId[risk.categoryId] === undefined) {
                            byCategoryId[risk.categoryId] = [];
                        }
                        byCategoryId[risk.categoryId].push(risk);

                        risk.vesselIds.forEach((vesselId: string) => {
                            if (byVesselId[vesselId] === undefined) {
                                byVesselId[vesselId] = {
                                    byCategoryId: {},
                                };
                            }
                            const vessel = byVesselId[vesselId];
                            if (vessel.byCategoryId[risk.categoryId] === undefined) {
                                vessel.byCategoryId[risk.categoryId] = [];
                                // vessel.categories.push(risk.category);
                            }
                            vessel.byCategoryId[risk.categoryId].push(risk);
                        });
                    });

                    set({
                        all,
                        byId,
                        byVesselId,
                        byCategoryId,
                    });
                },
                (error) => {
                    done();
                }
            );
        }
    },
};
