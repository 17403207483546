import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { Category } from '../../lib/categories';

export type LicenseeVesselLocationData = {
    byId: { [id: string]: Category };
    byVesselId: { [id: string]: Category[] };
};

export const useLicenseeVesselLocations = (vesselIds: string[] | undefined) => {
    const [licenseeVesselLocationsData, setLicenseeVesselLocationsData] = useState<LicenseeVesselLocationData>();

    useEffect(() => {
        setLicenseeVesselLocationsData(undefined);
        if (vesselIds?.length) {
            return onSnapshot(
                query(
                    collection(firestore, 'vesselLocations'),
                    where('vesselId', 'in', vesselIds),
                    where('state', '==', 'active')
                ),
                (snapshot) => {
                    const byId: { [id: string]: Category } = {};
                    const byVesselId: { [id: string]: Category[] } = {};
                    snapshot.forEach((doc) => {
                        const location = {
                            id: doc.id,
                            ...doc.data(),
                        } as Category;
                        byId[location.id] = location;
                        if (!byVesselId[location.vesselId]) {
                            byVesselId[location.vesselId] = [];
                        }
                        byVesselId[location.vesselId].push(location);
                    });
                    setLicenseeVesselLocationsData({ byId, byVesselId });
                },
                (error) => {
                    console.error('Error fetching license vesselLocations:', error);
                }
            );
        }

        return () => {
            setLicenseeVesselLocationsData(undefined);
        };
    }, [vesselIds]);

    return licenseeVesselLocationsData;
};