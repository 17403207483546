import React, { useState, useEffect, Profiler, useMemo } from 'react';
import { logPageView } from '../../../lib/firebase';
import { IonSelectOption } from '@ionic/react';
import { onProfilerRender } from '../../../lib/performance';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { renderCategoryName } from '../../../lib/categories';
import { EquipmentManualDocument } from '../../../shared-state/VesselDocuments/equipmentManualDocuments';
import { sharedState } from '../../../shared-state/shared-state';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import EditEquipmentManualDocument from '../../../modals/VesselMaintenance/EquipmentManualDocuments/EditEquipmentManualDocument/EditEquipmentManualDocument';
import ViewEquipmentManualDocument from '../../../modals/VesselMaintenance/EquipmentManualDocuments/ViewEquipmentManualDocument/ViewEquipmentManualDocument';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import './EquipmentManualDocuments.css';

interface EquipmentManualDocumentsProps {
    visible: boolean
}

const EquipmentManualDocuments: React.FC<EquipmentManualDocumentsProps> = ({visible}) => {
    const equipmentManualDocuments = sharedState.equipmentManualDocuments.use(visible ? 1 : 101);
    const equipment = sharedState.equipment.use(visible ? 1 : 101);
    const vesselSystems = sharedState.vesselSystems.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<EquipmentManualDocument>();
    const [systemFilter, setSystemFilter] = useState<string>('');
    const [equipmentFilter, setEquipmentFilter] = useState<string>('');
    const [filteredEquipmentManualDocuments, setFilteredEquipmentManualDocuments] = useState<EquipmentManualDocument[]>();

    useEffect(() => {
        if (visible) {
            logPageView('VesselMaintenance/EquipmentManualDocuments');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    useEffect(() => {
        resetPageLimit();
    }, [systemFilter, equipmentFilter, resetPageLimit]);

    useEffect(() => {
        setFilteredEquipmentManualDocuments(undefined);
        const manuals: EquipmentManualDocument[] = [];
        if (equipmentManualDocuments?.documents) {
            for (let i = 0; i < equipmentManualDocuments?.documents?.length; i++) {
                let item = equipmentManualDocuments?.documents[i];

                if (systemFilter && (item.systemIds === undefined || item.systemIds.indexOf(systemFilter) === -1)) {
                    continue;
                }
                if (equipmentFilter && (item.equipmentIds === undefined || item.equipmentIds.indexOf(equipmentFilter) === -1)) {
                    continue;
                }
                manuals.push(item)
            }
            setFilteredEquipmentManualDocuments(manuals);
        }
    }, [equipmentManualDocuments, systemFilter, equipmentFilter]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && equipmentManualDocuments?.byId[selectedItem.id]) { 
            setSelectedItem(equipmentManualDocuments.byId[selectedItem.id])
        };
    }, [equipmentManualDocuments, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: EquipmentManualDocument) => {
        setShowViewItemModal(true);
        setSelectedItem(item)
    };

    const equipmentFilterOptions = useMemo(() => {
        const _equipment: string[] = [];
        if (equipmentManualDocuments?.filterOptions?.equipmentIds?.length) {
            for (const id of equipmentManualDocuments.filterOptions.equipmentIds) {
                if (systemFilter && equipment?.byId[id]?.systemId !== systemFilter) {
                    continue;
                }
                _equipment.push(id);
            }
        }
        return _equipment;
    }, [
        equipmentManualDocuments?.filterOptions?.equipmentIds,
        equipment?.byId,
        systemFilter,
    ]);

    return (
        <RequirePermissions
            role="equipmentManualDocuments"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="equipment-manual-documents page-head">
                <div><h2>Equipment Manuals</h2></div>
                <div className="actions">
                    <RequirePermissions
                        role="equipmentManualDocuments"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Add New
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            {/* Filters */}
            <div className="columns wrap filters">
                {(equipmentManualDocuments?.filterOptions?.systemIds?.length || 0) > 0 && 
                    <div>
                        <SeaSelect
                            name="systemFilter"
                            value={systemFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => setSystemFilter(e.detail.value)}
                        >
                            <IonSelectOption value="">Filter by System</IonSelectOption>
                            {equipmentManualDocuments?.filterOptions?.systemIds?.map((id: string) => {
                                return (
                                    <IonSelectOption key={id} value={id}>{renderCategoryName(id, vesselSystems)}</IonSelectOption>
                                );
                            })}
                            
                        </SeaSelect>
                    </div>
                }
                {(equipmentManualDocuments?.filterOptions?.equipmentIds?.length || 0) > 0 && 
                    <div>
                        <SeaSelect
                            name="equipmentFilter"
                            value={equipmentFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => setEquipmentFilter(e.detail.value)}
                        >
                            <IonSelectOption value="">Filter by Equipment</IonSelectOption>
                            {equipmentFilterOptions.map((id: string) => {
                                return (
                                    <IonSelectOption key={id} value={id}>
                                        {equipment?.byId[id]?.equipment}
                                        {(equipment?.byId[id]?.state === 'deleted') && ' (deleted)'}
                                    </IonSelectOption>
                                );
                            })}
                        </SeaSelect>
                    </div>
                }
                {/* TODO Figure out why this was .systems and .equipment instead of systemIds and equipmentIds */}
                {(((equipmentManualDocuments?.filterOptions?.systemIds?.length || 0) > 0) || ((equipmentManualDocuments?.filterOptions?.equipmentIds?.length || 0) > 0)) && 
                    <div>
                        <SeaButton onClick={(e) => {setSystemFilter(''); setEquipmentFilter('')}} zone="grey">Reset</SeaButton>
                    </div>
                }
            </div>

            <SeaNoData
                dataName="Equipment Manuals"
                isLoading={!filteredEquipmentManualDocuments}
                hasNoData={filteredEquipmentManualDocuments && filteredEquipmentManualDocuments.length === 0}
                isUsingFilter={true}
            />

            <div className={`equipment-manual-documents has-thumbs ${filteredEquipmentManualDocuments && filteredEquipmentManualDocuments?.length > 0 ? 'reveal' : 'conceal' }`
            }>
                <div className="sea-row headings">
                    <div></div>
                    <div>Document Name</div>
                    <div>System</div>
                    <div>Equipment</div>
                </div>
                <Profiler id="maintenance.manuals" onRender={onProfilerRender}>
                    {mapArrayWithLimit(filteredEquipmentManualDocuments, (item) => {
                        return (
                            <div key={item.id} className="sea-card sea-row" onClick={(e) => onViewItem(item)}>
                                <div>
                                    <SeaFileImage files={item.files} size="tiny"/>
                                </div>
                                <div className="bold truncate-2">{item.title}</div>
                                <div className="truncate-2">{
                                    item.systemIds ? (
                                        item.systemIds.map((id: string) => {
                                            return renderCategoryName(id, vesselSystems);
                                        }).join(', ')
                                    ) : '-'
                                }</div>
                                <div className="truncate-2">{
                                    (equipment?.byId && item.equipmentIds) ? (
                                        item.equipmentIds.map((id: string) => {
                                            return `${equipment.byId[id]?.equipment}${equipment.byId[id]?.state === 'deleted' ? ' (deleted)' : ''}`;
                                        }).join(', ')
                                    ) : '-'
                                }</div>
                            </div>
                        );
                    })}
                    {visible && limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    <EditEquipmentManualDocument
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                    />
                    {selectedItem && <ViewEquipmentManualDocument
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                </>
            }
        </RequirePermissions>
    );
};

export default EquipmentManualDocuments;
