import React, { useEffect, useMemo, useState } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc, arrayRemove, serverTimestamp } from "firebase/firestore";
import { formatValue, formatTextAreaText } from '../../../../lib/util';
import { formatDate, formatDateShort, formatInterval, warnDays } from '../../../../lib/datesAndTime';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName } from '../../../../shared-state/Core/users';
import { UserType } from '../../../../shared-state/Core/user';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { openContextualHelp } from '../../../../managers/ContextualHelpManager/ContextualHelpManager';
import { TrainingTaskReport, TrainingTaskReportsData } from '../../../../shared-state/Crew/useTrainingTaskReports';
import { TrainingTask } from '../../../../shared-state/Crew/trainingTasks';
import { useTrainingTaskReportDocuments } from '../../../../shared-state/Crew/useTrainingTaskReportDocuments';
import { useTrainingTaskReportCustomFormsCompleted } from '../../../../shared-state/Crew/useTrainingTaskReportCustomFormsCompleted';
import SeaFileImage, { SeaFileViewerFile } from '../../../../components/SeaFileImage/SeaFileImage';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import EditTrainingReport from '../EditTrainingReport/EditTrainingReport';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import './ViewTrainingTaskUser.css';

interface ViewTrainingTaskUserProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    trainingTaskReports?: TrainingTaskReportsData,
    selectedTask?: TrainingTask,
    selectedUser: UserType | undefined,
    vesselId?: string,
    selectedReport?: TrainingTaskReport,
    level?: number
}

const ViewTrainingTaskUser: React.FC<ViewTrainingTaskUserProps> = ({showModal, setShowModal, trainingTaskReports, selectedTask, selectedUser, vesselId, selectedReport, level = 1}) => {
    const vessels = sharedState.vessels.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const trainingTasks = sharedState.trainingTasks.use(showModal);
    const customForms = sharedState.customForms.use(showModal);
    const trainingTaskReportCustomForms = useTrainingTaskReportCustomFormsCompleted(selectedTask?.id, undefined, vesselId, selectedUser?.id);
    const trainingTaskReportDocuments = useTrainingTaskReportDocuments(selectedTask?.id, undefined, vesselId, selectedUser?.id);
    const [taskUserHistory, setTaskUserHistory] = useState<TrainingTaskReport[]>();
    const [showEditTrainingTaskReportModal, setShowEditTrainingTaskReportModal] = useState(false);
    const [selectedReportToEdit, setSelectedReportToEdit] = useState<TrainingTaskReport | undefined>(undefined);

    const onDeleteUserFromReport = (report: TrainingTaskReport) => {
        if (!selectedTask) {
            throw new Error("No task selected");
        }

        const batch = splittableBatch(firestore, 20 - 0);
        const batchTrace = makeBatchTrace(batch, 'trainingTaskReports', undefined, report.id);
        const { completedBy } = report;

        if (selectedUser && completedBy.length > 1) { // Remove user from report
            batchTrace.exampleOperation = 'update';
            batch.set(
                doc(firestore, 'trainingTaskReports', report.id),
                {
                    completedBy: arrayRemove(selectedUser.id),
                    updatedBy: userId,
                    whenUpdated: batchTrace.whenAction,
                    touched: serverTimestamp(),
                },
                { merge: true }
            );
        } else { // Only 1 user so remove and delete report
            batchTrace.exampleOperation = 'delete';
            batch.set(
                doc(firestore, 'trainingTaskReports', report.id),
                {
                    deletedBy: userId,
                    whenDeleted: batchTrace.whenAction,
                    state: 'deleted',
                    completedBy: [],
                    touched: serverTimestamp(),
                },
                { merge: true }
            );
        }
        
        onCollectionUpdated(batch, 'trainingTaskReports');

        logAction(
            batch,
            'Delete',
            'trainingTaskReports',
            report.id,
            `${selectedUser?.firstName} ${selectedUser?.lastName} - ${selectedTask.task}`,
            [selectedTask.vesselId],
            selectedUser ? [selectedUser.id as string] : undefined
        );

        batchTrace.data = {
            report,
            selectedUser,
            selectedTask
        };
        batchTrace.save(`Remove ${selectedUser?.firstName} ${selectedUser?.lastName} from ${selectedTask?.task}`);
        batch.commit().then(() => {
            batchTrace.reportSuccess();
        }).catch((error) => {
            batchTrace.reportError(error.message, error);
        });

        showToast('Crew member removed from training report');
        setShowModal(false);
        setShowEditTrainingTaskReportModal(false);
    };

    useEffect(() => {
        setTaskUserHistory(undefined);
        if (showModal) {
            const _taskUserHistory: TrainingTaskReport[] = [];
            trainingTaskReports?.all.forEach((report) => {
                if (
                    report.taskId === selectedTask?.id &&
                    selectedUser?.id &&
                    report.completedBy.indexOf(selectedUser.id) !== -1
                ) {
                    _taskUserHistory.push(report);
                }
            });
            setTaskUserHistory(_taskUserHistory);
        }
    }, [selectedTask?.id, selectedUser?.id, showModal, trainingTaskReports])

    const openEditTrainingTaskReportModal = (report: TrainingTaskReport) => {
        setShowEditTrainingTaskReportModal(true);
        setSelectedReportToEdit(report);
    };

    const onAddNewTrainingReport = () => {
        setSelectedReportToEdit(undefined);
        setShowEditTrainingTaskReportModal(true);
    };

    const filesByTrainingReportId: { [key: string]: SeaFileViewerFile[] } = useMemo(() => {
        const _files: { [key: string]: SeaFileViewerFile[] } = {};
        for (const report of trainingTaskReports?.all || []) {
            _files[report.id] = [];
            if (trainingTaskReportDocuments?.byReportId[report.id]) {
                for (const document of trainingTaskReportDocuments.byReportId[report.id]) {
                    _files[report.id].push(...document.files);
                }
            }
            if (trainingTaskReportCustomForms?.byReportId[report.id]) {
                for (const customFormCompleted of trainingTaskReportCustomForms.byReportId[report.id]) {
                    const customForm = customForms?.byId[customFormCompleted.customFormId];
                    if (customForm) {
                        _files[report.id].push({
                            name: customForm.title,
                            customForm: customForm,
                            customFormCompleted: customFormCompleted,
                        });
                    }
                }   
            }
        }
        return _files;
    }, [trainingTaskReportDocuments?.byReportId, trainingTaskReportCustomForms?.byReportId, trainingTaskReports?.all, customForms?.byId]);

    return (
        <SeaModal
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        {renderFullName(selectedUser)} - {selectedTask?.task}
                    </span>
                    {selectedTask?.dateDue &&
                        <SeaStatusDueDate
                            dateDue={selectedTask?.dateDue}
                            warnDays={warnDays.crewTraining[0]}
                            inline={true}
                        />
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedTask?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="Vessel" w="50">
                    {vesselId && vessels?.byId[vesselId]?.name}
                </SeaGridCell>
                <SeaGridCell label="Retraining Interval" w="50">
                    {formatInterval(selectedTask?.interval)}
                </SeaGridCell>
                <SeaGridCell label="Next Due" w="50">
                    {formatDate(selectedReport?.dateDue)}
                </SeaGridCell>
                {/* <SeaGridCell label="Status" w="50">
                    {formatStatus(selectedReport?.dateDueDiff)}
                </SeaGridCell> */}
            </SeaGrid>

            <div className="end-info-line"></div>
            
            <div className="columns">
                <h2>Training History</h2>
                <div className="right">
                    <SeaButton zone="white" onClick={(e) => onAddNewTrainingReport()}><SeaIcon icon="notes" slot="start" />Complete Training</SeaButton>
                </div>
            </div>

            <SeaNoData
                dataName="training task"
                isHistory={true}
                isLoading={!taskUserHistory}
                hasNoData={taskUserHistory && taskUserHistory.length === 0}
            />
            <div className={`training-task-user-history has-thumbs ${taskUserHistory && taskUserHistory.length > 0 ? 'reveal' : 'conceal'}`}>
                <div className="sea-row-history headings-history">
                    <div></div>
                    <div>Date</div>
                    <div>Trainer</div>
                    <div>Notes</div>
                    <div></div>
                </div>   
                {taskUserHistory?.map((report) => {
                    return (
                        <div key={report.id} className='sea-card sea-row-history clickable' onClick={(e) => openEditTrainingTaskReportModal(report)}>
                            <div><SeaFileImage files={filesByTrainingReportId[report.id]} size="tiny" /></div>
                            <div>{formatDateShort(report.dateCompleted)}</div>
                            <div className="truncate-3">{formatValue(report.trainer)}</div>
                            <div
                                className="truncate-3"
                                onClick={(e) => openContextualHelp(e, {text: formatValue(report.notes)})}
                            >
                                {formatValue(formatTextAreaText(report.notes))}
                            </div>
                            <RequirePermissions
                                role="crewTraining"
                                level={permissionLevels.FULL}
                            >
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        confirmAction('Are you sure you want to delete this crew member from this training report?', 'Yes, delete from report')
                                        .then(() => {
                                            onDeleteUserFromReport(report);
                                        }).catch(() => {});
                                    }}
                                >
                                    <div><SeaIcon icon='trash' slot="icon-only" /></div>
                                </div>
                            </RequirePermissions>
                        </div>
                    )
                })}
            </div>
            <EditTrainingReport
                showModal={showEditTrainingTaskReportModal}
                setShowModal={setShowEditTrainingTaskReportModal}
                setTaskUserHistory={setTaskUserHistory}
                selectedReportAndCrew={{userId: selectedUser?.id || '', reportId: selectedReportToEdit?.id || ''}}
                trainingTask={selectedTask}
                trainingTaskReport={selectedReportToEdit}
                trainingTasks={trainingTasks}
                level={level + 1}
                vesselId={vesselId}
            />
        </SeaModal>
    );
};

export default ViewTrainingTaskUser;
