import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { SeaHelp } from "../../components/SeaContextualHelp/SeaContextualHelp";

//
// Contains user permissions as stored in firestore.
// Should match what can be found in userClaims.
//

export type PermissionLevel = 0 | 2 | 3 | 5 | 7 | 9;
export const permissionLevels = { // Human readable enum for PermissionLevels
    NONE: 0,        // hidden
    VIEW: 2,        // view
    COMPLETE: 3,    // view, complete
    CREATE: 5,      // view, complete, create 
    EDIT: 7,        // view, complete, create, edit
    FULL: 9         // view, complete, create, edit, archive, delete
} as {
    [permissionLevelName: string]: PermissionLevel
};

export type UserPermissionConfig = {
    name: string,
    hidden: PermissionLevel[], // Which permission levels are not applicable for a particular permission
    help?: SeaHelp
};

export const permissionsConfig = {
    vesselSettings: {
        name: 'Vessel Settings & Particulars',
        hidden: [3, 5, 9]
    } as UserPermissionConfig,
    engineHours: {
        name: 'Engine Hours',
        hidden: [3, 5, 9]
    } as UserPermissionConfig,
    logbook: {
        name: 'Logbook',
        hidden: [3]
    } as UserPermissionConfig,
    safetyEquipmentChecks: {
        name: 'Safety Checks',
        hidden: []
    } as UserPermissionConfig,
    safetyEquipmentList: {
        name: 'Safety Equipment Expiries',
        hidden: []
    } as UserPermissionConfig,
    drills: {
        name: 'Drills',
        hidden: []
    } as UserPermissionConfig,
    maintenanceSchedule: {
        name: 'Maintenance Schedule',
        hidden: []
    } as UserPermissionConfig,
    jobList: {
        name: 'Job List',
        hidden: []
    } as UserPermissionConfig,
    sparePartsList: {
        name: 'Spare Parts List / Inventory List',
        hidden: [3]
    } as UserPermissionConfig,
    equipmentManualDocuments: {
        name: 'Equipment Manuals',
        hidden: [3]
    } as UserPermissionConfig,
    maintenanceHistory: {
        name: 'Maintenance History',
        hidden: [3]
    } as UserPermissionConfig,
    vesselCertificates: {
        name: 'Vessel Certificates / Vehicle Expiries',
        hidden: [3]
    } as UserPermissionConfig,
    vesselDocuments: {
        name: 'Vessel Documents',
        hidden: [3]
    } as UserPermissionConfig,
    survey: {
        name: 'Survey Documents',
        hidden: [3]
    } as UserPermissionConfig,
    standardOperatingProcedures: {
        name: 'Standard Operating Procedures',
        hidden: [3]
    } as UserPermissionConfig,
    incidentAccidentMedicalRegister: {
        name: 'Incident / Event Reports',
        hidden: [3],
        help: {
            text: ` Hidden:
            * Can create and view their own reports
            
            View Only:
            * Can create and view their own reports
            * Can view other people's reports and report reviews
            
            Create:
            * Can create and view their own reports
            * Can create and edit report reviews unless completed
            * 
            * Edit:
            * Can create, view, edit any reports or report reviews 
            
            Delete:
            * Can create, view, edit and delete any reports or report reviews
            `
        }
    } as UserPermissionConfig,
    correctiveActions: {
        name: 'Corrective Actions',
        hidden: []
    } as UserPermissionConfig,
    hazardRegister: {
        name: 'Risk Register',
        hidden: [3]
    } as UserPermissionConfig,
    healthSafetyMeetings: {
        name: 'Health & Safety Meetings',
        hidden: [3]
    } as UserPermissionConfig,
    dangerousGoodsRegister: {
        name: 'Dangerous Goods Register',
        hidden: [3]
    } as UserPermissionConfig,
    companyPlan: {
        name: 'Company Plan',
        hidden: [3, 5, 9]
    } as UserPermissionConfig,
    companyDocuments: {
        name: 'Company Documents',
        hidden: [3]
    } as UserPermissionConfig,
    customForms: {
        name: 'Forms/Checklists',
        hidden: []
    } as UserPermissionConfig,
    crewParticulars: {
        name: 'Crew Particulars',
        hidden: [3]
    } as UserPermissionConfig,
    crewCertificates: {
        name: 'Crew Certificates',
        hidden: [3]
    } as UserPermissionConfig,
    crewTraining: {
        name: 'Crew Training',
        hidden: []
    } as UserPermissionConfig,
    contacts: {
        name: 'Contacts/Suppliers',
        hidden: [3]
    } as UserPermissionConfig,
};

export type PermissionRole = keyof (typeof permissionsConfig);

export const permissionRoles = Object.keys(permissionsConfig) as PermissionRole[];

export type UserPermissions = {
    [id in PermissionRole]: PermissionLevel
} & UpdateableDocument;

// Test each permission to see if ALL match
export const areUserPermissionsEqual = (a: UserPermissions, b: UserPermissions) => {
    for (let i = 0; i < permissionRoles.length; i++) {
        if (a[permissionRoles[i]] !== b[permissionRoles[i]]) {
            return false; // Found a different permission level
        }
    }
    return true;
}


export const userPermissionsSharedDataConfig: SharedStateConfig<UserPermissions> = {
    isAlwaysActive: true,
    dependencies: ['userId'],
    countLiveDocs: () => sharedState.userPermissions.current ? 1 : 0,
    run: (done, set, clear) => {
        clear();
        const userId = sharedState.userId.current;
        if (userId) {
            return onSnapshot(
                doc(firestore, 'userPermissions', userId),
                (snap) => {
                    done();
                    if (!snap.exists()) {
                        clear();
                        sharedState.userPending.set(false);
                    } else {
                        set({
                            ...snap.data()
                        } as UserPermissions);
                        sharedState.userPending.set(false);
                    }
                }, (error) => {
                    done();
                    sharedState.userPending.set(false);
                    console.log(`Error getting userPermissions for ${userId}`, error);
                }
            );
        }
    },
};

export const canView = (permission: PermissionRole): boolean => {
    const userPermissions = sharedState.userPermissions.current;
    return (userPermissions && userPermissions[permission] && userPermissions[permission] >= permissionLevels.VIEW) ? true : false;
};
export const canViewAny = (permissions: PermissionRole[]): boolean => {
    for (let i = 0; i < permissions.length; i++) {
        if (canView(permissions[i])) {
            return true;
        }
    }
    return false;
}
export const canComplete = (permission: PermissionRole): boolean => {
    const userPermissions = sharedState.userPermissions.current;
    return (userPermissions && userPermissions[permission] && userPermissions[permission] >= permissionLevels.COMPLETE) ? true : false;
}

export const canCreate = (permission: PermissionRole): boolean => {
    const userPermissions = sharedState.userPermissions.current;
    return (userPermissions && userPermissions[permission] && userPermissions[permission] >= permissionLevels.CREATE) ? true : false;
}

export const canEdit = (permission: PermissionRole): boolean => {
    const userPermissions = sharedState.userPermissions.current;
    return (userPermissions && userPermissions[permission] && userPermissions[permission] >= permissionLevels.EDIT) ? true : false;
};
export const canArchive = (permission: PermissionRole): boolean => {
    const userPermissions = sharedState.userPermissions.current;
    return (userPermissions && userPermissions[permission] && userPermissions[permission] >= permissionLevels.FULL) ? true : false;
};
export const canAccessVessel = (vesselId: string) => {
    return (
        vesselId &&
        sharedState.vesselIds.current &&
        sharedState.vesselIds.current.includes(vesselId)
    );
};
export const canAccessAllVessels = (vesselIds: string[]) => {
    const myMesselIds = sharedState.vesselIds.current;
    if (myMesselIds) {
        for (let i = 0; i < vesselIds.length; i++) {
            if (!myMesselIds.includes(vesselIds[i])) {
                return false;
            }
        }
        return true;
    }
    return false;
};
export const canAccessAnyVessels = (vesselIds: string[]) => {
    const myMesselIds = sharedState.vesselIds.current;
    if (myMesselIds) {
        for (let i = 0; i < vesselIds.length; i++) {
            if (myMesselIds.includes(vesselIds[i])) {
                return true;
            }
        }
    }
    return false;
}
export const canDelete = canArchive;

export const getPermissionRoleFromCollection = (collection: string): PermissionRole | undefined => {
    switch (collection) {
        case 'vesselSOPs':
        case 'SOPs':
            return 'standardOperatingProcedures';
        case 'vesselDocuments':
            return 'vesselDocuments';
        case 'risks':
            return 'hazardRegister';
        case 'scheduledMaintenanceTasks':
            return 'maintenanceSchedule';
        case 'trainingTasks':
            return 'crewTraining';
        case 'safetyCheckItems':
            return 'safetyEquipmentChecks';
        default:
            return collection as PermissionRole;
    }
};

