import React, { useEffect, useState } from 'react';
import { formatValue, renderCamelCase } from '../../../../lib/util';
import { formatDateShort } from '../../../../lib/datesAndTime';
import { logModalView } from '../../../../lib/firebase';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { Voyage } from '../../../../shared-state/VesselLogbook/voyages';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { sharedState } from '../../../../shared-state/shared-state';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import VoyagesReportPdf from '../../../../exports/Reporting/Logbook/VoyagesReportPdf';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaHorizontalStackedBarGraph from '../../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import reporting from '../../../../lib/reporting';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import './ViewVoyagesReport.css';

interface ViewVoyagesReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredItems?: Voyage[], // Adjust type as necessary
    categories?: (string | undefined)[],
}

const ViewVoyagesReport: React.FC<ViewVoyagesReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredItems,
    categories,
}) => {
    const {selectedVesselId} = useReportSettings();
    const vessels = sharedState.vessels.use();
    const [exportType, setExportType] = useState<ExportType>();
    const [labelWidthsForPdf, setLabelWidthsForPdf] = useState<number[]>([]);

    useEffect(() => {
        if (showModal) {
            logModalView('Voyages Report', true);
        }
    }, [showModal]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={exportType === 'pdf' &&
                <VoyagesReportPdf
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredItems={filteredItems}
                    categories={categories}
                    labelWidthsForPdf={labelWidthsForPdf}
                />
            }
            setExportType={setExportType}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={true}
            />
            <SeaHorizontalStackedBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                categories={categories}
                colourPalette={reporting.colours.default}
                setLabelWidthsForPdf={setLabelWidthsForPdf}
            />

            <div className="reporting-data">
                <SeaNoData
                    dataName="voyages"
                    isLoading={!filteredItems}
                    hasNoData={filteredItems?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`voyages-report ${(filteredItems && filteredItems.length > 0) ? 'reveal' : 'conceal'}`}>
                    <div className="sea-row-history headings-history">
                        <div>Name</div>
                        <div>Departed</div>
                        <div>Arrived</div>
                        <div>Trip Type</div>
                        {!selectedVesselId ? <div>Vessel</div> : null}
                    </div>
                    {filteredItems?.map((item) => (
                        <div key={item.id} className="sea-card sea-row-history">
                            <div className="bold truncate-2">{formatValue(item.name)}</div>
                            <div>{formatDateShort(item.whenDeparted)}</div>
                            <div>{formatDateShort(item.whenArrived)}</div>
                            <div>{renderCamelCase(item.tripType)}</div>
                            {!selectedVesselId ? <div>{vessels?.byId[item.vesselId]?.name}</div> : null}
                        </div>
                    ))}
                </div>
            </div>
        </SeaModal>
    );
};

export default ViewVoyagesReport;