import React, { useState, useEffect, useMemo, useCallback } from "react";
import { logPageView } from "../../../lib/firebase";
import { useReportSettings } from "../Reporting";
import { taskStatuses } from "../../../lib/util";
import { sharedState } from "../../../shared-state/shared-state";
import { renderVesselName } from "../../../shared-state/Core/vessels";
import { useReportingEquipmentByIds } from "../../../shared-state/Reporting/MaintenanceReporting/useReportingEquipmentById";
import { useReportingMaintenanceTasks } from "../../../shared-state/Reporting/MaintenanceReporting/useReportingMaintenanceTasks";
import { useReportingCompletedMaintenanceTasks } from "../../../shared-state/Reporting/MaintenanceReporting/useReportingCompletedMaintenanceTasks";
import { useReportingJobs } from "../../../shared-state/Reporting/MaintenanceReporting/useReportingJobs";
import { jobPriorities } from "../../../shared-state/VesselMaintenance/jobs";
import { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import reporting from "../../../lib/reporting";
import SeaHorizontalStackedBarGraph from "../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph";
import ViewMaintenanceScheduleReport from "../../../modals/Reporting/Maintenance/ViewMaintenanceScheduleReport/ViewMaintenanceScheduleReport";
import ViewJobsReport from "../../../modals/Reporting/Maintenance/ViewJobsReport/ViewJobsReport";
import ViewMaintenanceCompletedTasksReport from "../../../modals/Reporting/Maintenance/ViewMaintenanceCompletedTasksReport/ViewMaintenanceCompletedTasksReport";
import "./MaintenanceReporting.css";

const completedTaskTypes = {
    scheduled: "Scheduled",
    unscheduled: "Unscheduled / Jobs",
};

interface MaintenanceReportingProps {
    visible: boolean;
    graphAvailability: {
        count: number;
        maintenanceTaskStatus: boolean;
        completedTasks: boolean;
        jobPriorities: boolean;
    };
}

const MaintenanceReporting: React.FC<MaintenanceReportingProps> = ({ visible, graphAvailability }) => {
    const today = sharedState.today.use()!;
    const { selectedVesselIds, selectedVesselId, dateRangeDescription } = useReportSettings();
    const [modalToShow, setModalToShow] = useState<string>();
    const [equipmentCriticality, setEquipmentCriticality] = useState("both");
    const [selectedTaskStatuses, setSelectedTaskStatuses] = useState(Object.keys(taskStatuses));
    const [selectedCompletedTaskTypes, setSelectedCompletedTaskTypes] = useState(Object.keys(completedTaskTypes));
    const [selectedJobPriorities, setSelectedJobPriorities] = useState(Object.keys(jobPriorities));
    const equipmentById = useReportingEquipmentByIds(visible && graphAvailability.count > 0, selectedVesselIds); // vesselSafetyItems using selectedVesselIds
    const filteredMaintenanceTasks = useReportingMaintenanceTasks(visible && graphAvailability.maintenanceTaskStatus, selectedVesselIds, selectedTaskStatuses, equipmentById, equipmentCriticality);
    const completedMaintenanceTasks = useReportingCompletedMaintenanceTasks(visible && graphAvailability.completedTasks, selectedVesselIds, selectedCompletedTaskTypes, equipmentById, equipmentCriticality);
    const filteredJobs = useReportingJobs(visible && graphAvailability.jobPriorities, selectedVesselIds, selectedJobPriorities, equipmentById, equipmentCriticality);

    useEffect(() => {
        if (visible) {
            logPageView("Reporting/MaintenanceReporting");
        }
    }, [visible]);

    const maintenanceTasksGraphData = useMemo(() => {
        if (filteredMaintenanceTasks && selectedVesselIds) {
            const array: GraphData[] = [];
            const byVesselId: {
                [vesselId: string]: GraphData
            } = {};
            const indexByStatus = {
                overdue: 0,
                upcoming: 1,
            };

            selectedVesselIds.forEach((vesselId: string) => {
                const item = {
                    name: renderVesselName(vesselId),
                    values: [0, 0],
                };
                byVesselId[vesselId] = item;
                array.push(item);
            });

            filteredMaintenanceTasks.prioritised.forEach((item) => {
                if (byVesselId[item.vesselId]) {
                    if (item.dateDue && item.dateDue < today) {
                        // Overdue item
                        byVesselId[item.vesselId].values![indexByStatus.overdue]++;
                    } else {
                        // Upcoming item
                        byVesselId[item.vesselId].values![indexByStatus.upcoming]++;
                    }
                }
            });
            return array;
        }
        return undefined;
    }, [filteredMaintenanceTasks, selectedVesselIds, today]);

    const selectedTaskStatusNames = useMemo(() => {
        return Object.keys(taskStatuses).map((statusId: string) => {
            if (selectedTaskStatuses.indexOf(statusId) !== -1) {
                return taskStatuses[statusId];
            } else {
                return undefined; // Store undefined in array so it can be skipped
            }
        });
    }, [selectedTaskStatuses]);

    const completedMaintenanceTasksGraphData = useMemo(() => {
        if (completedMaintenanceTasks && selectedVesselIds) {
            const array: GraphData[] = [];
            const byVesselId: {
                [vesselId: string]: GraphData
            } = {};
            const indexByStatus = {
                scheduled: 0,
                unscheduled: 1,
            };

            selectedVesselIds.forEach((vesselId: string) => {
                const item = {
                    name: renderVesselName(vesselId),
                    values: [0, 0],
                };
                byVesselId[vesselId] = item;
                array.push(item);
            });

            completedMaintenanceTasks.forEach((item) => {
                if (byVesselId[item.vesselId]) {
                    if (item.type === "scheduled") {
                        // Scheduled
                        byVesselId[item.vesselId].values![indexByStatus.scheduled] += (item.timesCompleted || 0);
                    } else {
                        // Unscheduled / job
                        byVesselId[item.vesselId].values![indexByStatus.unscheduled]++;
                    }
                }
            });

            return array;
        }
        return undefined;
    }, [completedMaintenanceTasks, selectedVesselIds]);

    const selectedCompletedTaskTypeNames = useMemo(() => {
        return Object.keys(completedTaskTypes).map((taskType: string) => {
            if (selectedCompletedTaskTypes.indexOf(taskType) !== -1) {
                return completedTaskTypes[taskType as keyof typeof completedTaskTypes];
            } else {
                return undefined; // Store undefined in array so it can be skipped
            }
        }).filter((item) => item !== undefined) as string[];
    }, [selectedCompletedTaskTypes]);

    const jobsGraphData = useMemo(() => {
        if (filteredJobs && selectedVesselIds) {
            const array: GraphData[] = [];
            const byVesselId: {
                [vesselId: string]: GraphData
            } = {};
            const jobPriorityIds = Object.keys(jobPriorities);

            selectedVesselIds.forEach((vesselId: string) => {
                const item = {
                    name: renderVesselName(vesselId),
                    values: jobPriorityIds.map(() => {
                        return 0;
                    }),
                };
                byVesselId[vesselId] = item;
                array.push(item);
            });

            filteredJobs.forEach((item) => {
                if (byVesselId[item.vesselId]) {
                    byVesselId[item.vesselId].values![jobPriorityIds.indexOf(item.priority)]++;
                }
            });
            return array;
        }
        return undefined;
    }, [filteredJobs, selectedVesselIds]);

    const selectedJobPriorityNames = useMemo(() => {
        return Object.keys(jobPriorities).map((priorityId: string) => {
            if (selectedJobPriorities.indexOf(priorityId) !== -1) {
                return jobPriorities[priorityId as keyof typeof jobPriorities];
            } else {
                return undefined; // Store undefined in array so it can be skipped
            }
        });
    }, [selectedJobPriorities]);

    const renderMaintenanceTitle = useCallback(
        (title: string) => {
            switch (equipmentCriticality) {
                case "nonCritical":
                    return `Non-Critical ${title}`;
                case "critical":
                    return `Critical ${title}`;
                default:
                    return title;
            }
        },
        [equipmentCriticality]
    );

    const dateRangeSubTitle = useMemo(() => {
        return `(${dateRangeDescription})`;
    }, [dateRangeDescription]);

    const subtitle = useMemo(() => `(${selectedVesselId ? `${renderVesselName(selectedVesselId)}, ` : ''}${dateRangeDescription})`, [dateRangeDescription, selectedVesselId]);

    if (!visible) {
        return null;
    }

    let n = 0;

    return (
        <>
            {/* {filteredMaintenanceTasks === undefined && filteredJobs === undefined &&
                <IonSpinner name="crescent" className="sea-spinner"/>
            } */}
            <div className={`reporting-grid max-${graphAvailability.count}-graphs`}>
                {graphAvailability.maintenanceTaskStatus && (
                    <SeaHorizontalStackedBarGraph
                        n={n++}
                        title={renderMaintenanceTitle("Maintenance Schedule Tasks by Vessel")}
                        subTitle="(Live Status)"
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow("maintenanceTaskStatus");
                        }}
                        data={maintenanceTasksGraphData}
                        units="Tasks"
                        categories={selectedTaskStatusNames}
                        colourPalette={reporting.colours.taskStatuses}
                    />
                )}
                {graphAvailability.completedTasks && (
                    <SeaHorizontalStackedBarGraph
                        n={n++}
                        title={renderMaintenanceTitle("Completed Maintenance Tasks by Vessel")}
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow("completedTasks");
                        }}
                        data={completedMaintenanceTasksGraphData}
                        units="Tasks"
                        categories={selectedCompletedTaskTypeNames}
                        colourPalette={reporting.colours.completedMaintenanceTasks}
                    />
                )}
                {graphAvailability.jobPriorities && (
                    <SeaHorizontalStackedBarGraph
                        n={n++}
                        title={renderMaintenanceTitle("Job List Tasks by Vessel")}
                        subTitle="(Live Status)"
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow("jobPriorities");
                        }}
                        data={jobsGraphData}
                        units="Jobs"
                        categories={selectedJobPriorityNames}
                        colourPalette={reporting.colours.jobPriorities}
                    />
                )}
            </div>
            {visible && (
                <>
                    {graphAvailability.maintenanceTaskStatus && (
                        <ViewMaintenanceScheduleReport
                            title={renderMaintenanceTitle("Maintenance Schedule Tasks by Vessel")}
                            subTitle={subtitle}
                            showModal={modalToShow === "maintenanceTaskStatus"}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? "maintenanceTaskStatus" : undefined);
                            }}
                            graphData={maintenanceTasksGraphData}
                            filteredItems={filteredMaintenanceTasks?.prioritised}
                            categories={selectedTaskStatusNames}
                            selectedStatuses={selectedTaskStatuses}
                            setSelectedStatuses={setSelectedTaskStatuses}
                            itemCriticality={equipmentCriticality}
                            setItemCriticality={setEquipmentCriticality}
                            itemsById={equipmentById}
                        />
                    )}
                    {graphAvailability.completedTasks && (
                        <ViewMaintenanceCompletedTasksReport
                            title={renderMaintenanceTitle("Completed Maintenance Tasks by Vessel")}
                            subTitle={subtitle}
                            showModal={modalToShow === "completedTasks"}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? "completedTasks" : undefined);
                            }}
                            graphData={completedMaintenanceTasksGraphData}
                            filteredItems={completedMaintenanceTasks}
                            categories={selectedCompletedTaskTypeNames}
                            selectedStatuses={selectedCompletedTaskTypes}
                            setSelectedStatuses={setSelectedCompletedTaskTypes}
                            itemCriticality={equipmentCriticality}
                            setItemCriticality={setEquipmentCriticality}
                            itemsById={equipmentById}
                        />
                    )}
                    {graphAvailability.jobPriorities && (
                        <ViewJobsReport
                            title={renderMaintenanceTitle("Job List Tasks by Vessel")}
                            subTitle={subtitle}
                            showModal={modalToShow === "jobPriorities"}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? "jobPriorities" : undefined);
                            }}
                            graphData={jobsGraphData}
                            filteredItems={filteredJobs}
                            categories={selectedJobPriorityNames}
                            selectedStatuses={selectedJobPriorities}
                            setSelectedStatuses={setSelectedJobPriorities}
                            itemCriticality={equipmentCriticality}
                            setItemCriticality={setEquipmentCriticality}
                            itemsById={equipmentById}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default MaintenanceReporting;
