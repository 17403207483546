import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, SyncableDocument, UpdateableDocument, sharedState } from "../shared-state";
import { renderFullNameForUserId } from '../Core/users';
import { canView } from "../Core/userPermissions";
import { registerFiles } from "../FileSyncSystem/filesToCache";


export interface CorrectiveAction extends CreateableDocument, UpdateableDocument, SyncableDocument {
    correctiveActionNum: string;
    assignedTo?: string;
    completedBy?: string;
    completedNotes?: string;
    completedFiles?: string[];
    deletedBy?: string;
    description: string;
    emailReminder?: string;
    files: string[];
    licenseeId: string;
    state: string;
    tags?: string[];
    title: string;
    vesselIds: string[];
    whenCompleted?: number;
    whenDeleted?: number;
    dateDue?: string;
    dateToRemind?: string;
    searchText?: string;
    incidentReviewId?: string;
}

export type CorrectiveActionsData = {
    count: number,
    filterOptions: {
        assignedTo: string[]
    },
    byId: {
        completed: { [correctiveActionId: string]: CorrectiveAction },
        active: { [correctiveActionId: string]: CorrectiveAction },
        all: { [correctiveActionId: string]: CorrectiveAction }
    },
    byVesselId: {
        [vesselId: string]: CorrectiveAction[]
    },
    array: {
        completed: CorrectiveAction[],
        active: CorrectiveAction[],
        all: CorrectiveAction[]
    }
};

export const correctiveActionsConfig: SharedStateConfig<CorrectiveActionsData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId'],
    countLiveDocs: () => sharedState.correctiveActions.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (
            licenseeId && canView('correctiveActions')
        ) {
            console.log('correctiveActionsConfig', licenseeId);
            return onSnapshot(
                query(
                    collection(firestore, 'correctiveActions'),
                    where('licenseeId', '==', licenseeId),
                    where('state', 'in', ['active', 'completed']),
                    orderBy('title', 'asc')
                ),
                (snap) => {
                    done();
                    const rawCorrectiveActions = snap.docs.map((doc) => {
                        const correctiveAction = {
                            id: doc.id,
                            ...doc.data(),
                        } as CorrectiveAction

                        if (['active', 'completed'].includes(correctiveAction.state)) {
                            registerFiles(correctiveAction.files, 'correctiveActions', correctiveAction);
                        }

                        return correctiveAction;
                    });
                    if (
                        rawCorrectiveActions
                    ) {
                        const correctiveActions = [] as CorrectiveAction[];
                        const completedCorrectiveActions = [] as CorrectiveAction[];
                        const activeCorrectiveActions = [] as CorrectiveAction[];
                        const byId = { 
                            completed: {} as { [correctiveActionId: string]: CorrectiveAction },
                            active: {} as { [correctiveActionId: string]: CorrectiveAction },
                            all: {} as { [correctiveActionId: string]: CorrectiveAction }
                        };
                        const byVesselId = {} as { [vesselId: string]: CorrectiveAction[] };
                        rawCorrectiveActions.forEach((correctiveAction) => {
                            correctiveAction.searchText = correctiveAction.title;
                            // Inject equipment if present

                            // Inject assignedTo.name
                            if (correctiveAction.assignedTo) {
                                correctiveAction.searchText += renderFullNameForUserId(correctiveAction.assignedTo);
                            }
                            if (correctiveAction.description) {
                                correctiveAction.searchText += correctiveAction.description;
                            }
                            if (correctiveAction.correctiveActionNum) {
                                correctiveAction.searchText += correctiveAction.correctiveActionNum;
                            }
                            if (correctiveAction.tags) {
                                correctiveAction.searchText += correctiveAction.tags.join('');
                            }
                            correctiveActions.push(correctiveAction);
                            if (correctiveAction.state === 'active') {
                                byId.active[correctiveAction.id] = correctiveAction;
                            } else {
                                byId.completed[correctiveAction.id] = correctiveAction;
                            }
                            if (correctiveAction.state === 'completed') {
                                byId.completed[correctiveAction.id] = correctiveAction;
                            }
                            byId.all[correctiveAction.id] = correctiveAction;
                            if (correctiveAction.vesselIds) {
                                correctiveAction.vesselIds.forEach((vesselId) => {
                                    if (!byVesselId[vesselId]) {
                                        byVesselId[vesselId] = [];
                                    }
                                    byVesselId[vesselId].push(correctiveAction);
                                });
                            }
                        });

                        // Filters
                        const filterOptions = {
                            vesselIds: [] as string[],
                            assignedTo: [] as string[],
                        };
                        const hasFilter = {
                            vesselIds: {} as { [vesselId: string]: boolean },
                            assignedTo: {} as { [contactId: string]: boolean },
                        };

                        correctiveActions.forEach((correctiveAction) => {
                            const assignedToName = renderFullNameForUserId(correctiveAction.assignedTo);
                            if (
                                assignedToName &&
                                hasFilter.assignedTo[assignedToName] === undefined
                            ) {
                                hasFilter.assignedTo[assignedToName] = true;
                                filterOptions.assignedTo.push(assignedToName);
                            }
                            correctiveAction.searchText = correctiveAction.searchText?.toLowerCase();
                            if (correctiveAction.state === 'completed') {
                                completedCorrectiveActions.push(correctiveAction);
                            } else {
                                activeCorrectiveActions.push(correctiveAction);
                            }
                        });


                        filterOptions.assignedTo.sort();

                        set({
                            count: correctiveActions.length,
                            array: {
                                completed: completedCorrectiveActions,
                                active: activeCorrectiveActions,
                                all: correctiveActions
                            },
                            filterOptions,
                            byId,
                            byVesselId
                        });
                    }
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.error(
                        `Failed to access corrective action for licensee ${licenseeId}`,
                        error
                    );
                }
            )
        } else {
            done();
        }
    },

};
