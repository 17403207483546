import React, { useState, useEffect, useMemo, useCallback } from "react";
import { SplittableBatch } from "../../../../lib/firebase";
import { WriteBatch } from "firebase/firestore";
import { formatTextAreaText, formatValue } from "../../../../lib/util";
import { formatDate, formatInterval, warnDays, formatShortTimeDurationHrsMinsView, formatDateShort } from "../../../../lib/datesAndTime";
import { deleteIfConfirmed } from "../../../../managers/ConfirmDialogManager/ConfirmDialogManager";
import { usePageLimiter } from "../../../../hooks/usePageLimiter";
import { permissionLevels, canComplete, canEdit } from "../../../../shared-state/Core/userPermissions";
import { renderCategoryName } from "../../../../lib/categories";
import { renderFullNameForUserId } from "../../../../shared-state/Core/users";
import { sharedState } from "../../../../shared-state/shared-state";
import { onCollectionUpdated } from "../../../../shared-state/DataSyncSystem/dataSync";
import { SafetyCheckCompleted, useCompletedSafetyCheckItems } from "../../../../shared-state/VesselSafety/useCompletedSafetyCheckItems";
import { SafetyCheckItem } from "../../../../shared-state/VesselSafety/safetyCheckItems";
import SeaModal from "../../../../components/SeaModal/SeaModal";
import EditSafetyCheck from "../EditSafetyCheck/EditSafetyCheck";
import CompleteSafetyCheck from "../CompleteSafetyCheck/CompleteSafetyCheck";
import SeaIcon from "../../../../components/SeaIcon/SeaIcon";
import SeaButton from "../../../../components/SeaButton/SeaButton";
import SeaFileImage from "../../../../components/SeaFileImage/SeaFileImage";
import SeaNoData from "../../../../components/SeaNoData/SeaNoData";
import RequirePermissions from "../../../../components/RequirePermissions/RequirePermissions";
import SeaGrid from "../../../../components/SeaGrid/SeaGrid";
import SeaGridCell from "../../../../components/SeaGridCell/SeaGridCell";
import SeaLinkButton from "../../../../components/SeaLinkButton/SeaLinkButton";
import SeaStatusDueDate from "../../../../components/SeaStatusDueDate/SeaStatusDueDate";
import ViewCompletedSafetyCheck from "../ViewCompletedSafetyCheck/ViewCompletedSafetyCheck";
import "./ViewSafetyCheck.css";
import { SeaLinks } from "../../../../components/SeaLinks/SeaLinks";
import { useItemLinks } from "../../../../shared-state/Core/useItemLinks";

interface ViewSafetyCheckProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    selectedItem?: SafetyCheckItem;
    level?: number;
    viewOnly?: boolean;
    initialValues?: {
        name?: string;
        location?: string;
        category?: string;
    };
}

const ViewSafetyCheck: React.FC<ViewSafetyCheckProps> = ({ showModal, setShowModal, selectedItem, level = 2, viewOnly = false, initialValues }) => {
    const userId = sharedState.userId.use();
    const vesselId = sharedState.vesselId.use(showModal);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const vesselSafetyCheckCategories = sharedState.safetyCheckCategories.use(showModal);
    const completedSafetyCheckItems = useCompletedSafetyCheckItems(showModal ? selectedItem : undefined);
    const links = useItemLinks(selectedItem?.id);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showCompleteItemModal, setShowCompleteItemModal] = useState(false);
    const [showViewCompletedItemModal, setShowViewCompletedItemModal] = useState(false);
    const [historyItemToUpdate, setHistoryItemToUpdate] = useState<SafetyCheckCompleted>();
    const licenseeSettings = sharedState.licenseeSettings.use();

    useEffect(() => {
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    const onCompleteItem = (item?: SafetyCheckCompleted) => {
        setHistoryItemToUpdate(item);
        setShowCompleteItemModal(true);
    };

    const onViewCompletedItem = (item?: SafetyCheckCompleted) => {
        setShowViewCompletedItemModal(true);
        setHistoryItemToUpdate(item);
    };

    const canCompleteItem = useCallback((item?: SafetyCheckCompleted) => {
        if (
            canEdit('safetyEquipmentChecks') || (
                canComplete("safetyEquipmentChecks") && item?.completedBy === userId
            )
        ) {
            return true;
        }
        return false;
    }, [userId]);

    const safetyItemName = useMemo(() => {
        if (initialValues?.name) {
            return initialValues.name;
        }
        return renderCategoryName(selectedItem?.itemId, vesselSafetyItems);
    }, [selectedItem?.itemId, vesselSafetyItems, initialValues?.name]);

    const location = useMemo(() => {
        if (initialValues?.location) {
            return initialValues.location;
        }
        return renderCategoryName(selectedItem?.locationId, vesselLocations);
    }, [selectedItem?.locationId, vesselLocations, initialValues?.location]);

    const category = useMemo(() => {
        if (initialValues?.category) {
            return initialValues.category;
        }
        return renderCategoryName(selectedItem?.categoryId, vesselSafetyCheckCategories);
    }, [selectedItem?.categoryId, vesselSafetyCheckCategories, initialValues?.category]);

    if (!selectedItem) {
        return null;
    }
    

    return (
        <SeaModal
            title={
                <>
                    <span style={{ marginRight: "12px" }}>Safety Check - {safetyItemName}</span>
                    {selectedItem?.dateDue && <SeaStatusDueDate dateDue={selectedItem.dateDue} warnDays={warnDays.safetyEquipmentChecks[0]} hasFault={selectedItem.hasFault} inline={true} />}
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            level={level}
        >
            <div className="info-image">
                <SeaFileImage files={selectedItem?.files} size="medium" showOthers />
            </div>

            <SeaGrid>
                <SeaGridCell label="Location" w="50">
                    {formatValue(location)}
                </SeaGridCell>
                <SeaGridCell label="Category" w="50">
                    {formatValue(category)}
                </SeaGridCell>
                <SeaGridCell label="Critical Equipment" w="50" help={{ text: "Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew." }}>
                    {formatValue(selectedItem?.isCritical ? "Yes" : "No")}
                </SeaGridCell>
                <SeaGridCell label="Interval" w="50">
                    {formatInterval(selectedItem?.interval)}
                </SeaGridCell>
                <SeaGridCell label="Next Check" w="50">
                    {formatDate(selectedItem?.dateDue)}
                </SeaGridCell>
                <SeaGridCell label="Assigned To" w="50">
                    {formatValue(selectedItem?.assignedTo?.map((id) => renderFullNameForUserId(id)).join(", "))}
                </SeaGridCell>
                {licenseeSettings?.hasSafetyCheckTaskTime && (
                    <SeaGridCell label="Estimated Time" w="50" help={{ text: "This is the estimated time to complete this task." }}>
                        {formatValue(selectedItem?.estimatedTime ? formatShortTimeDurationHrsMinsView(selectedItem?.estimatedTime) : "")}
                    </SeaGridCell>
                )}
                <SeaLinks links={links} ids={selectedItem?.id ? [selectedItem?.id] : []} level={level+1} viewOnly={viewOnly} width='50' />
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Task">{formatValue(formatTextAreaText(selectedItem?.description))}</SeaGridCell>
            </SeaGrid>

            {!viewOnly ? (
                <div className="view-modal-buttons">
                    <RequirePermissions role="safetyEquipmentChecks" level={permissionLevels.EDIT}>
                        <SeaButton zone="white" onClick={() => onEditItem()}>
                            <SeaIcon icon="edit" slot="start" />
                            Edit Safety Check
                        </SeaButton>
                        <RequirePermissions role="safetyEquipmentChecks" level={permissionLevels.FULL}>
                            <div className="spacer-wide"></div>
                            <SeaLinkButton
                                mode="standard-link"
                                onClick={() => {
                                    deleteIfConfirmed(
                                        "safetyCheckItems",
                                        selectedItem.id,
                                        (batch: WriteBatch | SplittableBatch) => {
                                            onCollectionUpdated(batch, "safetyCheckItems");
                                            setShowModal(false);
                                        },
                                        "item",
                                        safetyItemName,
                                        vesselId ? [vesselId] : []
                                    );
                                }}
                            >
                                Delete Safety Check
                            </SeaLinkButton>
                        </RequirePermissions>
                    </RequirePermissions>
                </div>
            ) : null}

            <div className="end-info-line"></div>

            <div className="columns wrap">
                <h2>Safety Check History</h2>
                <RequirePermissions role="safetyEquipmentChecks" level={permissionLevels.COMPLETE}>
                    <div className="right">
                        <SeaButton zone="white" onClick={() => onCompleteItem(undefined)}>
                            <SeaIcon icon="tick" slot="start" />
                            Complete Safety Check
                        </SeaButton>
                    </div>
                </RequirePermissions>
            </div>

            <SeaNoData dataName="item" isHistory={true} isLoading={!completedSafetyCheckItems} hasNoData={completedSafetyCheckItems && completedSafetyCheckItems.length === 0} />

            <div className={`safety-checks-history ${(completedSafetyCheckItems?.length || 0) > 0 ? "reveal" : "conceal"}`}>
                <div className="sea-row-history headings-history">
                    <div className="date">Date</div>
                    <div className="by">By</div>
                    {licenseeSettings?.hasSafetyCheckTaskTime && <div className="actual-time">Actual Time</div>}
                    <div className="notes">Notes</div>
                    <div className="image"></div>
                </div>
                {mapArrayWithLimit(completedSafetyCheckItems, (item) => {
                    return (
                        <div
                            key={item.id}
                            className={`sea-card sea-row-history ${item.shouldReportFault && "fault-reported"} ${!viewOnly && canComplete("safetyEquipmentChecks") && "clickable"}`}
                            onClick={() => {
                                if (viewOnly) {
                                    return;
                                }
                                if (canCompleteItem(item)) {
                                    onCompleteItem(item);
                                } else if (canComplete("safetyEquipmentChecks")) {
                                    onViewCompletedItem(item);
                                }
                            }}
                        >
                            <div className="date">{formatDateShort(item.whenCompleted)}</div>
                            <div className="by truncate">{renderFullNameForUserId(item.completedBy)}</div>
                            {licenseeSettings?.hasSafetyCheckTaskTime && <div className="actual-time">{formatValue(formatShortTimeDurationHrsMinsView(item.actualTime, true))}</div>}
                            <div className="notes truncate">{item.shouldReportFault ? "FAULT REPORTED" : formatValue(formatTextAreaText(item.notes))}</div>
                            <div className="image">
                                <SeaFileImage files={item.files} size="tiny" />
                            </div>
                        </div>
                    );
                })}
                {limitTriggerElement}
            </div>
            {showModal && (
                <>
                    <EditSafetyCheck showModal={showEditItemModal} setShowModal={setShowEditItemModal} itemToUpdate={selectedItem} level={level + 1} />
                    <CompleteSafetyCheck
                        showModal={showCompleteItemModal}
                        setShowModal={setShowCompleteItemModal}
                        selectedItem={selectedItem}
                        setShowParentModal={setShowModal}
                        historyItemToUpdate={historyItemToUpdate}
                        completedSafetyCheckItems={completedSafetyCheckItems}
                        level={level + 1}
                    />
                    <ViewCompletedSafetyCheck
                        showModal={showViewCompletedItemModal}
                        setShowModal={setShowViewCompletedItemModal}
                        selectedItem={historyItemToUpdate}
                        selectedSafetyItemName={safetyItemName}
                        level={level + 1}
                    />
                </>
            )}
        </SeaModal>
    );
};

export default ViewSafetyCheck;
