import React, { useCallback } from 'react';
import { Document, View, Page,  Text } from '@react-pdf/renderer';
import { PdfPageLimiter, UnloadedPdfRows, pdfStyles } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { formatDatetime } from '../../../lib/datesAndTime';
import { renderCategoryName } from '../../../lib/categories';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { Incident } from '../../../shared-state/HealthSafety/incidents';
import { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaNoDataPDF from '../../../components/SeaNoDataPDF';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';
import SeaPieGraph from '../../../components/reporting/SeaPieGraph/SeaPieGraph';

interface IncidentCategoriesReportPdfProps {
    onCompleted: () => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredIncidents?: Incident[],
}

const IncidentCategoriesReportPdf: React.FC<IncidentCategoriesReportPdfProps> = ({
    onCompleted,
    title,
    subTitle,
    graphData,
    filteredIncidents,
}) => {
    const vessels = sharedState.vessels.use();
    const incidentCategories = sharedState.incidentCategories.use();
    const { selectedVesselId, selectedVesselIds } = useReportSettings();
    const counterRef = React.useRef(0);
    const { mapPdfArrayWithLimit } = PdfPageLimiter(counterRef);

    const MakePDF = useCallback(() => {
        counterRef.current = 0;
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={subTitle}
                        userFullName={renderFullName()}
                    />
                    {vessels && selectedVesselIds?.length > 1 ? (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Vessels</Text>
                                <Text>{selectedVesselIds.map((id: string) => vessels?.byId[id]?.name)?.join(', ')}</Text>
                            </View>
                        </View>
                    ) : null}

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <SeaPieGraph
                                mode="pdf"
                                data={graphData}
                                hashNamesForColours={true}
                            />
                        </View>
                    </View>

                    <SeaNoDataPDF 
                        dataName="incidents"
                        hasNoData={!filteredIncidents?.length}
                        isUsingFilter={true}
                    />
                    {filteredIncidents?.length ? (
                        <View style={pdfStyles.dataContainer}>
                                <View style={pdfStyles.dataHeaderRow}>
                                    <Text style={[pdfStyles.dataHeaderText, pdfStyles.timeColumn]}>Date/Time</Text>
                                    <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Category</Text>
                                    <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2x3]}>Name</Text>
                                    {!selectedVesselId ? <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Vessel</Text> : null}
                                </View>
                                {mapPdfArrayWithLimit(filteredIncidents, (incident) => (
                                    <View wrap={false} key={incident.id} style={pdfStyles.greyDataRow}>
                                        <Text style={pdfStyles.timeColumn}>{formatDatetime(incident.whenEvent)}</Text>
                                        <Text style={[pdfStyles.truncate, pdfStyles.column1]}>{renderCategoryName(incident.categoryId, incidentCategories)}</Text>
                                        <Text style={pdfStyles.column2x3}>{formatValue(incident.name)}</Text>
                                        {!selectedVesselId ? <Text style={[pdfStyles.truncate, pdfStyles.column1]}>{vessels?.byId[incident.vesselId]?.name}</Text> : null}
                                    </View>
                                ))}
                                <UnloadedPdfRows length={filteredIncidents?.length} />
                        </View>
                    ) : null}
                </Page>
            </Document>
        )
    }, [title, subTitle, vessels, selectedVesselIds, graphData, filteredIncidents, selectedVesselId, mapPdfArrayWithLimit, incidentCategories]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="incidentCategoriesReport"
        />
    );

};

export default IncidentCategoriesReportPdf;
