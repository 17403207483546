import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, DeleteableDocument, UpdateableDocument } from "../shared-state";
import { useEffect, useState } from 'react';
import { registerFiles } from "../FileSyncSystem/filesToCache";

export interface TrainingTaskReportDocument extends CreateableDocument, UpdateableDocument, DeleteableDocument {
    vesselId: string;
    taskId: string;
    reportId: string;
    name: string;
    files: string[];
    state: 'active' | 'deleted';
    personnelIds: string[];
}

export type TrainingTaskReportDocumentsData = {
    byId: { [id: string]: TrainingTaskReportDocument },
    byTaskId: { [id: string]: TrainingTaskReportDocument[] },
    byReportId: { [id: string]: TrainingTaskReportDocument[] },
    all: TrainingTaskReportDocument[]
};

export const useTrainingTaskReportDocuments = (taskId?: string, reportId?: string, vesselId?: string, personnelId?: string) => {
    const [trainingTaskReportDocuments, setTrainingTaskReportDocuments] = useState<TrainingTaskReportDocumentsData>();
    
    useEffect(() => {
        setTrainingTaskReportDocuments(undefined);
        if (taskId && vesselId) {
            const collectionRef = collection(firestore, 'trainingTaskReportDocuments');
            const queryConstraints = [
                where('vesselId', '==', vesselId),
                where('taskId', '==', taskId),
                where('state', '==', 'active'),
                orderBy('whenAdded', 'desc')
            ];

            if (reportId) {
                queryConstraints.push(where('reportId', '==', reportId));
            }

            if (personnelId) {
                queryConstraints.push(where('personnelIds', 'array-contains', personnelId));
            }

            const q = query(collectionRef, ...queryConstraints);
            
            return onSnapshot(
                q,
                (snap) => {
                    const byId = {} as {
                        [id: string]: TrainingTaskReportDocument
                    }; 
                    const byTaskId = {} as {
                        [id: string]: TrainingTaskReportDocument[]
                    };
                    const byReportId = {} as {
                        [id: string]: TrainingTaskReportDocument[]
                    };
                    const all = snap.docs.map((doc) => {
                        const o = {
                            id: doc.id,
                            ...doc.data()
                        } as TrainingTaskReportDocument;
                        byId[o.id] = o;
                        registerFiles(o.files, 'trainingTaskReportDocuments', o);
                        if (!byTaskId[o.taskId]) {
                            byTaskId[o.taskId] = [];
                        }
                        byTaskId[o.taskId].push(o);
                        if (!byReportId[o.reportId]) {
                            byReportId[o.reportId] = [];
                        }
                        byReportId[o.reportId].push(o);
                        
                        return o;
                    });
                    setTrainingTaskReportDocuments({
                        byId,
                        byTaskId,
                        byReportId,
                        all
                    });
    
                }, (error) => {
                    // This should be very rare
                    console.log(`Failed to access trainingTaskReport documents for taskId ${taskId} with personnelId ${personnelId} `, error);
                }
            );
        }
    }, [personnelId, reportId, taskId, vesselId]);

    return trainingTaskReportDocuments;
};
